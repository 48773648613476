<section>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">
        <div *ngIf="this.openModal === true" class="modal-background">
          <div class="modal-buy shadow">
            <a style="text-decoration: none;" class="user-cash-dashboard cash-boost">
              <span>{{ this.cash.toFixed(2) }}</span>
              <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
            </a>
            <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
              Buy Boosts
            </h3>
            <button (click)="closeModal()" class="close-icon">
              <img src="../../assets/images/close-icon.png" alt="Close icon" />
            </button>
            <form (submit)="submitChanges()" style="margin-bottom: 0" type="submit">
              <div [ngClass]="{
                'radius': this.total > this.cash,
                'radius-all': this.total < this.cash
              }" class="listing-type-boosts">
                <h4 class="buy-title">Buy Listing Boosts</h4>
                <div (click)="checkBoosts()" *ngIf="this.filteredItem[0].Booster !== true"
                  class="modal-select-boosters blue-box">
                  <div class="terms-section">
                    <div class="checkbox">
                      <div class="checked" *ngIf="this.checkedBoost === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                  </div>
                  <p>
                    There is a special spot at top of each listing page that will randomly cycle
                    through the pool of "top" listings. This will highly improve your listing views.
                  </p>
                </div>
                <div (click)="checkHighlight()" *ngIf="this.filteredItem[0].Highlight !== true"
                  class="modal-select-boosters purple-box">
                  <div class="terms-section">
                    <div class="checkbox">
                      <div class="checked" *ngIf="this.checkedHigh === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
                  </div>
                  <p>
                    Your property will appear with a visual highlight to make it stand out to
                    potential interested clients.
                  </p>
                </div>
                <ng-container *ngIf="this.filteredItem[0].Booster === true && this.filteredItem[0].Highlight === true">
                  <div *ngIf="this.filteredItem[0].Booster === true"
                    class="modal-select-boosters disabled-box-modal blue-box">
                    <div class="terms-section">
                      <div class="checkbox">
                        <div class="checked" *ngIf="this.checkedBoost === true"></div>
                      </div>
                    </div>
                    <div class="arrow arrow-boost-modal">
                      <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                    </div>
                    <p>
                      There is a special spot at top of each listing page that will randomly cycle
                      through the pool of "top" listings. This will highly improve your listing views.
                    </p>
                  </div>

                  <div *ngIf="this.filteredItem[0].Highlight === true"
                    class="modal-select-boosters disabled-box-modal purple-box">
                    <div class="terms-section">
                      <div class="checkbox">
                        <div class="checked" *ngIf="this.checkedHigh === true"></div>
                      </div>
                    </div>
                    <div class="arrow arrow-boost-modal">
                      <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
                    </div>
                    <p>
                      Your property will appear with a visual highlight to make it stand out to
                      potential interested clients.
                    </p>
                  </div>
                </ng-container>
                <div class="mobile-direction"
                  style="display: flex; justify-content: space-between; align-items: center; margin-top: 12px;">
                  <div class="same-styles">
                    <p style="margin-bottom: 0;">Duration month</p>
                    <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                      [value]="this.valueMonth" (input)="changeMonthValue($event)" id="month">
                  </div>
                  <div class="number-boost">
                    <p style="margin-bottom: 0;">Total</p>
                    <span>{{this.total.toFixed(2)}} RCT</span>
                  </div>
                </div>
              </div>
              <div *ngIf="this.total > this.cash" class="warn-orange">
                <span>insufficient RCT on your Wallet</span>
                <a routerLink="/wallet" class="user-cash buy-more-button">Buy more RCT
                  <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
                </a>
              </div>
              <div class="warning warning-slots">
                <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
                <p style="width: 100%; text-align: justify;">
                  By completing the purchase, the funds will be taken from your wallet.
                </p>
              </div>
              <div [ngClass]="{
                'top-short-distance': this.total > this.cash,
                'top-distance': this.total < this.cash
              }">
                <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new boosts will be
                  available until:</p>
                <p class="heading-3 available-date">{{this.dateavailable}}
                </p>
              </div>
              <div style="
                    display: flex;
                    justify-content: center;
                    margin-right: 34px;
                  ">
                <button [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : false"
                  class="submit-button">SUBMIT & PAY</button>
              </div>
            </form>
          </div>
        </div>
        <main id="content" class="bg-gray-01">
          <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile" data-animated-id="1">
            <div class="mb-6">
              <h2 class="mb-0 text-heading fs-22 lh-15">Edit property
              </h2>
              <p class="mb-1">Created at {{this.propertycreationdate}}</p>
            </div>
            <ng-container>
              <section data-animated-id="1" class="bg-secondary py-1 py-lg-3 align-items-center">
                <div class="container align-items-center">
                  <div class="row align-items-center">
                    <div style="text-align:center;" class="col-lg-12 pt-lg-1 pt-6 align-items-center">
                      <label class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">
                        Interested in making your property stand out?
                      </label>
                      &nbsp; &nbsp;<a (click)="handleOpenBuySlotsModal(this.id)" class="badge badge-orange"
                        style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Click
                        Here</a>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
            <div class="collapse-tabs new-property-step">
              <ul class="nav nav-pills border py-2 px-3 mb-6 d-none d-md-flex mb-6" role="tablist">
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block "
                    [ngClass]="classshowativetabStep1" id="description-tab" aria-selected="true"><span>
                      <ng-container *ngIf="stepnumber > 1">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Description</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block "
                    [ngClass]="classshowativetabStep2" id="media-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber > 2">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Media</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                    [ngClass]="classshowativetabStep3" id="location-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber > 3">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Location</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                    [ngClass]="classshowativetabStep4" id="detail-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber > 4">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Detail</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                    [ngClass]="classshowativetabStep5" id="amenities-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber > 5">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Amenities</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                    [ngClass]="classshowativetabStep6" id="amenities-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber > 6">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Contract</a>
                </li>
              </ul>
              <div class="tab-content shadow-none p-0">
                <div id="collapse-tabs-accordion">

                  <form class="form" [formGroup]="editpropertyForm">

                    <!-- Property summary-->
                    <ng-container *ngIf="stepnumber == 1">
                      <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep1"
                        id="description">
                        <div class="card bg-transparent border-0">
                          <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                            id="heading-description">
                            <h5 class="mb-0">
                              <button class="btn btn-lg collapse-parent btn-block border shadow-none"
                                data-toggle="false" data-number="1.">
                                <span class="number">1.</span> Description
                              </button>
                            </h5>
                          </div>

                          <div id="description-collapse">
                            <div class="card-body py-4 py-md-0 px-0">

                              <div class="row">

                                <div class="col-lg-6">
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Property
                                        Description</h3>

                                      <div class="form-group">
                                        <label for="propertytitle" class="text-heading">Title

                                        </label>
                                        <input type="text" class="form-control form-control-lg border-0 "
                                          [(ngModel)]="propertytitle" id="propertytitle" maxlength="50" minlength="3" formControlName="propertytitle"
                                          [ngClass]="{ 'is-invalid': submitted && fdesc.propertytitle.errors}">
                                        <div *ngIf="submitted && fdesc.propertytitle.errors" class="invalid-feedback">
                                          <div *ngIf="fdesc.propertytitle.errors.required">
                                            Property title is required
                                          </div>
                                        </div>

                                      </div>
                                      <div class="form-group mb-0">
                                        <label for="propertyfulldescription" class="text-heading">Full
                                          Description</label>
                                        <textarea class="form-control border-0" rows="3" maxlength="400" minlength="3"
                                          formControlName="propertyfulldescription"
                                          [(ngModel)]="propertyfulldescription" id="propertyfulldescription"
                                          [ngClass]="{ 'is-invalid': submitted && fdesc.propertyfulldescription.errors}"></textarea>
                                        <div *ngIf="submitted && fdesc.propertyfulldescription.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdesc.propertyfulldescription.errors.required">
                                            Property description is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Property {{this.propertylistedtitle}}
                                        Price</h3>
                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-12 px-2">
                                          <div class="form-group">
                                            <label for="propertyprice" class="text-heading">Price
                                              in $
                                              <span class="text-muted">(only
                                                numbers)</span></label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="propertyprice" id="price" formControlName="propertyprice"
                                              (keypress)="keyPressNumbers($event)"
                                              [ngClass]="{ 'is-invalid': submitted && fdesc.propertyprice.errors}">
                                            <div *ngIf="submitted && fdesc.propertyprice.errors"
                                              class="invalid-feedback">
                                              <div *ngIf="fdesc.propertyprice.errors.required">
                                                Property price is
                                                required
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Select
                                        Category</h3>
                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2 mb-4 mb-md-0">
                                          <div class="form-group mb-0">
                                            <div class="form-group">
                                              <label for="propertycategory" class="text-heading">Category</label>
                                              <select class="form-control border-0 shadow-none form-control-lg"
                                                name="propertycategory" title="Property Category"
                                                data-style="btn-lg py-2 h-52" id="propertycategory"
                                                (change)="selectPropertyCategory($event)">
                                                <option *ngFor="let propertycategory of lstpropertyCategory"
                                                  [ngValue]="propertycategory.id" id="propertycategory"
                                                  name="propertycategory" [selected]=propertycategory.isSelected>
                                                  {{
                                                                                                    propertycategory.desctext
                                                                                                    }}</option>
                                              </select>
                                            </div>

                                          </div>
                                        </div>
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2 mb-4 mb-md-0">
                                          <div class="form-group mb-0">
                                            <div class="form-group">
                                              <label for="listedincategory" class="text-heading">Listed
                                                in</label>
                                              <select class="form-control border-0 shadow-none form-control-lg"
                                                name="listedincategory" title="Property Listed in"
                                                data-style="btn-lg py-2 h-52" id="listedincategory" #t
                                                (change)="selectPropertyListed(t.value)">
                                                <option *ngFor="let listedin of lstListedCategory"
                                                  [ngValue]="listedin.id" id="listedincategory" name="listedincategory"
                                                  [selected]=listedin.isSelected>
                                                  {{
                                                                                                    listedin.desctext }}
                                                </option>
                                              </select>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Select Move-In Date</h3>
                                      <div class="form-group mb-0">
                                        <label>Move-In Date</label>
                                        <!--<input type="date" class="form-control" #dp="angular-mydatepicker" angular-mydatepicker [(ngModel)]="birthday" value= "22/02/1983" formControlName="birthday'"  (change)="onChangebirthday($event)" >-->
                                        <div class="input-group">
                                          <input class="form-control" placeholder="Select a date" angular-mydatepicker
                                            name="moveindate" id="myDate" formControlName="moveindate"
                                            [options]="moveincalendarOptions" #dp="angular-mydatepicker"
                                            [(ngModel)]="moveindate" (dateChanged)="onChangemoveindate($event)"
                                            (click)="dp.toggleCalendar()" readonly
                                            [ngClass]="{ 'is-invalid': submitted && fdesc.moveindate.errors}" />
                                          <div class="input-group-append">

                                          </div>
                                          <div class="input-group-append">
                                            <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                                              <i class="fa" style="font-size:18px;color:#0ca5b1;">&#xf133;</i>
                                            </button>
                                          </div>
                                          <div *ngIf="submitted && fdesc.moveindate.errors" class="invalid-feedback">
                                            <div *ngIf="fdesc.moveindate.errors.required">
                                              Move-In date is required
                                            </div>
                                          </div>
                                        </div>
                                      </div>


                                      <div class="form-group mb-0 pt-5">

                                        <div class="custom-control custom-checkbox">
                                          <input type="checkbox" class="custom-control-input form-control"
                                            id="isfeatured" formControlName="isfeatured" [checked]="true"
                                            [(ngModel)]="isfeatured" (change)="onFeaturedPropertyChange($event)">

                                          <label class="custom-control-label" for="isfeatured"
                                            style="font-size:medium;">Featured
                                            Property</label>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-right">

                                <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                              <!--</form>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- fotos -->
                    <ng-container *ngIf="stepnumber == 2">

                      <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep2" id="media">
                        <div class="card bg-transparent border-0">
                          <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                            id="heading-media">
                            <h5 class="mb-0">
                              <button class="btn btn-lg collapse-parent btn-block border shadow-none"
                                data-toggle="false" data-number="2." data-target="#media-collapse" aria-expanded="true"
                                aria-controls="media-collapse" disabled>
                                <span class="number">2.</span> Media
                              </button>
                            </h5>
                          </div>
                          <div id="media-collapse">


                            <div class="card-body py-4 py-md-0 px-0">



                              <div class="row">
                                <div class="col-lg-12">
                                  <div class="card mb-12">
                                    <div class="card-body p-12">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Upload photos
                                        of your property(At least 3 photos)</h3>

                                      <ngx-dropzone (change)="onSelect($event)">
                                        <ngx-dropzone-label>Drag and drop image
                                          or

                                          <br>
                                          <button class="btn btn-indigo px-7 mb-2" type="button">
                                            Browse file
                                          </button>
                                        </ngx-dropzone-label>

                                        <ngx-dropzone-image-preview [removable]="true"
                                          ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f.file"
                                          (removed)="onRemove(f.file)">

                                        </ngx-dropzone-image-preview>
                                      </ngx-dropzone>

                                    </div>
                                    <div *ngIf="showphotoserrormessage" class="alert alert-danger" role="alert">
                                      Please upload at least 3 photos!
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div class="d-flex flex-wrap">
                                <a (click)="movetoPreviousStep()"
                                  class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                  <span class="d-inline-block text-primary mr-2 fs-16"><i
                                      class="fal fa-long-arrow-left"></i></span>Prev
                                  step
                                </a>

                                <div class="text-right">

                                  <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                    <span class="d-inline-block ml-2 fs-16"><i
                                        class="fal fa-long-arrow-right"></i></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Location-->
                    <ng-container *ngIf="stepnumber == 3">

                      <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep3" id="location">
                        <div class="card bg-transparent border-0">

                          <div id="location-collapse">
                            <div class="card-body py-4 py-md-0 px-0">
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Listing
                                        Location</h3>

                                      <div style="position: relative;" class="form-group">
                                        <label for="locationaddress" class="text-heading">Address <!-- <a
                                            style="padding-left:00px;" data-toggle="modal"
                                            (click)="openGoogelMapsModal()">
                                            <img src="assets/images/location-pin.png" alt="Set Location" class="mr-2">
                                            <label style="cursor: pointer;color:#0ca5b1">Edit location</label>
                                          </a> --></label>
                                        <input type="text" class="form-control form-control-lg border-0"
                                          [(ngModel)]="locationaddress" formControlName="locationaddress"
                                          id="locationaddress" name="locationaddress"(blur)="updateAddressString($event)">

                                          <p *ngIf="this.addressError" class="not-found-address">Address not found, <a (click)="this.openMapModal()">click here</a> to open the map.</p>
                                      </div>
                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group">
                                            <label for="locationstate" class="text-heading">State</label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="locationstate" formControlName="locationstate"
                                              id="locationstate" name="locationstate" readonly>
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group">
                                            <label for="locationcity" class="text-heading">City</label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="locationcity" formControlName="locationcity"
                                              id="locationcity" name="locationcity" readonly>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group">
                                            <label for="locationneighborhood" class="text-heading">Neighborhood</label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="locationneighborhood" formControlName="locationneighborhood"
                                              id="locationneighborhood" name="locationneighborhood" readonly>
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group">
                                            <label for="locationzip" class="text-heading">Zip</label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="locationzip" formControlName="locationzip" id="locationzip"
                                              name="locationzip" readonly>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group mb-md-0">

                                        <div class="form-group">
                                          <label for="locationcounty" class="text-heading">Country</label>
                                          <input type="text" class="form-control form-control-lg border-0"
                                            [(ngModel)]="locationcounty" formControlName="locationcounty"
                                            id="locationcounty" name="locationcounty" readonly>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <!--
                                                                            <a style="padding-left:00px;"
                                                                                data-toggle="modal"
                                                                                (click)="openGoogelMapsModal()">
                                                                                <img src="assets/images/location-pin.png"
                                                                                    alt="Set Location" class="mr-2">
                                                                                <label
                                                                                    style="cursor: pointer;color:#0ca5b1">Set
                                                                                    pin location</label>
                                                                            </a>-->
                                      <label class="text-heading">Map View </label>
                                      <div class="position-relative">

                                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                                          [disableDefaultUI]="false" [scrollwheel]="null">
                                          <!--<agm-marker [latitude]="latitude"
                                                                                        [longitude]="longitude"
                                                                                        [markerDraggable]="true">
                                                                                    </agm-marker>-->
                                          <agm-circle [latitude]="latitude" [longitude]="longitude" [radius]="radius"
                                            [fillColor]="'gray'" [circleDraggable]="false" [editable]="false">
                                          </agm-circle>
                                        </agm-map>
                                        <!--
                                                                                <p
                                                                                    class="mb-0 p-3 bg-white shadow rounded-lg position-absolute pos-fixed-bottom mb-4 ml-4 lh-17 z-index-2">
                                                                                    {{this.address}}
                                                                                </p>-->
                                      </div>

                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group mb-md-0">
                                            <label for="latitude" class="text-heading">Latitude
                                            </label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              id="latitude" [(ngModel)]="latitude" formControlName="latitude"
                                              name="latitude" readonly>
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                                          <div class="form-group mb-md-0">
                                            <label for="longitude" class="text-heading">Longitude</label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              id="longitude" [(ngModel)]="longitude" formControlName="longitude"
                                              name="longitude" readonly>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-wrap">
                                <a (click)="movetoPreviousStep()"
                                  class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                  <span class="d-inline-block text-primary mr-2 fs-16"><i
                                      class="fal fa-long-arrow-left"></i></span>Prev
                                  step
                                </a>
                                <button (click)="movetoNextStep()" class="btn btn-lg btn-primary next-button mb-3">Next
                                  step
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </form>
                  <form class="form" [formGroup]="editpropertyForm">
                    <!-- Details-->
                    <ng-container *ngIf="stepnumber == 4">

                      <div class="tab-pane tab-pane-parent fade px-0 " [ngClass]="classshowativetabStep4" id="detail">
                        <div class="card bg-transparent border-0">
                          <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                            id="heading-detail">
                            <h5 class="mb-0">
                              <button class="btn btn-block collapse-parent collapsed border shadow-none"
                                data-toggle="collapse" data-number="4." data-target="#detail-collapse"
                                aria-expanded="true" aria-controls="detail-collapse">
                                <span class="number">4.</span> Detail
                              </button>
                            </h5>
                          </div>
                          <div id="detail-collapse">
                            <div class="card-body py-4 py-md-0 px-0">
                              <div class="card mb-6">
                                <div class="card-body p-6">
                                  <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                    Listing Detail</h3>

                                  <div class="row">
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="propertysizeft" class="text-heading">Size in ft
                                          <span class="text-muted">(only
                                            numbers)</span></label>
                                        <input type="text" class="form-control form-control-lg border-0"
                                          formControlName="propertysizeft" [(ngModel)]="propertysizeft"
                                          (keypress)="keyPressNumbers($event)" id="propertysizeft" name="propertysizeft"
                                          [ngClass]="{ 'is-invalid': submitted && fdetails.propertysizeft.errors}">
                                        <div *ngIf="submitted && fdetails.propertysizeft.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdetails.propertysizeft.errors.required">
                                            Size of the property is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="propertybedrooms" class="text-heading">Bedrooms<span
                                            class="text-muted">(only
                                            numbers)</span></label>
                                        <input type="text" class="form-control form-control-lg border-0"
                                          formControlName="propertybedrooms" [(ngModel)]="propertybedrooms"
                                          (keypress)="keyPressNumbers($event)" id="propertybedrooms"
                                          name="propertybedrooms"
                                          [ngClass]="{ 'is-invalid': submitted && fdetails.propertybedrooms.errors}">
                                        <div *ngIf="submitted && fdetails.propertybedrooms.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdetails.propertybedrooms.errors.required">
                                            Number of bedrooms is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="propertybathrooms" class="text-heading">Bathrooms<span
                                            class="text-muted">(only
                                            numbers)</span></label>
                                        <input type="text" class="form-control form-control-lg border-0"
                                          formControlName="propertybathrooms" [(ngModel)]="propertybathrooms"
                                          (keypress)="keyPressNumbers($event)" id="propertybathrooms"
                                          name="propertybathrooms"
                                          [ngClass]="{ 'is-invalid': submitted && fdetails.propertybathrooms.errors}">
                                        <div *ngIf="submitted && fdetails.propertybathrooms.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdetails.propertybathrooms.errors.required">
                                            Number of bathrooms is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <div class="d-flex flex-wrap">
                                <a (click)="movetoPreviousStep()"
                                  class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                  <span class="d-inline-block text-primary mr-2 fs-16"><i
                                      class="fal fa-long-arrow-left"></i></span>Prev
                                  step
                                </a>
                                <div class="text-right">

                                  <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                    <span class="d-inline-block ml-2 fs-16"><i
                                        class="fal fa-long-arrow-right"></i></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </form>
                  <!-- Amenities -->
                  <ng-container *ngIf="stepnumber == 5">

                    <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep5" id="amenities">
                      <div class="card bg-transparent border-0">
                        <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                          id="heading-amenities">
                          <h5 class="mb-0">
                            <button class="btn btn-block collapse-parent collapsed border shadow-none"
                              data-toggle="collapse" data-number="5." data-target="#amenities-collapse"
                              aria-expanded="true" aria-controls="amenities-collapse">
                              <span class="number">5.</span> Amenities
                            </button>
                          </h5>
                        </div>
                        <div id="amenities-collapse">
                          <div class="card-body py-4 py-md-0 px-0">
                            <div class="card mb-6">
                              <div class="card-body p-6">
                                <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                  Amenities

                                </h3>
                                <button type="button" class="btn btn-outline-primary"
                                  (click)="includenewamenitiesModal()">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="29" height="29" viewBox="0 0 47 47">
                                    <defs>
                                      <filter id="Ellipse_317" x="0" y="0" width="47" height="47"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                        <feFlood flood-opacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_317)">
                                      <circle id="Ellipse_317-2" data-name="Ellipse 317" cx="14.5" cy="14.5" r="14.5"
                                        transform="translate(9 6)" fill="#fff" />
                                    </g>
                                    <text id="_" data-name="+" transform="translate(0 41)" fill="#0ec6d5" font-size="38"
                                      font-family="SegoeUI, Segoe UI">
                                      <tspan x="10" y="-8">+</tspan>
                                    </text>
                                  </svg>
                                  New amenitie</button>

                                <div class="row">
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id <= 8">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id > 8 && commoditie.id <= 15">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id > 15">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex flex-wrap">
                              <a (click)="movetoPreviousStep()"
                                class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                <span class="d-inline-block text-primary mr-2 fs-16"><i
                                    class="fal fa-long-arrow-left"></i></span>Prev
                                step
                              </a>

                              <div class="text-right">

                                <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Contract -->
                  <ng-container *ngIf="stepnumber == 6">

                    <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep6" id="amenities">
                      <div class="card bg-transparent border-0">
                        <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                          id="heading-amenities">
                          <h5 class="mb-0">
                            <button class="btn btn-block collapse-parent collapsed border shadow-none"
                              data-toggle="collapse" data-number="6." data-target="#amenities-collapse"
                              aria-expanded="true" aria-controls="amenities-collapse">
                              <span class="number">6.</span> Contract
                            </button>
                          </h5>
                        </div>
                        <div id="amenities-collapse">
                          <div class="card-body py-4 py-md-0 px-0">
                            <!-- Contract  -->
                            <div class="card mb-12">
                              <div class="card-body p-6">
                                <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                  Contract</h3>


                                <!--
                                                                <p class="card-text mb-5">This file will be used....
                                                                </p>-->

                                <div class="form-row">
                                  <div class="form-group col-md-5">
                                    <label for="predefinedcontract" class="text-heading">Available
                                      Samples </label>
                                    <select class="form-control border-0 shadow-none form-control-lg"
                                      name="predefinedcontract" title="Contracts" data-style="btn-lg py-2 h-52"
                                      id="predefinedcontract" (change)="selectContractSample($event)">
                                      <option *ngFor="let predefinedcontract of lstPreDefinedContracts"
                                        [ngValue]="predefinedcontract.Id" id="predefinedcontract"
                                        name="predefinedcontract" [selected]=predefinedcontract.isSelected>
                                        {{
                                                                                predefinedcontract.name
                                                                                }}</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-2">
                                    <div style="text-align: center;">
                                      <label for="colFormLabelLg" class="col-form-label col-form-label-lg"
                                        style="padding-top: calc(0.625rem + 35px)!important;"><strong>OR</strong></label>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-5">
                                    <label for="addnewcontractfile" class="text-heading">Create new contract</label>

                                    <div class="input-group">
                                      <button type="button" (click)="OpenContractFile()"
                                        class="btn btn-secondary mb-2">New Contract</button>
                                      <!-- <div class="custom-file">
                                                                                <input type="file"
                                                                                    class="custom-file-input"
                                                                                    id="inputGroupFile04"
                                                                                    aria-describedby="inputGroupFileAddon04"
                                                                                    (change)="onFileSelect($event)"
                                                                                    accept = "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template">
                                                                                <label
                                                                                    class="custom-file-contract-label"
                                                                                    for="inputGroupFile04">Choose
                                                                                    file</label>
                                                                            </div>
                                                                        -->
                                    </div>


                                  </div>
                                </div>

                                <div class="form-row mx-n2">
                                  <div class="list-group">
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordname">@Landlord Name</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordid">@Landlord ID</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordidtype">@Landlord ID Type</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordaddress">@Landlord Address</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Phone Number</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Email</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Signature</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantname">@Tenant Name</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantid">@Tenant ID</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenanttype">@Tenant ID Type</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantddress">@Tenant Address</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Phone Number</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Email</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Signature</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Date Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Day Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Month Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@year Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantid">@Initial Date</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@End Date</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Move-In date</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property address</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property City</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property State</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Monthly Fee</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Deposit Value</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Included services</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Amenities services</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)"
                                      value="tenanttype">@Category(Apartment,Villa,..)</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Bedrooms</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Bathrooms</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Sq Ft</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Parking</a>
                                  </div>
                                </div>
                                <div class="py-4">
                                  <ckeditor [(ngModel)]="mycontent" #myckeditor="ngModel" name="myckeditor" required
                                    [config]="ckeConfig" debounce="500" (paste)="onPaste($event)"
                                    (change)="onChange($event)">
                                    <ckbutton [name]="'saveButton'" [command]="'insert_name'"
                                      (click)="insert_name($event)" [label]="'Insert User Name'"
                                      [toolbar]="'clipboard,1'">
                                    </ckbutton>
                                  </ckeditor>
                                </div>
                                <ng-container *ngIf="this.showfilemessage==true">
                                  <div class="form-row mx-n2">
                                    <article class="col-lg-14 pr-xl-7">
                                      <div class="alert alert-danger" role="alert">
                                        {{this.warningmessage}}
                                      </div>
                                    </article>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="this.contractfileuploaded">

                                  <div class="form-row mx-n2">
                                  </div>
                                  <!--<angular-editor [(ngModel)]="htmlContent" [config]="editorConfig" class="ml-2"></angular-editor> -->

                                  <!--
                                                                        <div class="html">
                                                                        {{ htmlContent }}
                                                                      </div>
                                                                    -->
                                  <!--
                                                                    <div class="form-row mx-n2">


                                                                        <article class="col-lg-12 pr-xl-7">
                                                                            <section class="pt-6 border-top pb-4">
                                                                                <h4 class="fs-22 text-heading mb-4">File
                                                                                    Details</h4>
                                                                                <div class="row">
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Name</dt>
                                                                                        <dd>{{this.ContractFilename}}</dd>
                                                                                    </dl>
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Created by</dt>
                                                                                        <dd>{{this.Contractcreatedby}}</dd>
                                                                                    </dl>
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Creation Date</dt>
                                                                                        <dd>{{this.Contractcreationdate}}
                                                                                        </dd>
                                                                                    </dl>
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Size</dt>
                                                                                        <dd>{{this.formatBytes(this.Contractsize)}}</dd>
                                                                                    </dl>
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Extension</dt>
                                                                                        <dd>{{this.Contractextension}}</dd>
                                                                                    </dl>
                                                                                    <dl class="col-sm-6 mb-0 d-flex">
                                                                                        <dt
                                                                                            class="w-110px fs-14 font-weight-500 text-heading pr-2">
                                                                                            Status</dt>
                                                                                            <dd> <ng-container *ngIf="this.Contractstatus== 'To Edit'">
                                                                                                <span class="badge badge-danger">{{this.Contractstatus}}</span>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="this.Contractstatus != 'To Edit'">
                                                                                                <span class="badge badge-success">{{this.Contractstatus}}</span>
                                                                                            </ng-container>
                                                                                        </dd>
                                                                                    </dl>
                                                                                </div>
                                                                            </section>
                                                                        </article>
                                                                    </div>
                                                                -->
                                  <!--<div class="form-row mx-n2 py-2">

                                                                    <button type="button" (click)="EditContractFile()" class="btn btn-secondary mb-2">Finalize Contract</button>

                                                                    <ng-container *ngIf="this.contractfileType == 1 || this.Contractstatus != 'To Edit'">
                                                                        <button type="button" (click)="PreviewContractFile()" class="btn btn-info mb-2 ml-2">Preview File</button>
                                                                    </ng-container>

                                                                    </div> -->
                                </ng-container>

                              </div>
                            </div>

                            <div class="d-flex flex-wrap">
                              <a (click)="movetoPreviousStep()"
                                class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                <span class="d-inline-block text-primary mr-2 fs-16"><i
                                    class="fal fa-long-arrow-left"></i></span>Prev
                                step
                              </a>


                              <button class="btn btn-lg btn-primary mb-3" (click)="onSubmitUpdateProperty()"
                                type="submit">Update
                                property
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>

              </div>
            </div>
          </div>


        </main>
      </div>
    </div>
  </div>
</section>
