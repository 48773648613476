<section>
    <div class="wrapper dashboard-wrapper">
        <div class="d-flex flex-wrap flex-xl-nowrap">
            <div id = "showDesktopDashboardNavBar" class="db-sidebar bg-white">
                <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
                  <div style="height: 279px;">
                    <div class="shadow-xs-1 py-3">
                      <div class="d-flex px-3 px-xl-6 w-100">
                      </div>
                      <app-leftmenumypainel></app-leftmenumypainel>
                    </div>
                  </div>
                </nav>
              </div>
            <div class="page-content">

                <main id="content" class="bg-gray-01">
                    <div class="" data-animated-id="1">
                    <div class="container" data-select2-id="7">
                        <div class="row" data-select2-id="6">
                          <div class="col-12 mb-5">
                           
                          </div>
                          <div class="col-11  pt-5" >


                            <div class="section-title d-flex align-items-center">
                              <h2>Event Configuration</h2>
                            </div>
                        
                            <table class="table table-striped px-3">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Creation Date</th>
                                    <th scope="col">Total events</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let event of this.lstOfEvents;">
                                    <th scope="row">{{event.number}}</th>
                                    <td>{{event.name}}</td>
                                    <td>{{event.creationdate | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{event.totalevents}}</td>
                                    <td><a (click)="editevent(event.number)" style="cursor: pointer" data-toggle="tooltip" title="" class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                                        <a (click)="deleteevent(event.number)" style="cursor: pointer" data-toggle="tooltip" title="" class="d-inline-block fs-18 text-muted hover-primary" data-original-title="Delete"><i class="fal fa-trash-alt"></i></a></td>
                                  </tr>

                                </tbody>
                              </table>
<!--
                            <div class="row">

                                <div class="col-sm-3">
                                    <div class="card card-outline-success mb-3">
                                        <div class="card-header card-inverse card-success">
                                            Available steps
                                            </div>
                                        <div class="card-block scroll-list">
                                            <div class="list-group" droppable [dragHintClass]="'drag-hint'" (onDrop)="onDropBackStepsConfiguration($event)">
                                                <li class="list-group-item list-group-item-action list-group-item-success" [draggable]  *ngFor="let item of availablesteps"
                                                    [dragClass]="'active'" [dragData]="item" [dragScope]="item.type">
                                                    {{item.name}}
                                                </li>
                                            </div>
                                        </div>

                                </div>
                            </div>
                                <div class="col-sm-3">
                                    <div class="card card-outline-primary mb-3"  droppable [dragOverClass]="'drag-target-border'" [dropScope]="['fruit', 'vegetable']"
                                        [dragHintClass]="'drag-hint'" (onDrop)="onAnyDrop($event)">
                                        <div class="card-header card-inverse card-primary">Event configuration</div>
                                        <div class="card-block scroll-list">
                                            <li *ngFor="let item of droppedItems" [draggable] [dragData]="item" class="list-group-item">{{item.name}}</li>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>

                        -->
                        <div class="text-right">
                            <a href="#" class="btn btn-lg btn-primary">New Event</a>
                            </div>
                             </div>
                        </div>
                   
                      </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</section>