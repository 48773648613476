import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataInviteService {
  private dadosSource = new BehaviorSubject<number>(0);
  dataAtt = this.dadosSource.asObservable();

  attData(newData: number) {
    this.dadosSource.next(newData);
  }
}
