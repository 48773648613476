<div class="modal-header">
    <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">{{this.fromParent.prop1}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <ng-container *ngIf="this.fromParent.prop3 ==1">
        <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
            <tbody>
            <tr>
            </tr>
            <tr>
            <td style="width: 100%;">
                <qrcode [qrdata]="this.fromParent.prop2" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </td>
            </tr>
            <tr>
                <td style="width: 100%;">
                    <br>{{this.fromParent.prop2}}
                </td>
                </tr>
            </tbody>
            </table>

    </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 ==2">
    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
        <tbody>
        <tr>
        </tr>
        <tr>
        <td style="width: 100%;">
            <h5>{{this.fromParent.prop2}}</h5>
        </td>
        </tr>
        <tr>
            <td style="width: 100%;">
                <br>In case of any doubt, read the terms of use and/or get in touch with us!  (support@roundcomb.com)
            </td>
            </tr>
        </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 ==3">
        <main id="page" role="main">

            <!--
                -->
            <!--
                -->
            <!--
                -->
            <div id="content" class="main-content" data-content-field="main-content"
                data-collection-id="57b1d8e71b631ba919052d74" data-edit-main-image="">
                <div class="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1501572064898"
                    id="page-57b1d8e71b631ba919052d74">
                    <div class="row sqs-row" id="yui_3_17_2_1_1614018611573_134">
                        <div class="col sqs-col-12 span-12" id="yui_3_17_2_1_1614018611573_133">
                            <div class="sqs-block html-block sqs-block-html" data-block-type="2"
                                id="block-4a6b27b673174064722a">
                                <div class="sqs-block-content" id="yui_3_17_2_1_1614018611573_132">
                                    <h1 class="text-align-center" id="yui_3_17_2_1_1614018611573_138">
                                    </h1>
                                    <p>Effective April 01, 2017</p>
                                    <p><strong>A</strong>.<strong> About Us</strong>:<strong> </strong>
                                    </p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>Welcome to roundcomb.com (the “Website”). Roundcomb Inc.
                                        (“Roundcomb”, “us”, “we”, or “our”) provides venue for third
                                        party property owners or their agents that register with us
                                        using the Website (“Landlords”) to advertise residential real
                                        property for rent or sale (each a “Listing”) directly from
                                        Landlords (collectively, “Customers”). We do this through the
                                        Website, our mobile application, the Content, and the products
                                        and services otherwise made available by us (together with the
                                        Website, and Content, and other products and services we offer
                                        via the Website, collectively, the “Services”). By accessing or
                                        using the Services, you the end user of the Services (together
                                        with Landlords, and Customers, collectively, “User”, “you”, or
                                        “your”), expressly agree to be bound to and to abide by these
                                        Terms of Service (together with any order forms, these “Terms”),
                                        our Privacy Policy, and any other policy we may develop from
                                        time to time and post on our Website (together with these Terms
                                        and our Privacy Policy, collectively, “Policies”), which create
                                        legal and enforceable agreements whether or not you register for
                                        a user Account with us (an “Account”), whether or not you
                                        obtain, transmit, post, send, receive, link, email, submit,
                                        upload, download, or otherwise communicate (“Transmit”): User
                                        Content, a Review, advertisements, text, images, video, audio,
                                        photographs, graphics, data, information, links, software,
                                        music, or any other input and data (collectively, “Content”)
                                        using the Services to us or other Users. If you do not agree to
                                        be bound to or to abide by these Terms of Service and our other
                                        Policies, do not browse the Website or use the Services.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>BY ACCESSING THE SERVICES, YOU (ON BEHALF OF YOURSELF OR
                                            THE ENTITY THAT YOU REPRESENT) REPRESENT AND WARRANT THAT
                                            YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO
                                            THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                                            REPRESENT) AND ACKNOWLEDGE THAT YOU HAVE
                                            READ</strong>,<strong> UNDERSTOOD AND AGREE TO BE BOUND BY
                                            ALL OF THE PROVISIONS</strong>,<strong> CONDITIONS AND
                                            NOTICES CONTAINED IN THESE TERMS JUST AS IF YOU SIGNED THESE
                                            TERMS</strong>.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>B</strong>. <strong>Our Terms of
                                            Service</strong>:<strong> </strong></p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>1</strong>. <strong>Service Conditions</strong>. You
                                        cannot use the Services unless you are at least 18 years of age.
                                        By accessing the Services, you represent and warrant to us that
                                        you are at least 18 years of age; you the right, authority and
                                        capacity to agree to, and abide by these Terms; and you shall
                                        not use any rights granted hereunder for any unlawful purpose or
                                        for any purpose which violates these Terms, as determined by
                                        us.<strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>2</strong>.<strong> Grant of Web License</strong>. These
                                        Terms provide you with a personal, revocable, non-exclusive,
                                        non-assignable, non-transferable, limited and temporary license
                                        to access and use certain aspects of our Website, subject to
                                        these Terms and our Policies.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>3</strong>.<strong> Mobile Devices</strong>.<strong>
                                        </strong>If you are accessing the Services via our mobile
                                        application downloaded from Apple, Inc.’s ‘App Store’ or Google,
                                        Inc.’s ‘Google Play’ store (collectively, “App Providers”) you
                                        and Roundcomb acknowledge and agree that these Terms are entered
                                        into by and between you and Roundcomb, and that none of the App
                                        Providers are party to these Terms. However, you acknowledge and
                                        agree that the App Providers are third party beneficiaries of
                                        these Terms, and that, upon your acceptance of these Terms, the
                                        App Providers will have the right (and will be deemed to have
                                        accepted the right) to enforce these Terms against you as a
                                        third party beneficiary thereof. The App Providers are not a
                                        sponsor to, nor in any way affiliated with, Roundcomb or any of
                                        the Services. Roundcomb alone is responsible for the Services
                                        and the materials on the Services. Roundcomb and not the App
                                        Providers are responsible for addressing any User’s concerns
                                        regarding the Services. In the event of any claim of
                                        intellectual property infringement, Roundcomb will be solely
                                        responsible for the investigation, defense, settlement and
                                        discharge of any such intellectual property infringement claim.
                                        Subject to your compliance with these Terms and our Policies,
                                        the license granted by these Terms allows you to install one
                                        copy of our mobile application on one mobile device, tablet, or
                                        personal computer, which is owned or controlled by you (a
                                        “Device”). As part of the installation process of our mobile
                                        application, you may be changing your Device settings. By
                                        installing our mobile application, you agree you have approved
                                        such changes and you understand you can reconfigure such
                                        settings in the configuration options available in your Device.
                                        Such Device settings changes may include but are not limited to
                                        providing our mobile application with access to your Device’s
                                        camera, or other Device information, as well as allowing
                                        software updates of our mobile application. You acknowledge that
                                        any failure to upgrade our mobile application may result in the
                                        inability to use all or part of our mobile application. You
                                        understand and agree that use of the Services via your Device
                                        may result in data or other charges from your mobile
                                        communication service provider and you expressly release us from
                                        any and all liability relating to any such charges and/or your
                                        Device. To uninstall our mobile application, you may use the
                                        uninstall procedures offered by your Device. If our mobile
                                        application is being acquired on behalf of the United States
                                        Government, then use, duplication, or disclosure of our mobile
                                        application by the U.S. Government is subject to restrictions
                                        set forth in these Terms and as provided in the Federal
                                        Acquisition Regulations and the Defense Federal Acquisition
                                        Regulation Supplement, as applicable.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>4</strong>. <strong>Account</strong> <strong>and
                                            Subscription</strong>.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>(a) To use certain aspects of the Services you must create an
                                        Account. In connection with the Services, you agree to provide
                                        true, accurate, current and complete information as prompted by
                                        our registration forms, and to maintain and promptly update the
                                        information you provide to us in order to keep your information
                                        true, accurate, current and complete. It is your obligation to
                                        maintain and control passwords to your Account. YOU AGREE THAT
                                        YOU ARE SOLELY AND FULLY RESPONSIBLE FOR ANY ACTIVITIES AND/OR
                                        ACTIONS TAKEN UNDER YOUR ACCOUNT, WHETHER OR NOT YOU HAVE
                                        AUTHORIZED SUCH ACTIVITIES OR ACTIONS. You agree to immediately
                                        notify us of any unauthorized uses of your username and password
                                        and/or any other breaches of security. There is no assumption by
                                        us of your responsibility to notify your local law enforcement
                                        agency of any identity theft. You agree we will not be liable
                                        for any loss or damages caused by your failure to comply with
                                        your security obligations.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) To use certain aspects of our Services, you must purchase a
                                        subscription to use and access those Services in exchange for a
                                        fee, subject to these terms (“Subscription”). The duration and
                                        price of the Subscriptions we offer will be displayed on our
                                        Website or otherwise communicated to you by us. If you purchase
                                        a Subscription it automatically renew upon expiration in
                                        exchange for the then-applicable fee using the credit card or
                                        other payment information on file with our payment processors
                                        (our “Payment Processor”). Each Subscription renewal payment
                                        will take place on or about your Subscription expiration date.
                                        Except as otherwise required by applicable law, you agree that
                                        we will not provide you with any notices prior to each renewal
                                        payment. If you do not elect for the Subscription to
                                        automatically renew, we will notify you on or about your
                                        Subscription expiration date that payment is due. If we do not
                                        receive timely payment from you prior to the expiration of the
                                        applicable Subscription period or if the automatic renewal of
                                        your Subscription fails for any reason, we reserve the right to
                                        restrict your access to the Services, as determined by us. For
                                        instructions regarding canceling your Subscription or Account,
                                        see Section 11 below. Each Subscription provides each User with
                                        a personal, revocable, non-exclusive, non-assignable,
                                        non-sublicensable, non-transferable, limited and temporary
                                        license to access and use Services which require a Subscription
                                        (as determined by us), subject to these Terms.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) All payment processing and storage of credit card data are
                                        handled by our Payment Processor. Further, you authorize us
                                        and/or our Payment Processor to charge your chosen payment
                                        method (credit card, debit card, et cetera) in connection with
                                        all fees, costs, and other amounts incurred by you in connection
                                        with your use of the Services (e.g. Listing or services
                                        purchased by a Customer or sold by a Landlord). In connection
                                        with any fees, costs, and other amounts to be paid by you, you
                                        agree: (i) to only provide valid and current payment
                                        information; (ii) that we may use the tools, software or
                                        services of the Payment Processor selected by us to process
                                        fees, costs, and other amounts as well as transactions on our
                                        behalf; and (iii) to promptly pay all amounts which are due and
                                        payable to us upon demand.</p>
                                    <p>&nbsp;</p>
                                    <p>(d) Refunds. We shall have no obligation to provide you with a
                                        refund of any amounts paid by you in connection with the
                                        Services (e.g. Subscription fees, et cetera). However, we
                                        reserve the right to do so in our sole and absolute discretion.
                                        Please contact us if you have billing questions.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>5</strong>.<strong> Listings and User
                                            Interaction</strong>.</p>
                                    <p>&nbsp;</p>
                                    <p>(a) Our Services allow Landlords to Transmit and post Content on
                                        our Website in the form of a listing in relating to providing
                                        information about selling or renting apartments, houses, and
                                        other residential property (a “Listing”). Each Listing must be
                                        in a form acceptable to us, as determined by us in our sole
                                        discretion. If we determine in our sole discretion that any
                                        Listing violates these Terms or is otherwise objectionable in
                                        any way, each Landlord agrees that we may immediately remove
                                        that Listing without notice and without off-set or compensation
                                        to the Landlord. Provided that a Listing is in a form acceptable
                                        to us, such Listing will be displayed on the Website until the
                                        earlier of: (i) the expiration or termination of the applicable
                                        Landlord’s Subscription, (ii) the expiration or termination of
                                        the applicable Landlord’s Account, or (iii) the expiration or
                                        termination of these Terms.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) These Listings will be made publicly available via our
                                        Services. We shall have no responsibility relating in any way to
                                        transactions between Landlords and Customers. Landlords
                                        acknowledge and agree that the Landlord that Transmits
                                        information about properties, or other products or services
                                        offered by Landlords is responsible for it. Accordingly,
                                        Landlords represent and warrant that any Content you Transmit:
                                        (i) will be in compliance with all applicable laws, tax
                                        requirements, and regulations that may apply to any such
                                        transaction, (ii) will contain only accurate, truthful, and
                                        complete information, and (ii) will not conflict with the rights
                                        of third parties.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) Due to the fact that each Listing and the engagement between
                                        each Customer and Landlord relating to a Listing is an
                                        arrangement between the Customer and the Landlord, each Customer
                                        and the transaction entered into with the Landlord is subject to
                                        the Landlord’s terms, contracts, and policies (collectively,
                                        “Landlord Policies”) in addition to these Terms and some
                                        Landlord. We are not a real estate broker or a co-Landlord in
                                        connection with a Listing or otherwise, and you understand and
                                        agree that by responding to a Listing, the Landlord and each
                                        Customer is entering into a separate agreement with a Landlord.
                                        We have no control over the Landlord Policies, and we make no
                                        representations or warranties with respect to the accuracy,
                                        legality, or enforceability of such Landlord Policies. We are
                                        acting as an independent contractor that facilitates a Landlord
                                        posting a Listing on or Services, and no employment, joint
                                        venture or other agency relationship exists between a Landlord
                                        and us. Landlord Policies may allow Landlords with whom you
                                        purchase products or services to cancel or modify Listings, and
                                        you agree that we shall have no liability for any changes or
                                        costs incurred that may result therefrom. All matters relating
                                        to a Listing (e.g. changes, cancelations, refunds, et cetera)
                                        other than is handled by the Landlord and is subject to the
                                        applicable Landlord Policies. We make no representation whether
                                        or not any Listing is changeable, cancelable, or refundable. You
                                        agree that the Landlord of the products or services in
                                        connection with your Listing has sole liability for those
                                        products or services.</p>
                                    <p>&nbsp;</p>
                                    <p>(d) Venue Only. OUR SERVICES CREATE ONLY A VENUE. LANDLORDS ARE
                                        NOT OUR EMPLOYEES, INDEPENDENT CONTRACTORS, OR AGENTS. WITH THAT
                                        IN MIND, YOU UNDERSTAND AND AGREE THAT: (i) we do not employ,
                                        recommend or endorse any Landlords and have no control over the
                                        acts or omissions of any Landlords in any way using or having
                                        used the Services, on or off our Website; (ii) we are not
                                        responsible for any Landlord’s compliance or non-compliance with
                                        applicable laws in connection with any transaction involving a
                                        Landlord; (iii) we make no representations or warranties about
                                        the quality or legality of the services offered by a Landlord in
                                        connection with a Listing or about interactions or dealings with
                                        others; (iv) we are not responsible for the performance or
                                        conduct of any Landlords, any other User, or other third parties
                                        in any way, on or off the Website; (v) we do not warrant the
                                        safety or reliability of any boats, equipment, vehicles, or
                                        other materials of Landlords; and (iv) we are under no
                                        obligation to become involved in disputes between any Users
                                        (whether or not such Users are Landlords). You are responsible
                                        for selecting a suitable Landlord. In doing so, you should
                                        evaluate all information you deem relevant, including but not
                                        limited to the Landlord’s background, training, experience, and
                                        reputation.</p>
                                    <p>&nbsp;</p>
                                    <p>(e) No Verification of Users. We do not screen Users or conduct
                                        any kind of identity, or criminal records checks. As such, you
                                        should exercise caution and perform your own screening before
                                        connecting with a User through the Services, meeting anyone, or
                                        traveling to a location in connection with a Listing or
                                        otherwise. We expressly disclaim, and you expressly release us
                                        from, any and all liability whatsoever for any controversies,
                                        claims, suits, injuries, loss, harm and/or damages arising from
                                        and/or in any way related to the Services or your interactions
                                        or dealings with Users, on or off our Website. By using the
                                        Services, you acknowledge that you are solely responsible for
                                        such use and the connections you make and that ALL USE OF OUR
                                        SERVICES IS AT YOUR SOLE RISK. You agree that only Users provide
                                        and are solely responsible for any claims that you may have
                                        against any of them. Although we have no obligation to verify
                                        any information provided by a User in connection with our
                                        Services, we reserve the right to do so in our sole discretion.
                                        Landlords hereby authorize us to verify the representations and
                                        warranties you make or the other information provided to us in
                                        connection with the Services. Landlords agree to provide us with
                                        any and all information and/or verification we may request as we
                                        deem appropriate in our sole discretion.</p>
                                    <p>&nbsp;</p>
                                    <p>(f) The Services may allow Users to Transmit review and Content
                                        regarding other Users (each a “Review”). All Reviews Transmitted
                                        by you are deemed “User Content” (as defined in Section 7(c)(i))
                                        and are subject to the provisions of these Terms. In order to
                                        Transmit Reviews, you must: (i) be 18 years of age or older,
                                        (ii) interacted with the User which such you are providing the
                                        Review in connection with, and (iii) comply with the applicable
                                        portions of these Terms. In Transmitting any Review, each User
                                        represents and warrants that such User does and shall comply
                                        with subsections (i) through (iii) above. You further agree that
                                        any Review Transmitted by you shall not: link to websites or
                                        other information that is not relevant to the Review, as
                                        determined by us; or contain spam or other forms of hyperlink
                                        marketing. The Reviews on our Services do not reflect the views
                                        of Roundcomb. We strive to maintain a high level of integrity
                                        with Reviews. You agree that any Review that we determine, in
                                        our sole discretion, is disingenuous or objectionable may be
                                        removed by us at any time without prior notice.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>6</strong>.<strong> Landlord Documents</strong>. We may
                                        allow Landlord’s to upload documents to be used in connection
                                        with the Services (“Landlord Documents”. Any such Landlord
                                        Documents shall be deemed “User Content” (as defined in Section
                                        7(c)(i)) and are subject to the provisions of these Terms.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>7</strong>.<strong> Content</strong>.</p>
                                    <p>&nbsp;</p>
                                    <p>(a) By Us. All Content on the Services, or obtained from a Linked
                                        Site are provided to you ‘AS IS’, ‘AS AVAILABLE’ and ‘WITH ALL
                                        FAULTS’. We provide the Services for informational purposes only
                                        and any statements on the Website are opinions of the author. We
                                        are not real estate brokers, real estate agents, architects,
                                        attorneys, paralegals, accountants, or tax specialists, or any
                                        other sort of licensed professional. OUR SERVICES ARE NOT TO BE
                                        CONSTRUED AS REAL ESTATE, REAL ESTATE BROKER, INVESTMENT, LEGAL,
                                        EMPLOYMENT, ACCOUNTING, TAX, OR OTHER PROFESSIONAL ADVICE. OUR
                                        SERVICES ARE A RESOURCE ONLY. As such, we expressly disclaim all
                                        liability related to the accuracy or reliability of any opinion,
                                        guidance, or Content Transmitted by us or available through our
                                        Services or reliance on any of the aforementioned. WE ENCOURAGE
                                        YOU TO SEEK THE ADVICE OF THE APPROPRIATE LICENSED PROFESSIONAL
                                        IN YOUR JURISDICTION AND YOU ASSUME THE RISKS ASSOCIATED WITH
                                        ANY FAILURE TO DO SO. We will do our best to provide top-quality
                                        Services to you. However, the Content published through the
                                        Services may also include inaccuracies or typographical errors.
                                        We do not warrant or represent that the Content available
                                        through our Services is complete or up-to-date.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) Linked Sites.</p>
                                    <p>&nbsp;</p>
                                    <p>(i) The Services may link to other sites by allowing you to leave
                                        the Services to access third party material or by bringing third
                                        party material into the Services (a “Linked Site”). The
                                        appearance, availability, or your use of URLs or hyperlinks to
                                        Linked Sites referenced or included anywhere on the Services or
                                        any other form of link or re-direction of your connection to,
                                        with or through the Services, does not create any obligation,
                                        responsibility or liability on the part of Roundcomb or its
                                        Affiliates, other than as provided herein. We have no discretion
                                        to alter, update, or control the Content on a Linked Site. In
                                        addition, we do not verify or have any responsibility for, any
                                        such Linked Sites, their business practices (including their
                                        privacy policies), or any goods or services associated with or
                                        obtained in connection with any Linked Site. If any Linked Site
                                        obtains or collects information from you, in no event shall we
                                        have any responsibility or liability relating to how any such
                                        information is collected or used. Please read our Privacy Policy
                                        for more information regarding advertising and linking.</p>
                                    <p>&nbsp;</p>
                                    <p>(ii) Third Party Products and Services. When you use the Services
                                        to purchase products or services from a Linked Site, you are
                                        purchasing that product or service directly from the third party
                                        Linked Site. Your order is placed with, filled by, and shipped
                                        by that third party Linked Site. We have no involvement in any
                                        shipment, fulfillment, returns, or refunds associated with any
                                        products or services that you purchase from a Linked Site or
                                        third party. You understand that you must contact the third
                                        party Linked Site directly for inquiries related to your
                                        purchase, including but not limited to: returns, shipping,
                                        customer service, refunds, or general information. By using the
                                        Services, you expressly represent and warrant that you will
                                        abide by and will not violate any policies, rules, terms, or
                                        conditions of that third party Linked Site.</p>
                                    <p>&nbsp;</p>
                                    <p>(iii) Ads and Sponsored Content. Our Services may display third
                                        party advertisements, promotional material, and Linked Sites. We
                                        may be compensated for clicks or purchases in connection with
                                        these third party advertisements, promotional material, and
                                        Linked Sites. We may also earn revenue from Content that is
                                        sponsored by advertisers or other third parties. Sponsored
                                        Content man not always be identified as such (e.g., sponsored
                                        post, paid distribution, or similar term). You agree the manner,
                                        mode and extent of such advertising and promotions are subject
                                        to change without specific notice to you. We do not endorse any
                                        third party advertisement or promotional material associated
                                        with third party advertisements in any manner and gives no
                                        warranty or other assurance in relation to any products or
                                        services featured in such advertisements and promotional
                                        materials. In the event you desire to advertise on our Services,
                                        either: we will enter a separate ancillary agreement, purchase
                                        order, invoice, and/or order form with you, or you and any such
                                        advertising on our Services will be subject to our additional
                                        advertising Policies, as determined by us, each of which are
                                        hereby incorporated herein by reference. In the event of a
                                        conflict between these Terms and any ancillary agreement to you
                                        advertising on our Services, relating to the provisions of the
                                        ancillary agreement or our additional advertising Policies (as
                                        applicable) shall control.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) Transmitted by You.<strong> </strong></p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>(i) You are solely responsible for and retain all rights in the
                                        Content that you Transmit by way of the Services and/or Transmit
                                        to us by way of the Services or otherwise, including but not
                                        limited to each Listing posted by you (collectively, “User
                                        Content”). You represent and warrant to us that you shall not:
                                        Transmit User Content that: (A) may create a risk of harm, loss,
                                        physical or mental injury, emotional distress, death,
                                        disability, or physical or mental illness to you, to any other
                                        person, (B) may cause loss or damage to any person or property;
                                        (C) involves or contributes to a violation of criminal or civil
                                        law; (D) contains Content that we deem to be unlawful, harmful,
                                        abusive, racially or ethnically offensive, defamatory,
                                        infringing, invasive of personal privacy or publicity rights,
                                        harassing, humiliating to other people (publicly or otherwise),
                                        libelous, threatening, profane, obscene, sexually explicit, or
                                        otherwise objectionable; (E) contains Content that you do not
                                        have a right to disclose under any law or under contractual or
                                        fiduciary relationships; (F) infringes on the intellectual
                                        property rights of others; or (G) violates any provision of the
                                        use restrictions in Section 10. WE ARE NOT RESPONSIBLE FOR
                                        ANOTHER’S MISUSE OR MISAPPROPRIATION OF ANY CONTENT OR
                                        INFORMATION YOU TRANSMIT USING THE SERVICES.</p>
                                    <p>&nbsp;</p>
                                    <p>(ii) You retain ownership of any copyrights relating to your User
                                        Content. However, you also agree that by Transmitting User
                                        Content to us and/or Transmitting User Content anywhere within,
                                        on, or using the Services, you hereby grant to us and represent
                                        and warrant to us that you have all rights necessary to grant to
                                        us a worldwide, irrevocable, perpetual, non-exclusive,
                                        cost-free, royalty-free license to use, copy, sell, rent,
                                        license, sublicense, display, publically perform, create
                                        derivative works of, distribute, store, archive, transform,
                                        edit, alter, distort, modify, add to, subtract from, enhance,
                                        broadcast, telecast, duplicate, distribute, and/or otherwise
                                        exploit your User Content, each as determined exclusively by us,
                                        in all forms of media and forms of exploitation, now known or
                                        hereafter created including but not limited to, one or more
                                        social media accounts including but not limited to, facebook.com
                                        (“Social Media Profiles”), websites, film, television, radio,
                                        and/or print, each as determined exclusively by us. In order to
                                        further effect the rights and license that you grant to us
                                        regarding your User Content, you also hereby grant to us the
                                        unconditional, perpetual, irrevocable right to use and exploit
                                        your name, persona, image, photograph, and likeness that you
                                        provide in connection with any User Content, without any
                                        obligation or compensation to you. To the extent any ‘moral
                                        rights’, ‘ancillary rights’, or similar rights in or to the User
                                        Content exists and are not licensed to us hereby, you agree not
                                        to enforce any such rights and you shall procure the same
                                        agreement not to enforce from any others who may possess such
                                        rights. Without limiting the scope of the license granted to us
                                        by you hereunder or any future grant of rights, consents,
                                        agreements, assignments, and waivers you may make with respect
                                        to User Content, and to the extent allowed by applicable law,
                                        you hereby ratify any prior grant of rights, consents,
                                        agreements, assignments and waivers made by you with respect to
                                        your User Content. You further acknowledge and agree that no
                                        compensation will be paid with respect to the use of your User
                                        Content or any of the rights granted to us in these Terms. You
                                        agree that the license granted by you in this Section 7(c)(ii)
                                        shall be binding upon you, your heirs, legal representatives,
                                        assigns, transferees and successors in interest and shall
                                        survive any termination of these Terms, of your Account, and/or
                                        your license to use and access the Services. You agree that any
                                        User Content you Transmit is not being disclosed in confidence
                                        or trust and that no confidential or fiduciary relationship is
                                        intended or created between you and us in any way. For clarity,
                                        and without altering the forgoing, generally, while you retain
                                        the copyrights in your User Content, we can do anything we
                                        desire with your User Content just as if we owned the User
                                        Content and any copyrights therein.</p>
                                    <p>&nbsp;</p>
                                    <p>(iii) You represent and warrant that you own or otherwise possess
                                        all necessary rights with respect to your User Content, and that
                                        your User Content does not and will not infringe,
                                        misappropriate, use or disclose without authorization or
                                        otherwise violate rights of any third party, including but not
                                        limited to intellectual property rights and/or trade secret
                                        rights.</p>
                                    <p>&nbsp;</p>
                                    <p>(iv) You agree that we may but are not obligated to: filter any
                                        Content on the Website (e.g. a Review or otherwise) in any
                                        manner we determine, including but not limited to, by deleting
                                        or replacing expletives or other harmful or offensive language;
                                        refusing to display any Content; removing Content from the
                                        Services for any reason or no reason, as determined by us;
                                        and/or disclosing any Content and the circumstances surrounding
                                        the use thereof, to any third party for any reason or no reason,
                                        as determined by us. We are not responsible for, and will have
                                        no liability for, the removal or non-removal of any Content from
                                        the Services.</p>
                                    <p>&nbsp;</p>
                                    <p>(v) You agree and understand that you may be held legally
                                        responsible for damages suffered by other Users or third parties
                                        as the result of your remarks, information, feedback or other
                                        Content Transmitted on the Services that is deemed defamatory or
                                        otherwise legally actionable. Under the Federal Communications
                                        Decency Act of 1996, Roundcomb is not legally responsible, nor
                                        can it be held liable for damages of any kind, arising out of or
                                        in connection to any defamatory or otherwise legally actionable
                                        remarks, information, feedback or other Content made available
                                        on the Services.</p>
                                    <p>&nbsp;</p>
                                    <p>(d) Transmitted by Users or Others.<strong> </strong>We do not
                                        endorse and are not responsible for (i) the Content provided by
                                        other Users, (ii) the accuracy or reliability of any opinion,
                                        advice, statement, or Content made through the Services, (iii)
                                        any Content provided on Linked Sites, or (iv) the capabilities
                                        or reliability of any products or services obtained from a
                                        Linked Site. There are risks involved with relying on
                                        information on the Services, and you expressly assume those
                                        risks when using the Services. Under no circumstance will we be
                                        liable for any loss or damage caused by your reliance on any
                                        Content, products, other information, or services obtained
                                        through the Website or a Linked Site. Further, you agree to
                                        indemnify, hold harmless, and defend us from any liability
                                        and/or damages relating to any Content Transmitted by you or by
                                        a third person using your Account.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>8</strong>.<strong> Privacy Policy</strong>.<strong>
                                        </strong>Privacy Policy. By using the Services, you agree to the
                                        provisions of our Privacy Policy, which is hereby incorporated
                                        by reference. You further agree that we may disclose Personal
                                        Data (as defined in our Privacy Policy) according to our Privacy
                                        Policy, as determined by us. Please see our Privacy Policy for
                                        more information.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>9</strong>.<strong> Trademarks and Intellectual
                                            Property</strong>.</p>
                                    <p>&nbsp;</p>
                                    <p>(a) Trademarks. ROUNDCOMB, ROUNDCOMB.COM, and all other graphics,
                                        logos, page headers, button icons, scripts, service names and
                                        other Content that we use, manage or control are trademarks,
                                        registered trademarks or trade dress of ours or our
                                        subsidiaries, officers, employees, independent contractors,
                                        suppliers, representatives, licensors, licensees, successors,
                                        assigns, agents, partners, or other affiliate (collectively
                                        “Affiliates”) in the United States or other countries or both.
                                        No one may use these trademarks or trade dress in connection
                                        with any product or service that is not our product or service
                                        without our express written permission. All other trademarks
                                        that appear on the Services are the property of their respective
                                        owners, who may or may not be affiliated with, connected to or
                                        sponsored by us or any of our Affiliates.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) Copyright. Except in the case of Content under license to us,
                                        we claim a copyright, and all copyright protection afforded,
                                        under international, United States and the State of Maryland
                                        laws to all text, graphics, logos, button icons, images, audio
                                        clips, digital downloads, data compilations, software (ours or
                                        our software suppliers), and all other Content on the Services.
                                        The compilation of all Content on the Services is our exclusive
                                        property, and it is similarly protected. We also claim a
                                        copyright, and all copyright protection afforded, under
                                        international, United States and the laws of the State of
                                        Maryland to all material described in the trademarks section
                                        above. Your access to all information and Content located on the
                                        Services is strictly permitted through the license granted to
                                        you under these Terms. Except for the license granted to you in
                                        Section 2 of these Terms and for the licenses granted to us in
                                        these Terms, all rights, title and interest in Content, in all
                                        languages, formats and media throughout the world, including all
                                        copyrights, are and will continue to be the exclusive property
                                        of ours and other parties. Except as permitted by these Terms,
                                        you are prohibited from modifying, copying, distributing,
                                        displaying, publishing, selling, licensing, creating derivative
                                        works, or otherwise exploiting any Content available on or
                                        through the Services without our prior written permission, or in
                                        the case of Content owned by a third party, without first
                                        receiving permission from the owner of that Content. You may not
                                        alter or remove any trademark, copyright or other notice from
                                        copies of the Content.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) Infringement Claims.<strong> </strong>We respect the
                                        intellectual property of others and ask that Users do the same.
                                        In connection with the Services, we have adopted and implemented
                                        a Digital Millennium Copyright Act (“DMCA”) policy respecting
                                        intellectual property that provides for the removal of any
                                        infringing or unauthorized materials and for the termination of
                                        a User’s ability to use our Services, in appropriate
                                        circumstances, if we determine that User is infringing on the
                                        intellectual property rights of others. If you believe that a
                                        User is, through the use of the Services, unlawfully infringing
                                        by submitting unauthorized Content, and wish to have the
                                        allegedly infringing or unauthorized material removed, the
                                        following information in the form of a written notification
                                        (pursuant to 17 U.S.C. § 512) must be provided to our designated
                                        copyright agent (“Designated Agent”): your physical or
                                        electronic signature; identification of the works or rights that
                                        you claim to have been infringed; identification of the Content
                                        on the Services that you claim is infringing and that you
                                        request us to remove; sufficient information to permit us to
                                        locate such Content; your address, telephone number, and e-mail
                                        address; a statement that you have a good faith belief that use
                                        of the objectionable Content is not authorized by the copyright
                                        or other rights owner, its agent, or the law; and a statement
                                        that the information in the notification is accurate, and under
                                        penalty of perjury, that you are either the owner of the
                                        copyright or other right that has allegedly been infringed or
                                        violated or that you are authorized to act on behalf of the
                                        copyright or other rights owner. Note that, pursuant to 17
                                        U.S.C. § 512, any misrepresentation of material fact in a
                                        written notification automatically subjects the complaining
                                        party to liability for any damages, costs and attorney’s fees
                                        incurred by us in connection with the written notification and
                                        allegation of copyright infringement.</p>
                                    <p>&nbsp;</p>
                                    <p>Our Designated Agent is:</p>
                                    <p>&nbsp;</p>
                                    <p>Kwangjae Lee</p>
                                    <p>11847 State Route 108</p>
                                    <p>Clarksville, MD 21029</p>
                                    <p>240-447-2441</p>
                                    <p>dailychoices@lean35.com</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>10</strong>. <strong>Use Restrictions</strong>. You may
                                        not use or plan, encourage or help others to use the Services
                                        for any purpose or in any manner that is prohibited by these
                                        Terms or by applicable law. In using the Services, you agree at
                                        all times that you shall not: (a) infringe on the copyrights or
                                        other intellectual property rights of Roundcomb, a User, or a
                                        third party (b) copy, distribute, or modify any part of the
                                        Services without our prior written authorization; (c) Transmit
                                        inappropriate, inaccurate, false, misleading, or objectionable
                                        Content to the Services, as determined by us; (d) Transmit any
                                        medical records or other health information in violation of The
                                        Health Insurance Portability and Accountability Act of 1996, as
                                        amended (“HIPAA”), any HIPAA enforcement rule, or any related
                                        state law concerning the dissemination of medical information;
                                        (e) Transmit any Content which contains software viruses, or
                                        other harmful computer code, files or programs; (f) Transmit
                                        Content that falsely states, impersonates or otherwise
                                        misrepresents your identity, including but not limited to the
                                        use of a pseudonym, or misrepresenting your current or previous
                                        positions and qualifications, or your affiliations with a person
                                        or entity, past or present; (g) make threats or use profanity;
                                        (h) harass, stalk or intimidate other Users; (i) manipulate or
                                        exclude identifiers in order to disguise the origin of any
                                        Content; (j) disrupt the networks connected to the Services,
                                        including but not limited to by: attempting to probe, scan or
                                        test the vulnerability of the Services, attempting to breach
                                        security or authentication measures without proper
                                        authorization, or attempting to interfere with the Services or a
                                        User, by means such as overloading, ‘flooding’, ‘mailbombing’ or
                                        ‘crashing.’; (k) circumvent, disable or otherwise interfere with
                                        security-related features of the Services or features that
                                        prevent or restrict use or copying of any Content or that
                                        enforce limitations on use of the Services; (l) collect Content,
                                        personally identifying information, and/or other information
                                        from the Services, or otherwise access the Services, by using
                                        any automated means, including but not limited to, ‘robots’,
                                        ‘spiders’, ‘scrapers’ and ‘offline readers’, without our prior
                                        written approval which we may withhold in our discretion; (m)
                                        modify, translate, reverse engineer, decompile, disassemble,
                                        create derivative works based on, sublicense, sell, or
                                        distribute the Services; (n) rent or lease any rights in the
                                        Services in any form to any third party or make the Services
                                        available or accessible to third parties; (o) use any
                                        communications systems provided by the Services to send
                                        unsolicited or unauthorized commercial communications, including
                                        but not limited to by email, SMS, MMS, or any other means; (p)
                                        remove, alter or obscure any proprietary notice or
                                        identification, including copyright, trademark, patent or other
                                        notices displayed on the Services; (q) mislead or attempt to
                                        mislead or defraud or attempt to defraud or conceal any
                                        information relating to Content or other information that you
                                        provide to us; (r) link, deep link, ‘frame’ or ‘mirror’ any part
                                        of the Services without our prior consent; or s use the Services
                                        to violate any applicable laws, rules or regulations, or for any
                                        unlawful, harmful, or inappropriate purpose, or in any manner
                                        that breaches these Terms or is otherwise objectionable, as
                                        determined by us in our sole discretion.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>11</strong>. <strong>Termination</strong>,<strong>
                                            Restriction, and Suspension</strong>.</p>
                                    <p>&nbsp;</p>
                                    <p>(a) Termination By You. Landlords may cancel their Account and/or
                                        Subscription at any time for any reason or no reason by
                                        providing written notice (email to info@roundcomb.com is
                                        acceptable). All Users other than Landlords may cancel their
                                        Account at any time for any reason or no reason by providing
                                        written notice to us reason (email to info@roundcomb.com is
                                        acceptable).Upon cancellation of your Account, access to certain
                                        features of the Services may be restricted. Termination of your
                                        Account will be effective within a reasonable time after we
                                        receive notification of your desire to cancel, as determined by
                                        us.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) By us. We retain the right to terminate, restrict, or suspend
                                        these Terms, your Account, your Subscription, and/or your
                                        license to access or use the Services at any time in our
                                        absolute and sole discretion, without prior notice, in the event
                                        we (i) have reason to believe you have breached any portion of
                                        these Terms, our Privacy Policy, or other Polices, as determined
                                        by us, or (ii) determine in our sole discretion that your use of
                                        the Services is in any way objectionable.</p>
                                    <p>&nbsp;</p>
                                    <p>(c)<strong> </strong>After Termination. Upon termination of your
                                        Account, Subscription and/or license to access or use the
                                        Services for any reason, you agree that we may take any measures
                                        we deem necessary to prevent you from accessing the Services,
                                        including by blocking your IP address. You agree that after
                                        termination of your access to the Services, we are not obliged
                                        to retain or provide to you any Content or Personal Data (as
                                        defined in our Privacy Policy) which was collected by us, but we
                                        may elect to do so in our sole discretion, for a duration
                                        determined by us.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>12</strong>.<strong>
                                        </strong><strong>DISCLAIMERS</strong>.</p>
                                    <p>&nbsp;</p>
                                    <p>(a) THE WEBSITE, CONTENT, AND SERVICES ARE PROVIDED ‘AS IS’, ‘AS
                                        AVAILABLE’ AND ‘WITH ALL FAULTS’ WITHOUT WARRANTY OF ANY KIND,
                                        EITHER EXPRESS OR IMPLIED. YOU ASSUME THE RISK OF ANY AND ALL
                                        DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SERVICES,
                                        INCLUDING BUT NOT LIMITED TO PHYSICAL INJURY OR DEATH AS WELL AS
                                        DAMAGES TO PERSONAL PROPERTY. WITHOUT LIMITING THE FOREGOING,
                                        WITH RESPECT TO THE WEBSITE, AND/OR THE SERVICES, ROUNDCOMB
                                        EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY,
                                        ACCURACY, SECURITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
                                        ENJOYMENT, QUIET TITLE, NON-INFRINGEMENT, AND ANY WARRANTIES
                                        ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. ROUNDCOMB
                                        MAKES NO WARRANTY THAT THE WEBSITE AND/OR CONTENT WILL MEET YOUR
                                        NEEDS, EXPECTATIONS, BE TO YOUR SATISFACTION, OR BE AVAILABLE ON
                                        AN UNINTERRUPTED, SECURE, OR ERROR-FREE, BUG-FREE, OR
                                        MALWARE-FREE BASIS. ROUNDCOMB MAKES NO WARRANTY REGARDING THE
                                        QUALITY OF THE WEBSITE OR CONTENT, OR THE ACCURACY, TIMELINESS,
                                        TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT
                                        OBTAINED THROUGH THE WEBSITE. NO ADVICE OR INFORMATION, WHETHER
                                        ORAL OR WRITTEN, OBTAINED FROM ROUNDCOMB OR THROUGH THE WEBSITE
                                        OR CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                                        THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO
                                        THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS
                                        FOR ANY SUCH PROHIBITIONS.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL
                                        COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS AND WITH OTHER
                                        PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF
                                        YOUR USE OF THE SERVICES. WE EXPLICITLY DISCLAIM ALL LIABILITY
                                        FOR ANY ACT OR OMISSION OF ANY USER OR OTHER THIRD PARTY.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) YOU UNDERSTAND AND AGREE THAT ENGAGING IN THE ACTIVITIES
                                        RELATED TO THE SERVICES PROVIDED BY LANDLORDS MAY RESULT IN
                                        PHYSICAL INJURY OR DEATH. YOU UNDERSTAND AND AGREE THAT YOU
                                        ASSUME ALL RISK IN UNDERTAKING ANY AND ALL ACTIVITIES RELATED TO
                                        A LISTING OR THE SERVICES PROVIDED BY A LANDLORD.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>13</strong>.<strong> Release and Waiver of
                                            Claims</strong>.<strong> </strong>To the maximum extent
                                        permitted by applicable law, YOU ON BEHALF OF YOURSELF OR THE
                                        ENTITY THAT YOU REPRESENT, YOUR PERSONAL REPRESENTATIVES AND
                                        YOUR HEIRS, HEREBY VOLUNTARILY AGREE TO RELEASE, WAIVE, AND
                                        DISCHARGE all claims, actions, demands, suits, or proceedings
                                        (“Claims”) against US and our AFFILIATES, including any and all
                                        liability for actual and/or consequential damages, costs and
                                        expenses (including litigation costs and attorneys’ fees) of
                                        every kind and nature arising from or in any way related to: (A)
                                        THE SERVICES or THESE TERMS, (B) CONTENT ANd/OR YOUR USER
                                        CONTENT, (c) PRODUCTS, PROPERTY, OR SERVICES PURCHASED OR LEASED
                                        BY YOU IN CONNECTION WITH A LISTING, (d) PERSONAL INJURY OR
                                        DEATH RELATING TO INTERACTIONS WITH USERS AND/OR THIRD PARTIES
                                        WITH WHOM YOU COMMUNICATED WITH AS A RESULT OF USING THE
                                        SERVICES (IN CONNECTION with A LISTING OR OTHERWISE), (e) any
                                        inaccuracy, untimeliness or incompleteness of a USER’S
                                        representations OR WARRANTIES, and/or (f) any inaccuracy,
                                        untimeliness, or incompleteness of ANY AND ALL CONTENT obtained
                                        or accessed by or through the Services. FURTHER, if YOU ARE A
                                        RESIDENT OF THE STATE OF CALIFORNIA, you waive your rights under
                                        California Civil Code Section 1542, which states, “A general
                                        release does not extend to claims which the creditor does not
                                        know or suspect to exist in his or her favor at the time of
                                        executing the release, which if known by him or her must have
                                        materially affected his or her settlement with the debtor.” You
                                        understand that any fact relating to any matter covered by THESE
                                        TERMS may be found to be other than now believed to be true, and
                                        accept and assume the risk of such possible differences in fact.
                                        In addition, you expressly waive and relinquish any and all
                                        rights which you may have had under any other state or federal
                                        statute or common law principle of similar effect, to the
                                        fullest extent permitted by law.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>14</strong>.<strong> </strong><strong>Limitation of
                                            Liability</strong>. TO THE EXTENT NOT PROHIBITED BY LAW, IN
                                        NO EVENT SHALL WE BE LIABLE FOR PERSONAL INJURY RELATED TO OR
                                        RESULTING FROM ANY ASPECT OF THE SERVICES, OR FOR ANY
                                        INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES
                                        WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                                        PROFITS, LOSS OF DATA, OR ANY OTHER DAMAGES OR LOSSES, ARISING
                                        OUT OF OR RELATED TO (A) THESE TERMS, (B) CONTENT ANd/OR YOUR
                                        USER CONTENT, (C) PRODUCTS, Property, or SERVICES PURCHASED OR
                                        LEASED BY YOU IN CONNECTION WITH A LISTING, (D) PERSONAL INJURY
                                        OR DEATH RELATING TO INTERACTIONS WITH USERS AND/OR THIRD
                                        PARTIES WITH WHOM YOU COMMUNICATED WITH AS A RESULT OF USING THE
                                        SERVICES (IN CONNECTION with A LISTING OR OTHERWISE), and/or (E)
                                        ANY ASPECT OF OUR SERVICES, HOWEVER CAUSED, REGARDLESS OF THE
                                        THEORY OF LIABILITY (BREACH OF CONTRACT, A BREACH OF WARRANTY,
                                        NEGLIGENCE, PRODUCTS LIABILITY, STRICT LIABILITY, OR OTHERWISE)
                                        AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                        DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
                                        LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL
                                        DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event
                                        shall our total liability to you for all damages exceed an
                                        amount equal to the greater of: (i) the sums paid by you to us
                                        in connection with the Services, or (ii) $125. The foregoing
                                        limitations will apply even if the above stated remedy fails of
                                        its essential purpose.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>15</strong>.<strong> Dispute Resolution</strong>.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>(a) In the event that any dispute arises with respect to the
                                        Services, these Terms, or any of our Policies, upon our election
                                        in our sole discretion, such dispute shall be resolved by
                                        binding arbitration in accordance with the Commercial Dispute
                                        Resolution Procedures and the Supplementary Procedures for
                                        Consumer Related Disputes of the American Arbitration
                                        Association, in Prince George’s County, Maryland, Maryland. In
                                        the event we elect not to require that it be submitted to
                                        binding arbitration as described above, any such dispute shall
                                        nevertheless be litigated by you and us in the state courts
                                        located in Prince George’s County, Maryland, Maryland or in the
                                        U.S. District Court for the District of Maryland, as the case
                                        may be. You shall be liable for and shall reimburse us for our
                                        expenses and fees, including attorneys’ fees, in the event any
                                        arbitration or litigation arises between us and you. By using
                                        the Services, you irrevocably agree and consent to be bound to
                                        personal jurisdiction of and venue selection in the state courts
                                        located in Prince George’s County, Maryland, Maryland or in the
                                        U.S. District Court for the District of Maryland as the case may
                                        be, whether either arbitration or litigation arises between us
                                        and you. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE
                                        ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN
                                        ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH
                                        CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
                                    <p>&nbsp;</p>
                                    <p>(b) YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST US ONLY ON AN
                                        INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS USER IN ANY
                                        PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS
                                        WE AGREE OTHERWISE, THE DECISION-MAKER MAY NOT CONSOLIDATE OR
                                        JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT
                                        OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
                                        REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE DECISION-MAKER
                                        MAY AWARD RELIEF ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
                                        NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIMS. ANY RELIEF
                                        AWARDED CANNOT AFFECT OTHER USERS.</p>
                                    <p>&nbsp;</p>
                                    <p>(c) You agree that irreparable harm to us would occur in the
                                        event that any of the provisions of these Terms were not
                                        performed fully by you or were otherwise breached by you, and
                                        that money damages are an inadequate remedy for breach of the
                                        Terms because of the difficulty of ascertaining and quantifying
                                        the amount of damage that will be suffered by us in the event
                                        that these Terms are not performed in accordance with its
                                        provisions or is otherwise breached. It is accordingly hereby
                                        acknowledged that, notwithstanding any provision of this Section
                                        15, we shall be entitled to petition the courts mentioned in
                                        Section 15(a) for an injunction or injunctions to restrain,
                                        enjoin and prevent a failure to perform these Terms by you,
                                        without positing bond or other security, and to enforce
                                        specifically such provisions of these Terms.</p>
                                    <p>&nbsp;</p>
                                    <p>(d) Dispute Resolution Severability. If a court decides that any
                                        term or provision relating to our ability to submit any
                                        above-mentioned dispute to arbitration or to the above class
                                        action wavier according to this Section 15, the parties agree to
                                        litigate any such dispute according to Section 15(a) above and
                                        to replace any other such terms or provisions of Section 15(a)
                                        or Section 15(b) with a term or provision that is valid and
                                        enforceable and that comes closest to expressing the intention
                                        of the invalid or unenforceable term or provision, and this
                                        Section 15 shall be enforceable as so modified. In the event of
                                        any such severing, the remainder of these Terms will continue to
                                        apply.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>16</strong>.<strong> Indemnification</strong>. You agree
                                        to indemnify, defend, and hold harmless us and our Affiliates
                                        from and against any and all Claims, losses, expenses, damages
                                        and costs (including, but not limited to, direct, incidental,
                                        consequential, exemplary and indirect damages), and reasonable
                                        attorneys’ fees, resulting from or arising out of, under, or
                                        relating to: your use, misuse, or inability to use the Services;
                                        any infringement of a third party’s rights (including but not
                                        limited to intellectual property rights); a breach of a
                                        representation or warranty made by you; your User Content; any
                                        claims made relating to products, property, or services offered,
                                        leased, or sold by a Landlord using the Services; your use of
                                        Landlord Documents; a Listing; any defamatory, offensive,
                                        fraudulent, or illegal use of the Services by you; any improper
                                        disclosure of Content by you; and any violation by you of these
                                        Terms, our Privacy Policy, any of our other Policies.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>17</strong>. <strong>Survival</strong>. All provisions of
                                        these Terms which must or which in accordance with its terms is
                                        intended to survive the earlier termination or expiration of
                                        these Terms, your Account, your Subscription, and/or any license
                                        to access or use any portion of the Services granted by these
                                        Terms shall survive the earlier termination or expiration
                                        thereof..</p>
                                    <p>&nbsp;</p>
                                    <p><strong>18</strong>.<strong> No Implied Waiver;
                                            Assignment</strong>. Our failure to assert any right or
                                        provision under these Terms shall not constitute a waiver of
                                        such right or provision. These Terms, and any rights and
                                        licenses granted hereunder, may not be transferred or assigned
                                        by you, but may be assigned by us as determined by us.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>19</strong>. <strong>Severability</strong>. The
                                        representations and warranties and/or covenants set forth herein
                                        are each to be construed as a separate agreement, independent of
                                        any other provisions of these Terms. Further, the invalidity or
                                        unenforceability of any provision, word, phrase, clause,
                                        sentence, paragraph or section of these Terms shall in no way
                                        affect the validity or enforceability of any other provision,
                                        word, phrase, clause, sentence, paragraph or section of these
                                        Terms, and any such invalid or unenforceable provision that is
                                        overbroad shall be deemed narrowed to the broadest term
                                        permitted by applicable law and shall be enforced as narrowed.
                                        If one or more of the provisions in these Terms is deemed
                                        invalid or unenforceable, then the remaining provisions will
                                        continue in full force and effect.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>20</strong>.<strong> Our Relationship with
                                            Users</strong>. We are an independent contractor only. These
                                        terms do not create any employer-employee, agency, joint
                                        venture, or partnership relationship.&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><strong>21</strong>.<strong> Third Parties</strong>. From time to
                                        time, we may engage third parties or Affiliates to assist us in
                                        providing certain aspects of the Services, including but not
                                        limited to marketing functions. You agree that we may engage
                                        such third parties in providing Services to you, as determined
                                        by us.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>22</strong>. <strong>Entire Agreement;
                                            Modification</strong>. These Terms together with our Polices
                                        any other document referenced herein, constitutes the entire
                                        understanding between us and you with respect to the subject
                                        matter hereof. You agree that we may amend, modify, or alter
                                        these Terms and/or our Polices at any time in our sole
                                        discretion. We will notify you about changes to these Terms by
                                        placing the updated Terms on the Website. You agree that your
                                        use of the Services after such notification will constitute
                                        acceptance by you of such changes to the Terms.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>23</strong>.<strong> Construction</strong>. Headings in
                                        these Terms are for convenience only, and shall not govern the
                                        meaning or interpretation of any provision of these Terms.
                                        Whenever the context requires, all words, including but not
                                        limited to defined capitalized terms, will include the
                                        masculine, feminine, and neuter, and each word will include the
                                        singular form, plural form, and other conjugations of that word.
                                        The word “or” connotes any combination of all or any of the
                                        items listed (e.g. in the same manner as “and/or”). The word
                                        “including” and its syntactic variants mean “includes, but is
                                        not limited to” and corresponding syntactic variant expressions.
                                    </p>
                                    <p>&nbsp;</p>
                                    <p><strong>24</strong>.<strong> Governing Law; English
                                            Language</strong>.<strong> </strong>You agree that: (a) the
                                        Services shall be deemed solely based in Prince George’s County,
                                        Maryland, Maryland (where we have our headquarters), and (b) the
                                        Services shall be deemed passive which does not give rise to
                                        personal jurisdiction over us, either specific or general, in
                                        jurisdictions other than Prince George’s County, Maryland,
                                        Maryland. These Terms, our Privacy Policy, and other Policies
                                        are governed by the laws of the State of Maryland and of the
                                        United States of America, and without regard to conflicts of law
                                        principles. In the event of a conflict between these Terms and a
                                        foreign language version of the Terms, the English language
                                        version of these Terms shall govern. All disputes, claims and
                                        causes of action (and related proceedings) will be communicated
                                        in English.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>25</strong>.<strong> Compliance</strong>. You represent
                                        and warrant that you shall comply with all applicable laws,
                                        statutes, ordinances, and regulations regarding use of the
                                        Services. We will cooperate with law enforcement agencies in any
                                        investigation of alleged unlawful activity of which we are made
                                        aware of regarding the use of our Services and may contact law
                                        enforcement if we are made aware of any use of our Services
                                        which potentially violates any applicable laws, statutes,
                                        ordinances, or regulations. The Services are designed and
                                        targeted to Users who reside in the United States. We make no
                                        representation that the Services are operated in accordance with
                                        the laws or regulations of, or governed by, other nations. By
                                        accessing the Services you certify that you meet the age and
                                        other eligibility requirements for use of the Services. Those
                                        who access or use the Services do so at their own volition and
                                        are entirely responsible for compliance with applicable law.</p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p><strong>26</strong>. <strong>Feedback</strong>. We value your
                                        comments and opinions. If you have questions, comments or a
                                        complaint about these Terms, you may send a written notice to us
                                        at info@roundcomb.com.</p>
                                </div>
                            </div>
                            <div class="sqs-block horizontalrule-block sqs-block-horizontalrule"
                                data-block-type="47" id="block-yui_3_17_2_5_1471350293976_7146">
                                <div class="sqs-block-content">
                                    <hr>
                                </div>
                            </div>
                            <div class="sqs-block html-block sqs-block-html" data-block-type="2"
                                id="block-yui_3_17_2_5_1471350293976_7583">
                                <div class="sqs-block-content"></div>
                            </div>
                            <div class="sqs-block button-block sqs-block-button" data-block-type="53"
                                id="block-yui_3_17_2_5_1471350293976_8644">
                                <div class="sqs-block-content" id="yui_3_17_2_1_1614018611573_108">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
                -->

        </main>
        </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 ==4">
        <div  style="text-align: center;">
            <img src="{{this.fromParent.prop2}}" id="imagepreview" style="Width: fit-content;">
        </div>
    </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 ==5">
            <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
                <tbody>
                <tr>
                </tr>
                <tr>
                <td style="width: 100%;">
                    <h5>{{this.fromParent.prop2}}</h5>
                </td>
                </tr>
                <tr>
                    <td style="width: 100%;">
                        <br>In case of any doubt, read the terms of use and or get in touch with us!  (support@roundcomb.com)
                    </td>
                    </tr>
                </tbody>
                </table>
        </ng-container>
        <ng-container *ngIf="this.fromParent.prop3 ==6">
            <div class="row">

                <p class="fs-13" style="margin-left: 13px;">Please drag and drop steps for event configuration.</p>

                <div class="col-sm-6">
                    <div class="card card-outline-success mb-3">
                        <div class="card-header card-inverse card-success">
                            Available steps
                            </div>
                        <div class="card-block scroll-list">
                            <div class="list-group" droppable [dragHintClass]="'drag-hint'" (onDrop)="onDropBackStepsConfiguration($event)">
                                <li class="list-group-item list-group-item-action list-group-item-success" [draggable]  *ngFor="let item of availablesteps"
                                    [dragClass]="'active'" [dragData]="item" [dragScope]="item.type">
                                    {{item.name}}
                                </li>
                            </div>
                        </div>

                </div>
            </div>
                <div class="col-sm-6">
                    <div class="card card-outline-primary mb-3"  droppable [dragOverClass]="'drag-target-border'" [dropScope]="['fruit', 'vegetable']"
                        [dragHintClass]="'drag-hint'" (onDrop)="onAnyDrop($event)">
                        <div class="card-header card-inverse card-primary">Event configuration</div>
                        <div class="card-block scroll-list">
                            <li *ngFor="let item of droppedItems" [draggable] [dragData]="item" class="list-group-item">{{item.name}}</li>
                        </div>
                    </div>
                </div>
                <p class="fs-13" style="margin-left: 13px;"><strong>Note:</strong> the new configuration will be applied only for new events.</p>
            </div>
    </ng-container>
</div>
<div class="modal-footer">
 <!--   <ng-container *ngIf="this.fromParent.prop3 ==4">
        <button type="button" class="btn btn-primary" (click)="downloadFile()">Download</button>
    </ng-container>
-->
    <ng-container *ngIf="this.fromParent.prop3 ==6">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="closeModalWithParameter('apply')">Apply</button>
    </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 !=5">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
    </ng-container>
    <ng-container *ngIf="this.fromParent.prop3 ==5">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="closeModalWithParameter('yes')">yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal()">No</button>
    </ng-container>
</div>
