<main id="content">
  <section class="position-relative" data-animated-id="1">
    <div class="pt-2 page-title bg-overlay bg-cover" style="background-image: url('assets/images/bg-title-3.jpg');">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb text-light mb-0 p-0">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Vendors</li>
          </ol>
        </nav>
        <h1 class="fs-32 mb-0 text-white font-weight-500 text-center pt-10 pb-9 lh-17 mxw-478 fadeInDown animated"
          data-animate="fadeInDown">Meet The Vendors
          Transforming Real Estate </h1>
      </div>
    </div>

  </section>
  <section class="pt-8 pb-13 bg-gray-01" data-animated-id="2">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="row align-items-sm-center mb-7">
            <div class="col-sm-6 mb-6 mb-sm-0">
              <h2 class="fs-15 text-dark mb-0">We found <span class="text-primary">{{this.listVendors.length}}</span>
                Vendors
                available
                for
                you
              </h2>
            </div>
            <div class="col-sm-6 ml-auto">
              <div class="d-flex align-items-center justify-content-sm-end">
                <div class="input-group border rounded input-group-lg w-auto mr-6">
                  <label class="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3"
                    for="inputGroupSelect01"><i class="fas fa-align-left fs-16 pr-2"></i>Sort
                    by:</label>
                  <div class="dropdown bootstrap-select form-control border-0 bg-transparent shadow-none p-0">
                    <!-- <select class="form-control border-0 bg-transparent shadow-none p-0 selectpicker"
                      data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0" id="inputGroupSelect01"
                      name="sortby">
                      <option selected="">Alphabet</option>
                      <option value="1">Random</option>
                      <option value="1">Rating</option>
                      <option value="1">Number of property</option> -->
                    <!-- </select> -->
                    <button (click)="openSortBy()" style="gap: 40px;" type="button" tabindex="-1"
                      class="btn dropdown-toggle bg-transparent border-0 font-weight-600 btn-lg pl-0"
                      data-toggle="dropdown" role="combobox" aria-owns="bs-select-7" aria-haspopup="listbox"
                      aria-expanded="false" data-id="inputGroupSelect01" title="Alphabet">
                      <div class="filter-option">
                        <div class="filter-option-inner">
                          <div class="filter-option-inner-inner">{{this.sortByText}}</div>
                        </div>
                      </div>
                    </button>
                    <div class="dropdown-menu" [ngClass]="{'show-dropdown-menu': this.sortByMenu}">
                      <div class="inner show" role="listbox" id="bs-select-7" tabindex="-1">
                        <ul class="dropdown-menu inner show" role="presentation"></ul>
                        <li (click)="selectSortBy('Alphabet')">
                          <a role="option" class="dropdown-item" id="{{'bs-select-10-0'}}" tabindex="0">
                            <span class="text">Alphabet</span>
                          </a>
                        </li>
                        <li (click)="selectSortBy('Random')">
                          <a role="option" class="dropdown-item" id="{{'bs-select-10-1'}}" tabindex="0">
                            <span class="text">Random</span>
                          </a>
                        </li>
                        <li (click)="selectSortBy('Ratings')">
                          <a role="option" class="dropdown-item" id="{{'bs-select-10-2'}}" tabindex="0">
                            <span class="text">Ratings</span>
                          </a>
                        </li>
                        <li (click)="selectSortBy('Number')">
                          <a role="option" class="dropdown-item" id="{{'bs-select-10-3'}}" tabindex="0">
                            <span class="text">Number</span>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-none d-md-block list-layout">
                  <a class="fs-sm-18 text-muted" href="/vendors">
                    <i class="fas fa-list"></i>
                  </a>
                  <span class="fs-sm-18 text-muted ml-5 active">
                    <i class="fa fa-th-large"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              *ngFor="let vendor of this.listVendors | paginate: { itemsPerPage: 6, currentPage: page, totalItems: count }"
              class="col-md-6 mb-6">
              <div class="card border-0 shadow-hover-3 px-6">
                <div class="card-body text-center pt-6 pb-2 px-0">
                  <a href="agent-details-1.html" class="d-inline-block mb-2">
                    <img src="assets/images/agent-1.jpg" alt="Oliver Beddows">
                  </a>
                  <a href="agent-details-1.html"
                    class="d-block fs-16 lh-214 text-dark mb-0 font-weight-500 hover-primary">{{vendor.FirstName}}
                    {{vendor.LastaName}}</a>
                  <p class="mb-0">Sales Excutive</p>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item fs-13 text-heading font-weight-500">{{vendor.ratingCalc}}/5
                    </li>
                    <li class="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                      <div class="stars-display">
                        <ng-container>
                          <div class="arrow arrow-vendor star">
                            <ng-container *ngIf="vendor.ratingCalc > 0.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc > 0 && vendor.ratingCalc <= 0.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStarHalf"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc <= 0">
                              <fa-icon class="star-no-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                          <div class="arrow arrow-vendor star">
                            <ng-container *ngIf="vendor.ratingCalc >= 2">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc >= 1.1 && vendor.ratingCalc <= 1.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStarHalf"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc <= 1">
                              <fa-icon class="star-no-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                          <div class="arrow arrow-vendor star">
                            <ng-container *ngIf="vendor.ratingCalc >= 3">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc >= 2.1 && vendor.ratingCalc <= 2.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStarHalf"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc <= 2">
                              <fa-icon class="star-no-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                          <div class="arrow arrow-vendor star">
                            <ng-container *ngIf="vendor.ratingCalc >= 4">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc >= 3.1 && vendor.ratingCalc <= 3.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStarHalf"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc <= 3">
                              <fa-icon class="star-no-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                          <div class="arrow arrow-vendor star">
                            <ng-container *ngIf="vendor.ratingCalc == 5">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc >= 4.1 && vendor.ratingCalc <= 4.9">
                              <fa-icon class="star-bg-icon star-icon-vendor" [icon]="faStarHalf"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="vendor.ratingCalc <= 4">
                              <fa-icon class="star-no-icon star-icon-vendor" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </li>
                    <li class="list-inline-item fs-13 text-gray-light">({{vendor.ratingLength}} reviews)</li>
                  </ul>
                </div>
                <div class="card-footer bg-white px-0 pt-1 pb-6">
                  <ul class="list-group list-group-no-border pb-1">
                    <li class="list-group-item d-flex align-items-sm-center lh-114 row m-0 px-0 pt-3 pb-0">
                      <span class="col-sm-4 p-0 fs-13 mb-1 mb-sm-0">Office</span>
                      <span class="col-sm-8 p-0 text-heading font-weight-500">123 900 68668</span>
                    </li>
                    <li class="list-group-item d-flex align-items-sm-center lh-114 row m-0 px-0 pt-3 pb-0">
                      <span class="col-sm-4 p-0 fs-13 mb-1 mb-sm-0">Mobile</span>
                      <span
                        class="col-sm-8 p-0 text-heading font-weight-500">{{vendor.CellPhoneNumber === '' || vendor.CellPhoneNumber === '[object Object]' ? 'No cellphone number' : vendor.CellPhoneNumber}}</span>
                    </li>
                    <li class="list-group-item d-flex align-items-sm-center lh-114 row m-0 px-0 pt-3 pb-0">
                      <span class="col-sm-4 p-0 fs-13 mb-1 mb-sm-0">Fax</span>
                      <span class="col-sm-8 p-0 text-heading font-weight-500">1-323 900
                        6800</span>
                    </li>
                    <li class="list-group-item d-flex align-items-sm-center row m-0 px-0 pt-2 pb-0">
                      <span class="col-sm-4 p-0 fs-13 lh-114">Email</span>
                      <span class="col-sm-8 p-0">{{vendor.Email}}</span>
                    </li>
                    <li class="list-group-item d-flex align-items-sm-center lh-114 row m-0 px-0 pt-3 pb-0">
                      <span class="col-sm-4 p-0 fs-13 mb-1 mb-sm-0">Social</span>
                      <ul class="col-md-8 list-inline text-gray-lighter m-0 p-0 z-index-2">
                        <li class="list-inline-item m-0">
                          <a href="#"
                            class="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary"><i
                              class="fab fa-twitter"></i></a>
                        </li>
                        <li class="list-inline-item mr-0 ml-2">
                          <a href="#"
                            class="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary"><i
                              class="fab fa-facebook-f"></i></a>
                        </li>
                        <li class="list-inline-item mr-0 ml-2">
                          <a href="#"
                            class="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary"><i
                              class="fab fa-instagram"></i></a>
                        </li>
                        <li class="list-inline-item mr-0 ml-2">
                          <a href="#"
                            class="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary"><i
                              class="fab fa-linkedin-in"></i></a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 2rem; margin-bottom: 40px;" class="d-flex justify-content-center">
            <div class="pagination-desktop">
              <pagination-template #p="paginationApi" (pageChange)="page = $event">
                <div class="custom-pagination">
                  <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                    <span (click)="p.previous()">
                      < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value">
                        <span (click)="p.setCurrent(page.value)"
                          *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                        <div class="pages" *ngIf="p.getCurrent() === page.value">
                          <span>{{ page.label }}</span>
                        </div>
                  </div>
                  <div class="pagination-next" [class.disabled]="p.isLastPage()">
                    <span (click)="toNext(p)"> > </span>
                  </div>
                </div>
              </pagination-template>
            </div>
            <div class="pagination-mobile">
              <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
        <div class="col-lg-4 primary-sidebar sidebar-sticky" id="sidebar">
          <div class="primary-sidebar-inner" style="">
            <div class="card mb-4">
              <div class="card-body px-6 pt-5 pb-6">
                <h4 class="card-title fs-16 lh-2 text-dark mb-3">Search</h4>
                <form>
                  <div class="form-group mb-2">
                    <div class="dropdown bootstrap-select form-control border-0 shadow-none dropup">
                      <!-- <select class="form-control border-0 shadow-none selectpicker" name="company" title="Company"
                        data-style="btn-lg px-3" tabindex="null">
                        <option class="bs-title-option" value=""></option>
                        <option>Google</option>
                        <option>Facebook</option>
                      </select> -->
                      <button (click)="handleShowDropMenu('company')" type="button" tabindex="-1"
                        class="btn dropdown-toggle btn-lg px-3 bs-placeholder" data-toggle="dropdown" role="combobox"
                        aria-owns="bs-select-8" aria-haspopup="listbox" aria-expanded="false" title="Company">
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{this.companyText}}</div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu" [ngClass]="{'show-dropdown-menu': this.companyDrop}"
                        style="max-height: 2285.25px; overflow: hidden; min-height: 0px;">
                        <div class="inner show" role="listbox" id="bs-select-8" tabindex="-1"
                          style="max-height: 2253.25px; overflow-y: auto; min-height: 0px;">
                          <ul class="dropdown-menu inner show" role="presentation"
                            style="margin-top: 0px; margin-bottom: 0px;">
                            <li *ngFor="let company of this.companies; let i = index"
                              (click)="selectValue(company.value, 'company')"><a role="option" class="dropdown-item"
                                id="{{'bs-select-8-' + i}}" tabindex="0"><span class="text">{{company.name}}</span></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-2">
                    <div class="col-6 form-group">
                      <div class="dropdown bootstrap-select form-control border-0 dropup">
                        <button (click)="handleShowDropMenu('language')" type="button" tabindex="-1"
                          class="btn dropdown-toggle btn-lg rounded-lg px-3 bs-placeholder" data-toggle="dropdown"
                          role="combobox" aria-owns="bs-select-9" aria-haspopup="listbox" aria-expanded="false"
                          title="Language">
                          <div class="filter-option">
                            <div class="filter-option-inner">
                              <div class="filter-option-inner-inner">{{this.languageText}}</div>
                            </div>
                          </div>
                        </button>
                        <div class="dropdown-menu" [ngClass]="{'show-dropdown-menu': this.languageDrop}"
                          style="max-height: 2347.25px; overflow: hidden; min-height: 0px;">
                          <div class="inner show" role="listbox" id="bs-select-9" tabindex="-1"
                            style="max-height: 2315.25px; overflow-y: auto; min-height: 0px;">
                            <ul class="dropdown-menu inner show" role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px;">
                              <li *ngFor="let language of this.languages; let i = index"
                                (click)="selectValue(language.value, 'language')">
                                <a role="option" class="dropdown-item" id="{{'bs-select-10-' + i}}" tabindex="0">
                                  <span class="text">{{language.name}}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 form-group">
                      <div class="dropdown bootstrap-select form-control border-0 dropup">
                        <button (click)="handleShowDropMenu('region')" type="button" tabindex="-1"
                          class="btn dropdown-toggle btn-lg rounded-lg px-3 bs-placeholder" data-toggle="dropdown"
                          role="combobox" aria-owns="bs-select-10" aria-haspopup="listbox" aria-expanded="false"
                          title="Region">
                          <div class="filter-option">
                            <div class="filter-option-inner">
                              <div class="filter-option-inner-inner">{{this.regionText}}</div>
                            </div>
                          </div>
                        </button>
                        <div class="dropdown-menu" [ngClass]="{'show-dropdown-menu': this.regionDrop}"
                          style="max-height: 2347.25px; overflow: hidden; min-height: 134px;">
                          <div class="inner show" role="listbox" id="bs-select-10" tabindex="-1"
                            style="max-height: 2315.25px; overflow-y: auto; min-height: 102px;">
                            <ul class="dropdown-menu inner show" role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px;">
                              <li *ngFor="let region of this.regions; let i = index"
                                (click)="selectValue(region.value, 'region')">
                                <a role="option" class="dropdown-item" id="{{'bs-select-10-' + i}}" tabindex="0">
                                  <span class="text">{{region.name}}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <input type="text" class="form-control form-control-lg border-0" name="search"
                      placeholder="Search by agent’s name...">
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-body text-center pt-7 pb-6 px-0">
                <img src="assets/images/contact-widget.jpg" alt="Want to become an Vendor?">
                <div class="text-dark mb-6 mt-n2 font-weight-500">Want to become an
                  <p class="mb-0 fs-18">vendor?</p>
                </div>
                <a href="#" class="btn btn-primary">Register</a>
              </div>
            </div>
            <div class="card property-widget mb-4">
              <div class="card-body px-6 pt-5 pb-6">
                <h4 class="card-title fs-16 lh-2 text-dark mb-3">Featured Properties</h4>
                <div class="slick-slider mx-0 slick-initialized slick-dotted"
                  data-slick-options="{&quot;slidesToShow&quot;: 1, &quot;autoplay&quot;:true}">
                  <div class="slick-prev slick-arrow slick-disabled" aria-label="Previous" aria-disabled="true"
                    style=""><i class="far fa-angle-left"></i></div>
                  <div class="slick-list draggable" style="height: 515.328px;">
                    <div class="slick-track" style="opacity: 1; width: 2319px; transform: translate3d(0px, 0px, 0px);">
                      <div class="box px-0 slick-slide slick-current slick-active" data-slick-index="0"
                        aria-hidden="false" style="width: 773px;" tabindex="0" role="tabpanel" id="slick-slide00"
                        aria-describedby="slick-slide-control00">
                        <div class="card border-0">
                          <img src="assets/images/feature-property-01.jpg" class="card-img"
                            alt="Villa on Hollywood Boulevard">
                          <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">
                            <div class="d-flex mb-auto">
                              <a href="#" class="mr-1 badge badge-orange" tabindex="0">featured</a>
                              <a href="#" class="badge badge-indigo" tabindex="0">for
                                Rent</a>
                            </div>
                            <div class="px-2 pb-2">
                              <a href="single-property-1.html" class="text-white" tabindex="0">
                                <h5 class="card-title fs-16 lh-2 mb-0">Villa on
                                  Hollywood
                                  Boulevard</h5>
                              </a>
                              <p class="card-text text-gray-light mb-0 font-weight-500">
                                1421 San
                                Predro
                                St, Los Angeles</p>
                              <p class="text-white mb-0"><span class="fs-17 font-weight-bold">$2500 </span>/month
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="box px-0 slick-slide" data-slick-index="1" aria-hidden="true" style="width: 773px;"
                        tabindex="-1" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                        <div class="card border-0">
                          <img src="assets/images/feature-property-01.jpg" class="card-img"
                            alt="Villa on Hollywood Boulevard">
                          <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">
                            <div class="d-flex mb-auto">
                              <a href="#" class="mr-1 badge badge-orange" tabindex="-1">featured</a>
                              <a href="#" class="badge badge-indigo" tabindex="-1">for
                                Rent</a>
                            </div>
                            <div class="px-2 pb-2">
                              <a href="single-property-1.html" class="text-white" tabindex="-1">
                                <h5 class="card-title fs-16 lh-2 mb-0">Villa on
                                  Hollywood
                                  Boulevard</h5>
                              </a>
                              <p class="card-text text-gray-light mb-0 font-weight-500">
                                1421 San
                                Predro
                                St, Los Angeles</p>
                              <p class="text-white mb-0"><span class="fs-17 font-weight-bold">$2500 </span>/month
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="box px-0 slick-slide" data-slick-index="2" aria-hidden="true" style="width: 773px;"
                        tabindex="-1" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
                        <div class="card border-0">
                          <img src="assets/images/feature-property-01.jpg" class="card-img"
                            alt="Villa on Hollywood Boulevard">
                          <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">
                            <div class="d-flex mb-auto">
                              <a href="#" class="mr-1 badge badge-orange" tabindex="-1">featured</a>
                              <a href="#" class="badge badge-indigo" tabindex="-1">for
                                Rent</a>
                            </div>
                            <div class="px-2 pb-2">
                              <a href="single-property-1.html" class="text-white" tabindex="-1">
                                <h5 class="card-title fs-16 lh-2 mb-0">Villa on
                                  Hollywood
                                  Boulevard</h5>
                              </a>
                              <p class="card-text text-gray-light mb-0 font-weight-500">
                                1421 San
                                Predro
                                St, Los Angeles</p>
                              <p class="text-white mb-0"><span class="fs-17 font-weight-bold">$2500 </span>/month
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="slick-next slick-arrow" aria-label="Next" style="" aria-disabled="false"><i
                      class="far fa-angle-right"></i></div>
                  <ul class="slick-dots" style="" role="tablist">
                    <li class="slick-active" role="presentation"><span></span></li>
                    <li role="presentation" class=""><span></span></li>
                    <li role="presentation" class=""><span></span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
