import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { usersService, AuthenticationService} from '../services';
import { Router} from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  resetpasswordForm: FormGroup;
  submitted = false;
  errorMessage = '';
  passwordchanged = false;

  constructor( private formBuilder: FormBuilder,
               private userservice: usersService,  private router: Router,private authenticationService: AuthenticationService) 
  { 

  }

  ngOnInit(): void {


        this.resetpasswordForm = this.formBuilder.group({
          oldPassword: ['', Validators.required],
          newPassword: ['', Validators.required],
          confirmNewPassword: ['', Validators.required],
      });

  }

      // convenience getter for easy access to form fields
      get f() { return this.resetpasswordForm.controls; }

  setResetPassword()
  {

    this.submitted = true;
       // stop here if form is invalid
      if (this.resetpasswordForm.invalid) {
      return;
      }
   
      this.userservice.setResetPassword(this.f.oldPassword.value, this.f.newPassword.value,this.f.confirmNewPassword.value)
          .pipe()
          .subscribe(
              data => {
                this.passwordchanged = true;
              },
              error => {

                if(JSON.stringify(error).length > 4)
                    this.errorMessage = JSON.parse(JSON.stringify(error)).error.errorMessage;

              });

  }

  logout() {
    
    this.authenticationService.logout();
    this.router.navigate(['/home']);
    
}



}
