<section class="py-13" data-animated-id="1">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 mx-auto">
                <div class="card border-0 login-register">
                    <div class="card-body p-6">
                        <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Forgot password</h2>
                        <ng-container *ngIf="!passwordchanged">
                            <form class="form" [formGroup]="forgotresetpasswordForm" (ngSubmit)="onSubmit()">
                            <div class="form-group mb-4">
                                <label for="email" class="text-heading">Email address</label>
                                <input type="email" name="mail" id = "mail" formControlName="mail" class="form-control form-control-lg border-0" id="email" [(ngModel)]="emaildata"
                                readonly>
                                      
                            </div>

                            <div class="form-group mb-4">
                                <label for="newPassword" class="text-heading">New Password</label>
                                                <input type="password"  formControlName="newPassword" class="form-control form-control-lg border-0"
                                                    id="newPassword" name="newPassword" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"
                                                    >
                                                    <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                                        <div *ngIf="f.newPassword.errors.required">New Password is required</div>
                                                        
                                                    </div>
                            </div>
                        
                            <div class="form-group mb-4">
                                <label for="confirmNewPassword" class="text-heading">Confirm New
                                    Password</label>
                                <input type="password"  formControlName="confirmNewPassword"  class="form-control form-control-lg border-0"
                                    id="confirmNewPassword" name="confirmNewPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmNewPassword.errors }"
                                    >
                                    <div *ngIf="submitted && f.confirmNewPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmNewPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.confirmNewPassword.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                                    </div>

                                    <input type="text"  formControlName="hiddentoken"  id="hiddentoken"  name="hiddentoken" [(ngModel)]="ResetPasswordToken"  style="display: none;"/>
                            </div>
                            <div class="form-group mb-4">
                                <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg rounded">Reset password</button>
                            </div>
                            
                        </form>
                    </ng-container>
                        <ng-container *ngIf="passwordchanged">
                            <div class="card-body p-6">
                                    <p class="mb-4">Your password has been reset. You can now use the new password to login to your account.</p>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>