

        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              EDIT CONTRACT
            </h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ng-container *ngIf="showEditor">
              <div class="form-row mx-n2">
                <div class="list-group">
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordname">@Landlord Name</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordid">@Landlord ID</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordidtype">@Landlord ID Type</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordaddress">@Landlord Address</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action landlordstyledraganddrop"  value="landlordphonenumber">@Landlord Phone Number</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordphonenumber">@Landlord Email</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action landlordstyledraganddrop" value="landlordphonenumber">@Landlord Signature</a>
                  </div>
                  <div class="list-group ml-2">
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenantname">@Tenant Name</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenantid">@Tenant ID</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenanttype">@Tenant ID Type</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"  style="color:rgb(155, 214, 59)" value="tenantddress">@Tenant Address</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Phone Number</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Email</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action" style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Signature</a>
                  </div>
                  <div class="list-group ml-2">
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenantname">@Date Today</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenantname">@Day Today</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenantname">@Month Today</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenantname">@year Today</a>
                    <a href="javascript:void(0);" class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenantid">@Initial Date</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@End Date</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Move-In date</a>
                  </div>
                  <div class="list-group ml-2">
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Property address</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Property City</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Property State</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Monthly Fee</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Deposit Value</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Included services</a>
                  <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Amenities services</a>
                </div>
                <div class="list-group ml-2">
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Category(Apartment,Villa,..)</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Bedrooms</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Bathrooms</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Sq Ft</a>
                    <a href="javascript:void(0);"class="list-group-item list-group-item-action" style="color:rgb(52, 191, 196)" value="tenanttype">@Parking</a>
                  </div>
            </div>
              <ckeditor [(ngModel)]="mycontent"
              #myckeditor="ngModel"
              name="myckeditor"
              required
              [config]="ckeConfig"
              debounce="500" >
            </ckeditor>
          </ng-container>
          <ng-container *ngIf="!showEditor">
            <div class="content_view" [innerHtml]="this.mycontent">
            </div>
        </ng-container>
          </div>
          <ng-container *ngIf="showupdatemessage">
            <p></p>
            <div class="alert alert-success alert-dismissible fade show" role="alert">
                Contract updated successfully!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        </ng-container>
          <div class="modal-footer">
            <ng-container *ngIf="!showEditor">
              <button type="button" class="btn btn-primary" (click)="editContract()">Edit Contract </button>
            </ng-container>
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="saveContract()">Save and Close</button>
          </div>
