import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../services';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider,SocialUser  } from "angularx-social-login";


const fbLoginOptions = {
    scope: 'email'//'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages'
  }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11


@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    errorMessage = '';

    user: SocialUser;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private authService: SocialAuthService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.isUserLogged) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        this.authService.authState.subscribe((user) => {
            this.user = user;
            //this.loggedIn = (user != null);
          });

    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value,0)
            .pipe(first())
            .subscribe(
                data => {
                    this.authenticationService.setUserLogged();
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.errorMessage ="Username or Password is Incorrect";
                    this.loading = false;
                });
    }

    async signInWithFB() {

        await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID,fbLoginOptions);



        // check if user exists
        this.authenticationService.login(this.user.email, this.user.id,1)
            .pipe(first())
            .subscribe(
                data => {

                    this.authenticationService.setUserLogged();
                    this.authenticationService.profileimageurl = this.user.photoUrl;
                    this.router.navigate([this.returnUrl]);
                },
                error => {

                    //Unauthorized
                    if(error.status = '401')
                    {
                        this.errorMessage = "The user is not registered or activated. Please click register for new registration with your facebook account.";
                    }
                    else
                    {
                        this.errorMessage = error.error.errorMessage;
                        this.loading = false;
                    }

                });
      }

      async signInWithGoogle() {

        await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

        // check if user exists
        this.authenticationService.login(this.user.email, this.user.id,2)
            .pipe(first())
            .subscribe(
                data => {

                    this.authenticationService.setUserLogged();
                    this.authenticationService.profileimageurl = this.user.photoUrl;
                    this.router.navigate([this.returnUrl]);
                },
                error => {

                    //Unauthorized
                    if(error.status = '401')
                    {
                        this.errorMessage = "The user is not registered or activated. Please click register for new registration with your google account.";
                    }
                    else
                    {
                        this.errorMessage = error.error.errorMessage;
                        this.loading = false;
                    }

                });
      }
}
