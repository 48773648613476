<section class="pt-9 pb-10" data-animated-id="1">
    <div class="container">
        <div class="text-center mb-15">
            <img src="assets/images/page-404.jpg" alt="Page 404" class="mb-5">
            <h1 class="fs-30 lh-16 text-dark font-weight-600 mb-5">Oops! That page can’t be found.</h1>
            <p class="mb-8">It looks like nothing was found at this location. Maybe try one of the links below or a
                search?</p>

        </div>

    </div>
</section>