<ng-container *ngIf="!this.loading">
  <footer class="pt-8 pb-6 footer text-muted" [ngClass]="{'pt-8': true, 'pb-6': true, 'footer': true, 'text-muted': true, 'loading-footer': this.loading === true}" style="background-color: #f3f3f3!important;">
    <div *ngIf="!this.loading" class="footer-container">
      <div class="row row-footer">
        <div class="mb-6 mb-md-0">
          <a class="d-block mb-2" href="#">
            <img fetchpriority="high" src="assets/images/logo.png" class="footer-logo" width="90%" height="90%" alt="Round Comb"
              alt="RoundComb">
          </a>
          <!--
            <ul class="list-inline mb-0">
              <li class="list-inline-item mr-0">
              <a href="#" class="text-primary  fs-25 px-4 opacity-hover-10"><i class="fab fa-twitter"></i></a>
              </li>
              <li class="list-inline-item mr-0">
              <a href="#" class="text-primary fs-25 px-4 opacity-hover-10"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li class="list-inline-item mr-0">
              <a href="#" class="text-primary  fs-25 px-4 opacity-hover-10"><i class="fab fa-skype"></i></a>
              </li>
              <li class="list-inline-item mr-0">
              <a href="#" class="text-primary fs-25 px-4 opacity-hover-10"><i class="fab fa-linkedin-in"></i></a>
              </li>
              </ul>
            -->
          <!-- <div class="lh-26 font-weight-500">
              <p class="mb-0">58 Howard Street #2 San Francisco</p>
              <a class="d-block text-muted hover-white" href="mailto:admin@roundcomb.com">admin@roundcomb.com</a>
              <a class="d-block text-lighter font-weight-bold fs-15 hover-white" href="tel:(+1)-800-216-1583">(+1)-800-216-1583</a>
            </div>-->
        </div>

        <div class="nav-container-footer">

          <ul class="list-group list-group-flush list-group-no-border">
            <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;"><strong>OUR
                COMPANY</strong></h4>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/about" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">About Us</a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/ourmission" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Our Mission
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/ourstory" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Our Story
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/whoweare" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Who We Are
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/careers" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Careers
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/contactus" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Contact Us
              </a>
            </li>
          </ul>

          <ul class="list-group list-group-flush list-group-no-border to-desktop">
            <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;">
              <strong>HELP</strong>
            </h4>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/glossary" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Glossary
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/feedback" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Feedback
              </a>
            </li>
          </ul>

          <div class="to-mobile">
            <ul style="margin-bottom: 50px;" class="list-group list-group-flush list-group-no-border">
              <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;">
                <strong>HELP</strong>
              </h4>
              <li class="list-group-item bg-transparent p-0">
                <a routerLink="/glossary" style="cursor: pointer;color: #31499c;"
                  class="lh-26 font-weight-500 hover-info">Glossary
                </a>
              </li>
              <li class="list-group-item bg-transparent p-0">
                <a routerLink="/feedback" style="cursor: pointer;color: #31499c;"
                  class="lh-26 font-weight-500 hover-info">Feedback
                </a>
              </li>
            </ul>

            <ul class="list-group list-group-flush list-group-no-border">
              <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;"><strong>QUICK
                  LINKS</strong></h4>
              <li class="list-group-item bg-transparent p-0">
                <a style="cursor: pointer;color: #31499c;" href="/filterservice?servicetype=All%20Services"
                  class="lh-26 font-weight-500 hover-info">Find a service
                </a>
              </li>
              <li class="list-group-item bg-transparent p-0">
                <a style="cursor: pointer;color: #31499c;" href="/filterproperty?propertytype=All%20Properties"
                  class="lh-26 font-weight-500 hover-info">Find a property
                </a>
              </li>
            </ul>
          </div>

          <ul class="list-group list-group-flush list-group-no-border">
            <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;"><strong>NEWS</strong>
            </h4>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/inthenews" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">In the News
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a routerLink="/pressmedia" style="cursor: pointer;color: #31499c;"
                class="lh-26 font-weight-500 hover-info">Press and Media
              </a>
            </li>

          </ul>
          <ul class="list-group list-group-flush list-group-no-border to-desktop">
            <h4 class="fs-16 my-4 font-weight-500" style="color: #31499c; margin-top: 0 !important;"><strong>QUICK
                LINKS</strong></h4>
            <li class="list-group-item bg-transparent p-0">
              <a style="cursor: pointer;color: #31499c;" href="/filterservice?servicetype=All%20Services"
                class="lh-26 font-weight-500 hover-info">Find a service
              </a>
            </li>
            <li class="list-group-item bg-transparent p-0">
              <a style="cursor: pointer;color: #31499c;" href="/filterproperty?propertytype=All%20Properties"
                class="lh-26 font-weight-500 hover-info">Find a property
              </a>
            </li>
          </ul>
        </div>


        <!--<div class="col-md-6 col-lg-4 mb-6 mb-md-0">
            <h4 class="text-white fs-16 my-4 font-weight-500">Follow us on Social Media</h4>
            <ul class="list-inline mb-0">
              <li class="list-inline-item mr-0">
                <a href="#" class="text-white opacity-3 fs-25 px-4 opacity-hover-10"><i class="fab fa-twitter"></i></a>
              </li>
              <li class="list-inline-item mr-0">
                <a href="#" class="text-white opacity-3 fs-25 px-4 opacity-hover-10"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li class="list-inline-item mr-0">
                <a href="#" class="text-white opacity-3 fs-25 px-4 opacity-hover-10"><i class="fab fa-skype"></i></a>
              </li>
              <li class="list-inline-item mr-0">
                <a href="#" class="text-white opacity-3 fs-25 px-4 opacity-hover-10"><i class="fab fa-linkedin-in"></i></a>
              </li>
            </ul>
          </div>
        -->
      </div>
      <div class="end-footer mt-0 mt-md-7 row">
        <ul class="end-footer list-inline mb-0">
          <li class="list-inline-item mr-6">
            <a routerLink="/termsconditions" style="color: #31499c;" class="lh-26 font-weight-500 hover-info">Terms of
              Use</a>
          </li>
          <li class="list-inline-item">
            <a routerLink="/privacypolicy" style="color: #31499c;" class="lh-26 font-weight-500 hover-info">Privacy
              Policy</a>
          </li>
        </ul>
        <p class="end-footer list-inline mb-0" style="color: #31499c;text-align: left;">
          <!-- © Сopyright 2021 Roundcomb, Inc. All Rights Reserved. -->
          © Сopyright {{ this.year }} Roundcomb, Inc. All Rights Reserved.
        </p>

      </div>
    </div>
  </footer>
</ng-container>
