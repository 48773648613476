<div class="modal-header">
  <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">REPORT USER</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <ng-container *ngIf="!istoshowfinalmessage">
    <span style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;">

      This user will be penalized by Roundcomb.com. Please explain in detail the cause of this report.
      <br>
      In case of any doubt, read the terms of use and or get in touch with us! (support@roundcomb.com)
    </span>

    WAS THIS USER OFFENSIVE OR PROVIDED FAKE INFORMATION?

    <br>
    <br>

    <textarea (input)="setReportValue($event)" class="form-control border-0" placeholder="Message" name="message" id="message" rows="7"></textarea>
    <!-- <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
            -->
  </ng-container>

  <ng-container *ngIf="istoshowfinalmessage">


    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
      <tbody>
        <tr>
          <td style="width: 100%;">
            <svg xmlns="http://www.w3.org/2000/svg" width="376.58" height="376.58" viewBox="0 0 376.58 376.58">
              <path id="frown-regular"
                d="M188.29,8A188.29,188.29,0,1,0,376.58,196.29,188.258,188.258,0,0,0,188.29,8Zm0,340.137c-83.744,0-151.847-68.1-151.847-151.847S104.547,44.443,188.29,44.443s151.847,68.1,151.847,151.847S272.034,348.137,188.29,348.137ZM127.551,184.142a24.3,24.3,0,1,0-24.3-24.3A24.269,24.269,0,0,0,127.551,184.142Zm121.477-48.591a24.3,24.3,0,1,0,24.3,24.3A24.269,24.269,0,0,0,249.029,135.551ZM188.29,232.733a102.374,102.374,0,0,0-78.808,36.9A18.222,18.222,0,1,0,137.5,292.94a66.029,66.029,0,0,1,101.585,0A18.222,18.222,0,1,0,267.1,269.632,102.374,102.374,0,0,0,188.29,232.733Z"
                transform="translate(0 -8)" fill="#ff993c" />
            </svg>
          </td>
        </tr>
        <tr>
          <td style="width: 100%;">
            EVENT CANCELED DUE TO INFRACTION!
          </td>
        </tr>
      </tbody>
    </table>


  </ng-container>

</div>
<div class="modal-footer">
  <ng-container *ngIf="!istoshowfinalmessage">
    <button type="button" class="btn" style="width: 115px;height:43.4px; background-color: #ff6935; color: white;"
      data-dismiss="modal" (click)="closeModal()">GO BACK</button>
    &nbsp;
    &nbsp;
    <button type="button" class="btn" style="width: 115px;height:43.4px; background-color: #10c6d5; color: white;"
      (click)="cancelEvent()">SUBMIT</button>
  </ng-container>
  <ng-container *ngIf="istoshowfinalmessage">
    <div style="align-items: center; text-align: center;">
      <button type="button" class="btn" style="width: 115px;height:43.4px; background-color: #10c6d5; color: white;"
        data-dismiss="modal" (click)="closeModal()">CLOSE</button>
    </div>
  </ng-container>
</div>
