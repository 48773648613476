import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services';
import { User } from './models';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  currentUser: User;

  constructor( private router: Router,
    public authenticationService: AuthenticationService,
) {
    //this.loadScripts();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

  }

  title = 'RoundComb';

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }


  private loadScripts() {
    const dynamicScripts = [
      'assets/js/theme.js',
      'assets/vendors/jquery.min.js',
      'assets/vendors/jquery-ui/jquery-ui.min.js',
      'assets/vendors/bootstrap/bootstrap.bundle.js',
      'assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      'assets/vendors/slick/slick.min.js',
      'assets/vendors/waypoints/jquery.waypoints.min.js',
      'assets/vendors/counter/countUp.js',
      'assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      'assets/vendors/chartjs/Chart.min.js',
      'assets/vendors/dropzone/js/dropzone.min.js',
      'assets/vendors/timepicker/bootstrap-timepicker.min.js',
      'assets/vendors/hc-sticky/hc-sticky.min.js',
      'assets/vendors/jparallax/TweenMax.min.js',
      'assets/vendors/mapbox-gl/mapbox-gl.js',
      'assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js'

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

}
