import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-previewdocument',
  templateUrl: './previewdocument.component.html',
  styleUrls: ['./previewdocument.component.css']
})
export class PreviewdocumentComponent implements OnInit {

  @Input() fromParent;

  public url:string;
  public viewer:any = "office";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.url = this.fromParent.prop1;
  }

  closeModal() { 

    this.activeModal.close(); 
  }

  
}