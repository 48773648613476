import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcasingyourpropertyholdings',
  templateUrl: './showcasingyourpropertyholdings.component.html',
  styleUrls: ['./showcasingyourpropertyholdings.component.css']
})
export class ShowcasingyourpropertyholdingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
