<section *ngIf="this.authenticationService.customizedroles === 'landlord'">
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
    <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
      <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
        <div style="height: 279px">
          <div class="shadow-xs-1">
            <div class="d-flex px-3 px-xl-6 w-100"></div>
            <app-leftmenumypainel></app-leftmenumypainel>
          </div>
        </div>
      </nav>
    </div>
    <div class="page-content">
      <div class="modal-background" *ngIf="this.openModal">
        <div class="modal-slots shadow" [ngClass]="{
          'to-padding': this.step === 'buy-slots'
        }">
          <button (click)="closeModal()" [ngClass]="{
            'close-icon': true,
            'close-slot-button': this.step === 'buy-slots' || this.step === 'renew-slots'
          }">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <div *ngIf="this.step === 'select-slot'">
            <h3>My properties</h3>
            <p *ngIf="this.slots2.length === 0" class="available-slots">
              {{ this.slotsPreviewArr.length ? this.slotsPreviewArr.length : '0'}}/1
              available
              slots
            </p>
            <p *ngIf="this.slots2.length > 0" class="available-slots">
              {{ this.slotsPreviewArr.length ? this.slotsPreviewArr.length : '0'}}/{{ this.slotsDiffMonth.length > 0 ?  this.slotsDiffMonth.length - this.slotsPropFiltered.length  : this.slotsEqualPropFiltered.length }}
              available
              slots
            </p>
            <div class="modal-properties">
              <ng-container *ngIf="this.allProperties.length > 0">
                <div *ngFor="let properties of this.allProperties">
                  <div class="properties-slots-info">
                    <div class="principal-slot-info">
                      <img class="shadow" [src]="toPropertyPhoto(properties)" alt="Properties" />

                      <div class="properties-name">
                        <h5>{{ properties.Name }}</h5>
                        <p>
                          ${{ properties.Price }}/<span>month</span>
                        </p>
                      </div>
                    </div>
                    <div class="type-date-propertie">
                      <p *ngIf="properties.PropertySellType === 'ForRent'">
                        For Rent
                      </p>
                      <p *ngIf="properties.PropertySellType !== 'ForRent'">
                        {{ properties.PropertySellType }}
                      </p>
                      <span>
                        {{ properties.CreatedDate.slice(0, 10) }}
                      </span>
                    </div>
                    <button (click)="addSlot($event)" class="add-button" id="{{ properties.Id }}">
                      ADD TO SLOT
                    </button>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="this.allProperties.length === 0">
                <p style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600;">
                  No Properties!
                </p>
              </div>
            </div>
            <div class="bottom-buttons">
              <button class="cancel-button button-modal" (click)="closeModal()">
                CANCEL
              </button>
              <button (click)="addOrUpdateSlot()" class="confirm-button button-modal">CONFIRM</button>
            </div>
          </div>
          <div *ngIf="this.step === 'buy-slots' || this.step === 'renew-slots'">
            <a style="text-decoration: none; color: #27449B !important;" class="user-cash-dashboard">
              <span>{{ this.cash.toFixed(2) }}</span>
              <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
            </a>
            <h3 class="modal-title">
              {{this.step === 'renew-slots' ? 'RENEW' : 'BUY'}} SLOT
            </h3>
            <div class="box-slots" [ngClass]="{
              'radius': this.total > this.cash,
              'radius-all': this.total < this.cash
            }">
              <div class="box-display">
                <img class="locked-house" src="../../assets/images/houseWithPadlock.png"
                  alt="House with a padlock in your center">
                <div class="texts-about-slots">
                  <p>{{this.step === 'renew-slots' ? 'Renew' : 'Buy'}} Property Slots</p>
                  <span>Slots allow you to make public your properties listings</span>
                </div>
              </div>
              <div class="info-for-slots">
                <div *ngIf="this.specSlot !== 'specific-slot'" class="same-styles" style="margin-bottom: 10px;">
                  <p style="margin-bottom: 0;">Number of Slots</p>
                  <input type="text" name="slots" maxlength="2" (input)="changeSlotsValue($event)"
                    [value]="this.valueSlots" [ngClass]="{'less-then-ten': this.valueMonth <= 10}" id="slots"
                    [disabled]="this.specSlot === 'specific-slot' ? true : false">
                </div>
                <div class="same-styles">
                  <p style="margin-bottom: 0;">Duration month</p>
                  <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                    [value]="this.valueMonth" (input)="changeMonthValue($event)" id="month">
                </div>
                <div class="number">
                  <p style="margin-bottom: 0;">Total</p>
                  <span>{{this.total.toFixed(2)}} RCT</span>
                </div>
              </div>
            </div>
            <div *ngIf="this.total > this.cash" class="warn-orange">
              <span>insufficient RCT on your Wallet</span>
              <a routerLink="/wallet" class="user-cash buy-more-button"
                style="border-radius: 5px !important; color: #27449B !important;">Buy more RCT
                <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
              </a>
            </div>
            <div class="warning warning-slots">
              <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
              <p style="width: 100%; text-align: justify;">
                By completing the purchase, the funds will be taken from your wallet and the slots will be available on
                your account.
              </p>
            </div>
            <div *ngIf="this.step === 'buy-slots'" [ngClass]="{
              'top-short-distance': this.total > this.cash,
              'top-distance': this.total < this.cash
            }">
              <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new slots will be
                available until:</p>
              <p class="heading-3 available-date">{{this.dateavailable}}
              </p>
            </div>
            <!-- <div class="no-slots" *ngIf="this.slots2.length === 4 && this.step === 'buy-slots'">
              <p>No more slots</p>
            </div> -->
            <div class="end-button">
              <button *ngIf="this.step === 'renew-slots'"
                [disabled]="this.total > this.cash ? true : this.slots2.length === 0 ? true : this.valueMonthInNumber === 0 ? true : false"
                class="submit-button" (click)="renewSlot()">SUBMIT & PAY</button>
              <button *ngIf="this.step !== 'renew-slots'"
                [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : this.valueSlotsInNumber === 0 ? true : false"
                class="submit-button" (click)="buySlot()">SUBMIT & PAY</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.openBuyModal === true" class="modal-background">
        <div class="modal-buy shadow">
          <a style="text-decoration: none; color: #27449B !important;" class="user-cash-dashboard cash-boost">
            <span>{{ this.cash.toFixed(2) }}</span>
            <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
          </a>
          <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
            {{this.step === 'buy-booster' ? 'Buy' : 'Renew'}} Boosts
          </h3>
          <button (click)="closeModal()" class="close-icon">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <form (submit)="submitChanges()" style="margin-bottom: 0" type="submit">
            <div [ngClass]="{
              'radius': this.total > this.cash,
              'radius-all': this.total < this.cash
            }" class="listing-type-boosts">
              <h4 class="buy-title">{{this.step === 'buy-booster' ? 'Buy' : 'Renew'}} Listing Boosts</h4>
              <div (click)="checkBoosts(this.filteredItem[0].Booster ? 'renew-booster' : 'buy-booster')"
                *ngIf="this.specSlot === 'boosted'" class="modal-select-boosters blue-box">
                <div class="terms-section-renew">
                  <div class="checkbox-renew">
                    <div class="checked-renew" *ngIf="this.checkedBoost === true"></div>
                  </div>
                </div>
                <div class="arrow arrow-boost-modal">
                  <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                </div>
                <p>
                  There is a special spot at top of each listing page that will randomly cycle
                  through the pool of "top" listings. This will highly improve your listing views.
                </p>
              </div>
              <div (click)="checkHighlight(this.filteredItem[0].Highlight ? 'renew-booster' : 'buy-booster')"
                *ngIf="this.specSlot === 'highlight'" class="modal-select-boosters purple-box">
                <div class="terms-section-renew">
                  <div class="checkbox-renew">
                    <div class="checked-renew" *ngIf="this.checkedHigh === true"></div>
                  </div>
                </div>
                <div class="arrow arrow-boost-modal">
                  <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
                </div>
                <p>
                  Your property will appear with a visual highlight to make it stand out to
                  potential interested clients.
                </p>
              </div>
              <div style="display: flex; gap: 5px"
                *ngIf="this.specSlot === 'boosted' && this.filteredItem[0].monthBoost > 0 && this.step === 'renew-booster'">
                <span>Remaining days: </span>
                <span>{{this.filteredItem[0].Top}} days</span>
              </div>
              <div style="display: flex; gap: 5px"
                *ngIf="this.specSlot === 'highlight' && this.filteredItem[0].monthHigh > 0 && this.step === 'renew-booster'">
                <span>Remaining days: </span>
                <span>{{this.filteredItem[0].Featured}} days</span>
              </div>
              <div class="mobile-direction"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 12px;">
                <div class="same-styles">
                  <p style="margin-bottom: 0;">Duration month</p>
                  <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                    [value]="this.valueMonth" (input)="changeBoostMonthValue($event)" id="month">
                </div>
                <div class="number-boost">
                  <p style="margin-bottom: 0;">Total</p>
                  <span>{{this.total.toFixed(2)}} RCT</span>
                </div>
              </div>
            </div>
            <div *ngIf="this.total > this.cash" class="warn-orange">
              <span>insufficient RCT on your Wallet</span>
              <a routerLink="/wallet" class="user-cash buy-more-button"
                style="border-radius: 5px !important; color: #27449B !important;">Buy more RCT
                <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
              </a>
            </div>
            <div class="warning warning-slots">
              <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
              <p style="width: 100%; text-align: justify;">
                By completing the purchase, the funds will be taken from your wallet.
              </p>
            </div>
            <div [ngClass]="{
              'top-short-distance': this.total > this.cash,
              'top-distance': this.total < this.cash
            }">
              <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new boosts will be
                available until:</p>
              <p class="heading-3 available-date">{{this.dateavailable}}
              </p>
            </div>
            <div style="
                  display: flex;
                  justify-content: center;
                  margin-right: 34px;
                ">
              <button [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : false"
                class="submit-button">SUBMIT & PAY</button>
            </div>
          </form>
        </div>
      </div>
      <main id="content">
        <h2 class="component__title">Dashboard</h2>
        <div id="swiper" class="d-flex position d-flex-to-center mb-5 flex-wrap flex-xl-nowrap">
          <swiper #usefulSwiper [config]="config">
            <ng-container *ngIf="this.slots2[0]?.Idproperty === 0 || this.slots2.length === 0">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('select-slot', 1, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Empty Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">Free</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngFor="let slotsProp of this.slots">
              <ng-container *ngFor="let slots of this.slots2">
                <ng-container *ngIf="slots.Idproperty.toString() === slotsProp.Id && slots.Month >= 1">
                  <ng-template swiperSlide>
                    <div
                      [ngClass]="{'first-box': true, 'box-dashboard': true, 'shadow': true, 'in-draft': slots.status === 'Draft', 'second-margin': slots.Idtransaction === '2' || slots.Idtransaction === '3'}">
                      <img [src]="toSlotPhoto(slotsProp)" alt="Single family house by the lake" />
                      <div class="property-info">
                        <div class="to-mobile-name">
                          <h4>{{slotsProp.Name.substring(0,10) + "..."}}</h4>
                        </div>
                        <div class="to-desktop-name">
                          <h4>{{slotsProp.Name}}</h4>
                        </div>
                        <p>${{slotsProp.Price}}/ per month</p>
                      </div>
                      <div class="actions">
                        <button (click)="goToProperty(slotsProp.Id)" class="view-button">VIEW PROPERTY</button>
                        <button *ngIf="slotsProp.PropertySellType !== 'Unavailable'"
                          (click)="deletePropertySlot(slotsProp.Id)" class="delete-button">DELETE SLOT</button>
                      </div>
                      <ng-container *ngFor="let slotsToTime of this.slots2Filtered">
                        <ng-container *ngFor="let times of this.slotstime">
                          <ng-container *ngIf="times.idslot.toString() === slots.Idtransaction">
                            <div class="blue bg-time bg-time-position">
                              <p style="text-align: center;">
                                {{times.day}}
                                days,
                                {{times.hours}} hours</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let slots of this.slots2Filtered">
              <ng-container *ngIf="slots.Idproperty === 0 && slots.Month >= 1">
                <ng-template swiperSlide>
                  <div (click)="handleOpenBuySlotsModal('select-slot', slots.Idtransaction, 'specific-slot','',0,0)"
                    [ngClass]="{'empty-box': true, 'box-dashboard': true, 'shadow': true, 'second-margin': slots.Idtransaction === '2'}">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Empty Slot</p>
                    <div class="blue bg-time" *ngFor="let times of this.slotstime">
                      <p style="text-align: center;" *ngIf="times.idslot.toString() === slots.Idtransaction">
                        {{times.day}}
                        days,
                        {{times.hours}} hours</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 1 || this.slots2.length === 0">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 2">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 3, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 3, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 3">
              <ng-template swiperSlide>
                <div (click)="handleOpenBuySlotsModal('buy-slots', 4, 'specific-slot','',0,0)"
                  class="empty-box box-dashboard shadow">
                  <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                  <p class="empty-paragraph">Buy Slot</p>
                  <div class="blue bg-time">
                    <p style="text-align: center;">10 RCT</p>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slotsPerMonth.length > 0">
              <ng-container *ngFor="let slots of this.slotsPerMonthTwo; let i = index">
                <ng-template swiperSlide *ngIf="slots.Iduser.trim() === this.userIdProfile && slots.Month === 0"
                  class="expired box-dashboard shadow">
                  <ng-container *ngIf="slots.Idproperty === 0">
                    <fa-icon
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="house-expired-icon house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
                    <fa-icon
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="lock-icon" [icon]="faLock"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="slots.Idproperty !== 0">
                    <img [src]="toSlotPhoto(this.slotsPerMonth[i])" alt="Single family house by the lake" />
                  </ng-container>
                  <div (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                    *ngIf="slots.Idproperty === 0" class="orange bg-time">
                    <p>EXPIRED - Renew this slot?</p>
                  </div>
                  <div *ngIf="slots.Idproperty !== 0" class="orange bg-time">
                    <p>EXPIRED - Renew this slot?</p>
                  </div>
                  <div *ngIf="slots.Idproperty !== 0" class="actions">
                    <button
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="view-button">RENEW SLOT</button>
                    <button (click)="deletePropertySlot(this.slotsPerMonth[i].Id)" class="delete-button">DELETE
                      SLOT</button>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="this.slotsPerMonth.length === 0">
              <ng-container *ngFor="let slotsProp of this.slotsPerMonthTwo">
                <ng-template swiperSlide
                  *ngIf="slotsProp.Iduser.trim() === this.userIdProfile && slotsProp.Month === 0">
                  <div (click)="handleOpenBuySlotsModal('renew-slots', slotsProp.Idtransaction, 'specific-slot','',0,0)"
                    class="expired box-dashboard shadow">
                    <fa-icon class="house-expired-icon house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
                    <fa-icon class="lock-icon" [icon]="faLock"></fa-icon>
                    <div class="orange bg-time">
                      <p>EXPIRED - Renew this slot?</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </swiper>

          <div (click)="handleOpenBuySlotsModal('buy-slots', null, 'all','',0,0)"
            class="more-slot box-dashboard shadow">
            <fa-icon class="house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
            <fa-icon class="blue-lock-icon lock-icon" [icon]="faLock"></fa-icon>
            <p>Buy more slots</p>
          </div>
        </div>

        <div class="d-flex d-flex-to-center mb-5 flex-wrap flex-xl-nowrap gap-box">
          <div class="margin-to-tablet">
            <div class="display-box box-widthAndHeight">
              <div class="total__list__box">
                <div>
                  <p>{{ this.totalProperties?.length }}</p>
                  <span>Published properties</span>
                </div>
              </div>
              <div class="my-properties shadow bg-white">
                <h3 class="ml-2 my-properties-title">My properties</h3>
                <div class="status-area">
                  <div>
                    <p><span>{{ this.totalLeaseProperties?.length }}</span> lease</p>
                    <!-- <p><span>{{this.totalPropertiesLeased?.length}}</span> leased</p> -->
                    <!-- <p><span>2</span> for sale</p> -->
                  </div>
                  <div>
                    <p><span>{{this.totalLeased?.length}}</span> leased</p>
                  </div>
                  <!-- <div>
                    <p><span>3</span> leased</p>
                    <p><span>2</span> sold</p>
                  </div> -->
                </div>
                <a class="dashboard-link mt-6" routerLink="/myproperties">Go to all Properties</a>
              </div>
            </div>
          </div>
          <div>
            <div class="tenant__box shadow box-widthAndHeight bg-white">
              <div>
                <h3 class="tenant-title">Open Tenant Payments</h3>
                <div class="info-display">
                  <div class="status-payment-area">
                    <p class="p-hover"><span>{{this.openStatus.length}}</span> Open payments</p>
                    <p class="p-hover"><span>{{this.closedStatus.length}}</span> Closed payments</p>
                    <p class="p-hover"><span>{{this.dueStatus.length}}</span> Due payments</p>
                  </div>
                  <div class="img-hand">
                    <fa-icon class="holding" [icon]="faHandHoldingDollar"></fa-icon>
                  </div>
                </div>
              </div>
              <a class="dashboard-link" routerLink="/alleventspayments">Go to all Payments</a>
            </div>
          </div>
          <div class="events-box shadow bg-white">
            <h3>Open Events</h3>
            <p>{{this.totalEvents}}</p>
            <a style="text-decoration: underline; cursor: pointer;" class="dashboard-link"
              (click)="navigateToPageEvent('events','IDEvent',1)">Go to all events</a>
          </div>
        </div>
        <div class="d-flex d-flex-to-center  mb-5 flex-wrap flex-xl-nowrap gap-box">
          <div class="profile_info shadow bg-white">
            <h3>My Rating</h3>
            <div class="user__photo">
              <ng-container *ngIf="this.userPhoto === null">
                <img src="assets/images/User-Default.jpg" alt="{{this.userName}} photo">
              </ng-container>
              <ng-container *ngIf="!this.userPhoto?.startsWith('https') && this.userPhoto !== null">
                <img src="{{this.apiUrl + this.userPhoto}}" alt="{{this.userName}} photo">
              </ng-container>
              <ng-container *ngIf="this.userPhoto?.startsWith('https')">
                <img src="{{this.userPhoto}}" alt="{{this.userName}} photo">
              </ng-container>
            </div>
            <p class="profile__info-name">{{ this.userName }}</p>
            <div class="profile_info-note">
              <div class="stars-note">
                <ng-container *ngIf="this.ratingLength <= 0">
                  <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                    <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.ratingLength > 0">
                  <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                    <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                      <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                    </ng-container>
                    <ng-container
                      *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                      <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                      <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                      <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <div class="number_note">
                <p>{{this.ratingCalc}}/5</p>
                <span>({{this.ratingLength}})</span>
              </div>
            </div>
            <a class="dashboard-link" style="cursor: pointer;" *ngIf="this.ratingLength > 0"
              (click)="navigateToPage('userreviews','id',this.userIdProfile)">See all
              reviews</a>
          </div>
          <div class="boosted-box shadow bg-white">
            <h3>Boosted properties</h3>
            <ul style="list-style: none;" class="list-tenant list-boosted">
              <li class="first">Property</li>
              <li class="second">Time Left</li>
              <li>Boosters</li>
            </ul>
            <div class="info-boosters-display">
              <div class="to-margin" *ngIf="this.propretiesBoostedArr.length > 0">
                <div *ngFor="let array of this.propretiesBoostedArr" class="boosters-info">
                  <div class="booster-to-landlord">
                    <p class="property-name name-desktop">
                      {{array.Name.substring(0,10) + "..."}}
                    </p>
                    <p class="property-name name-mobile">
                      {{array.Name}}
                    </p>
                    <div class="boosters-info">
                      <p class="featured-tag" *ngIf="array.Booster === true">
                        <span>Top:</span> {{array.Top <= 0 ? 'Expired' : array.Top + 'days'}}
                      </p>
                      <p class="featured-tag" *ngIf="array.Highlight === true">
                        <span>Featured:</span>
                        {{array.Featured <= 0 ? 'Expired' : array.Featured + 'days'}}
                      </p>
                    </div>
                    <div class="booster-display">
                      <div class="gap-booster">
                        <div (click)="handleOpenBuySlotsModal('renew-booster', '', 'boosted',array.price,array.Id, '')"
                          class="arrow" *ngIf="array.Booster === true && array.Top > 0">
                          <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                        </div>
                        <div (click)="handleOpenBuySlotsModal('buy-booster', '', 'boosted',array.price,array.Id, '')"
                          class="arrow" *ngIf="array.Booster !== true || array.Top <= 0">
                          <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                        </div>
                      </div>
                      <div>
                        <div class="gap-booster">
                          <div
                            (click)="handleOpenBuySlotsModal('buy-booster', '', 'highlight',array.price,array.Id, '')"
                            class="arrow star" *ngIf="array.Highlight !== true || array.Featured <= 0">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </div>
                          <div
                            (click)="handleOpenBuySlotsModal('renew-booster', '', 'highlight',array.price,array.Id, '')"
                            class="arrow star" *ngIf="array.Highlight === true && array.Featured > 0">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: center; width: 100%;"
                *ngIf="this.propretiesBoostedArr.length ===0">
                <p style="color: #0066B2; font-size: 16px; font-weight: 600;">
                  No Boosted Properties!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>
<section style="margin-top: 15px;" *ngIf="this.authenticationService.customizedroles === 'tenant'">
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
    <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
      <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
        <div style="height: 279px">
          <div class="shadow-xs-1 py-3">
            <div class="d-flex px-3 px-xl-6 w-100"></div>
            <app-leftmenumypainel></app-leftmenumypainel>
          </div>
        </div>
      </nav>
    </div>
    <div class="page-content">
      <div *ngIf="this.openModal === true" class="modal-background">
        <div class="modal-buy shadow">
          <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
            Payment
          </h3>
          <button (click)="closeModal()" class="close-icon">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <div class="modal-overflow" style="overflow-y: auto; height: 400px; padding-right: 20px">
            <form *ngIf="this.stepPayment === 'add-card'" style="margin-bottom: 0" type="submit"
              class="payment-method-section">
              <div (click)="paymentModal()" id="paypal-box" class="paypal-box box-to-position shadow">
                <div class="dolar-symbol">
                  <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                </div>
                <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="paypal"
                  class="heading-3">
                  PayPal
                </p>
              </div>
              <!-- (click)="setCurrent('credit', $event)" -->
              <div id="credit-box" class="card-box box-to-position shadow blocked">
                <div class="dolar-symbol">
                  <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                </div>
                <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="credit"
                  class="heading-3">
                  Credit Card
                </p>
                <div class="payment-form" *ngIf="this.typeCurrent.length > 0">
                  <div class="owner-control">
                    <label style="margin-bottom: 0; font-weight: bold; color: black" for="owner-name">Owner Name</label>
                    <input placeholder="John Wayme" class="modal-input" type="text" name="owner-name" id="owner-name" />
                  </div>
                  <div class="card-info">
                    <input class="modal-input card-input" type="text" name="card" id="card"
                      placeholder="xxxx-xxxx-xxxx-1234" />
                    <div class="owner-control">
                      <label style="
                          margin-bottom: 0;
                          font-weight: bold;
                          color: black;
                        " for="cvv">CVV</label>
                      <input class="modal-input" type="number" name="cvv" id="cvv" placeholder="xxx" />
                    </div>
                  </div>
                  <div class="date-company">
                    <input class="modal-input" type="text" name="date" id="date" placeholder="2021, January 21" />
                    <div class="bank-companies">
                      <div class="visa to-style">
                        <img src="../../assets/images/visa-logo.png" alt="Visa logo" />
                      </div>
                      <div class="mastercard to-style">
                        <img src="../../assets/images/mastercard-logo.png" alt="mastercard logo" />
                      </div>
                      <div style="padding: 10px 5px" class="express to-style">
                        <img style="width: 80px" src="../../assets/images/american-express-logo.png"
                          alt="American express logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  margin-right: 34px;
                ">
                <button (click)="changeStep()" *ngIf="this.typeCurrent.length > 0" class="pay-button">
                  Pay now
                </button>
              </div>
            </form>
            <form *ngIf="this.stepPayment === 'billing'" style="margin-bottom: 20px" type="submit"
              class="payment-method-section">
              <h3 class="heading-3">Billing Details</h3>
              <div class="input-fields">
                <div class="first-column">
                  <div class="owner-control">
                    <label class="label" for="first-name">First Name</label>
                    <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="text"
                      name="first-name" id="first-name" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="last-name">Last Name</label>
                    <input style="background-color: white;" placeholder="James" class="modal-input" type="text"
                      name="last-name" id="last-name" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="email">Email</label>
                    <input style="background-color: white;" placeholder="saraj@gmail.com" class="modal-input"
                      type="email" name="email" id="email" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="phone">Phone</label>
                    <input style="background-color: white;" placeholder="+1 1234 1234" class="modal-input" type="text"
                      name="phone" id="phone" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="id-number">ID Number</label>
                    <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="number"
                      name="id-number" id="id-number" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="vat">VAT</label>
                    <input style="background-color: white;" placeholder="1234567" class="modal-input" type="number"
                      name="vat" id="vat" />
                  </div>
                </div>
                <div style="margin-top: 20px;" class="owner-control">
                  <label class="label" for="address">Address line 1</label>
                  <input style="background-color: white;" placeholder="123 Fakestreet" class="modal-input" type="text"
                    name="address" id="address" />
                </div>
                <div style="margin-top: 20px;" class="owner-control">
                  <label class="label" for="address-two">Address line 2</label>
                  <input style="background-color: white;" placeholder="" class="modal-input" type="text"
                    name="address-two" id="address-two" />
                </div>
                <div style="gap: 20px 30px;" class="first-column">
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="country">Country</label>
                    <input style="background-color: white;" placeholder="USA" class="modal-input" type="text"
                      name="country" id="country" />
                  </div>
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="address-two">State</label>
                    <input style="background-color: white;" placeholder="New Mexico" class="modal-input" type="text"
                      name="state" id="state" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="city">City</label>
                    <input style="background-color: white;" placeholder="Roswell" class="modal-input" type="text"
                      name="city" id="city" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="zip-code">ZIP CODE</label>
                    <input style="background-color: white;" placeholder="512345" class="modal-input" type="text"
                      name="zip-code" id="zip-code" />
                  </div>
                </div>
                <div class="terms-section">
                  <div (click)="checkTerms()" class="checkbox">
                    <div class="checked" *ngIf="this.checked === true"></div>
                  </div>
                  <p class="dashboard-link" class="term">I have read and agree to the <a href="#">terms of use</a></p>
                </div>
                <div class="end-button">
                  <button [disabled]="this.checked === true ? false : true" class="submit-button">SUBMIT & PAY</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <main id="content">
        <h2 class="component__title">Dashboard</h2>
        <div style="align-items: flex-start;" class="d-flex d-flex-to-center mb-5 flex-wrap flex-xl-nowrap gap-box">
          <div class="personal-information">
            <div class="profile_info profile-tenant shadow bg-white">
              <h3 class="title">My Rating</h3>
              <div class="user__photo">
                <ng-container *ngIf="this.userPhoto === null">
                  <img src="assets/images/User-Default.jpg" alt="{{this.userName}} photo">
                </ng-container>
                <ng-container *ngIf="!this.userPhoto?.startsWith('https') && this.userPhoto !== null">
                  <img src="{{this.apiUrl + this.userPhoto}}" alt="{{this.userName}} photo">
                </ng-container>
                <ng-container *ngIf="this.userPhoto?.startsWith('https')">
                  <img src="{{this.userPhoto}}" alt="{{this.userName}} photo">
                </ng-container>
              </div>
              <p class="profile__info-name">{{ this.userName }}</p>
              <div class="profile_info-note">
                <div class="profile_info-note">
                  <div class="stars-note">
                    <ng-container *ngIf="this.ratingLength <= 0">
                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="this.ratingLength > 0">
                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                        <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                          <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="number_note">
                  <p>{{this.ratingCalc}}/5</p>
                  <span>({{this.ratingLength}})</span>
                </div>
              </div>
              <a class="dashboard-link" style="cursor: pointer;" *ngIf="this.ratingLength > 0"
                (click)="navigateToPage('userreviews','id',this.userIdProfile)">See all
                reviews</a>
            </div>
            <div style="margin-top: 20px;" class="profile_info shadow bg-white">
              <h3 class="title">Open Payment Events</h3>
              <ul class="list-tenant list-payment" style="list-style: none; display: flex  !important;">
                <li class="first-item">Property</li>
                <li class="second-item">Date</li>
                <li class="third-item">Status</li>
              </ul>
              <div [style.margin-bottom.px]="this.eventsPayment.length > 0 ? 0 : 20" class="events-tenant-box">
                <ng-container *ngIf="this.eventsPayment.length > 0">
                  <div style="display: flex;" class="events-tenant-payment" *ngFor="let events of this.eventsPayment">
                    <p class="openevents-name" [title]="events.property" style="margin-bottom: 0;">
                      {{ events.property.length > 18 ? events.property.substring(0, 15) : events.property }}</p>
                    <span class="events-date">{{events.date}}</span>
                    <span style="cursor: pointer;" *ngIf="events.status === 'Open' || events.status === 'refused'"
                      (click)="handleOpenBuySlotsModal('events', '', events.status, events.value, events.idProperty,events.date)"
                      [ngClass]="{'status-tenant': true,'open-status': events.status === 'Open','refused-status': events.status === 'refused'}">
                      {{events.status}}
                    </span>
                    <span *ngIf="events.status !== 'Open' && events.status !== 'refused'"
                      style="text-transform: capitalize; cursor: default !important;"
                      [ngClass]="{'status-tenant': true, 'paid-status': events.status === 'paid', 'due-status': events.status === 'Due', 'pendent-status': events.status === 'pendent'}">
                      {{events.status === 'pendent' ? 'Waiting' : events.status}}
                    </span>
                  </div>
                </ng-container>
                <div *ngIf="this.eventsPayment.length === 0">
                  <p
                    style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600; margin-bottom: 20px;">
                    No Open Payments Events!
                  </p>
                </div>
              </div>
              <a class="dashboard-link" style="margin-top: 10px;" routerLink="/alleventspayments">Go to all Payments</a>
            </div>
          </div>
          <div class="boosted-box open-events-tenant shadow bg-white events-open-tenants">
            <h3 class="title">Open Events</h3>
            <ul class="list-tenant list-none" style="list-style: none; margin-left: 15px;">
              <li>Type</li>
              <li class="list-tenant-name">Name</li>
              <li class="list-tenant-step">Step</li>
              <li>Action</li>
            </ul>
            <div>
              <div style="flex-direction: column;" class="info-boosters-display info-tenant-open events-height">
                <ng-container *ngIf="this.openEventsPayment.length > 0">
                  <div class="open-info shadow" *ngFor="let openEvents of this.openEventsPayment">
                    <span>{{openEvents.type}}</span>
                    <span
                      [title]="openEvents.name">{{openEvents.name.length > 10 ? openEvents.name.substring(0,10) + "..." : openEvents.name}}</span>
                    <span class="step-width">{{openEvents.step}}</span>
                    <button (click)="navigateToPage('chat','chatroomguid',openEvents.action)">Open</button>
                  </div>
                </ng-container>
                <div *ngIf="this.openEventsPayment.length === 0">
                  <p style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600;">
                    No Open Events!
                  </p>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center; margin-top: 15px;">
              <a class="dashboard-link" href="/events?IDEvent=1">Go to all Events</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>
<section style="margin-top: 15px;" *ngIf="this.authenticationService.customizedroles === 'vendor'">
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
    <div id="showDesktopDashboardNavBar" class="db-sidebar sidebar-vendor bg-white">
      <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
        <div style="height: 279px">
          <div class="shadow-xs-1 py-3">
            <div class="d-flex px-3 px-xl-6 w-100"></div>
            <app-leftmenumypainel></app-leftmenumypainel>
          </div>
        </div>
      </nav>
    </div>
    <div class="page-content">
      <div class="modal-background" *ngIf="this.openModal">
        <div class="modal-slots shadow" [ngClass]="{
          'to-padding': this.step === 'buy-slots'
        }">
          <button (click)="closeModal()" [ngClass]="{
            'close-icon': true,
            'close-slot-button': this.step === 'buy-slots' || this.step === 'renew-slots'
          }">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <div *ngIf="this.step === 'select-slot'">
            <h3>My services</h3>
            <p *ngIf="this.slots2.length === 0" class="available-slots">
              {{ this.slotsPreviewArr.length ? this.slotsPreviewArr.length : '0'}}/1
              available
              slots
            </p>
            <p *ngIf="this.slots2.length > 0" class="available-slots">
              {{ this.slotsPreviewArr.length ? this.slotsPreviewArr.length : '0'}}/{{ this.slotsDiffMonth.length > 0 ?  this.slotsDiffMonth.length - this.slotsPropFiltered.length  : this.slotsEqualPropFiltered.length }}
              available
              slots
            </p>
            <div class="modal-properties">
              <ng-container *ngIf="this.services.length > 0">
                <div *ngFor="let service of this.services">
                  <div class="properties-slots-info">
                    <div class="principal-slot-info">
                      <img class="shadow" src="{{this.apiUrl}}/{{service.imageOne}}" alt="Properties" />

                      <div class="properties-name">
                        <h5>{{ service.title }}</h5>
                      </div>
                    </div>
                    <div class="type-date-propertie">
                      <!-- <p *ngIf="properties.PropertySellType === 'ForRent'">
                        For Rent
                      </p>
                      <p *ngIf="properties.PropertySellType !== 'ForRent'">
                        {{ properties.PropertySellType }}
                      </p> -->
                      <p>
                        Available
                      </p>
                      <span>
                        {{ service.create_date.slice(0, 2).includes('/') ? '0' + service.create_date : service.create_date }}
                      </span>
                    </div>
                    <button (click)="addSlot($event)" class="add-button" id="{{ service.id }}">
                      ADD TO SLOT
                    </button>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="this.services.length === 0">
                <p style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600;">
                  No Services!
                </p>
              </div>
            </div>
            <div class="bottom-buttons">
              <button class="cancel-button button-modal" (click)="closeModal()">
                CANCEL
              </button>
              <button (click)="addOrUpdateSlot()" class="confirm-button button-modal">CONFIRM</button>
            </div>
          </div>
          <div *ngIf="this.step === 'buy-slots' || this.step === 'renew-slots'">
            <a style="text-decoration: none;" class="user-cash-dashboard">
              <span>{{ this.cash.toFixed(2) }}</span>
              <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
            </a>
            <h3 class="modal-title">
              {{this.step === 'renew-slots' ? 'RENEW' : 'BUY'}} SLOT
            </h3>
            <div class="box-slots" [ngClass]="{
              'radius': this.total > this.cash,
              'radius-all': this.total < this.cash
            }">
              <div class="box-display">
                <img class="locked-house" src="../../assets/images/houseWithPadlock.png"
                  alt="House with a padlock in your center">
                <div class="texts-about-slots">
                  <p>{{this.step === 'renew-slots' ? 'Renew' : 'Buy'}} Service Slots</p>
                  <span>Slots allow you to make public your services listings</span>
                </div>
              </div>
              <div class="info-for-slots">
                <div *ngIf="this.specSlot !== 'specific-slot'" class="same-styles" style="margin-bottom: 10px;">
                  <p style="margin-bottom: 0;">Number of Slots</p>
                  <input type="text" name="slots" maxlength="2" (input)="changeSlotsValue($event)"
                    [value]="this.valueSlots" [ngClass]="{'less-then-ten': this.valueMonth <= 10}" id="slots"
                    [disabled]="this.specSlot === 'specific-slot' ? true : false">
                </div>
                <div class="same-styles">
                  <p style="margin-bottom: 0;">Duration month</p>
                  <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                    [value]="this.valueMonth" (input)="changeMonthValue($event)" id="month">
                </div>
                <div class="number">
                  <p style="margin-bottom: 0;">Total</p>
                  <span>{{this.total.toFixed(2)}} RCT</span>
                </div>
              </div>
            </div>
            <div *ngIf="this.total > this.cash" class="warn-orange">
              <span>insufficient RCT on your Wallet</span>
              <a routerLink="/wallet" class="user-cash buy-more-button"
                style="border-radius: 5px !important; color: #27449B !important;">Buy more RCT
                <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
              </a>
            </div>
            <div class="warning warning-slots">
              <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
              <p style="width: 100%; text-align: justify;">
                By completing the purchase, the funds will be taken from your wallet and the slots will be available on
                your account.
              </p>
            </div>
            <div *ngIf="this.step === 'buy-slots'" [ngClass]="{
              'top-short-distance': this.total > this.cash,
              'top-distance': this.total < this.cash
            }">
              <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new slots will be
                available until:</p>
              <p class="heading-3 available-date">{{this.dateavailable}}
              </p>
            </div>
            <!-- <div class="no-slots" *ngIf="this.slots2.length === 4 && this.step === 'buy-slots'">
              <p>No more slots</p>
            </div> -->
            <div class="end-button">
              <button *ngIf="this.step === 'renew-slots'"
                [disabled]="this.total > this.cash ? true : this.slots2.length === 0 ? true : this.valueMonthInNumber === 0 ? true : false"
                class="submit-button" (click)="renewSlot()">SUBMIT & PAY</button>
              <button *ngIf="this.step !== 'renew-slots'"

                class="submit-button" (click)="buySlot()">SUBMIT & PAY</button>
            </div>
          </div>
        </div>
      </div>
      <main id="content">
        <h2 class="component__title">Dashboard</h2>
        <div style="gap: 15px;" class="d-flex position d-flex-to-center mb-5 flex-wrap flex-xl-nowrap">
          <swiper #usefulSwiper [config]="config">
            <ng-container *ngIf="this.slots2[0]?.Idproperty === 0 || this.slots2.length === 0">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('select-slot', 1, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Empty Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">Free</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngFor="let slotsProp of this.slots">
              <ng-container *ngFor="let slots of this.slots2">
                <ng-container *ngIf="slots.Idproperty.toString() === slotsProp.id.toString() && slots.Month >= 1">
                  <ng-template swiperSlide>
                    <div
                      [ngClass]="{'first-box': true, 'box-dashboard': true, 'shadow': true, 'in-draft': slots.status === 'Draft', 'second-margin': slots.Idtransaction === '2' || slots.Idtransaction === '3'}">
                      <img src="{{this.apiUrl}}/{{slotsProp.imageOne}}" alt="Single family house by the lake" />
                      <div class="property-info">
                        <div class="to-mobile-name">
                          <h4>{{slotsProp.title.substring(0,10) + "..."}}</h4>
                        </div>
                        <div class="to-desktop-name" style="  overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;">
                          <h4>{{slotsProp.title}}</h4>
                        </div>
                        <div class="review-area review-area-vendor">
                          <div class="profile_info-note">
                            <div class="stars-note to-vendor">
                              <ng-container *ngIf="lengthRating(slotsProp.totalrating)">
                                <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="!lengthRating(slotsProp.totalrating)">
                                <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                  <ng-container *ngIf="slotsProp.rating > ratingConditional.moreThan">
                                    <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                                  </ng-container>
                                  <ng-container *ngIf="slotsProp.rating > ratingConditional.inBetweenLess && slotsProp.rating <= ratingConditional.inBetweenMore">
                                    <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                    <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                                  </ng-container>
                                  <ng-container *ngIf="slotsProp.rating <= ratingConditional.lessThan">
                                    <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <div class="services-review">
                            <p>{{lengthRating(slotsProp.rating) ? '0' : slotsProp.rating}}/5</p>
                            <span>({{lengthRating(slotsProp.totalrating) ? '0' : slotsProp.totalrating}})</span>
                          </div>
                        </div>
                      </div>
                      <div class="actions">
                        <button (click)="goToService(slotsProp.id)" class="view-button">VIEW SERVICE</button>
                        <button (click)="deletePropertySlot(slotsProp.id)" class="delete-button">DELETE SLOT</button>
                      </div>
                      <ng-container *ngFor="let times of this.slotstime">
                        <ng-container *ngIf="times.idslot.toString() === slots.Idtransaction">
                          <div class="blue bg-time bg-time-position" vv>
                            <p style="text-align: center;">
                              {{times.day}}
                              days,
                              {{times.hours}} hours</p>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let slots of this.slots2Filtered">
              <ng-container *ngIf="slots.Idproperty === 0 && slots.Month >= 1">
                <ng-template swiperSlide>
                  <div (click)="handleOpenBuySlotsModal('select-slot', slots.Idtransaction, 'specific-slot','',0,0)"
                    [ngClass]="{'empty-box': true, 'box-dashboard': true, 'shadow': true, 'second-margin': slots.Idtransaction === '2'}">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Empty Slot</p>
                    <div class="blue bg-time" *ngFor="let times of this.slotstime">
                      <p style="text-align: center;" *ngIf="times.idslot.toString() === slots.Idtransaction">
                        {{times.day}}
                        days,
                        {{times.hours}} hours</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 1 || this.slots2.length === 0">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 2, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 2">
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 3, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template swiperSlide>
                <ng-container>
                  <div (click)="handleOpenBuySlotsModal('buy-slots', 3, 'specific-slot','',0,0)"
                    class="empty-box box-dashboard shadow">
                    <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                    <p class="empty-paragraph">Buy Slot</p>
                    <div class="blue bg-time">
                      <p style="text-align: center;">10 RCT</p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slots2.length === 3">
              <ng-template swiperSlide>
                <div (click)="handleOpenBuySlotsModal('buy-slots', 4, 'specific-slot','',0,0)"
                  class="empty-box box-dashboard shadow">
                  <fa-icon class="plus-sign" [icon]="faPlus"></fa-icon>
                  <p class="empty-paragraph">Buy Slot</p>
                  <div class="blue bg-time">
                    <p style="text-align: center;">10 RCT</p>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="this.slotsPerMonth.length > 0">
              <ng-container *ngFor="let slots of this.slotsPerMonthTwo; let i = index">
                <ng-template swiperSlide *ngIf="slots.Iduser.trim() === this.userIdProfile && slots.Month === 0"
                  class="expired box-dashboard shadow">
                  <ng-container *ngIf="slots.Idproperty === 0">
                    <fa-icon
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="house-expired-icon house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
                    <fa-icon
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="lock-icon" [icon]="faLock"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="slots.Idproperty !== 0">
                    <img src="{{this.apiUrl}}/{{this.slotsPerMonth[i].imageOne}}"
                      alt="Single family house by the lake" />
                  </ng-container>
                  <div (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                    *ngIf="slots.Idproperty === 0" class="orange bg-time">
                    <p>EXPIRED - Renew this slot?</p>
                  </div>
                  <div *ngIf="slots.Idproperty !== 0" class="orange bg-time">
                    <p>EXPIRED - Renew this slot?</p>
                  </div>
                  <div *ngIf="slots.Idproperty !== 0" class="actions">
                    <button
                      (click)="handleOpenBuySlotsModal('renew-slots', slots.Idtransaction, 'specific-slot','',0,0)"
                      class="view-button">RENEW SLOT</button>
                    <button (click)="deletePropertySlot(this.slotsPerMonth[i].id)" class="delete-button">DELETE
                      SLOT</button>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="this.slotsPerMonth.length === 0">
              <ng-container *ngFor="let slotsProp of this.slotsPerMonthTwo">
                <ng-template swiperSlide
                  *ngIf="slotsProp.Iduser.trim() === this.userIdProfile && slotsProp.Month === 0">
                  <div class="expired box-dashboard shadow"
                    (click)="handleOpenBuySlotsModal('renew-slots', slotsProp.Idtransaction, 'specific-slot','',0,0)">
                    <fa-icon class="house-expired-icon house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
                    <fa-icon class="lock-icon" [icon]="faLock"></fa-icon>
                    <div class="orange bg-time">
                      <p>EXPIRED - Renew this slot?</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </swiper>

          <div (click)="handleOpenBuySlotsModal('buy-slots', null, 'all','',0,0)"
            class="more-slot box-dashboard shadow">
            <fa-icon class="house-icon" [icon]="faHouseChimneyWindow"></fa-icon>
            <fa-icon class="blue-lock-icon lock-icon" [icon]="faLock"></fa-icon>
            <p>Buy more slots</p>
          </div>
        </div>
        <div class="d-flex d-flex-to-center mb-5 flex-wrap flex-xl-nowrap gap-box">
          <div>
            <div class="profile_info profile-vendor profile-tenant shadow bg-white">
              <div class="vendor-overflow">
                <h3 class="title" style="text-align: center;">My Average Rating</h3>
                <div class="vendor-profile">
                  <div class="user__photo">
                    <ng-container *ngIf="this.userPhoto === null">
                      <img src="assets/images/User-Default.jpg" alt="{{this.userName}} photo">
                    </ng-container>
                    <ng-container *ngIf="!this.userPhoto?.startsWith('https') && this.userPhoto !== null">
                      <img src="{{this.apiUrl + this.userPhoto}}" alt="{{this.userName}} photo">
                    </ng-container>
                    <ng-container *ngIf="this.userPhoto?.startsWith('https')">
                      <img src="{{this.userPhoto}}" alt="{{this.userName}} photo">
                    </ng-container>
                  </div>
                  <div>
                    <p class="profile-vendor-name">{{ this.userName }}</p>
                    <div class="profile-review">
                      <div class="profile_info-note">
                        <div class="stars-note">
                          <ng-container *ngIf="this.ratingLength <= 0">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="this.ratingLength > 0">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <a *ngIf="this.ratingLength > 0" style="cursor: pointer;" class="all-reviews dashboard-link"
                        (click)="navigateToPage('userreviews','id',this.userIdProfile)">See all
                        reviews</a>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px;">
                  <div style="margin-bottom: 10px;">
                    <span class="vendor-profile-span">Member Since</span>
                    <p>{{this.date[0].date}}</p>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <span class="vendor-profile-span">Address</span>
                    <p>{{this.address === '' ? 'No Address' : this.address}}</p>
                  </div>
                  <!-- <div>
                    <span class="vendor-profile-span">Area of operation</span>
                    <p>Boston DC</p>
                  </div> -->
                </div>
                <div style="margin-top: 25px; margin-right: 10px; margin-bottom: 10px;">
                  <h3 class="title" style="text-align: center; margin-bottom: 30px;">My Services</h3>
                  <div style="display: flex; flex-direction: column; gap: 10px;">
                    <ng-container *ngIf="this.servicesPublished?.length > 0">
                      <div *ngFor="let service of this.servicesPublished" class="services-box shadow">
                        <div>
                          <span style="text-align: center;"
                            class="vendor-profile-span">{{service.title.length > 19 ? service.title.substring(0, 16) + '...' : service.title}}</span>
                          <div class="review-area">
                            <div class="profile-service-review">
                              <div class="stars-note to-vendor-service">
                                <ng-container *ngIf="lengthRating(service.totalrating)">
                                  <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                    <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="!lengthRating(service.totalrating)">
                                  <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                    <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                                      <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                                    </ng-container>
                                    <ng-container *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                                      <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                      <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                                    </ng-container>
                                    <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                                      <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                                    </ng-container>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="services-review">
                              <p>{{lengthRating(service.rating) ? '0' : service.rating}}/5</p>
                              <span>({{lengthRating(service.totalrating) ? '0' : service.totalrating}})</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span style="text-align: center;" class="vendor-profile-span">Area of operation</span>
                          <p style="text-align: center;">
                            {{service.operationArea.length > 19 ? service.operationArea.substring(0,16) + '...' : service.operationArea}}
                          </p>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="this.servicesPublished?.length === 0">
                      <p style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600;">
                        No Published Services!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="boosted-box open-events-tenant shadow bg-white events-open-vendor">
            <h3 class="title">Open Events</h3>
            <ul class="list-vendor" style="list-style: none; margin-left: 15px;">
              <li>Service</li>
              <li class="list-vendor-name">Name</li>
              <li class="list-vendor-step">Step</li>
              <li>Action</li>
            </ul>
            <div *ngIf="this.openEventsVendorPayment.length > 0">
              <div style="flex-direction: column;" class="info-boosters-display info-tenant-open">
                <div class="open-info shadow" *ngFor="let openEvents of this.openEventsVendorPayment">
                  <span>{{openEvents.service}}</span>
                  <span class="event-vendor-name ">{{openEvents.name.substring(0, 12) + '...'}}</span>
                  <span class="event-vendor-step">{{openEvents.step}}</span>
                  <a class="dashboard-link" href="/chat?chatroomguid={{openEvents.action}}">Open</a>
                </div>
              </div>
            </div>
            <div *ngIf="this.openEventsVendorPayment.length === 0">
              <p style="text-align: center; color: #0066B2; font-size: 16px; font-weight: 600;">
                No Open Events!
              </p>
            </div>
            <div *ngIf="this.openEventsVendorPayment.length > 0"
              style="display: flex; justify-content: center; margin-top: 15px;">
              <a class="dashboard-link" style="font-size: 16px;" href="/events?IDEvent=2">Go to all Events</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>
