<div class="container">

    <div class="card">

      <div class="card-header">

          Crop profile Image

      </div>

      <div class="card-body">

        <!--<input type="file" (change)="fileChangeEvent($event)" />-->

        <div class="row" style="margin-top: 15px;">

            <div class="text-center col-md-8">

                <h5>Crop Image</h5>

                <image-cropper 

                [imageChangedEvent]="imageChangedEvent" 

                [maintainAspectRatio]="true" 

                [roundCropper] = "true"

                [aspectRatio]="4 / 4"

                [resizeToWidth]="256" 

                format="png" 

                (imageCropped)="imageCropped($event)" 

                (imageLoaded)="imageLoaded()"

                (cropperReady)="cropperReady()" 

                (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>

            <div class="text-center col-md-4">

                <h5>Preview</h5>

                <img [src]="croppedImage" style="border-radius: 50%"/>

            </div>

        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveCropImage()">Save Photo</button>
    </div>

    </div>

</div>