import { Component, OnInit } from '@angular/core';
import { propertiesService } from '../services/properties.service';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.css'],
})
export class RatingsComponent implements OnInit {
  public ratingLength: any;
  public loading: any = true;
  public roleUser: any = '';
  public a: any = [];
  public arrServices: any = [];
  public arrServicesSliced: any = [];
  public userId: Number;
  public ratingCalc = 0;
  public empty = false
  public slicedReviews = 3;
  public serviceReviews = [];
  public ratingArr = [
    {
      moreThan: 0.9,
      inBetweenLess: 0,
      inBetweenMore: 0.9,
      lessThan: 0,
    },
    {
      moreThan: 1.9,
      inBetweenLess: 1.1,
      inBetweenMore: 1.9,
      lessThan: 1,
    },
    {
      moreThan: 2.9,
      inBetweenLess: 2.1,
      inBetweenMore: 2.9,
      lessThan: 2,
    },
    {
      moreThan: 3.9,
      inBetweenLess: 3.1,
      inBetweenMore: 3.9,
      lessThan: 3,
    },
    {
      moreThan: 4.9,
      inBetweenLess: 4.1,
      inBetweenMore: 4.9,
      lessThan: 4,
    },
  ];
  faStar = faStar;
  faStarHalf = faStarHalf;
  public apiurl: string = '';

  constructor(
    private propertiesService: propertiesService,
    private ngxService: NgxUiLoaderService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.apiurl = environment.apiUrl
    this.ngxService.startBackground('loadmyrating');
    const userinfo = localStorage.getItem('roundcombuserdetail');
    let UserJsonObject = JSON.parse(userinfo);

    this.route.queryParams.subscribe((queryparams: Params) => {
      this.userId = queryparams.id;
    });

    // this.userId = UserJsonObject.userIdProfile;
    this.isuserlandlord(this.userId);
    this.getUserServices();
  }

  lengthRating(rating) {
    let number = Number(rating);
    if (isNaN(number)) {
      return true;
    } else {
      return false;
    }
  }

  loadMoreReviews(service, id) {
    if (this.roleUser === 'Vendor') {
      this.slicedReviews = service + 3;
      service = this.slicedReviews;
      let filter = this.arrServices.filter((el) => {
        return el.id === id;
      });
      filter.forEach((el) => {
        el.slice = service;
      });
    } else {
      this.slicedReviews = this.slicedReviews + 3;
    }
  }

  private getUserServices() {
    this.propertiesService
      .getServices(this.userId)
      .toPromise()
      .then((data) => {
        data.forEach((el) => {
          let date = new Date(el.create_date * 1000);
          return (el.create_date = date.toLocaleDateString('en-US'));
        });

        let filter = data.filter((el) => {
          return el.status === 'AvailableForSell';
        });
        this.getUserRating(data);
      })
      .catch((e) => console.error(e));
  }

  async isuserlandlord(Id) {
    await this.propertiesService
      .getRole(Number(this.userId))
      .pipe()
      .subscribe(async (data) => {
        this.roleUser = data[0].Name;
      });
  }

  private getUserRating(filtered) {
    this.propertiesService
      .getUserRating(this.userId)
      .pipe()
      .subscribe((data) => {
        if (this.roleUser === 'Vendor') {
          let filter = data.filter((el) => {
            return el.serviceId > -1;
          });
          let result = [];
          for (let i of filter) {
            let resp = filtered.filter((el) => {
              return i.serviceId === el.id;
            });

            if (resp[0] !== undefined) {
              result.push(resp[0]);
            }
          }
          const set = new Set([...result]);
          let arr = [...set];
          arr.forEach((el) => {
            el.rating = Number(el.rating);
            el = Object.assign(el, { ratings: [], slice: 3 });
          });
          filter.forEach((element) => {
            let date = new Date(element.create_date * 1000);
            element.create_date = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
          });
          for (let i of filter) {
            arr.forEach((el) => {
              if (i.serviceId === el.id) {
                el.ratings.push(i);
              }
            });
          }
          this.arrServices = arr;
          this.serviceReviews = filter;
          this.loading = false;
        } else {
          if (data.length > 0) {
            data.forEach((el) => {
              el.rating = Number(el.rating);
            });
            data.forEach((element) => {
              let date = new Date(element.create_date * 1000);
              element.create_date = `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()}`;
            });
          }
          this.arrServices = data;
          this.loading = false;
        }
        if (data.length === 0 || this.arrServices.length === 0) {
          this.empty = true
        } else {
          this.empty = false
        }
      });
    this.ngxService.stopBackground('loadmyrating');
  }
}
