<section>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">

        <main id="content" class="bg-gray-01">

          <div class="container event-margin media-width" data-select2-id="7">
            <div style="margin-top: 15px;" class="row" data-select2-id="6">
              <div class="col-12">
                <div class="section-title d-flex align-items-center">
                  <h2>Reset password</h2>

                </div>

                <ng-container *ngIf="!passwordchanged">
                  <form class="form" [formGroup]="resetpasswordForm" (ngSubmit)="setResetPassword()"
                    data-select2-id="5">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="oldPassword" class="text-heading">Old Password</label>
                        <input type="password" formControlName="oldPassword" class="form-control" id="oldPassword"
                          name="oldPassword" [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }">
                        <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                          <div *ngIf="f.oldPassword.errors.required">Old Password is required</div>

                        </div>
                      </div>
                      <div class="form-group col-md-6">

                      </div>
                      <div class="form-group col-md-6">
                        <label for="newPassword" class="text-heading">New Password</label>
                        <input type="password" formControlName="newPassword" class="form-control" id="newPassword"
                          name="newPassword" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }">
                        <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                          <div *ngIf="f.newPassword.errors.required">New Password is required</div>

                        </div>
                      </div>
                      <div class="form-group col-md-6">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="confirmNewPassword" class="text-heading">Confirm New
                          Password</label>
                        <input type="password" formControlName="confirmNewPassword" class="form-control"
                          id="confirmNewPassword" name="confirmNewPassword"
                          [ngClass]="{ 'is-invalid': submitted && f.confirmNewPassword.errors }">
                        <div *ngIf="submitted && f.confirmNewPassword.errors" class="invalid-feedback">
                          <div *ngIf="f.confirmNewPassword.errors.required">Confirm Password is required</div>

                        </div>
                      </div>


                      <hr class="my-5">
                      <div class="col-md-12">
                        <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}
                        </div>

                        <button class="btn btn-primary" type="submit">Reset password</button>
                      </div>
                    </div>
                  </form>
                </ng-container>

                <ng-container *ngIf="passwordchanged">
                  <div class="card-body p-6">
                    <p class="mb-4">Your password has been reset. You can now use the new password to login to your
                      account.</p>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
