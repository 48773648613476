<section *ngIf="this.loading">
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
</section>
<section *ngIf="!this.loading">
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">

        <main id="content" class="bg-gray-01">
          <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" style="padding-top: 15px !important;"
            data-animated-id="1">
            <div class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15" *ngIf="!this.isuserlandlord()">My services<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.services.length}}</span>
                </h2>

              </div>
              <div class="form-inline justify-content-md-end">

                <form class="form-inline" style="gap: 20px;" *ngIf="!this.isuserlandlord()">
                  <label class="sr-only" for="inlineFormInputName2">Name</label>
                  <input type="search" class="form-control" id="inlineFormInputName2" placeholder="Search"
                    id="freetextfilter" #s (input)="searchAService($event)">

                  <label class="sr-only" for="inlineFormInputGroupUsername2">Status</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Status</div>
                    </div>
                    <select class="form-control" id="exampleFormControlSelect1" name="sortby" #t
                      (change)="onstatuschanged(t.value)">
                      <option [ngValue]="1" value="1" selected>All</option>
                      <option [ngValue]="2" value="2">Published</option>
                      <option [ngValue]="3" value="3">Unpublished</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="this.services.length > 0" class="table-responsive  to-list-desktop">
              <table class="table table-hover bg-white border rounded-lg">
                <thead class="thead-sm thead-black head-responsive">
                  <tr>
                    <th scope="col" class="border-top-0 px-6 pt-5 pb-4">Listing title</th>
                    <th scope="col" style="white-space: nowrap;" class="border-top-0 pt-5 pb-4">Date Published</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Status</th>
                    <ng-container *ngIf="this.isuserlandlord()">
                      <th scope="col" class="border-top-0 pt-5 pb-4">Views</th>
                    </ng-container>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-hover-xs-2 bg-hover-white prop-responsive"
                    *ngFor="let service of this.services | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count } ">

                    <td class="align-middle pt-6 pb-4 px-6">
                      <div class="media">
                        <div class="w-120px mr-4 position-relative">
                          <a class="text-dark hover-primary">
                            <img style="display:block;width: 110px;height: auto;max-height: 80px;"
                              src="{{this.apiurl}}/{{service.imageOne}}" alt="">
                          </a>
                          <!-- <span class="badge badge-indigo position-absolute pos-fixed-top">{{property.selltype}}</span> -->
                        </div>
                        <div class="media-body">
                          <a class="text-dark hover-primary" style="cursor: pointer">
                            <h5 style="text-align: left !important; width: 109px !important;" class="fs-16 mb-0 lh-18">
                              {{service.title}}</h5>
                          </a>
                          <p class="mb-1 font-weight-500"></p>
                          <!--  <span class="text-heading lh-15 font-weight-bold fs-17">${{service.price}}</span>
                          <span class="text-gray-light">/month</span> -->
                        </div>
                      </div>
                    </td>
                    <td class="align-middle" style="width: 116px !important;">
                      {{ service.create_date.slice(0, 2).includes('/') ? '0' + service.create_date : service.create_date }}
                    </td>
                    <td class="align-middle" style="width: 126px !important;">
                      <ng-container *ngIf="service.status=='AvailableForSell'">
                        <span class="badge text-capitalize font-weight-normal fs-12 badge-green">Published</span>
                      </ng-container>
                      <ng-container *ngIf="service.status=='Draft'">
                        <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">Unpublished</span>
                      </ng-container>

                      <ng-container *ngIf="service.status=='Cancelled'">
                        <span
                          class="badge text-capitalize font-weight-normal fs-12 badge-pink">{{service.status}}</span>
                      </ng-container>
                    </td>
                    <td class="align-middle actions-responsive">
                      <a (click)="this.navigateToServicesDetails(service.id)"
                        class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">View
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span></a>
                      <a (click)="this.navigateToPropertyEdit(service.id)" title="Edit"
                        class="d-inline-block fs-18 text-muted hover-primary mr-5" style="cursor: pointer"
                        data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                      <ng-container *ngIf="service.Status!='Cancelled'">
                        <a (click)="deletepropertyConfirmation(service.id)" data-toggle="tooltip" title="Cancel Publish"
                          class="d-inline-block fs-18 text-muted mr-5 hover-primary" data-original-title="Delete"
                          style="cursor: pointer"><i class="fal fa-trash-alt"></i></a>
                      </ng-container>
                      <ng-container *ngIf="service.Status!='Cancelled' && service.status=='Draft'">
                        <!-- <a routerLink="/addnewevent" data-toggle="tooltip" title="Add Event"
                          class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Event"
                          style="cursor: pointer"><i class="fal fa-calendar-plus"></i></a> -->
                        <a (click)="updateSlotStatus(service.id.toString(), true)" data-toggle="tooltip"
                          title="Publish service in slot" class="d-inline-block fs-18 text-muted hover-primary"
                          data-original-title="Publish" style="cursor: pointer">
                          <fa-icon [icon]="faUpload"></fa-icon>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="service.Status!='Cancelled' && service.status=='AvailableForSell'">
                        <!-- <a routerLink="/addnewevent" data-toggle="tooltip" title="Add Event"
                          class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Event"
                          style="cursor: pointer"><i class="fal fa-calendar-plus"></i></a> -->
                        <a (click)="updateSlotStatus(service.id.toString(), false)" data-toggle="tooltip"
                          title="Unpublish" class="d-inline-block fs-18 text-muted hover-primary"
                          data-original-title="Unpublish" style="cursor: pointer">
                          <fa-icon [icon]="faX"></fa-icon>
                        </a>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="to-list-mobile">
              <div class="cards-responsive">
                <div class="card-body" style="padding: 10px; background-color: white; border: 3px;"
                  *ngFor="let service of this.services | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                  <div class="align-middle pt-6 pb-4 px-6 no-padding">
                    <div class="media">
                      <div class="w-120px mr-4 position-relative">
                        <a (click)="navigateToServicesDetails(service.id.toString())" class="text-dark hover-primary">
                          <img class="prop-photo" style="display:block;width: 110px;height: auto;max-height: 80px;"
                            src="{{this.apiurl}}/{{service.imageOne}}" alt="">
                        </a>
                        <span
                          class="badge badge-indigo position-absolute pos-fixed-top">{{service.PropertySellType === 'ForRent' ? 'For Rent' : service.PropertySellType}}</span>
                      </div>
                      <div class="media-body">
                        <a class="text-dark display-title hover-primary" style="cursor: pointer">
                          <h5 (click)="navigateToServicesDetails(service.id.toString())" class="fs-16 mb-0 lh-18">
                            {{service.title}}
                          </h5>
                        </a>
                        <p class="mb-1 font-weight-500"></p>
                      </div>
                    </div>
                  </div>
                  <div (click)="navigateToServicesDetails(service.id.toString())"
                    class="align-middle weight-responsive">
                    <span>Date Published: </span>
                    {{ service.create_date.slice(0, 2).includes('/') ? '0' + service.create_date : service.create_date }}
                  </div>
                  <div (click)="navigateToServicesDetails(service.id.toString())"
                    class="align-middle actions-responsive">
                    <ng-container *ngIf="service.status=='AvailableForSell'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-green">Published</span>
                    </ng-container>
                    <ng-container *ngIf="service.status=='Draft'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">Unpublished</span>
                    </ng-container>

                    <ng-container *ngIf="service.status=='Cancelled'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">{{service.status}}</span>
                    </ng-container>

                    <!-- <ng-container *ngIf="service.Status!='UnPublished' && service.Status!='Cancelled'">
                                              <span
                                                  class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{service.Status}}</span>
                                          </ng-container> -->

                  </div>
                  <ng-container *ngIf="this.isuserlandlord()">
                    <!-- <div (click)="navigateToserviceDetails(service.Id.toString())" class="align-middle">
                      {{service.views}}</div> -->
                  </ng-container>

                  <div class="align-middle actions-responsive">
                    <a (click)="navigateToServicesDetails(service.id.toString())"
                      class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">View
                      <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span></a>
                    <a (click)="navigateToPropertyEdit(service.id.toString())" title="Edit"
                      class="d-inline-block fs-18 text-muted hover-primary mr-5" style="cursor: pointer"
                      data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                    <ng-container *ngIf="service.Status!='Cancelled'">
                      <!-- <a routerLink="/addnewevent" data-toggle="tooltip" title="Add Event"
                        class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Event"
                        style="cursor: pointer"><i class="fal fa-calendar-plus"></i></a> -->
                      <a (click)="deletepropertyConfirmation(service.id.toString())" data-toggle="tooltip"
                        title="Cancel Publish" class="d-inline-block mr-5 fs-18 text-muted hover-primary"
                        data-original-title="Delete" style="cursor: pointer"><i class="fal fa-trash-alt"></i></a>
                    </ng-container>
                    <ng-container *ngIf="service.Status!='Cancelled' && service.status=='Draft'">
                      <!-- <a routerLink="/addnewevent" data-toggle="tooltip" title="Add Event"
                        class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Event"
                        style="cursor: pointer"><i class="fal fa-calendar-plus"></i></a> -->
                      <a (click)="updateSlotStatus(service.id.toString(), true)" data-toggle="tooltip"
                        title="Publish service in slot" class="d-inline-block fs-18 text-muted hover-primary"
                        data-original-title="Publish" style="cursor: pointer">
                        <fa-icon [icon]="faUpload"></fa-icon>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="service.Status!='Cancelled' && service.status=='AvailableForSell'">
                      <!-- <a routerLink="/addnewevent" data-toggle="tooltip" title="Add Event"
                        class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Event"
                        style="cursor: pointer"><i class="fal fa-calendar-plus"></i></a> -->
                      <a (click)="updateSlotStatus(service.id.toString(), false)" data-toggle="tooltip"
                        title="Unpublish" class="d-inline-block fs-18 text-muted hover-primary"
                        data-original-title="Unpublish" style="cursor: pointer">
                        <fa-icon [icon]="faX"></fa-icon>
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="this.empty" style="font-size: 18px;">
              No services!
            </div>
            <div style="margin-top: 2rem;" class="d-flex justify-content-center">
              <div *ngIf="this.services.length > 0" style="margin-top: 2rem;" class="d-flex justify-content-center">
                <div class="pagination-desktop">
                  <pagination-template #p="paginationApi" (pageChange)="page = $event">
                    <div class="custom-pagination">
                      <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                        <span (click)="p.previous()">
                          < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                            [class.current]="p.getCurrent() === page.value">
                            <span (click)="p.setCurrent(page.value)"
                              *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                            <div class="pages" *ngIf="p.getCurrent() === page.value">
                              <span>{{ page.label }}</span>
                            </div>
                      </div>
                      <div class="pagination-next" [class.disabled]="p.isLastPage()">
                        <span (click)="toNext(p)"> > </span>
                      </div>
                    </div>
                  </pagination-template>
                </div>
                <div class="pagination-mobile">
                  <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                    (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
