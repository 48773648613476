<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>
    <ng-container *ngIf="this.src.length > 0">
      <div class="show-pdf">
        <pdf-viewer [src]="this.src" [src]="src" [render-text]="true" [original-size]="false"
          style="width: 100%; height: 650px" [rotation]="0" [show-all]="true" [fit-to-page]="false" [zoom]="1"
          [zoom-scale]="'page-width'" [stick-to-page]="true" [external-link-target]="'blank'" [autoresize]="true"
          [show-borders]="true" class="pdf-viewer"></pdf-viewer>
          <button class="close_pdf" (click)="closePdf()">
            <fa-icon [icon]="faX"></fa-icon>
          </button>
          <button class="download_pdf" (click)="onlyDownload(this.src)">
            <fa-icon [icon]="faDownload"></fa-icon>
          </button>
      </div>
    </ng-container>
  </div>

  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">
        <main id="content" class="bg-gray-01">
          <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" style="padding-top: 15px !important;"
            data-animated-id="1">
            <div class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15">My Leases<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.totalproperties}}</span>
                </h2>

              </div>
            </div>
            <div *ngIf="this.propertieslistpage.length > 0" class="table-responsive to-list-desktop">
              <table class="table table-hover bg-white border rounded-lg">
                <thead class="thead-sm thead-black">
                  <tr>
                    <th scope="col" class="border-top-0 px-6 pt-5 pb-4">Listing title</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Date Published</th>
                    <!-- <th scope="col" class="border-top-0 pt-5 pb-4">Status</th> -->
                    <th *ngIf="!this.role" scope="col" class="border-top-0 pt-5 pb-4">Landlord</th>
                    <th *ngIf="this.role" scope="col" class="border-top-0 pt-5 pb-4">Tenant</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-hover-xs-2 bg-hover-white"
                    *ngFor="let property of this.propertieslistpage | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }">

                    <td class="align-middle pt-6 pb-4 px-6">
                      <div class="media">
                        <div class="w-120px mr-4 position-relative">
                          <a (click)="navigateToPropertyDetails(property.property.idproperty.toString())"
                            class="text-dark hover-primary">
                            <img style="display:block;width: 110px;height: auto;max-height: 80px;"
                              src="{{property.property.image}}" alt="">
                          </a>
                          <ng-container *ngIf="property.property.selltype=='Unavailable'">
                            <span class="badge badge-indigo position-absolute pos-fixed-top">Rented</span>
                          </ng-container>
                        </div>
                        <div class="media-body">
                          <a (click)="navigateToPropertyDetails(property.property.idproperty.toString())"
                            class="text-dark hover-primary" style="cursor: pointer">
                            <h5 class="fs-16 mb-0 lh-18">{{property.property.Name}}</h5>
                          </a>
                          <p class="mb-1 font-weight-500"></p>
                          <span class="text-heading lh-15 font-weight-bold fs-17">${{property.property.Price}}</span>
                          <span class="text-gray-light">/month</span>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">{{ property.property.CreatedDate | date: 'MM/dd/yyyy' }}</td>
                    <td class="align-middle" style="display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: 29px;">
                      <div class="w-48px text-white">
                        <div class="avatar-container"><img class="rounded-circle" loading="lazy"
                            src="{{ property.property.imgowner }}" alt="">
                        </div>
                      </div>
                      <br>
                      <span>{{ property.property.namephoto}}</span>
                    </td>

                    <td class="align-middle">
                      <a (click)="showContract(property.property.idproperty)"
                        class="btn btn-sm bg-hover-light border mr-5 to-pdf-mobile" style="cursor: pointer">Contract
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-file-download"></i></span></a>
                      <a (click)="openContract(property.property.idproperty)"
                        class="btn btn-sm bg-hover-light border mr-5 to-pdf-desktop" style="cursor: pointer">Contract
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-file-download"></i></span></a>
                      <a (click)="navigateToLease(property.property.lease)"
                        class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">Lease
                        <span class="d-inline-block ml-1 text-primary "><svg id="SvgjsSvg1001" width="24" height="24"
                            xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:svgjs="http://svgjs.com/svgjs">
                            <defs id="SvgjsDefs1002"></defs>
                            <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)"><svg xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" enable-background="new 0 0 64 64" viewBox="0 0 64 64">
                                <path
                                  d="m51.707 20.293-19-19c-.391-.391-1.023-.391-1.414 0l-19 19c-.188.187-.293.442-.293.707v38h2v-2h5.586l5.707 5.707c.187.188.441.293.707.293h15c.553 0 1-.447 1-1v-4c0-.553-.447-1-1-1h-10.586l1.293-1.293c.391-.391.391-1.023 0-1.414l-5-5c-.187-.188-.441-.293-.707-.293h-12v-27.586l18-18 18 18v37.586h-6v2h7c.553 0 1-.447 1-1v-39c0-.265-.105-.52-.293-.707zm-26.121 30.707 4 4-1.586 1.586-2.293-2.293-1.414 1.414 3 3c.195.195.451.293.707.293h12v2h-13.586l-5.707-5.707c-.187-.188-.441-.293-.707-.293h-6v-4z"
                                  fill="#ababab" class="color000 svgShape"></path>
                                <path
                                  d="m28 41v3c0 .334.167.646.445.832l3 2c.168.112.362.168.555.168s.387-.056.555-.168l3-2c.278-.186.445-.498.445-.832v-13.843c3.616-1.58 6-5.174 6-9.157 0-5.514-4.486-10-10-10s-10 4.486-10 10c0 3.983 2.384 7.577 6 9.157v2.843c0 .266.105.52.293.707l1.293 1.293-1.293 1.293c-.391.391-.391 1.023 0 1.414l1.293 1.293-1.293 1.293c-.188.187-.293.441-.293.707zm1.334-12.466c-3.19-1.129-5.334-4.157-5.334-7.534 0-4.411 3.589-8 8-8s8 3.589 8 8c0 3.377-2.144 6.405-5.334 7.534-.399.142-.666.519-.666.943v13.988l-2 1.333-2-1.333v-2.051l1.707-1.707c.391-.391.391-1.023 0-1.414l-1.293-1.293 1.293-1.293c.391-.391.391-1.023 0-1.414l-1.707-1.707v-3.109c0-.424-.267-.801-.666-.943z"
                                  fill="#ababab" class="color000 svgShape"></path>
                                <path
                                  d="M36 19c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM39 41h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1h-8c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1zm1-4h2v2h-2zm4 2v-2h2v2zm2-4h-2v-2h2zm-4-2v2h-2v-2zM17 31c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1zm7 4h-2v-2h2zm-4-2v2h-2v-2zm-2 4h2v2h-2zm4 2v-2h2v2z"
                                  fill="#ababab" class="color000 svgShape"></path>
                              </svg></g>
                          </svg></span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="to-list-mobile">
              <div class="cards-responsive">
                <div class="card-body" style="padding: 10px; background-color: white; border: 3px;"
                  *ngFor="let property of this.propertieslistpage | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                  <div class="align-middle pt-6 pb-4 px-6 no-padding">
                    <div class="media">
                      <div class="w-120px mr-4 position-relative">
                        <a (click)="navigateToPropertyDetails(property.property.idproperty.toString())"
                          class="text-dark hover-primary">
                          <img class="prop-photo" style="display:block;width: 110px;height: auto;max-height: 80px;"
                            src="{{property.property.image}}" alt="">
                        </a>
                        <ng-container *ngIf="property.property.selltype=='Unavailable'">
                          <span class="badge badge-indigo position-absolute pos-fixed-top">Rented</span>
                        </ng-container>
                      </div>
                      <div class="media-body">
                        <a class="text-dark display-title hover-primary" style="cursor: pointer">
                          <h5 (click)="navigateToPropertyDetails(property.property.idproperty.toString())"
                            class="fs-16 mb-0 lh-18">
                            {{property.property.Name}}</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div (click)="navigateToPropertyDetails(property.property.idproperty.toString())"
                    class="align-middle weight-responsive">
                    <span>Date Published: </span>{{ property.property.CreatedDate | date: 'MM/dd/yyyy' }}
                  </div>

                  <div class="align-middle" style="display: flex; gap: 10px; align-items: center; margin-top: 15px;">
                    <div class="w-48px text-white">
                      <div class="avatar-container"><img class="rounded-circle" loading="lazy"
                          src="{{ property.property.imgowner }}" alt="">
                      </div>
                    </div>
                    <span>{{ property.property.namephoto}}</span>
                  </div>
                  <div style="display: flex; margin-top: 15px;
                  justify-content: center;">
                    <a (click)="showContract(property.property.idproperty)"
                      class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">Contract
                      <span class="d-inline-block ml-1 text-primary "><i class="fal fa-file-download"></i></span></a>
                    <a (click)="navigateToLease(property.property.lease)" class="btn btn-sm bg-hover-light border"
                      style="cursor: pointer">Lease
                      <span class="d-inline-block ml-1 text-primary "><svg id="SvgjsSvg1001" width="24" height="24"
                          xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns:svgjs="http://svgjs.com/svgjs">
                          <defs id="SvgjsDefs1002"></defs>
                          <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)"><svg xmlns="http://www.w3.org/2000/svg"
                              width="24" height="24" enable-background="new 0 0 64 64" viewBox="0 0 64 64">
                              <path
                                d="m51.707 20.293-19-19c-.391-.391-1.023-.391-1.414 0l-19 19c-.188.187-.293.442-.293.707v38h2v-2h5.586l5.707 5.707c.187.188.441.293.707.293h15c.553 0 1-.447 1-1v-4c0-.553-.447-1-1-1h-10.586l1.293-1.293c.391-.391.391-1.023 0-1.414l-5-5c-.187-.188-.441-.293-.707-.293h-12v-27.586l18-18 18 18v37.586h-6v2h7c.553 0 1-.447 1-1v-39c0-.265-.105-.52-.293-.707zm-26.121 30.707 4 4-1.586 1.586-2.293-2.293-1.414 1.414 3 3c.195.195.451.293.707.293h12v2h-13.586l-5.707-5.707c-.187-.188-.441-.293-.707-.293h-6v-4z"
                                fill="#ababab" class="color000 svgShape"></path>
                              <path
                                d="m28 41v3c0 .334.167.646.445.832l3 2c.168.112.362.168.555.168s.387-.056.555-.168l3-2c.278-.186.445-.498.445-.832v-13.843c3.616-1.58 6-5.174 6-9.157 0-5.514-4.486-10-10-10s-10 4.486-10 10c0 3.983 2.384 7.577 6 9.157v2.843c0 .266.105.52.293.707l1.293 1.293-1.293 1.293c-.391.391-.391 1.023 0 1.414l1.293 1.293-1.293 1.293c-.188.187-.293.441-.293.707zm1.334-12.466c-3.19-1.129-5.334-4.157-5.334-7.534 0-4.411 3.589-8 8-8s8 3.589 8 8c0 3.377-2.144 6.405-5.334 7.534-.399.142-.666.519-.666.943v13.988l-2 1.333-2-1.333v-2.051l1.707-1.707c.391-.391.391-1.023 0-1.414l-1.293-1.293 1.293-1.293c.391-.391.391-1.023 0-1.414l-1.707-1.707v-3.109c0-.424-.267-.801-.666-.943z"
                                fill="#ababab" class="color000 svgShape"></path>
                              <path
                                d="M36 19c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM39 41h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1h-8c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1zm1-4h2v2h-2zm4 2v-2h2v2zm2-4h-2v-2h2zm-4-2v2h-2v-2zM17 31c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1zm7 4h-2v-2h2zm-4-2v2h-2v-2zm-2 4h2v2h-2zm4 2v-2h2v2z"
                                fill="#ababab" class="color000 svgShape"></path>
                            </svg></g>
                        </svg></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="this.empty" style="font-size: 18px;">
              No leased property!
            </div>
            <div *ngIf="this.propertieslistpage.length > 4" style="margin-top: 2rem;"
              class="d-flex justify-content-center">
              <div class="pagination-desktop">
                <pagination-template #p="paginationApi" (pageChange)="page = $event">
                  <div class="custom-pagination">
                    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                      <span (click)="toPrevious(p)">
                        < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                          [class.current]="p.getCurrent() === page.value">
                          <span (click)="p.setCurrent(page.value)"
                            *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                          <div class="pages" *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                          </div>
                    </div>
                    <div class="pagination-next" [class.disabled]="p.isLastPage()">
                      <span (click)="toNext(p)"> > </span>
                    </div>
                  </div>
                </pagination-template>
              </div>
              <div class="pagination-mobile">
                <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                  (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
            <!--<div class="text-center mt-2">1-1 of 1 Results</div>-->
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
