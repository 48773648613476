import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';
import { AuthenticationService } from '../services';

@Component({
  selector: 'app-confirmuserregistration',
  templateUrl: './confirmuserregistration.component.html',
  styleUrls: ['./confirmuserregistration.component.css']
})
export class ConfirmuserregistrationComponent implements OnInit {

  showerror = false;
  email = '';

  constructor(private route:ActivatedRoute,private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(
      (queryparams: Params) => {

        this.authenticationService.setNewUserActivation(queryparams.userTokenValidation)
        .pipe()
        .subscribe(
            data => {

            },
            error => {

              this.showerror = true;
            });
      });
  }

  navigateToLoginPage()
  {

      
  }

}
