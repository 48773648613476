import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public ishamburgeropen: boolean = false;
  public idHtml: string = '';
  public url: string = '';

  constructor(private router: Router) {}

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'propertytype' || paramname == 'servicetype')
        this.router.navigate(['/' + destpage], {
          queryParams: paramname === 'propertytype' ? { propertytype: paramvalue } : { servicetype: paramvalue },
        });
    }
    if (this.idHtml !== '') {
      var parent = document.getElementById('navbarroundcomb');
      var cont = document.getElementById(this.idHtml);
      if (cont.classList.contains('second')) {
        parent.style.display = 'none';
        parent.parentElement.classList.remove('rotate-icon');
      } else {
        var getSecond = document.querySelectorAll(
          '.second'
        ) as NodeListOf<HTMLElement>;
        var getDropdown = document.querySelectorAll(
          '.dropdown'
        ) as NodeListOf<HTMLElement>;
        getSecond.forEach((el) => {
          if (el.style.display === 'block') {
            el.style.display = 'none';
          }
        });
        getDropdown.forEach((el) => {
          if (el.style.display === 'block') {
            el.style.display = 'none';
          }
        });
        parent.style.display = 'none';
        parent.parentElement.classList.remove('rotate-icon');
      }
      cont.style.display = 'none';
      cont.parentElement.classList.remove('rotate-icon');
    }
    this.showhidenavbar();
  }

  ngOnInit() {
    this.router.events.subscribe(
      (event:any)=>{
        if(event instanceof NavigationEnd){
          this.url = event.url
        }
      }
    )
  }

  togglenavbar(element) {
    this.idHtml = element;
    var cont = document.getElementById(element);
    if (cont.style.display == 'block') {
      cont.style.display = 'none';
      cont.parentElement.classList.remove('rotate-icon');
    } else {
      cont.parentElement.classList.add('rotate-icon');
      cont.style.display = 'block';
    }
  }

  showhidenavbar() {
    var element = <HTMLInputElement>document.getElementById('check');
    var isChecked = element.checked;

    if (isChecked == true) this.ishamburgeropen = false;
  }
}
