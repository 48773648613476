<section style="width: 100%; margin-bottom: 50px;">
  <section>
    <div style="position: absolute;">
      <ngx-ui-loader></ngx-ui-loader>
    </div>
  </section>
  <section>
    <div class='notification-content'>
      <main>
        <h1 class="component__title">
          Notifications
        </h1>
        <div class="notifications">
          <div (click)="goToEvent('chat','chatroomguid',not.guid)" class="notification-card" *ngFor="let not of this.notifications; let i = index"
            [ngClass]="{'is-notification': this.notifications[i].isnotification === 'true' ? true : false, 'isNot-notification': this.notifications[i].isnotification === 'false' ? true : false}">
            <h3 style="font-size: 17px; color: white !important;">{{not.message}}</h3>
            <p class="data">{{not.data}}</p>
          </div>
        </div>
      </main>
    </div>
  </section>
</section>

