<header [ngClass]="{'header-to-modal': ishamburgeropen}">
  <div *ngIf="!this.loading" class="container" style="height: 92px;" id="topmenu">
    <input type="checkbox" name="" id="check" [(ngModel)]="ishamburgeropen">

    <div class="logo-container">
      <a href="#">
        <img fetchpriority="high" src="assets/images/logo.png" class="home-logo" alt="RoundComb">
      </a>
    </div>

    <div class="nav-btn" id="nav-btn">
      <div class="nav-links">

        <div class="logged-user">
          <div class="position-relative py-8">
            <div class="bottom-bar-action py-2 px-4 bg-gray-01 position-absolute fixed-bottom">
              <div class="container">
                <div class="row no-gutters mx-n2 mxw-571 mx-auto" style="align-content: center;">
                  <div class="px-2">
                    <table border="0" style="text-align: center;">
                      <thead>
                        <tr>
                          <td rowspan="2">
                            <div (click)="navigateToPage('myprofile','','')" class="w-78">
                              <img fetchpriority="high" src="{{ this.authenticationService.profileimageurl }}" alt="" class="rounded-circle">
                            </div>
                          </td>
                          <td>
                            <p (click)="navigateToPage('myprofile','','')" class="usernameText">{{ this.profileJson?.FirstName }} {{this.profileJson?.LastName}}</p>
                          </td>
                          <td rowspan="2">
                            <ul>
                              <li class="nav-link mr-auto mr-lg-6"
                                style="--i: 1.35s; position: relative; padding-top: 5px !important; margin-top: 5px;">
                                <a class="nav-link px-2 position-relative" (click)="toggleNotificationsModal()"
                                  style="cursor: pointer; padding-top: 5px !important;">
                                  <i style="transform: none !important;" class="fal fa-bell fs-large-4"></i>
                                  <ng-container *ngIf="this.notLength == 0">
                                    <span class="badge badge-primary badge-circle badge-absolute"
                                      style="padding-top: 5px; padding-right: 8px;padding-bottom: 5px;padding-left: 8px;top: -8px;">{{this.notLength}}</span>
                                  </ng-container>
                                  <ng-container *ngIf="this.notLength != 0">
                                    <span class="badge badge-danger badge-circle badge-absolute"
                                      style="padding-top: 5px; padding-right: 8px;padding-bottom: 5px;padding-left: 8px;top: -8px;">{{this.notLength}}</span>
                                  </ng-container>
                                </a>
                                <div *ngIf="this.toggleModal" class="notifications-modal">
                                  <div class="out-position"></div>
                                  <div class="notifications-item not-header">
                                    <h3>{{this.notLength <= 0 ? 'Notification' : 'Notifications'}}</h3>
                                    <p class="not-length" style="margin-bottom: 0 !important;">{{this.notLength}}</p>
                                  </div>
                                  <div class="to-scroll">
                                    <ng-container *ngIf="this.notifications.length > 0">
                                      <div *ngFor="let not of this.notifications">
                                        <a href="{{'chat?chatroomguid=' + not.guid}}"
                                          (click)="updateRead(not.guid, not.id)"
                                          title="{{not.wasRead ? 'Notification read' : 'Unread'}}"
                                          style="color: black !important;" [ngClass]="{'was-read': not.wasRead}"
                                          class="notifications-item">

                                          <div class="not-photo">
                                            <ng-container
                                              *ngIf="!not.userPhoto.startsWith('https') && not.userPhoto !== null && not.userPhoto !== '' && !not.userPhoto.includes('MessageCo') && !not.userPhoto.startsWith('[&user')">
                                              <img fetchpriority="high" src="{{this.apiUrl + not.userPhoto}}"
                                                alt="user photo, by notifications">
                                            </ng-container>
                                            <ng-container *ngIf="not.userPhoto === null || not.userPhoto === ''">
                                              <img fetchpriority="high" src="assets/images/User-Default.jpg"
                                                alt="user photo, by notifications">
                                            </ng-container>
                                            <ng-container *ngIf="not.userPhoto?.startsWith('https')">
                                              <img fetchpriority="high" src="{{not.userPhoto}}" alt="user photo, by notifications">
                                            </ng-container>
                                            <ng-container
                                              *ngIf="not.userPhoto?.includes('MessageCo') || not.userPhoto?.startsWith('[&user')">
                                              <img fetchpriority="high" src="assets/images/User-Default.jpg"
                                                alt="user photo, by notifications">
                                            </ng-container>
                                          </div>
                                          <div class="not-data">
                                            <p class="not-message"
                                              style="margin-bottom: 5px !important; font-size: 15px;">{{not.message}}
                                            </p>
                                            <ng-container>
                                              <p style="margin-bottom: 0 !important; font-size: 15px;">{{not.date}}</p>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="not.day === 215 || not.day <= 0">
                                              <ng-container *ngIf="not.hours > 0">
                                                <p style="margin-bottom: 0 !important; font-size: 15px;">{{not.hours}} hours ago</p>
                                              </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="not.day === 215 || not.day <= 0">
                                              <ng-container *ngIf="not.minutes > 0 && not.hours <= 0">
                                                <p style="margin-bottom: 0 !important; font-size: 15px;">{{not.minutes}} minutes ago</p>
                                              </ng-container>
                                            </ng-container> -->
                                          </div>
                                        </a>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="this.notifications.length <= 0">
                                      <div class="notifications-item">
                                        <span style="text-align: center; font-size: 20px;">No notifications</span>
                                      </div>
                                    </ng-container>

                                  </div>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td><button type="button" class="btn btn-orange" (click)="logout()">Log Out</button></td>
                        </tr>
                      </thead>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <ul>
          <li [ngClass]="{'selected': this.url === '/'}" class="nav-link" style="--i: .3s">
            <a href="#">Home</a>
          </li>
          <li class="nav-link logged-user" style="--i: .6s;">
            <a (click)="togglenavbar('myusermenu', false)">{{ this.profileJson?.FirstName }} {{this.profileJson?.LastName}}<i
                class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="myusermenu">
              <ul>
                <li [ngClass]="{'selected': this.url === '/myprofile'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/myprofile'}" (click)="navigateToPage('myprofile','','')">My Profile</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/dashboard'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/dashboard'}" (click)="navigateToPage('dashboard','','')">Dashboard</a>
                </li>
                <ng-container *ngIf="!this.Isvendor">
                  <ng-container *ngIf="!this.Istenant">
                    <li [ngClass]="{'selected': this.url === '/myproperties'}" class="dropdown-link">
                      <a [ngClass]="{'selected': this.url === '/myproperties'}" (click)="navigateToPage('myproperties','','')">My Properties</a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="this.Isvendor">
                  <li [ngClass]="{'selected': this.url === '/myservices'}" class="dropdown-link">
                    <a [ngClass]="{'selected': this.url === '/myservices'}" (click)="navigateToPage('myservices','','')">My Services</a>
                  </li>
                </ng-container>
                <ng-container *ngIf="!this.Isvendor">
                  <li [ngClass]="{'selected': this.url === '/events?IDEvent=1'}" class="dropdown-link">
                    <a [ngClass]="{'selected': this.url === '/events?IDEvent=1'}" (click)="navigateToPageEvent('events','IDEvent',1)">Leases</a>
                  </li>
                </ng-container>
                <ng-container *ngIf="this.Isvendor || !this.Isvendor">
                  <li [ngClass]="{'selected': this.url === '/events?IDEvent=2'}" class="dropdown-link">
                    <a [ngClass]="{'selected': this.url === '/events?IDEvent=2'}" (click)="navigateToPageEvent('events','IDEvent',2)">Maintenance</a>
                  </li>
                </ng-container>
                <li [ngClass]="{'selected': this.url === '/wallet'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/wallet'}" (click)="navigateToPage('wallet','','')">Wallet</a>
                </li>
                <li class="dropdown-link">
                  <a (click)="logout()">Log Out</a>
                </li>
                <!--<div class="arrow"></div>-->
              </ul>
            </div>
          </li>
          <li class="nav-link" data-toggle="slide-collapse" aria-haspopup="true" aria-expanded="false"
            style="--i: .85s">
            <a (click)="togglenavbar('navbarroundcomb', false)">RoundComb<i class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarroundcomb">
              <ul>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarabout', true)">About<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarabout">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/whoweare'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/whoweare'}" (click)="navigateToPage('whoweare','','')">Who we are</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/about'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/about'}" (click)="navigateToPage('about','','')">About Us</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/ourstory'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/ourstory'}" (click)="navigateToPage('ourstory','','')">Our Story</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/ourmission'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/ourmission'}" (click)="navigateToPage('ourmission','','')">Our Mission</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarnewspress', true)">News & Press<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarnewspress">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/inthenews'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/inthenews'}" (click)="navigateToPage('inthenews','','')">In the News</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/pressmedia'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/pressmedia'}" (click)="navigateToPage('pressmedia','','')">Press Media</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarhelp', true)">Help<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarhelp">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/glossary'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/glossary'}" (click)="navigateToPage('glossary','','')">Glossary</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/accountandprofile'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/accountandprofile'}" (click)="navigateToPage('accountandprofile','','')">Account and Profile</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/Signingup'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/Signingup'}" (click)="navigateToPage('Signingup','','')">Signing Up</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/showcasingyourpropertyholdings'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/showcasingyourpropertyholdings'}" (click)="navigateToPage('showcasingyourpropertyholdings','','')">Showcasing your Property
                          Holdings</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/updatingratingsandrentalhistory'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/updatingratingsandrentalhistory'}" (click)="navigateToPage('updatingratingsandrentalhistory','','')">Updating Ratings and Rental
                          History</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li [ngClass]="{'selected': this.url === '/feedback'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/feedback'}" (click)="navigateToPage('feedback','','')">Feedback</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/testimonials'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/testimonials'}" (click)="navigateToPage('testimonials','','')">Testimonials</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/termsconditions'}" class="dropdown-link">
                  <a  [ngClass]="{'selected': this.url === '/termsconditions'}"(click)="navigateToPage('termsconditions','','')">Terms & Conditions</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/privacypolicy'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/privacypolicy'}" (click)="navigateToPage('privacypolicy','','')">Privacy Policy</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/careers'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/careers'}" (click)="navigateToPage('careers','','')">Careers</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/contactus'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/contactus'}" (click)="navigateToPage('contactus','','')">Contact Us</a>
                </li>
                <!--<div class="arrow"></div>-->
              </ul>
            </div>
          </li>
          <li class="nav-link" style="--i: 1.1s">
            <a (click)="togglenavbar('navbarproperties', false)">Properties<i class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarproperties">
              <ul>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=All%20Properties'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=All%20Properties'}" (click)="navigateToPage('filterproperty','propertytype','All Properties')">All Properties</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Apartment'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Apartment'}" (click)="navigateToPage('filterproperty','propertytype','Apartment')">Apartment</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20Family'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20Family'}" (click)="navigateToPage('filterproperty','propertytype','Single Family')">Single Family</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20House'}"  class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20House'}" (click)="navigateToPage('filterproperty','propertytype','Single House')">Single House</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%Luxury'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%Luxury'}" (click)="navigateToPage('filterproperty','propertytype','Single Luxury')">Single Luxury</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Townhouse'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Townhouse'}" (click)="navigateToPage('filterproperty','propertytype','Townhouse')">Townhouse</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Villa'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Villa'}" (click)="navigateToPage('filterproperty','propertytype','Villa')">Villa</a>
                </li>
                <!--<div class="arrow"></div>-->
              </ul>
            </div>
          </li>
          <li class="nav-link" style="--i: 1.1s">
            <a (click)="togglenavbar('navbarservices', false)">Vendors<i class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarservices">
              <ul>
                <li [ngClass]="{'selected': this.url === '/filterservice?servicetype=All%20Services'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterservice?servicetype=All%20Services'}" (click)="navigateToPage('filterservice','servicetype','All Services')">All Services</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="log-sign" style="--i: 1.8s">
        <a routerLink="/wallet" class="user-cash">
          <span class="cash-in-account">{{ this.cash.toFixed(2) }}</span>
          <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
        </a>
        <li class="nav-link" style="--i: 1.1s">
          <a class="p-0  d-flex align-items-center text-heading">
            <a (click)="togglenavbar('navbarmymenu', false)"
              style="color: #31499c;cursor: pointer; white-space: nowrap;">
              {{ this.profileJson?.FirstName }} {{this.profileJson?.LastName}}<i style="margin-left: 3px;"
                class="fas fa-caret-down"></i></a>
            <div class="w-48px text-white">
              <img fetchpriority="high" src="{{ this.authenticationService.profileimageurl }}" alt="" class="rounded-circle">
            </div>
          </a>
          <div class="dropdown" id="navbarmymenu">
            <ul>
              <li [ngClass]="{'selected': this.url === '/myprofile'}" class="dropdown-link">
                <a (click)="navigateToPage('myprofile','','')">My Profile</a>
              </li>
              <li [ngClass]="{'selected': this.url === '/myproperties'}" *ngIf="!this.Isvendor && !this.Istenant" class="dropdown-link">
                <a (click)="navigateToPage('myproperties','','')">My Properties</a>
              </li>
              <li [ngClass]="{'selected': this.url === '/myservices'}" *ngIf="this.Isvendor" class="dropdown-link">
                <a (click)="navigateToPage('myservices','','')">My Services</a>
              </li>
              <ng-container *ngIf="this.isuservendor()">
                <li [ngClass]="{'selected': this.url === '/'}" class="dropdown-link">
                  <a (click)="navigateToPageEvent('events','IDEvent',2)">Events</a>
                </li>
              </ng-container>
              <ng-container *ngIf="!this.isuservendor()">
                <li [ngClass]="{'selected': this.url === '/'}" class="dropdown-link">
                  <a (click)="navigateToPageEvent('events','IDEvent',1)">Events</a>
                </li>
              </ng-container>
              <li class="dropdown-link">
                <a (click)="logout()">Log out</a>
              </li>

              <!--<div class="arrow"></div>-->
            </ul>
          </div>
        </li>
        <li class="nav-link mr-auto mr-lg-6" style="--i: 1.35s; position: relative;">
          <a class="nav-link px-2 position-relative" (click)="toggleNotificationsModal()" style="cursor: pointer">
            <i class="fal fa-bell fs-large-4"></i>
            <ng-container *ngIf="this.notLength == 0">
              <span class="badge badge-primary badge-circle badge-absolute"
                style="padding-top: 5px; padding-right: 8px;padding-bottom: 5px;padding-left: 8px;top: -8px;">{{this.notLength}}</span>
            </ng-container>
            <ng-container *ngIf="this.notLength != 0">
              <span class="badge badge-danger badge-circle badge-absolute"
                style="padding-top: 5px; padding-right: 8px;padding-bottom: 5px;padding-left: 8px;top: -8px;">{{this.notLength}}</span>
            </ng-container>
          </a>
          <div *ngIf="this.toggleModal" class="notifications-modal">
            <div class="out-position"></div>
            <div class="notifications-item not-header">
              <h3>{{this.notLength <= 0 ? 'Notification' : 'Notifications'}}</h3>
              <p class="not-length" style="margin-bottom: 0 !important;">{{this.notLength}}</p>
            </div>
            <div class="to-scroll">
              <ng-container *ngIf="this.notifications.length > 0">
                <div *ngFor="let not of this.notifications">
                  <a href="{{'chat?chatroomguid=' + not.guid}}" (click)="updateRead(not.guid, not.id)"
                    title="{{not.wasRead ? 'Notification read' : 'Unread'}}" style="color: black !important;"
                    [ngClass]="{'was-read': not.wasRead}" class="notifications-item">
                    <div class="not-photo">
                      <ng-container *ngIf="not.userPhoto === null || not.userPhoto === ''">
                        <img fetchpriority="high" src="assets/images/User-Default.jpg" alt="user photo, by notifications">
                      </ng-container>
                      <ng-container
                        *ngIf="!not.userPhoto?.startsWith('https') && not.userPhoto !== null && not.userPhoto !== '' && !not.userPhoto?.includes('MessageCo') && !not.userPhoto?.startsWith('[&user')">
                        <img fetchpriority="high" src="{{this.apiUrl + not.userPhoto}}" alt="user photo, by notifications">
                      </ng-container>
                      <ng-container *ngIf="not.userPhoto?.startsWith('https')">
                        <img fetchpriority="high" src="{{not.userPhoto}}" alt="user photo, by notifications">
                      </ng-container>
                      <ng-container *ngIf="not.userPhoto?.includes('MessageCo') || not.userPhoto?.startsWith('[&user')">
                        <img fetchpriority="high" src="assets/images/User-Default.jpg" alt="user photo, by notifications">
                      </ng-container>
                    </div>
                    <div class="not-data">
                      <p class="not-message" style="margin-bottom: 5px !important; font-size: 15px;">{{not.message}}</p>
                      <ng-container>
                        <p style="margin-bottom: 0 !important; font-size: 15px;">{{not.date}}</p>
                      </ng-container>
                    </div>
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="this.notifications.length <= 0">
                <div class="notifications-item" style="display: flex; justify-content: center; align-items: center;">
                  <span style="text-align: center; font-size: 18px; color: #27449B;">No notifications</span>
                </div>
              </ng-container>
            </div>
          </div>
        </li>


      </div>
    </div>


    <div class="hamburger-menu-container">
      <div class="hamburger-menu">
        <div></div>
      </div>
    </div>
  </div>
</header>
