import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // add authorization header with jwt token if available
   let currentUser = this.auth.currentUserValue;
   if (currentUser && this.auth.getToken()) {
       request = request.clone({
           setHeaders: {
               Authorization: `Bearer ${this.auth.getToken()}`
           }
       });
   }

    return next.handle(request);
  }
}