import { Component, OnInit } from '@angular/core';
import { GenerictoastmessageComponent } from '../../generictoastmessage/generictoastmessage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsConfigurationComponent implements OnInit {

  availablesteps = [
    {name: 'Presentation', type: 'vegetable',order:1},
    {name: 'Negotiation', type: 'vegetable',order:2},
    {name: 'Information Supply', type: 'vegetable',order:3},
    {name: 'Validate your Identity', type: 'vegetable',order:4},
    {name: 'Signing the Contract', type: 'vegetable',order:5},
    {name: 'Payment', type: 'vegetable',order:6}];


  dragEnabled = true;

    public droppedItems = new Array<{
      name: string,
      type: string
    }>();

    public lstOfEvents = new Array<{
      name: string,
      number: number,
      creationdate: string,
      eventconfiguration:string,
      totalevents:number,
    }>();


  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

    this.lstOfEvents.push({name:"Lease",number:1,creationdate:"2021-05-01 00:00.000",eventconfiguration:"",totalevents: 30});
    this.lstOfEvents.push({name:"Maintanance",number:2,creationdate:"2021-05-01 00:00.000",eventconfiguration:"",totalevents: 0});
  }


  onVegetableDrop(e: any) {
    this.removeItem(e.dragData, this.availablesteps);
  }

  onAnyDrop(e: any) {
    this.droppedItems.push(e.dragData);
    this.removeItem(e.dragData, this.availablesteps);

  }

  removeItem(item: any, list: Array<any>) {
    let index = list.map(function (e) {
      return e.name
    }).indexOf(item.name);
    list.splice(index, 1);
  }

  onDropBackStepsConfiguration(e: any) {


    this.availablesteps.push(e.dragData);
    this.removeItem(e.dragData, this.droppedItems);

  }


  editevent(eventnumber:number)
  {

    const modalRef = this.modalService.open(GenerictoastmessageComponent,
      {
        scrollable: true,
        windowClass: 'mycustommodelclass',
        // keyboard: false,
        // backdrop: 'static'
      });
    let data = {
      prop1: 'Edit Event',
      prop2: '',
      prop3: '6'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      if(result == 'yes')
      {
        //this.deleteProperty();
      }

    }, (reason) => {
    });

  }

  deleteevent(eventnumber:number)
  {
    this.showToastMessage("Are you sure you want to delete this event?");
  }

  showToastMessage(message) {

    const modalRef = this.modalService.open(GenerictoastmessageComponent,
      {
        scrollable: true,
        windowClass: 'mycustommodelclass',
        // keyboard: false,
        // backdrop: 'static'
      });
    let data = {
      prop1: 'Question',
      prop2: message,
      prop3: '5'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {

      if(result == 'yes')
      {
      }

    }, (reason) => {
    });
  }
}
