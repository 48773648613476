<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <!--  <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white"> -->
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <!-- </div> -->
      <div class="page-content">

        <main id="content" class="bg-gray-01">
          <div style="padding-top: 0 !important; margin-top: 15px;"
            class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" data-animated-id="1">
            <div class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15">Invites
                  <span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.invites.length}}</span>
                </h2>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover bg-white border rounded-lg">
                <thead class="thead-sm thead-black">
                  <tr>
                    <th scope="col" class="border-top-0 px-6 pb-3">Event Type</th>
                    <th scope="col" class="border-top-0 px-6 pt-6 pb-3">Name</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3">Date Created</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3">Participants</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3" style="padding-left: 100;">Action</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3"></th>

                  </tr>
                </thead>
                <tbody>

                  <tr class="shadow-hover-xs-2 bg-hover-white"
                    *ngFor="let event of this.invites | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                    <td *ngIf="this.role !== 'Vendor'" style="padding-left: 32px;" class="align-middle">
                      Lease
                    </td>
                    <td *ngIf="this.role === 'Vendor'" style="padding-left: 32px;" class="align-middle">
                      Maintenance
                    </td>
                    <td class="align-middle p-6">
                      {{event.name}}
                    </td>
                    <td class="align-middle">{{ event.CreationDate | date: 'MM/dd/yyyy' }}</td>
                    <td style="display: flex; gap: 10px;" class="align-middle">
                      <div class="photo-participants">
                        <ng-container *ngIf="event?.ImagemUserB === 'null'">
                          <img src="assets/images/User-Default.jpg" alt="User photo">
                        </ng-container>
                        <ng-container *ngIf="!event?.ImagemUserB?.startsWith('https') && event?.ImagemUserB !== 'null'">
                          <img src="{{this.apiUrl + event?.ImagemUserB}}" alt="User photo">
                        </ng-container>
                        <ng-container *ngIf="event?.ImagemUserB?.startsWith('https')">
                          <img src="{{event?.ImagemUserB}}" alt="User photo">
                        </ng-container>
                      </div>
                      <div>
                        <p style="margin-bottom: 0 !important; font-size: 18px; text-transform: capitalize;">
                          {{event.FNameUserB}} {{event.LNameUserB}}
                        </p>
                        <a href="userreviews?id={{event.IDUserChat_B}}" class="stars-note">
                          <ng-container
                            *ngIf="(event.ratingB === 'begin' && event.IDUserChat_B.toString() !== this.userIdProfile) || (event.ratingA === 'begin' && event.IDUserChat_A.toString() !== this.userIdProfile)">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="event.ratingA !== 'begin' && event.IDUserChat_A.toString() !== this.userIdProfile">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <ng-container *ngIf="event.ratingA > ratingConditional.moreThan">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingA > ratingConditional.inBetweenLess && event.ratingA <= ratingConditional.inBetweenMore">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingA <= ratingConditional.lessThan">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="event.ratingB !== 'begin' && event.IDUserChat_B.toString() !== this.userIdProfile">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <ng-container *ngIf="event.ratingB > ratingConditional.moreThan">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingB > ratingConditional.inBetweenLess && event.ratingB <= ratingConditional.inBetweenMore">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingB <= ratingConditional.lessThan">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                            </div>
                          </ng-container>
                        </a>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div style="display: flex; gap: 15px;">
                        <a (click)="this.acceptEvent(event.GUID, event.IDEvent)"
                          style="display: flex; align-items: center; gap: 5px;"
                          class="btn btn-sm bg-hover-light border fs-14 px-3">Accept <span
                            class="d-inline-block ml-1 text-primary ">
                            <fa-icon class="accept-icon icon-invite" [icon]="faCheck"></fa-icon>
                          </span>
                        </a>
                        <a (click)="this.refuseEvent(event.GUID)" style="display: flex; align-items: center; gap: 5px;"
                          class="btn btn-sm bg-hover-light border fs-14 px-3">Refuse <span
                            class="d-inline-block ml-1 text-primary ">
                            <fa-icon class="refuse-icon icon-invite" [icon]="faXmark"></fa-icon>
                          </span>
                        </a>
                      </div>
                    </td>
                    <td class="align-middle">
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
            <div style="margin-top: 2rem;" class="d-flex justify-content-center">
              <div class="pagination-desktop">
                <pagination-template #p="paginationApi" (pageChange)="page = $event">
                  <div class="custom-pagination">
                    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                      <span (click)="p.previous()">
                        < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                          [class.current]="p.getCurrent() === page.value">
                          <span (click)="p.setCurrent(page.value)"
                            *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                          <div class="pages" *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                          </div>
                    </div>
                    <div class="pagination-next" [class.disabled]="p.isLastPage()">
                      <span (click)="toNext(p)"> > </span>
                    </div>
                  </div>
                </pagination-template>
              </div>
              <div class="pagination-mobile">
                <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                  (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
