<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    <ng-container *ngIf="!this.showAlreadypaid && this.showError==false">
      PAYMENT
    </ng-container>
    <ng-container *ngIf="this.showAlreadypaid==true && this.showError==false">
      PAYMENT SUCCESSFULL
    </ng-container>
    <ng-container *ngIf="this.showError==true">
      ERROR
    </ng-container>
  </h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <ng-container *ngIf="!this.showAlreadypaid && this.showError==false">


    <div id="accordion-style-02" class="accordion accordion-02">
      <div
        class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-7 pr-5 position-relative">
        <div class="card-header border-0 bg-white p-0" id="headingFour">
          <h5 class="mb-0">
            <button class="btn btn-link fs-22 font-weight-500 p-0 border-0" data-toggle="collapse"
              data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
              Credit Card
            </button>
          </h5>
        </div>
        <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#accordion-style-02">
          <div class="card-body px-0 pt-2 pb-1 lh-214">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
        </div>
      </div>
      <div
        class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-7 pr-5 position-relative">
        <div class="card-header border-0 bg-white p-0" id="headingFive">
          <h5 class="mb-0">
            <button class="btn btn-link fs-22 font-weight-500 p-0 border-0 collapsed" data-toggle="collapse"
              data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
              PayJunction
            </button>
          </h5>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion-style-02">
          <div class="card-body px-0 pt-2 pb-1 lh-214">
            To be developed
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="this.showAlreadypaid && this.showError==false">

    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
      <tbody>
        <tr>
        </tr>
        <tr>
          <td style="width: 100%;">
            <ng-container *ngIf="this.slots">
              <p class="fs-13">Thank you for buying RCT's. Your funds will be credited into your Roudcomb Wallet within
                the next 24h.</p>
            </ng-container>
            <ng-container *ngIf="!this.slots">
              <p class="fs-13"> {{this.text ? 'Thank you for your payment. The vendor will now need to confirm the reception of the funds, and after that this event will be completed.' : 'We worked really hard to provide you a pleasent experience with event system! Please RATE your experience and leave us your feedback.'}} </p>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td style="width: 100%;">

            <div class="image-stack">

              <div class="image-stack__item image-stack__item—top">

                <svg xmlns="http://www.w3.org/2000/svg" width="30.385" height="120.171" viewBox="0 0 115.385 205.171">
                  <path id="dollar-sign-solid"
                    d="M83.854,93.529,40.575,80.866A11.819,11.819,0,0,1,43.9,57.7H70.47a24.46,24.46,0,0,1,13.7,4.208,6.247,6.247,0,0,0,7.814-.8l13.945-13.625a6.5,6.5,0,0,0-.721-9.818A56.664,56.664,0,0,0,70.55,25.646V6.412A6.43,6.43,0,0,0,64.138,0H51.315A6.43,6.43,0,0,0,44.9,6.412V25.646h-1A43.906,43.906,0,0,0,.222,73.573c1.683,18.473,15.789,33.5,33.581,38.71l41.075,12.022a11.819,11.819,0,0,1-3.326,23.162H44.983a24.46,24.46,0,0,1-13.7-4.208,6.247,6.247,0,0,0-7.814.8L9.519,157.685a6.5,6.5,0,0,0,.721,9.818A56.664,56.664,0,0,0,44.9,179.525V198.76a6.43,6.43,0,0,0,6.412,6.412H64.138a6.43,6.43,0,0,0,6.412-6.412V179.445c18.674-.361,36.186-11.461,42.357-29.133A44,44,0,0,0,83.854,93.529Z"
                    transform="translate(-0.036)" fill="#fff" />
                </svg>



              </div>

              <div class="image-stack__item image-stack__item—bottom">

                <svg xmlns="http://www.w3.org/2000/svg" width="460.812" height="98.499" viewBox="0 0 404.32 359.394">
                  <path id="house-user-solid"
                    d="M400.592,165.847l-41.2-36.382V33.693a11.231,11.231,0,0,0-11.231-11.231H303.24a11.231,11.231,0,0,0-11.231,11.231V69.962L220.958,7.23C216.55,3.236,208.147,0,202.16,0S187.8,3.236,183.39,7.23L3.692,165.869A12.824,12.824,0,0,0,0,174.222a13.084,13.084,0,0,0,2.871,7.518l15.029,16.7a14.839,14.839,0,0,0,8.423,3.72,15.211,15.211,0,0,0,7.5-2.885l11.161-9.827V336.931A22.462,22.462,0,0,0,67.45,359.394H336.933A22.462,22.462,0,0,0,359.4,336.931V189.44l11.168,9.827a15.4,15.4,0,0,0,7.525,2.892,14.663,14.663,0,0,0,8.332-3.727l15.029-16.713a15.19,15.19,0,0,0,2.871-7.5A14.741,14.741,0,0,0,400.592,165.847Z"
                    fill="#0ec6d5" />
                </svg>

              </div>
            </div>

            <P style="color: #0ec6d5;"><strong>THANK YOU!</strong></P>
            <P *ngIf="!this.slots && !this.text" style="color: #0ec6d5;"><strong>Your contract is now valid!</strong></P>

          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="this.showError==true">
    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
      <tbody>
        <tr>
        </tr>
        <tr>
          <td style="width: 100%;">
            <svg xmlns="http://www.w3.org/2000/svg" width="226.58" height="226.58" viewBox="0 0 376.58 376.58">
              <path id="frown-regular"
                d="M188.29,8A188.29,188.29,0,1,0,376.58,196.29,188.258,188.258,0,0,0,188.29,8Zm0,340.137c-83.744,0-151.847-68.1-151.847-151.847S104.547,44.443,188.29,44.443s151.847,68.1,151.847,151.847S272.034,348.137,188.29,348.137ZM127.551,184.142a24.3,24.3,0,1,0-24.3-24.3A24.269,24.269,0,0,0,127.551,184.142Zm121.477-48.591a24.3,24.3,0,1,0,24.3,24.3A24.269,24.269,0,0,0,249.029,135.551ZM188.29,232.733a102.374,102.374,0,0,0-78.808,36.9A18.222,18.222,0,1,0,137.5,292.94a66.029,66.029,0,0,1,101.585,0A18.222,18.222,0,1,0,267.1,269.632,102.374,102.374,0,0,0,188.29,232.733Z"
                transform="translate(0 -8)" fill="#ff993c" />
            </svg>
          </td>
        </tr>
        <tr>
          <td style="width: 100%;">
            <p class="fs-14">An error occurred to process your payment.Please make sure you have enought balance to
              process the transacion.</p>
            <p>In any case get in touch with us! (support@roundcomb.com)</p>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
</div>
