import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services';
import { Router,ActivatedRoute,Params } from '@angular/router';
import { FormGroup, FormBuilder,Validators} from '@angular/forms';
import { eventsService,propertiesService } from '../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerictoastmessageComponent } from '../generictoastmessage/generictoastmessage.component';

@Component({
  selector: 'app-addnewevent',
  templateUrl: './addnewevent.component.html',
  styleUrls: ['./addnewevent.component.css']
})
export class AddneweventComponent implements OnInit {

  public lstEventslist = new Array<{
    id:number,
    desctext:string,
    isSelected:boolean
  }>();
  public lstMyPropertieslist = new Array<{
    id:number,
    IdProperty:number,
    name:string,
    category:string,
    isSelected:boolean
  }>();

  newEventForm: FormGroup;
  showfirstmessagetextbox: boolean=false;
  submitted = false;

  constructor(private modalService: NgbModal, private propertiesService: propertiesService,private formBuilder: FormBuilder,public authenticationService: AuthenticationService,private router: Router,private route: ActivatedRoute,private eventservice: eventsService) {

  }

  ngOnInit(): void {

    var propertyId: string = '';
    var propertyname: string = '';

      this.route.queryParams.subscribe(
        (queryparams: Params) => {
          propertyId = queryparams.propertyId;
          propertyname = queryparams.propertyname;
        });



      this.newEventForm = this.formBuilder.group({
        eventype: ['', ],
        firstmessage: ['', Validators.required],

    });

    this.lstEventslist.splice(0,this.lstEventslist.length);

    this.lstEventslist.push(
        {id: 0,desctext: 'Please select event type', isSelected:true},
        {id: 1,desctext: 'Inspection', isSelected:false},
        {id: 2,desctext: 'Maintenance', isSelected:false},
        {id: 3,desctext: 'Service Request', isSelected:false},
        {id: 4,desctext: 'Subletting', isSelected:false},
        {id: 5,desctext: 'Complaints', isSelected:false},
      );

      this.lstMyPropertieslist.splice(0,this.lstMyPropertieslist.length);

      if(propertyId != '' && propertyId != undefined)
      {
        this.lstMyPropertieslist.push({id: 1,IdProperty: Number(propertyId), name: propertyname,category: "", isSelected:true});
      }
      else
      {
        this. getMyProperties();
      }

    }


  private getMyProperties() {

    this.lstMyPropertieslist.splice(0, this.lstMyPropertieslist.length);


    this.propertiesService.getMyProperties()
      .pipe()
      .subscribe(
        data => {

          if(Object.keys(data).length > 0)
            this.lstMyPropertieslist.push({id: 0,IdProperty: 0, name: 'Please select property',category: "", isSelected:true});

          // get lst of properties ID
          for (var j = 0; j < Object.keys(data).length; j++) {

            if(data[j].IsActive == true && data[j].IsDeleted == false)
            {
              this.lstMyPropertieslist.push({id: j+1,IdProperty: data[j].Id, name: data[j].Name,category: data[j].Category.Name, isSelected:false});
            }
          }


      },
      error => {

      });

  }

  logout() {

    this.authenticationService.logout();
    this.router.navigate(['/home']);

}

selectEventTypeOption({ target }) {

    // Inspection
  if(target.value == this.lstEventslist[1].desctext)
  {
    this.showfirstmessagetextbox = true;
  }
  else
  {
    this.showfirstmessagetextbox = false;
  }

}

selectPropertyOption({ target }) {

}

onSubmit() {

  this.submitted = true;

  this.router.navigate(['/chat']);

}



  // convenience getter for easy access to form fields
  get f() { return this.newEventForm.controls; }

}
