<section class="py-8" data-animated-id="2">
  <div class="container" style="display: flex;justify-content: center;">

    <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">


      <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
        data-animate="fadeInDown">Careers</h1>

      <div class="sqs-block html-block sqs-block-html pt-5" data-block-type="2" id="block-718e1cde52f74ee3249f">
        <div class="sqs-block-content">
          <p>We welcome you to join the movement that is re-defining the future of rental business.</p>
          <p>We are a diverse workforce working together to create a healthy marketplace where integrity and
            accountability thrive; a marketplace so viable that every player wins.</p>
          <p>In our world, everyone wins. It may not sound real, which is why we draw talents and build teams that make
            it happen. We seek individuals with diverse skills and backgrounds to help drive our corporate mission. Join
            our team of talented workforce and experience the kind of camaraderie that aims at bringing out the best in
            you. We strive to make our workplace both exciting and supportive, creating a healthy work - life balance.
          </p>
        </div>
      </div>

      <div class="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type="47"
        id="block-beecd918a0854573c1af">
        <div class="sqs-block-content">
          <hr>
        </div>
      </div>

      <div class="sqs-block html-block sqs-block-html" data-block-type="2" id="block-4251266ffdbb2307e139">
        <div class="sqs-block-content">
          <h5 class="text-align-center">Looking for a place to start or advance your career? Please contact us:</h5>
        </div>
      </div>

      <div class="card shadow-hover-lg-xs-4 border border-sm-0 mb-4 pt-5" style="min-height: 253px;">
        <div class="row no-gutters align-items-center">
          <div class="col-sm-4 rounded-0 rounded-lg-top-left card-img" style="padding-left: 15px;padding-top: 15px;">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49527.18193231129!2d-76.93770523953751!3d39.119015947938635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7db6be83312b3%3A0x96081cab717e0e3b!2sBurtonsville%2C%20MD%2C%20USA!5e0!3m2!1sen!2spt!4v1617049669491!5m2!1sen!2spt"
              width="250" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
          <div class="col-sm-8">
            <div class="card-body px-6 py-6 py-sm-0 border-top border-sm-0">
              <!-- <a href="agency-details-1.html"> -->
                <h6 class="card-title text-dark lh-213 mb-0 hover-primary">
                  Property manager</h6>
              <!-- </a> -->
              <p class="card-text">A property manager or estate manager is a person or firm charged with operating a
                real estate property for a fee, when the owner is unable to personally attend to such details, or is not
                interested in doing so.</p>

              <div class="text-center">
                <a routerLink="/careersapply" class="btn btn-primary btn-lg rounded">Apply</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow-hover-lg-xs-4 border border-sm-0 mb-4" style="min-height: 253px;">
        <div class="row no-gutters align-items-center">
          <div class="col-sm-4 rounded-0 rounded-lg-top-left card-img" style="padding-left: 15px;padding-top: 15px;">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49527.18193231129!2d-76.93770523953751!3d39.119015947938635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7db6be83312b3%3A0x96081cab717e0e3b!2sBurtonsville%2C%20MD%2C%20USA!5e0!3m2!1sen!2spt!4v1617049669491!5m2!1sen!2spt"
              width="250" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
          <div class="col-sm-8">
            <div class="card-body px-6 py-6 py-sm-0 border-top border-sm-0">
              <!-- <a href="assets/agency-details-1.html"> -->
                <h6 class="card-title text-dark lh-213 mb-0 hover-primary">
                  Support Specialist</h6>
              <!-- </a> -->
              <p class="card-text">Customer support specialists provide service or product information, solutions and
                relevant details to customers. </p>
              <div class="text-center">
                <a routerLink="/careersapply" class="btn btn-primary btn-lg rounded">Apply</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="pt-6">
        <ul class="pagination rounded-active justify-content-center">
          <li class="page-item"><a class="page-link"><i class="far fa-angle-double-left"></i></a>
          </li>
          <li class="page-item active"><a class="page-link">1</a></li>

          <li class="page-item"><a class="page-link"><i class="far fa-angle-double-right"></i></a></li>
        </ul>
      </nav>

      <div class="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type="47"
        id="block-yui_3_17_2_3_1496920551017_38676">
        <div class="sqs-block-content">
          <hr>
        </div>
      </div>

      <div class="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_3_1496920551017_10893">
        <div class="sqs-block-content">
          <p>U.S. Equal Employment Opportunity/Affirmative Action Information</p>
          <p>Individuals seeking employment at Roundcomb are considered without regards to race, color, religion, sex,
            sexual orientation, gender identification, national origin, age, marital status, ancestry, physical or
            mental disability, or veteran status. You are being given the opportunity to provide the following
            information in order to help us comply with federal and state Equal Employment Opportunity/Affirmative
            Action record keeping, reporting, and other legal requirements.</p>
        </div>
      </div>

    </div>


  </div>

</section>
