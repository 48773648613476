<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
    <div class="container">
        <div class="row ml-xl-0 mr-xl-n6">
            <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div class="primary-sidebar-inner" style="position: static; left: auto; max-width: 370px; width: 100%;">

                        <div class="px-6 pb-6 media-list">

                            <ul style="padding: 10px 15px; background-color: white;" class="list-group list-group-no-border">
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary" routerLink="/whoweare">
                                        <span class="lh-29">Who We Are</span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a routerLink="/about" class="d-flex text-body hover-primary">
                                        <span class="lh-29">About Us</span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary">
                                        <span class="lh-29"><strong>Our Story</strong></span>

                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a routerLink="/ourmission"  class="d-flex text-body hover-primary">
                                        <span class="lh-29">Our Mission</span>

                                    </a>
                                </li>
                            </ul>
                        </div>


                </div>
            </div>
            <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
                <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

                    <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated" data-animate="fadeInDown">Our Story</h1>


                <p class="desc-static mb-4 lh-214 pt-5">The general notion that leasing a property to a tenant is much easier relative to the costs, and often times, the emotional trauma involved in an eviction process, accounts for why rental business is fast becoming such a nightmare to an average investor, and a deterrent to prospective landlords and/or property managers. Most landlord-tenant relationships end up in the court system with tenants accusing landlords of being "slumlords" and landlords accusing tenants of wrecking their properties in addition to rental delinquencies. Leveraging our combined wealth of experience  as landlords, property managers, and engineers, the idea of a platform designed to change such negative perceptions became absolutely necessary. Roundcomb was born out of the need to not only empower landlords, property managers, and other investors to monetize their investment through enhanced due diligence, but also to empower renters and buyers alike, having planned their move, to search, and find property that meets their specification, all in a seamless fashion. It all starts by doing your due diligence, collaborating, and making the right decision from the onset.</p>
                    <p>&nbsp;</p>
                    <p class="desc-static mb-4 lh-214">Our system demands accountability from both parties, requiring providers to objectively rate their tenants and diligently make periodic updates where necessary. On the other hand, it compels tenants to adhere to the terms and conditions specified in their lease agreement in order to earn better rating. It demands that every landlord, property manager, housing authority, among others, subscribe to this unique platform in order to form a strong coalition that will help restore the system, and sustain the viability of the rental and property management business.</p>
                </div>


            </div>

        </div>
    </div>
</section>
