import { Component, OnInit,  Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;

@Component({
  selector: 'app-generictoastmessage',
  templateUrl: './generictoastmessage.component.html',
  styleUrls: ['./generictoastmessage.component.css']
})
export class GenerictoastmessageComponent implements OnInit {

  public myAngularxQrCode: string = null;
  @Input() fromParent;

  availablesteps = [
    {name: 'Presentation', type: 'vegetable',order:1},
    {name: 'Negotiation', type: 'vegetable',order:2},
    {name: 'Information Supply', type: 'vegetable',order:3},
    {name: 'Validate your Identity', type: 'vegetable',order:4},
    {name: 'Signing the Contract', type: 'vegetable',order:5},
    {name: 'Payment', type: 'vegetable',order:6}];


  dragEnabled = true;

    public droppedItems = new Array<{
      name: string,
      type: string
    }>();
   
    public lstOfEvents = new Array<{
      name: string,
      number: number,
      creationdate: string,
      eventconfiguration:string,
      totalevents:number,
    }>();
  //public event: string = "Lease";
  
  constructor(public activeModal: NgbActiveModal) { 
   
  }

  ngOnInit(): void {

  }

  closeModal() { 

    this.activeModal.close('close'); 
  }

  closeModalWithParameter(info:string) { 

    this.activeModal.close(info); 
  }


dataURItoBlob(dataURI,fileextension) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
for (var i = 0; i < binary.length; i++) {
   array.push(binary.charCodeAt(i));
}
return new Blob([new Uint8Array(array)], {
  type: fileextension
});
}

 downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}


onVegetableDrop(e: any) {
  this.removeItem(e.dragData, this.availablesteps);
}

onAnyDrop(e: any) {
  this.droppedItems.push(e.dragData);
  this.removeItem(e.dragData, this.availablesteps);

}

removeItem(item: any, list: Array<any>) {
  let index = list.map(function (e) {
    return e.name
  }).indexOf(item.name);
  list.splice(index, 1);
}

onDropBackStepsConfiguration(e: any) {


  this.availablesteps.push(e.dragData);
  this.removeItem(e.dragData, this.droppedItems);

}


}
