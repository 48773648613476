// google-maps.component.ts
import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, asNativeElements } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { GeocodingService } from '../services';
import { GeocoderResponse } from '../models/geocoder-response.model';

declare var $: any;

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.css']
})
export class GoogleMapsComponent implements OnInit {
  geocoderWorking = false;
  geolocationWorking = false;
  locationCoords?: google.maps.LatLng | null = null;

  title: string = 'RoundComb';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @Input() fromParent;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public activeModal: NgbActiveModal,
    private geocodingService: GeocodingService
  ) { }

  ngOnInit()
  {

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  findAddress() {

    this.geocoderWorking = true;
    this.geocodingService
      .getLocation(this.searchElementRef.nativeElement.value)
      .subscribe(
        (response: GeocoderResponse) => {
          if (response.status === 'OK' && response.results?.length) {
            const location = response.results[0];
            const loc: any = location.geometry.location;

            this.locationCoords = new google.maps.LatLng(parseFloat(loc.lat), parseFloat(loc.lng));

            this.latitude = this.locationCoords.lat();
            this.longitude = this.locationCoords.lng();
            this.zoom = 12;
            this.getAddress(this.latitude, this.longitude);
          } else {
          }
        },
        (err: HttpErrorResponse) => {
          console.error('geocoder error', err);
        }
      )
      .add(() => {
        this.geocoderWorking = false;
      });
  }

  private setCurrentLocation() {

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(this.fromParent.prop1 !='' && this.fromParent.prop1 !='undefined')
        {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
        }
        else
        {


        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
       }
        this.zoom = 4;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  //}
  }


  markerDragEnd($event: google.maps.MouseEvent) {

    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();

    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  closeModal(sendData) {
  this.activeModal.close(sendData);
  }

  saveLocation(){
    const data = {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude
    }
    this.activeModal.close(data);
  }
}
