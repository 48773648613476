<div style="position: relative">

  <ngx-ui-loader></ngx-ui-loader>
  <ng-container *ngIf="this.src.length > 0">
    <div class="show-pdf">
      <pdf-viewer [src]="this.src" [src]="src" [render-text]="true" [original-size]="false"
        style="width: 100%; height: 650px" [rotation]="0" [show-all]="true" [fit-to-page]="false" [zoom]="1"
        [zoom-scale]="'page-width'" [stick-to-page]="true" [external-link-target]="'blank'" [autoresize]="true"
        [show-borders]="true" class="pdf-viewer"></pdf-viewer>
        <button class="close_pdf" (click)="closePdf()">
          <fa-icon [icon]="faX"></fa-icon>
        </button>
        <button class="download_pdf" (click)="onlyDownload(this.src)">
          <fa-icon [icon]="faDownload"></fa-icon>
        </button>
    </div>
  </ng-container>

  <section>


    <div class="wrapper dashboard-wrapper">
      <div class="d-flex flex-wrap flex-xl-nowrap">
        <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
          <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
            <div style="height: 279px;">
              <div class="shadow-xs-1 py-3">
                <div class="d-flex px-3 px-xl-6 w-100">
                </div>
                <app-leftmenumypainel></app-leftmenumypainel>
              </div>
            </div>
          </nav>
        </div>

        <div class="page-content">
          <div class="tab-content shadow-none rounded-0 pt-6 pt-md-10 pb-10 pb-md-12 px-0 bg-gray-01 media-width"
            style="overflow: auto; text-align: justify; padding-top: 0 !important;">


            <div class="tab-pane tab-pane-parent fade container show active" id="showDesktopChat"
              style="margin-left: 20px !important;" role="tabpanel">
              <div class="card border-0 bg-transparent" style="min-height:560px; box-shadow: none;">

                <div class="row"
                  style="margin-right: 8px!important;margin-left: 55px!important;margin-top: 0px!important;margin-bottom: 8px!important;">

                  <div class="col-md-6  "
                    style=" position:relative;height:600px; padding-left: 0px;padding-right: 0px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;margin: 10px;margin-top: 0;background-color: white;border-radius:15px;">

                    <h4 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
                      style="color: #27449B!important;" data-animate="fadeInDown">EVENT STEPS</h4>

                    <div id="eventsbox2" style="height: 535px;position:relative;margin-top: 15px;">
                      <div id="eventsbox3"
                        style="max-height:100%;overflow-y: scroll;border:0px solid; margin-right: 15px;margin-left: 10px; min-height: 530px;"
                        class="has-slimscroll">

                        <!-- accordion start-->
                        <!-- accordion 00 Presentation-->
                        <ng-container *ngIf="this.listUIConfiguration[0].isvisible">
                          <div class="aspect-tab pt-2" style="width: 98%; margin-top: 10px;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                            <input id="item-18" type="checkbox" class="aspect-input" name="aspect"
                              [disabled]="this.listUIConfiguration[0].isaccordiondisabled"
                              [checked]="this.listUIConfiguration[0].isaccordionopen">
                            <label for="item-18" class="aspect-label"></label>


                            <div class="aspect-content">
                              <div class="aspect-info">

                                <ng-container *ngIf="this.listUIConfiguration[0].isStepclosed">

                                  <div class="chart-pie negative over50">

                                    <span class="chart-pie-count">
                                      <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                                    </span>
                                    <div>
                                      <div class="first-fill"></div>
                                      <div class="second-fill" style="transform: rotate(249deg)"></div>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="!this.listUIConfiguration[0].isStepclosed">
                                  <div class="chart-pie positive">
                                    <div>
                                      <div class="first-fill"></div>
                                      <div class="second-fill" style="transform: rotate(119deg)"></div>
                                    </div>
                                  </div>
                                </ng-container>
                                <span class="aspect-name">{{this.listUIConfiguration[0].stepname}}</span>

                              </div>
                              <div class="aspect-stat">

                                <div class="nav-start">
                                  <div class="recipient-block">
                                    <div class="avatar-container"><img class="userlandlord-avatar" loading="lazy"
                                        src="{{this.userA.userphotourl}}" alt=""></div>
                                    <div class="avatar-container"><img class="usertentant-avatar" loading="lazy"
                                        src="{{this.userB.userphotourl}}" alt=""></div>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div class="aspect-tab-content">
                              <div class="sentiment-wrapper"
                                [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                <div class="container">

                                  <table style="table-layout: inherit; height: 191px;" border="0">
                                    <tbody>
                                      <tr style="height: 22px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="width: 395px; height: 22px;">
                                          <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                            <h5 class="card-title">Services</h5>
                                          </ng-container>
                                          <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                            <h5 class="card-title">Property</h5>
                                          </ng-container>
                                        </td>
                                        <td style="width: 293px; height: 22px;" colspan="2">
                                          <h5 class="card-title">Participants</h5>
                                        </td>
                                      </tr>
                                      <tr style="height: 22px;  text-align: center;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="width: 395px; height: 151px;" rowspan="7">
                                          <div class="card fadeInUp animated" data-animate="fadeInUp">
                                            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                              <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                                <img src="{{this.propertyDetails[0].imageurl}}"
                                                  alt="Villa on Hollywood Boulevard" class="responsivegridimage">
                                              </ng-container>
                                              <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                                <img src="{{this.propertyDetails[0].imageurl}}"
                                                  alt="Villa on Hollywood Boulevard" class="responsivegridimage">
                                              </ng-container>
                                              <div class="card-img-overlay d-flex flex-column">
                                              </div>
                                            </div>
                                            <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                              <div
                                                class="card-header bg-transparent d-flex justify-content-between align-items-center py-3">
                                                <p class="fs-17 font-weight-bold text-heading mb-0 lh-1">
                                                  {{this.propertyDetails[0].Price}}
                                                </p>
                                                <span
                                                  class="badge badge-primary">{{this.propertyDetails[0].PropertySellType}}</span>
                                              </div>
                                            </ng-container>
                                            <div class="card-body py-2">
                                              <h4 *ngIf="this.eventinfo.eventname == 'Maintenance'" class="fs-16 lh-2 mb-0"><a
                                                  (click)="navigateToServiceDetails(this.propertyDetails[0].IdProperty)"
                                                  style="cursor: pointer"
                                                  class="text-dark hover-primary">{{this.propertyDetails[0].Name}}</a>
                                              </h4>
                                              <h4 *ngIf="this.eventinfo.eventname !== 'Maintenance'" class="fs-16 lh-2 mb-0"><a
                                                  (click)="navigateToPropertyDetails(this.propertyDetails[0].IdProperty)"
                                                  style="cursor: pointer"
                                                  class="text-dark hover-primary">{{this.propertyDetails[0].Name}}</a>
                                              </h4>
                                              <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                                <p class="font-weight-400 text-gray-light mb-0 fs-13 desc">
                                                  {{this.propertyDetails[0].FullDescription}}
                                                </p>
                                              </ng-container>
                                              <p class="font-weight-400 text-gray-light mb-0 fs-13">
                                                {{this.locationdesc}}
                                              </p>
                                            </div>
                                            <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                              <div class="card-footer bg-transparent pt-3 pb-4">
                                                <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                                                  <li
                                                    class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                    data-toggle="tooltip" title="" data-original-title="3 Bedroom">
                                                    <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                                      <use xlink:href="#icon-bedroom"></use>
                                                    </svg>
                                                    {{this.propertyDetails[0].Beds}}
                                                  </li>
                                                  <li
                                                    class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                    data-toggle="tooltip" title="" data-original-title="3 Bathrooms">
                                                    <svg class="icon icon-shower fs-18 text-primary mr-1">
                                                      <use xlink:href="#icon-shower"></use>
                                                    </svg>
                                                    {{this.propertyDetails[0].Baths}}
                                                  </li>
                                                  <li
                                                    class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                    data-toggle="tooltip" title="" data-original-title="Size">
                                                    <svg class="icon icon-square fs-18 text-primary mr-1">
                                                      <use xlink:href="#icon-square"></use>
                                                    </svg>
                                                    {{this.propertyDetails[0].SquareFeet}}
                                                  </li>
                                                  <li
                                                    class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                    data-toggle="tooltip" title="" data-original-title="1 Garage">
                                                    <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                                      <use xlink:href="#icon-Garage"></use>
                                                    </svg>
                                                    {{this.propertyDetails[0].Garage}}
                                                  </li>
                                                </ul>
                                              </div>
                                            </ng-container>
                                          </div>
                                        </td>
                                        <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                          <td style="width: 148px; height: 22px;">
                                            <ng-container *ngIf="this.userA.isLandlord">
                                              <h1>Landlord</h1>
                                            </ng-container>
                                            <ng-container *ngIf="!this.userA.isLandlord">
                                              <h1>Tenant</h1>
                                            </ng-container>
                                          </td>
                                          <td style="width: 139px; height: 22px;">
                                            <ng-container *ngIf="this.userB.islandlord">
                                              <h1>Landlord</h1>
                                            </ng-container>
                                            <ng-container *ngIf="!this.userB.islandlord">
                                              <h1>Tenant</h1>
                                            </ng-container>
                                          </td>
                                        </ng-container>
                                        <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                          <td style="width: 148px; height: 22px;">
                                            <h1>Vendor</h1>
                                          </td>
                                          <td style="width: 139px; height: 22px;">
                                            <h1>Client</h1>
                                          </td>
                                        </ng-container>
                                      </tr>
                                      <tr style="height: 22px;  text-align: center;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="width: 148px; height: 22px;">
                                          <div class="recipient-block" style="justify-content: center;">
                                            <div class="avatar-container-box"><img class="userlandlord-avatar"
                                                loading="lazy" src="{{this.userA.userphotourl}}"
                                                style="display: block;margin-left: auto;margin-right: auto;" alt="">
                                            </div>

                                          </div>
                                        </td>
                                        <td style="width: 139px; height: 22px;">
                                          <div class="recipient-block" style="justify-content: center;">

                                            <div class="avatar-container-box"><img class="usertentant-avatar"
                                                loading="lazy" src="{{this.userB.userphotourl}}" alt=""></div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr style="height: 22px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="width: 148px; height: 22px; text-align: center;">
                                          <p><strong>{{this.userA.username}}</strong></p>
                                        </td>
                                        <td style="width: 139px; height: 22px;text-align: center;">
                                          <p><strong>{{this.userB.username}}</strong></p>
                                        </td>
                                      </tr>
                                      <tr style="height: 22px; text-align: center;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="width: 148px; height: 22px;">
                                          <ul class="list-inline mb-3">
                                            <li class="list-inline-item fs-13 text-heading font-weight-500">
                                              {{this.ratingCalcA}}/5</li>
                                            <br>
                                            <li class="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                              <ul class="list-inline mb-0">
                                                <li class="list-inline-item mr-0">
                                                  <div class="stars-display">
                                                    <ng-container>
                                                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                                        <ng-container *ngIf="this.ratingCalcA > ratingConditional.moreThan">
                                                          <fa-icon class="star-bg-icon star-icon-vendor"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                        <ng-container
                                                          *ngIf="this.ratingCalcA > ratingConditional.inBetweenLess && this.ratingCalcA <= ratingConditional.inBetweenMore">
                                                          <fa-icon class="star-bg-icon star-icon-vendor"
                                                            [icon]="faStarHalf"></fa-icon>
                                                          <fa-icon class="star-no-icon star-icon-vendor star-half"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="this.ratingCalcA <= ratingConditional.lessThan">
                                                          <fa-icon class="star-no-icon star-icon-vendor"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                      </div>
                                                    </ng-container>
                                                  </div>
                                                </li>
                                              </ul>
                                            </li>

                                          </ul>
                                        </td>
                                        <td style="width: 139px; height: 22px;">
                                          <ul class="list-inline mb-3">
                                            <li class="list-inline-item fs-13 text-heading font-weight-500">
                                              {{this.ratingCalcB}}/5</li>
                                            <br>
                                            <li class="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                              <ul class="list-inline mb-0">
                                                <li class="list-inline-item mr-0">
                                                  <div class="stars-display">
                                                    <ng-container>
                                                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                                                        <ng-container *ngIf="this.ratingCalcB > ratingConditional.moreThan">
                                                          <fa-icon class="star-bg-icon star-icon-vendor"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                        <ng-container
                                                          *ngIf="this.ratingCalcB > ratingConditional.inBetweenLess && this.ratingCalcB <= ratingConditional.inBetweenMore">
                                                          <fa-icon class="star-bg-icon star-icon-vendor"
                                                            [icon]="faStarHalf"></fa-icon>
                                                          <fa-icon class="star-no-icon star-icon-vendor star-half"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="this.ratingCalcB <= ratingConditional.lessThan">
                                                          <fa-icon class="star-no-icon star-icon-vendor"
                                                            [icon]="faStar"></fa-icon>
                                                        </ng-container>
                                                      </div>
                                                    </ng-container>
                                                  </div>
                                                </li>
                                              </ul>
                                            </li>

                                          </ul>
                                        </td>
                                      </tr>
                                      <tr style="height: 22px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="text-align: center; height: 21px; width: 293px;" colspan="2">
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="text-align: center; width: 293px; height: 21px;text-align: center;"
                                          colspan="2">
                                          <p><svg xmlns="http://www.w3.org/2000/svg" width="66.729" height="58.203"
                                              viewBox="0 0 76.729 68.203">
                                              <path id="house-user-solid"
                                                d="M76.021,31.473l-7.818-6.9V6.394a2.131,2.131,0,0,0-2.131-2.131H57.546a2.131,2.131,0,0,0-2.131,2.131v6.883L41.932,1.372A6.127,6.127,0,0,0,38.364,0,6.112,6.112,0,0,0,34.8,1.372L.7,31.477A2.434,2.434,0,0,0,0,33.063a2.483,2.483,0,0,0,.545,1.427L3.4,37.658a2.816,2.816,0,0,0,1.6.706,2.887,2.887,0,0,0,1.424-.547l2.118-1.865V63.94A4.263,4.263,0,0,0,12.8,68.2H63.94A4.263,4.263,0,0,0,68.2,63.94V35.951l2.119,1.865a2.923,2.923,0,0,0,1.428.549,2.783,2.783,0,0,0,1.581-.707l2.852-3.172a2.883,2.883,0,0,0,.545-1.424A2.8,2.8,0,0,0,76.021,31.473ZM38.364,23.445a8.525,8.525,0,1,1-8.525,8.525A8.525,8.525,0,0,1,38.364,23.445ZM53.284,59.678H23.445a2.131,2.131,0,0,1-2.131-2.131A12.788,12.788,0,0,1,34.1,44.758h8.525A12.788,12.788,0,0,1,55.415,57.546,2.131,2.131,0,0,1,53.284,59.678Z"
                                                opacity="0.34" />
                                            </svg>
                                          </p>
                                          <p class="fs-14">Event started by <span
                                              style="color: #27449B;">{{eventinfo.eventstartedbyusername}}</span></p>
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="text-align: center; height: 21px; width: 293px;text-align: center;"
                                          colspan="2">
                                          <p class="text-heading font-weight-500">{{eventinfo?.creationdate?.split(',').join(', ')}}</p>
                                        </td>
                                      </tr>
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                        <td style="height: 18px;"><span class="badge badge-pill badge-secondary"
                                            style="width: 100% ">Event type
                                            : <b>{{eventinfo.eventname}}</b></span>
                                        </td>
                                        <td style="width: 293px;; height: 18px; text-align: right;" colspan="2">
                                          <ng-container *ngIf="this.listUIConfiguration[0].showproceedbuttonUser">
                                            <a class="badge badge-primary" (click)="dontSpanProceedMessage()"
                                              style="color:white">PROCEED</a>
                                          </ng-container>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <!-- accordion 01 Negotiation -->
                        <ng-container *ngIf="this.listUIConfiguration[1].isvisible">
                          <div class="aspect-tab " style="width: 98%;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}"
                            [ngClass]="{'disabled-card': this.listUIConfiguration[1].isaccordiondisabled}">
                            <input id="item-14" type="checkbox" class="aspect-input" name="aspect"
                              [disabled]="this.listUIConfiguration[1].isaccordiondisabled"
                              [checked]="this.listUIConfiguration[1].isaccordionopen">
                            <label [ngClass]="{'disabled-card': this.listUIConfiguration[1].isaccordiondisabled}"
                              for="item-14" class="aspect-label"></label>
                            <div class="aspect-content">
                              <div class="aspect-info">

                                <ng-container *ngIf="this.listUIConfiguration[1].isStepclosed">

                                  <div class="chart-pie negative over50">

                                    <span class="chart-pie-count">
                                      <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                                    </span>
                                    <div>
                                      <div class="first-fill"></div>
                                      <div class="second-fill" style="transform: rotate(249deg)"></div>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="!this.listUIConfiguration[1].isStepclosed">
                                  <div class="chart-pie positive">
                                    <div>
                                      <div class="first-fill"></div>
                                      <div class="second-fill" style="transform: rotate(119deg)"></div>
                                    </div>
                                  </div>
                                </ng-container>
                                <span class="aspect-name">{{this.listUIConfiguration[1].stepname}}</span>

                              </div>
                              <div class="aspect-stat">


                              </div>
                            </div>
                            <div class="aspect-tab-content">
                              <div class="sentiment-wrapper"
                                [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                <div class="container">


                                  <table style="border-collapse: collapse; width: 100%; height: 126px;" border="0">
                                    <tbody>
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                        <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                          <td style="width: 30%;height: 18px;">Was there a presential evalutation?</td>
                                        </ng-container>
                                      </tr>
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor }">
                                        <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                          <td style="width: 50%; height: 18px;">
                                            <div style="display: flex; gap: 10px;">
                                              <div id="yes" (click)="this.yesCheck()"
                                                style="cursor: pointer;display: flex; gap: 5px; align-items: center;">
                                                <p id="yes">Yes</p>
                                                <div id="yes" class="checkbox">
                                                  <div id="yes" [ngClass]="{'checked': yesChecked}"></div>
                                                </div>
                                              </div>
                                              <div (click)="this.noCheck()" id="no"
                                                style="cursor: pointer;display: flex; gap: 5px; align-items: center;">
                                                <p id="no">No</p>
                                                <div id="no" class="checkbox">
                                                  <div id="no" [ngClass]="{'checked': noChecked}"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </ng-container>
                                      </tr>
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                        <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                          <td style="width: 25%; height: 18px;">General Commodities</td>
                                          <td style="width: 50%; height: 18px;">Included Services</td>
                                        </ng-container>
                                        <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                          <td style="width: 25%; height: 18px;">Services</td>
                                        </ng-container>
                                      </tr>
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                        <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                          <td style="width: 50%; height: 18px;">

                                            <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                              <div class="row">
                                                <div class="col-lg-7">
                                                  <div class="form-group">

                                                    <div class="custom-control custom-checkbox"
                                                      style="position: absolute!important;">
                                                      <input type="checkbox" class="custom-control-input"
                                                        id="commoditiecheckbox{{commoditie.id}}"
                                                        [checked]="commoditie.iselected"
                                                        (change)="onGeneralCommoditiesChange($event,commoditie.id)"
                                                        [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true">
                                                      <label class="custom-control-label"
                                                        for="commoditiecheckbox{{commoditie.id}}"
                                                        style="font-size: small;">{{commoditie.name}}</label>
                                                    </div>

                                                  </div>
                                                </div>
                                                <div class="col-lg-4">
                                                  <div class="form-group">

                                                    <div class="input-group input-group-sm mb-3">
                                                      <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                      </div>
                                                      <input type="text" class="form-control"
                                                        id="inputgeneralcommodities{{(i+1)}}"
                                                        name="inputgeneralcommodities{{(i+1)}}" aria-label=""
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value="{{commoditie.value}}"
                                                        [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true"
                                                        (change)="submitUIObjectchanged($event,'listgeneralcommodities',commoditie.id)"
                                                        [readonly]="!commoditie.iselected || this.getmyprofile() =='Tenant'">
                                                    </div>


                                                  </div>
                                                </div>

                                              </div>

                                            </div>


                                          </td>
                                        </ng-container>
                                        <td style="width: 25%; height: 18px;">


                                          <div *ngFor="let includedservice of this.listincludedservices; let i = index">

                                            <div class="row">
                                              <div class="col-lg-6">
                                                <div class="form-group">

                                                  <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                      id="includedservicecheckbox{{includedservice.id}}"
                                                      [checked]="includedservice.iselected"
                                                      (change)="onIncludedServicessChange($event,includedservice.id)"
                                                      [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true">
                                                    <label class="custom-control-label"
                                                      for="includedservicecheckbox{{includedservice.id}}"
                                                      style="font-size: small;">{{includedservice.name}}</label>
                                                  </div>


                                                </div>
                                              </div>
                                              <div class="col-lg-4">
                                                <div class="form-group">

                                                  <div class="input-group input-group-sm mb-3">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                      id="inputincludedservice{{(i+1)}}"
                                                      name="inputincludedservice{{(i+1)}}" aria-label=""
                                                      aria-describedby="inputGroup-sizing-sm"
                                                      value="{{includedservice.value}}"
                                                      [disabled]="this.listUIConfiguration[1].disablecheckboxs"
                                                      (change)="submitUIObjectchanged($event,'listincludedservices',includedservice.id)"
                                                      [readonly]="!includedservice.iselected || this.getmyprofile() =='Tenant'">
                                                  </div>


                                                </div>
                                              </div>

                                              <div class="col-lg-2">
                                                <ng-container
                                                  *ngIf="includedservice.canbedeleted && this.getmyprofile() !='Tenant'">


                                                  <div class="form-group">

                                                    <button class="btn-sm" style="padding-top:9px" type="button"
                                                      (click)="deleteincludedservice(includedservice.id)">
                                                      <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11"
                                                          viewBox="0 0 11 11">
                                                          <path id="times-solid"
                                                            d="M7.585,85.5l3.127-3.127a.983.983,0,0,0,0-1.39l-.695-.7a.983.983,0,0,0-1.39,0L5.5,83.415,2.373,80.288a.983.983,0,0,0-1.39,0l-.7.7a.983.983,0,0,0,0,1.39L3.415,85.5.288,88.627a.983.983,0,0,0,0,1.39l.7.695a.983.983,0,0,0,1.39,0L5.5,87.585l3.127,3.127a.983.983,0,0,0,1.39,0l.695-.695a.983.983,0,0,0,0-1.39Z"
                                                            transform="translate(0 -80)" fill="#ff6b3c" />
                                                        </svg>

                                                      </span>
                                                    </button>
                                                  </div>
                                                </ng-container>
                                              </div>

                                            </div>

                                          </div>
                                          <ng-container *ngIf="this.ismyuserlandlord()">
                                            <ng-container *ngIf="this.listUIConfiguration[1].showproceedbuttonUser">
                                              <button class="btn btn-primary btn-sm"
                                                style=" background-color: #10c6d5; " type="button"
                                                (click)="includedservicesModal()">
                                                <span style="padding-left:4px">Add New Service
                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29"
                                                    viewBox="0 0 47 47">
                                                    <defs>
                                                      <filter id="Ellipse_317" x="0" y="0" width="47" height="47"
                                                        filterUnits="userSpaceOnUse">
                                                        <feOffset dy="3" input="SourceAlpha" />
                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                        <feFlood flood-opacity="0.161" />
                                                        <feComposite operator="in" in2="blur" />
                                                        <feComposite in="SourceGraphic" />
                                                      </filter>
                                                    </defs>
                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_317)">
                                                      <circle id="Ellipse_317-2" data-name="Ellipse 317" cx="14.5"
                                                        cy="14.5" r="14.5" transform="translate(9 6)" fill="#fff" />
                                                    </g>
                                                    <text id="_" data-name="+" transform="translate(0 41)"
                                                      fill="#0ec6d5" font-size="38" font-family="SegoeUI, Segoe UI">
                                                      <tspan x="10" y="-8">+</tspan>
                                                    </text>
                                                  </svg>
                                                </span>
                                              </button>
                                            </ng-container>
                                          </ng-container>

                                        </td>
                                      </tr>

                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                        <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                      <tr style="height: 18px;"
                                        [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                        <td style="width: 25%; height: 18px;">Pricing</td>
                                        <td style="width: 25%; height: 18px;">&nbsp;</td>
                                      </tr>
                                      <tr style="width: 25%; height: 18px;">
                                        <div class="row" style="margin-left: 8px;">
                                          <div class="col-lg-6"
                                            style="max-width: none !important; flex: none !important; padding-left: 0;">
                                            <div class="form-group">

                                              <label for="rent">Rent</label>
                                              <div style="flex-wrap: nowrap;" class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text">$</span>
                                                </div>
                                                <input type="text" value="{{this.rentvalue}}" id="rent" name="rent"
                                                  class="form-control" aria-label="Amount (to the nearest dollar)"
                                                  [disabled]="this.listUIConfiguration[1].disablecheckboxs"
                                                  (change)="submitUIObjectchanged($event,'negotiationRentvalue',0)"
                                                  [readonly]="this.getmyprofile() =='Tenant'">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-lg-6"
                                            style="max-width: none !important; flex: none !important; padding-left: 0;">
                                            <div class="form-group">
                                              <label for="deposit">Deposit</label>
                                              <div style="flex-wrap: nowrap;" class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text">$</span>
                                                </div>
                                                <input type="text" value="{{this.depositvalue}}" id="deposit"
                                                  name="deposit"
                                                  [disabled]="this.listUIConfiguration[1].disablecheckboxs"
                                                  class="form-control" aria-label="Amount (to the nearest dollar)"
                                                  (change)="submitUIObjectchanged($event,'negotiationDepositvalue',0)"
                                                  [readonly]="this.getmyprofile() =='Tenant'">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </tr>
                        </ng-container>
                        <td style="width: 25%; height: 18px;">


                          <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group">


                                <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                  <label for="total">Total/mo</label>
                                </ng-container>
                                <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                  <label for="total">Total</label>
                                </ng-container>
                                <div class="input-group mb-3">
                                  <input type="text" value="{{this.totalvalue}}" id="total" name="total"
                                    class="form-control"
                                    style="border:0px;font-size:small;font-weight: bold; color: #27449B;" readonly>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3">
                              <label for="total">&nbsp;</label>
                              <div class="input-group mb-3" style="align-items: flex-end; padding-top: 7px;">

                                <ng-container
                                  *ngIf="this.ismyuserlandlord() && this.listUIConfiguration[1].showproceedbuttonUser">
                                  <a class="badge badge-warning" (click)="updateNegotiation()"
                                    style="color:white">Update</a>
                                </ng-container>

                                <ng-container
                                  *ngIf="!this.ismyuserlandlord() && this.listUIConfiguration[1].showdeclinebutton && this.listUIConfiguration[1].showproceedbuttonUser">
                                  <a class="badge badge-orange" (click)="declineNegotiation()"
                                    style="color:white">Decline</a>
                                </ng-container>

                              </div>
                            </div>
                            &nbsp;
                            &nbsp;
                            <div class="col-lg-3">
                              <div class="form-group">

                                <label for="total">&nbsp;</label>
                                <ng-container *ngIf="this.listUIConfiguration[1].showproceedbuttonUser">
                                  <div class="input-group mb-3" style="align-items: flex-end;padding-top: 7px;">
                                    <a class="badge badge-primary" *ngIf="this.ismyuserlandlord()"
                                      (click)="updateAndProceedStep()" style="color:white">
                                      <ng-container *ngIf="this.ismyuserlandlord()">
                                        Proceed
                                      </ng-container>
                                    </a>
                                    <a *ngIf="!this.ismyuserlandlord()" class="badge badge-primary"
                                      (click)="sendActionPROCEED('true')" style="color:white">
                                      <ng-container *ngIf="!this.ismyuserlandlord()">
                                        Accept
                                      </ng-container>
                                    </a>
                                  </div>
                                </ng-container>
                              </div>
                            </div>

                          </div>



                        </td>
                        </tr>

                        </tbody>
                        </table>


                      </div>


                    </div>
                  </div>
                </div>
                </ng-container>
                <!-- accordion 02 Information Supply -->
                <ng-container *ngIf="this.listUIConfiguration[2].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}"
                    [ngClass]="{'disabled-card': this.listUIConfiguration[2].isaccordiondisabled}">
                    <input id="item-2" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[2].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[2].isaccordionopen">
                    <label [ngClass]="{'disabled-card': this.listUIConfiguration[2].isaccordiondisabled}" for="item-2"
                      class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[2].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[2].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[2].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                        <div class="container">

                          <div *ngIf="this.eventinfo.eventname === 'Maintenance'" style="padding: 0 !important;"
                            class="form">
                            <table style="border-collapse: collapse; width: 100%; height: 179px;padding: 0 !important;"
                              border="0">
                              <tbody>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 90%; height: 18px;">
                                    <p style="white-space: nowrap;" class="fs-15">
                                      House address were the service will be provided
                                    </p>
                                  </td>
                                  <td style="width: 3.69314%; height: 18px;">&nbsp;</td>
                                  <td style="width: 114.773%; height: 18px;">&nbsp;</td>
                                </tr>
                                <tr class="display-medium" style="width: 120%;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="height: 10px;">
                                    <div class="form-group desktop-medium-top col-md-14"><label>Address line 1</label>
                                      <input id="addressline1" class="form-control" name="addressline1" type="text"
                                        id="addressline1" [value]="this.submittedAddressScheduling"
                                        (input)="this.addressScheduling = getValue($event)"
                                        [ngClass]="{ 'is-invalid': this.addressError && !this.isuservendor() }"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                      <div *ngIf="this.addressError && !this.isuservendor()" class="invalid-feedback">
                                        <div *ngIf="this.addressError && !this.isuservendor()">Address is required</div>
                                      </div>
                                    </div>
                                    <div class="form-group desktop-medium-top col-md-14"><label>Address line 2</label> <input
                                        id="addressline2" class="form-control" name="addressline2" type="text"
                                        [value]="this.submittedAddressTwoScheduling"
                                        (input)="this.addressTwoScheduling = getValue($event)"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                    </div>
                                    <div class="form-row desktok-medium">
                                      <div class="form-group col-md-6"><label>Country</label>
                                        <input id="country" class="form-control" name="country" type="text" id="country"
                                          [value]="this.submittedCountryScheduling"
                                          (input)="this.countryScheduling = getValue($event)"
                                          [ngClass]="{ 'is-invalid': this.countryError && !this.isuservendor() }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                        <div *ngIf="this.countryError && !this.isuservendor()" class="invalid-feedback">
                                          <div *ngIf="this.countryError && !this.isuservendor()">Country is required
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>State</label>
                                        <input id="state" class="form-control" name="state" type="text" id="state"
                                          [value]="this.submittedStateScheduling"
                                          (input)="this.stateScheduling = getValue($event)"
                                          [ngClass]="{ 'is-invalid': this.stateError && !this.isuservendor() }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                        <div *ngIf="this.stateError && !this.isuservendor()" class="invalid-feedback">
                                          <div *ngIf="this.stateError && !this.isuservendor()">State is required</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-row desktok-medium">
                                      <div class="form-group col-md-6"><label>City</label> <input id="city"
                                          class="form-control" name="city" type="text" id="city"
                                          (input)="this.cityScheduling = getValue($event)"
                                          [value]="this.submittedCityScheduling"
                                          [ngClass]="{ 'is-invalid': this.cityError && !this.isuservendor() }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                        <div *ngIf="this.cityError && !this.isuservendor()" class="invalid-feedback">
                                          <div *ngIf="this.cityError && !this.isuservendor()">City is required</div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>ZIP Code</label> <input id="zipcode"
                                          class="form-control" name="zipcode" type="text" id="zipcode"
                                          [value]="this.submittedZipcodeScheduling"
                                          (input)="this.zipcodeScheduling = getValue($event)"
                                          [ngClass]="{ 'is-invalid': this.zipcodeError && !this.isuservendor() }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                        <div *ngIf="this.zipcodeError && !this.isuservendor()" class="invalid-feedback">
                                          <div *ngIf="this.zipcodeError && !this.isuservendor()">ZIP code is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td sye="width: 63.0682%; height: 10px;">
                                    <div class="form-group select-control date col-md-14">
                                      <label>Date</label>
                                      <input readonly style="width: 180px;" class="form-control input-medium" [(ngModel)]="model"
                                        placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker"
                                        (click)="d.toggle()" (dateSelect)="getDate($event)" navigation="select"
                                        [value]="this.submittedDateScheduling"
                                        [ngClass]="{ 'is-invalid': this.dateError && !this.isuservendor() }"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()" />
                                      <div *ngIf="this.dateError && !this.isuservendor()" class="invalid-feedback">
                                        <div *ngIf="this.dateError && !this.isuservendor()">Date is required</div>
                                      </div>
                                    </div>
                                    <div class="form-group  col-md-14">
                                      <label>Time</label>
                                      <input style="width: 180px;" placeholder="12hr format (default settings)"
                                        outsideDays="visible" aria-label="12hr format" [ngxTimepicker]="default"
                                        id="date" class="form-control input-medium" name="date"
                                        [value]="this.submittedTimeScheduling"
                                        [ngClass]="{ 'is-invalid': this.timeError && !this.isuservendor() }"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        readonly>
                                      <ngx-material-timepicker (timeSet)="getTime($event)" #default>
                                      </ngx-material-timepicker>
                                      <div *ngIf="this.timeError && !this.isuservendor()" class="invalid-feedback">
                                        <div *ngIf="this.timeError && !this.isuservendor()">Time is required</div>
                                      </div>
                                    </div>
                                    <div class="form-group  col-md-14"><label>Name</label>
                                      <input style="width: 180px;" id="name" class="form-control input-medium" name="name"
                                        [disabled]=" this.listUIConfiguration[2].disablecheckboxs ||
                                        this.isuservendor()"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        [value]="this.submittedNameScheduling"
                                        [ngClass]="{ 'is-invalid': this.nameError && !this.isuservendor() }"
                                        (input)="this.nameScheduling = getValue($event)" type="text" />
                                      <div *ngIf="this.nameError && !this.isuservendor()" class="invalid-feedback">
                                        <div *ngIf="this.nameError && !this.isuservendor()">Name is required</div>
                                      </div>
                                    </div>
                                    <div class="form-group  col-md-14"><label>Phone</label>
                                      <input style="width: 180px;" id="phone" class="form-control" name="phone"
                                        type="text" id="phone"
                                        [ngClass]="{ 'is-invalid': this.phoneError && !this.isuservendor(), 'input-medium': true }"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                        [value]="this.submittedPhoneScheduling"
                                        (input)="this.phoneScheduling = getValue($event)" />
                                      <div *ngIf="this.phoneError && !this.isuservendor()" class="invalid-feedback">
                                        <div *ngIf="this.phoneError && !this.isuservendor()">Phone number is required
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                            <div *ngIf="!this.isuservendor()"
                              [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                              <div style="width: 100%;">
                                <div style="display: flex; gap: 5px; padding-left: 0.55rem !important;"
                                  class="custom-control custom-checkbox">
                                  <button (click)="showChecked(!this.checkedTerms)"
                                    [ngClass]="{'terms-checkbox': true, 'terms-checked': this.checkedTerms}"
                                    [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                    <fa-icon *ngIf="this.checkedTerms" class="check-icon" [icon]="faCheck"></fa-icon>
                                  </button>
                                  <p class="fs-14">I have read and agree
                                    to the <a (click)="showtermsofuse()"
                                      style="color: navy;text-decoration: underline;">terms of
                                      use</a>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <div style="width: 100%;display: flex; justify-content: center; " colspan="2">
                              <ng-container *ngIf="this.listUIConfiguration[2].showproceedbuttonUser">
                                <div style="display: flex; justify-content: center; gap: 20px;">
                                  <a *ngIf="this.isuservendor() && this.proceedScheduling" class="badge badge-primary"
                                    style="color:white;align-items: center;" (click)="onSubmitInformationSupply()">
                                    Agree
                                  </a>
                                  <a *ngIf="this.isuservendor() && !this.proceedScheduling" class="badge badge-primary"
                                    style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important; cursor: not-allowed !important;">
                                    Agree
                                  </a>
                                  <a *ngIf="!this.isuservendor() && this.checkedTerms" class="badge badge-primary"
                                    style="color:white;align-items: center;" (click)="onSubmitInformationSupply()">
                                    Agree
                                  </a>
                                  <a *ngIf="!this.isuservendor() && !this.checkedTerms" class="badge badge-primary"
                                    style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important; cursor: not-allowed !important;">
                                    Agree
                                  </a>
                                  <a *ngIf="this.isuservendor()" class="badge badge-primary"
                                    (click)="declineNegotiation()"
                                    style="color:white;align-items: center; background-color: #FF6B3C;">
                                    Refuse
                                  </a>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <form *ngIf="this.eventinfo.eventname !== 'Maintenance'" class="form"
                            [formGroup]="informationsupplyForm" (ngSubmit)="onSubmitInformationSupply()">
                            <table style="border-collapse: collapse; width: 100%; height: 179px;" border="0">
                              <tbody>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="text-align: left; width: 66.7613%; height: 18px;" colspan="2"
                                    [ngStyle]="{'background-color': this.getmyprofileColor()}"><span
                                      style="color: white;;"><b>{{this.getmyprofile()}}</b></span></td>
                                  <td style="width: 114.773%; text-align: left; height: 18px;"
                                    [ngStyle]="{'background-color': this.getmyprofileColor()}">
                                    &nbsp;</td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 18px;">
                                    <p class="fs-15">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13.726" height="16.388"
                                        viewBox="0 0 18.726 21.388">
                                        <path id="user-solid"
                                          d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                          transform="translate(0.02)" fill="#27449b" />
                                      </svg>
                                      You can use your present information!
                                    </p>
                                  </td>
                                  <td style="width: 3.69314%; height: 18px;">&nbsp;</td>
                                  <td style="width: 114.773%; height: 18px;">&nbsp;</td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 18px;">

                                    <div class="form-row">
                                      <div class="form-group col-md-6"><label>Email</label>
                                        <input id="email" class="form-control" name="email" type="email"
                                          formControlName="email" [(ngModel)]="userEmail" id="email"
                                          [ngClass]="{ 'is-invalid': submitted && f.email.errors}"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                          <div *ngIf="f.email.errors.required">Email is required</div>
                                          <div *ngIf="f.email.errors.pattern">Email is invalid</div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>Phone</label>
                                        <input id="phone" class="form-control" name="phone" type="text"
                                          formControlName="phone" [(ngModel)]="userPhoneNumber" id="phone"
                                          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                          <div *ngIf="f.phone.errors.required">Phone number is required</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style="height: 18px; width: 3.69314%;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72"
                                      viewBox="0 0 22.5 72">
                                      <path id="exclamation-solid"
                                        d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                        transform="translate(-16)" fill="#ff993c" />
                                    </svg>

                                  </td>
                                  <td style="height: 18px; width: 114.773%;"><span
                                      style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;"><strong>RESPONSABILITY
                                        NOTICE</strong></span>
                                  </td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 10px;">
                                    <div class="form-row">
                                      <div class="form-group col-md-6"><label>ID Number</label>
                                        <input id="idnumber" class="form-control" name="idnumber" type="text"
                                          formControlName="idnumber" [(ngModel)]="userIdNumber" id="idnumber"
                                          [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                          <div *ngIf="f.idnumber.errors.required">ID number is required</div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>VAT</label>
                                        <input id="vat" class="form-control" name="vat" type="text"
                                          formControlName="vat" id="vat" [(ngModel)]="userVAT"
                                          [ngClass]="{ 'is-invalid': submitted && f.vat.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.vat.errors" class="invalid-feedback">
                                          <div *ngIf="f.vat.errors.required">VAT is required</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style="width: 3.69314%; height: 20px;" colspan="2" rowspan="2">
                                    <p class="fs-13">This information <strong style="color: navy;">WILL BE
                                        USED
                                        TO
                                        GENERATE THE CONTRACT!</strong> Please
                                      use
                                      only information that is true and belongs to yourself.
                                      <br><br><br>
                                      In case of any doubt, read the <a (click)="showtermsofuse()"
                                        style="color: navy;text-decoration: underline;"><strong>terms of
                                          use</strong></a> and or get in touch with us!

                                    </p>
                                    <br>
                                    <br>

                                    <p class="fs-13">

                                      <a style="color: navy;text-decoration: underline;"
                                        routerLink="/contactus"><strong>(support@roundcomb.com)</strong></a>
                                    </p>
                                  </td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 10px;">
                                    <div class="form-group col-md-14"><label>Address line 1</label>
                                      <input id="addressline1" class="form-control" name="addressline1" type="text"
                                        formControlName="addressline1" [(ngModel)]="userAddress" id="addressline1"
                                        [ngClass]="{ 'is-invalid': submitted && f.addressline1.errors }"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                      <div *ngIf="submitted && f.addressline1.errors" class="invalid-feedback">
                                        <div *ngIf="f.addressline1.errors.required">Address is required</div>
                                      </div>
                                    </div>
                                    <div class="form-group col-md-14"><label>Address line 2</label> <input
                                        id="addressline2" class="form-control" name="addressline2" type="text"
                                        formControlName="addressline2" [(ngModel)]="userAddress2" id="addressline2"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                        [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                    </div>
                                  </td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 18px;">
                                    <div class="form-row">
                                      <div class="form-group col-md-6"><label>Country</label>
                                        <input id="country" class="form-control" name="country" type="text"
                                          formControlName="country" [(ngModel)]="userCountry" id="country"
                                          [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                          <div *ngIf="f.country.errors.required">Country is required</div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>State</label>
                                        <input id="state" class="form-control" name="state" type="text"
                                          formControlName="state" [(ngModel)]="userState" id="state"
                                          [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                          <div *ngIf="f.state.errors.required">State is required</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style="width: 118.466%; height: 18px;" colspan="2">

                                  </td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 18px;">
                                    <div class="form-row">
                                      <div class="form-group col-md-6"><label>City</label> <input id="city"
                                          class="form-control" name="city" type="text" formControlName="city" id="city"
                                          [(ngModel)]="userCity"
                                          [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                          <div *ngIf="f.city.errors.required">City is required</div>
                                        </div>
                                      </div>
                                      <div class="form-group col-md-6"><label>ZIP Code</label> <input id="zipcode"
                                          class="form-control" name="zipcode" type="text" formControlName="zipcode"
                                          id="zipcode" [(ngModel)]="userZipCode"
                                          [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                          [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                        <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                                          <div *ngIf="f.zipcode.errors.required">ZIP code is required</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style="width: 118.466%; height: 18px;" colspan="2">

                                  </td>
                                </tr>
                                <tr style="height: 18px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                  <td style="width: 63.0682%; height: 18px;">

                                    <div style="display: flex; gap: 5px; padding-left: 0.55rem !important;"
                                      class="custom-control custom-checkbox">
                                      <button (click)="showChecked(!this.checkedTerms)" type="button"
                                        [ngClass]="{'terms-checkbox': true, 'terms-checked': this.checkedTerms}"
                                        [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                        <fa-icon *ngIf="this.checkedTerms" class="check-icon" [icon]="faCheck">
                                        </fa-icon>
                                      </button>
                                      <p class="fs-14">I have read and agree
                                        to the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline; white-space: nowrap;">terms of
                                          use</a>
                                      </p>

                                    </div>
                                  </td>
                                  <td style="width: 118.466%; height: 18px;" colspan="2">

                                    <ng-container
                                      *ngIf="this.listUIConfiguration[2].showproceedbuttonUser && this.checkedTerms">
                                      <a class="badge badge-primary" style="color:white;align-items: center;"
                                        (click)="onSubmitInformationSupply()">PROCEED</a>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="this.listUIConfiguration[2].showproceedbuttonUser && !this.checkedTerms">
                                      <a class="badge badge-primary"
                                        style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important;">PROCEED</a>
                                    </ng-container>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 03 Validate your Identity-->
                <ng-container *ngIf="this.listUIConfiguration[3].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}"
                    [ngClass]="{'disabled-card': this.listUIConfiguration[3].isaccordiondisabled}">
                    <input id="item-3" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[3].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[3].isaccordionopen">
                    <label for="item-3" class="aspect-label"
                      [ngClass]="{'disabled-card': this.listUIConfiguration[3].isaccordiondisabled}"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[3].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[3].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[3].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                        <ng-container *ngIf="this.eventinfo.eventname === 'Maintenance'">
                          <div style="display: flex; justify-content: center; width: 100%;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72" viewBox="0 0 22.5 72">
                              <path id="exclamation-solid"
                                d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                transform="translate(-16)" fill="#ff993c" />
                            </svg>
                            <div style="padding-right: 0 !important;width: 375px; background-color: transparent;">
                              <p style="color: #27449B; font-weight: bold; font-size: 20px;">
                                The service is now being executed by the Vendor</p>
                            </div>
                          </div>
                          <p
                            style="text-align: center; color: rgb(154, 154, 154); font-size: 15px; background-color: transparent;">
                            Was the
                            service completed?</p>
                          <div style="width: 100%;display: flex; justify-content: center; ">
                            <ng-container *ngIf="!this.listUIConfiguration[3].disablecheckboxs">
                              <div
                                style="display: flex; justify-content: center; gap: 20px; background-color: transparent;">
                                <a (click)="completedDontSpanService()" class="badge badge-primary"
                                  style="color:white;align-items: center; width: 100px;">
                                  Yes
                                </a>
                                <a (click)="this.sendActionPROCEED('false');" class="badge badge-primary"
                                  style="color:white;align-items: center; background-color: #FF6B3C; width: 100px;">
                                  No
                                </a>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '1' && this.eventinfo.eventname !== 'Maintenance'">
                          <table style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                            <tbody>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;">Upload your ID Card
                                </td>
                                <td style="width: 10.5938px; height: 21px;">&nbsp;</td>
                                <td style="width: 151.406px; height: 21px;">&nbsp;</td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 63px;text-align: center;" rowspan="3">
                                  <ng-container *ngIf="this.getvalueofmyuser('IDCardphoto')">
                                    <img [src]="this.getvalueofmyuser('IDCardphoto')" id="IDCardphoto"
                                      name="IDCardphoto" alt="IDCard photo" style="height: 150px; width: 150px;" />
                                  </ng-container>
                                  <ng-container *ngIf="!this.getvalueofmyuser('IDCardphoto')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160.714" height="125"
                                      viewBox="0 0 160.714 125">
                                      <path id="address-card-regular"
                                        d="M147.321,32H13.393A13.4,13.4,0,0,0,0,45.393v98.214A13.4,13.4,0,0,0,13.393,157H147.321a13.4,13.4,0,0,0,13.393-13.393V45.393A13.4,13.4,0,0,0,147.321,32Zm0,111.607H13.393V45.393H147.321ZM58.036,94.5A17.857,17.857,0,1,0,40.179,76.643,17.874,17.874,0,0,0,58.036,94.5Zm-25,35.714h50c3.46,0,6.25-2.4,6.25-5.357V119.5c0-8.873-8.4-16.071-18.75-16.071-3.013,0-5.218,2.232-12.5,2.232-7.506,0-9.319-2.232-12.5-2.232-10.352,0-18.75,7.2-18.75,16.071v5.357C26.786,127.815,29.576,130.214,33.036,130.214Zm67.411-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232v-4.464a2.239,2.239,0,0,0-2.232-2.232h-31.25a2.239,2.239,0,0,0-2.232,2.232v4.464A2.239,2.239,0,0,0,100.446,112.357Zm0-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232V87.8a2.239,2.239,0,0,0-2.232-2.232h-31.25A2.239,2.239,0,0,0,98.214,87.8v4.464A2.239,2.239,0,0,0,100.446,94.5Zm0-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232V69.946a2.239,2.239,0,0,0-2.232-2.232h-31.25a2.239,2.239,0,0,0-2.232,2.232v4.464A2.239,2.239,0,0,0,100.446,76.643Z"
                                        transform="translate(0 -32)" fill="#b1b1b1" />
                                    </svg>

                                  </ng-container>
                                </td>
                                <td style="width: 10.5938px; height: 42px;" rowspan="2"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 151.406px; height: 21px;"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;"><strong>RESPONSABILITY
                                    </strong></span></td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 151.406px; height: 21px;"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;"><strong>NOTICE
                                      NOTICE</strong></span></td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 162px; height: 21px;" colspan="2" rowspan="4">
                                  <p class="fs-13">This information <strong style="color: navy;">WILL BE USED
                                      TO
                                      GENERATE THE CONTRACT!</strong> Please
                                    use
                                    only information that is true and belongs to yourself.
                                    <br><br><br>
                                    In case of any doubt, read the <a (click)="showtermsofuse()"
                                      style="color: navy;text-decoration: underline;"><strong>terms of
                                        use</strong></a> and or get in touch with us!
                                  </p>
                                  <br>


                                  <p class="fs-13">

                                    <a style="color: navy;text-decoration: underline;"
                                      routerLink="/contactus"><strong>(support@roundcomb.com)</strong></a>
                                  </p>
                                </td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;"><input type="file"
                                    id="uploadIdCardPhoto" accept="image/png, image/gif, image/jpeg"
                                    (change)="onFileSelect($event)" hidden />
                                  <a class="badge badge-primary" style="color: white;" id="btnOpenFileDialog"
                                    (click)="openfileDialog();">Choose file</a>
                                </td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;"><a
                                    (click)="generateQRCode('2')" class="badge badge-secondary"
                                    style="color: white;">Use Your PHONE</a></td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;">Verify your
                                  Identity
                                </td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;">
                                  <ng-container *ngIf="this.getvalueofmyuser('identityphoto')">
                                    <img [src]="this.getvalueofmyuser('identityphoto')" id="identityphoto"
                                      name="identityphoto" alt="Identity photo"
                                      style="height: 150px; width: 150px;border-radius: 50%" />
                                  </ng-container>
                                  <ng-container *ngIf="!this.getvalueofmyuser('identityphoto')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160.715" height="131.28"
                                      viewBox="0 0 160.715 131.28">
                                      <path id="camera-solid"
                                        d="M160.715,64.82v84.394c0,7.765-6.749,14.066-15.067,14.066H15.067C6.749,163.28,0,156.98,0,149.215V64.82c0-7.765,6.749-14.066,15.067-14.066H42.69l3.861-9.641A15.047,15.047,0,0,1,60.645,32h39.394a15.047,15.047,0,0,1,14.094,9.113l3.892,9.641h27.623C153.966,50.754,160.715,57.055,160.715,64.82Zm-42.69,42.2c0-19.4-16.888-35.164-37.668-35.164S42.69,87.618,42.69,107.017s16.888,35.164,37.668,35.164S118.025,126.416,118.025,107.017Zm-10.045,0c0,14.212-12.4,25.787-27.623,25.787S52.735,121.23,52.735,107.017,65.133,81.23,80.357,81.23,107.98,92.805,107.98,107.017Z"
                                        transform="translate(0 -32)" fill="#b1b1b1" />
                                    </svg>
                                  </ng-container>
                                </td>
                                <td style="width: 162px; height: 21px;" colspan="2">&nbsp;</td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;"><a
                                    (click)="cameraidentityModal()" class="badge badge-primary"
                                    style="color: white;">Use Your WEBCAM</a></td>
                                <td style="width: 162px; height: 21px;" colspan="2">&nbsp;</td>
                              </tr>
                              <tr style="height: 21px;">
                                <td style="width: 318px; height: 21px;text-align: center;"><a
                                    (click)="generateQRCode('1')" class="badge badge-secondary"
                                    style="color: white;">Use Your PHONE</a></td>
                                <td style="width: 162px; height: 21px;text-align: center;" colspan="2"><a
                                    class="badge badge-warning" style="color:white;align-items: center;"
                                    (click)="sendIdentity()">Send Identity</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <ng-container
                          *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '2' && this.getStepValidateIdentityOtherUser() == '1' && this.eventinfo.eventname !== 'Maintenance'">

                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr style="height: 20px;">
                                <td style="width: 529px; height: 20px;" colspan="3">
                                  <div>
                                    <div>
                                      <p class="card-text fs-13 lh-2 text-heading mb-5"
                                        style="margin-top: 15px;margin-bottom:0 !important;color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;text-align: center;">
                                        Please wait for {{this.getNameOtherUser()}} to send you the identity
                                        data
                                        for validation.</p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr style="display: flex; justify-content: center;">
                                <div class="lds-ellipsis">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </tr>
                              <tr style="height: 35px;">
                                <td style="width: 31px; height: 35px;" colspan="3">
                                  <p class="card-text fs-13 lh-2 text-heading mb-5"
                                    style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;text-align: center;">
                                    Your data has been already sent to {{this.getNameOtherUser()}}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </ng-container>
                        <ng-container
                          *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '2' && this.getStepValidateIdentityOtherUser() == '2' && this.eventinfo.eventname !== 'Maintenance'">

                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr style="height: 18px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                <td style="width: 3.26708%; height: 18px;">&nbsp;</td>
                                <td style="width: 45%; height: 18px; align-items: center; text-align: center;">
                                  <img [src]="this.getIDCardPhtoOtherUser()" id="IDCardphotoIdentity"
                                    name="IDCardphotoIdentity" alt="Photo identity"
                                    style="height: 230px; width: 230px;" />
                                  <br>
                                  Card Number: {{this.getIDNumberOtherUser()}}
                                </td>
                                <td style="width: 57.0029%; height: 18px;">
                                  <img [src]="this.getIdentityPhotoOtherUser()" id="IDCardphotoreview"
                                    name="IDCardphotoeview" alt="IDCard photo" style="height: 230px; width: 210px;" />

                                </td>
                              </tr>
                              <tr style="height: 18px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                <td style="width: 3.26708%; height: 36px;" rowspan="2">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72"
                                    viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg>
                                </td>
                                <td style="width: 96.7331%; height: 18px;" colspan="2">
                                  <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                    <strong>DOES THE VERIFICATION</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr style="height: 18px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                <td style="width: 96.7331%; height: 18px;" colspan="2">
                                  <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                    <strong>PHOTO MATCH WITH THE ID CARD?</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr style="height: 18px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                <td style="height: 18px;" colspan="3">
                                  <p class="fs-13">Please verify carefully if the <strong
                                      style="color: navy;">VERIFICATION PHOTO</strong> matches with the
                                    <strong style="color: navy;">ID CARD</strong> photo on
                                    the right and if the <strong style="color: navy;">ID NUMBER</strong>
                                    provided
                                    on the previous step matches the one on the
                                    photo.<br />If you find that the user does not look like the ID picture
                                    and
                                    may not
                                    be the same person <strong style="color: navy;">DO NOT
                                      PROCEED!</strong><br />In case of any doubt, read the <a
                                      (click)="showtermsofuse()"
                                      style="color: navy;text-decoration: underline;"><strong>terms of
                                        use</strong></a>
                                    and or get in touch with us!
                                  </p>
                                </td>
                              </tr>
                              <tr style="height: 18px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                <td style="height: 18px;align-items: center; text-align: center;" colspan="3">

                                  <ng-container>

                                    <button type="button" class="btn"
                                      style="width: 95px;height:33.4px; background-color: #ff6935; color: white;"
                                      (click)="reportuserModal()">REPORT</button>
                                    &nbsp;
                                    &nbsp;
                                    <button type="button" class="btn"
                                      style="width: 95px;height:33.4px; background-color: #10c6d5; color: white;"
                                      (click)="dontSpanProceedMessage()">CONFIRM</button>
                                  </ng-container>
                                </td>
                              </tr>
                            </tbody>
                          </table>


                        </ng-container>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 04 Signing the Contract -->
                <ng-container *ngIf="this.listUIConfiguration[4].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}"
                    [ngClass]="{'disabled-card': this.listUIConfiguration[4].isaccordiondisabled}">
                    <input id="item-4" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[4].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[4].isaccordionopen">
                    <label for="item-4" class="aspect-label"
                      [ngClass]="{'disabled-card': this.listUIConfiguration[4].isaccordiondisabled}"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">
                        <ng-container *ngIf="this.listUIConfiguration[4].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[4].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[4].stepname}}</span>
                      </div>
                      <div class="aspect-stat">
                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                        <ng-container *ngIf="this.IsContractConfirmed == 'false' && this.ismyuserlandlord() != true">
                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;" rowspan="2"> <svg xmlns="http://www.w3.org/2000/svg"
                                    width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 498px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>THE CONTRACT WILL BE GENERATED</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 498px;" colspan="2"> <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>BASED ON THE EARLYER STEPS.</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 529px;" colspan="3">
                                  <div>
                                    <div>
                                      <p class="fs-13">In case of any doubt, read the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a> and or get in touch with us!</p>
                                      <p class="fs-13" style="text-align: center;"><strong>Proposal & Contract
                                          Acceptance</strong></p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;">&nbsp;</td>
                                <td *ngIf="!this.VisibleContract" style="width: 403.125px;text-align: center;">
                                  <button disabled type="button" class="btn mb-2"
                                    style="width: 145px;height:33.4px; background-color: grey; color: white;"
                                    (click)="ViewContract()">View Contract</button>
                                </td>
                                <td *ngIf="this.VisibleContract" style="width: 403.125px;text-align: center;">
                                  <button type="button" class="btn mb-2"
                                    style="width: 145px;height:33.4px; background-color: #1979b9; color: white;"
                                    (click)="ViewContract()">View Contract</button>
                                  <button type="button" class="btn mb-2 ml-2"
                                    style="width: 149px;height:33.4px; background-color: #07cf61; color: white;"
                                    (click)="ConfirmContract()">Confirm Contract</button>
                                </td>
                                <td style="width: 94.875px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>

                        <ng-container *ngIf="this.IsContractConfirmed == 'false' && this.ismyuserlandlord() == true">
                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;" rowspan="2"> <svg xmlns="http://www.w3.org/2000/svg"
                                    width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 498px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>THE CONTRACT WILL BE GENERATED</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 498px;" colspan="2"> <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>BASED ON THE EARLYER STEPS.</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 529px;" colspan="3">
                                  <div>
                                    <div>
                                      <p class="fs-13">In case of any doubt, read the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a> and or get in touch with us!</p>
                                      <p class="fs-13" style="text-align: center;"><strong>Proposal & Contract
                                          Acceptance</strong></p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;">&nbsp;</td>
                                <td style="width: 403.125px;text-align: center;">
                                  <button type="button" class="btn mb-2"
                                    style="width: 180px;height:33.4px; background-color: #1979b9; color: white;"
                                    (click)="EditContract()">VIEW & EDIT CONTRACT</button>
                                  <button type="button" class="btn mb-2 ml-2"
                                    style="width: 180px;height:33.4px; background-color: #07cf61; color: white;"
                                    (click)="SubmitContract()">SUBMIT TO TENANT</button>
                                </td>
                                <td style="width: 94.875px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>

                        <ng-container
                          *ngIf="this.signerConfirmation_A == 'false' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() == true">
                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;" rowspan="2"> <svg xmlns="http://www.w3.org/2000/svg"
                                    width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 498px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>THE CONTRACT WILL BE GENERATED</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 498px;" colspan="2"> <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>BASED ON THE EARLYER STEPS.</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 529px;" colspan="3">
                                  <div>
                                    <div>
                                      <p class="fs-13">In case of any doubt, read the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a> and or get in touch with us!</p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;">&nbsp;</td>
                                <td style="width: 403.125px;text-align: center;">
                                  <button type="button" class="btn mb-2 ml-2"
                                    style="width: 149px;height:33.4px; background-color: #10c6d5; color: white;"
                                    (click)="signTheContractModal()">Sign Contract</button>
                                </td>
                                <td style="width: 94.875px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>

                        <ng-container
                          *ngIf="this.signerConfirmation_A == 'false' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() != true">

                          <p class="card-text fs-14 lh-2 text-heading mb-5"
                            style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif; text-align: center;">
                            Please wait for {{this.getNameOtherUser()}} to sign the contract.
                          </p>
                        </ng-container>

                        <ng-container
                          *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'false'  && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() == true">

                          <p class="card-text fs-14 lh-2 text-heading mb-5"
                            style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif; text-align: center;">
                            Please wait for {{this.getNameOtherUser()}} to sign the contract.
                          </p>
                        </ng-container>
                        <ng-container
                          *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() != true">

                          <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                            <tbody>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;" rowspan="2"> <svg xmlns="http://www.w3.org/2000/svg"
                                    width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 498px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>THE CONTRACT WILL BE GENERATED</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 498px;" colspan="2"> <span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                    <strong>BASED ON THE EARLYER STEPS.</strong>
                                  </span></td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 529px;" colspan="3">
                                  <div>
                                    <div>
                                      <p class="fs-13">In case of any doubt, read the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a> and or get in touch with us!</p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <td style="width: 25px;">&nbsp;</td>
                                <td style="width: 31px;">&nbsp;</td>
                                <td style="width: 403.125px;text-align: center;">
                                  <button type="button" class="btn mb-2 ml-2"
                                    style="width: 149px;height:33.4px; background-color: #10c6d5; color: white;"
                                    (click)="signTheContractModal()">Sign Contract</button>
                                </td>
                                <td style="width: 94.875px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>

                        <ng-container
                          *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'true' && this.IsContractConfirmed == 'true'">

                          <div>
                            <div class="card text-center pt-4" style="background-color: #80808000">
                              <img src="../../assets/images/pdf-icon.png" class="card-img card-img w-78px mx-auto"
                                alt="Signed Contract Document">
                              <div class="card-body p-0 mt-4">
                                <p class="fs-13 lh-2  mb-0 py-0 px-2">Signed Contract - PDF Document</p>
                                <a (click)="onClickDownloadPdf()"
                                  class="btn btn-block bg-gray-01 border-0 fs-14 text-heading">Download<i
                                    class="far fa-arrow-alt-circle-down ml-1 text-primary"></i></a>
                              </div>
                            </div>
                          </div>
                          <ng-container
                            *ngIf="this.listUIConfiguration[4].showproceedbuttonUser && !this.ismyuserlandlord()">
                            <div style="display: flex; justify-content: center;">
                              <div *ngIf="!this.userA.user_proceed"
                                style="display: flex; flex-direction: column; align-items: center;">
                                <span style="font-size: 16px; color: black;">Please wait for the landlord to verify the
                                  contract, for both to proceed to payment.</span>
                                <div class="lds-ellipsis">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                              <a *ngIf="this.userA.user_proceed" class="badge badge-primary"
                                style="background-color: #10c6d5; color: white;"
                                (click)="sendActionPROCEED('true')">Proceed</a>
                            </div>
                          </ng-container>
                          <div *ngIf="this.listUIConfiguration[4].showproceedbuttonUser && this.ismyuserlandlord()"
                            style="width: 100%; display: flex;
                           justify-content: center; gap: 20px; margin: 20px 0; ">
                            <a class="badge badge-primary" style="background-color: #10c6d5; color: white;"
                              (click)="sendActionPROCEED('true')">AGREE</a>
                          </div>

                        </ng-container>

                      </div>
                    </div>
                  </div>

                </ng-container>
                <!-- accordion 05 Payment -->
                <ng-container *ngIf="this.listUIConfiguration[5].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}"
                    [ngClass]="{'disabled-card': this.listUIConfiguration[5].isaccordiondisabled}">
                    <input id="item-6" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[5].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[5].isaccordionopen">
                    <label for="item-6" class="aspect-label"
                      [ngClass]="{'disabled-card': this.listUIConfiguration[5].isaccordiondisabled}"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">
                        <ng-container *ngIf="this.listUIConfiguration[5].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[5].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[5].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">

                        <table
                          [ngStyle]="{'background-color': this.eventinfo.eventname == 'Maintenance' && 'transparent'}"
                          style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                          <tbody>
                            <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                              <tr style="height: 21px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                <td style="width: 17px; height: 21px;">&nbsp;</td>
                                <td style="width: 27px; height: 42px;" rowspan="2"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="22.5" height="72" viewBox="0 0 22.5 72">
                                    <path id="exclamation-solid"
                                      d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                      transform="translate(-16)" fill="#ff993c" />
                                  </svg></td>
                                <td style="width: 172.562px; height: 21px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16;"><strong
                                      style="color:#27449b;">THE
                                      CONTRACT WILL ONLY BE VALID
                                    </strong></span></td>
                              </tr>
                              <tr style="height: 21px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                <td style="width: 17px; height: 21px;">&nbsp;</td>
                                <td style="width: 172.562px; height: 21px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16;"><strong
                                      style="color:#27449b;">ONCE
                                      THE PAYMENT IS DONE BY THE TENANT
                                    </strong></span></td>
                              </tr>
                            </ng-container>
                            <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                              <tr style="height: 21px;">
                                <td style="width: 17px; height: 21px;">&nbsp;</td>
                                <td style="width: 172.562px; height: 21px;" colspan="2"><span
                                    style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16;"><strong
                                      style="color:#27449b;">Provided Service details
                                    </strong></span></td>
                              </tr>
                            </ng-container>
                            <tr style="height: 21.5px;">
                              <td style="width: 17px; height: 21.5px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                &nbsp;</td>
                              <td style="width: 96.625px; height: 42.5px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}"
                                colspan="2" rowspan="2">
                                <div style="max-width:370px">

                                  <div class="card"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                    <div class="card-body px-6 py-4">
                                      <h4 class="card-title fs-16 lh-2 text-dark mb-3"><strong>Items</strong>
                                      </h4>

                                      <ul class="list-group list-group-flush mt-3"
                                        *ngFor="let chargeableitem of listfinalitemstocharge">

                                        <li class="list-group-item px-0"
                                          [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                          <div class="d-flex">
                                            <span>
                                              <ng-container *ngIf="chargeableitem.istotal == false">
                                                {{chargeableitem.name}}
                                              </ng-container>
                                              <ng-container *ngIf="chargeableitem.istotal == true">
                                                <strong>{{chargeableitem.name}}</strong>
                                              </ng-container>
                                            </span>
                                            <span class="d-block ml-auto font-weight-500 text-heading">

                                              <ng-container *ngIf="chargeableitem.istotal == false">
                                                ${{chargeableitem.value}}
                                              </ng-container>
                                              <ng-container *ngIf="chargeableitem.istotal == true">
                                                <strong>${{chargeableitem.value}}</strong>
                                              </ng-container>

                                            </span>
                                          </div>

                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style="width: 102.9375px; height: 42.5px;text-align:center;vertical-align:middle;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}"
                                rowspan="2">
                                <div class="image-stack">

                                  <div class="image-stack__item image-stack__item—top">

                                    <ng-container *ngIf="this.eventinfo.eventname !== 'Maintenance'">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="30.385" height="120.171"
                                        viewBox="0 0 115.385 205.171">
                                        <path id="dollar-sign-solid"
                                          d="M83.854,93.529,40.575,80.866A11.819,11.819,0,0,1,43.9,57.7H70.47a24.46,24.46,0,0,1,13.7,4.208,6.247,6.247,0,0,0,7.814-.8l13.945-13.625a6.5,6.5,0,0,0-.721-9.818A56.664,56.664,0,0,0,70.55,25.646V6.412A6.43,6.43,0,0,0,64.138,0H51.315A6.43,6.43,0,0,0,44.9,6.412V25.646h-1A43.906,43.906,0,0,0,.222,73.573c1.683,18.473,15.789,33.5,33.581,38.71l41.075,12.022a11.819,11.819,0,0,1-3.326,23.162H44.983a24.46,24.46,0,0,1-13.7-4.208,6.247,6.247,0,0,0-7.814.8L9.519,157.685a6.5,6.5,0,0,0,.721,9.818A56.664,56.664,0,0,0,44.9,179.525V198.76a6.43,6.43,0,0,0,6.412,6.412H64.138a6.43,6.43,0,0,0,6.412-6.412V179.445c18.674-.361,36.186-11.461,42.357-29.133A44,44,0,0,0,83.854,93.529Z"
                                          transform="translate(-0.036)" fill="#fff" />
                                      </svg>
                                    </ng-container>
                                    <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="50.385" height="120.171"
                                        viewBox=" 0 0 512 512">
                                        <path id="dollar-sign-solid" transform="translate(-0.036)" fill="#fff"
                                          d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                      </svg>
                                    </ng-container>


                                  </div>

                                  <div class="image-stack__item image-stack__item—bottom">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="125.812" height="98.499"
                                      viewBox="0 0 404.32 359.394">
                                      <path id="house-user-solid"
                                        d="M400.592,165.847l-41.2-36.382V33.693a11.231,11.231,0,0,0-11.231-11.231H303.24a11.231,11.231,0,0,0-11.231,11.231V69.962L220.958,7.23C216.55,3.236,208.147,0,202.16,0S187.8,3.236,183.39,7.23L3.692,165.869A12.824,12.824,0,0,0,0,174.222a13.084,13.084,0,0,0,2.871,7.518l15.029,16.7a14.839,14.839,0,0,0,8.423,3.72,15.211,15.211,0,0,0,7.5-2.885l11.161-9.827V336.931A22.462,22.462,0,0,0,67.45,359.394H336.933A22.462,22.462,0,0,0,359.4,336.931V189.44l11.168,9.827a15.4,15.4,0,0,0,7.525,2.892,14.663,14.663,0,0,0,8.332-3.727l15.029-16.713a15.19,15.19,0,0,0,2.871-7.5A14.741,14.741,0,0,0,400.592,165.847Z"
                                        fill="#0ec6d5" />
                                    </svg>

                                  </div>
                                </div>

                                <br>
                                <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                  <p class="fs-13"><strong style="color: #0ec6d5;font-size: 13;">Monthly
                                      payment</strong></p>
                                  <p class="fs-13"><strong style="color: navy;">${{this.totalmonthly}}</strong>
                                  </p>
                                </ng-container>
                                <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                  <ng-container
                                    *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == false">
                                    Waiting payment from <strong
                                      style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == true && this.getvalueofmyuser('user_proceed') == false">
                                    <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;"
                                      type="button" (click)="openRatingModal()">
                                      Approve payment
                                    </button>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == false && this.PaymentModal == true">
                                    <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;"
                                      type="button" (click)="paymentMaintenanceModal()">
                                      Go to Payment
                                    </button>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == true && this.getUserProceedOtherUser() == false">
                                    Waiting payment approval from <strong
                                      style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                                  </ng-container>
                                </ng-container>
                              </td>
                            </tr>
                            <tr style="height: 21px;">
                              <td style="width: 17px; height: 21px;"
                                [ngStyle]="this.eventinfo.eventname !== 'Maintenance' && {'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                &nbsp;</td>
                            </tr>
                            <tr style="max-height: 21px;">
                              <td style="width: 17px; height: 21px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                &nbsp;</td>
                              <td style="width: 96.625px; height: 21px; text-align: center;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}"
                                colspan="2" *ngIf="this.eventinfo.eventname !== 'Maintenance'">
                                <ng-container
                                  *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == false">
                                  Waiting payment from <strong
                                    style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                                </ng-container>
                                <ng-container
                                  *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == true && this.getvalueofmyuser('user_proceed') == false">
                                  <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;"
                                    type="button" (click)="sendActionPROCEED('true')">
                                    Approve payment
                                  </button>
                                </ng-container>
                                <ng-container
                                  *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == false && this.PaymentModal == true">
                                  <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;"
                                    type="button" (click)="paymentModal()">
                                    Go to Payment
                                  </button>
                                </ng-container>
                                <ng-container
                                  *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == true && this.getUserProceedOtherUser() == false">
                                  Waiting payment approval from <strong
                                    style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                                </ng-container>

                              </td>
                              <td style="width: 102.9375px; height: 21px;"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                &nbsp;</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion end-->
                <div style="text-align: center;">
                  <ng-container *ngIf="this.allstepsClosed">
                    <div class="image-stackEndStep">
                      <div class="image-stack__item image-stack__item—topEndStep">
                        <svg xmlns="http://www.w3.org/2000/svg" width="68.719" height="54.97"
                          viewBox="0 0 68.719 54.97">
                          <path id="hands-helping-solid"
                            d="M52.377,20.635H36.057v6.013a7.731,7.731,0,0,1-15.462,0V13.591l-6.968,4.188a6.857,6.857,0,0,0-3.339,5.884v5.079L1.7,33.7A3.428,3.428,0,0,0,.441,38.394l8.59,14.882a3.437,3.437,0,0,0,4.692,1.256l11.1-6.41H39.493a6.878,6.878,0,0,0,6.872-6.872h1.718a3.432,3.432,0,0,0,3.436-3.436V30.943h.859a2.571,2.571,0,0,0,2.577-2.577V23.212A2.571,2.571,0,0,0,52.377,20.635Zm15.859-4.016L59.647,1.737A3.437,3.437,0,0,0,54.954.481l-11.1,6.41H32.879a6.923,6.923,0,0,0-3.64,1.042l-3.6,2.244a3.413,3.413,0,0,0-1.611,2.91V26.648a4.295,4.295,0,0,0,8.59,0V17.2H52.377a6.017,6.017,0,0,1,6.013,6.013v3.06l8.59-4.961A3.44,3.44,0,0,0,68.236,16.619Z"
                            transform="translate(0.023 -0.022)" fill="#fff" />
                        </svg>

                      </div>
                      <div style="margin-bottom: 5px;" class="image-stack__item image-stack__item—bottomEndStep">

                        <svg xmlns="http://www.w3.org/2000/svg" width="125.812" height="98.499"
                          viewBox="0 0 404.32 359.394">
                          <path id="house-user-solid"
                            d="M400.592,165.847l-41.2-36.382V33.693a11.231,11.231,0,0,0-11.231-11.231H303.24a11.231,11.231,0,0,0-11.231,11.231V69.962L220.958,7.23C216.55,3.236,208.147,0,202.16,0S187.8,3.236,183.39,7.23L3.692,165.869A12.824,12.824,0,0,0,0,174.222a13.084,13.084,0,0,0,2.871,7.518l15.029,16.7a14.839,14.839,0,0,0,8.423,3.72,15.211,15.211,0,0,0,7.5-2.885l11.161-9.827V336.931A22.462,22.462,0,0,0,67.45,359.394H336.933A22.462,22.462,0,0,0,359.4,336.931V189.44l11.168,9.827a15.4,15.4,0,0,0,7.525,2.892,14.663,14.663,0,0,0,8.332-3.727l15.029-16.713a15.19,15.19,0,0,0,2.871-7.5A14.741,14.741,0,0,0,400.592,165.847Z"
                            fill="#0ec6d5" />
                        </svg>

                      </div>
                    </div>
                    <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                      <strong>Property Leased to </strong>
                    </ng-container>
                    <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                      <strong>Service provided to </strong>
                    </ng-container>
                    <ng-container *ngIf="this.ismyuserlandlord() == true">
                      <strong>{{this.getNameOtherUser()}}</strong>
                    </ng-container>
                    <ng-container *ngIf="this.ismyuserlandlord() == false">
                      {{this.getvalueofmyuser('username')}}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5"
            style="height:600px;outline: none;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;margin: 10px;margin-left: 30px;margin-top: 0;background-color: white;border-radius:15px;">

            <h4 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
              style="color: #27449B!important;" data-animate="fadeInDown">CHAT</h4>
            <div id="eventsbox2" style="height: 550px;position:relative;">
              <div class="chat-wrapper pt-3" style="position: inherit;">
                <div class="chat-inner">

                  <div class="chat-body is-opened">

                    <div class="chat-body-inner is-multiple has-slimscroll" id="scrollMeChat" #scrollMeChat
                      [scrollTop]="scrollMeChat.scrollHeight">
                      <div class="true-dom">
                        <div class="date-divider">
                          <hr class="date-divider-line">
                          <div class="hr-sect"><span class="date-divider-text"
                              style="color: #7fa4b3;font-size: 11px;box-sizing: content-box;padding: 5px 5px;top: 0%;">Presentation</span>
                          </div>
                          <br>

                        </div>


                        <div *ngFor="let messageitem of ChatMessages">

                          <ng-container *ngIf="messageitem.isstepnotification == true">
                            <div class="date-divider">
                              <hr class="date-divider-line">
                              <div class="hr-sect"><span class="date-divider-text"
                                  style="color: #7fa4b3;font-size: 11px;box-sizing: content-box;padding: 5px 5px;top: 0%;">{{messageitem.message}}</span>
                              </div>

                              <br>

                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="messageitem.isnotification == true && messageitem.isstepnotification == false">
                            <div class="date-divider">
                              <hr class="date-divider-line">
                              <ng-container *ngIf="messageitem.notificationicon == 'usersentproceed'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="13.726"
                                    height="16.388" viewBox="0 0 18.726 21.388">
                                    <path id="user-solid"
                                      d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                      transform="translate(0.021)" fill="#294599" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="13.726"
                                    height="16.388" viewBox="0 0 18.726 21.388">
                                    <path id="user-solid"
                                      d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                      transform="translate(0.021)" fill="#FF993C" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'pricechanged'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="20.971"
                                    height="18.196" viewBox="0 0 24.971 22.196">
                                    <path id="hand-holding-usd-solid"
                                      d="M11.751,6.256l2.353.62a.372.372,0,0,1,.287.351.382.382,0,0,1-.4.364H12.452a1.3,1.3,0,0,1-.485-.1.621.621,0,0,0-.663.087l-.824.759a.506.506,0,0,0-.1.115.5.5,0,0,0,.168.682,3.632,3.632,0,0,0,1.5.5V10.4a.728.728,0,0,0,.753.694h.753a.728.728,0,0,0,.753-.694V9.641A2.489,2.489,0,0,0,16.626,6.91a2.629,2.629,0,0,0-2.018-2.068l-2.353-.62a.372.372,0,0,1-.287-.351.382.382,0,0,1,.4-.364h1.543a1.3,1.3,0,0,1,.487.1.621.621,0,0,0,.663-.087l.824-.759a.49.49,0,0,0,.094-.113.5.5,0,0,0-.166-.684,3.634,3.634,0,0,0-1.5-.5V.694A.727.727,0,0,0,13.558,0H12.8a.727.727,0,0,0-.753.694v.763a2.491,2.491,0,0,0-2.32,2.731A2.631,2.631,0,0,0,11.751,6.256Zm12.754,7.968a1.435,1.435,0,0,0-1.847,0l-4.006,3.2a2.759,2.759,0,0,1-1.734.607H11.791a.694.694,0,1,1,0-1.387h3.394a1.439,1.439,0,0,0,1.441-1.153,1.353,1.353,0,0,0,.02-.237,1.387,1.387,0,0,0-1.387-1.385H8.323a5.1,5.1,0,0,0-3.212,1.14L3.1,16.647H.694A.694.694,0,0,0,0,17.34V21.5a.694.694,0,0,0,.694.694H16.16a2.774,2.774,0,0,0,1.734-.607l6.556-5.245a1.387,1.387,0,0,0,.055-2.12Z"
                                      fill="#294599" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20.971" height="18.196"
                                    viewBox="0 0 24.971 22.196">
                                    <path id="hand-holding-usd-solid"
                                      d="M11.751,6.256l2.353.62a.372.372,0,0,1,.287.351.382.382,0,0,1-.4.364H12.452a1.3,1.3,0,0,1-.485-.1.621.621,0,0,0-.663.087l-.824.759a.506.506,0,0,0-.1.115.5.5,0,0,0,.168.682,3.632,3.632,0,0,0,1.5.5V10.4a.728.728,0,0,0,.753.694h.753a.728.728,0,0,0,.753-.694V9.641A2.489,2.489,0,0,0,16.626,6.91a2.629,2.629,0,0,0-2.018-2.068l-2.353-.62a.372.372,0,0,1-.287-.351.382.382,0,0,1,.4-.364h1.543a1.3,1.3,0,0,1,.487.1.621.621,0,0,0,.663-.087l.824-.759a.49.49,0,0,0,.094-.113.5.5,0,0,0-.166-.684,3.634,3.634,0,0,0-1.5-.5V.694A.727.727,0,0,0,13.558,0H12.8a.727.727,0,0,0-.753.694v.763a2.491,2.491,0,0,0-2.32,2.731A2.631,2.631,0,0,0,11.751,6.256Zm12.754,7.968a1.435,1.435,0,0,0-1.847,0l-4.006,3.2a2.759,2.759,0,0,1-1.734.607H11.791a.694.694,0,1,1,0-1.387h3.394a1.439,1.439,0,0,0,1.441-1.153,1.353,1.353,0,0,0,.02-.237,1.387,1.387,0,0,0-1.387-1.385H8.323a5.1,5.1,0,0,0-3.212,1.14L3.1,16.647H.694A.694.694,0,0,0,0,17.34V21.5a.694.694,0,0,0,.694.694H16.16a2.774,2.774,0,0,0,1.734-.607l6.556-5.245a1.387,1.387,0,0,0,.055-2.12Z"
                                      fill="#FF993C" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'genericservice'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="home" class="svg-inline--fa fa-home fa-w-18" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="home" class="svg-inline--fa fa-home fa-w-18" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'paw'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="paw" class="svg-inline--fa fa-paw fa-w-16" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="paw" class="svg-inline--fa fa-paw fa-w-16" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'tshirt'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="tshirt" class="svg-inline--fa fa-tshirt fa-w-20"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M631.2 96.5L436.5 0C416.4 27.8 371.9 47.2 320 47.2S223.6 27.8 203.5 0L8.8 96.5c-7.9 4-11.1 13.6-7.2 21.5l57.2 114.5c4 7.9 13.6 11.1 21.5 7.2l56.6-27.7c10.6-5.2 23 2.5 23 14.4V480c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V226.3c0-11.8 12.4-19.6 23-14.4l56.6 27.7c7.9 4 17.5.8 21.5-7.2L638.3 118c4-7.9.8-17.6-7.1-21.5z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="tshirt" class="svg-inline--fa fa-tshirt fa-w-20"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M631.2 96.5L436.5 0C416.4 27.8 371.9 47.2 320 47.2S223.6 27.8 203.5 0L8.8 96.5c-7.9 4-11.1 13.6-7.2 21.5l57.2 114.5c4 7.9 13.6 11.1 21.5 7.2l56.6-27.7c10.6-5.2 23 2.5 23 14.4V480c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V226.3c0-11.8 12.4-19.6 23-14.4l56.6 27.7c7.9 4 17.5.8 21.5-7.2L638.3 118c4-7.9.8-17.6-7.1-21.5z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'warehouse'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="warehouse" class="svg-inline--fa fa-warehouse fa-w-20"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="warehouse" class="svg-inline--fa fa-warehouse fa-w-20"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>

                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'broom'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 87.49998701560162, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z"
                                        style="fill: #294599;" />
                                    </g>
                                    <path d="M 1107.7864990234375 853.637451171875" style="fill: #294599; " />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 87.49998701560162, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z"
                                        style="fill: #FF993C;" />
                                    </g>
                                    <path d="M 1107.7864990234375 853.637451171875" style="fill: #FF993C; " />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'chef'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 209.99992598044446, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 192 64 192h60.09L112 169.25a8 8 0 0 1 7.33-8.61l16-1.28a8 8 0 0 1 8.61 7.34L156.2 320h83.14V168a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v152h84.46l12.27-153.3a8 8 0 0 1 8.61-7.34l16 1.28a8 8 0 0 1 7.33 8.61L387.91 320H448s64-150.26 64-192a96 96 0 0 0-96-96zM64 480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352H64z"
                                        class="" style="fill: #294599;" />
                                    </g>
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 209.99992598044446, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 192 64 192h60.09L112 169.25a8 8 0 0 1 7.33-8.61l16-1.28a8 8 0 0 1 8.61 7.34L156.2 320h83.14V168a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v152h84.46l12.27-153.3a8 8 0 0 1 8.61-7.34l16 1.28a8 8 0 0 1 7.33 8.61L387.91 320H448s64-150.26 64-192a96 96 0 0 0-96-96zM64 480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352H64z"
                                        class="" style="fill: #FF993C;" />
                                    </g>
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'washer'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 273.1640495156016, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm36.87-163.66a51.23 51.23 0 0 1-73.74 0 51.79 51.79 0 0 1-73.24 1A110.76 110.76 0 0 0 112 304a112 112 0 0 0 224 0 110.76 110.76 0 0 0-1.89-18.69 51.79 51.79 0 0 1-73.24-1z"
                                        class="" style="fill: #294599;" />
                                    </g>
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg style="margin-right: 10px;" id="master-artboard" viewBox="0 0 1400 980"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 273.1640495156016, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm36.87-163.66a51.23 51.23 0 0 1-73.74 0 51.79 51.79 0 0 1-73.24 1A110.76 110.76 0 0 0 112 304a112 112 0 0 0 224 0 110.76 110.76 0 0 0-1.89-18.69 51.79 51.79 0 0 1-73.24-1z"
                                        class="" style="fill: #FF993C;" />
                                    </g>
                                  </svg>
                                </ng-container>


                              </ng-container>

                              <span
                                style="color:#707070 ; font-size: 12;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;text-align: center;">{{messageitem.message}}</span>
                            </div>
                          </ng-container>

                          <!-- regular message-->
                          <ng-container
                            *ngIf="messageitem.istodownload == false && messageitem.isimage == false && messageitem.isnotification == false && messageitem.isstepnotification == false">
                            <div class="{{messageitem.style}}">
                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>
                              <div class="message-block"><span>{{messageitem.data}}</span>

                                <ng-container *ngIf="messageitem.innerHTML">
                                  <div class="message-text" [innerHTML]="messageitem.message">
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="!messageitem.innerHTML">
                                  <div class="message-text" [innerText]="messageitem.message">
                                  </div>
                                </ng-container>



                                <ng-container
                                  *ngIf="messageitem.style != 'chat-message is-sent' && messageitem.style != 'chat-message is-sent has-reactions'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="messageitem.emoji!=null && messageitem.emoji!=''">
                                  <div class="reaction-counts">
                                    <div class="reaction-count">
                                      <span>{{messageitem.emoji}}</span><span>1</span>
                                    </div>
                                  </div>
                                </ng-container>

                              </div>
                            </div>
                          </ng-container>

                          <!-- file to donwload -->
                          <ng-container
                            *ngIf="messageitem.istodownload == true && messageitem.isnotification == false && messageitem.isstepnotification == false">
                            <div class="{{messageitem.style}}">

                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>
                              <div class="message-block"><span>{{messageitem.data}}</span>
                                <div class="message-text">
                                  <div class="uploaded-file">
                                    <div class="icon-container">
                                      <img src="assets/images/pdf-icon.png" class="mx-auto"
                                        alt="Villa Called Archangel PDF Document">
                                    </div>
                                    <div class="meta">
                                      <span><a href="{{messageitem.fileurl}}"
                                          target="_blank">{{messageitem.filename}}</a></span><span>{{this.bytesToSize(messageitem.filesize)}}</span>
                                    </div>
                                    <div class="action"><a href="{{messageitem.fileurl}}" target="_blank">
                                        <i class="far fa-arrow-alt-circle-down ml-1 text-primary"></i>
                                      </a></div>
                                  </div>

                                  {{messageitem.message}}

                                </div>
                                <ng-container *ngIf="messageitem.style != 'chat-message is-sent'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>

                              </div>
                            </div>
                          </ng-container>

                          <!-- image to show -->
                          <ng-container
                            *ngIf="messageitem.isimage == true && messageitem.isnotification == false && messageitem.isstepnotification == false">

                            <div class="{{messageitem.style}}">

                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>

                              <div class="message-block"><span>{{messageitem.data }}</span>
                                <div class="message-text"><a lg-uid="lg0"><img class="uploaded-image"
                                      src="{{messageitem.fileurl}}" href="#"
                                      (click)="showpopupimage(messageitem.fileurl)"></a>
                                  {{messageitem.message}}</div>
                                <ng-container *ngIf="messageitem.style != 'chat-message is-sent'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>

                        </div>

                      </div>
                    </div>


                    <div class="chat-action">
                      <form class="chat-action-inner">
                        <div class="typing-indicator-wrap indicator-desktop" style="left:calc(100% - 340px);">
                          <ng-container *ngIf="!this.userA.user_proceed && this.showdeclinedstatusbuttonUserA == false">
                            <button class="btn btn-primary"
                              style="width: 120px; height: 33.4px; background-color: #707070;" type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.userA.user_proceed && this.showdeclinedstatusbuttonUserA == false">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1"><svg xmlns="http://www.w3.org/2000/svg"
                                  width="15.899" height="33.421" viewBox="0 0 25.899 33.421">
                                  <path id="Path_72" data-name="Path 72"
                                    d="M2076.527,220.5s10.235,8.43,9.157,13.278,7-33.935,15.082-29.626"
                                    transform="translate(-2075.573 -202.281)" fill="none" stroke="#fff"
                                    stroke-width="3" />
                                </svg>
                              </span>
                              <span>{{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}</span>
                            </button>
                          </ng-container>


                          <ng-container *ngIf="this.showdeclinedstatusbuttonUserA">
                            <button class="btn" style="width: 120px;height:33.4px; background-color: #ff6935;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.69" height="20.69"
                                  viewBox="0 0 35.69 35.69">
                                  <path id="ban-solid"
                                    d="M25.845,8A17.845,17.845,0,1,0,43.69,25.845,17.845,17.845,0,0,0,25.845,8Zm9.362,8.483A13.242,13.242,0,0,1,36.7,33.439L18.252,15A13.241,13.241,0,0,1,35.207,16.483ZM16.483,35.207A13.242,13.242,0,0,1,15,18.252L33.439,36.7A13.241,13.241,0,0,1,16.483,35.207Z"
                                    transform="translate(-8 -8)" fill="#fff" />
                                </svg>

                              </span>
                              <span>{{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}</span>
                            </button>
                          </ng-container>

                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ng-container *ngIf="!this.userB.user_proceed && this.showdeclinedstatusbuttonUserB == false">
                            <button class="btn btn-primary"
                              style="width: 120px; height: 33.4px; background-color: #707070;" type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.userB.user_proceed && this.showdeclinedstatusbuttonUserB == false">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1"><svg xmlns="http://www.w3.org/2000/svg"
                                  width="15.899" height="33.421" viewBox="0 0 25.899 33.421">
                                  <path id="Path_72" data-name="Path 72"
                                    d="M2076.527,220.5s10.235,8.43,9.157,13.278,7-33.935,15.082-29.626"
                                    transform="translate(-2075.573 -202.281)" fill="none" stroke="#fff"
                                    stroke-width="3" />
                                </svg>
                              </span>
                              <span>{{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}</span>
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.showdeclinedstatusbuttonUserB">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35.69" height="35.69"
                                  viewBox="0 0 35.69 35.69">
                                  <path id="ban-solid"
                                    d="M25.845,8A17.845,17.845,0,1,0,43.69,25.845,17.845,17.845,0,0,0,25.845,8Zm9.362,8.483A13.242,13.242,0,0,1,36.7,33.439L18.252,15A13.241,13.241,0,0,1,35.207,16.483ZM16.483,35.207A13.242,13.242,0,0,1,15,18.252L33.439,36.7A13.241,13.241,0,0,1,16.483,35.207Z"
                                    transform="translate(-8 -8)" fill="#fff" />
                                </svg>

                              </span>
                              <span>{{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}</span>
                            </button>
                          </ng-container>



                        </div>
                        <div class="control">

                          <textarea
                            style="padding-right: 85px !important; line-height: 1; padding-top: 12px !important;"
                            placeholder="Type your message" class="textarea comment-textarea" rows="1" id="inputmessage"
                            name="inputmessage" [(ngModel)]="inputmessage" (keyup.enter)="sendMessage($event)"
                            (keypress)="keyPress($event)">
                                </textarea>

                          <div class="compose-dropdown">
                            <div><input type="file"
                                accept="image/*, application/pdf, application/zip, application/tar, application/gzip, .rar"
                                id="inputchatattachement" (change)="onAttachmentSelect($event)"><button>
                                <div class="add-button">
                                  <div class="button-inner"><svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                      height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                      class="feather feather-paperclip">
                                      <path
                                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                                      </path>
                                    </svg></div>
                                </div>
                              </button></div>
                          </div>
                          <div class="toolbar"><button type="button" class="button-emoji" [ngbPopover]="popContent"><svg
                                xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-smile">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                              </svg></button><button class="button is-primary" (click)="sendMessage($event)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-send">
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg> </button></div>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- chat end-->
          </div>

        </div>


      </div>
    </div>

    <div id="showMobileChat" class="row" style="margin-right: 0px;">
      <div class="bd-example-wrapper">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <ng-container *ngIf="this.mobileunreadeventsteps=='0'">
              <a class="nav-link active" data-toggle="tab" href="#result-177" role="tab" aria-controls="result-177"
                aria-selected="true" (click)="setmobilechatActiveTab('1')">EVENT
                STEPS<span class="badge badge-light">{{this.mobileunreadeventsteps}}</span></a>
            </ng-container>
            <ng-container *ngIf="this.mobileunreadeventsteps!='0'">
              <a class="nav-link active" data-toggle="tab" href="#result-177" role="tab" aria-controls="result-177"
                aria-selected="true" (click)="setmobilechatActiveTab('1')">EVENT
                STEPS<span class="badge badge-orange">{{this.mobileunreadeventsteps}}</span></a>
            </ng-container>
          </li>
          <li class="nav-item" role="presentation">

            <ng-container *ngIf="this.mobileunreadchat=='0'">

              <a class="nav-link" data-toggle="tab" href="#html-177" role="tab" aria-controls="html-177"
                aria-selected="false" (click)="setmobilechatActiveTab('2')">CHAT <span
                  class="badge badge-light">{{this.mobileunreadchat}}</span></a>

            </ng-container>
            <ng-container *ngIf="this.mobileunreadchat!='0'">

              <a class="nav-link" data-toggle="tab" href="#html-177" role="tab" aria-controls="html-177"
                aria-selected="false" (click)="setmobilechatActiveTab('2')">CHAT <span
                  class="badge badge-orange">{{this.mobileunreadchat}}</span></a>

            </ng-container>

          </li>
        </ul>
        <div class="tab-content" style="background-color: white;" id="examplte-tab-content-177">
          <div class="tab-pane fade active show" style="width:88vw" id="result-177" role="tabpanel"
            aria-labelledby="result-177">


            <div id="eventsbox2" style="height: 535px;position:relative">
              <div id="eventsbox3" style="max-height:100%;overflow-y: scroll;border:0px solid;  min-height: 530px;"
                class="has-slimscroll">

                <!-- accordion start-->

                <!-- accordion 00 Presentation-->
                <ng-container *ngIf="this.listUIConfiguration[0].isvisible">
                  <div class="aspect-tab pt-2" style="width: 98%; margin-top: 10px;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                    <input id="item-118" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[0].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[0].isaccordionopen">
                    <label for="item-118" class="aspect-label"></label>


                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[0].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[0].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[0].stepname}}</span>

                      </div>
                      <div class="aspect-stat">

                        <div class="nav-start">
                          <div class="recipient-block">

                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper" style="padding: 0px 0px 0px!important">
                        <div class="container" style="padding: 0 0px;">

                          <div class="row" style="margin-right: 0px; margin-left: 0px;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                            <div class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">

                                <table border="0">
                                  <tbody>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td>
                                        <h5 class="card-title">Property</h5>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td>

                                        <div class="card fadeInUp animated" data-animate="fadeInUp">
                                          <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                            <img src="{{this.propertyDetails[0].imageurl}}"
                                              alt="Villa on Hollywood Boulevard" class="responsivegridimage">
                                            <div class="card-img-overlay d-flex flex-column">
                                            </div>
                                          </div>
                                          <div
                                            class="card-header bg-transparent d-flex justify-content-between align-items-center py-3">
                                            <p class="fs-17 font-weight-bold text-heading mb-0 lh-1">
                                              {{this.propertyDetails[0].Price}}
                                            </p>
                                            <span
                                              class="badge badge-primary">{{this.propertyDetails[0].PropertySellType}}</span>
                                          </div>
                                          <div class="card-body py-2">
                                            <h4 class="fs-16 lh-2 mb-0"><a
                                                (click)="navigateToPropertyDetails(this.propertyDetails[0].IdProperty)"
                                                style="cursor: pointer"
                                                class="text-dark hover-primary">{{this.propertyDetails[0].Name}}</a>
                                            </h4>
                                            <p class="font-weight-400 text-gray-light mb-0 fs-13">
                                              {{this.locationdesc}}
                                            </p>
                                          </div>
                                          <div class="card-footer bg-transparent pt-3 pb-4">
                                            <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                                              <li
                                                class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="" data-original-title="3 Bedroom">
                                                <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                                  <use xlink:href="#icon-bedroom"></use>
                                                </svg>
                                                {{this.propertyDetails[0].Beds}}
                                              </li>
                                              <li
                                                class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="" data-original-title="3 Bathrooms">
                                                <svg class="icon icon-shower fs-18 text-primary mr-1">
                                                  <use xlink:href="#icon-shower"></use>
                                                </svg>
                                                {{this.propertyDetails[0].Baths}}
                                              </li>
                                              <li
                                                class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="" data-original-title="Size">
                                                <svg class="icon icon-square fs-18 text-primary mr-1">
                                                  <use xlink:href="#icon-square"></use>
                                                </svg>
                                                {{this.propertyDetails[0].SquareFeet}}
                                              </li>
                                              <li
                                                class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="" data-original-title="1 Garage">
                                                <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                                  <use xlink:href="#icon-Garage"></use>
                                                </svg>
                                                {{this.propertyDetails[0].Garage}}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td>
                                        <span class="badge badge-pill badge-secondary" style="width: 100% ">Event
                                          type
                                          : <b>{{eventinfo.eventname}}</b></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>


                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">

                                <table border="0">
                                  <thead>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <th colspan="2">
                                        <h5 class="card-title">Participants</h5>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td style="text-align: center;">
                                        <ng-container *ngIf="this.userA.isLandlord">
                                          <h1>Landlord</h1>
                                        </ng-container>
                                        <ng-container *ngIf="!this.userA.isLandlord">
                                          <h1>Tenant</h1>
                                        </ng-container>
                                      </td>
                                      <td style="text-align: center;">
                                        <ng-container *ngIf="this.userB.islandlord">
                                          <h1>Landlord</h1>
                                        </ng-container>
                                        <ng-container *ngIf="!this.userB.islandlord">
                                          <h1>Tenant</h1>
                                        </ng-container>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td>
                                        <div class="recipient-block" style="justify-content: center;">
                                          <div class="avatar-container-box"><img class="userlandlord-avatar"
                                              loading="lazy" src="{{this.userA.userphotourl}}"
                                              style="display: block;margin-left: auto;margin-right: auto;" alt="">
                                          </div>

                                        </div>
                                      </td>
                                      <td>
                                        <div class="recipient-block" style="justify-content: center;">

                                          <div class="avatar-container-box"><img class="usertentant-avatar"
                                              loading="lazy" src="{{this.userB.userphotourl}}" alt=""></div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td style="text-align: center;">
                                        <p><strong>{{this.userA.username}}</strong></p>
                                      </td>
                                      <td style="text-align: center;">
                                        <p><strong>{{this.userB.username}}</strong></p>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td style="text-align: center;">
                                        <ul class="list-inline mb-3">
                                          <li class="list-inline-item fs-13 text-heading font-weight-500">
                                            {{this.userA.ratedescription}}</li>
                                          <br>
                                          <li class="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                            <ul class="list-inline mb-0">

                                              <li *ngFor="let item of this.arrayofstartsUserA;"
                                                class="list-inline-item mr-0">
                                                <span ngClass="{{item.classname}}"><i class="fas fa-star"></i></span>

                                              </li>
                                            </ul>
                                          </li>

                                        </ul>
                                      </td>
                                      <td style="text-align: center;">
                                        <ul class="list-inline mb-3">
                                          <li class="list-inline-item fs-13 text-heading font-weight-500">
                                            {{this.userB.ratedescription}}</li>
                                          <br>
                                          <li class="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                            <ul class="list-inline mb-0">


                                              <li *ngFor="let item of this.arrayofstartsUserB;"
                                                class="list-inline-item mr-0">
                                                <span ngClass="{{item.classname}}"><i class="fas fa-star"></i></span>
                                            </ul>
                                          </li>

                                        </ul>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td colspan="2" rowspan="2" style="text-align: center;">
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="66.729" height="58.203"
                                            viewBox="0 0 76.729 68.203">
                                            <path id="house-user-solid"
                                              d="M76.021,31.473l-7.818-6.9V6.394a2.131,2.131,0,0,0-2.131-2.131H57.546a2.131,2.131,0,0,0-2.131,2.131v6.883L41.932,1.372A6.127,6.127,0,0,0,38.364,0,6.112,6.112,0,0,0,34.8,1.372L.7,31.477A2.434,2.434,0,0,0,0,33.063a2.483,2.483,0,0,0,.545,1.427L3.4,37.658a2.816,2.816,0,0,0,1.6.706,2.887,2.887,0,0,0,1.424-.547l2.118-1.865V63.94A4.263,4.263,0,0,0,12.8,68.2H63.94A4.263,4.263,0,0,0,68.2,63.94V35.951l2.119,1.865a2.923,2.923,0,0,0,1.428.549,2.783,2.783,0,0,0,1.581-.707l2.852-3.172a2.883,2.883,0,0,0,.545-1.424A2.8,2.8,0,0,0,76.021,31.473ZM38.364,23.445a8.525,8.525,0,1,1-8.525,8.525A8.525,8.525,0,0,1,38.364,23.445ZM53.284,59.678H23.445a2.131,2.131,0,0,1-2.131-2.131A12.788,12.788,0,0,1,34.1,44.758h8.525A12.788,12.788,0,0,1,55.415,57.546,2.131,2.131,0,0,1,53.284,59.678Z"
                                              opacity="0.34" />
                                          </svg>
                                        </p>
                                        <p class="fs-14">Event started by <span
                                            style="color: #27449B;">{{eventinfo.eventstartedbyusername}}</span>
                                        </p>

                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td colspan="2" style="text-align: center;">
                                        <p class="text-heading font-weight-500">{{eventinfo?.creationdate?.split(',').join(', ')}}</p>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[0].accordionheadercolor}">
                                      <td colspan="2">
                                        <ng-container *ngIf="this.listUIConfiguration[0].showproceedbuttonUser">
                                          <a class="badge badge-primary" (click)="sendActionPROCEED('true')"
                                            style="color:white">PROCEED</a>
                                        </ng-container>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 01 Negotiation -->
                <ng-container *ngIf="this.listUIConfiguration[1].isvisible">
                  <div class="aspect-tab " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                    <input id="item-114" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[1].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[1].isaccordionopen">
                    <label for="item-114" class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[1].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[1].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[1].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper" style="padding: 0px 0px 0px!important;">
                        <div class="container" style="padding: 0 0px;">

                          <div class="row" style="margin-right: 0px; margin-left: 0px;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                            <div class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">
                                <table>
                                  <tr *ngIf="this.eventinfo.eventname == 'Maintenance'" style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                    <ng-container>
                                      <td style="width: 30%;height: 18px;">Was there a presential evalutation?</td>
                                    </ng-container>
                                  </tr>
                                  <tr *ngIf="this.eventinfo.eventname == 'Maintenance'" style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor }">
                                    <ng-container>
                                      <td style="width: 50%; height: 18px;">
                                        <div style="display: flex; gap: 10px;">
                                          <div id="yes" (click)="this.yesCheck()"
                                            style="cursor: pointer;display: flex; gap: 5px; align-items: center;">
                                            <p id="yes">Yes</p>
                                            <div id="yes" class="checkbox">
                                              <div id="yes" [ngClass]="{'checked': yesChecked}"></div>
                                            </div>
                                          </div>
                                          <div (click)="this.noCheck()" id="no"
                                            style="cursor: pointer;display: flex; gap: 5px; align-items: center;">
                                            <p id="no">No</p>
                                            <div id="no" class="checkbox">
                                              <div id="no" [ngClass]="{'checked': noChecked}"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </ng-container>
                                  </tr>
                                  <thead *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                      <th>General Commodities</th>
                                    </tr>
                                  </thead>
                                  <tbody *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                      <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                        <td style="width: 50%; height: 18px;">

                                          <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                            <div class="row">
                                              <div class="col-lg-7">
                                                <div class="form-group">

                                                  <div class="custom-control custom-checkbox"
                                                    style=" margin-bottom: 20px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                      id="commoditiecheckbox{{commoditie.id}}"
                                                      [checked]="commoditie.iselected"
                                                      (change)="onGeneralCommoditiesChange($event,commoditie.id)"
                                                      [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true">
                                                    <label class="custom-control-label"
                                                      for="commoditiecheckbox{{commoditie.id}}"
                                                      style="font-size: small;">{{commoditie.name}}</label>
                                                  </div>

                                                </div>
                                              </div>
                                              <div class="col-lg-4">
                                                <div class="form-group">

                                                  <div class="input-group input-group-sm mb-3">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                      id="inputgeneralcommodities{{(i+1)}}"
                                                      name="inputgeneralcommodities{{(i+1)}}" aria-label=""
                                                      aria-describedby="inputGroup-sizing-sm"
                                                      value="{{commoditie.value}}"
                                                      [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true"
                                                      (change)="submitUIObjectchanged($event,'listgeneralcommodities',commoditie.id)"
                                                      [readonly]="!commoditie.iselected || this.getmyprofile() =='Tenant'">
                                                  </div>


                                                </div>
                                              </div>

                                            </div>

                                          </div>


                                        </td>
                                      </ng-container>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">
                                <table>
                                  <thead>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                      <th>Included Services</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                      <td>
                                        <div *ngFor="let includedservice of this.listincludedservices; let i = index">

                                          <div class="row">
                                            <div class="col-lg-6">
                                              <div class="form-group">

                                                <div class="custom-control custom-checkbox">
                                                  <input type="checkbox" class="custom-control-input"
                                                    id="includedservicecheckbox{{includedservice.id}}"
                                                    [checked]="includedservice.iselected"
                                                    (change)="onIncludedServicessChange($event,includedservice.id)"
                                                    [disabled]="this.getmyprofile() =='Tenant' || this.listUIConfiguration[1].disablecheckboxs == true">
                                                  <label class="custom-control-label"
                                                    for="includedservicecheckbox{{includedservice.id}}"
                                                    style="font-size: small;">{{includedservice.name}}</label>
                                                </div>


                                              </div>
                                            </div>
                                            <div class="col-lg-4">
                                              <div class="form-group">

                                                <div class="input-group input-group-sm mb-3">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                  </div>
                                                  <input type="text" class="form-control"
                                                    id="inputincludedservice{{(i+1)}}"
                                                    name="inputincludedservice{{(i+1)}}" aria-label=""
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value="{{includedservice.value}}"
                                                    [disabled]="this.listUIConfiguration[1].disablecheckboxs"
                                                    (change)="submitUIObjectchanged($event,'listincludedservices',includedservice.id)"
                                                    [readonly]="!includedservice.iselected || this.getmyprofile() =='Tenant'">
                                                </div>


                                              </div>
                                            </div>

                                            <div class="col-lg-2">
                                              <ng-container
                                                *ngIf="includedservice.canbedeleted && this.getmyprofile() !='Tenant'">


                                                <div class="form-group">

                                                  <button class="btn-sm" style="padding-top:9px" type="button"
                                                    (click)="deleteincludedservice(includedservice.id)">
                                                    <span>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11"
                                                        viewBox="0 0 11 11">
                                                        <path id="times-solid"
                                                          d="M7.585,85.5l3.127-3.127a.983.983,0,0,0,0-1.39l-.695-.7a.983.983,0,0,0-1.39,0L5.5,83.415,2.373,80.288a.983.983,0,0,0-1.39,0l-.7.7a.983.983,0,0,0,0,1.39L3.415,85.5.288,88.627a.983.983,0,0,0,0,1.39l.7.695a.983.983,0,0,0,1.39,0L5.5,87.585l3.127,3.127a.983.983,0,0,0,1.39,0l.695-.695a.983.983,0,0,0,0-1.39Z"
                                                          transform="translate(0 -80)" fill="#ff6b3c" />
                                                      </svg>

                                                    </span>
                                                  </button>
                                                </div>
                                              </ng-container>
                                            </div>

                                          </div>

                                        </div>

                                        <ng-container *ngIf="this.ismyuserlandlord()">
                                          <ng-container *ngIf="this.listUIConfiguration[1].showproceedbuttonUser">
                                            <button class="btn btn-primary btn-sm" style=" background-color: #10c6d5; "
                                              type="button" (click)="includedservicesModal()">
                                              <span style="padding-left:4px">Add New Service
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29"
                                                  viewBox="0 0 47 47">
                                                  <defs>
                                                    <filter id="Ellipse_317" x="0" y="0" width="47" height="47"
                                                      filterUnits="userSpaceOnUse">
                                                      <feOffset dy="3" input="SourceAlpha" />
                                                      <feGaussianBlur stdDeviation="3" result="blur" />
                                                      <feFlood flood-opacity="0.161" />
                                                      <feComposite operator="in" in2="blur" />
                                                      <feComposite in="SourceGraphic" />
                                                    </filter>
                                                  </defs>
                                                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_317)">
                                                    <circle id="Ellipse_317-2" data-name="Ellipse 317" cx="14.5"
                                                      cy="14.5" r="14.5" transform="translate(9 6)" fill="#fff" />
                                                  </g>
                                                  <text id="_" data-name="+" transform="translate(0 41)" fill="#0ec6d5"
                                                    font-size="38" font-family="SegoeUI, Segoe UI">
                                                    <tspan x="10" y="-8">+</tspan>
                                                  </text>
                                                </svg>
                                              </span>
                                            </button>
                                          </ng-container>
                                        </ng-container>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                          </div>
                          <!-- totals  -->
                          <div class="row" style="margin-right: 0px; margin-left: 0px;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                            <div *ngIf="this.eventinfo.eventname != 'Maintenance'" class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">
                                <!-- a1-->

                                <div class="row" style="margin-right: 0px; margin-left: 0px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                  <div class="col-lg-6">
                                    <div class="form-group">
                                      <div class="input-group input-group-sm mb-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">Rent</span>
                                        </div>
                                        <input type="text" value="${{this.rentvalue}}" id="rent" name="rent"
                                          class="form-control" aria-label="Amount (to the nearest dollar)"
                                          [disabled]="this.listUIConfiguration[1].disablecheckboxs"
                                          (change)="submitUIObjectchanged($event,'negotiationRentvalue',0)"
                                          [readonly]="this.getmyprofile() =='Tenant'">
                                      </div>

                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div class="form-group">

                                      <!--<label for="deposit">Deposit</label>-->
                                      <div class="input-group input-group-sm mb-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">Deposit</span>
                                        </div>
                                        <input type="text" value="${{this.depositvalue}}" id="deposit" name="deposit"
                                          [disabled]="this.listUIConfiguration[1].disablecheckboxs" class="form-control"
                                          aria-label="Amount (to the nearest dollar)"
                                          (change)="submitUIObjectchanged($event,'negotiationDepositvalue',0)"
                                          [readonly]="this.getmyprofile() =='Tenant'">
                                      </div>

                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="card border-0 pb-0" style="box-shadow:none">

                                <!-- a2 -->
                                <div class="row" style="margin-right: 0px; margin-left: 0px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label style="font-size: 16px;" *ngIf="this.eventinfo.eventname != 'Maintenance'"
                                        for="total">Total/mo</label>

                                      <label style="font-size: 16px;" *ngIf="this.eventinfo.eventname == 'Maintenance'"
                                        for="total">Total</label>
                                      <div class="input-group mb-3">
                                        <input type="text" value="{{this.totalvalue}}" id="total" name="total"
                                          class="form-control"
                                          style="border:0px;font-size:small;font-weight: bold; color: #27449B;"
                                          readonly>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                          <!-- buttons -->
                          <div class="row" style="margin-right: 0px; margin-left: 00px;text-align: center;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[1].accordionheadercolor}">
                            <ng-container
                              *ngIf="this.ismyuserlandlord() && this.listUIConfiguration[1].showproceedbuttonUser">
                              <a class="badge badge-warning m-2" style="margin-right:10px;"
                                (click)="submitNegotiation()" style="color:white">Update</a>
                            </ng-container>
                            <ng-container
                              *ngIf="!this.ismyuserlandlord() && this.listUIConfiguration[1].showdeclinebutton && this.listUIConfiguration[1].showproceedbuttonUser">
                              <a class="badge badge-orange m-2" style="margin-right:10px;"
                                (click)="declineNegotiation()" style="color:white">Decline</a>
                            </ng-container>
                            <ng-container *ngIf="this.listUIConfiguration[1].showproceedbuttonUser">
                              <ng-container *ngIf="this.ismyuserlandlord()">
                                <a class="badge badge-primary m-2" (click)="updateAndProceedStep()" style="color:white">
                                  Proceed
                                </a>
                              </ng-container>
                              <ng-container *ngIf="!this.ismyuserlandlord()">
                                <a class="badge badge-primary m-2" (click)="sendActionPROCEED('true')"
                                  style="color:white">
                                  Accept
                                </a>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 02 Information Supply -->
                <ng-container *ngIf="this.listUIConfiguration[2].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                    <input id="item-112" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[2].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[2].isaccordionopen">
                    <label for="item-112" class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[2].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[2].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[2].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper" style="padding: 0px 0px 0px!important">
                        <div class="container" style="padding: 0px 0px 0px!important">

                          <div class="card text-center"
                            style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none">
                            <table style="border-collapse: collapse; width: 100%; ;" border="0">
                              <thead>
                                <tr style="text-align: left; width: 66.7613%; height: 18px;" colspan="2"
                                  [ngStyle]="{'background-color': this.getmyprofileColor()}">
                                  <td>
                                    <span style="color: white;;"><b>{{this.getmyprofile()}}</b>
                                    </span>
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>


                          <div class="row row-responsive" style="margin-right: 0px; margin-left: 0px;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                            <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                              <div class="card" style="border: 0px;;box-shadow:none; background-color: transparent;">
                                <div *ngIf="this.eventinfo.eventname === 'Maintenance'" style="padding: 0 !important;"
                                  class="form">
                                  <table
                                    style="border-collapse: collapse; width: 100%; height: 179px;padding: 0 !important; background-color: transparent; position: relative;"
                                    border="0">
                                    <tbody>
                                      <tr style="height: 18px;">
                                        <td style="width: 90%; height: 18px;">
                                          <p class="fs-15">
                                            House address were the service will be provided
                                          </p>
                                        </td>
                                        <td class="no-space" style="width: 3.69314%; height: 18px;">&nbsp;</td>
                                        <td class="no-space" style="width: 114.773%; height: 18px;">&nbsp;</td>
                                      </tr>
                                      <tr class="forms-columns" style="background-color: transparent;">
                                        <td class="first-column">
                                          <div class="form-group col-md-14"><label>Address line 1</label>
                                            <input id="addressline1" class="form-control" name="addressline1"
                                              type="text" id="addressline1" [value]="this.submittedAddressScheduling"
                                              (input)="this.addressScheduling = getValue($event)"
                                              [ngClass]="{ 'is-invalid': this.addressError && !this.isuservendor() }"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                            <div *ngIf="this.addressError && !this.isuservendor()"
                                              class="invalid-feedback">
                                              <div *ngIf="this.addressError && !this.isuservendor()">Address is required
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group col-md-14"><label>Address line 2</label> <input
                                              id="addressline2" class="form-control" name="addressline2" type="text"
                                              [value]="this.submittedAddressTwoScheduling"
                                              (input)="this.addressTwoScheduling = getValue($event)"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                          </div>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>Country</label>
                                              <input id="country" class="form-control" name="country" type="text"
                                                id="country" [value]="this.submittedCountryScheduling"
                                                (input)="this.countryScheduling = getValue($event)"
                                                [ngClass]="{ 'is-invalid': this.countryError && !this.isuservendor() }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                              <div *ngIf="this.countryError && !this.isuservendor()"
                                                class="invalid-feedback">
                                                <div *ngIf="this.countryError && !this.isuservendor()">Country is
                                                  required</div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>State</label>
                                              <input id="state" class="form-control" name="state" type="text" id="state"
                                                [value]="this.submittedStateScheduling"
                                                (input)="this.stateScheduling = getValue($event)"
                                                [ngClass]="{ 'is-invalid': this.stateError && !this.isuservendor() }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                              <div *ngIf="this.stateError && !this.isuservendor()"
                                                class="invalid-feedback">
                                                <div *ngIf="this.stateError && !this.isuservendor()">State is required
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>City</label> <input id="city"
                                                class="form-control" name="city" type="text" id="city"
                                                (input)="this.cityScheduling = getValue($event)"
                                                [value]="this.submittedCityScheduling"
                                                [ngClass]="{ 'is-invalid': this.cityError && !this.isuservendor() }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                              <div *ngIf="this.cityError && !this.isuservendor()"
                                                class="invalid-feedback">
                                                <div *ngIf="this.cityError && !this.isuservendor()">City is required
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>ZIP Code</label> <input id="zipcode"
                                                class="form-control" name="zipcode" type="text" id="zipcode"
                                                [value]="this.submittedZipcodeScheduling"
                                                (input)="this.zipcodeScheduling = getValue($event)"
                                                [ngClass]="{ 'is-invalid': this.zipcodeError && !this.isuservendor() }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                              <div *ngIf="this.zipcodeError && !this.isuservendor()"
                                                class="invalid-feedback">
                                                <div *ngIf="this.zipcodeError && !this.isuservendor()">ZIP code is
                                                  required</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="second-column">
                                          <div class="form-group select-control date col-md-14">
                                            <label>Date</label>
                                            <input readonly style="width: 180px;" class="form-control"
                                              [(ngModel)]="model" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                              #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="getDate($event)"
                                              navigation="select" [value]="this.submittedDateScheduling"
                                              [ngClass]="{ 'is-invalid': this.dateError && !this.isuservendor() }"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()" />
                                            <div *ngIf="this.dateError && !this.isuservendor()"
                                              class="invalid-feedback">
                                              <div *ngIf="this.dateError && !this.isuservendor()">Date is required</div>
                                            </div>
                                          </div>
                                          <div class="form-group  col-md-14">
                                            <label>Time</label>
                                            <input style="width: 180px;" placeholder="12hr format (default settings)"
                                              outsideDays="visible" aria-label="12hr format" [ngxTimepicker]="default"
                                              id="date" class="form-control" name="date"
                                              [value]="this.submittedTimeScheduling"
                                              [ngClass]="{ 'is-invalid': this.timeError && !this.isuservendor() }"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              readonly>
                                            <ngx-material-timepicker (timeSet)="getTime($event)" #default>
                                            </ngx-material-timepicker>
                                            <div *ngIf="this.timeError && !this.isuservendor()"
                                              class="invalid-feedback">
                                              <div *ngIf="this.timeError && !this.isuservendor()">Time is required</div>
                                            </div>
                                          </div>
                                          <div class="form-group  col-md-14"><label>Name</label>
                                            <input style="width: 180px;" id="name" class="form-control" name="name"
                                              [disabled]=" this.listUIConfiguration[2].disablecheckboxs ||
                                            this.isuservendor()"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              [value]="this.submittedNameScheduling"
                                              [ngClass]="{ 'is-invalid': this.nameError && !this.isuservendor() }"
                                              (input)="this.nameScheduling = getValue($event)" type="text" />
                                            <div *ngIf="this.nameError && !this.isuservendor()"
                                              class="invalid-feedback">
                                              <div *ngIf="this.nameError && !this.isuservendor()">Name is required</div>
                                            </div>
                                          </div>
                                          <div class="form-group  col-md-14"><label>Phone</label>
                                            <input style="width: 180px;" id="phone" class="form-control" name="phone"
                                              type="text" id="phone"
                                              [ngClass]="{ 'is-invalid': this.phoneError && !this.isuservendor() }"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()"
                                              [value]="this.submittedPhoneScheduling"
                                              (input)="this.phoneScheduling = getValue($event)" />
                                            <div *ngIf="this.phoneError && !this.isuservendor()"
                                              class="invalid-feedback">
                                              <div *ngIf="this.phoneError && !this.isuservendor()">Phone number is
                                                required</div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                  <div
                                    *ngIf="!this.isuservendor() && this.listUIConfiguration[2].showproceedbuttonUser ">
                                    <div style="width: 100%;">
                                      <div style="display: flex; gap: 5px; padding-left: 0.55rem !important;"
                                        class="custom-control custom-checkbox">
                                        <button (click)="showChecked(!this.checkedTerms)"
                                          [ngClass]="{'terms-checkbox': true, 'terms-checked': this.checkedTerms}"
                                          [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                          <fa-icon *ngIf="this.checkedTerms" class="check-icon" [icon]="faCheck">
                                          </fa-icon>
                                        </button>
                                        <p class="fs-14">I have read and agree
                                          to the <a (click)="showtermsofuse()"
                                            style="color: navy;text-decoration: underline;">terms of
                                            use</a>
                                        </p>

                                      </div>
                                    </div>
                                  </div>
                                  <div style="width: 100%;display: flex; justify-content: center; " colspan="2">
                                    <ng-container *ngIf="this.listUIConfiguration[2].showproceedbuttonUser">
                                      <div style="display: flex; justify-content: center; gap: 20px;">
                                        <a *ngIf="this.isuservendor() && this.proceedScheduling"
                                          class="badge badge-primary" style="color:white;align-items: center;"
                                          (click)="onSubmitInformationSupply()">
                                          Agree
                                        </a>
                                        <a *ngIf="this.isuservendor() && !this.proceedScheduling"
                                          class="badge badge-primary"
                                          style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important; cursor: not-allowed !important;">
                                          Agree
                                        </a>
                                        <a *ngIf="!this.isuservendor() && this.checkedTerms" class="badge badge-primary"
                                          style="color:white;align-items: center;"
                                          (click)="onSubmitInformationSupply()">
                                          Agree
                                        </a>
                                        <a *ngIf="!this.isuservendor() && !this.checkedTerms"
                                          class="badge badge-primary"
                                          style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important; cursor: not-allowed !important;">
                                          Agree
                                        </a>
                                        <a *ngIf="this.isuservendor()" class="badge badge-primary"
                                          (click)="declineNegotiation()"
                                          style="color:white;align-items: center; background-color: #FF6B3C;">
                                          Refuse
                                        </a>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <form *ngIf="this.eventinfo.eventname !== 'Maintenance'" class="form"
                                  [formGroup]="informationsupplyForm" (ngSubmit)="onSubmitInformationSupply()">
                                  <table>
                                    <thead>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td style="height: 18px;">
                                          <p class="fs-15">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.726" height="16.388"
                                              viewBox="0 0 18.726 21.388">
                                              <path id="user-solid"
                                                d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                                transform="translate(0.02)" fill="#27449b" />
                                            </svg>
                                            You can use your present information!
                                          </p>
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>Email</label>
                                              <input id="email" class="form-control" name="email" type="email"
                                                formControlName="email" [(ngModel)]="userEmail" id="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors}"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs" />
                                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.pattern">Email is invalid</div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>Phone</label>
                                              <input id="phone" class="form-control" name="phone" type="text"
                                                formControlName="phone" [(ngModel)]="userPhoneNumber" id="phone"
                                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs" />
                                              <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                <div *ngIf="f.phone.errors.required">Phone number is required
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>ID Number</label>
                                              <input id="idnumber" class="form-control" name="idnumber" type="text"
                                                formControlName="idnumber" [(ngModel)]="userIdNumber" id="idnumber"
                                                [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.idnumber.errors.required">ID number is required
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>VAT</label>
                                              <input id="vat" class="form-control" name="vat" type="text"
                                                formControlName="vat" id="vat" [(ngModel)]="userVAT"
                                                [ngClass]="{ 'is-invalid': submitted && f.vat.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.vat.errors" class="invalid-feedback">
                                                <div *ngIf="f.vat.errors.required">VAT is required</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="form-group col-md-14"><label>Address line 1</label>
                                            <input id="addressline1" class="form-control" name="addressline1"
                                              type="text" formControlName="addressline1" [(ngModel)]="userAddress"
                                              id="addressline1"
                                              [ngClass]="{ 'is-invalid': submitted && f.addressline1.errors }"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                            <div *ngIf="submitted && f.addressline1.errors" class="invalid-feedback">
                                              <div *ngIf="f.addressline1.errors.required">Address is required
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group col-md-14"><label>Address line 2</label>
                                            <input id="addressline2" class="form-control" name="addressline2"
                                              type="text" formControlName="addressline2" [(ngModel)]="userAddress2"
                                              id="addressline2"
                                              [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                              [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>Country</label>
                                              <input id="country" class="form-control" name="country" type="text"
                                                formControlName="country" [(ngModel)]="userCountry" id="country"
                                                [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                <div *ngIf="f.country.errors.required">Country is required
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>State</label>
                                              <input id="state" class="form-control" name="state" type="text"
                                                formControlName="state" [(ngModel)]="userState" id="state"
                                                [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                                <div *ngIf="f.state.errors.required">State is required</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="form-row">
                                            <div class="form-group col-md-6"><label>City</label> <input id="city"
                                                class="form-control" name="city" type="text" formControlName="city"
                                                id="city" [(ngModel)]="userCity"
                                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                <div *ngIf="f.city.errors.required">City is required</div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-6"><label>ZIP Code</label> <input id="zipcode"
                                                class="form-control" name="zipcode" type="text"
                                                formControlName="zipcode" id="zipcode" [(ngModel)]="userZipCode"
                                                [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs"
                                                [readonly]="this.listUIConfiguration[2].disablecheckboxs">
                                              <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                                                <div *ngIf="f.zipcode.errors.required">ZIP code is required
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                        <td>
                                          <div class="custom-control custom-checkbox">
                                            <div style="display: flex; gap: 5px; padding-left: 0.55rem !important;"
                                              class="custom-control custom-checkbox">
                                              <button (click)="showChecked(!this.checkedTerms)" type="button"
                                                [ngClass]="{'terms-checkbox': true, 'terms-checked': this.checkedTerms}"
                                                [disabled]="this.listUIConfiguration[2].disablecheckboxs || this.isuservendor()">
                                                <fa-icon *ngIf="this.checkedTerms" class="check-icon" [icon]="faCheck">
                                                </fa-icon>
                                              </button>
                                              <p class="fs-14">I have read and agree
                                                to the <a (click)="showtermsofuse()"
                                                  style="color: navy;text-decoration: underline; white-space: nowrap;">terms
                                                  of
                                                  use</a>
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </form>
                              </div>
                            </div>
                            <div *ngIf="this.eventinfo.eventname !== 'Maintenance'" class="col-sm-6"
                              style="padding: 0px 0px 0px!important">
                              <div class="card" style="border: 0px;;box-shadow:none">

                                <table>
                                  <thead>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72"
                                          viewBox="0 0 22.5 72">
                                          <path id="exclamation-solid"
                                            d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                            transform="translate(-16)" fill="#ff993c" />
                                        </svg>
                                      </td>
                                      <td>
                                        <span
                                          style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                          <strong>RESPONSABILITY NOTICE</strong>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td colspan="2">
                                        <p class="fs-13">This information <strong style="color: navy;">WILL BE
                                            USED
                                            TO
                                            GENERATE THE CONTRACT!</strong> Please
                                          use
                                          only information that is true and belongs to yourself.
                                          <br><br><br>
                                          In case of any doubt, read the <a (click)="showtermsofuse()"
                                            style="color: navy;text-decoration: underline;"><strong>terms of
                                              use</strong></a> and or get in touch with us!

                                        </p>
                                        <br>
                                        <br>

                                        <p class="fs-13">

                                          <a style="color: navy;text-decoration: underline;"
                                            routerLink="/contactus"><strong>(support@roundcomb.com)</strong></a>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td colspan="2"></td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td colspan="2"></td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td colspan="2"></td>
                                    </tr>
                                    <tr
                                      [ngStyle]="{'background-color': this.listUIConfiguration[2].accordionheadercolor}">
                                      <td colspan="2"></td>
                                    </tr>
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                          <ng-container
                            *ngIf="this.listUIConfiguration[2].showproceedbuttonUser && this.eventinfo.eventname !== 'Maintenance'">
                            <div class="card ;box-shadow:none" style="padding: 0px 0px 0px!important;border: 0px;">
                              <table style="border-collapse: collapse; width: 100%; ;" border="0">
                                <thead>
                                  <tr style="text-align: left; width: 66.7613%; height: 18px;" colspan="2">

                                    <td style="padding-left: 25px; display: flex; justify-content: flex-end;">

                                      <ng-container
                                        *ngIf="this.listUIConfiguration[2].showproceedbuttonUser && this.checkedTerms">
                                        <a class="badge badge-primary" style="color:white;align-items: center;"
                                          (click)="onSubmitInformationSupply()">PROCEED</a>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="this.listUIConfiguration[2].showproceedbuttonUser && !this.checkedTerms">
                                        <a class="badge badge-primary"
                                          style="color:white;align-items: center; background-color: #707070 !important; opacity: 0.5 !important;">PROCEED</a>
                                      </ng-container>
                                    </td>
                                  </tr>
                                </thead>
                              </table>

                            </div>
                          </ng-container>

                        </div>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 03 Validate your Identity-->
                <ng-container *ngIf="this.listUIConfiguration[3].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                    <input id="item-113" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[3].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[3].isaccordionopen">
                    <label for="item-113" class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">

                        <ng-container *ngIf="this.listUIConfiguration[3].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[3].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[3].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}"
                        style="padding: 0px 0px 0px!important">

                        <div class="container" style="padding: 0px 0px 0px!important"
                          [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                          <ng-container *ngIf="this.eventinfo.eventname === 'Maintenance'">
                            <div
                              style="display: flex; justify-content: center; width: 100%; gap: 10px; align-items: center;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72" viewBox="0 0 22.5 72">
                                <path id="exclamation-solid"
                                  d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                  transform="translate(-16)" fill="#ff993c" />
                              </svg>
                              <div style="padding-right: 0 !important;width: 375px; background-color: transparent;">
                                <p style="color: #27449B; font-weight: bold; font-size: 16px;">
                                  The service is now being executed by the Vendor</p>
                              </div>
                            </div>
                            <p
                              style="text-align: center; color: rgb(154, 154, 154); font-size: 15px; background-color: transparent;">
                              Was the
                              service completed?</p>
                            <div style="width: 100%;display: flex; justify-content: center; ">
                              <ng-container *ngIf="!this.listUIConfiguration[3].disablecheckboxs">
                                <div
                                  style="display: flex; justify-content: center; gap: 20px; background-color: transparent;">
                                  <a (click)="completedDontSpanService()" class="badge badge-primary"
                                    style="color:white;align-items: center; width: 100px;">
                                    Yes
                                  </a>
                                  <a (click)="this.sendActionPROCEED('false');" class="badge badge-primary"
                                    style="color:white;align-items: center; background-color: #FF6B3C; width: 100px;">
                                    No
                                  </a>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                          <!-- first step stepvalidateIdentity = 1-->
                          <ng-container
                            *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '1' && this.eventinfo.eventname !== 'Maintenance'">
                            <div class="row" style="margin-right: 0px; margin-left: 0px;padding: 0px 0px 0px!important">
                              <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                                <div class="card text-center" style="border: 0px;;box-shadow:none"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                  <table style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                                    <tbody>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;">Upload your
                                          ID Card
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 63px;text-align: center;" rowspan="3">
                                          <ng-container *ngIf="this.getvalueofmyuser('IDCardphoto')">
                                            <img [src]="this.getvalueofmyuser('IDCardphoto')" id="IDCardphoto"
                                              name="IDCardphoto" alt="IDCard photo"
                                              style="height: 150px; width: 150px;" />
                                          </ng-container>
                                          <ng-container *ngIf="!this.getvalueofmyuser('IDCardphoto')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="160.714" height="125"
                                              viewBox="0 0 160.714 125">
                                              <path id="address-card-regular"
                                                d="M147.321,32H13.393A13.4,13.4,0,0,0,0,45.393v98.214A13.4,13.4,0,0,0,13.393,157H147.321a13.4,13.4,0,0,0,13.393-13.393V45.393A13.4,13.4,0,0,0,147.321,32Zm0,111.607H13.393V45.393H147.321ZM58.036,94.5A17.857,17.857,0,1,0,40.179,76.643,17.874,17.874,0,0,0,58.036,94.5Zm-25,35.714h50c3.46,0,6.25-2.4,6.25-5.357V119.5c0-8.873-8.4-16.071-18.75-16.071-3.013,0-5.218,2.232-12.5,2.232-7.506,0-9.319-2.232-12.5-2.232-10.352,0-18.75,7.2-18.75,16.071v5.357C26.786,127.815,29.576,130.214,33.036,130.214Zm67.411-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232v-4.464a2.239,2.239,0,0,0-2.232-2.232h-31.25a2.239,2.239,0,0,0-2.232,2.232v4.464A2.239,2.239,0,0,0,100.446,112.357Zm0-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232V87.8a2.239,2.239,0,0,0-2.232-2.232h-31.25A2.239,2.239,0,0,0,98.214,87.8v4.464A2.239,2.239,0,0,0,100.446,94.5Zm0-17.857H131.7a2.239,2.239,0,0,0,2.232-2.232V69.946a2.239,2.239,0,0,0-2.232-2.232h-31.25a2.239,2.239,0,0,0-2.232,2.232v4.464A2.239,2.239,0,0,0,100.446,76.643Z"
                                                transform="translate(0 -32)" fill="#b1b1b1" />
                                            </svg>

                                          </ng-container>
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;">

                                      </tr>
                                      <tr style="height: 21px;">

                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;"><input type="file"
                                            id="uploadIdCardPhoto" accept="image/png, image/gif, image/jpeg"
                                            (change)="onFileSelect($event)" hidden />
                                          <a class="badge badge-primary" style="color: white;" id="btnOpenFileDialog"
                                            (click)="openfileDialog();">Choose file</a>
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;">
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                                <div class="card text-center" style="border: 0px;;box-shadow:none"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                  <table style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                                    <tbody>

                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;">Verify your
                                          Identity
                                        </td>
                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;">
                                          <ng-container *ngIf="this.getvalueofmyuser('identityphoto')">
                                            <img [src]="this.getvalueofmyuser('identityphoto')" id="identityphoto"
                                              name="identityphoto" alt="Identity photo"
                                              style="height: 150px; width: 150px;border-radius: 50%" />
                                          </ng-container>
                                          <ng-container *ngIf="!this.getvalueofmyuser('identityphoto')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="160.715" height="131.28"
                                              viewBox="0 0 160.715 131.28">
                                              <path id="camera-solid"
                                                d="M160.715,64.82v84.394c0,7.765-6.749,14.066-15.067,14.066H15.067C6.749,163.28,0,156.98,0,149.215V64.82c0-7.765,6.749-14.066,15.067-14.066H42.69l3.861-9.641A15.047,15.047,0,0,1,60.645,32h39.394a15.047,15.047,0,0,1,14.094,9.113l3.892,9.641h27.623C153.966,50.754,160.715,57.055,160.715,64.82Zm-42.69,42.2c0-19.4-16.888-35.164-37.668-35.164S42.69,87.618,42.69,107.017s16.888,35.164,37.668,35.164S118.025,126.416,118.025,107.017Zm-10.045,0c0,14.212-12.4,25.787-27.623,25.787S52.735,121.23,52.735,107.017,65.133,81.23,80.357,81.23,107.98,92.805,107.98,107.017Z"
                                                transform="translate(0 -32)" fill="#b1b1b1" />
                                            </svg>
                                          </ng-container>
                                        </td>

                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;"><a
                                            (click)="cameraidentityModal()" class="badge badge-primary"
                                            style="color: white;">Take Photo</a></td>

                                      </tr>
                                      <tr style="height: 21px;">
                                        <td style="width: 318px; height: 21px;text-align: center;">

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div class="card text-center"
                              style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none">

                              <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                <tbody>

                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="width: 3.26708%; height: 36px;" rowspan="2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72"
                                        viewBox="0 0 22.5 72">
                                        <path id="exclamation-solid"
                                          d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                          transform="translate(-16)" fill="#ff993c" />
                                      </svg>
                                    </td>
                                    <td style="width: 96.7331%; height: 18px;" colspan="2">
                                      <span
                                        style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                        <strong>RESPONSABILITY</strong>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="width: 96.7331%; height: 18px;" colspan="2">
                                      <span
                                        style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                        <strong>NOTICE</strong>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="height: 18px;" colspan="3">
                                      <p class="fs-13">This information <strong style="color: navy;">WILL BE
                                          USED
                                          TO
                                          GENERATE THE CONTRACT!</strong> Please
                                        use
                                        only information that is true and belongs to yourself.
                                        <br><br><br>
                                        In case of any doubt, read the <a (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a> and or get in touch with us!
                                      </p>
                                      <br>


                                      <p class="fs-13">

                                        <a style="color: navy;text-decoration: underline;"
                                          routerLink="/contactus"><strong>(support@roundcomb.com)</strong></a>
                                      </p>
                                      <div style="display: flex; justify-content: flex-end;">
                                        <a class="badge badge-warning" style="color:white;align-items: center;"
                                          (click)="sendIdentity()">Send Identity</a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="height: 18px;align-items: center; text-align: center;" colspan="3">

                                      <ng-container
                                        *ngIf="this.listUIConfiguration[3].showproceedbuttonUser  && this.getvalueofmyuser('stepvalidateIdentity') == '2' && this.getStepValidateIdentityOtherUser() == '2'">

                                        <button type="button" class="btn"
                                          style="width: 95px;height:33.4px; background-color: #ff6935; color: white;"
                                          (click)="reportuserModal()">REPORT</button>
                                        &nbsp;
                                        &nbsp;
                                        <button type="button" class="btn"
                                          style="width: 95px;height:33.4px; background-color: #10c6d5; color: white;"
                                          (click)="dontSpanProceedMessage()">CONFIRM</button>
                                      </ng-container>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </ng-container>
                          <!-- second step stepvalidateIdentity = 2 && getStepValidateIdentityOtherUser() = '1'-->
                          <ng-container
                            *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '2' && this.getStepValidateIdentityOtherUser() == '1'">
                            <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                              <tbody>
                                <tr style="height: 53.5469px;">

                                  <td style="width: 529px; height: 53.5469px;" colspan="3">
                                    <div>
                                      <div>
                                        <p class="card-text fs-13 lh-2 text-heading mb-5"
                                          style="color: #707070; margin-top: 10px; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;text-align: center;">
                                          Please wait for {{this.getNameOtherUser()}} to send you the identity
                                          data
                                          for validation.</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr style="display: flex; justify-content: center;">
                                  <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </tr>
                                <tr style="height: 35px;">

                                  <td style="width: 31px; height: 35px;" colspan="3">
                                    <p class="card-text fs-13 lh-2 text-heading mb-5"
                                      style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;text-align: center;">
                                      Your data has been already sent to {{this.getNameOtherUser()}}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </ng-container>
                          <!-- last step stepvalidateIdentity = 2 && getStepValidateIdentityOtherUser() = '2'-->
                          <ng-container
                            *ngIf="this.getvalueofmyuser('stepvalidateIdentity') == '2' && this.getStepValidateIdentityOtherUser() == '2'">
                            <div class="row" style="margin-right: 0px; margin-left: 0px;padding: 0px 0px 0px!important">
                              <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                                <div class="card text-center" style="border: 0px;;box-shadow:none"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                  <div class="card-body">
                                    <img [src]="this.getIDCardPhtoOtherUser()" id="IDCardphotoIdentity"
                                      name="IDCardphotoIdentity" alt="Photo identity"
                                      style="height: 150px; width: 150px;" />
                                    <br>
                                    <div class="fs-13">Card Number: {{this.getIDNumberOtherUser()}}</div>

                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                                <div class="card text-center" style="border: 0px;;box-shadow:none"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                  <div class="card-body">
                                    <img [src]="this.getIdentityPhotoOtherUser()" id="IDCardphotoreview"
                                      name="IDCardphotoeview" alt="IDCard photo" style="height: 150px; width: 150px;" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="card text-center"
                              style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none">

                              <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                <tbody>

                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="width: 3.26708%; height: 36px;" rowspan="2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="72"
                                        viewBox="0 0 22.5 72">
                                        <path id="exclamation-solid"
                                          d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                          transform="translate(-16)" fill="#ff993c" />
                                      </svg>
                                    </td>
                                    <td style="width: 96.7331%; height: 18px;" colspan="2">
                                      <span
                                        style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                        <strong>DOES THE VERIFICATION</strong>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="width: 96.7331%; height: 18px;" colspan="2">
                                      <span
                                        style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20;">
                                        <strong>PHOTO MATCH WITH THE ID CARD?</strong>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="height: 18px;" colspan="3">
                                      <p class="fs-13">Please verify carefully if the <strong
                                          style="color: navy;">VERIFICATION PHOTO</strong> matches with the
                                        <strong style="color: navy;">ID CARD</strong> photo on
                                        the right and if the <strong style="color: navy;">ID NUMBER</strong>
                                        provided
                                        on the previous step matches the one on the
                                        photo.<br />If you find that the user does not look like the ID
                                        picture
                                        and
                                        may not
                                        be the same person <strong style="color: navy;">DO NOT
                                          PROCEED!</strong><br />In case of any doubt, read the <a
                                          (click)="showtermsofuse()"
                                          style="color: navy;text-decoration: underline;"><strong>terms of
                                            use</strong></a>
                                        and or get in touch with us!
                                      </p>
                                    </td>
                                  </tr>
                                  <tr style="height: 18px;"
                                    [ngStyle]="{'background-color': this.listUIConfiguration[3].accordionheadercolor}">
                                    <td style="height: 18px;align-items: center; text-align: center;" colspan="3">

                                      <ng-container *ngIf="this.listUIConfiguration[3].showproceedbuttonUser">

                                        <button type="button" class="btn"
                                          style="width: 95px;height:33.4px; background-color: #ff6935; color: white;"
                                          (click)="reportuserModal()">REPORT</button>
                                        &nbsp;
                                        &nbsp;
                                        <button type="button" class="btn"
                                          style="width: 95px;height:33.4px; background-color: #10c6d5; color: white;"
                                          (click)="dontSpanProceedMessage()">CONFIRM</button>
                                      </ng-container>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </ng-container>
                        </div>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 04 Signing the Contract -->
                <ng-container *ngIf="this.listUIConfiguration[4].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                    <input id="item-124" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[4].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[4].isaccordionopen">
                    <label for="item-124" class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">
                        <ng-container *ngIf="this.listUIConfiguration[4].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[4].isStepclosed">
                          <div class="chart-pie positive">
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[4].stepname}}</span>
                      </div>
                      <div class="aspect-stat">
                      </div>
                    </div>
                    <div class="aspect-tab-content" style="display: flex;
                    justify-content: center;
                    align-items: center;">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">

                        <ng-container *ngIf="this.listUIConfiguration[4].isvisible">
                          <div class="aspect-tab  " style="width: 98%;"
                            [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                            <div class="aspect-tab-content">
                              <div class="sentiment-wrapper"
                                [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                <ng-container
                                  *ngIf="this.IsContractConfirmed == 'false' && this.ismyuserlandlord() != true">
                                  <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                    <tbody>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 31px;" rowspan="0"> <svg xmlns="http://www.w3.org/2000/svg"
                                            width="22.5" height="72" viewBox="0 0 22.5 72">
                                            <path id="exclamation-solid"
                                              d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                              transform="translate(-16)" fill="#ff993c" />
                                          </svg></td>
                                        <td style="width: 498px;" colspan="1"><span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>THE CONTRACT WILL BE GENERATED</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 498px;" colspan="2"> <span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>BASED ON THE EARLYER STEPS.</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 529px;" colspan="3">
                                          <div>
                                            <div>
                                              <p class="fs-13">In case of any doubt, read the <a
                                                  (click)="showtermsofuse()"
                                                  style="color: navy;text-decoration: underline;"><strong>terms of
                                                    use</strong></a> and or get in touch with us!</p>
                                              <p class="fs-13" style="text-align: center;"><strong>Proposal & Contract
                                                  Acceptance</strong></p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td *ngIf="!this.VisibleContract" style="width: 403.125px;text-align: center;">
                                          <button disabled type="button" class="btn mb-2"
                                            style="width: 145px;height:33.4px; background-color: grey; color: white;"
                                            (click)="ViewContract()">View Contract</button>
                                        </td>
                                        <td *ngIf="this.VisibleContract" style="width: 403.125px;text-align: center;">
                                          <button type="button" class="btn mb-2"
                                            style="width: 145px;height:33.4px; background-color: #1979b9; color: white;"
                                            (click)="ViewContract()">View Contract</button>
                                          <button type="button" class="btn mb-2 ml-2"
                                            style="width: 149px;height:33.4px; background-color: #07cf61; color: white;"
                                            (click)="ConfirmContract()">Confirm Contract</button>
                                        </td>
                                        <td style="width: 94.875px;">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </ng-container>

                                <ng-container
                                  *ngIf="this.IsContractConfirmed == 'false' && this.ismyuserlandlord() == true">
                                  <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                    <tbody>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 31px;" rowspan="0"> <svg xmlns="http://www.w3.org/2000/svg"
                                            width="22.5" height="72" viewBox="0 0 22.5 72">
                                            <path id="exclamation-solid"
                                              d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                              transform="translate(-16)" fill="#ff993c" />
                                          </svg></td>
                                        <td style="width: 498px;" colspan="1"><span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>THE CONTRACT WILL BE GENERATED</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 498px;" colspan="2"> <span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>BASED ON THE EARLYER STEPS.</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 529px;" colspan="3">
                                          <div>
                                            <div>
                                              <p class="fs-13">In case of any doubt, read the <a
                                                  (click)="showtermsofuse()"
                                                  style="color: navy;text-decoration: underline;"><strong>terms of
                                                    use</strong></a> and or get in touch with us!</p>
                                              <p class="fs-13" style="text-align: center;"><strong>Proposal & Contract
                                                  Acceptance</strong></p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 403.125px;text-align: center;">
                                          <button type="button" class="btn mb-2"
                                            style="width: 180px;height:33.4px; background-color: #1979b9; color: white;"
                                            (click)="EditContract()">VIEW & EDIT CONTRACT</button>
                                          <button type="button" class="btn mb-2 ml-2 no-left"
                                            style="width: 180px;height:33.4px; background-color: #07cf61; color: white;"
                                            (click)="SubmitContract()">SUBMIT TO TENANT</button> <!-- submit -->
                                        </td>
                                        <td style="width: 94.875px;">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </ng-container>

                                <ng-container
                                  *ngIf="this.signerConfirmation_A == 'false' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() == true">
                                  <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                    <tbody>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 31px;" rowspan="0"> <svg xmlns="http://www.w3.org/2000/svg"
                                            width="22.5" height="72" viewBox="0 0 22.5 72">
                                            <path id="exclamation-solid"
                                              d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                              transform="translate(-16)" fill="#ff993c" />
                                          </svg></td>
                                        <td style="width: 498px;" colspan="0"><span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>THE CONTRACT WILL BE GENERATED</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 498px;" colspan="2"> <span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>BASED ON THE EARLYER STEPS.</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 529px;" colspan="3">
                                          <div>
                                            <div>
                                              <p class="fs-13">In case of any doubt, read the <a
                                                  (click)="showtermsofuse()"
                                                  style="color: navy;text-decoration: underline;"><strong>terms of
                                                    use</strong></a> and or get in touch with us!</p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 403.125px;text-align: center;">
                                          <button type="button" class="btn mb-2 ml-2"
                                            style="width: 149px;height:33.4px; background-color: #10c6d5; color: white;"
                                            (click)="signTheContractModal()">Sign Contract</button>
                                        </td>
                                        <td style="width: 94.875px;">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </ng-container>

                                <ng-container
                                  *ngIf="this.signerConfirmation_A == 'false' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() != true">

                                  <p class="card-text fs-14 lh-2 text-heading mb-5"
                                    style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif; text-align: center;">
                                    Please wait for {{this.getNameOtherUser()}} to sign the contract.
                                  </p>
                                </ng-container>

                                <ng-container
                                  *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'false'  && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() == true">

                                  <p class="card-text fs-14 lh-2 text-heading mb-5"
                                    style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif; text-align: center;">
                                    Please wait for {{this.getNameOtherUser()}} to sign the contract.
                                  </p>
                                </ng-container>
                                <ng-container
                                  *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'false' && this.IsContractConfirmed == 'true' && this.ismyuserlandlord() != true">

                                  <table style="border-collapse: collapse; width: 100%; height: 90px;" border="0">
                                    <tbody>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 31px;" rowspan="0"> <svg xmlns="http://www.w3.org/2000/svg"
                                            width="22.5" height="72" viewBox="0 0 22.5 72">
                                            <path id="exclamation-solid"
                                              d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                              transform="translate(-16)" fill="#ff993c" />
                                          </svg></td>
                                        <td style="width: 498px;" colspan="0"><span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>THE CONTRACT WILL BE GENERATED</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 498px;" colspan="2"> <span
                                            style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 19;">
                                            <strong>BASED ON THE EARLYER STEPS.</strong>
                                          </span></td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 529px;" colspan="3">
                                          <div>
                                            <div>
                                              <p class="fs-13">In case of any doubt, read the <a
                                                  (click)="showtermsofuse()"
                                                  style="color: navy;text-decoration: underline;"><strong>terms of
                                                    use</strong></a> and or get in touch with us!</p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        [ngStyle]="{'background-color': this.listUIConfiguration[4].accordionheadercolor}">
                                        <td style="width: 403.125px;text-align: center;">
                                          <button type="button" class="btn mb-2 ml-2"
                                            style="width: 149px;height:33.4px; background-color: #10c6d5; color: white;"
                                            (click)="signTheContractModal()">Sign Contract</button>
                                        </td>
                                        <td style="width: 94.875px;">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </ng-container>

                                <ng-container
                                  *ngIf="this.signerConfirmation_A == 'true' && this.signerConfirmation_B == 'true' && this.IsContractConfirmed == 'true'">

                                  <div>
                                    <div class="card text-center pt-4" style="background-color: #80808000">
                                      <img src="../../assets/images/pdf-icon.png"
                                        class="card-img card-img w-78px mx-auto" alt="Signed Contract Document">
                                      <div class="card-body p-0 mt-4">
                                        <p class="fs-13 lh-2  mb-0 py-0 px-2">Signed Contract - PDF Document</p>
                                        <a (click)="showContract()"
                                          class="btn btn-block bg-gray-01 border-0 fs-14 text-heading">Download<i
                                            class="far fa-arrow-alt-circle-down ml-1 text-primary"></i></a>
                                      </div>
                                    </div>
                                  </div>
                                  <ng-container
                                    *ngIf="this.listUIConfiguration[4].showproceedbuttonUser && !this.ismyuserlandlord()">
                                    <div style="display: flex; justify-content: center;">
                                      <div *ngIf="!this.userA.user_proceed"
                                        style="display: flex; flex-direction: column; align-items: center;">
                                        <span style="font-size: 16px; color: black;">Please wait for the landlord to
                                          verify the
                                          contract, for both to proceed to payment.</span>
                                        <div class="lds-ellipsis">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </div>
                                      <a *ngIf="this.userA.user_proceed" class="badge badge-primary"
                                        style="background-color: #10c6d5; color: white;"
                                        (click)="sendActionPROCEED('true')">Proceed</a>
                                    </div>
                                  </ng-container>
                                  <div
                                    *ngIf="this.listUIConfiguration[4].showproceedbuttonUser && this.ismyuserlandlord()"
                                    style="width: 100%; display: flex;
                                   justify-content: center; gap: 20px; margin: 20px 0; ">
                                    <a class="badge badge-primary" style="background-color: #10c6d5; color: white;"
                                      (click)="sendActionPROCEED('true')">AGREE</a>
                                  </div>

                                </ng-container>

                              </div>
                            </div>
                          </div>

                        </ng-container>

                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion 05 Payment -->
                <ng-container *ngIf="this.listUIConfiguration[5].isvisible">
                  <div class="aspect-tab  " style="width: 98%;"
                    [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                    <input id="item-116" type="checkbox" class="aspect-input" name="aspect"
                      [disabled]="this.listUIConfiguration[5].isaccordiondisabled"
                      [checked]="this.listUIConfiguration[5].isaccordionopen">
                    <label for="item-116" class="aspect-label"></label>
                    <div class="aspect-content">
                      <div class="aspect-info">
                        <ng-container *ngIf="this.listUIConfiguration[5].isStepclosed">

                          <div class="chart-pie negative over50">

                            <span class="chart-pie-count">
                              <fa-icon class="check-step" [icon]="faCheck"></fa-icon>
                            </span>
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(249deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!this.listUIConfiguration[5].isStepclosed">
                          <div class="chart-pie positive">
                            <!-- <span class="chart-pie-count">{{this.listUIConfiguration[5].stepnumber}}</span> -->
                            <div>
                              <div class="first-fill"></div>
                              <div class="second-fill" style="transform: rotate(119deg)"></div>
                            </div>
                          </div>
                        </ng-container>
                        <span class="aspect-name">{{this.listUIConfiguration[5].stepname}}</span>

                      </div>
                      <div class="aspect-stat">


                      </div>
                    </div>
                    <div class="aspect-tab-content">
                      <div class="sentiment-wrapper"
                        [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">

                        <div class="container" style="padding: 0px 0px 0px!important"
                          [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">

                          <div class="card text-center"
                            style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none">

                            <table style="border-collapse: collapse; width: 100%;" border="0">
                              <tbody>
                                <tr *ngIf="this.eventinfo.eventname !== 'Maintenance'" style="line-height: 16px;"
                                  [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                  <td style="width: 17px; ">&nbsp;</td>
                                  <td style="width: 27px;  text-align: right;" rowspan="2"><svg
                                      xmlns="http://www.w3.org/2000/svg" width="22.5" height="72" viewBox="0 0 22.5 72">
                                      <path id="exclamation-solid"
                                        d="M38.5,60.75A11.25,11.25,0,1,1,27.25,49.5,11.263,11.263,0,0,1,38.5,60.75ZM17.3,3.544l1.912,38.25A3.375,3.375,0,0,0,22.585,45h9.329a3.375,3.375,0,0,0,3.371-3.206L37.2,3.544A3.375,3.375,0,0,0,33.827,0H20.673A3.375,3.375,0,0,0,17.3,3.544Z"
                                        transform="translate(-16)" fill="#ff993c" />
                                    </svg></td>
                                  <td style="width: 172.562px; vertical-align: middle;"><span
                                      style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16;"><strong
                                        style="color:#27449b;">THE
                                        CONTRACT WILL ONLY BE VALID ONCE
                                        THE PAYMENT IS DONE BY THE TENANT

                                      </strong></span></td>
                                </tr>
                                <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                                  <tr style="height: 21px;">
                                    <td style="width: 17px; height: 21px;">&nbsp;</td>
                                    <td style="width: 172.562px; height: 21px;" colspan="2"><span
                                        style="color: #27449b; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16;"><strong
                                          style="color:#27449b;">Provided Service details
                                        </strong></span></td>
                                  </tr>
                                </ng-container>
                              </tbody>

                            </table>

                          </div>

                          <div class="row" style="margin-right: 0px; margin-left: 0px;padding: 0px 0px 0px!important">
                            <div class="col-sm-6" style="padding: 0px 0px 0px!important">
                              <div class="card" style="box-shadow:none;border: 0px"
                                [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">


                                <table style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                                  <tbody>
                                    <tr style="height: 21.5px;"
                                      [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                      <td style="width: 96.625px; height: 42.5px;" colspan="2" rowspan="2">
                                        <div>

                                          <div class="card"
                                            [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                            <div class="card-body px-6 py-4">
                                              <h4 class="card-title fs-16 lh-2 text-dark mb-3">
                                                <strong>Items</strong>
                                              </h4>

                                              <ul class="list-group list-group-flush mt-3"
                                                *ngFor="let chargeableitem of listfinalitemstocharge">

                                                <li class="list-group-item px-0"
                                                  [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                                                  <div class="d-flex">
                                                    <span>
                                                      <ng-container *ngIf="chargeableitem.istotal == false">
                                                        {{chargeableitem.name}}
                                                      </ng-container>
                                                      <ng-container *ngIf="chargeableitem.istotal == true">
                                                        <strong>{{chargeableitem.name}}</strong>
                                                      </ng-container>
                                                    </span>
                                                    <span class="d-block ml-auto font-weight-500 text-heading">

                                                      <ng-container *ngIf="chargeableitem.istotal == false">
                                                        ${{chargeableitem.value}}
                                                      </ng-container>
                                                      <ng-container *ngIf="chargeableitem.istotal == true">
                                                        <strong>${{chargeableitem.value}}</strong>
                                                      </ng-container>

                                                    </span>
                                                  </div>

                                                </li>

                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                              </div>
                            </div>
                            <div class="col-sm-6" style="padding: 0px 0px 0px!important"
                              [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">
                              <div class="card" style="border: 0px;;box-shadow:none">
                                <table style="border-collapse: collapse; width: 100%; height: 161px;" border="0">
                                  <tbody>
                                    <tr style="height: 21.5px;"
                                      [ngStyle]="{'background-color': this.listUIConfiguration[5].accordionheadercolor}">

                                      <td style="text-align:center;vertical-align:middle;">
                                        <div class="parent">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="125.812" height="98.499"
                                            viewBox="0 0 404.32 359.394">
                                            <path id="house-user-solid"
                                              d="M400.592,165.847l-41.2-36.382V33.693a11.231,11.231,0,0,0-11.231-11.231H303.24a11.231,11.231,0,0,0-11.231,11.231V69.962L220.958,7.23C216.55,3.236,208.147,0,202.16,0S187.8,3.236,183.39,7.23L3.692,165.869A12.824,12.824,0,0,0,0,174.222a13.084,13.084,0,0,0,2.871,7.518l15.029,16.7a14.839,14.839,0,0,0,8.423,3.72,15.211,15.211,0,0,0,7.5-2.885l11.161-9.827V336.931A22.462,22.462,0,0,0,67.45,359.394H336.933A22.462,22.462,0,0,0,359.4,336.931V189.44l11.168,9.827a15.4,15.4,0,0,0,7.525,2.892,14.663,14.663,0,0,0,8.332-3.727l15.029-16.713a15.19,15.19,0,0,0,2.871-7.5A14.741,14.741,0,0,0,400.592,165.847Z"
                                              fill="#0ec6d5" />
                                          </svg>

                                          <div class="over-img">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="30.385" height="120.171"
                                              viewBox="0 0 115.385 205.171">
                                              <path id="dollar-sign-solid"
                                                d="M83.854,93.529,40.575,80.866A11.819,11.819,0,0,1,43.9,57.7H70.47a24.46,24.46,0,0,1,13.7,4.208,6.247,6.247,0,0,0,7.814-.8l13.945-13.625a6.5,6.5,0,0,0-.721-9.818A56.664,56.664,0,0,0,70.55,25.646V6.412A6.43,6.43,0,0,0,64.138,0H51.315A6.43,6.43,0,0,0,44.9,6.412V25.646h-1A43.906,43.906,0,0,0,.222,73.573c1.683,18.473,15.789,33.5,33.581,38.71l41.075,12.022a11.819,11.819,0,0,1-3.326,23.162H44.983a24.46,24.46,0,0,1-13.7-4.208,6.247,6.247,0,0,0-7.814.8L9.519,157.685a6.5,6.5,0,0,0,.721,9.818A56.664,56.664,0,0,0,44.9,179.525V198.76a6.43,6.43,0,0,0,6.412,6.412H64.138a6.43,6.43,0,0,0,6.412-6.412V179.445c18.674-.361,36.186-11.461,42.357-29.133A44,44,0,0,0,83.854,93.529Z"
                                                transform="translate(-0.036)" fill="#fff" />
                                            </svg>

                                          </div>

                                        </div>
                                        <br>
                                        <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                                          <p class="fs-13"><strong style="color: #0ec6d5;font-size: 13;">Monthly
                                              payment</strong></p>
                                          <p class="fs-13"><strong style="color: navy;">${{this.totalmonthly}}</strong>
                                          </p>
                                        </ng-container>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>


                              </div>
                            </div>
                          </div>

                          <div *ngIf="this.eventinfo.eventname == 'Maintenance'" class="card text-center"
                            style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none;align-items: center;">
                            <div style="font-size: 16px; text-align: center;"
                              *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == false">
                              Waiting payment from <strong style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                            </div>
                            <ng-container
                              *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == true && this.getvalueofmyuser('user_proceed') == false">
                              <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;" type="button"
                                (click)="openRatingModal()">
                                Approve payment
                              </button>
                            </ng-container>
                            <ng-container
                              *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == false && this.PaymentModal == true">
                              <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;" type="button"
                                (click)="paymentMaintenanceModal()">
                                Go to Payment
                              </button>
                            </ng-container>
                            <div style="font-size: 16px; text-align: center;"
                              *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == true && this.getUserProceedOtherUser() == false">
                              Waiting payment approval from <strong
                                style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                            </div>
                          </div>
                          <div class="card text-center" *ngIf="this.eventinfo.eventname !== 'Maintenance'"
                            style="padding: 0px 0px 0px!important;border: 0px;box-shadow:none;align-items: center;">

                            <ng-container
                              *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == false">
                              Waiting payment from <strong style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                            </ng-container>
                            <ng-container
                              *ngIf="this.ismyuserlandlord() == true && this.getUserProceedOtherUser() == true && this.getvalueofmyuser('user_proceed') == false">
                              <button class="btn btn-primary" style="min-width: 170px; height: 33.4px;max-width: 155px;"
                                type="button" (click)="sendActionPROCEED('true')">
                                Approve payment
                              </button>
                            </ng-container>
                            <ng-container
                              *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == false">
                              <button class="btn btn-primary"
                                style="min-width: 170px; height: 33.4px; max-width: 155px;" type="button"
                                (click)="paymentModal()">
                                Go to Payment
                              </button>
                            </ng-container>
                            <ng-container
                              *ngIf="this.ismyuserlandlord() == false && this.getvalueofmyuser('user_proceed') == true && this.getUserProceedOtherUser() == false">
                              Waiting payment approval from <strong
                                style="color: #FF993C;">{{this.getNameOtherUser()}}</strong>
                            </ng-container>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- accordion end-->

                <div style="text-align: center;">
                  <ng-container *ngIf="this.allstepsClosed">
                    <div class="image-stackEndStep">
                      <div class="image-stack__item image-stack__item—topEndStep">
                        <svg xmlns="http://www.w3.org/2000/svg" width="68.719" height="54.97"
                          viewBox="0 0 68.719 54.97">
                          <path id="hands-helping-solid"
                            d="M52.377,20.635H36.057v6.013a7.731,7.731,0,0,1-15.462,0V13.591l-6.968,4.188a6.857,6.857,0,0,0-3.339,5.884v5.079L1.7,33.7A3.428,3.428,0,0,0,.441,38.394l8.59,14.882a3.437,3.437,0,0,0,4.692,1.256l11.1-6.41H39.493a6.878,6.878,0,0,0,6.872-6.872h1.718a3.432,3.432,0,0,0,3.436-3.436V30.943h.859a2.571,2.571,0,0,0,2.577-2.577V23.212A2.571,2.571,0,0,0,52.377,20.635Zm15.859-4.016L59.647,1.737A3.437,3.437,0,0,0,54.954.481l-11.1,6.41H32.879a6.923,6.923,0,0,0-3.64,1.042l-3.6,2.244a3.413,3.413,0,0,0-1.611,2.91V26.648a4.295,4.295,0,0,0,8.59,0V17.2H52.377a6.017,6.017,0,0,1,6.013,6.013v3.06l8.59-4.961A3.44,3.44,0,0,0,68.236,16.619Z"
                            transform="translate(0.023 -0.022)" fill="#fff" />
                        </svg>

                      </div>
                      <div class="image-stack__item image-stack__item—bottomEndStep">

                        <svg xmlns="http://www.w3.org/2000/svg" width="125.812" height="98.499"
                          viewBox="0 0 404.32 359.394">
                          <path id="house-user-solid"
                            d="M400.592,165.847l-41.2-36.382V33.693a11.231,11.231,0,0,0-11.231-11.231H303.24a11.231,11.231,0,0,0-11.231,11.231V69.962L220.958,7.23C216.55,3.236,208.147,0,202.16,0S187.8,3.236,183.39,7.23L3.692,165.869A12.824,12.824,0,0,0,0,174.222a13.084,13.084,0,0,0,2.871,7.518l15.029,16.7a14.839,14.839,0,0,0,8.423,3.72,15.211,15.211,0,0,0,7.5-2.885l11.161-9.827V336.931A22.462,22.462,0,0,0,67.45,359.394H336.933A22.462,22.462,0,0,0,359.4,336.931V189.44l11.168,9.827a15.4,15.4,0,0,0,7.525,2.892,14.663,14.663,0,0,0,8.332-3.727l15.029-16.713a15.19,15.19,0,0,0,2.871-7.5A14.741,14.741,0,0,0,400.592,165.847Z"
                            fill="#0ec6d5" />
                        </svg>

                      </div>
                    </div>

                    <ng-container *ngIf="this.eventinfo.eventname != 'Maintenance'">
                      <strong>Property Leased to </strong>
                    </ng-container>
                    <ng-container *ngIf="this.eventinfo.eventname == 'Maintenance'">
                      <strong>Service provided to </strong>
                    </ng-container>

                    <ng-container *ngIf="this.ismyuserlandlord() == true">
                      <strong>{{this.getNameOtherUser()}}</strong>
                    </ng-container>
                    <ng-container *ngIf="this.ismyuserlandlord() == false">
                      {{this.getvalueofmyuser('username')}}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>



          </div>
          <div class="tab-pane fade" id="html-177" style="width:88vw" role="tabpanel" aria-labelledby="html-177">

            <div id="eventsbox2" style="height: 550px;position:relative;">
              <div class="chat-wrapper pt-3" style="position: inherit; width: 86vw;">
                <div class="chat-inner">

                  <div class="chat-body is-opened" style="width: calc(100% - 0px)!important;">

                    <div class="chat-body-inner is-multiple has-slimscroll" id="scrollMeChat" #scrollMeChat
                      [scrollTop]="scrollMeChat.scrollHeight">
                      <div class="true-dom">
                        <div class="date-divider">
                          <hr class="date-divider-line">
                          <div class="hr-sect"><span class="date-divider-text"
                              style="color: #7fa4b3;font-size: 11px;box-sizing: content-box;padding: 5px 5px;top: 0%;">Presentation</span>
                          </div>
                          <br>

                        </div>


                        <div *ngFor="let messageitem of ChatMessages">

                          <ng-container *ngIf="messageitem.isstepnotification == true">
                            <div class="date-divider">
                              <hr class="date-divider-line">
                              <div class="hr-sect"><span class="date-divider-text"
                                  style="color: #7fa4b3;font-size: 11px;box-sizing: content-box;padding: 5px 5px;top: 0%;">{{messageitem.message}}</span>
                              </div>

                              <br>

                            </div>
                          </ng-container>

                          <!-- notification with icon-->
                          <ng-container
                            *ngIf="messageitem.isnotification == true && messageitem.isstepnotification == false">
                            <div class="date-divider">
                              <hr class="date-divider-line">
                              <ng-container *ngIf="messageitem.notificationicon == 'usersentproceed'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.726" height="16.388"
                                    viewBox="0 0 18.726 21.388">
                                    <path id="user-solid"
                                      d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                      transform="translate(0.021)" fill="#294599" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.726" height="16.388"
                                    viewBox="0 0 18.726 21.388">
                                    <path id="user-solid"
                                      d="M9.347,10.694A5.347,5.347,0,1,0,4,5.347,5.347,5.347,0,0,0,9.347,10.694Zm3.743,1.337h-.7a7.272,7.272,0,0,1-6.091,0H5.6A5.616,5.616,0,0,0-.01,17.645v1.738c0,1.107-.175,2.005.932,2.005H17.666c1.107,0,1.038-.9,1.038-2.005V17.645A5.616,5.616,0,0,0,13.09,12.031Z"
                                      transform="translate(0.021)" fill="#FF993C" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'pricechanged'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20.971" height="18.196"
                                    viewBox="0 0 24.971 22.196">
                                    <path id="hand-holding-usd-solid"
                                      d="M11.751,6.256l2.353.62a.372.372,0,0,1,.287.351.382.382,0,0,1-.4.364H12.452a1.3,1.3,0,0,1-.485-.1.621.621,0,0,0-.663.087l-.824.759a.506.506,0,0,0-.1.115.5.5,0,0,0,.168.682,3.632,3.632,0,0,0,1.5.5V10.4a.728.728,0,0,0,.753.694h.753a.728.728,0,0,0,.753-.694V9.641A2.489,2.489,0,0,0,16.626,6.91a2.629,2.629,0,0,0-2.018-2.068l-2.353-.62a.372.372,0,0,1-.287-.351.382.382,0,0,1,.4-.364h1.543a1.3,1.3,0,0,1,.487.1.621.621,0,0,0,.663-.087l.824-.759a.49.49,0,0,0,.094-.113.5.5,0,0,0-.166-.684,3.634,3.634,0,0,0-1.5-.5V.694A.727.727,0,0,0,13.558,0H12.8a.727.727,0,0,0-.753.694v.763a2.491,2.491,0,0,0-2.32,2.731A2.631,2.631,0,0,0,11.751,6.256Zm12.754,7.968a1.435,1.435,0,0,0-1.847,0l-4.006,3.2a2.759,2.759,0,0,1-1.734.607H11.791a.694.694,0,1,1,0-1.387h3.394a1.439,1.439,0,0,0,1.441-1.153,1.353,1.353,0,0,0,.02-.237,1.387,1.387,0,0,0-1.387-1.385H8.323a5.1,5.1,0,0,0-3.212,1.14L3.1,16.647H.694A.694.694,0,0,0,0,17.34V21.5a.694.694,0,0,0,.694.694H16.16a2.774,2.774,0,0,0,1.734-.607l6.556-5.245a1.387,1.387,0,0,0,.055-2.12Z"
                                      fill="#294599" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20.971" height="18.196"
                                    viewBox="0 0 24.971 22.196">
                                    <path id="hand-holding-usd-solid"
                                      d="M11.751,6.256l2.353.62a.372.372,0,0,1,.287.351.382.382,0,0,1-.4.364H12.452a1.3,1.3,0,0,1-.485-.1.621.621,0,0,0-.663.087l-.824.759a.506.506,0,0,0-.1.115.5.5,0,0,0,.168.682,3.632,3.632,0,0,0,1.5.5V10.4a.728.728,0,0,0,.753.694h.753a.728.728,0,0,0,.753-.694V9.641A2.489,2.489,0,0,0,16.626,6.91a2.629,2.629,0,0,0-2.018-2.068l-2.353-.62a.372.372,0,0,1-.287-.351.382.382,0,0,1,.4-.364h1.543a1.3,1.3,0,0,1,.487.1.621.621,0,0,0,.663-.087l.824-.759a.49.49,0,0,0,.094-.113.5.5,0,0,0-.166-.684,3.634,3.634,0,0,0-1.5-.5V.694A.727.727,0,0,0,13.558,0H12.8a.727.727,0,0,0-.753.694v.763a2.491,2.491,0,0,0-2.32,2.731A2.631,2.631,0,0,0,11.751,6.256Zm12.754,7.968a1.435,1.435,0,0,0-1.847,0l-4.006,3.2a2.759,2.759,0,0,1-1.734.607H11.791a.694.694,0,1,1,0-1.387h3.394a1.439,1.439,0,0,0,1.441-1.153,1.353,1.353,0,0,0,.02-.237,1.387,1.387,0,0,0-1.387-1.385H8.323a5.1,5.1,0,0,0-3.212,1.14L3.1,16.647H.694A.694.694,0,0,0,0,17.34V21.5a.694.694,0,0,0,.694.694H16.16a2.774,2.774,0,0,0,1.734-.607l6.556-5.245a1.387,1.387,0,0,0,.055-2.12Z"
                                      fill="#FF993C" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'genericservice'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                                    class="svg-inline--fa fa-home fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" width="20.971" height="18.196">
                                    <path fill="currentColor"
                                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                                    class="svg-inline--fa fa-home fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512" width="20.971" height="18.196">
                                    <path fill="currentColor"
                                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'paw'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paw"
                                    class="svg-inline--fa fa-paw fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512" width="20.971" height="18.196">
                                    <path fill="currentColor"
                                      d="M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paw"
                                    class="svg-inline--fa fa-paw fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512" width="20.971" height="18.196">
                                    <path fill="currentColor"
                                      d="M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'tshirt'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tshirt"
                                    class="svg-inline--fa fa-tshirt fa-w-20" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M631.2 96.5L436.5 0C416.4 27.8 371.9 47.2 320 47.2S223.6 27.8 203.5 0L8.8 96.5c-7.9 4-11.1 13.6-7.2 21.5l57.2 114.5c4 7.9 13.6 11.1 21.5 7.2l56.6-27.7c10.6-5.2 23 2.5 23 14.4V480c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V226.3c0-11.8 12.4-19.6 23-14.4l56.6 27.7c7.9 4 17.5.8 21.5-7.2L638.3 118c4-7.9.8-17.6-7.1-21.5z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tshirt"
                                    class="svg-inline--fa fa-tshirt fa-w-20" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M631.2 96.5L436.5 0C416.4 27.8 371.9 47.2 320 47.2S223.6 27.8 203.5 0L8.8 96.5c-7.9 4-11.1 13.6-7.2 21.5l57.2 114.5c4 7.9 13.6 11.1 21.5 7.2l56.6-27.7c10.6-5.2 23 2.5 23 14.4V480c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V226.3c0-11.8 12.4-19.6 23-14.4l56.6 27.7c7.9 4 17.5.8 21.5-7.2L638.3 118c4-7.9.8-17.6-7.1-21.5z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'warehouse'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="warehouse"
                                    class="svg-inline--fa fa-warehouse fa-w-20" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                                      style="fill: #294599;" />
                                  </svg>
                                </ng-container>

                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="warehouse"
                                    class="svg-inline--fa fa-warehouse fa-w-20" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20.971"
                                    height="18.196">
                                    <path fill="currentColor"
                                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                                      style="fill: #FF993C;" />
                                  </svg>
                                </ng-container>

                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'broom'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 87.49998701560162, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z"
                                        style="fill: #294599;" />
                                    </g>
                                    <path d="M 1107.7864990234375 853.637451171875" style="fill: #294599; " />
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 87.49998701560162, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z"
                                        style="fill: #FF993C;" />
                                    </g>
                                    <path d="M 1107.7864990234375 853.637451171875" style="fill: #FF993C; " />
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'chef'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 209.99992598044446, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 192 64 192h60.09L112 169.25a8 8 0 0 1 7.33-8.61l16-1.28a8 8 0 0 1 8.61 7.34L156.2 320h83.14V168a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v152h84.46l12.27-153.3a8 8 0 0 1 8.61-7.34l16 1.28a8 8 0 0 1 7.33 8.61L387.91 320H448s64-150.26 64-192a96 96 0 0 0-96-96zM64 480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352H64z"
                                        class="" style="fill: #294599;" />
                                    </g>
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 209.99992598044446, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 192 64 192h60.09L112 169.25a8 8 0 0 1 7.33-8.61l16-1.28a8 8 0 0 1 8.61 7.34L156.2 320h83.14V168a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v152h84.46l12.27-153.3a8 8 0 0 1 8.61-7.34l16 1.28a8 8 0 0 1 7.33 8.61L387.91 320H448s64-150.26 64-192a96 96 0 0 0-96-96zM64 480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352H64z"
                                        class="" style="fill: #FF993C;" />
                                    </g>
                                  </svg>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="messageitem.notificationicon == 'washer'">

                                <ng-container *ngIf="messageitem.userrgbcolor == '#294599'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 273.1640495156016, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm36.87-163.66a51.23 51.23 0 0 1-73.74 0 51.79 51.79 0 0 1-73.24 1A110.76 110.76 0 0 0 112 304a112 112 0 0 0 224 0 110.76 110.76 0 0 0-1.89-18.69 51.79 51.79 0 0 1-73.24-1z"
                                        class="" style="fill: #294599;" />
                                    </g>
                                  </svg>
                                </ng-container>
                                <ng-container *ngIf="messageitem.userrgbcolor == '#FF993C'">
                                  <svg id="master-artboard" viewBox="0 0 1400 980" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    style="enable-background:new 0 0 1400 980;" width="20.971" height="18.196">
                                    <g
                                      transform="matrix(1.9140625, 0, 0, 1.9140625, 273.1640495156016, 0.0000043281329453748185)">
                                      <path fill="currentColor"
                                        d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm36.87-163.66a51.23 51.23 0 0 1-73.74 0 51.79 51.79 0 0 1-73.24 1A110.76 110.76 0 0 0 112 304a112 112 0 0 0 224 0 110.76 110.76 0 0 0-1.89-18.69 51.79 51.79 0 0 1-73.24-1z"
                                        class="" style="fill: #FF993C;" />
                                    </g>
                                  </svg>
                                </ng-container>


                              </ng-container>

                              <span
                                style="color:#707070 ; font-size: 12;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;text-align: center;">{{messageitem.message}}</span>
                            </div>
                          </ng-container>

                          <!-- regular message-->
                          <ng-container
                            *ngIf="messageitem.istodownload == false && messageitem.isimage == false && messageitem.isnotification == false && messageitem.isstepnotification == false">
                            <div class="{{messageitem.style}}">
                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>
                              <div class="message-block"><span>{{messageitem.data}}</span>

                                <ng-container *ngIf="messageitem.innerHTML">
                                  <div class="message-text" [innerHTML]="messageitem.message">
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="!messageitem.innerHTML">
                                  <div class="message-text" [innerText]="messageitem.message">
                                  </div>
                                </ng-container>



                                <ng-container
                                  *ngIf="messageitem.style != 'chat-message is-sent' && messageitem.style != 'chat-message is-sent has-reactions'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="messageitem.emoji!=null && messageitem.emoji!=''">
                                  <div class="reaction-counts">
                                    <div class="reaction-count">
                                      <span>{{messageitem.emoji}}</span><span>1</span>
                                    </div>
                                  </div>
                                </ng-container>

                              </div>
                            </div>
                          </ng-container>

                          <!-- file to donwload -->
                          <ng-container
                            *ngIf="messageitem.istodownload == true && messageitem.isnotification == false && messageitem.isstepnotification == false">
                            <div class="{{messageitem.style}}">

                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>
                              <div class="message-block"><span>{{messageitem.data}}</span>
                                <div class="message-text">
                                  <div class="uploaded-file">
                                    <div class="icon-container">
                                      <img src="assets/images/pdf-icon.png" class="mx-auto"
                                        alt="Villa Called Archangel PDF Document">
                                    </div>
                                    <div class="meta">
                                      <span><a href="{{messageitem.fileurl}}"
                                          target="_blank">{{messageitem.filename}}</a></span><span>{{this.bytesToSize(messageitem.filesize)}}</span>
                                    </div>
                                    <div class="action"><a href="{{messageitem.fileurl}}" target="_blank">
                                        <i class="far fa-arrow-alt-circle-down ml-1 text-primary"></i>
                                      </a></div>
                                  </div>

                                  {{messageitem.message}}

                                </div>
                                <ng-container *ngIf="messageitem.style != 'chat-message is-sent'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>

                              </div>
                            </div>
                          </ng-container>

                          <!-- image to show -->
                          <ng-container
                            *ngIf="messageitem.isimage == true && messageitem.isnotification == false && messageitem.isstepnotification == false">

                            <div class="{{messageitem.style}}">

                              <div class="nav-start">
                                <div class="recipient-block">
                                  <div class="avatar-container">
                                    <ng-container *ngIf="messageitem.islandlord">
                                      <img class="userlandlord-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!messageitem.islandlord">
                                      <img class="usertentant-avatar" loading="lazy" style="border-radius: 50px;"
                                        src="{{messageitem.userimageurl}}" alt="">
                                    </ng-container>
                                  </div>

                                </div>
                              </div>

                              <div class="message-block"><span>{{messageitem.data }}</span>
                                <div class="message-text"><a lg-uid="lg0"><img class="uploaded-image"
                                      src="{{messageitem.fileurl}}" href="#"
                                      (click)="showpopupimage(messageitem.fileurl)"></a>
                                  {{messageitem.message}}</div>
                                <ng-container *ngIf="messageitem.style != 'chat-message is-sent'">
                                  <div class="reactions-toolbar"><button class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👍',messageitem.idmessage, messageitem.data)"><span>👍</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('👊',messageitem.idmessage, messageitem.data)"><span>👊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😊',messageitem.idmessage, messageitem.data)"><span>😊</span></button><button
                                      class="reaction"
                                      (click)="insertemojiToCommentReceivedMessage('😀',messageitem.idmessage, messageitem.data)"><span>😀</span></button>
                                  </div>
                                </ng-container>
                                <!---->
                              </div>
                            </div>
                          </ng-container>

                        </div>

                      </div>
                    </div>


                    <div class="chat-action" style="width: calc(100%-0px)!important;">
                      <form class="chat-action-inner">
                        <div class="typing-indicator-wrap indicator-mobile">
                          <ng-container *ngIf="!this.userA.user_proceed && this.showdeclinedstatusbuttonUserA == false">
                            <button class="btn btn-primary"
                              style="width: 120px; height: 33.4px; background-color: #707070;" type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.userA.user_proceed && this.showdeclinedstatusbuttonUserA == false">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1"><svg xmlns="http://www.w3.org/2000/svg"
                                  width="15.899" height="33.421" viewBox="0 0 25.899 33.421">
                                  <path id="Path_72" data-name="Path 72"
                                    d="M2076.527,220.5s10.235,8.43,9.157,13.278,7-33.935,15.082-29.626"
                                    transform="translate(-2075.573 -202.281)" fill="none" stroke="#fff"
                                    stroke-width="3" />
                                </svg>
                              </span>
                              <span>{{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}</span>
                            </button>
                          </ng-container>


                          <ng-container *ngIf="this.showdeclinedstatusbuttonUserA">
                            <button class="btn" style="width: 120px;height:33.4px; background-color: #ff6935;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.69" height="20.69"
                                  viewBox="0 0 35.69 35.69">
                                  <path id="ban-solid"
                                    d="M25.845,8A17.845,17.845,0,1,0,43.69,25.845,17.845,17.845,0,0,0,25.845,8Zm9.362,8.483A13.242,13.242,0,0,1,36.7,33.439L18.252,15A13.241,13.241,0,0,1,35.207,16.483ZM16.483,35.207A13.242,13.242,0,0,1,15,18.252L33.439,36.7A13.241,13.241,0,0,1,16.483,35.207Z"
                                    transform="translate(-8 -8)" fill="#fff" />
                                </svg>

                              </span>
                              <span>{{this.userA.username.length > 7 ? this.userA.username.substring(0, 7) + '...' : this.userA.username}}</span>
                            </button>
                          </ng-container>

                          &nbsp;&nbsp;
                          <ng-container *ngIf="!this.userB.user_proceed && this.showdeclinedstatusbuttonUserB == false">
                            <button class="btn btn-primary"
                              style="width: 120px; height: 33.4px; background-color: #707070;" type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.userB.user_proceed && this.showdeclinedstatusbuttonUserB == false">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1"><svg xmlns="http://www.w3.org/2000/svg"
                                  width="15.899" height="33.421" viewBox="0 0 25.899 33.421">
                                  <path id="Path_72" data-name="Path 72"
                                    d="M2076.527,220.5s10.235,8.43,9.157,13.278,7-33.935,15.082-29.626"
                                    transform="translate(-2075.573 -202.281)" fill="none" stroke="#fff"
                                    stroke-width="3" />
                                </svg>
                              </span>
                              <span>{{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}</span>
                            </button>
                          </ng-container>

                          <ng-container *ngIf="this.showdeclinedstatusbuttonUserB">
                            <button class="btn btn-primary" style="width: 120px; background-color: #10c6d5;"
                              type="button" disabled>
                              <span class="d-inline-block ml-1 fs-20 lh-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35.69" height="35.69"
                                  viewBox="0 0 35.69 35.69">
                                  <path id="ban-solid"
                                    d="M25.845,8A17.845,17.845,0,1,0,43.69,25.845,17.845,17.845,0,0,0,25.845,8Zm9.362,8.483A13.242,13.242,0,0,1,36.7,33.439L18.252,15A13.241,13.241,0,0,1,35.207,16.483ZM16.483,35.207A13.242,13.242,0,0,1,15,18.252L33.439,36.7A13.241,13.241,0,0,1,16.483,35.207Z"
                                    transform="translate(-8 -8)" fill="#fff" />
                                </svg>

                              </span>
                              <span>{{this.userB.username.length > 7 ? this.userB.username.substring(0, 7) + '...' : this.userB.username}}</span>
                            </button>
                          </ng-container>



                        </div>
                        <div class="control">

                          <textarea placeholder="Type your message" class="textarea comment-textarea" rows="1"
                            id="inputmessage" name="inputmessage" [(ngModel)]="inputmessage"
                            (keyup.enter)="sendMessage($event)" (keypress)="keyPress($event)">
                              </textarea>

                          <div class="compose-dropdown">
                            <div><input type="file"
                                accept="image/*, application/pdf, application/zip, application/tar, application/gzip, .rar"
                                id="inputchatattachement" (change)="onAttachmentSelect($event)"><button>
                                <div class="add-button">
                                  <div class="button-inner"><svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                      height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                      class="feather feather-paperclip">
                                      <path
                                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                                      </path>
                                    </svg></div>
                                </div>
                              </button></div>
                          </div>
                          <div class="toolbar"><button type="button" class="button-emoji" [ngbPopover]="popContent"><svg
                                xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-smile">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                              </svg></button><button class="button is-primary" (click)="sendMessage($event)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-send">
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg> </button></div>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
</div>
</div>

</div>
<ng-template #popContent class="popover-body">

  <div class="row">
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[0].emoji)"><span
        style="font-size: 1rem;">{{emojilist[0].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[1].emoji)"><span
        style="font-size: 1rem;">{{emojilist[1].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[2].emoji)"><span
        style="font-size: 1rem;">{{emojilist[2].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[3].emoji)"><span
        style="font-size: 1rem;">{{emojilist[3].emoji}}</span></button>
  </div>
  <div class="row">
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[4].emoji)"><span
        style="font-size: 1rem;">{{emojilist[4].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[5].emoji)"><span
        style="font-size: 1rem;">{{emojilist[5].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[6].emoji)"><span
        style="font-size: 1rem;">{{emojilist[6].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[7].emoji)"><span
        style="font-size: 1rem;">{{emojilist[7].emoji}}</span></button>
  </div>
  <div class="row">
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[8].emoji)"><span
        style="font-size: 1rem;">{{emojilist[8].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[9].emoji)"><span
        style="font-size: 1rem;">{{emojilist[9].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[10].emoji)"><span
        style="font-size: 1rem;">{{emojilist[10].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[11].emoji)"><span
        style="font-size: 1rem;">{{emojilist[11].emoji}}</span></button>
  </div>

  <div class="row">
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[12].emoji)"><span
        style="font-size: 1rem;">{{emojilist[12].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[13].emoji)"><span
        style="font-size: 1rem;">{{emojilist[13].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[14].emoji)"><span
        style="font-size: 1rem;">{{emojilist[14].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[15].emoji)"><span
        style="font-size: 1rem;">{{emojilist[15].emoji}}</span></button>
  </div>
  <div class="row">
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[16].emoji)"><span
        style="font-size: 1rem;">{{emojilist[16].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[17].emoji)"><span
        style="font-size: 1rem;">{{emojilist[17].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[18].emoji)"><span
        style="font-size: 1rem;">{{emojilist[18].emoji}}</span></button>
    <button class="reaction" (click)="insertimojiToTextChat(emojilist[19].emoji)"><span
        style="font-size: 1rem;">{{emojilist[19].emoji}}</span></button>
  </div>

</ng-template>

</div>


</section>


</div>
