<div class="collapse navbar-collapse bg-white" id="primaryMenuSidebar">


  <ul class="list-group list-group-flush w-100">
    <ng-container *ngIf="this.isusersysadmin()">
      <li class="list-group-item pt-6 pb-4">
        <h5 class="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Administrator
        </h5>
        <ul class="list-group list-group-no-border rounded-lg">
          <li [ngClass]="{'selected': this.url === '/usersconfiguration'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('usersconfiguration','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-my-properties">
                <use xlink:href="#icon-my-properties"></use>
              </svg>-->
                <i class="ti ti-users"></i>
              </span>
              <span class="sidebar-item-text">Users</span>

            </a>
          </li>
        </ul>
        <!--<ul class="list-group list-group-no-border rounded-lg">
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('addnewproperty','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
            </span>
            <span class="sidebar-item-text">Properties</span>
          </a>
        </li>
      </ul>-->
        <ul class="list-group list-group-no-border rounded-lg">
          <li [ngClass]="{'selected': this.url === '/eventsconfiguration'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('eventsconfiguration','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-my-properties">
                <use xlink:href="#icon-my-properties"></use>
              </svg>-->
                <i class="ti ti-calendar-event"></i>
              </span>
              <span class="sidebar-item-text">Events</span>
            </a>
          </li>
        </ul>
        <!--<ul class="list-group list-group-no-border rounded-lg">
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('addnewproperty','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
            </span>
            <span class="sidebar-item-text">Settings</span>
          </a>
        </li>
      </ul>

      <ul class="list-group list-group-no-border rounded-lg">
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('addnewproperty','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
            </span>
            <span class="sidebar-item-text">Subscriptions</span>
          </a>
        </li>
      </ul>
      <ul class="list-group list-group-no-border rounded-lg">
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('addnewproperty','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
            </span>
            <span class="sidebar-item-text">Templates</span>
          </a>
        </li>
      </ul>
    -->
        <ul class="list-group list-group-no-border rounded-lg">
          <li [ngClass]="{'selected': this.url === '/emailconfiguration'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('emailconfiguration','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-my-properties">
            <use xlink:href="#icon-my-properties"></use>
          </svg>-->
                <i class="ti ti-calendar-event"></i>
              </span>
              <span class="sidebar-item-text">Email</span>
            </a>
          </li>
        </ul>

      </li>
    </ng-container>


    <li class="list-group-item pb-4">
      <h5 class="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage
        Listings</h5>
      <ul *ngIf="!this.isuservendor()" class="list-group list-group-no-border rounded-lg">
        <li [ngClass]="{'selected': this.url === '/myleases'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('myleases','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <!--<svg class="icon icon-my-properties">
                <use xlink:href="#icon-my-properties"></use>
              </svg>-->
              <i class="ti ti-home"></i>
            </span>
            <!-- <span class="sidebar-item-text">{{this.managelistingtitle}}</span> -->
            <span class="sidebar-item-text">My Leases</span>
            <!--<span class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span>-->
          </a>
        </li>
      </ul>
      <ng-container *ngIf="this.isuserlandlord()">
        <ul class="list-group list-group-no-border rounded-lg">
          <li [ngClass]="{'selected': this.url === '/myproperties'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('myproperties','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>-->
                <i class="ti ti-home"></i>

              </span>
              <span class="sidebar-item-text">My Properties</span>
            </a>
          </li>
          <li [ngClass]="{'selected': this.url === '/addnewproperty'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('addnewproperty','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>-->
                <i class="ti ti-plus"></i>

              </span>
              <span class="sidebar-item-text">Add Property</span>
            </a>
          </li>
          <!-- <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('modalsubscriptions','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
                <i class="ti ti-plus"></i>

              </span>
              <span class="sidebar-item-text">Add Property</span>
            </a>
          </li> -->
        </ul>
      </ng-container>
      <ng-container *ngIf="this.isuservendor()">
        <ul class="list-group list-group-no-border rounded-lg">
          <li [ngClass]="{'selected': this.url === '/myservices'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('myservices','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>-->
                <i class="ti ti-home"></i>

              </span>
              <span class="sidebar-item-text">My Services</span>
            </a>
          </li>
          <li [ngClass]="{'selected': this.url === '/addnewservice'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('addnewservice','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <!--<svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>-->
                <i class="ti ti-plus"></i>

              </span>
              <span class="sidebar-item-text">Add service</span>
            </a>
          </li>
          <!-- <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('modalsubscriptions','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <svg class="icon icon-add-new"><use xlink:href="#icon-add-new"></use></svg>
                <i class="ti ti-plus"></i>

              </span>
              <span class="sidebar-item-text">Add Property</span>
            </a>
          </li> -->
        </ul>
      </ng-container>
    </li>

    <li class="list-group-item pt-6 pb-4">
      <h5 class="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">
        Manage Events
      </h5>
      <ul class="list-group list-group-no-border rounded-lg">

        <li [ngClass]="{'selected': this.url === '/events?IDEvent=2'}"
          class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <!--  <a href="/events?IDEvent={{event.IDEvent}}"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer"> -->
          <a href="events?IDEvent=2"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">

            </span>
            <span class="sidebar-item-text">Maintenance</span>
            <span style="opacity: 1 !important;"
              class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">{{this.serviceTotal}}</span>
          </a>
        </li>
        <ng-container *ngIf="!this.isuservendor()">
        <li [ngClass]="{'selected': this.url === '/events?IDEvent=1'}"
          class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <!--  <a href="/events?IDEvent={{event.IDEvent}}"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer"> -->
          <a href="events?IDEvent=1"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <!-- <svg id="SvgjsSvg1001" width="24" height="24" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"><defs id="SvgjsDefs1002"></defs><g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path d="M57,8H52V6a4,4,0,0,0-8,0V8H36V6a4,4,0,0,0-8,0V8H20V6a4,4,0,0,0-8,0V8H7a5,5,0,0,0-5,5V53a5,5,0,0,0,5,5H35a1,1,0,0,0,0-2H7a3.009,3.009,0,0,1-3-3V22H60V39a1,1,0,0,0,2,0V13A5,5,0,0,0,57,8ZM46,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM30,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM14,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM60,20H4V13a3.009,3.009,0,0,1,3-3h5v2a4,4,0,0,0,8,0V10h8v2a4,4,0,0,0,8,0V10h8v2a4,4,0,0,0,8,0V10h5a3.009,3.009,0,0,1,3,3Z" fill="#ababab" class="color000 svgShape"></path><path d="M30 29a2 2 0 00-2-2H24a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3V29h4v3zM18 29a2 2 0 00-2-2H12a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3V29h4v3zM52 34a2 2 0 002-2V29a2 2 0 00-2-2H48a2 2 0 00-2 2v3a2 2 0 002 2zm-4-5h4v3H48zM30 38a2 2 0 00-2-2H24a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3V38h4v3zM18 38a2 2 0 00-2-2H12a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3V38h4v3zM28 45H24a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2V47A2 2 0 0028 45zm-4 5V47h4v3zM36 34h4a2 2 0 002-2V29a2 2 0 00-2-2H36a2 2 0 00-2 2v3A2 2 0 0036 34zm0-5h4v3H36zM34 41a2 2 0 002 2 1 1 0 000-2V38h4a1 1 0 000-2H36a2 2 0 00-2 2zM16 45H12a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2V47A2 2 0 0016 45zm-4 5V47h4v3z" fill="#ababab" class="color000 svgShape"></path><path d="M49,36A13,13,0,1,0,62,49,13.015,13.015,0,0,0,49,36Zm0,24A11,11,0,1,1,60,49,11.013,11.013,0,0,1,49,60Z" fill="#ababab" class="color000 svgShape"></path><path d="M54.778,44.808,47,52.586,43.465,49.05a1,1,0,0,0-1.414,1.414l4.242,4.243a1,1,0,0,0,1.414,0l8.485-8.485a1,1,0,0,0-1.414-1.414Z" fill="#ababab" class="color000 svgShape"></path></svg></g></svg>-->
              <!-- <svg id="SvgjsSvg1001" width="24" height="24" xmlns="http://www.w3.org/2000/svg" version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
                <defs id="SvgjsDefs1002"></defs>
                <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" enable-background="new 0 0 64 64" viewBox="0 0 64 64">
                    <path
                      d="m51.707 20.293-19-19c-.391-.391-1.023-.391-1.414 0l-19 19c-.188.187-.293.442-.293.707v38h2v-2h5.586l5.707 5.707c.187.188.441.293.707.293h15c.553 0 1-.447 1-1v-4c0-.553-.447-1-1-1h-10.586l1.293-1.293c.391-.391.391-1.023 0-1.414l-5-5c-.187-.188-.441-.293-.707-.293h-12v-27.586l18-18 18 18v37.586h-6v2h7c.553 0 1-.447 1-1v-39c0-.265-.105-.52-.293-.707zm-26.121 30.707 4 4-1.586 1.586-2.293-2.293-1.414 1.414 3 3c.195.195.451.293.707.293h12v2h-13.586l-5.707-5.707c-.187-.188-.441-.293-.707-.293h-6v-4z"
                      fill="#ababab" class="color000 svgShape"></path>
                    <path
                      d="m28 41v3c0 .334.167.646.445.832l3 2c.168.112.362.168.555.168s.387-.056.555-.168l3-2c.278-.186.445-.498.445-.832v-13.843c3.616-1.58 6-5.174 6-9.157 0-5.514-4.486-10-10-10s-10 4.486-10 10c0 3.983 2.384 7.577 6 9.157v2.843c0 .266.105.52.293.707l1.293 1.293-1.293 1.293c-.391.391-.391 1.023 0 1.414l1.293 1.293-1.293 1.293c-.188.187-.293.441-.293.707zm1.334-12.466c-3.19-1.129-5.334-4.157-5.334-7.534 0-4.411 3.589-8 8-8s8 3.589 8 8c0 3.377-2.144 6.405-5.334 7.534-.399.142-.666.519-.666.943v13.988l-2 1.333-2-1.333v-2.051l1.707-1.707c.391-.391.391-1.023 0-1.414l-1.293-1.293 1.293-1.293c.391-.391.391-1.023 0-1.414l-1.707-1.707v-3.109c0-.424-.267-.801-.666-.943z"
                      fill="#ababab" class="color000 svgShape"></path>
                    <path
                      d="M36 19c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM39 41h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1h-8c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1zm1-4h2v2h-2zm4 2v-2h2v2zm2-4h-2v-2h2zm-4-2v2h-2v-2zM17 31c-.553 0-1 .448-1 1v8c0 .553.447 1 1 1h8c.553 0 1-.447 1-1v-8c0-.552-.447-1-1-1zm7 4h-2v-2h2zm-4-2v2h-2v-2zm-2 4h2v2h-2zm4 2v-2h2v2z"
                      fill="#ababab" class="color000 svgShape"></path>
                  </svg></g>
              </svg> -->
            </span>
            <span class="sidebar-item-text">Lease</span>
            <span style="opacity: 1 !important;"
              class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">{{this.leaseTotal}}</span>
          </a>
        </li>
      </ng-container>
        <!--
              <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                <a routerLink="/myproperties" class="text-heading lh-1 sidebar-link d-flex align-items-center"
                  style="cursor: pointer">
                  <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                    <svg class="icon icon-my-properties">
                      <use xlink:href="#icon-my-properties"></use>
                    </svg>
                  </span>
                  <span class="sidebar-item-text">Maintenance</span>
                  <span class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span>
                </a>
              </li>
              <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                <a routerLink="/myproperties" class="text-heading lh-1 sidebar-link d-flex align-items-center"
                  style="cursor: pointer">
                  <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                    <svg class="icon icon-my-properties">
                      <use xlink:href="#icon-my-properties"></use>
                    </svg>
                  </span>
                  <span class="sidebar-item-text">Service Request</span>
                  <span class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span>
                </a>
              </li>
              <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                <a routerLink="/myproperties" class="text-heading lh-1 sidebar-link d-flex align-items-center"
                  style="cursor: pointer">
                  <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                    <svg class="icon icon-my-properties">
                      <use xlink:href="#icon-my-properties"></use>
                    </svg>
                  </span>
                  <span class="sidebar-item-text">Subletting</span>
                  <span class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span>
                </a>
              </li>
              <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                <a routerLink="/myproperties" class="text-heading lh-1 sidebar-link d-flex align-items-center"
                  style="cursor: pointer">
                  <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                    <svg class="icon icon-my-properties">
                      <use xlink:href="#icon-my-properties"></use>
                    </svg>
                  </span>
                  <span class="sidebar-item-text">Complaints</span>
                  <span class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span>
                </a>
              </li>
            -->
      </ul>
      <!-- <ul class="list-group list-group-no-border rounded-lg">
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a routerLink="/addnewevent" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <i class="ti ti-plus"></i>
            </span>
            <span class="sidebar-item-text">Add new Event</span>
          </a>
        </li>
      </ul> -->
      <ul class="list-group list-group-no-border rounded-lg">

        <!-- <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">

          <a routerLink="/notifications" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
            </span>
            <span class="sidebar-item-text">Notifications</span>
          </a>
        </li> -->

        <li *ngIf="this.invites.length > 0" [ngClass]="{'selected': this.url === '/invites'}"
          class="list-group-item px-3 px-xl-4 py-2 sidebar-item">

          <a (click)="navigateToPage('invites','','')" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
            </span>
            <span class="sidebar-item-text">Invites</span>
            <span style="opacity: 1 !important;"
            class="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">{{this.invites.length}}</span>
          </a>
        </li>

      </ul>
    </li>

    <li class="list-group-item pt-6 pb-4">
      <h5 class="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage
        Acount</h5>
      <ul class="list-group list-group-no-border rounded-lg">
        <li [ngClass]="{'selected': this.url === '/myprofile'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('myprofile','','')"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <i class="ti ti-user"></i>
              <!--<svg class="icon icon-my-profile">
                <use xlink:href="#icon-my-profile"></use>
              </svg>-->
            </span>
            <span class="sidebar-item-text">My Profile</span>
          </a>
        </li>
        <li [ngClass]="{'selected': this.url === '/dashboard'}" class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="navigateToPage('dashboard','','')"
            class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <i class="ti ti-user"></i>
              <!--<svg class="icon icon-my-profile">
                <use xlink:href="#icon-my-profile"></use>
              </svg>-->
            </span>
            <span class="sidebar-item-text">Dashboard</span>
          </a>
        </li>
        <ng-container *ngIf="!isexternalproviderlogin">
          <li [ngClass]="{'selected': this.url === '/resetpassword'}"
            class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
            <a (click)="navigateToPage('resetpassword','','')"
              class="text-heading lh-1 sidebar-link d-flex align-items-center" style="cursor: pointer">
              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                <i class="ti ti-lock"></i>
              </span>
              <span class="sidebar-item-text">Reset password</span>
            </a>
          </li>
        </ng-container>
        <!--<li class="list-group-item px-3 px-xl-4 py-2 sidebar-item active">
                    <a routerLink="/events" class="text-heading lh-1 sidebar-link"
                        style="cursor: pointer">
                        <span
                            class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg class="icon icon-review">
                                <use xlink:href="#icon-review"></use>
                            </svg>
                        </span>
                        <span class="sidebar-item-text">Events</span>
                    </a>
                </li>-->
        <li class="list-group-item px-3 px-xl-4 py-2 sidebar-item">
          <a (click)="logout()" class="text-heading lh-1 sidebar-link d-flex align-items-center"
            style="cursor: pointer">
            <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
              <i class="ti ti-logout"></i>
              <!--<svg class="icon icon-log-out">
                <use xlink:href="#icon-log-out"></use>
              </svg>-->
            </span>
            <span class="sidebar-item-text">Log Out</span>
          </a>
        </li>
      </ul>
    </li>

  </ul>
</div>
