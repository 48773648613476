
<div style="text-align:center" class="py-8">
    <!--{{this.userid}}
    <br>
    {{this.chatroomguid}}-->
    <h5 class="modal-title" style="text-align: center;align-items: center;color: #27449b;" id="exampleModalLabel">VERIFY
        YOUR IDENTITY</h5>
        <ng-container *ngIf="!this.submitted">
    <span style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;">
        Please do not use <strong>SUNGLASSES, HATS,</strong> or any <strong>ACCESSORIES</strong> that may influence the
        way you look.
        <br>
        In case of any doubt, read the terms of use and or get in touch with us! (support@roundcomb.com)
    </span>
        </ng-container>
    <br>
    <br>
</div>

<ng-container *ngIf="showWebcam && !this.submitted">

    <div style="text-align:center">
        <div>
            <webcam [height]="430" [width]="430" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"></webcam>
            <br />
            <br />

            <!--<button class="actionBtn" (click)="toggleWebcam();">Toggle Webcam</button>
                  <br/>
                  <button class="actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next Webcam</button>
                  <input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch"><label for="cameraSwitchCheckbox">Allow Camera Switch</label>
                  <br/>
                  DeviceId: <input id="deviceId" type="text" [(ngModel)]="deviceId" style="width: 500px">
                  <button (click)="showNextWebcam(deviceId);">Activate</button>
                -->
        </div>

        <button class="btn btn-primary" (click)="triggerSnapshot();">Take Photo</button>
        <!--<button type="button" class="btn btn-primary" (click)="savenewimage()">Submit</button>-->
        <br>
        <br>In case of any doubt, read the terms of use and or get in touch with us! (support@roundcomb.com)
        <br>
    </div>
</ng-container>
<ng-container *ngIf="!showWebcam && !this.submitted">

    
    <div style="text-align:center">
    <div class="snapshot" *ngIf="webcamImage">


        <div class="row" style="margin-top: 7px;text-align:center">

            <div class="text-center">

                <h5>Crop Image</h5>

                <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="true" [roundCropper]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>

            </div>


        </div>
<br>
        <button type="button" class="btn btn-primary" style="margin-right: 6px;" (click)="toggleWebcam();">Take New
            Photo</button>
        <button type="button" class="btn btn-primary" (click)="savenewimage()">Submit</button>
<br>
<br>
        <!--<h2>Nice one!</h2>-->
        <!-- <img [src]="webcamImage.imageAsDataUrl"/> -->
    </div>
    </div>
</ng-container>

<ng-container *ngIf="this.submitted">

<table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
    <tbody>
    <tr>
    <td style="width: 100%;">
        <!--<svg xmlns="http://www.w3.org/2000/svg" width="376.58" height="376.58" viewBox="0 0 376.58 376.58">
            <path id="frown-regular" d="M188.29,8A188.29,188.29,0,1,0,376.58,196.29,188.258,188.258,0,0,0,188.29,8Zm0,340.137c-83.744,0-151.847-68.1-151.847-151.847S104.547,44.443,188.29,44.443s151.847,68.1,151.847,151.847S272.034,348.137,188.29,348.137ZM127.551,184.142a24.3,24.3,0,1,0-24.3-24.3A24.269,24.269,0,0,0,127.551,184.142Zm121.477-48.591a24.3,24.3,0,1,0,24.3,24.3A24.269,24.269,0,0,0,249.029,135.551ZM188.29,232.733a102.374,102.374,0,0,0-78.808,36.9A18.222,18.222,0,1,0,137.5,292.94a66.029,66.029,0,0,1,101.585,0A18.222,18.222,0,1,0,267.1,269.632,102.374,102.374,0,0,0,188.29,232.733Z" transform="translate(0 -8)" fill="#ff993c"/>
          </svg>
        -->
          <svg xmlns="http://www.w3.org/2000/svg" width="216.58" height="216.58" viewBox="0 0 439 439">
            <path id="check-circle-solid" d="M447,227.5C447,348.727,348.727,447,227.5,447S8,348.727,8,227.5,106.273,8,227.5,8,447,106.273,447,227.5ZM202.111,343.723,364.965,180.869a14.161,14.161,0,0,0,0-20.027l-20.027-20.027a14.161,14.161,0,0,0-20.028,0L192.1,273.629l-62.008-62.008a14.163,14.163,0,0,0-20.028,0L90.035,231.648a14.161,14.161,0,0,0,0,20.027l92.048,92.048a14.162,14.162,0,0,0,20.028,0Z" transform="translate(-8 -8)" fill="#0ec6d5"/>
          </svg>
          
    </td>
    </tr>
    <tr>
    <td style="width: 100%;">
        THANK YOU!
    </td>
    </tr>
    <tr>
        <td style="width: 100%;">
            <br>In case of any doubt, read the terms of use and or get in touch with us!  (support@roundcomb.com)
        </td>
        </tr>
    </tbody>
    </table>
</ng-container>
<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
    <li>{{error | json}}</li>
</ul>