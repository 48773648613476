<section class="py-13" data-animated-id="1">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 mx-auto">
                <div class="card border-0 login-register">
                    <div class="card-body p-6">
                        <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Forgot your password?</h2>
                        <p class="mb-4">Don’t have an account yet? <a routerLink="/newuser"
                                class="text-heading hover-primary"><u>Register</u></a></p>
                        <p class="mb-4">Have an account? <a routerLink="/login"
                            class="text-heading hover-primary"><u>Sign In</u></a></p>
                            <form class="form" [formGroup]="recoverpasswordForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="email" class="text-heading">Enter your email address</label>
                                <input type="email" name="mail" id = "mail" formControlName="mail" class="form-control form-control-lg border-0" id="email"
                                    placeholder="Enter your email address"
                                    [ngClass]="{ 'is-invalid': submitted && f.mail.errors }"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                        <div *ngIf="submitted && f.mail.errors" class="invalid-feedback">
                                            <div *ngIf="f.mail.errors.required">Email is required</div>
                                            <div *ngIf="f.mail.errors.pattern">Email is invalid</div>
                                        </div>
                            </div>
                            <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg rounded">Reset password</button>
                        </form>
 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>