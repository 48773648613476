<p>modalsubscriptions works!</p>

<ngx-paypal
  #basic
  [config]="configs['basic']"
  [registerScript]="false"
></ngx-paypal>
<ngx-paypal
  #advanced
  [config]="configs['advanced']"
  [registerScript]="false"
></ngx-paypal>