import { Component, OnInit, OnDestroy } from '@angular/core';
import { onAppStartService, propertiesService } from '../services';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UploadResponse } from '@kolkov/angular-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public userIdProfile: string = '';

  public notifications: any[] = [];

  constructor(
    private onAppStartService: onAppStartService,
    private router: Router,
    private propertiesservice: propertiesService,
    private ngxService: NgxUiLoaderService
  ) {
    this.loadScripts();
  }
  ngOnInit() {
    const userinfo = localStorage.getItem('roundcombuserdetail');

    let UserJsonObject = JSON.parse(userinfo);

    this.userIdProfile = UserJsonObject.userIdProfile;

    this.getNotifications(this.userIdProfile);
  }

  ngOnDestroy() {}

  loadScripts() {
    const dynamicScripts = [
      'assets/js/theme.js',
      'assets/vendors/jquery.min.js',
      'assets/vendors/jquery-ui/jquery-ui.min.js',
      'assets/vendors/bootstrap/bootstrap.bundle.js',
      'assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      'assets/vendors/slick/slick.min.js',
      'assets/vendors/waypoints/jquery.waypoints.min.js',
      'assets/vendors/counter/countUp.js',
      'assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      'assets/vendors/chartjs/Chart.min.js',
      'assets/vendors/dropzone/js/dropzone.min.js',
      'assets/vendors/timepicker/bootstrap-timepicker.min.js',
      'assets/vendors/hc-sticky/hc-sticky.min.js',
      'assets/vendors/jparallax/TweenMax.min.js',
      'assets/vendors/mapbox-gl/mapbox-gl.js',
      'assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  goToEvent(destpage: string, paramname: string, paramvalue: string) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'chatroomguid') {
        this.router.navigate(['/' + destpage], {
          queryParams: { chatroomguid: paramvalue },
        });
      }
    }
  }

  getNotifications(id) {
    this.propertiesservice
      .getNotifications(id)
      .toPromise()
      .then((response) => {
        this.notifications = [];
        let setResponse = new Set([...response]);
        let arr = [...setResponse];
        arr.forEach((el) => {
          let data = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'data'
          );
          let message = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'chatmessage'
          );
          let photo = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'userimageurl'
          );
          let icon = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'notificationicon'
          );
          let color = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'myrgbcolor'
          );
          let isnotification = this.readChatMessageParameter(
            JSON.stringify(el.Message),
            'isnotification'
          );
          this.notifications.push({
            data: data,
            userPhoto: photo,
            guid: el.GUID,
            message: message,
            isnotification: isnotification,
            icon: icon,
            color: color,
          });
        });
      });
  }

  private readChatMessageParameter(
    messagecontent: string,
    messageparameter: string
  ) {
    var indexbegin =
      messagecontent.indexOf('<' + messageparameter + '>') +
      messageparameter.length +
      2;
    var indexend = messagecontent.indexOf('</' + messageparameter + '>');

    if (indexend - indexbegin == 0) return '';
    else return messagecontent.substring(indexbegin, indexend);
  }
}
