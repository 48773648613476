import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { AuthenticationService, propertiesService } from '../services';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerictoastmessageComponent } from '../generictoastmessage/generictoastmessage.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { faUpload, faX } from '@fortawesome/free-solid-svg-icons';
export class property {
  constructor(
    public id: number,
    public idproperty: number,
    public pageindex: number,
    public page: number,
    public Name: string,
    public CreatedDate: string,
    public Status: string,
    public selltype: string,
    public Price: string,
    public image: string,
    public views: string,
    public isdeleted: boolean //active = 0 or deleted
  ) {}
}

export class UserClickViewsPerProperty {
  constructor(public propertyid: string, public totalviews: string) {}
}

@Component({
  selector: 'app-myservices',
  templateUrl: './myservices.component.html',
  styleUrls: ['./myservices.component.css'],
})
export class MyServicesComponent implements OnInit, AfterViewInit {
  public useridprofile: string = '';

  page: number = 1;
  count: number = 0;
  faUpload = faUpload;
  faX = faX;

  public propSlots: any = [];

  public textstatusparam: string = 'All';
  public textfilterparam: string = '';
  public searchValue: string = '';
  public apiurl: string = '';
  public innerWidth: number = window.innerWidth;
  public selectedpage: number = 1;
  public showonlyactive: boolean = true;
  public loading: boolean = true;
  public empty: boolean = true;

  public services = [];
  public propSlotsFree = [];
  public serviceidtoDelete: string;
  public totalproperties: number = 0;

  public propertieslist = new Array<property>();
  public pagesarray = new Array<{ idpage: number; pagedesc: String }>();
  public propertieslisttoshowpage = new Array<property>();
  public viewsarray = new Array<UserClickViewsPerProperty>();
  public propertieslistpage = new Array<{
    pagenumber: number;
    property: property;
  }>();

  constructor(
    private modalService: NgbModal,
    public authenticationService: AuthenticationService,
    private propertiesservice: propertiesService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.apiurl = environment.apiUrl;
    this.ngxService.startBackground('loadmyservices');

    // get user info
    const userinfo = localStorage.getItem('roundcombuserdetail');

    let UserjsonObject = JSON.parse(userinfo);
    this.useridprofile = UserjsonObject.userIdProfile;

    this.getServices(UserjsonObject.userIdProfile);
    this.getSlot(UserjsonObject.userIdProfile);
  }

  ngAfterViewInit() {}
  async getSlot(id) {
    await this.propertiesservice
      .getSlot(id)
      .toPromise()
      .then((response) => {
        let filtered = response.filter((el) => {
          return el.Idproperty === 0 && el.Month > 0;
        });
        this.propSlotsFree = filtered;
        this.propSlots = response;
      })
      .catch((e) => console.error(e));
    this.ngxService.stopBackground('loadmydashboard');
    this.loading = false;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.ngOnInit();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getServices(this.useridprofile);
  }

  toNext(p) {
    if (!p.isLastPage()) {
      p.next();
    }
  }

  private getServices(id) {
    this.propertiesservice
      .getServices(id)
      .toPromise()
      .then((response) => {
        response.forEach((el) => {
          let date = new Date(el.create_date * 1000);
          return (el.create_date = date.toLocaleDateString('en-US'));
        });
        if (this.textstatusparam === 'All' && this.searchValue === '') {
          this.services = response;
        } else if (this.textstatusparam === 'All' && this.searchValue !== '') {
          let filteredByName = response.filter((el) => {
            return el.title
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
          this.services = filteredByName
        } else if (this.textstatusparam === 'Published' && this.searchValue === '') {
          let filteredByStatus = response.filter(el => {
            return el.status === "AvailableForSell"
          })
          this.services = filteredByStatus
        } else if (this.textstatusparam === 'Published' && this.searchValue !== '') {
          let filteredByStatus = response.filter(el => {
            return el.status === "AvailableForSell"
          })
          let filteredByName = filteredByStatus.filter((el) => {
            return el.title
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
          this.services = filteredByName
        } else if (this.textstatusparam === 'Unpublished' && this.searchValue === '') {
          let filteredByStatus = response.filter(el => {
            return el.status === "Draft"
          })
          this.services = filteredByStatus
        } else {
          let filteredByStatus = response.filter(el => {
            return el.status === "Draft"
          })
          let filteredByName = filteredByStatus.filter((el) => {
            return el.title
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
          this.services = filteredByName
        }

        this.empty = this.services.length === 0 ? true : false
        this.ngxService.stopBackground('loadmyservices');
      })
      .catch((e) => console.error(e));
  }

  onstatuschanged(selectedvalue) {
    if (selectedvalue == 1)
      // All
      this.textstatusparam = 'All';
    else if (selectedvalue == 2) this.textstatusparam = 'Published';
    else if (selectedvalue == 3) this.textstatusparam = 'Unpublished';
    this.getServices(this.useridprofile);
  }

  searchAService(e) {
    this.searchValue = e.target.value;
    this.getServices(this.useridprofile);
  }

  private getNumberofViewsFromArray(idproperty: string) {
    for (var j = 0; j < this.viewsarray.length; j++) {
      if (this.viewsarray[j].propertyid == idproperty)
        return this.viewsarray[j].totalviews;
    }

    return '0';
  }

  public getarraypage(page: number) {
    this.propertieslisttoshowpage.splice(
      0,
      this.propertieslisttoshowpage.length
    );

    for (var i = 0; i < this.propertieslistpage.length; i++) {
      if (this.propertieslistpage[i].pagenumber == page)
        this.propertieslisttoshowpage.push(this.propertieslistpage[i].property);
    }
  }

  public updateSlotStatus(id, pubOrNot) {
    if (this.propSlotsFree.length === 0 && pubOrNot) {
      this.toast.error('No slots available!', 'Error!');
      return;
    } else {
      Swal.fire({
        icon: 'warning',
        title: `${
          pubOrNot
            ? 'Do you want publish property in slot?'
            : 'Do you want to unpublish slot ownership?'
        }`,
        showDenyButton: true,
        confirmButtonText: `${pubOrNot ? 'Publish' : 'Unpublish'}`,
        denyButtonText: `Cancel`,
      }).then((res) => {
        /* Read more about isConfirmed, isDenied below */
        if (res.isConfirmed) {
          if (pubOrNot) {
            this.updateSlots(
              this.useridprofile,
              '0',
              this.propSlotsFree[0].Idtransaction,
              Number(id)
            );
            this.updateService(Number(id), 'AvailableForSell');
            this.toast.success('Published successfully!', 'Success!');
          } else {
            this.updateService(Number(id), 'Draft');
            let propFilter = this.propSlots.filter((el) => {
              return el.Idproperty === Number(id);
            });
            this.updateSlots(
              this.useridprofile,
              '1',
              propFilter[0].Idtransaction,
              0
            );
            this.toast.success('Unpublished successfully!', 'Success!');
          }
          this.ngOnInit();
        } else if (res.isDenied) {
          return;
        }
      });
    }
  }

  private updateService(id, value) {
    let result;
    this.propertiesservice
      .updateStatusService(id, value)
      .toPromise()
      .then((response) => {
        result = response;
      });
    return result;
  }

  sortCompare(a, b) {
    var ax = [],
      bx = [];

    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }

    return ax.length - bx.length;
  }

  public navigateToServicesDetails(serviceId: string) {
    this.router.navigate(['/servicedetail'], { queryParams: { serviceId } });
  }

  public navigateToPropertyEdit(serviceId: string) {
    this.router.navigate(['/editservices'], { queryParams: { serviceId } });
  }

  updatepageindex(page: number) {
    this.selectedpage = page;
    this.getarraypage(this.selectedpage);
    this.updateselectedpageUI();
  }

  updateselectedpageUI() {
    for (var i = 0; i < this.pagesarray.length; i++) {
      if (this.pagesarray[i].idpage == this.selectedpage)
        this.pagesarray[i].pagedesc = 'page-item active';
      else this.pagesarray[i].pagedesc = 'page-item';
    }
  }

  searchevent(searchword: string) {
    this.textfilterparam = searchword;
  }

  public isuserlandlord() {
    if (this.authenticationService.roles.includes('landlord')) return true;
    else return false;
  }
  showToastMessage(message) {
    const modalRef = this.modalService.open(GenerictoastmessageComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
    });
    let data = {
      prop1: 'Question',
      prop2: message,
      prop3: '5',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        if (result == 'yes') {
          this.deleteOneService(
            this.useridprofile,
            Number(this.serviceidtoDelete)
          );
          this.getServices(this.useridprofile);
        }
      },
      (reason) => {}
    );
  }

  deletepropertyConfirmation(serviceId: string) {
    this.serviceidtoDelete = serviceId;
    //Are you sure you want to delete this item?
    this.showToastMessage('Are you sure you want to delete this property?');
  }

  private updateSlots(id, available, idslot, Idproperty) {
    let result;
    this.propertiesservice
      .updateSlot(id, available, idslot, Idproperty)
      .toPromise()
      .then((response) => {
        result = response;
      });

    return result;
  }

  private deleteOneService(id, Idservice) {
    let filter = this.propSlots.filter((el) => {
      return el.Idproperty.toString() === this.serviceidtoDelete.toString();
    });
    if (filter.length > 0) {
      this.updateSlots(this.useridprofile, '1', filter[0].Idtransaction, 0);
    }
    let result;
    this.propertiesservice
      .deleteService(id, Idservice)
      .toPromise()
      .then((response) => {
        result = response;
      });

    return result;
  }
}
