<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
  <div class="container" style="display: flex;justify-content: center;">

    <div style="background-color: white; padding: 30px !important;" class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">


      <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
        data-animate="fadeInDown">In the news</h1>


      <div id="content" class="main-content" data-content-field="main-content"
        data-collection-id="57da6cdcd482e972e846c552" data-edit-main-image="">
        <div class="blog-list">


          <article class="entry h-entry hentry author-dennis-oparaugo post-type-text article-index-1"
            id="article-592e9ce69de4bb6e73d9832e" data-item-id="592e9ce69de4bb6e73d9832e">

            <header class="entry-header">
              <div class="meta-above-title">



              </div>

              <div class="meta-below-title">



              </div>
            </header>

            <div class="entry-content">


              <div class="e-content">
                <div class="sqs-layout sqs-grid-12 columns-12" data-layout-label="Post Body" data-type="item"
                  data-updated-on="1496227246727" id="item-592e9ce69de4bb6e73d9832e">
                  <div class="row sqs-row">
                    <div class="col sqs-col-12 span-12">
                      <div class="sqs-block html-block sqs-block-html" data-block-type="2"
                        id="block-4dd900c6158e0dd5b5f4">
                        <div class="sqs-block-content">
                          <p>NOTICE OF ALLOWANCE (NOA)<br><br>ISSUE DATE: Dec 27, 2016<br><br>Mark: ROUNDCOMB DILIGENCE
                            MATTERS. (Stylized/Design)<br>Docket/Reference Number:<br><br>No opposition was filed for
                            this published application.&nbsp; The issue date of this NOA establishes the due date for
                            the filing of a Statement of Use (SOU) or a Request for Extension of Time to file a
                            Statement of Use (Extension Request).&nbsp; WARNING: An SOU that meets all legal
                            requirements must be filed before a registration certificate can issue.&nbsp; Please read
                            below for important information regarding the applicant's pending six (6) month
                            deadline.<br><br>SIX (6)-MONTH DEADLINE: Applicant has six (6) MONTHS from the NOA issue
                            date to file either:<br><br>&nbsp;- An SOU, if the applicant is using the mark in commerce
                            (required even if the applicant was using the mark at the time of filing the application, if
                            use basis was not specified originally); OR<br><br>&nbsp;- An Extension Request, if the
                            applicant is not yet using the mark in commerce.&nbsp; If an Extension Request is filed, a
                            new request must be filed every six (6) months until the SOU is filed.&nbsp; The applicant
                            may file a total of five (5) extension requests.&nbsp; WARNING: An SOU may not be filed more
                            than thirty-six (36) months from when the NOA issued.&nbsp; The deadline for filing is
                            always calculated from the issue date of the NOA.<br><br>How to file SOU and/or Extension
                            Request:<br>Use the Trademark Electronic Application System (TEAS).&nbsp; Do NOT reply to
                            this e-mail, as e-mailed filings will NOT be processed.&nbsp; Both the SOU and Extension
                            Request have many legal requirements, including fees and verified statements; therefore,
                            please use the USPTO forms available online at <a target="_blank"
                              href="http://www.uspto.gov/teas/index.html">http://www.uspto.gov/teas/index.html</a>&nbsp;(under
                            the 'INTENT-TO-USE (ITU) FORMS' category) to avoid the possible omission of required
                            information.&nbsp; If you have questions about this notice, please contact the Trademark
                            Assistance Center at <a target="_blank"
                              href="tel:1-800-786-9199">1-800-786-9199</a>.<br><br>For information on how to (1) divide
                            an application; (2) delete goods/services (or entire class) with a Section 1(b) basis; or
                            (3) change filing basis, see <a target="_blank"
                              href="http://www.uspto.gov/trademarks/basics/MoreInfo_SOU_EXT.jsp">http://www.uspto.gov/trademarks/basics/MoreInfo_SOU_EXT.jsp</a>.<br><br>FAILURE
                            TO FILE A REQUIRED DOCUMENT OUTLINED ABOVE DURING THE APPROPRIATE TIME PERIOD WILL RESULT IN
                            THE ABANDONMENT OF THIS APPLICATION.<br><br>REVIEW APPLICATION INFORMATION FOR
                            ACCURACY<br><br>If you believe this NOA should not have issued or correction of the
                            information shown below is needed, you must submit a request to the Intent-to-Use
                            Unit.&nbsp; Please use the 'Post-Publication Amendment' form under the
                            'POST-PUBLICATION/POST NOTICE OF ALLOWANCE (NOA) FORMS' category, available at <a
                              target="_blank"
                              href="http://www.uspto.gov/teas/index.html">http://www.uspto.gov/teas/index.html</a>.&nbsp;
                            Do NOT reply to this e-mail, as e-mailed filings will NOT be processed.<br><br>Mark:
                            ROUNDCOMB DILIGENCE MATTERS. (Stylized/Design)<br>Docket/Reference Number:<br><br>This
                            application has the following bases, but not necessarily for all listed
                            goods/services:<br>Section 1(a):&nbsp; NOSection 1(b):&nbsp; YESSection 44(e):&nbsp;
                            NO<br><br>GOODS/SERVICES BY INTERNATIONAL CLASS<br><br>036 - Online collaborative real
                            estate listing, tenant rating, rental history, and rental application verification
                            services<br>-- FIRST USE DATE: NONE;&nbsp; &nbsp; &nbsp; -- USE IN COMMERCE DATE:
                            NONE<br><br>ALL OF THE GOODS/SERVICES IN EACH CLASS ARE LISTED.</p>
                        </div>
                      </div>
                      <div class="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type="47"
                        id="block-yui_3_17_2_1_1496226853841_37606">
                        <div class="sqs-block-content">
                          <hr>
                        </div>
                      </div>
                      <div class="sqs-block html-block sqs-block-html" data-block-type="2"
                        id="block-yui_3_17_2_1_1496226853841_37668">
                        <div class="sqs-block-content">
                          <p>Fraudulent statements may result in registration being cancelled: Applicants must ensure
                            that statements made in filings to the USPTO are accurate, as inaccuracies may result in the
                            cancellation of any issued trademark registration.&nbsp; The lack of a bona fide intention
                            to use the mark with ALL goods and/or services listed in an application or the lack of
                            actual use on all goods and/or services for which use is claimed could jeopardize the
                            validity of the registration, possibly resulting in its cancellation.<br><br>Additional
                            information: For information on filing and maintenance requirements for U.S. trademark
                            applications and registrations and required fees, please consult the USPTO website at <a
                              target="_blank" href="http://www.uspto.gov/">www.uspto.gov</a>&nbsp;or call the Trademark
                            Assistance Center at <a target="_blank"
                              href="tel:1-800-786-9199">1-800-786-9199</a>.<br><br>Checking status: To check the current
                            status of your application, go to Trademark Status and Document Retrieval (TSDR)(<a
                              target="_blank" href="http://tsdr.uspto.gov/">http://tsdr.uspto.gov/</a>). Please check
                            the status of any application at least every three (3) months after the application filing
                            date.<br><br>To view this notice and other documents for this application on-line, go to <a
                              target="_blank"
                              href="http://tdr.uspto.gov/search.action?sn=87055175">http://tdr.uspto.gov/search.action?sn=87055175</a>&nbsp;.&nbsp;
                            NOTE: This notice will only be available on-line the next business day after receipt of this
                            e-mail.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>




          </article>




        </div>
      </div>




    </div>


  </div>
</section>
