<section class="py-13" data-animated-id="1">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 mx-auto">
                <div class="card border-0 shadow-xxs-2 login-register">
                    <div class="card-body p-6">
                        <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Successfully Reset Password</h2>
                        <p class="mb-4">Your password has been reset. Please login again with new password.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>