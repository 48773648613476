<section style="margin-bottom: 50px;">
  <div *ngIf="this.propertyDetails.length === 0" style="position: relative; width: 100%; height: 100vh;">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div *ngIf="this.propertyDetails.length > 0" class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb py-3">
        <li class="breadcrumb-item fs-12 letter-spacing-087">
          <a routerLink="/home">Home</a>
        </li>
        <li class="breadcrumb-item fs-12 letter-spacing-087">
          <a routerLink="/filterservice" [queryParams]='{servicetype:"All Services"}'>Listing</a>
        </li>
        <li class="breadcrumb-item fs-12 letter-spacing-087 active">{{this.propertyDetails[0]?.title}}</li>
      </ol>
    </nav>

    <div class="row service-row">
      <div class="col-sm-6 col-md-6 mb-6 card-width">
        <div class="card card-details px-6" style="border:0px; padding-top: 15px; padding-bottom: 15px;">
          <div class="d-sm-flex justify-content-sm-between">
            <div>
              <h2 style="font-size: 1.6rem !important; margin-bottom: 8px;"
                class="fs-11 font-weight-600 lh-15 text-heading">
                {{this.propertyDetails[0]?.title}}
              </h2>
            </div>
          </div>
          <h3 style="font-size: 1rem; font-weight: 600; color: #203E97;">Vendor Information</h3>
          <div style="display: flex; gap: 10px;">
            <div class="detail-photo" *ngIf="this.userDetail.ProfilePhotoUrl === null">
              <img src="assets/images/User-Default.jpg" alt="{{this.userDetail.FirstName}} photo">
            </div>
            <div class="detail-photo"
              *ngIf="!this.userDetail.ProfilePhotoUrl?.startsWith('https') && this.userDetail.ProfilePhotoUrl !== null">
              <img src="{{this.apiUrl + this.userDetail.ProfilePhotoUrl}}" alt="{{this.userDetail.FirstName}} photo">
            </div>
            <div class="detail-photo" *ngIf="this.userDetail.ProfilePhotoUrl?.startsWith('https')">
              <img src="{{this.userDetail.ProfilePhotoUrl}}" alt="{{this.userDetail.FirstName}} photo">
            </div>

            <div>
              <p style="margin-bottom: 0px !important; font-size: 20px; text-transform: capitalize; color: black;">
                <span style="margin-right: 6px;">{{this.userDetail.FirstName}}</span>
                <span>{{this.userDetail.LastName}}</span>
              </p>
              <div class="profile-detail-note">
                <div class="stars-note">
                  <ng-container *ngIf="this.ratingLength === 0">
                    <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                      <fa-icon style="font-size: 25px !important;" class="star-bg-icon star-icon" [icon]="faStar">
                      </fa-icon>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="this.ratingLength > 0">
                    <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                      <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                        <fa-icon style="font-size: 25px !important;" class="star-bg-icon star-icon" [icon]="faStar">
                        </fa-icon>
                      </ng-container>
                      <ng-container *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                        <fa-icon style="font-size: 25px !important;" class="star-bg-icon star-icon" [icon]="faStarHalf">
                        </fa-icon>
                        <fa-icon style="font-size: 25px !important;" class="star-no-icon star-icon star-half"
                          [icon]="faStar"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                        <fa-icon style="font-size: 25px !important;" class="star-no-icon star-icon" [icon]="faStar">
                        </fa-icon>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
                <a style="cursor: pointer;" *ngIf="this.ratingLength > 0" class="all-reviews"
                  (click)="navigateToPage('userreviews','id', this.idUser)">See all
                  reviews</a>
              </div>
            </div>
          </div>
          <h3 class="span-style" style="margin-top: 20px;">Member Since</h3>
          <p *ngIf="this.date?.length > 0">{{this.date[0]?.date}}</p>
          <h3 class="span-style">Address</h3>
          <p style="margin-bottom: 0px !important;">
            {{this.userDetail.Address === null || this.userDetail.Address === '' ? 'No Address' : this.userDetail.Address}}
          </p>
          <div class="description-list" style="margin-top: 30px; margin-bottom: 10px;">
            <div class="shadow user-services">
              <div class="some-details-services">
                <div class="to-text-align">
                  <span class="span-style">
                    {{this.propertyDetails[0]?.title}}
                  </span>
                  <div class="review-area">
                    <div class="profile-service-review">
                      <div class="stars-note to-vendor-service">
                        <ng-container *ngIf="lengthRating(this.propertyDetails[0].totalrating)">
                          <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!lengthRating(this.propertyDetails[0].totalrating)">
                          <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                            <ng-container *ngIf="this.propertyDetails[0].rating <= ratingConditional.lessThan">
                              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container
                              *ngIf="this.propertyDetails[0].rating > ratingConditional.inBetweenLess && this.propertyDetails[0].rating <= ratingConditional.inBetweenMore">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                              <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="this.propertyDetails[0].rating > ratingConditional.moreThan">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="services-review">
                      <p style="margin-bottom: 0 !important;">
                        {{lengthRating(this.propertyDetails[0].rating) ? '0' : this.propertyDetails[0].rating}}/5</p>
                      <span>({{lengthRating(this.propertyDetails[0].totalrating) ? '0' : this.propertyDetails[0].totalrating}})</span>
                    </div>
                  </div>
                </div>
                <div class="to-text-align">
                  <span class="span-style">Area of
                    operation</span>
                  <p style="margin-bottom: 0 !important;">
                    {{this.propertyDetails[0]?.operationArea.length > 0 ? this.propertyDetails[0]?.operationArea : 'Not Informed'}}
                  </p>
                </div>
              </div>
              <span class="span-style description-space">Service Description</span>
              <p class="short-description">
                {{this.propertyDetails[0]?.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 mb-6 card-width">
        <div class="card px-6" style="border:0px; background-color: #f8f8f8;">
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"
            style="ngx-gallery-active"></ngx-gallery>

        </div>
      </div>

    </div>
    <ng-container *ngIf="this.isuserlogged && !this.isuservendor()">
      <section data-animated-id="1" class="bg-secondary py-6 py-lg-1 align-items-center">
        <div class="container align-items-center">
          <div class="row align-items-center">
            <div style="text-align:center;" class="col-lg-12 pt-lg-1 pt-6 align-items-center">
              <label class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">
                Interested? Please contact the vendor
              </label>
              &nbsp; &nbsp;<a (click)="ContactLandLordModal()" class="badge badge-orange"
                style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Contact</a>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="!this.isuserlogged">
      <section data-animated-id="1" class="bg-secondary py-6 py-lg-1 align-items-center">
        <div class="container align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-12 pt-lg-1 pt-6 align-items-center" style="text-align:center"><label
                class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">Interested?
                Please Sign In to contact the vendor</label>
              &nbsp; &nbsp;<a (click)="navigateToPage('login','','')" class="badge badge-orange"
                style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Sign
                In</a>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="this.amenities?.length > 0">
      <section class="mt-2 pb-7 px-6 pt-5 bg-white rounded-lg">
        <h4 class="fs-22 text-heading mb-4">Services</h4>
        <ul class="list-unstyled mb-0 row no-gutters" *ngFor="let feature of this.amenities;">
          <li class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>{{feature.desctext}}
          </li>
        </ul>
      </section>
    </ng-container>
    <div *ngIf="this.serviceReviews.length > 0"
      style="padding: 20px; background-color: white; width: 100%; margin-top: 10px;">
      <h4 class="fs-22 text-heading mb-4">Service Reviews</h4>
      <div style="border-bottom: 1px solid #c0bcbc; padding-bottom: 15px;"
        *ngFor="let review of this.serviceReviews.slice(0, this.sliceReviews); let i = index">
        <h5 [ngClass]="{'diff-one': i > 0, 'username-style': true}">{{review.userName}}</h5>
        <div class="stars-note">
          <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
            <ng-container *ngIf="review.rating > ratingConditional.moreThan">
              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
            </ng-container>
            <ng-container *ngIf="review.rating > ratingConditional.inBetweenLess && review.rating <= ratingConditional.inBetweenMore">
              <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
              <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
            </ng-container>
            <ng-container *ngIf="review.rating <= ratingConditional.lessThan">
              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
            </ng-container>
          </div>
          <!-- </ng-container> -->
        </div>
        <p class="review-date">Review date: {{review.create_date}}</p>
        <p class="rating-desc">{{review.text === '' ? 'No description.' : review.text}}</p>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 40px;">
        <button *ngIf="this.reviews.length > 3 && this.serviceReviews.length > this.sliceReviews"
          (click)="this.loadMoreReviews()" class="more-reviews">Load more reviews</button>
      </div>
    </div>
    <section class="mt-2 pb-7 px-6 pt-6 bg-white rounded-lg">
      <h4 class="fs-22 text-heading mb-6">Similar services You May Like</h4>
      <div class="slick-slider slick-initialized">
        <div class="slick-prev slick-arrow slick-disabled" aria-label="Previous" aria-disabled="true" style="">
          <i class="far fa-angle-left"></i>
        </div>
        <div class="slick-list draggable" style="height: 300px;">
          <div class="slick-track" style="opacity: 1; width: 1110px; transform: translate3d(0px, 0px, 0px);">

            <ng-container *ngIf="this.similarServices.length > 0">
              <div *ngFor="let similarservice of this.similarServices; let i = index"
              (click)="navigateToPropertyDetails(similarservice.id)">

              <div class="box slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
                style="width: 370px;" tabindex="0">
                <div class="card card-height shadow-hover-2">
                  <a>
                    <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                      <img src="{{this.apiurl}}/{{similarservice.imageOne}}" alt="" class="responsivegridimage">
                    </div>
                  </a>
                  <div style="padding-bottom: 5px !important;" class="card-body pt-3">
                    <h2 class="card-title fs-16 mb-0"><a class="text-dark hover-primary" tabindex="0" style="cursor: pointer;  overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;">{{similarservice.title}}</a></h2>
                  </div>
                  <div style="margin-left: 20px; padding-bottom: 20px;">
                    <div>
                      <span class="desc-detail">{{similarservice.description}}</span>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            </ng-container>
          </div>
        </div>


        <div class="slick-next slick-arrow" aria-label="Next" style="" aria-disabled="false"><i
            class="far fa-angle-right"></i></div>
      </div>
    </section>


  </div>
  <!--</ng-container>-->

  <ng-container *ngIf="this.istheownerloggeduser">
    <div id="bottom">
      <button id="bot_open" class="btn btn-primary btn-rounded" (click)="navigateToPropertyEdit()">Edit
        Service</button>
    </div>
  </ng-container>
</section>
