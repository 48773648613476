import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { usersService} from '../../services';

@Component({
  selector: 'app-careersapply',
  templateUrl: './careersapply.component.html',
  styleUrls: ['./careersapply.component.css']
})
export class CareersapplyComponent implements OnInit {


  careersapplyForm: FormGroup;
  submitted = false;
  openpositioncode:string = '#ABC000112';
  isemailsentsuccessfull = false;

  constructor(private usersservice: usersService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.careersapplyForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      city: ['',],
      state: ['',],
      postalcode: ['',],
      coverletter: ['', ]
    });
  }

    // convenience getter for easy access to form fields
    get f() { return this.careersapplyForm.controls; }


    onSubmit() {

      this.submitted = true;

       // stop here if form is invalid
      if (this.careersapplyForm.invalid) {
          return;
      }

       this.usersservice.sendEmailApplyCareer(this.f.email.value,this.f.firstname.value, this.f.lastname.value, this.f.phone.value,this.f.coverletter.value,this.f.address.value,this.f.city.value,this.f.state.value,this.f.postalcode.value,this.openpositioncode)
      .pipe()
      .subscribe(
          data => {
            this.usersservice.sendMailCareer(this.f.email.value,this.f.firstname.value, this.f.lastname.value, this.f.phone.value,"null",this.f.address.value, this.f.city.value,this.f.state.value,this.f.postalcode.value,this.f.coverletter.value,"123","Careers");
            this.isemailsentsuccessfull = true;
          },
          error => {

          });



  }

}
