import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registrationsucessed',
  templateUrl: './registrationsucessed.component.html',
  styleUrls: ['./registrationsucessed.component.css']
})
export class RegistrationsucessedComponent implements OnInit {
  public email:string;
  constructor( ) { }

  ngOnInit(): void {

  }

}
