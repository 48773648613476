<div class="modal-header">
    <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">Edit User</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">


    <div class="collapse-tabs">
        <ul class="tabs-01 nav nav-tabs justify-content-center text-uppercase d-none d-md-flex" role="tablist">
            <li class="nav-item">
                <a href="#selling1" class="nav-link rounded-0 lh-2 fs-13 bg-white py-1 px-6 shadow-none active"
                    data-toggle="tab" role="tab" aria-selected="true">
                    Profile
                </a>
            </li>
           <!--  <li class="nav-item">
                <a href="#renting1" class="nav-link rounded-0 lh-2 fs-13 bg-white py-1 px-6 shadow-none"
                    data-toggle="tab" role="tab" aria-selected="false">
                    Change Password
                </a>
            </li>
            <li class="nav-item dropdown">
                <a href="#question1" class="nav-link rounded-0 lh-2 fs-13 bg-white py-1 px-6 shadow-none"
                    data-toggle="tab" role="tab" aria-selected="false">
                    Permissions
                </a>
            </li> -->
        </ul>
        <div class="tab-content shadow-none rounded-0 pt-8 pt-md-10 pb-10 pb-md-12 px-0 bg-gray-01">
            <div id="collapse-tabs-accordion-01">
                <div class="tab-pane tab-pane-parent fade container active show" id="selling1" role="tabpanel">
                    <div class="pt-5" style="align-content: center;">
                        <div class="section-title d-flex align-items-center">
                            <!--<h2>Edit profile </h2>
                                      <span class="ml-auto">Role {{this.authenticationService.roles}}</span>-->
                            <span class="ml-auto badge mr-xl-2 mt-3 mt-sm-0"
                                style="background-color: rgb(102 113 119 / 93%);color:white;">
                                <strong>Roles:</strong> {{this.userroles}}</span>

                        </div>

                        <form class="form" [formGroup]="myprofileForm" (ngSubmit)="onSubmit()">
                            <div style="display: flex;justify-content: center;text-align: center;">
                                <div class="profile-img">
                                    <img [src]="this.url" id="profileimage" name="profile" alt="profile image"
                                        style="height: 150px; width: 150px;border-radius: 50%" />
                                    <div class="file btn btn-lg btn-primary">
                                        Change Photo
                                        <input type="file" name="file" id="imgfile" (change)="onFileSelect($event)" value="" />
                                    </div>

                                </div>


                            </div>



                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label>First name</label>
                                    <input type="text" formControlName="firstname" id="firstname" name="firstname" class="form-control"
                                        [(ngModel)]="firstname" (change)="onChangefirstname($event)">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Last name</label>
                                    <input type="text" class="form-control" formControlName="lastname" id="lastname" name="lastname"
                                        [(ngModel)]="lastname" (change)="onChangelastname($event)">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Email</label>
                                    <input type="text" class="form-control" formControlName="email" id="email" name="email"
                                        [(ngModel)]="email" readonly>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Phone number</label>
                                    <input type="text" class="form-control" formControlName="phonenumber" id="phonenumber"
                                        name="phonenumber" [(ngModel)]="phonenumber" (change)="onChangephonenumber($event)">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Location</label> (<a (click)="openGoogelMapsModal()" class="text-heading hover-primary"
                                        style="cursor: pointer"><u>set location</u></a>)
                                    <ng-container *ngIf="this.islocationcheked">
                                        <a style="padding-left:20px;" (click)="clearlocation()">
                                            <label style="cursor: pointer;color:#0ca5b1">clear location</label></a>
                                    </ng-container>
                                    <!--<input type="text" class="form-control" formControlName="address" id="address" name="address" [(ngModel)]="address"  readonly>-->
                                    <p><label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">
                                            {{this.address}}
                                        </label>
                                    </p>

                                </div>
                                <div class="form-group col-md-6">
                                    <label>Birthday</label>
                                    <!--<input type="date" class="form-control" #dp="angular-mydatepicker" angular-mydatepicker [(ngModel)]="birthday" value= "22/02/1983" formControlName="birthday'"  (change)="onChangebirthday($event)" >-->
                                    <div class="input-group">
                                        <input class="form-control" placeholder="Select a date" angular-mydatepicker name="myDate"
                                            id="myDate" [(ngModel)]="modelbirthdate" formControlName="myDate" [options]="myOptions"
                                            #dp="angular-mydatepicker" (click)="dp.toggleCalendar()"
                                            (dateChanged)="onDateChanged($event)" readonly />
                                        <div class="input-group-append">

                                        </div>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                                                <i class="fa" style="font-size:18px;color:#0ca5b1;">&#xf133;</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <hr class="my-5">
                                <div class="col-md-12">
                                    <ng-container *ngIf="isSavedmyprofile">

                                        <div class="alert alert-success" role="alert">
                                            <h4 class="alert-heading">Saved!</h4>
                                            <p>Success! Your profile has been updated!</p>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                        </form>
                    </div>


                </div>
                <div class="tab-pane tab-pane-parent fade container" id="renting1" role="tabpanel">
                    <div class="card border-0 bg-transparent">
                        <div class="card-header border-0 d-block d-md-none bg-transparent px-0 py-1"
                            id="headingRenting-01">
                            <h5 class="mb-0">
                                <button
                                    class="btn lh-2 fs-18 bg-white py-1 px-6 shadow-none w-100 collapse-parent border"
                                    data-toggle="false" data-target="#renting-collapse-01" aria-expanded="true"
                                    aria-controls="renting-collapse-01">
                                    Question about renting
                                </button>
                            </h5>
                        </div>
                        <div id="renting-collapse-01" class="collapse collapsible" aria-labelledby="headingRenting-01"
                            data-parent="#collapse-tabs-accordion-01">
                            <div id="accordion-style-01-2" class="accordion accordion-01 row my-7 my-md-0 mx-3 mx-md-0">
                                <div class="col-md-6">
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0 rounded-top" id="heading_10">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0"
                                                    data-toggle="collapse" data-target="#collapse_10"
                                                    aria-expanded="true" aria-controls="collapse_10">
                                                    I’ve got a problem, how do I contact support?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_10" class="collapse show" aria-labelledby="heading_10"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_11">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_11"
                                                    aria-expanded="true" aria-controls="collapse_11">
                                                    How do I delete my account?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_11" class="collapse" aria-labelledby="heading_11"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_12">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_12"
                                                    aria-expanded="true" aria-controls="collapse_12">
                                                    What is cloud backup?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_12" class="collapse" aria-labelledby="heading_12"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pt-md-0 pt-6">
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0 rounded-top" id="heading_7">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_7"
                                                    aria-expanded="true" aria-controls="collapse_7">
                                                    How can we help?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_7" class="collapse" aria-labelledby="heading_7"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_8">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_8"
                                                    aria-expanded="true" aria-controls="collapse_8">
                                                    How do I delete my account?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_8" class="collapse" aria-labelledby="heading_8"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_9">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_9"
                                                    aria-expanded="true" aria-controls="collapse_9">
                                                    Do you store any of my information?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_9" class="collapse" aria-labelledby="heading_9"
                                            data-parent="#accordion-style-01-2">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane tab-pane-parent fade container" id="question1" role="tabpanel">
                    <div class="card border-0 bg-transparent">
                        <div class="card-header border-0 d-block d-md-none bg-transparent px-0 py-1"
                            id="headingOther-01">
                            <h5 class="mb-0">
                                <button
                                    class="btn lh-2 fs-18 bg-white py-1 px-6 shadow-none w-100 collapse-parent border"
                                    data-toggle="false" data-target="#other-collapse-01" aria-expanded="true"
                                    aria-controls="other-collapse-01">
                                    Other question
                                </button>
                            </h5>
                        </div>
                        <div id="other-collapse-01" class="collapse collapsible" aria-labelledby="headingOther-01"
                            data-parent="#collapse-tabs-accordion-01">
                            <div id="accordion-style-01-3" class="accordion accordion-01 row my-7 my-md-0 mx-3 mx-md-0">
                                <div class="col-md-6">
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_14">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0"
                                                    data-toggle="collapse" data-target="#collapse_14"
                                                    aria-expanded="true" aria-controls="collapse_14">
                                                    How do I delete my account?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_14" class="collapse show" aria-labelledby="heading_14"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0 rounded-top" id="heading_13">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_13"
                                                    aria-expanded="true" aria-controls="collapse_13">
                                                    How can we help?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_13" class="collapse" aria-labelledby="heading_13"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_15">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_15"
                                                    aria-expanded="true" aria-controls="collapse_15">
                                                    Do you store any of my information?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_15" class="collapse" aria-labelledby="heading_15"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pt-md-0 pt-6">
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0 rounded-top" id="heading_16">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_16"
                                                    aria-expanded="true" aria-controls="collapse_16">
                                                    I’ve got a problem, how do I contact support?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_16" class="collapse" aria-labelledby="heading_16"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_17">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_17"
                                                    aria-expanded="true" aria-controls="collapse_17">
                                                    How do I delete my account?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_17" class="collapse" aria-labelledby="heading_17"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                        <div class="card-header border-0 p-0" id="heading_18">
                                            <h5 class="mb-0">
                                                <button
                                                    class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                    data-toggle="collapse" data-target="#collapse_18"
                                                    aria-expanded="true" aria-controls="collapse_18">
                                                    What is cloud backup?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_18" class="collapse" aria-labelledby="heading_18"
                                            data-parent="#accordion-style-01-3">
                                            <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                                GrandHome is intended to be highly responsive and customizable for site
                                                building
                                                process. Thanks to its devoted, fastidious, and compact design, Mitech
                                                can be
                                                considered among plenty of unique themes that serve to create highly
                                                responsive
                                                websites.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>
<div class="modal-footer">

    <button class="btn btn-primary">Save Changes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>

</div>
