import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { onAppStartService, propertiesService } from '../services';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UploadResponse } from '@kolkov/angular-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swiper from 'swiper';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public nameOwner: string;
  public Bedroom: string;
  public Bathroom: string;
  public squareFeetA: string;
  public optionGarage: string;
  public list: any = [];
  public listHighlight: any = [];
  public allBooster: any = [];
  public propList: any = [];
  public sevList: any = [];
  public jsonrequest: string = '';
  public canvas: any;
  public loading: boolean = true;
  public currentSlide: number = 0;

  public image1: string = '';
  public image2: string = '';
  public image3: string = '';
  public image4: string = '';

  public show1: boolean = false;
  public show2: boolean = false;
  public show3: boolean = false;
  public show4: boolean = false;

  public imageResultUpload: string = '';

  public featuredpropertieslist = new Array<{
    idcategory: number;
    namecategory: string;
    SquareFeet: string;
    Baths: string;
    Beds: string;
    MoveInDate: string;
    OwnerName: string;
    Name: string;
    ShortDescription: string;
    FullDescription: string;
    LocationId: string;
    LocationDesc: string;
    CreatedDate: string;
    CreatedBy: string;
    imageurl: string;
    Price: string;
    Garage: string;
    PropertySellType: string;
    IdProperty: string;
    queryparam: string;
  }>();

  slideConfig = {
    slidesToShow: 1,
    cssEase: 'linear',
    fade: true,
    speed: 1000,
    autoplay: true,
    dots: false,
    arrows: true,
    infinite: true,
  };

  slideTwoConfig = {
    slidesToShow: 4,
    dots: false,
    infinite: false,
    arrows: true,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 3, dots: true } },
      { breakpoint: 992, settings: { slidesToShow: 2, dots: true } },
      { breakpoint: 768, settings: { slidesToShow: 2, dots: true } },
      { breakpoint: 576, settings: { slidesToShow: 1, dots: true } },
    ],
  };
  swiper: Swiper;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  isDataAvailable: boolean = false;
  constructor(
    private onAppStartService: onAppStartService,
    private router: Router,
    private propertiesservice: propertiesService,
    private ngxService: NgxUiLoaderService,
    private http: HttpClient
  ) {}
   ngOnInit() {
     this.ngxService.startBackground('loadHomePage');
     this.loadScripts();
     this.getAllProperties('');
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {}

  loadScripts() {
    const dynamicScripts = [
      'assets/js/theme.js',
      'assets/vendors/jquery.min.js',
      'assets/vendors/jquery-ui/jquery-ui.min.js',
      'assets/vendors/slick/slick.min.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'propertytype')
        this.router.navigate(['/' + destpage], {
          queryParams: { propertytype: paramvalue },
        });
      if (paramname == 'propertyId') {
        this.router.navigate(['/' + destpage], {
          queryParams: { propertyId: paramvalue },
        });
      }
    }
  }

  getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  private getAllProperties(id) {
    this.propertiesservice
      .getAllProperties()
      .pipe()
      .subscribe((data) => {
        let dataUnavailable = data.filter((el) => {
          return el.PropertySellType !== 'Unavailable';
        });
        let filter = dataUnavailable.filter((el) => {
          return (
            el.status !== 'Draft' &&
            el.status === 'AvailableForSell' &&
            !el.IsDeleted
          );
        });
        let filteredBooster = filter.filter((el) => {
          return el.Booster === true && el.MonthBooster > 0;
        });
        this.allBooster = filteredBooster;
        let filteredHighlight = filter.filter((el) => {
          return el.Highlight === true && el.MonthHighlight > 0;
        });
        let eventList = new Set([...filteredBooster, ...filteredHighlight]);
        let arr = [...eventList];
        this.list = [];
        let random = [];
        random = this.getMultipleRandom(arr, 4);
        this.list = random;
        this.listHighlight = filteredHighlight;
        if (this.list.length > 0) {
          this.list.forEach((el) => {
            this.queryProperties(el.Id);
          });
        } else {
          this.ngxService.stopBackground('loadHomePage');
          this.loading = false;
        }
      });
  }
  queryProperties(id) {
    this.propertiesservice
      .getQueryPropertyById(id)
      .pipe()
      .subscribe(
        (data) => {
          let dataArr = [data];
          this.listProp(dataArr)
        },
        (error) => {}
      );
  }

  beforeChange(event) {
    this.currentSlide = event.nextSlide;
  }

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }

  listProp(filterByDiffId) {
    var imageurltemp: string = '';

    var DisplayOrder: string = '';

    try {
      DisplayOrder = JSON.stringify(filterByDiffId[0].Images[0].DisplayOrder);

      if (JSON.stringify(filterByDiffId[0].Images[0].DisplayOrder) == '0') {
        imageurltemp = `${environment.apiUrl}`.concat(
          JSON.stringify(filterByDiffId[0].Images[0].Url),
          ''
        );
      } else {
        imageurltemp = 'assets/images/photonotavailable.jfif';
      }
    } catch (Error) {
      imageurltemp = 'assets/images/photonotavailable.jfif';
    }

    imageurltemp = imageurltemp.replace('"', '').replace('"', '');

    var SquareFeet: string = '';

    try {
      SquareFeet = JSON.stringify(
        filterByDiffId[0].ExtendedProperties.SquareFeet.IntValue
      );
    } catch (Error) {
      SquareFeet = '0';
    }

    var Baths: string = '';

    try {
      Baths = JSON.stringify(
        filterByDiffId[0].ExtendedProperties.Baths.IntValue
      );
    } catch (Error) {
      Baths = '0';
    }

    var Beds: string = '';

    try {
      Beds = JSON.stringify(filterByDiffId[0].ExtendedProperties.Beds.IntValue);
    } catch (Error) {
      Beds = '0';
    }

    var MoveInDate: string = '';

    try {
      MoveInDate = JSON.stringify(
        filterByDiffId[0].ExtendedProperties.MoveInDate.DateTimeValue
      );
    } catch (Error) {
      MoveInDate = '0';
    }

    var LocationId: string = '';

    try {
      LocationId = JSON.stringify(filterByDiffId[0].LocationId);
    } catch (Error) {
      LocationId = '0';
    }

    var CreatedDate: string = '';

    try {
      CreatedDate = JSON.stringify(filterByDiffId[0].CreatedDate);
    } catch (Error) {
      CreatedDate = '0';
    }

    var Garage: string = '';
    let i = this.listHighlight.find((el) => {
      if (el.Id === filterByDiffId[0].Id) {
        return true;
      } else {
        return false;
      }
    });

    try {
      Garage = JSON.stringify(
        filterByDiffId[0].ExtendedProperties.Features.CheckboxValues
      );
      if (Garage.includes('Garage')) Garage = 'Yes';
      else Garage = 'No';
    } catch (Error) {
      Garage = 'No';
    }

    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = imageurltemp;
    link.as = 'image';
    head.appendChild(link);
    this.featuredpropertieslist.push({
      idcategory: parseInt(
        JSON.stringify(filterByDiffId[0].Category.Id)
          .replace('"', '')
          .replace('"', '')
      ),
      namecategory: JSON.stringify(filterByDiffId[0].Category.Name)
        .replace('"', '')
        .replace('"', ''),
      SquareFeet: SquareFeet.replace('null', '0'),
      Baths: Baths.replace('null', '0'),
      Beds: Beds.replace('null', '0'),
      MoveInDate: MoveInDate.replace('"', '').replace('"', ''),
      OwnerName: JSON.stringify(filterByDiffId[0].OwnerName)
        .replace('"', '')
        .replace('"', ''),
      Name: JSON.stringify(filterByDiffId[0].Name)
        .replace('"', '')
        .replace('"', ''),
      ShortDescription: JSON.stringify(filterByDiffId[0].ShortDescription)
        .replace('"', '')
        .replace('"', ''),
      FullDescription: JSON.stringify(filterByDiffId[0].FullDescription)
        .replace('"', '')
        .replace('"', ''),
      LocationId: LocationId.replace('"', '').replace('"', ''),
      LocationDesc: '',
      CreatedDate: CreatedDate.replace('"', '').replace('"', ''),
      CreatedBy: JSON.stringify(filterByDiffId[0].CreatedBy)
        .replace('"', '')
        .replace('"', ''),
      imageurl: imageurltemp,
      Price: JSON.stringify(filterByDiffId[0].Price)
        .replace('"', '')
        .replace('"', ''), //formatter.format(parseInt(JSON.stringify(filterByDiffId[0].Price).replace('"','').replace('"',''))),//JSON.stringify(filterByDiffId[0].Price).replace('"','').replace('"',''),
      Garage: Garage,
      PropertySellType: JSON.stringify(filterByDiffId[0].PropertySellType)
        .replace('"', '')
        .replace('"', ''),
      IdProperty: JSON.stringify(filterByDiffId[0].Id),
      queryparam: JSON.stringify(filterByDiffId[0].Id),
    });

    this.isDataAvailable = true;
    if (this.featuredpropertieslist.length === this.list.length) {
      this.loading = false;
    }
    this.ngxService.stopBackground('loadHomePage');
  }
}
