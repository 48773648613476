<section class="py-8" data-animated-id="2">
  <div class="container">
    <div class="row ml-xl-0 mr-xl-n6">
      <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
        <div class="primary-sidebar-inner" style="position: static; left: auto; width: 370px;">
          <div class="px-6 pb-6">

            <ul class="list-group list-group-no-border" style="padding: 10px 15px; background-color: white;">
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary" routerLink="/glossary">
                  <span class="lh-29">Glossary</span>
                </a>
              </li>
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary" routerLink="/accountandprofile">
                  <span class="lh-29">Account and Profile</span>

                </a>
              </li>
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary">
                  <span class="lh-29"><strong>Signing Up</strong></span>

                </a>
              </li>
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary" routerLink="/showcasingyourpropertyholdings">
                  <span class="lh-29">Showcasing your property holdings</span>

                </a>
              </li>
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary" routerLink="/updatingratingsandrentalhistory">
                  <span class="lh-29">Updating ratings and rental history</span>

                </a>
              </li>
            </ul>
          </div>


        </div>
      </div>
      <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
        <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

          <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
            data-animate="fadeInDown">Signing Up</h1>


          <p style="text-align: justify;" class="mb-4 lh-214 pt-5">The initial step that a user must take in order to gain access to the system.</p>
        </div>


      </div>


    </div>
  </div>
</section>
