        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              VIEW CONTRACT
            </h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div [innerHtml]="this.mycontentWithReplacedVariables"></div>            
          </div>
          <div class="modal-footer">
            <!-- <ng-container *ngIf="showEditor">
              <button type="button" class="btn btn-primary" (click)="editContract()">Edit Contract</button>
              <button type="button" class="btn btn-accent" (click)="previewContract()">Preview Contract </button>
            </ng-container>
            <ng-container *ngIf="!showEditor">
              <button type="button" class="btn btn-primary" (click)="editContract()">Edit Contract </button>
              <button type="button" class="btn btn-accent" (click)="exportHtmlToPDF()">Download PDF File</button>
            </ng-container>
            
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="saveContract()">Close</button> -->
          </div>
  