<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>

  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">
        <div *ngIf="this.openModal === true" class="modal-background">
          <div class="modal-buy shadow">
            <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
              Buy Boosts
            </h3>
            <button (click)="closeModal()" class="close-icon">
              <img src="../../assets/images/close-icon.png" alt="Close icon" />
            </button>
            <form *ngIf="this.stepPayment === 'add-card'" (submit)="submitChanges()" style="margin-bottom: 0"
              type="submit">
              <div [ngClass]="{
                'radius': this.total > this.cash,
                'radius-all': this.total < this.cash
              }" class="listing-type-boosts">
                <h4 class="buy-title">Buy Listing Boosts</h4>
                <div (click)="checkBoosts()" *ngIf="this.filteredItem[0].Booster !== true"
                  class="modal-select-boosters blue-box">
                  <div class="terms-section">
                    <div class="checkbox">
                      <div class="checked" *ngIf="this.checkedBoost === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                  </div>
                  <p>
                    There is a special spot at top of each listing page that will randomly cycle
                    through the pool of "top" listings. This will highly improve your listing views.
                  </p>
                </div>

                <div (click)="checkHighlight()" *ngIf="this.filteredItem[0].Highlight !== true"
                  class="modal-select-boosters purple-box">
                  <div class="terms-section">
                    <div class="checkbox">
                      <div class="checked" *ngIf="this.checkedHigh === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
                  </div>
                  <p>
                    Your property will appear with a visual highlight to make it stand out to
                    potential interested clients.
                  </p>
                </div>

                <div class="mobile-direction"
                  style="display: flex; justify-content: space-between; align-items: center; margin-top: 12px;">
                  <div class="same-styles">
                    <p style="margin-bottom: 0;">Duration month</p>
                    <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                      [value]="this.valueMonth" (input)="changeMonthValue($event)" id="month">
                  </div>
                  <div class="number-boost">
                    <p style="margin-bottom: 0;">Total</p>
                    <span>{{this.total.toFixed(2)}} RCT</span>
                  </div>
                </div>
              </div>
              <div *ngIf="this.total > this.cash" class="warn-orange">
                <span>insufficient RCT on your Wallet</span>
                <a routerLink="/wallet" class="user-cash buy-more-button">Buy more RCT
                  <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
                </a>
              </div>
              <div class="warning warning-slots">
                <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
                <p style="width: 100%; text-align: justify;">
                  By completing the purchase, the funds will be taken from your wallet.
                </p>
              </div>
              <div [ngClass]="{
                'top-short-distance': this.total > this.cash,
                'top-distance': this.total < this.cash
              }">
                <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new boosts will be
                  available until:</p>
                <p class="heading-3 available-date">{{this.dateavailable}}
                </p>
              </div>
              <div style="
                    display: flex;
                    justify-content: center;
                    margin-right: 34px;
                  ">
                <button [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : false"
                  class="submit-button">SUBMIT & PAY</button>
              </div>
            </form>
          </div>
        </div>
        <main id="content" class="bg-gray-01">
          <div style="padding-top: 0 !important; margin-top: 15px;"
            class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" data-animated-id="1">
            <div style="align-items: center;" class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15" *ngIf="this.isuserlandlord()">My Properties<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.totalproperties}}</span>
                </h2>
                <h2 class="mb-0 text-heading fs-22 lh-15" *ngIf="!this.isuserlandlord()">My Leases<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.totalproperties}}</span>
                </h2>

              </div>
              <div class="form-inline justify-content-md-end mx-n2">

                <form style="margin-right: 10px; padding:  0 !important; margin-bottom: 0 !important;"
                  class="form-inline py-2" *ngIf="this.isuserlandlord()">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" name="showdeleted" id="showdeleted"
                      [checked]="showonlyactive" (change)="onshowdeletedchange()">
                    <label style="padding-top: 4px;" class="custom-control-label" for="showdeleted">Show only active
                    </label>
                  </div>
                  <label class="sr-only" for="inlineFormInputName2">Name</label>
                  <input type="search" style="margin-bottom: 0 !important;" class="form-control mb-2 mr-sm-2"
                    id="inlineFormInputName2" placeholder="Search" id="freetextfilter" (input)="searchAProperty($event)"
                    #s>

                  <label class="sr-only" for="inlineFormInputGroupUsername2">Status</label>
                  <div style="margin-bottom: 0;" class="input-group mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Status</div>
                    </div>
                    <select class="form-control" id="exampleFormControlSelect1" name="sortby" #t
                      (change)="onstatuschanged(t.value)">
                      <option [ngValue]="1" value="1" selected>All</option>
                      <option [ngValue]="2" value="2">Unpublished</option>
                      <option [ngValue]="3" value="3">Published</option>
                      <option [ngValue]="4" value="4">Rented</option>
                    </select>
                  </div>

                  <button class="btn btn-outline-primary mb-2" type="submit"
                    (click)="searchevent(s.value)">Search</button>
                </form>
              </div>
            </div>
            <div *ngIf="this.list.length > 0" class="table-responsive to-list-desktop">
              <table class="table table-hover bg-white border rounded-lg">
                <thead class="thead-sm thead-black head-responsive">
                  <tr>
                    <th scope="col" class="border-top-0 px-6 pt-5 pr-4 pb-4">Listing title</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Date Published</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4"><span class="mr-5">Status</span></th>
                    <th scope="col" class="border-top-0 pt-5 pb-4" style="text-align: center;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-hover-xs-2 bg-hover-white prop-responsive"
                    *ngFor="let property of this.list | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                    <td class="align-middle pt-6 pb-4 px-6">
                      <div class="media">
                        <div class="w-120px mr-4 position-relative">
                          <a (click)="navigateToPropertyDetails(property.Id.toString())"
                            class="text-dark hover-primary">
                            <img style="display:block;width: 110px;height: auto;max-height: 80px;"
                              [src]="toPropertyPhoto(property)" alt="">
                          </a>
                          <span
                            class="badge badge-indigo position-absolute pos-fixed-top">{{property.PropertySellType === 'ForRent' ? 'For Rent' : property.PropertySellType}}</span>
                        </div>
                        <div class="media-body">
                          <a class="text-dark display-title hover-primary" style="cursor: pointer">
                            <ng-container *ngIf="property.status == 'AvailableForSell'">
                              <div class="arrow" *ngIf="property.Booster === true">
                                <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>
                              <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                                *ngIf="property.Booster !== true">
                                <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>

                              <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                                *ngIf="property.Highlight !== true">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                              <div class="arrow star" *ngIf="property.Highlight === true">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="property.status == 'Draft' && property.Booster === true && property.Highlight === true">
                              <div class="arrow" *ngIf="property.Booster === true">
                                <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>
                              <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                                *ngIf="property.Booster !== true">
                                <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>

                              <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                                *ngIf="property.Highlight !== true">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                              <div class="arrow star" *ngIf="property.Highlight === true">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="property.status == 'Draft' && property.Booster !== true && property.Highlight === true">
                              <div class="arrow" *ngIf="property.Booster === true">
                                <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>
                              <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                                *ngIf="property.Booster !== true">
                                <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>

                              <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                                *ngIf="property.Highlight !== true">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                              <div class="arrow star" *ngIf="property.Highlight === true">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="property.status == 'Draft' && property.Booster === true && property.Highlight !== true">
                              <div class="arrow" *ngIf="property.Booster === true">
                                <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>
                              <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                                *ngIf="property.Booster !== true">
                                <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                              </div>

                              <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                                *ngIf="property.Highlight !== true">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                              <div class="arrow star" *ngIf="property.Highlight === true">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </div>
                            </ng-container>
                            <h5 (click)="navigateToPropertyDetails(property.Id.toString())" class="fs-16 mb-0 lh-18">
                              {{property.Name}}</h5>
                          </a>
                          <p class="mb-1 font-weight-500"></p>
                          <span (click)="navigateToPropertyDetails(property.Id.toString())"
                            class="text-heading lh-15 font-weight-bold fs-17">${{property.Price}}</span>
                          <span (click)="navigateToPropertyDetails(property.Id.toString())"
                            class="text-gray-light">/month</span>
                        </div>
                      </div>
                    </td>
                    <td (click)="navigateToPropertyDetails(property.Id.toString())" class="align-middle">
                      {{ property.CreatedDate | date: 'MM/dd/yyyy' }}</td>
                    <td (click)="navigateToPropertyDetails(property.Id.toString())" class="align-middle">
                      <ng-container *ngIf="property.status=='AvailableForSell' && property.PropertySellType !== 'Unavailable'">
                        <span class="badge text-capitalize font-weight-normal fs-12 badge-green">Published</span>
                      </ng-container>
                      <ng-container *ngIf="property.PropertySellType === 'Unavailable'">
                        <span class="badge text-capitalize font-weight-normal fs-12 badge-green">Rented</span>
                      </ng-container>
                      <ng-container *ngIf="property.status=='Draft' && property.PropertySellType !== 'Unavailable'">
                        <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">Unpublished</span>
                      </ng-container>

                      <ng-container *ngIf="property.status=='Cancelled' && property.PropertySellType !== 'Unavailable'">
                        <span
                          class="badge text-capitalize font-weight-normal fs-12 badge-pink">{{property.status}}</span>
                      </ng-container>
                    </td>
                    <ng-container *ngIf="this.isuserlandlord()">
                    </ng-container>

                    <td class="align-middle actions-responsive">
                      <a (click)="navigateToPropertyDetails(property.Id.toString())"
                        class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">View
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span></a>
                      <a (click)="navigateToPropertyEdit(property.Id.toString())" title="Edit"
                        class="d-inline-block fs-18 text-muted hover-primary mr-5" style="cursor: pointer"
                        data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                      <ng-container *ngIf="property.Status!='Cancelled'">
                        <a (click)="deletepropertyConfirmation(property.Id.toString())" data-toggle="tooltip"
                          title="Cancel Publish" class="d-inline-block mr-5 fs-18 text-muted hover-primary"
                          data-original-title="Delete" style="cursor: pointer"><i class="fal fa-trash-alt"></i></a>
                      </ng-container>
                      <ng-container *ngIf="property.Status!='Cancelled' && property.status=='Draft' && property.PropertySellType !== 'Unavailable' && property.IsDeleted === false">
                        <a (click)="updateSlotStatus(property.Id.toString(), true)" data-toggle="tooltip"
                          title="Publish property in slot" class="d-inline-block fs-18 text-muted hover-primary"
                          data-original-title="Publish" style="cursor: pointer">
                          <fa-icon [icon]="faUpload"></fa-icon>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="property.Status!='Cancelled' && property.status=='AvailableForSell' && property.PropertySellType !== 'Unavailable' && property.IsDeleted === false">
                        <a (click)="updateSlotStatus(property.Id.toString(), false)" data-toggle="tooltip"
                          title="Unpublish" class="d-inline-block fs-18 text-muted hover-primary"
                          data-original-title="Unpublish" style="cursor: pointer">
                          <fa-icon [icon]="faX"></fa-icon>
                        </a>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="this.list.length > 0" class="to-list-mobile">
              <div class="cards-responsive">
                <div class="card-body" style="padding: 10px; background-color: white; border: 3px;"
                  *ngFor="let property of this.list | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                  <div class="align-middle pt-6 pb-4 px-6 no-padding">
                    <div class="media">
                      <div class="w-120px mr-4 position-relative">
                        <a (click)="navigateToPropertyDetails(property.Id.toString())" class="text-dark hover-primary">
                          <img class="prop-photo" style="display:block;width: 110px;height: auto;max-height: 80px;"
                            [src]="toPropertyPhoto(property)" alt="">
                        </a>
                        <span
                          class="badge badge-indigo position-absolute pos-fixed-top">{{property.PropertySellType === 'ForRent' ? 'For Rent' : property.PropertySellType}}</span>
                      </div>
                      <div class="media-body">
                        <a class="text-dark display-title hover-primary" style="cursor: pointer">
                          <h5 (click)="navigateToPropertyDetails(property.Id.toString())" class="fs-16 mb-0 lh-18">
                            {{property.Name}}</h5>
                        </a>
                        <p class="mb-1 font-weight-500"></p>
                        <div class="prop-priceBoost">
                          <div style="margin-right: 10px;">
                            <span (click)="navigateToPropertyDetails(property.Id.toString())"
                              class="text-heading lh-15 font-weight-bold fs-17">${{property.Price}}</span>
                            <span (click)="navigateToPropertyDetails(property.Id.toString())"
                              class="text-gray-light">/month</span>
                          </div>
                          <ng-container *ngIf="property.status == 'AvailableForSell'">
                            <div class="arrow" *ngIf="property.Booster === true">
                              <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>
                            <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                              *ngIf="property.Booster !== true">
                              <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>

                            <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                              *ngIf="property.Highlight !== true">
                              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                            <div class="arrow star" *ngIf="property.Highlight === true">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="property.status == 'Draft' && property.Booster === true && property.Highlight === true">
                            <div class="arrow" *ngIf="property.Booster === true">
                              <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>
                            <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                              *ngIf="property.Booster !== true">
                              <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>

                            <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                              *ngIf="property.Highlight !== true">
                              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                            <div class="arrow star" *ngIf="property.Highlight === true">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="property.status == 'Draft' && property.Booster !== true && property.Highlight === true">
                            <div class="arrow" *ngIf="property.Booster === true">
                              <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>
                            <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                              *ngIf="property.Booster !== true">
                              <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>

                            <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                              *ngIf="property.Highlight !== true">
                              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                            <div class="arrow star" *ngIf="property.Highlight === true">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="property.status == 'Draft' && property.Booster === true && property.Highlight !== true">
                            <div class="arrow" *ngIf="property.Booster === true">
                              <fa-icon class="arrow-true to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>
                            <div (click)="handleOpenBuySlotsModal(property.Id, 'boosted')" class="arrow"
                              *ngIf="property.Booster !== true">
                              <fa-icon class="arrow-false to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                            </div>

                            <div (click)="handleOpenBuySlotsModal(property.Id, 'highlight')" class="arrow star"
                              *ngIf="property.Highlight !== true">
                              <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                            <div class="arrow star" *ngIf="property.Highlight === true">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div (click)="navigateToPropertyDetails(property.Id.toString())"
                    class="align-middle weight-responsive">
                    <span>Date Published: </span>{{ property.CreatedDate | date: 'MM/dd/yyyy' }}</div>
                  <div (click)="navigateToPropertyDetails(property.Id.toString())"
                    class="align-middle actions-responsive">
                    <ng-container *ngIf="property.status=='AvailableForSell'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-green">Published</span>
                    </ng-container>
                    <ng-container *ngIf="property.status=='Draft'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">Unpublished</span>
                    </ng-container>

                    <ng-container *ngIf="property.status=='Cancelled'">
                      <span class="badge text-capitalize font-weight-normal fs-12 badge-pink">{{property.status}}</span>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="this.isuserlandlord()">
                  </ng-container>

                  <div class="align-middle actions-responsive">
                    <a (click)="navigateToPropertyDetails(property.Id.toString())"
                      class="btn btn-sm bg-hover-light border mr-5" style="cursor: pointer">View
                      <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span></a>
                    <a (click)="navigateToPropertyEdit(property.Id.toString())" title="Edit"
                      class="d-inline-block fs-18 text-muted hover-primary mr-5" style="cursor: pointer"
                      data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                    <ng-container *ngIf="property.Status!='Cancelled'">
                      <a (click)="deletepropertyConfirmation(property.Id.toString())" data-toggle="tooltip"
                        title="Cancel Publish" class="d-inline-block mr-5 fs-18 text-muted hover-primary"
                        data-original-title="Delete" style="cursor: pointer"><i class="fal fa-trash-alt"></i></a>
                    </ng-container>
                    <ng-container *ngIf="property.Status!='Cancelled' && property.status=='Draft' && property.IsDeleted === false">
                      <a (click)="updateSlotStatus(property.Id.toString(), true)" data-toggle="tooltip"
                        title="Publish property in slot" class="d-inline-block fs-18 text-muted hover-primary"
                        data-original-title="Publish" style="cursor: pointer">
                        <fa-icon [icon]="faUpload"></fa-icon>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="property.Status!='Cancelled' && property.status=='AvailableForSell' && property.IsDeleted === false">
                      <a (click)="updateSlotStatus(property.Id.toString(), false)" data-toggle="tooltip"
                        title="Unpublish" class="d-inline-block fs-18 text-muted hover-primary"
                        data-original-title="Unpublish" style="cursor: pointer">
                        <fa-icon [icon]="faX"></fa-icon>
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="this.empty" style="font-size: 18px;">
              No leased property!
            </div>
            <div *ngIf="this.list.length > 0" style="margin-top: 2rem;" class="d-flex justify-content-center">
              <div class="pagination-desktop">
                <pagination-template #p="paginationApi" (pageChange)="page = $event">
                  <div class="custom-pagination">
                    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                      <span (click)="p.previous()">
                        < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                          [class.current]="p.getCurrent() === page.value">
                          <span (click)="p.setCurrent(page.value)"
                            *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                          <div class="pages" *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                          </div>
                    </div>
                    <div class="pagination-next" [class.disabled]="p.isLastPage()">
                      <span (click)="toNext(p)"> > </span>
                    </div>
                  </div>
                </pagination-template>
              </div>
              <div class="pagination-mobile">
                <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
