<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content" style="margin-bottom: 80px;">
        <main id="content" class="bg-gray-01">
          <!-- <a routerLink="/emailconfiguration" style="cursor: pointer;color: #31499c;"
            class="lh-26 font-weight-500 hover-info">Email
          </a> -->
          <div class="container" data-select2-id="7">
            <div class="row" data-select2-id="6">
              <div class="col-11" style="margin-top: 15px;">
                <div class="section-title d-flex align-items-center">
                  <h2>My profile</h2>
                  <span class="badge mr-xl-2 mt-3 mt-sm-0"
                    style="background-color: rgb(102 113 119 / 93%);color:white;">
                    <strong>Roles:</strong> {{this.authenticationService.customizedroles}}
                  </span>
                </div>

                <form class="form" [formGroup]="myprofileForm" (ngSubmit)="onSubmit()">



                  <div style="display: flex;justify-content: center; margin-bottom: 20px;">
                    <div class="profile-img">
                      <img [src]="this.url" id="profileimage" name="profile" alt="profile image"
                        style="height: 150px; width: 150px;border-radius: 50%" />
                      <div class="file">
                        <!-- <label for=""></label> -->
                        <fa-icon style="cursor: pointer;" [icon]="faPenToSquare"></fa-icon>
                        <input type="file" name="file" id="imgfile" (change)="onFileSelect($event)" value="" />
                      </div>

                    </div>


                  </div>



                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>First name</label>
                      <input type="text" formControlName="firstname" id="firstname" name="firstname"
                        class="form-control" [(ngModel)]="firstname" (change)="onChangefirstname($event)">
                    </div>
                    <div class="form-group col-md-6">
                      <label>Last name</label>
                      <input type="text" class="form-control" formControlName="lastname" id="lastname" name="lastname"
                        [(ngModel)]="lastname" (change)="onChangelastname($event)">
                    </div>
                    <div class="form-group col-md-6">
                      <label>Email</label>
                      <input type="text" class="form-control" formControlName="email" id="email" name="email"
                        [(ngModel)]="email" readonly>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Phone number</label>
                      <input type="text" class="form-control" formControlName="phonenumber" id="phonenumber"
                        name="phonenumber" [(ngModel)]="phonenumber" (change)="onChangephonenumber($event)">
                    </div>
                    <div class="form-group col-md-6">
                      <label>Location</label> (<a (click)="openGoogelMapsModal()" class="text-heading hover-primary"
                        style="cursor: pointer"><u>set location</u></a>)
                      <ng-container *ngIf="this.islocationcheked">
                        <a style="padding-left:20px;" (click)="clearlocation()">
                          <label style="cursor: pointer;color:#0ca5b1">clear location</label></a>
                      </ng-container>
                      <input type="text" class="form-control" id="address" name="address"
                        value="{{this.address}}" readonly>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Birthday</label>
                      <!--<input type="date" class="form-control" #dp="angular-mydatepicker" angular-mydatepicker [(ngModel)]="birthday" value= "22/02/1983" formControlName="birthday'"  (change)="onChangebirthday($event)" >-->
                      <div class="input-group">
                        <input class="form-control" placeholder="Select a date" angular-mydatepicker name="myDate"
                          id="myDate" [(ngModel)]="modelbirthdate" formControlName="myDate" [options]="myOptions"
                          #dp="angular-mydatepicker" (click)="dp.toggleCalendar()" (dateChanged)="onDateChanged($event)"
                          readonly />
                        <div class="input-group-append">

                        </div>
                        <div class="input-group-append">
                          <button type="button" class="btn btn-primary" (click)="dp.toggleCalendar()">
                            <i class="fa" style="font-size:18px;color:#0ca5b1;">&#xf133;</i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr class="my-5">
                    <div class="col-md-12">
                      <ng-container *ngIf="isSavedmyprofile">

                        <div style="position: relative;" class="alert alert-success" role="alert">
                          <div (click)="this.closeSave()" style="position: absolute;" class="close-success">
                            <fa-icon [icon]="faXmark"></fa-icon>
                          </div>
                          <h4 class="alert-heading">Saved!</h4>
                          <p>Success! Your profile has been updated!</p>
                        </div>
                      </ng-container>
                      <button class="btn btn-primary" type="submit">Save Changes</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
