import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { propertiesService, AuthenticationService } from '../services';
import { environment } from './../../environments/environment';
import { MapsAPILoader } from '@agm/core';
import { textChangeRangeIsUnchanged } from 'typescript';
import { ContactlandlordComponent } from '../contactlandlord/contactlandlord.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { GenerictoastmessageComponent } from '../generictoastmessage/generictoastmessage.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { userInfo } from 'os';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { D } from 'angular-mydatepicker';

@Component({
  selector: 'app-servicedetail',
  templateUrl: './servicedetail.component.html',
  styleUrls: ['./servicedetail.component.css'],
})
export class ServicedetailComponent implements OnInit, AfterViewInit {
  galleryOptions: NgxGalleryOptions[];
  public galleryImages = new Array<NgxGalleryImage>();

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  radius: number = 1500;

  private serviceId: string = '';
  public locationdesc: string = '';
  public apiUrl: string = '';
  public idUser: string = '';
  public userDetail: any = {};
  public totalfotostoshow: number = 0;
  public sliceReviews: number = 3;

  public filtersLoaded: Promise<boolean>;

  public featureslist = new Array<{ feature: string }>();
  public imageslist = new Array<{ imageurl: string }>();
  public istheownerloggeduser: boolean = false;
  public idEvent: string;

  public ratings: any[] = [];
  public ratingLength: number;
  public ratingCalc: number = 4;
  public reviews = [];
  public serviceReviews = [];

  public propertyDetails = [];
  public amenities: any[] = [];
  public similarServices = [];
  public serviceUser = [];

  public date: any[] = [];
  public fakeDate = [
    {
      date: '20/12/2022',
    },
    {
      date: '14/09/2022',
    },
    {
      date: '10/05/2023',
    },
    {
      date: '03/02/2023',
    },
    {
      date: '05/05/2023',
    },
    {
      date: '12/02/2023',
    },
    {
      date: '24/04/2023',
    },
    {
      date: '23/05/2023',
    },
    {
      date: '27/03/2023',
    },
    {
      date: '21/02/2023',
    },
  ];
  public ratingArr = [
    {
      moreThan: 0.9,
      inBetweenLess: 0,
      inBetweenMore: 0.9,
      lessThan: 0,
    },
    {
      moreThan: 1.9,
      inBetweenLess: 1.1,
      inBetweenMore: 1.9,
      lessThan: 1,
    },
    {
      moreThan: 2.9,
      inBetweenLess: 2.1,
      inBetweenMore: 2.9,
      lessThan: 2,
    },
    {
      moreThan: 3.9,
      inBetweenLess: 3.1,
      inBetweenMore: 3.9,
      lessThan: 3,
    },
    {
      moreThan: 4.9,
      inBetweenLess: 4.1,
      inBetweenMore: 4.9,
      lessThan: 4,
    },
  ];

  faStarHalf = faStarHalf;
  faStar = faStar;

  public similarpropertieslist = new Array<{
    idcategory: number;
    namecategory: string;
    SquareFeet: string;
    Baths: string;
    Beds: string;
    MoveInDate: string;
    OwnerName: string;
    OwnerId: number;
    Name: string;
    ShortDescription: string;
    FullDescription: string;
    LocationId: string;
    LocationDesc: string;
    CreatedDate: string;
    CreatedBy: string;
    imageurl: string;
    Price: string;
    Garage: string;
    PropertySellType: string;
    IdProperty: string;
    queryparam: string;
  }>();

  loadAPI: Promise<any>;

  public isuserlogged = false;
  public userid: string;
  public userfirstname: string;
  public chatname: string;
  public useridprofile: string;
  public apiurl: string = '';
  constructor(
    private route: ActivatedRoute,
    private propertiesservice: propertiesService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private authservice: AuthenticationService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService
  ) {
    this.loadScripts();
  }

  ngOnInit(): void {
    this.apiurl = environment.apiUrl;
    this.ngxService.startBackground('loadmyservice');

    this.isuserlogged = this.authservice.isUserLogged;

    if (this.isuserlogged) {
      // get user info
      const userinfo = localStorage.getItem('roundcombuserdetail');

      let UserjsonObject = JSON.parse(userinfo);

      this.userfirstname = UserjsonObject.FirstName;
      this.userid = UserjsonObject.Id;
      this.useridprofile = UserjsonObject.userIdProfile;
    } else {
      this.userid = '0';
      this.useridprofile = '0';
    }

    this.route.queryParams.subscribe((queryparams: Params) => {
      this.serviceId = queryparams.serviceId;
    });
    this.getSimilarServices();
    this.getServices();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });
    //
  }

  ngAfterViewInit() {
    this.ngxService.stopBackground('loadproperty');
  }
  ///////////////////////////////////////// functions  ////////////////////////////////////////////////

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  loadMoreReviews() {
    this.sliceReviews = this.sliceReviews + 3;
  }

  lengthRating(rating) {
    let number = Number(rating);
    if (isNaN(number)) {
      return true;
    } else {
      return false;
    }
  }

  getUserRating(id) {
    var totalRating = 0;
    this.propertiesservice
      .getUserRating(id)
      .pipe()
      .subscribe((data) => {
        this.ratingLength = data.length;
        let filter = data.filter((el) => {
          return el.serviceId === this.propertyDetails[0].id;
        });
        this.reviews = filter;
        this.serviceReviews = filter;
        console.log(this.serviceReviews)
        if (this.ratingLength > 0) {
          data.forEach((element) => {
            totalRating = totalRating + element.rating;
            let date = new Date(element.create_date * 1000);
            element.create_date = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
          });
          let calc = totalRating / this.ratingLength;
          let checkCalc = calc.toString().includes('.')
            ? Number(calc).toFixed(1)
            : calc;
          this.ratingCalc = Number(checkCalc);
        } else {
          this.ratingCalc = 0;
        }
      });
  }

  getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  private getUser() {
    this.authservice
      .getAnonymousProfileUserInfo(this.propertyDetails[0].iduser.trim())
      .pipe()
      .subscribe((result) => {
        this.userDetail = result;
        this.apiUrl = environment.apiUrl;
        let random = this.getMultipleRandom(this.fakeDate, 1);
        this.date = random;
      });
  }
  async getSimilarServices() {
    await this.propertiesservice
      .getAllServices()
      .pipe()
      .subscribe((data) => {
        let filteredServices = data.filter((el) => {
          return el.id !== Number(this.serviceId);
        });
        let random = this.getMultipleRandom(filteredServices, 3);
        this.similarServices = random;
        this.ngxService.stopBackground('loadmyservice');
      });
  }
  async getServices() {
    this.propertyDetails = [];
    await this.propertiesservice
      .getServiceById(this.serviceId)
      .toPromise()
      .then((data) => {
        data.forEach((el) => {
          let date = new Date(el.create_date * 1000);
          return (el.create_date = date.toLocaleDateString('en-US'));
        });
        let filterByStatus = data.filter((el) => {
          return (
            el.status === 'AvailableForSell' && el.id !== Number(this.serviceId)
          );
        });
        this.imageslist = [];
        this.galleryImages = [];
        this.similarServices = this.getMultipleRandom(filterByStatus, 3);
        this.propertyDetails.splice(0, this.propertyDetails.length);
        this.imageslist.splice(0, this.imageslist.length);
        this.galleryImages.splice(0, this.galleryImages.length);
        this.galleryOptions = [
          {
            width: '540px',
            height: '400px',
            thumbnailsColumns: 4,
            imageAnimation: NgxGalleryAnimation.Slide,
            imageInfinityMove: true,
          },
          // max-width 800
          {
            breakpoint: 800,
            width: '100%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 20,
            thumbnailsMargin: 20,
            thumbnailMargin: 20,
          },
          // max-width 400
          {
            breakpoint: 400,
            preview: false,
          },
        ];
        this.propertyDetails = data.filter((el) => {
          return el.id === Number(this.serviceId);
        });
        this.chatname = this.propertyDetails[0].title;
        this.propertyDetails[0].rating = Number(this.propertyDetails[0].rating);
        this.getUserRating(this.propertyDetails[0].iduser);
        this.idUser = this.propertyDetails[0].iduser.trim();
        this.getUser();
        if (Object.keys(this.propertyDetails[0].amenities).length > 0) {
          this.amenities = JSON.parse(this.propertyDetails[0].amenities);
        }

        this.imageslist.push({
          imageurl: `${environment.apiUrl}/` + this.propertyDetails[0].imageOne,
        });
        if (this.propertyDetails[0].ImageTwo !== undefined) {
          this.imageslist.push({
            imageurl:
              `${environment.apiUrl}/` + this.propertyDetails[0].ImageTwo,
          });
        }
        if (this.propertyDetails[0].ImageThree !== undefined) {
          this.imageslist.push({
            imageurl:
              `${environment.apiUrl}/` + this.propertyDetails[0].ImageThree,
          });
        }
        for (var i = 0; i < this.imageslist.length; i++) {
          var imageurltemp: string = this.imageslist[i].imageurl;
          this.galleryImages.push({
            small: imageurltemp,
            medium: imageurltemp,
            big: imageurltemp,
          });
        }
        if (
          Number(this.propertyDetails[0].iduser) == Number(this.userid) ||
          Number(this.propertyDetails[0].iduser) == Number(this.useridprofile)
        )
          this.istheownerloggeduser = true;
      })
      .catch((e) => console.error(e));
  }

  navigateToPropertyDetails(serviceId: string) {
    this.router.navigate(['/servicedetail'], { queryParams: { serviceId } });
    this.getUser();
    this.getServices();
    this.ngOnInit();
  }

  getlocationById(locationid: string) {
    this.propertiesservice
      .getQueryLocationById(locationid)
      .pipe()
      .subscribe(
        (data) => {
          this.locationdesc = data.FormattedAddress;
          this.latitude = data.Latitude;
          this.longitude = data.Longitude;
          this.chatname = data.Address;
          //load Places Autocomplete
          this.mapsAPILoader.load().then(() => {
            //this.setCurrentLocation();
            this.getAddress(this.latitude, this.longitude);
            this.geoCoder = new google.maps.Geocoder();
          });
        },
        (error) => {}
      );
  }

  private getInsertedDate(inserteddate: string) {
    var dt: Date = new Date(inserteddate);
    var dttoday: Date = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = dttoday.getTime() - dt.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days < 7)
      return Math.round(Difference_In_Days).toString().concat(' days ago');
    else if (Difference_In_Days < 30)
      return Math.round(Difference_In_Days / 7)
        .toString()
        .concat(' weeks ago');
    else if (Difference_In_Days > 365)
      return Math.round(Difference_In_Days / 365)
        .toString()
        .concat(' year ago');
    else
      return Math.round(Difference_In_Days / 30)
        .toString()
        .concat(' month ago');
  }

  ContactLandLordModal() {
    if (this.propertyDetails[0].OwnerId == Number(this.userid)) {
      this.showToastMessage(
        'The tenant and landlord is the same user. Impossible to continue.'
      );
    } else {
      this.authservice
        .getAnonymousProfileUserInfo(this.propertyDetails[0].iduser.trim())
        .pipe()
        .subscribe(
          (result) => {
            const modalRef = this.modalService.open(ContactlandlordComponent);
            let data = {
              name: this.chatname,
              iduserA: this.propertyDetails[0].iduser.trim(),
              iduserB: this.userid,
              iduserC: '0',
              IDProperty: this.propertyDetails[0].id,
              IDEvent: '2',
              eventStartedByUserName: this.userfirstname,
              OwnerName: result.FirstName + ' ' + result.LastName,
              OwnerPhotourl: JSON.stringify(result.ProfilePhotoUrl).toString(),
              phonenumber: JSON.stringify(result.PhoneNumber).toString(),
              defaultuserimage: `${environment.apiUrl}`.concat(
                '/',
                'assets/images/User-Default.jpg'
              ),
            };
            modalRef.componentInstance.fromParent = data;
            modalRef.result.then(
              (result) => {},
              (reason) => {}
            );
          },
          (error) => {}
        );
    }
  }

  showToastMessage(message) {
    const modalRef = this.modalService.open(GenerictoastmessageComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
    });
    let data = {
      prop1: 'Information',
      prop2: message,
      prop3: '2',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  private isuserTenent() {
    if (this.authservice.roles.includes('tenants')) return true;
    else return false;
  }

  public isuservendor() {
    if (this.authservice.roles.includes('vendor')) return true;
    else return false;
  }

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    /*
    let activeDiv = document.getElementById("topmenu");

    if (activeDiv.classList.contains("show"))
      activeDiv.classList.remove('show');
*/
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'propertytype') {
        this.router.navigate(['/' + destpage], {
          queryParams: { propertytype: paramvalue },
        });
      } else {
        this.router.navigate(['/' + destpage], {
          queryParams: { id: paramvalue },
        });
      }
    }
  }

  public navigateToPropertyEdit() {
    var serviceId = this.serviceId;

    this.router.navigate(['/editservices'], { queryParams: { serviceId } });
  }

  loadScripts() {
    const dynamicScripts = [
      './assets/js/theme.js',
      './assets/vendors/jquery.min.js',
      './assets/vendors/jquery-ui/jquery-ui.min.js',
      './assets/vendors/bootstrap/bootstrap.bundle.js',
      './assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      './assets/vendors/slick/slick.min.js',
      './assets/vendors/waypoints/jquery.waypoints.min.js',
      './assets/vendors/counter/countUp.js',
      './assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      './assets/vendors/chartjs/Chart.min.js',
      './assets/vendors/dropzone/js/dropzone.min.js',
      './assets/vendors/timepicker/bootstrap-timepicker.min.js',
      './assets/vendors/hc-sticky/hc-sticky.min.js',
      './assets/vendors/jparallax/TweenMax.min.js',
      './assets/vendors/mapbox-gl/mapbox-gl.js',
      './assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
