<div class="modal-header">
    <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">CREATE NEW PROPERTY</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
        <tbody>
        <tr>
        <td style="width: 100%;">
              <svg xmlns="http://www.w3.org/2000/svg" width="216.58" height="216.58" viewBox="0 0 439 439">
                <path id="check-circle-solid" d="M447,227.5C447,348.727,348.727,447,227.5,447S8,348.727,8,227.5,106.273,8,227.5,8,447,106.273,447,227.5ZM202.111,343.723,364.965,180.869a14.161,14.161,0,0,0,0-20.027l-20.027-20.027a14.161,14.161,0,0,0-20.028,0L192.1,273.629l-62.008-62.008a14.163,14.163,0,0,0-20.028,0L90.035,231.648a14.161,14.161,0,0,0,0,20.027l92.048,92.048a14.162,14.162,0,0,0,20.028,0Z" transform="translate(-8 -8)" fill="#0ec6d5"/>
              </svg>

        </td>
        </tr>
        <tr>
        <td style="width: 100%;">
            NEW PROPERTY CREATED! <br>Please acess you area to check the list of your properties.
        </td>
        </tr>
        <tr>
            <td style="width: 100%;">
                <br>In case of any doubt, read the terms of use and or get in touch with us!  (support@roundcomb.com)
            </td>
            </tr>
        </tbody>
        </table>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>

</div>
