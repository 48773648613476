import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import {
  AuthenticationService,
  eventsService,
  propertiesService,
} from '../services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
//
export class events {
  constructor(
    public CreationDate: string,
    public Name: string,
    public Location: string,
    public PropertySellType: string,
    public status: string,
    public chatroomguid: string,
    public propertyId: string,
    public IdEvent: string,
    public totalnewmessages: string,
    public EventName: string,
    public IDUserChat_B: string,
    public IDUserChat_A: string
  ) {}
}

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit, AfterViewInit {
  page: number = 1;
  count: number = 0;
  public empty: any = false;

  public isexternalproviderlogin = false;
  private iduser: string;
  public userId: string;
  public totalevents: string;
  public eventslist = new Array<events>();
  public allEventsList = [];
  public ratingArr = [
    {
      moreThan: 0.9,
      inBetweenLess: 0,
      inBetweenMore: 0.9,
      lessThan: 0,
    },
    {
      moreThan: 1.9,
      inBetweenLess: 1.1,
      inBetweenMore: 1.9,
      lessThan: 1,
    },
    {
      moreThan: 2.9,
      inBetweenLess: 2.1,
      inBetweenMore: 2.9,
      lessThan: 2,
    },
    {
      moreThan: 3.9,
      inBetweenLess: 3.1,
      inBetweenMore: 3.9,
      lessThan: 3,
    },
    {
      moreThan: 4.9,
      inBetweenLess: 4.1,
      inBetweenMore: 4.9,
      lessThan: 4,
    },
  ];
  public servicesList = new Array<events>();
  public pagesarray = new Array<{ idpage: number; pagedesc: String }>();
  public eventslistpage = new Array<{ pagenumber: number; event: events }>();
  public eventslisttoshowpage = new Array<events>();
  public selectedpage: number = 1;
  public searchkeyword: string;
  formfilterevents: FormGroup;
  public textstatusparam: string = 'All';
  public textfilterparam: string = '';
  public innerWidth: number = window.innerWidth;
  public apiUrl: string = '';
  public userDetails: any = [];
  public eventName: any;

  public sliceMin: any = 0;
  public sliceMax: any = 4;

  public users: any = [];

  public ratingCalc: any = 0;
  public index: any = 0;
  public ratingLength: any = 0;

  faStar = faStar;
  faStarHalf = faStarHalf;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private eventservice: eventsService,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private propertiesservice: propertiesService
  ) {}

  ngOnInit(): void {
    this.ngxService.startBackground('loadevents');
    this.apiUrl = environment.apiUrl;

    this.formfilterevents = this.formBuilder.group({
      searchkeyword: [''],
    });

    if (
      +JSON.parse(localStorage.getItem('roundcombuserprovider')).loginprovider >
      0
    )
      this.isexternalproviderlogin = true;

    this.route.queryParams.subscribe((queryparams: Params) => {
      const userinfo = localStorage.getItem('roundcombuserdetail');

      let UserjsonObject = JSON.parse(userinfo);

      this.iduser = UserjsonObject.Id;
      this.userId = UserjsonObject.userIdProfile;
      if (queryparams.IDEvent == 2) {
        this.eventName = 'Maintenance';
        this.eventservice
          .getmyEventsServicesList(Number(this.iduser))
          .toPromise()
          .then((response) => {
            this.eventslist = response;
            //order by CreationDate desc
            let sortedBuildsCreationDate = this.eventslist.sort(
              (n1: any, n2: any) => {
                //return this.sortCompare(n2.CreationDate, n1.CreationDate)
                return (
                  <any>new Date(n2.CreationDate) -
                  <any>new Date(n1.CreationDate)
                );
              }
            );

            this.filterEventIDlist(queryparams.IDEvent);
          });
      } else if (queryparams.IDEvent == 1) {
        this.eventName = 'Lease';
        // queryparams.IDEvent
        this.eventservice
          .getMyEventsList(this.iduser)
          .pipe()
          .subscribe(
            (data) => {
              this.eventslist = data;
              //order by CreationDate desc
              let sortedBuildsCreationDate = this.eventslist.sort(
                (n1: any, n2: any) => {
                  return (
                    <any>new Date(n2.CreationDate) -
                    <any>new Date(n1.CreationDate)
                  );
                }
              );
              this.filterEventIDlist(queryparams.IDEvent);
            },
            (error) => {}
          );
      }
    });
  }

  ngAfterViewInit() {
    this.ngxService.stopBackground('loadevents');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  onTableDataChange(event: any) {
    this.page = event;
  }

  toPrevious(p) {
    if (!p.isFirstPage()) {
      p.previous();
    }
  }

  setCurrent(p, value) {
    p.setCurrent(value);
  }

  toNext(p) {
    if (!p.isLastPage()) {
      p.next();
    }
  }

  public isuservendor() {
    if (this.authenticationService.roles.includes('vendor')) return true;
    else return false;
  }

  sortCompare(a, b) {
    var ax = [],
      bx = [];

    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }

    return ax.length - bx.length;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }

  filterEventIDlist(IdEvent: string) {
    this.eventslistpage.splice(0, this.eventslistpage.length);
    this.pagesarray.splice(0, this.pagesarray.length);
    this.eventslist.forEach((el) => {
      el.Name = el.Name.split(`#${el.propertyId}_`).join('');
    });

    var totalperpage: number = 4;
    var page: number = 1;

    this.eventslist = this.eventslist.concat(this.servicesList);

    for (var i = 0; i < this.eventslist.length; i++) {
      if (IdEvent != '' && IdEvent == 'null') {
        if (this.eventslist[i].IdEvent == IdEvent) {
          this.eventslistpage.push({
            pagenumber: page,
            event: this.eventslist[i],
          });

          if (
            this.eventslistpage.length % totalperpage == 0 &&
            i < this.eventslist.length - 1
          )
            page = page + 1;
        }
      } else {
        this.eventslistpage.push({
          pagenumber: page,
          event: this.eventslist[i],
        });

        if (
          this.eventslistpage.length % totalperpage == 0 &&
          i < this.eventslist.length - 1
        )
          page = page + 1;
      }
    }

    // in case of exactly number of events
    if (this.eventslistpage.length % totalperpage == 0 && page > 1)
      page = page - 1;

    for (var i = 0; i < page; i++) {
      if (i == 0) {
        //first element
        this.pagesarray.push({ idpage: i + 1, pagedesc: 'page-item active' });
      } else {
        this.pagesarray.push({ idpage: i + 1, pagedesc: 'page-item' });
      }
    }

    this.totalevents = this.eventslistpage.length.toString();

    // discard other events
    this.eventslist.splice(0, this.eventslist.length);

    // backup
    this.eventslistpage.forEach((val) => {
      this.eventslist.push(Object.assign({}, val.event));
    });

    // first page
    this.getarraypage(1);
  }

  public getarraypage(page: number) {
    this.eventslisttoshowpage.splice(0, this.eventslisttoshowpage.length);
    this.page = 1;
    this.allEventsList = [];
    this.eventslistpage.forEach((el) => {
      this.allEventsList.push(el.event);
    });
    this.allEventsList.forEach((el) => {
      if (Object.prototype.toString.call(el.JSON) !== '[object Array]') {
        if (Object.keys(el.ImagemUserA).length === 0) {
          el.ImagemUserA = 'null';
        }
        if (Object.keys(el.ImagemUserB).length === 0) {
          el.ImagemUserB = 'null';
        }
        if (Object.values(el.JSON).length > 0) {
          el.JSON = JSON.parse(el.JSON);
          el.ratingA = Number(el.ratingA) === -1 ? 'begin' : el.ratingA
          el.ratingB = Number(el.ratingB) === -1 ? 'begin' : el.ratingB
        }
      }
    });
    if (this.allEventsList.length === 0) {
      this.empty = true;
    } else {
      this.empty = false
    }
  }

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'chatroomguid') {
        this.router.navigate(['/' + destpage], {
          queryParams: { chatroomguid: paramvalue },
        });
      }
    }
  }

  onstatuschanged(selectedvalue) {
    if (selectedvalue == 1) this.textstatusparam = 'All';
    else if (selectedvalue == 2) this.textstatusparam = 'Presentation';
    else if (selectedvalue == 3) this.textstatusparam = 'Negotiation';
    else if (selectedvalue == 4) this.textstatusparam =  this.eventName === 'Lease' ? 'Information Supply' : 'Scheduling';
    else if (selectedvalue == 5) this.textstatusparam = 'Validate your Identity';
    else if (selectedvalue == 6) this.textstatusparam = 'Signing the Contract';
    else if (selectedvalue == 7) this.textstatusparam = 'Payment';

    this.filtergrid();
  }

  searchevent(searchword: string) {
    this.textfilterparam = searchword;

    this.filtergrid();
  }

  filtergrid() {
    this.eventslistpage.splice(0, this.eventslistpage.length);
    this.pagesarray.splice(0, this.pagesarray.length);
    var totalperpage: number = 4;
    var page: number = 1;

    for (var i = 0; i < this.eventslist.length; i++) {
      if (
        (this.textfilterparam == '' && this.textstatusparam == 'All') ||
        (this.eventslist[i].Name.includes(this.textfilterparam) &&
          this.textstatusparam == this.eventslist[i].status) ||
        (this.eventslist[i].Name.includes(this.textfilterparam) &&
          this.textstatusparam == 'All')
      ) {
        this.eventslistpage.push({
          pagenumber: page,
          event: this.eventslist[i],
        });
      }
    }
    this.totalevents = this.eventslistpage.length.toString();

    this.getarraypage(1);
  }
}
