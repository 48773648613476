<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
  <div class="container" style="display: flex;justify-content: center;">

    <div style="background-color: white !important; padding: 30px !important;"
      class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">


      <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
        data-animate="fadeInDown">Privacy Policy</h1>

      <div id="content" class="main-content" data-content-field="main-content"
        data-collection-id="57b1d8f6d1758e31faf01df9" data-edit-main-image="">
        <div class="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1491477009307"
          id="page-57b1d8f6d1758e31faf01df9">
          <div class="row sqs-row">
            <div class="col sqs-col-12 span-12">
              <div class="sqs-block html-block sqs-block-html" data-block-type="2" id="block-a423555fd7cd39d4f730">
                <div class="sqs-block-content">
                  <p>Effective April 01, 2017</p>
                  <p><strong>A</strong>.<strong> About Us</strong>:<strong> </strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p>Welcome to roundcomb.com (the “Website”). Roundcomb Inc. (“Roundcomb”, “us”, “we”, or “our”)
                    provides venue for third party service providers that register with us using the Website
                    (“Landlords”) to advertise products and services to others that use our Website to purchase products
                    or services (each a “Listing”) directly from Landlords (collectively, “Customers”). We do this
                    through the Website, our mobile application, the Content, and the products and services otherwise
                    made available by us (together with the Website, and Content, and other products and services we
                    offer via the Website, collectively, the “Services”). By accessing or using the Services, you the
                    end user of the Services (together with Landlords, and Customers, collectively, “User”, “you”, or
                    “your”), expressly agree to be bound to and to abide by this Privacy Policy (“Privacy Policy”), our
                    Terms of Service (together with any order forms, “Terms of Service”), and any other policy we may
                    develop from time to time and post on our Website (together with the Terms of Service and our
                    Privacy Policy, collectively, “Policies.</p>
                  <p>&nbsp;</p>
                  <p>If you do not agree to the practices described below in this Privacy Policy, do not browse our
                    Website or use our Services, as your continued use of our Services indicates that you are agreeing
                    to the collection, use, disclosure, management and storage of your Personal Data and other
                    information as described in this Privacy Policy.</p>
                  <p>&nbsp;</p>
                  <p><strong>B</strong>.<strong> Privacy Policy:</strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p>Please read this Privacy Policy as it includes important information regarding your Personal Data
                    and other information. Capitalized terms not defined in this Privacy Policy shall have the meanings
                    given to such terms in our Terms of Service. If you have any questions or concerns, please do not
                    hesitate to contact us at: info@roundcomb.com.</p>
                  <p>&nbsp;</p>
                  <p><strong>1</strong>.<strong> Information We Collect</strong>.</p>
                  <p>&nbsp;</p>
                  <p>(a) Personal Data. Personal Data and other information are collected from you when you create an
                    Account with us or when you otherwise disclose Personal Data or other information to us or third
                    parties when using the Services. “Personal Data” means any information that may be used to identify
                    an individual. Personal Data does not include aggregate information. To use certain aspects of our
                    Services you may be asked to provide us the following information: your name, company name,
                    telephone number, email address, mailing address, payment information (if applicable), and related
                    requested information.</p>
                  <p>&nbsp;</p>
                  <p>(b) Location Data. Third parties that we may engage may collect location information in relation to
                    a User’s Device and use of the Services (“Location Data”) with respect to you. If you use the
                    Services, then you are providing consent to collect Location Data such as the physical location of
                    your Device which will be used to provide you with certain features of the Services. This Location
                    Data is collected anonymously in a form that does not personally identify you. Location Data is
                    aggregated with data from Devices of other Users to enhance the quality of Content Users provide to
                    us and to enhance the quality of the Services we provide to you. We may also share your Location
                    data with third parties. Location Data is shared anonymously in a form that would not personally
                    identify you.</p>
                  <p>&nbsp;</p>
                  <p>(c) Use Data. When you send or receive Content using our Services, we collect data about that
                    Content.</p>
                  <p>&nbsp;</p>
                  <p>(d) Log Information. When you browse our Services, you do so anonymously, unless you have
                    previously created an Account with us. However, we may log your IP address to give us an idea of
                    which part of our Services you visit and how long you spend there. However, we do not link your IP
                    address to any Personal Data unless you have logged into our Services. Also, our Services may use a
                    standard technology called a ‘cookie’ to collect information about how you interact with our
                    Services. Please see Section 7 below for more information.</p>
                  <p>&nbsp;</p>
                  <p>(e) Aggregate Data. Certain aspects of our Services are set up to collect and report aggregate
                    information. Aggregate information is data we collect about the use of the Services or about a group
                    or category of products, services or Users, from which individual identities or other Personal Data
                    has been removed. In other words, information about how you use the Services may be collected and
                    combined with information about how others use the Services. Aggregate data helps us understand
                    trends and User’s needs so that we can better consider new features or otherwise tailor our
                    Services. This Privacy Policy does not restrict what we can do with aggregate information.</p>
                  <p>&nbsp;</p>
                  <p>(f) Analytics. We may use third party analytics tools to collect information about use of our
                    Services. Analytics tools collect information such as how often Users visit our Website, what pages
                    Users visit when Users visit our Website, and the other websites they accessed prior to accessing
                    our Website. We use the information we gather from analytics to improve our Services. Analytics
                    tools collect information such as the IP address assigned to you on the date you visit our Website,
                    but does not collect Personal Data. We do not combine the information collected through the use of
                    analytics with Personal Data. Although our analytics tools may plant a cookie on your web browser to
                    identify you as a unique User the next time you visit our Website, the cookie cannot be used by
                    anyone but our analytics tools. In the event that we use Google’s ‘Google Analytics’, then Google’s
                    ability to use and share information collected by ‘Google Analytics’ about your visits to our
                    Website is restricted by the Google Analytics Terms of Use here: <a
                      href="http://www.google.com/analytics/tos.html">http://www.google.com/analytics/tos.html</a> and
                    the Google Privacy Policy here: <a
                      href="http://www.google.com/privacypolicy.html">http://www.google.com/privacypolicy.html</a>. You
                    can prevent Google Analytics from using your data by downloading the Google Analytics Opt-out
                    Browser that is available from Google here: <a
                      href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>, but
                    if you do so, certain features of our Services may become unavailable. See Section 7 below for more
                    information</p>
                  <p>&nbsp;</p>
                  <p>(g) Device Information. Linked Sites may collect data about the computer or Device you use to
                    access our Services, including the hardware model, operating system and version, MAC address, unique
                    device identifier (‘UDI’), phone number, and mobile carrier information.</p>
                  <p>&nbsp;</p>
                  <p>(h) Tracking Technologies. We collect information through technology, such as cookies, flash
                    cookies and web beacons, including when you visit our Services or Linked Sites. Please see Section 7
                    below for further information regarding tracking technologies.</p>
                  <p>&nbsp;</p>
                  <p>(i) From Third Parties. We acquire information from other trusted sources to update or supplement
                    the information you provided or we collected automatically. Local law may require that you authorize
                    the third party to share your information with us before we can acquire it.</p>
                  <p>&nbsp;</p>
                  <p><strong>2</strong>. <strong>How We Use It</strong>. We use your Personal Data to:</p>
                  <p>&nbsp;</p>
                  <p>(a) enable us to provide the Services to you;</p>
                  <p>(b) notify you regarding Services, your Account, Subscription, or fees;</p>
                  <p>(c) increase the usability of the Services;</p>
                  <p>(d) provide information about promotional offers;</p>
                  <p>(e) deliver ads on our Services and on the websites of advertising partners, to improve and monitor
                    our advertising, and to provide you with targeted ads;</p>
                  <p>(f) investigate objectionable use of the Services;</p>
                  <p>(g) respond to requests for assistance from our customer support team;</p>
                  <p>(h) analyze trends and use of our Services;</p>
                  <p>(i) analyze the Personal Data and other Content provided by Users; and</p>
                  <p>(j) carry on our business, as determined by us.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>3</strong>. <strong>Who We Share It With</strong>.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p>We will share Personal Data with third parties when:</p>
                  <p>&nbsp;</p>
                  <p>(a) When you have enabled us to share your Personal Data with another company or our Affiliates by
                    using the Services. Once we share your Personal Data with another company, the information received
                    by the other company becomes subject to the other company’s privacy practices.</p>
                  <p>&nbsp;</p>
                  <p>(b) When necessary, as determined by us in our sole discretion, to provide Services to you. This
                    includes for the uses listed in Section 2 above.</p>
                  <p>&nbsp;</p>
                  <p>(c) When required by law or by court order.</p>
                  <p>&nbsp;</p>
                  <p>(d) To protect our rights and property, to prevent fraudulent activity or other deceptive practices
                    of Users or third parties, or to prevent harm to others.</p>
                  <p>&nbsp;</p>
                  <p>(e) If we are acquired by or merged with another company, or if our assets are sold to another
                    company. In all of these circumstances, you understand and agree that our Terms of Service and this
                    Privacy Policy will be assigned and delegated to the other company.</p>
                  <p>&nbsp;</p>
                  <p>(f) To perform tasks for us or in connection with our business, as determined by us.&nbsp;We may
                    use third parties to help operate the Services and perform other aspects of the Services. You agree
                    we may share your Personal Data with our Affiliates and other third parties that provide services to
                    us in connection with our business (such as website or database hosting companies, address list
                    hosting companies, email service providers, analytics companies, distribution companies, and other
                    similar service providers that use such information on our behalf). Unless otherwise stated, these
                    third parties do not have any right to use the Personal Data we provide to them beyond what is
                    necessary for them to assist us, as determined by us.</p>
                  <p>&nbsp;</p>
                  <p><strong>4</strong>. <strong>Your Choices</strong>.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p>(a) Any Content that you Transmit to a public portion of our Website may be searchable by other
                    Users and third parties as well as subject to use by others. Once you have made Content viewable to
                    others, that Content may be re-shared by others and we have no control or liability regarding how
                    others may use the Content you Transmit. We use commercially reasonable efforts to prevent undesired
                    disclosure of Content or Personal Data. However, we are not responsible for how others may use
                    Content or Personal Data that is disclosed by you to Users or third parties using the Services.</p>
                  <p>&nbsp;</p>
                  <p>(b) The Services may allow you to share Content on Social Media Profiles. If you use such
                    functionality of the Services, then you should use caution when determining to whom you want to make
                    Content available. If you choose to Transmit Content on third party Social Media Profiles, then,
                    depending on the privacy settings of your Social Media Profiles, it is possible that persons in
                    addition to those to whom you intended to view that Content could have access to that information.
                    Accordingly, you should use caution when sharing Content via third party Social Media Profiles and
                    you should carefully review the privacy practices of such Social Media Profiles.</p>
                  <p>&nbsp;</p>
                  <p>(c) If you remove information, Personal Data, or Content that you Transmit to the Services, copies
                    may remain viewable in cached and archived pages of the Services, or if other Users or third parties
                    copied or saved that information.</p>
                  <p>&nbsp;</p>
                  <p>(d) You may opt out of targeted advertisements from some third party companies by visiting the
                    Network Advertising Initiative or Digital Advertising Alliance Consumer Choice Page, which provide
                    simple ways to opt out of ad targeting from participating third party companies. When you opt out,
                    your selection will be stored in a cookie that is specific to your Device or computer and the web
                    browser you are using. The opt out cookie has an expiration date of five years. If you delete the
                    cookies on your computer or Device, you will need to opt out again.</p>
                  <p>&nbsp;</p>
                  <p>(e) We will contact you from time to time for marketing purposes. Unless you have opted out, this
                    could include contacting you by phone or email. You may opt-out of receiving marketing
                    communications from us by the following means: (i) contacting us at info@roundcomb.com; or (ii)
                    following the instructions in the communication.</p>
                  <p>&nbsp;</p>
                  <p><strong>5</strong>. <strong>Security of Your Personal Information</strong>.<strong> </strong>We
                    exercise commercially reasonable care in protecting the security of Personal Data provided to us. We
                    make efforts to protect your data from loss, misuse, unauthorized access or disclosure, alteration,
                    or destruction. Personal Data is stored in password-controlled servers with limited access. However,
                    you have a significant role in Account security. Someone may see or edit your Personal Data if that
                    person gains access to your username and password, so do not share these with others. WE TAKE THESE
                    PRECAUTIONS IN AN EFFORT TO PROTECT YOUR INFORMATION AGAINST SECURITY BREACHES. HOWEVER, THIS IS NOT
                    A GUARANTEE THAT SUCH INFORMATION MAY NOT BE ACCESSED, DISCLOSED, ALTERED, OR DESTROYED BY BREACH OF
                    SUCH FIREWALLS AND SECURE SERVER SOFTWARE. BY USING THE WEBSITE, YOU ACKNOWLEDGE THAT YOU UNDERSTAND
                    AND AGREE TO ASSUME THESE RISKS.</p>
                  <p>&nbsp;</p>
                  <p><strong>6</strong>.<strong> Your Account</strong>.<strong> </strong>Users may review and update
                    Personal Data by logging into their Account. To protect your privacy and security, we will also take
                    reasonable steps to verify your identity, such as password and username, before granting access to
                    any Personal Data. If your Account is terminated by you or us, we will remove your name and other
                    Personal Data from our publicly viewable database. If you close your Account, we have no obligation
                    to retain your information, and may delete any or all of your Account information without liability.
                    However, we may retain Content related to you for any reason or no reason, as determined by us. We
                    may also retain and use your Content if necessary to provide Services to other Users. We disclaim
                    any liability in relation to the deletion or retention (subject to this Privacy Policy) of Content
                    or any obligation not to delete the Content.</p>
                  <p>&nbsp;</p>
                  <p><strong>7</strong>. <strong>Tracking Technology</strong>.</p>
                  <p>&nbsp;</p>
                  <p>(a) Generally. We and other third parties with whom we may partner may use cookies, clear .gifs and
                    .pngs (also known as ‘web beacons’), or local shared objects (sometimes called ‘flash cookies’) to
                    help personalize the Services. A cookie is a text file that is placed on your computer or other
                    device by a server. Cookies cannot be used to run programs or deliver viruses to your computer or
                    other device. Cookies are uniquely assigned to you, and can only be read by the server that issued
                    the cookie to you. A web beacon is typically a transparent .gif or .pngs graphic image (usually 1
                    pixel by 1 pixel in size) that is used in conjunction with the Services, which allows us or third
                    parties with whom we may partner to measure the actions of Users who interact with the portion of
                    the Services that contain the web beacons. You have the ability to accept or decline cookies and web
                    beacons may be unusable if you elect to reject cookies. Most web browsers automatically accept
                    cookies, but you can usually modify the settings to decline cookies if you prefer. If you choose to
                    decline cookies, you may not be able to fully experience the interactive features of the Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>(b) Use. We and other third parties use web beacons to measure traffic and related browsing
                    behavior, and to improve your experience when using the Services. We and other third parties may
                    also use customized links or other similar technologies to track hyperlinks that you click, and
                    associate that information with your Personal Data in order to provide you with more focused
                    communications. Even if you do not have an Account of if you are logged out of your Account, we
                    still use cookies. We use cookies when you log out of your account to do things such as: (i)
                    identify and disable the Accounts of Users who violate our Terms of Service, (ii) help you recover
                    your Account, (iii) provide security features, (iv) restrict access to the Services to individuals
                    who provide false information when signing up for an Account, (v) provide us with information about
                    the ads we and our Affiliates make available on the Services, and to (vi) gather an understanding
                    about our Users. If you do not have an Account, we use cookies to protect us from malicious activity
                    (e.g. flooding, mass creation of fraudulent Accounts, and the like).</p>
                  <p>&nbsp;</p>
                  <p>(c) Do Not Track. Our Services may not recognize ‘Do Not Track’ requests and any such web browser
                    feature may be inoperable with respect to our Services. You understand and agree that we shall not
                    be liable for and you expressly release, indemnify, hold harmless, and defend us from any claims you
                    may have related to our inability to honor a ‘Do Not Track’ request.</p>
                  <p>&nbsp;</p>
                  <p><strong>8</strong>. <strong>Compliance with the Children</strong>’<strong>s Online Privacy
                      Protection Act</strong>.<strong> </strong>We recognize the need to provide further privacy
                    protections with respect to Personal Data we may collect from Children who use our Services. For
                    that reason, we make every effort to comply with the regulations of the Children’s Online Privacy
                    Protection Act of 1998 (15 U.S.C. § 6501 - 6506). We never collect or maintain Personal Data through
                    the Services from those we actually know are under thirteen years of age, and no part of the
                    Services are structured to attract anyone under thirteen years of age. We expressly disclaim, and
                    you expressly release us from, any and all liability whatsoever for any controversies, claims,
                    suits, injuries, harm, loss, penalties, damages, arising from and/or in any way related to any
                    misrepresentations regarding the age of any User. We reserve the right to suspend and/or terminate
                    with or without notice the Account of User who we believe has provided false information when
                    registering for and/or using the Services and each User agrees to make no further use of the
                    Services after termination and/or during suspension.</p>
                  <p>&nbsp;</p>
                  <p><strong>9</strong>. <strong>Linked Sites</strong>.<strong> </strong>The Services and/or third
                    parties may provide links to Linked Sites and data provided by third parties. We are not responsible
                    for the privacy practices employed by Linked Sites, nor are we responsible for the information or
                    materials that third party data contains. This Privacy Policy applies solely to information
                    collected by us through the Services. When you use a link to go from the Services to a Linked Site,
                    this Privacy Policy is no longer in effect. We encourage Users to read the privacy policies of
                    Linked Sites before proceeding to use them. When you conduct e-commerce with a Linked Site, certain
                    Personal Data may be collected by the Linked Site and made available to us.</p>
                  <p>&nbsp;</p>
                  <p><strong>10</strong>. <strong>Advertisement Revenue. </strong>The Services may display
                    advertisements operated by us, our Affiliates, and/or third parties. Some of the advertisements we
                    display are targeted to you based on your online behavior and your click habits and we may receive
                    compensation based on your clicks.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>11</strong>.<strong> Changes to our Privacy Policy; Miscellaneous</strong>.<strong>
                    </strong>We reserve the right to change this Privacy Policy, our Terms of Service, and our other
                    Polices at any time. We will notify you about changes to this Privacy Policy by placing the updated
                    Privacy Policy on the Services. You agree that your use of the Services after such notification will
                    constitute acceptance by you of such changes to our Privacy Policy. This Privacy Policy is governed
                    by the laws of the State of Maryland. This Privacy Policy is and any disputes in connection with
                    this Privacy Policy are subject to our Terms of Service and our other Policies which are each hereby
                    incorporated herein by reference. In the event of any conflict between the provisions of this
                    Privacy Policy and our Terms of Service, our Terms of Service shall control and the remaining
                    provisions of this Privacy Policy shall be supplemental.</p>
                  <p>&nbsp;</p>
                  <p><strong>12</strong>. <strong>Feedback</strong>. We value your comments and opinions. If you have
                    questions, comments or a complaint about compliance with this Privacy Policy, you may send a written
                    notice to us at: info@roundcomb.com.</p>
                </div>
              </div>
              <div class="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type="47"
                id="block-yui_3_17_2_27_1473945559935_5194">
                <div class="sqs-block-content">
                  <hr>
                </div>
              </div>
              <div class="sqs-block html-block sqs-block-html" data-block-type="2"
                id="block-yui_3_17_2_27_1473945559935_5559">
                <div class="sqs-block-content"></div>
              </div>
              <div class="sqs-block button-block sqs-block-button" data-block-type="53"
                id="block-yui_3_17_2_27_1473945559935_8026">
                <div class="sqs-block-content" id="yui_3_17_2_1_1616526100258_109">

                  <div class="sqs-block-button-container--center" data-animation-role="button" data-alignment="center"
                    data-button-size="medium" id="yui_3_17_2_1_1616526100258_108">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>
</section>
