<section style="margin-bottom: 50px;">
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div *ngIf="this.openModal === true" class="modal-background">
    <div class="modal-buy shadow">
      <a style="text-decoration: none;" class="user-cash-dashboard cash-boost">
        <span>{{ this.cash.toFixed(2) }}</span>
        <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
      </a>
      <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
        Buy Boosts
      </h3>
      <button (click)="closeModal()" class="close-icon">
        <img src="../../assets/images/close-icon.png" alt="Close icon" />
      </button>
      <form (submit)="submitChanges()" style="margin-bottom: 0" type="submit">
        <div [ngClass]="{
          'radius': this.total > this.cash,
          'radius-all': this.total < this.cash
        }" class="listing-type-boosts">
          <h4 class="buy-title">Buy Listing Boosts</h4>

          <div (click)="checkBoosts()" *ngIf="this.filteredItem[0].Booster !== true"
            class="modal-select-boosters blue-box">
            <div class="terms-section">
              <div class="checkbox">
                <div class="checked" *ngIf="this.checkedBoost === true"></div>
              </div>
            </div>
            <div class="arrow arrow-boost-modal">
              <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
            </div>
            <p>
              There is a special spot at top of each listing page that will randomly cycle
              through the pool of "top" listings. This will highly improve your listing views.
            </p>
          </div>

          <div (click)="checkHighlight()" *ngIf="this.filteredItem[0].Highlight !== true"
            class="modal-select-boosters purple-box">
            <div class="terms-section">
              <div class="checkbox">
                <div class="checked" *ngIf="this.checkedHigh === true"></div>
              </div>
            </div>
            <div class="arrow arrow-boost-modal">
              <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
            </div>
            <p>
              Your property will appear with a visual highlight to make it stand out to
              potential interested clients.
            </p>
          </div>

          <ng-container *ngIf="this.filteredItem[0].Booster === true && this.filteredItem[0].Highlight === true">
            <div *ngIf="this.filteredItem[0].Booster === true"
              class="modal-select-boosters disabled-box-modal blue-box">
              <div class="terms-section">
                <div class="checkbox">
                  <div class="checked" *ngIf="this.checkedBoost === true"></div>
                </div>
              </div>
              <div class="arrow arrow-boost-modal">
                <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
              </div>
              <p>
                There is a special spot at top of each listing page that will randomly cycle
                through the pool of "top" listings. This will highly improve your listing views.
              </p>
            </div>

            <div *ngIf="this.filteredItem[0].Highlight === true"
              class="modal-select-boosters disabled-box-modal purple-box">
              <div class="terms-section">
                <div class="checkbox">
                  <div class="checked" *ngIf="this.checkedHigh === true"></div>
                </div>
              </div>
              <div class="arrow arrow-boost-modal">
                <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
              </div>
              <p>
                Your property will appear with a visual highlight to make it stand out to
                potential interested clients.
              </p>
            </div>
          </ng-container>

          <div class="mobile-direction"
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 12px;">
            <div class="same-styles">
              <p style="margin-bottom: 0;">Duration month</p>
              <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                [value]="this.valueMonth" (input)="changeMonthValue($event)" id="month">
            </div>
            <div class="number-boost">
              <p style="margin-bottom: 0;">Total</p>
              <span>{{this.total.toFixed(2)}} RCT</span>
            </div>
          </div>
        </div>
        <div *ngIf="this.total > this.cash" class="warn-orange">
          <span>insufficient RCT on your Wallet</span>
          <a routerLink="/wallet" class="user-cash buy-more-button">Buy more RCT
            <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
          </a>
        </div>
        <div class="warning warning-slots">
          <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
          <p style="width: 100%; text-align: justify;">
            By completing the purchase, the funds will be taken from your wallet.
          </p>
        </div>
        <div [ngClass]="{
          'top-short-distance': this.total > this.cash,
          'top-distance': this.total < this.cash
        }">
          <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new boosts will be
            available until:</p>
          <p class="heading-3 available-date">{{this.dateavailable}}
          </p>
        </div>
        <div style="
              display: flex;
              justify-content: center;
              margin-right: 34px;
            ">
          <button [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : false"
            class="submit-button">
            SUBMIT & PAY
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="container" id="container">
    <ng-container *ngIf="this.isuserlogged && this.istheownerloggeduser">
      <section data-animated-id="1" class="bg-secondary py-1 py-lg-3 align-items-center">
        <div class="container align-items-center">
          <div class="row align-items-center">
            <div style="text-align:center;" class="col-lg-12 pt-lg-1 pt-6 align-items-center">
              <label class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">
                Interested in making your property stand out?
              </label>
              &nbsp; &nbsp;<a (click)="handleOpenBuySlotsModal(this.id)" class="badge badge-orange"
                style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Click
                Here</a>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb py-3">
        <li class="breadcrumb-item fs-12 letter-spacing-087">
          <a routerLink="/home">Home</a>
        </li>
        <li class="breadcrumb-item fs-12 letter-spacing-087">
          <a routerLink="/filterproperty" [queryParams]='{propertytype:"All Properties"}'>Listing</a>
        </li>
        <li class="breadcrumb-item fs-12 letter-spacing-087 active">{{this.propertyDetails[0]?.Name}}</li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-sm-6 col-md-6 mb-6">
        <div class="card px-6" style="border:0px; padding-top: 15px;">
          <ul class="list-inline d-sm-flex align-items-sm-center mb-2">
            <li class="list-inline-item mr-2 mt-2 mt-sm-0"><i class="fal fa-clock mr-1"></i>
              {{this.propertyDetails[0]?.inserteddate}}
            </li>

            <!--<li class="list-inline-item mt-2 mt-sm-0"><i class="fal fa-eye mr-1"></i>1039 views</li>-->
          </ul>
          <div class="d-sm-flex justify-content-sm-between">
            <div>
              <h2 class="fs-11 font-weight-600 lh-15 text-heading">{{this.propertyDetails[0]?.Name}}
              </h2>
              <p class="mb-0"><i class="fal fa-map-marker-alt mr-2"></i>{{this.locationdesc}}
              </p>
            </div>
          </div>
          <h4 class="fs-22 text-heading mt-6 mb-2">Description</h4>

          <p [innerHTML]="this.propertyDetails[0]?.FullDescription | keepHtml"></p>

        </div>
      </div>
      <div class="col-sm-6 col-md-6 mb-6">
        <div class="card px-6" style="border:0px; background-color: #f8f8f8;">
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"
            style="ngx-gallery-active"></ngx-gallery>

        </div>
      </div>

    </div>
    <!--
        <div class="row">

            <div class="col-lg-5 mb-8 mb-lg-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div class="primary-sidebar-inner" style="position: static; left: auto; width: 570px;">
                    <section class="pb-8 px-6 pt-6 bg-white rounded-lg">
                        <ul class="list-inline d-sm-flex align-items-sm-center mb-2">
                            <li class="list-inline-item mr-2 mt-2 mt-sm-0"><i class="fal fa-clock mr-1"></i>
                                {{this.propertyDetails[0]?.inserteddate}}
                            </li>

                        </ul>
                        <div class="d-sm-flex justify-content-sm-between">
                            <div>
                                <h2 class="fs-11 font-weight-600 lh-15 text-heading">{{this.propertyDetails[0]?.Name}}
                                </h2>
                                <p class="mb-0"><i class="fal fa-map-marker-alt mr-2"></i>{{this.locationdesc}}
                                </p>
                            </div>
                        </div>
                        <h4 class="fs-22 text-heading mt-6 mb-2">Description</h4>

                        <p [innerHTML]="this.propertyDetails[0]?.FullDescription | keepHtml"></p>

                    </section>


                </div>
            </div>

            <div class="col-lg-6">
                <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
               <div class="slider">

            </div>


        </div>
    -->
    <ng-container *ngIf="this.isuserlogged && this.sellTypeProp !== 'Unavailable'">
      <section data-animated-id="1" class="bg-secondary py-6 py-lg-1 align-items-center">
        <div class="container align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-12 pt-lg-1 pt-6 align-items-center" style="text-align:center"><label
                class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">Interested?
                Please contact the landlord</label>
              &nbsp; &nbsp;<a (click)="ContactLandLordModal()" class="badge badge-orange"
                style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Contact</a>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="!this.isuserlogged">
      <section data-animated-id="1" class="bg-secondary py-6 py-lg-1 align-items-center">
        <div class="container align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-12 pt-lg-1 pt-6 align-items-center" style="text-align:center"><label
                class="text-uppercase font-weight-500 opacity-10 text-white letter-spacing-093">Interested?
                Please Sign In to contact the landlord</label>
              &nbsp; &nbsp;<a (click)="navigateToPage('login','','')" class="badge badge-orange"
                style="cursor: pointer;color: white !important; background-color: rgb(255,153,60) !important;">Sign
                In</a>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <section class="mt-2 pb-3 px-6 pt-5 bg-white rounded-lg">
      <h4 class="fs-22 text-heading mb-6">Facts and Features</h4>
      <div class="row">
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-family fs-32 text-primary">
                <use xlink:href="#icon-family"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Type</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.namecategory}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-status fs-32 text-primary">
                <use xlink:href="#icon-status"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Status</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">
                {{this.propertyDetails[0]?.PropertySellType}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-price fs-32 text-primary">
                <use xlink:href="#icon-price"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">SQFT</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.SquareFeet}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-e1 fs-32 text-primary">
                <use xlink:href="#icon-e1"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Price</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.Price}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-bedroom fs-32 text-primary">
                <use xlink:href="#icon-bedroom"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Bedrooms</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.Beds}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-shower fs-32 text-primary">
                <use xlink:href="#icon-shower"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">bathrooms</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.Baths}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-Garage fs-32 text-primary">
                <use xlink:href="#icon-Garage"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">GARAGE</h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.Garage}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 mb-6">
          <div class="media">
            <div class="p-2 shadow-xxs-1 rounded-lg mr-2">
              <svg class="icon icon-e4 fs-32 text-primary">
                <use xlink:href="#icon-e4"></use>
              </svg>
            </div>
            <div class="media-body">
              <h5 class="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">Move-in date
              </h5>
              <p class="mb-0 fs-13 font-weight-bold text-heading">{{this.propertyDetails[0]?.MoveInDate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ng-container *ngIf="this.featureslist?.length > 1">
      <section class="mt-2 pb-7 px-6 pt-5 bg-white rounded-lg">
        <h4 class="fs-22 text-heading mb-4">Offices Amenities</h4>
        <ul class="list-unstyled mb-0 row no-gutters" *ngFor="let feature of this.featureslist;">
          <li class="col-sm-3 col-6 mb-2"><i class="far fa-check mr-2 text-primary"></i>{{feature.feature}}
          </li>
        </ul>
      </section>
    </ng-container>
    <section class="py-6 border-bottom">
      <h4 class="fs-22 text-heading mb-6">Location</h4>
      <div class="position-relative">

        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [disableDefaultUI]="false"
          [scrollwheel]="null">
          <!--<agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"></agm-marker>-->
          <agm-circle [latitude]="latitude" [longitude]="longitude" [radius]="radius" [fillColor]="'gray'"
            [circleDraggable]="false" [editable]="false">
          </agm-circle>
        </agm-map>
      </div>
    </section>

    <section class="mt-2 pb-7 px-6 pt-6 bg-white rounded-lg">
      <h4 class="fs-22 text-heading mb-6">Similar Homes You May Like</h4>
      <div class="slick-slider slick-initialized"
        data-slick-options="{&quot;slidesToShow&quot;: 2, &quot;dots&quot;:false,&quot;responsive&quot;:[{&quot;breakpoint&quot;: 1200,&quot;settings&quot;: {&quot;slidesToShow&quot;:2,&quot;arrows&quot;:false}},{&quot;breakpoint&quot;: 992,&quot;settings&quot;: {&quot;slidesToShow&quot;:2}},{&quot;breakpoint&quot;: 768,&quot;settings&quot;: {&quot;slidesToShow&quot;: 1}},{&quot;breakpoint&quot;: 576,&quot;settings&quot;: {&quot;slidesToShow&quot;: 1}}]}">
        <div class="slick-prev slick-arrow slick-disabled" aria-label="Previous" aria-disabled="true" style="">
          <i class="far fa-angle-left"></i>
        </div>
        <div class="slick-list draggable" style="height: 401.812px;">
          <div class="slick-track" style="opacity: 1; width: 1110px; transform: translate3d(0px, 0px, 0px);">

            <div *ngFor="let similarpropertie of this.similarpropertieslist; let i = index">

              <div class="box slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
                style="width: 370px;" tabindex="0">
                <div class="card shadow-hover-2">
                  <a (click)="navigateToPropertyDetails(similarpropertie.IdProperty)">
                    <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                      <img src="{{similarpropertie.imageurl}}" alt="" class="responsivegridimage">
                      <div class="card-img-overlay p-2 d-flex flex-column">
                        <div>
                          <span class="badge mr-2 badge-primary">{{similarpropertie.PropertySellType}}</span>
                        </div>

                      </div>
                    </div>
                  </a>
                  <div class="card-body pt-3">
                    <h2 class="card-title fs-16 lh-2 mb-0"><a
                        (click)="navigateToPropertyDetails(similarpropertie.IdProperty)" class="text-dark hover-primary"
                        tabindex="0" style="cursor: pointer">{{similarpropertie.Name}}</a></h2>
                    <p class="card-text font-weight-500 text-gray-light mb-2">
                    </p>
                    <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="" data-original-title="Bedroom">
                        <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                          <use xlink:href="#icon-bedroom"></use>
                        </svg>{{similarpropertie.Beds}}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="" data-original-title="Bathrooms">
                        <svg class="icon icon-shower fs-18 text-primary mr-1">
                          <use xlink:href="#icon-shower"></use>
                        </svg>{{similarpropertie.Baths}}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5-2"
                        data-toggle="tooltip" title="" data-original-title="Size">
                        <svg class="icon icon-square fs-18 text-primary mr-1">
                          <use xlink:href="#icon-square"></use>
                        </svg>{{similarpropertie.SquareFeet}}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="" data-original-title="Garage">
                        <svg class="icon icon-Garage fs-18 text-primary mr-1">
                          <use xlink:href="#icon-Garage"></use>
                        </svg>{{similarpropertie.Garage}}
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                    <p class="fs-17 font-weight-bold text-heading mb-0">{{similarpropertie.Price}}
                      <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                    </p>

                  </div>
                </div>
              </div>

            </div>


            <!--
                        <div class="box slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
                            style="width: 370px;" tabindex="0">
                            <div class="card shadow-hover-2">
                                <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                    <img src="assets/images/properties-grid-38.jpg" alt="Home in Metric Way">
                                    <div class="card-img-overlay p-2 d-flex flex-column">
                                        <div>
                                            <span class="badge mr-2 badge-primary">for Sale</span>
                                        </div>
                                        <ul class="list-inline mb-0 mt-auto hover-image">
                                            <li class="list-inline-item mr-2" data-toggle="tooltip" title=""
                                                data-original-title="9 Images">
                                                <a href="#" class="text-white hover-primary" tabindex="0">
                                                    <i class="far fa-images"></i><span class="pl-1">9</span>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" data-toggle="tooltip" title=""
                                                data-original-title="2 Video">
                                                <a href="#" class="text-white hover-primary" tabindex="0">
                                                    <i class="far fa-play-circle"></i><span class="pl-1">2</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body pt-3">
                                    <h2 class="card-title fs-16 lh-2 mb-0"><a href="single-property-1.html"
                                            class="text-dark hover-primary" tabindex="0">Home in Metric Way</a></h2>
                                    <p class="card-text font-weight-500 text-gray-light mb-2">1421 San Pedro St, Los
                                        Angeles
                                    </p>
                                    <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bedroom">
                                            <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-bedroom"></use>
                                            </svg>3 Br
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bathrooms">
                                            <svg class="icon icon-shower fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-shower"></use>
                                            </svg>3 Ba
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5-2"
                                            data-toggle="tooltip" title="" data-original-title="Size">
                                            <svg class="icon icon-square fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-square"></use>
                                            </svg>2300 Sq.Ft
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="1 Garage">
                                            <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-Garage"></use>
                                            </svg>1 Gr
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                                    <p class="fs-17 font-weight-bold text-heading mb-0">$1.250.000</p>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-secondary bg-accent border-accent"
                                                data-toggle="tooltip" title="" tabindex="0"
                                                data-original-title="Wishlist"><i class="fas fa-heart"></i></a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                data-toggle="tooltip" title="" tabindex="0"
                                                data-original-title="Compare"><i class="fas fa-exchange-alt"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="box slick-slide slick-active" data-slick-index="1" aria-hidden="false"
                            style="width: 370px;" tabindex="0">
                            <div class="card shadow-hover-2">
                                <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                    <img src="assets/images/properties-grid-01.jpg" alt="Garden Gingerbread House">
                                    <div class="card-img-overlay p-2 d-flex flex-column">
                                        <div>
                                            <span class="badge mr-2 badge-orange">featured</span>
                                            <span class="badge mr-2 badge-indigo">for Sale</span>
                                        </div>
                                        <ul class="list-inline mb-0 mt-auto hover-image">
                                            <li class="list-inline-item mr-2" data-toggle="tooltip" title=""
                                                data-original-title="9 Images">
                                                <a href="#" class="text-white hover-primary" tabindex="0">
                                                    <i class="far fa-images"></i><span class="pl-1">9</span>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" data-toggle="tooltip" title=""
                                                data-original-title="2 Video">
                                                <a href="#" class="text-white hover-primary" tabindex="0">
                                                    <i class="far fa-play-circle"></i><span class="pl-1">2</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body pt-3">
                                    <h2 class="card-title fs-16 lh-2 mb-0"><a href="single-property-1.html"
                                            class="text-dark hover-primary" tabindex="0">Garden Gingerbread House</a>
                                    </h2>
                                    <p class="card-text font-weight-500 text-gray-light mb-2">1421 San Pedro St, Los
                                        Angeles
                                    </p>
                                    <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bedroom">
                                            <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-bedroom"></use>
                                            </svg>3 Br
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bathrooms">
                                            <svg class="icon icon-shower fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-shower"></use>
                                            </svg>3 Ba
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5-2"
                                            data-toggle="tooltip" title="" data-original-title="Size">
                                            <svg class="icon icon-square fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-square"></use>
                                            </svg>2300 Sq.Ft
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="1 Garage">
                                            <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-Garage"></use>
                                            </svg>1 Gr
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                                    <p class="fs-17 font-weight-bold text-heading mb-0">$550<span
                                            class="text-gray-light font-weight-500 fs-14"> / month</span></p>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                data-toggle="tooltip" title="" tabindex="0"
                                                data-original-title="Wishlist"><i class="far fa-heart"></i></a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                data-toggle="tooltip" title="" tabindex="0"
                                                data-original-title="Compare"><i class="fas fa-exchange-alt"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="box slick-slide" data-slick-index="2" aria-hidden="true" style="width: 370px;"
                            tabindex="-1">
                            <div class="card shadow-hover-2">
                                <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                    <img src="assets/images/properties-grid-02.jpg" alt="Affordable Urban House">
                                    <div class="card-img-overlay p-2 d-flex flex-column">
                                        <div>
                                            <span class="badge mr-2 badge-primary">for Sale</span>
                                        </div>
                                        <ul class="list-inline mb-0 mt-auto hover-image">
                                            <li class="list-inline-item mr-2" data-toggle="tooltip" title=""
                                                data-original-title="9 Images">
                                                <a href="#" class="text-white hover-primary" tabindex="-1">
                                                    <i class="far fa-images"></i><span class="pl-1">9</span>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" data-toggle="tooltip" title=""
                                                data-original-title="2 Video">
                                                <a href="#" class="text-white hover-primary" tabindex="-1">
                                                    <i class="far fa-play-circle"></i><span class="pl-1">2</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body pt-3">
                                    <h2 class="card-title fs-16 lh-2 mb-0"><a href="single-property-1.html"
                                            class="text-dark hover-primary" tabindex="-1">Affordable Urban House</a>
                                    </h2>
                                    <p class="card-text font-weight-500 text-gray-light mb-2">1421 San Pedro St, Los
                                        Angeles
                                    </p>
                                    <ul class="list-inline d-flex mb-0 flex-wrap mr-n5">
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bedroom">
                                            <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-bedroom"></use>
                                            </svg>3 Br
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="3 Bathrooms">
                                            <svg class="icon icon-shower fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-shower"></use>
                                            </svg>3 Ba
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5-2"
                                            data-toggle="tooltip" title="" data-original-title="Size">
                                            <svg class="icon icon-square fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-square"></use>
                                            </svg>2300 Sq.Ft
                                        </li>
                                        <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                            data-toggle="tooltip" title="" data-original-title="1 Garage">
                                            <svg class="icon icon-Garage fs-18 text-primary mr-1">
                                                <use xlink:href="#icon-Garage"></use>
                                            </svg>1 Gr
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                                    <p class="fs-17 font-weight-bold text-heading mb-0">$1.250.000</p>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                data-toggle="tooltip" title="" tabindex="-1"
                                                data-original-title="Wishlist"><i class="far fa-heart"></i></a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#"
                                                class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                                                data-toggle="tooltip" title="" tabindex="-1"
                                                data-original-title="Compare"><i class="fas fa-exchange-alt"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    -->

          </div>
        </div>


        <div class="slick-next slick-arrow" aria-label="Next" style="" aria-disabled="false"><i
            class="far fa-angle-right"></i></div>
      </div>
    </section>


  </div>
  <!--</ng-container>-->

  <ng-container *ngIf="this.istheownerloggeduser">
    <div id="bottom">
      <button id="bot_open" class="btn btn-primary btn-rounded" (click)="navigateToPropertyEdit()">Edit
        Property</button>
    </div>
  </ng-container>
</section>
