<main *ngIf="!this.loading" id="content">

  <section class="pt-8 pb-11 bg-gray-01">
    <div style="position: relative">

      <ngx-ui-loader></ngx-ui-loader>

    </div>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb py-3">
          <li class="breadcrumb-item fs-12 letter-spacing-087">
            <a routerLink="/home">Home</a>
          </li>
          <li class="breadcrumb-item fs-12 letter-spacing-087 active">
            Listing
          </li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-lg-4 order-2 order-lg-1 primary-sidebar sidebar-sticky" id="sidebar">
          <div class="primary-sidebar-inner">
            <div class="card mb-4">
              <div class="card-body px-6 py-4">

                <h4 class="card-title fs-16 lh-2 text-dark mb-3">Find your service</h4>

                <form [formGroup]="formfilterproperty" class="form" (ngSubmit)="getAllServices()">
                  <div class="form-group">
                    <label for="keyword" class="sr-only">Key Word</label>
                    <input type="text" formControlName="keyword"
                      class="form-control form-control-lg border-0 shadow-none" id="keyword" name="keyword"
                      placeholder="Enter keyword...">
                  </div>
                  <div class="form-group">
                    <label for="propertycategory" class="text-heading">Service Category</label>
                    <select class="form-control border-0 shadow-none form-control-lg" name="propertycategory"
                      title="Property Category" data-style="btn-lg py-2 h-52" id="propertycategory"
                      (change)="selectCategoryOption($event)">
                      <option *ngFor="let propertycategory of lstpropertylist" [ngValue]="propertycategory.id"
                        id="propertycategory" name="propertycategory" [selected]=propertycategory.isSelected>
                        {{
                                                propertycategory.desctext }}</option>
                    </select>
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block shadow-none mt-4">Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 mb-8 mb-lg-0 order-1 order-lg-2">
          <div class="row align-items-sm-center mb-4">
            <div class="col-md-6">
              <h2 class="fs-15 text-dark mb-0">We found <span class="text-primary">{{ this.listServices.length
                                    }}</span>
                services
                available for
                you
              </h2>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <div class="d-flex justify-content-md-end align-items-center">
                <div class="input-group border rounded input-group-lg w-auto bg-white mr-3">
                  <div style="display: flex; align-items: center; padding: 0.85rem 1.5rem; position: relative;">
                    <p class="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093"
                      style="margin-bottom: 0 !important; padding: 0 0.75rem 0 0 !important;"><i
                        class="fas fa-align-left fs-16"></i>SortBy:</p>
                    <div>
                      <p (click)="this.showSortBy = !this.showSortBy"
                        style="margin-bottom: 0 !important; cursor: default; font-weight: 600;">{{this.label}} <fa-icon
                          [icon]="faAngleDown">
                        </fa-icon>
                      </p>
                    </div>
                    <div *ngIf="this.showSortBy" class="sort-properties sort-top">
                      <ul style="list-style: none; padding-left: 0 !important;">
                        <li [ngClass]="{'line-down': i !== this.options.length - 1}"
                          *ngFor="let option of this.options; let i = index"
                          (click)="onChangeorderby(option.value, option.option)">
                          {{option.option}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!this.showpropertylistGrid">
                  <div class="d-none d-md-block">
                    <a class="fs-sm-18 text-dark">
                      <i class="fas fa-list"></i>
                    </a>
                    <a class="fs-sm-18 text-dark opacity-2 ml-5" style="cursor: pointer"
                      (click)="changepropertylistGrid()">
                      <i class="fa fa-th-large"></i>
                    </a>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.showpropertylistGrid">
                  <div class="d-none d-md-block">
                    <a class="fs-sm-18 text-dark opacity-2" style="cursor: pointer" (click)="changepropertylistGrid()">
                      <i class="fas fa-list"></i>
                    </a>
                    <a class="fs-sm-18 text-dark ml-5">
                      <i class="fa fa-th-large"></i>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!this.showpropertylistGrid">

            <div
              *ngFor="let propertie of this.listServices | paginate: { itemsPerPage: 7, currentPage: page, totalItems: count }; let i = index">
              <ng-container *ngIf="propertie != undefined">
                <div [ngClass]="{'emphasis': propertie.Highlight}"
                  class="py-5 px-4 border rounded-lg shadow-hover-1 bg-white mb-4 fadeInUp animated">
                  <div *ngIf="propertie.Highlight" class="emphasis-text">
                    <fa-icon class="text-position star-position" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="media flex-column flex-sm-row no-gutters">
                    <div class="col-sm-3 mr-sm-5 card border-0 hover-change-image bg-hover-overlay mb-sm-5"
                      (click)="navigateToPropertyDetails(propertie.id)">

                      <ng-container *ngIf="propertie.image != null">
                        <img src="{{this.apiurl}}/{{propertie.image}}" class="card-img responsivelistimage"
                          alt="{{propertie.name}}">
                      </ng-container>
                      <ng-container *ngElse>
                        <img src="../../assets/images//service1.jpg" class="card-img responsivelistimage"
                          alt="{{propertie.name}}">
                      </ng-container>

                    </div>
                    <div class="media-body mt-3 mt-sm-0">
                      <h2 class="my-0"><a (click)="navigateToPropertyDetails(propertie.id)" style="cursor: pointer"
                          class="fs-16 lh-2 text-dark hover-primary d-block props-link">{{propertie.name}}</a>
                      </h2>
                      <p class="mb-1 font-weight-500 text-gray-light">{{propertie.OwnerName}}
                      </p>
                      <p class="mb-2 ml-0 desc">{{ propertie.description }} </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="this.showpropertylistGrid">
            <div class="row">

              <div
                *ngFor="let propertie of this.listServices | paginate: { itemsPerPage:7, currentPage: page, totalItems: count }; let i = index"
                class="col-md-6 mb-6">
                <div class="card border-0 fadeInUp animated">
                  <a (click)="navigateToPropertyDetails(propertie.id)">
                    <div class="position-relative hover-change-image bg-hover-overlay rounded-lg card-img">
                      <ng-container *ngIf="propertie.image != null">
                        <img src="{{this.apiurl}}/{{propertie.image}}" alt="Home in Metric Way"
                          class="responsivegridimage">
                      </ng-container>
                      <ng-container *ngElse>
                        <img src="../../assets/images//service1.jpg" alt="Home in Metric Way"
                          class="responsivegridimage">
                      </ng-container>
                      <div class="card-img-overlay d-flex flex-column">
                        <div class="mt-auto d-flex hover-image">

                        </div>
                      </div>
                    </div>
                  </a>
                  <div class="card-body pt-3 px-0 pb-1" style="margin-left:0.5em">
                    <h2 class="fs-16 mb-1"><a (click)="navigateToPropertyDetails(propertie.id)"
                        class="text-dark hover-primary" style=" text-indent: 10px; "
                        style="cursor: pointer">{{propertie.name}}</a>
                    </h2>
                    <p class="font-weight-500 text-gray-light mb-0">{{propertie.OwnerName}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div style="margin-top: 2rem;" class="d-flex justify-content-center">
            <div class="pagination-desktop">
              <pagination-template #p="paginationApi" (pageChange)="page = $event">
                <div class="custom-pagination">
                  <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                    <span (click)="p.previous()">
                      < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value">
                        <span (click)="p.setCurrent(page.value)"
                          *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                        <div class="pages" *ngIf="p.getCurrent() === page.value">
                          <span>{{ page.label }}</span>
                        </div>
                  </div>
                  <div class="pagination-next" [class.disabled]="p.isLastPage()">
                    <span (click)="toNext(p)"> > </span>
                  </div>
                </div>
              </pagination-template>
            </div>
            <div class="pagination-mobile">
              <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>
