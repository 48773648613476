<section class="py-13" data-animated-id="1">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 mx-auto">
                <div class="card border-0 shadow-xxs-2 login-register">
                    <div class="card-body p-6">
                        <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Confirm registration</h2>

                        <ng-container *ngIf="showerror">
                            <p class="mb-4">Upsss! Error to confirm your registration. Please contact us by email <strong>admin@roundcomb.com</strong></p>
                        </ng-container>
                        <ng-container *ngIf="!showerror">
                            <p class="mb-4">Welcome! User active now. Please <a (click)="navigateToLoginPage()">login</a> to start using our platform.</p>
                        </ng-container>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>