
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">CREATE NEW AMENITIE</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <form>
                  <div class="form-group">
                      <label for="item-name" class="col-form-label">Name:</label>
                      <input type="text" class="form-control" id="item-name" value = "{{this.name}}" (change)="updatevalue($event,'name')" >
                    </div>

                    <div class="form-group">
                      <label for="item-description" class="col-form-label">Description:</label>
                      <textarea rows="3" class="form-control" id="item-description" value = "{{this.description}}" (change)="updatevalue($event,'description')"></textarea>
                    </div>
                </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="closeModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="savenewservice()">Create Amenitie</button>
          </div>
