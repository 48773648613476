import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-addincludedservicesmodal',
  templateUrl: './addincludedservicesmodal.component.html',
  styleUrls: ['./addincludedservicesmodal.component.css']
})
export class AddincludedservicesmodalComponent implements OnInit {


  public price:string = "";
  public name:string = "";
  public description:string = "";
  public action:string = "close";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() { 

    this.activeModal.close(); 
  }

  updatevalue(event:any,component:string)
  {
    if(component == 'price')
      this.price = event.target.value;
      else
        if(component == 'name')
        this.name = event.target.value;
        else
          if(component == 'description')
            this.description = event.target.value;
  }

  savenewservice()
  {
    const data = {
      price: this.price,
      name: this.name,
      description: this.description
    }

    this.activeModal.close(data);
  }
}
