import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { propertiesService } from '../../services';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.css']
})
export class ViewContractComponent implements OnInit {

  mycontent: string = '';
  mycontentWithReplacedVariables: string = '';
  ckeConfig: CKEDITOR.config;

  showupdatemessage:boolean = false;
  showEditor:boolean = false;
  pdfSrc:any;// = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  @Input() fromParent;
  constructor(public activeModal: NgbActiveModal,private propertiesService: propertiesService) { }



  ngOnInit(): void {

    this.mycontent = this.fromParent.prop3;
    this.getContractWithReplacedVariables();

  }

  closeModal() {

    this.activeModal.close();

  }

  saveContract()
  {

    var JSONTemplateToNewProperty = '{"name":"","HtmlContent":[&HtmlContent],"IdEvent":"[&IdEvent]","propertyID":"[&propertyID]"}'

    JSONTemplateToNewProperty = JSONTemplateToNewProperty.replace('[&propertyID]', this.fromParent.prop1);
    JSONTemplateToNewProperty = JSONTemplateToNewProperty.replace('[&IdEvent]', this.fromParent.prop2).replace('[&HtmlContent]',JSON.stringify(this.mycontent));


       // just update the contract
       this.propertiesService.createNewContractDocument(JSONTemplateToNewProperty).pipe()
       .subscribe(
         data => {
            this.showupdatemessage = true;
         },
         error => {


       });
  }


  public getContractWithReplacedVariables()
  {

    var jsonrequest:string = '{"IdEvent":"[&IdEvent]","IdProperty":"[&Idproperty]","HTMLContent":[&HTMLContent]}'.replace('[&IdEvent]',this.fromParent.prop2).replace('[&Idproperty]',this.fromParent.prop1);
    jsonrequest= jsonrequest.replace('[&HTMLContent]',JSON.stringify(this.mycontent));

    // this.ContractReplaceVariables();

     this.propertiesService.GetContractReplacedByPropertyId(jsonrequest).pipe()
     .subscribe(
       dataContent => {


        this.mycontentWithReplacedVariables = JSON.parse(dataContent);

        this.exportHtmlToPDF();

        this.showEditor = false;

       },
       error => {

       });
  }

  public exportHtmlToPDF(){


    this.propertiesService.convertHTMLToPDF(this.mycontentWithReplacedVariables ).pipe()
    .subscribe(
      dataContent => {


        this.pdfSrc = dataContent;
      },
      error => {

      });
  }


  previewContract()
  {
    this.getContractWithReplacedVariables();
  }
  editContract()
  {
    this.showEditor = true;
  }

}
