import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public innerWidth: number = window.innerWidth;
  public year: string;
  public loading: any = true;


  constructor(private router: Router) { }

  ngOnInit() {
    const dttoday = moment();
    this.year = dttoday.format("YYYY");
  }

  ngAfterViewInit(): void {
    this.loading = false
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  navigateToPage(destpage:string,paramname:string,paramvalue:string,)
  {

  /*
    let activeDiv = document.getElementById("topmenu");

    if(activeDiv.classList.contains("show"))
      activeDiv.classList.remove('show');

      window.scrollTo(0,0);
*/
    if(paramname =='')
      this.router.navigate(['/'+destpage]);
    else
    {
      if(paramname == 'propertytype')
        this.router.navigate(['/'+destpage], { queryParams: { propertytype: paramvalue } });
    }

  }

}
