
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">PREVIEW DOCUMENT</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ngx-doc-viewer
            [url]="this.url"
            viewer="office"
            style="width:100%;height:50vh;"
            
          >
          </ngx-doc-viewer>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
          </div>
  