<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
  <div class="container" style="display: flex;justify-content: center;">


    <div class="card border-0 mb-4">
      <div class="card-body p-0 p-sm-8 p-4">
        <div class="media border-top pt-7 pb-6 d-sm-flex d-block text-sm-left text-center">
          <div
            class="w-82px h-82 mr-2 bg-gray-01 rounded-circle fs-25 font-weight-500 text-muted d-flex align-items-center justify-content-center text-uppercase mr-sm-8 mb-4 mb-sm-0 mx-auto">
            HL
          </div>
          <div class="media-body">
            <div class="row mb-1 align-items-center">
              <div class="col-sm-6 mb-2 mb-sm-0">
                <h4 class="mb-0 text-heading fs-14"> H. Lee</h4>
              </div>
              <div class="col-sm-6">
                <ul class="list-inline d-flex justify-content-sm-end justify-content-center mb-0">
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                </ul>
              </div>
            </div>
            <p class="mb-3 pr-xl-17">Great app! 100% recommended.</p>
            <div class="d-flex justify-content-sm-start justify-content-center">
              <p class="mb-0 text-muted fs-13 lh-1">02 Dec 2020 at 2:40pm</p>

            </div>
          </div>
        </div>
        <div class="media border-top py-6 d-sm-flex d-block text-sm-left text-center">
          <div
            class="w-82px h-82 mr-2 bg-gray-01 rounded-circle fs-25 font-weight-500 text-muted d-flex align-items-center justify-content-center text-uppercase mr-sm-8 mb-4 mb-sm-0 mx-auto">
            SM
          </div>
          <div class="media-body">
            <div class="row mb-1 align-items-center">
              <div class="col-sm-6 mb-2 mb-sm-0">
                <h4 class="mb-0 text-heading fs-14">Sandy M.</h4>
              </div>
              <div class="col-sm-6 ">
                <ul class="list-inline d-flex justify-content-sm-end justify-content-center mb-0">
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                </ul>
              </div>
            </div>
            <p class="mb-3 pr-xl-17">I couldn’t find an apartment for a long time.
              I tried to use Roundcomb and now I live in gorgeous villa. Thanks a lot!</p>
            <div class="d-flex justify-content-sm-start justify-content-center">
              <p class="mb-0 text-muted fs-13 lh-1">02 Dec 2020 at 2:40pm</p>

            </div>
          </div>
        </div>
        <div class="media border-top py-6 d-sm-flex d-block text-sm-left text-center">
          <div
            class="w-82px h-82 mr-2 bg-gray-01 rounded-circle fs-25 font-weight-500 text-muted d-flex align-items-center justify-content-center text-uppercase mr-sm-8 mb-4 mb-sm-0 mx-auto">
            PB
          </div>
          <div class="media-body">
            <div class="row mb-1 align-items-center">
              <div class="col-sm-6 mb-2 mb-sm-0">
                <h4 class="mb-0 text-heading fs-14">Pitt B.</h4>
              </div>
              <div class="col-sm-6 ">
                <ul class="list-inline d-flex justify-content-sm-end justify-content-center mb-0">
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                  <li class="list-inline-item mr-1">
                    <span class="text-warning fs-12 lh-2"><i class="fas fa-star"></i></span>
                  </li>
                </ul>
              </div>
            </div>
            <p class="mb-3 pr-xl-17">Highly recommended if you are currently searching for apartment. 10 of 10.</p>
            <div class="d-flex justify-content-sm-start justify-content-center">
              <p class="mb-0 text-muted fs-13 lh-1">02 Dec 2020 at 2:40pm</p>

            </div>
          </div>
        </div>


      </div>


    </div>



  </div>

  <div style="display: flex;justify-content: center;">
    <a routerLink="/feedback" class="btn btn-lg btn-primary px-9">SEND YOUR TESTIMONIAL</a>
  </div>


</section>
