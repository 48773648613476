<section style="margin-top: 30px;">
  <div class="page-content">
    <main>
      <h1 class="component__title">
        Templates
      </h1>
      <div>
        <div [ngStyle]="{'margin-bottom.px': this.checkArr.length > 0 ? '0' : '300'}" class="templates-card">
          <div class="shadow card-edit" style=" margin-bottom: 30px;"
            *ngFor="let templates of this.emailTemplates; let i = index">
            <div>
              <h3>{{templates.Name}}</h3>
            </div>
            <button style="width: 100%;" (click)="selectTemplate(i)" class="templates-button">Edit</button>
          </div>
        </div>
        <div *ngIf='this.checkArr.length > 0'>
          <div style="display: flex;
           justify-content: center;">
            <ckeditor [(ngModel)]="this.filteredTemplate" name="myckeditor" required [config]="ckeConfig" debounce="500"
              #myckeditor="ngModel" (change)="onPaste($event)">
              <ckbutton [name]="'saveButton'" [command]="'insert_name'" [label]="'Insert User Name'"
                [toolbar]="'clipboard,1'">
              </ckbutton>
            </ckeditor>
          </div>
          <div style="display: flex;justify-content: flex-end; margin-top: 30px;">
            <button class="save-template" (click)="updateTemplate()">Save changes</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</section>
