import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router} from '@angular/router';

import { usersService, AuthenticationService} from '../services';
@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.css']
})
export class RecoverpasswordComponent implements OnInit {

  recoverpasswordForm: FormGroup;
  submitted = false;
  errorMessage:string = '';

  constructor( private formBuilder: FormBuilder,
    private userservice: usersService,
    private authenticationService: AuthenticationService,
    private router: Router,) {
    this.loadScripts();
  }

  ngOnInit(): void {
    this.recoverpasswordForm = this.formBuilder.group({
      mail: ['', Validators.required]
    });
  }


    // convenience getter for easy access to form fields
  get f() { return this.recoverpasswordForm.controls; }

  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.recoverpasswordForm.invalid)
    return;

    this.userservice.setRequestForgotPassword(this.f.mail.value)
        .pipe()
        .subscribe(
            data => {
              this.authenticationService.sendMailRegistration(this.f.mail.value, "Recover");
              this.router.navigate(['/recoverpassworddone']);
            },
            error => {
          this.errorMessage = error.errorMessage;
            });
}

  private loadScripts() {
    const dynamicScripts = [
      'assets/js/theme.js',
      'assets/vendors/jquery.min.js',
      'assets/vendors/jquery-ui/jquery-ui.min.js',
      'assets/vendors/bootstrap/bootstrap.bundle.js',
      'assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      'assets/vendors/slick/slick.min.js',
      'assets/vendors/waypoints/jquery.waypoints.min.js',
      'assets/vendors/counter/countUp.js',
      'assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      'assets/vendors/chartjs/Chart.min.js',
      'assets/vendors/dropzone/js/dropzone.min.js',
      'assets/vendors/timepicker/bootstrap-timepicker.min.js',
      'assets/vendors/hc-sticky/hc-sticky.min.js',
      'assets/vendors/jparallax/TweenMax.min.js',
      'assets/vendors/mapbox-gl/mapbox-gl.js',
      'assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js'

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
