import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IPayPalConfig,
  ICreateOrderRequest,
  ITransactionItem,
} from 'ngx-paypal';
import { RatelandlordEndEventComponent } from './../ratelandlord-end-event/ratelandlord-end-event.component';
import { of } from 'rxjs';

export class unit_amount {
  constructor(public currency_code: string, public value: string) {}
}

@Component({
  selector: 'app-paymentmodal',
  templateUrl: './paymentmodal.component.html',
  styleUrls: ['./paymentmodal.component.css'],
})
export class PaymentmodalComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  public paymentmodalresponse: string = '';
  public text: any;

  public paymentItems = new Array<{
    name: string;
    quantity: string;
    category: string;
    unit_amount: unit_amount;
  }>();

  public showAlreadypaid: boolean = false;
  public showError: boolean = false;

  public purchaseItems: ITransactionItem[];

  @Input() fromParent;
  @Input() fromParentId;
  @Input() fromParentServiceId;
  @Input() fromParentText = false;
  @Input() fromParentUserNameB: any;

  @Input() slots: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initPayPalConfig();
    this.text = this.fromParentText;
  }

  closeModal() {
    if (this.showAlreadypaid && !this.slots) this.ratelandlordevent();
    setTimeout(() => {
      this.activeModal.close(this.showAlreadypaid);
    }, 1000);

    //this.activeModal.close(true);
  }
  ratelandlordevent() {
    const modalRef = this.modalService.open(RatelandlordEndEventComponent, {
      windowClass: 'my-cameradialog',
    });

    modalRef.componentInstance.fromParentB = this.fromParentId;
    modalRef.componentInstance.fromParentIdB = this.fromParentServiceId;
    modalRef.componentInstance.fromParentUserNameB = this.fromParentUserNameB;
  }

  private initPayPalConfig(): void {
    var totalvalue: number = 0;

    this.paymentItems = JSON.parse(this.fromParent.prop1);
    var index: number = 0;

    for (index = 0; index < this.paymentItems.length; index++) {
      totalvalue =
        totalvalue + Number(this.paymentItems[index].unit_amount.value);
    }

    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'AZ7CGYX-rNhXAGrOTZbr6yjXxsWfPhtfWS7neMOGye7CJCz1kSzNuLx9liAkUoQVoi-AJzpHeU8O5q4N',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: totalvalue.toString(),
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: totalvalue.toString(),
                  },
                },
              },
              items: this.paymentItems,
              /*items: [{
            name: 'Enterprise Subscription',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'EUR',
              value: '9.99',
            },
          }]*/
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
      },
      onClientAuthorization: (data) => {
        var dispute_categories: string = '';

        var dataauthorization = JSON.parse(
          JSON.stringify(data.purchase_units[0])
        );

        if (
          data.status == 'COMPLETED' &&
          dataauthorization.payments.captures[0].seller_protection.status ==
            'ELIGIBLE'
        ) {
          this.showAlreadypaid = true;
        }
      },
      onCancel: (data, actions) => {
      },
      onError: (err) => {
        this.showError = true;
      },
      onClick: (data, actions) => {
      },
    };
  }
}
