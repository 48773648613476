<header>
  <div class="container" style="height: 92px;" id="topmenu">
    <input type="checkbox" name="" id="check" [(ngModel)]="ishamburgeropen">

    <div class="logo-container">
      <a href="#">
        <img src="assets/images/logo.png" class="home-logo" alt="RoundComb">
      </a>
    </div>

    <div class="nav-btn">
      <div class="nav-links">

        <ul>

          <li [ngClass]="{'selected': this.url === '/'}" class="nav-link" style="--i: .6s">
            <a href="#">Home</a>
          </li>
          <li class="nav-link " data-toggle="slide-collapse" aria-haspopup="true" aria-expanded="false"
            style="--i: .85s">
            <a (click)="togglenavbar('navbarroundcomb')">RoundComb<i style="margin-left: 3px;" class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarroundcomb">
              <ul>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarabout')">About<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarabout">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/whoweare'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/whoweare'}" (click)="navigateToPage('whoweare','','')">Who we are</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/about'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/about'}" (click)="navigateToPage('about','','')">About Us</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/ourstory'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/ourstory'}" (click)="navigateToPage('ourstory','','')">Our Story</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/ourmission'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/ourmission'}" (click)="navigateToPage('ourmission','','')">Our Mission</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarnewspress')">News & Press<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarnewspress">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/inthenews'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/inthenews'}" (click)="navigateToPage('inthenews','','')">In the News</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/pressmedia'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/pressmedia'}" (click)="navigateToPage('pressmedia','','')">Press Media</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="dropdown-link">
                  <a (click)="togglenavbar('navbarhelp')">Help<i class="fas fa-caret-down"></i></a>
                  <div class="dropdown second" id="navbarhelp">
                    <ul>
                      <li [ngClass]="{'selected': this.url === '/glossary'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/glossary'}" (click)="navigateToPage('glossary','','')">Glossary</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/accountandprofile'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/accountandprofile'}" (click)="navigateToPage('accountandprofile','','')">Account and Profile</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/Signingup'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/Signingup'}" (click)="navigateToPage('Signingup','','')">Signing Up</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/showcasingyourpropertyholdings'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/showcasingyourpropertyholdings'}" (click)="navigateToPage('showcasingyourpropertyholdings','','')">Showcasing your Property
                          Holdings</a>
                      </li>
                      <li [ngClass]="{'selected': this.url === '/updatingratingsandrentalhistory'}" class="dropdown-link">
                        <a [ngClass]="{'selected': this.url === '/updatingratingsandrentalhistory'}" (click)="navigateToPage('updatingratingsandrentalhistory','','')">Updating Ratings and Rental
                          History</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li [ngClass]="{'selected': this.url === '/feedback'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/feedback'}" (click)="navigateToPage('feedback','','')">Feedback</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/testimonials'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/testimonials'}" (click)="navigateToPage('testimonials','','')">Testimonials</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/termsconditions'}" class="dropdown-link">
                  <a  [ngClass]="{'selected': this.url === '/termsconditions'}"(click)="navigateToPage('termsconditions','','')">Terms & Conditions</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/privacypolicy'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/privacypolicy'}" (click)="navigateToPage('privacypolicy','','')">Privacy Policy</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/careers'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/careers'}" (click)="navigateToPage('careers','','')">Careers</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/contactus'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/contactus'}" (click)="navigateToPage('contactus','','')">Contact Us</a>
                </li>
                <!--<div class="arrow"></div>-->
              </ul>
            </div>
          </li>
          <li class="nav-link" style="--i: 1.1s">
            <a (click)="togglenavbar('navbarproperties')">Properties<i class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarproperties">
              <ul>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=All%20Properties'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=All%20Properties'}" (click)="navigateToPage('filterproperty','propertytype','All Properties')">All Properties</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Apartment'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Apartment'}" (click)="navigateToPage('filterproperty','propertytype','Apartment')">Apartment</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20Family'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20Family'}" (click)="navigateToPage('filterproperty','propertytype','Single Family')">Single Family</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20House'}"  class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%20House'}" (click)="navigateToPage('filterproperty','propertytype','Single House')">Single House</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%Luxury'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Single%Luxury'}" (click)="navigateToPage('filterproperty','propertytype','Single Luxury')">Single Luxury</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Townhouse'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Townhouse'}" (click)="navigateToPage('filterproperty','propertytype','Townhouse')">Townhouse</a>
                </li>
                <li [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Villa'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterproperty?propertytype=Villa'}" (click)="navigateToPage('filterproperty','propertytype','Villa')">Villa</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-link" style="--i: 1.1s">
            <a (click)="togglenavbar('navbarservices')">Vendors<i class="fas fa-caret-down"></i></a>
            <div class="dropdown" id="navbarservices">
              <ul>
                <li [ngClass]="{'selected': this.url === '/filterservice?servicetype=All%20Services'}" class="dropdown-link">
                  <a [ngClass]="{'selected': this.url === '/filterservice?servicetype=All%20Services'}" (click)="navigateToPage('filterservice','servicetype','All Services')">All Services</a>
                </li>
                <!-- <li class="dropdown-link">
                  <a (click)="navigateToPage('vendors','','')">All Vendors</a>
                </li> -->
              </ul>
            </div>
          </li>
        </ul>

        <div class="logged-user">
          <div class="position-relative py-8">
            <div class="bottom-bar-action py-2 px-4 bg-gray-01 position-absolute fixed-bottom">
              <div class="container">
                <div class="row no-gutters mx-n2 mxw-571 mx-auto" style="align-content: center;">
                  <div class="px-2">
                    <table border="0" style="text-align: center;">
                      <thead>
                        <tr>
                          <td>
                            <button type="button" class="btn btn-outline-secondary" style="border-color: #31499c;"
                              (click)="navigateToPage('login','','')">Sign in</button>
                          </td>
                          <td>
                            <button type="button" class="btn btn-outline-secondary" style="border-color: #31499c;"
                              (click)="navigateToPage('newuser','','')">Register</button>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="log-sign" style="--i: 1.8s">
        <button type="button" class="btn btn-outline-secondary" style="border-color: #31499c;"
          (click)="navigateToPage('login','','')">Sign in</button>
        <button type="button" class="btn btn-outline-secondary" style="border-color: #31499c;"
          (click)="navigateToPage('newuser','','')">Register</button>
      </div>


    </div>


    <div class="hamburger-menu-container">
      <div class="hamburger-menu">
        <div></div>
      </div>
    </div>
  </div>
</header>
