import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, Route } from '@angular/router';

import { FilterpropertyComponent } from './filterproperty/filterproperty.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { RecoverpasswordComponent } from './recoverpassword/recoverpassword.component';
import { DashboardComponent } from './Dashboard/dashboard.component';
import { WalletComponent } from './wallet/wallet.component';
import { LoginComponent } from './loginpage';
import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { MyprofileComponent } from './user/myprofile/myprofile.component';
import { PropertydetailComponent } from './propertydetail/propertydetail.component';
import { VendorsComponent } from './vendors/vendors.component';
import { RegisternewuserComponent } from './registernewuser/registernewuser.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ApplicationhistoryComponent } from './applicationhistory/applicationhistory.component';
import { RegistrationsucessedComponent } from './registernewuser/registrationsucessed/registrationsucessed.component';
import { ResetpasswordsucessedComponent } from './resetpassword/resetpasswordsucessed/resetpasswordsucessed.component';
import { RecoverpasswordsucessedComponent } from './recoverpassword/recoverpasswordsucessed/recoverpasswordsucessed.component';
import { ConfirmuserregistrationComponent } from './confirmuserregistration/confirmuserregistration.component';
import { ForgotPasswordRemindComponent } from './forgot-password-remind/forgot-password-remind.component';
import { AboutComponent } from './staticpages/about/about.component';
import { OurstoryComponent } from './staticpages/ourstory/ourstory.component';
import { OurmissionComponent } from './staticpages/ourmission/ourmission.component';
import { InthenewsComponent } from './staticpages/inthenews/inthenews.component';
import { GlossaryComponent } from './staticpages/glossary/glossary.component';
import { AccountandprofileComponent } from './staticpages/accountandprofile/accountandprofile.component';
import { SigningupComponent } from './staticpages/signingup/signingup.component';
import { ShowcasingyourpropertyholdingsComponent } from './staticpages/showcasingyourpropertyholdings/showcasingyourpropertyholdings.component';
import { UpdatingratingsandrentalhistoryComponent } from './staticpages/updatingratingsandrentalhistory/updatingratingsandrentalhistory.component';
import { FeedbackComponent } from './staticpages/feedback/feedback.component';
import { TestimonialsComponent } from './staticpages/testimonials/testimonials.component';
import { TermsconditionsComponent } from './staticpages/termsconditions/termsconditions.component';
import { PrivacypolicyComponent } from './staticpages/privacypolicy/privacypolicy.component';
import { CareersComponent } from './staticpages/careers/careers.component';
import { ContactusComponent } from './staticpages/contactus/contactus.component';
import { WhoweareComponent } from './staticpages/whoweare/whoweare.component';
import { PressmediaComponent } from './staticpages/pressmedia/pressmedia.component';
import { CareersapplyComponent } from './staticpages/careersapply/careersapply.component';
import { CropprofileimageComponent } from './user/myprofile/cropprofileimage/cropprofileimage.component';
import { EventsComponent } from './events/events.component';
import { MypropertiesComponent } from './myproperties/myproperties.component';
import { MyleasesComponent } from './myleases/myleases.component';
import { AddneweventComponent } from './addnewevent/addnewevent.component';
import { ChatComponent } from './chat/chat.component';
import { AddnewpropertyComponent } from './addnewproperty/addnewproperty.component';
import { MobilephonetakephotoComponent } from './mobilephonetakephoto/mobilephonetakephoto.component';
import { EditpropertyComponent } from './editproperty/editproperty.component';
import { EditServicesComponent } from './editservices/editservices.component';
import { EventsConfigurationComponent } from './configuration/events/events.component';
import { UsersConfigurationComponent } from './configuration/users/users.component';
import { EmailComponent } from './configuration/email/email.component';
import { ModalsubscriptionsComponent } from './modalsubscriptions/modalsubscriptions.component';

import { AuthGuard } from './helpers';
import { AddnewserviceComponent } from './addnewservice/addnewservices.component';
import { MyServicesComponent } from './myservices/myservices.component';
import { EventsPayment } from './eventspayment/eventspayment.component';
import { ServicedetailComponent } from './servicedetail/servicedetail.component';
import { FilterServiceComponent } from './filterservice/filterservice.component';
import { RatingsComponent } from './ratings/ratings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InvitesComponent } from './invites/invites.component';
import { Observable, of } from 'rxjs';

const routes: Routes = [
  { path: '', component: LandingpageComponent, data: { preload: true } },
  { path: 'home', component: LandingpageComponent, data: { preload: true } },
  { path: 'filterproperty', component: FilterpropertyComponent },
  { path: 'filterservice', component: FilterServiceComponent },
  { path: 'recoverpassword', component: RecoverpasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'alleventspayments', component: EventsPayment },
  { path: 'userreviews', component: RatingsComponent },
  {
    path: 'myprofile',
    component: MyprofileComponent,
    canActivate: [AuthGuard],
  },
  { path: 'propertydetail', component: PropertydetailComponent },
  { path: 'servicedetail', component: ServicedetailComponent },
  { path: 'vendors', component: VendorsComponent },
  { path: 'newuser', component: RegisternewuserComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'invites', component: InvitesComponent },
  {
    path: 'resetpassword',
    component: ResetpasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptions',
    component: SubscriptionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'preferences',
    component: PreferencesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'applicationhistory',
    component: ApplicationhistoryComponent,
    canActivate: [AuthGuard],
  },
  { path: 'userregisterdone', component: RegistrationsucessedComponent },
  { path: 'resetpassworddone', component: ResetpasswordsucessedComponent },
  { path: 'recoverpassworddone', component: RecoverpasswordsucessedComponent },
  {
    path: 'confirmuserregistration',
    component: ConfirmuserregistrationComponent,
  },
  { path: 'about', component: AboutComponent },
  { path: 'wallet', component: WalletComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'ourstory', component: OurstoryComponent },
  { path: 'ourmission', component: OurmissionComponent },
  { path: 'inthenews', component: InthenewsComponent },
  { path: 'glossary', component: GlossaryComponent },
  { path: 'accountandprofile', component: AccountandprofileComponent },
  { path: 'Signingup', component: SigningupComponent },
  {
    path: 'showcasingyourpropertyholdings',
    component: ShowcasingyourpropertyholdingsComponent,
  },
  {
    path: 'updatingratingsandrentalhistory',
    component: UpdatingratingsandrentalhistoryComponent,
  },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'termsconditions', component: TermsconditionsComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'whoweare', component: WhoweareComponent },
  { path: 'pressmedia', component: PressmediaComponent },
  { path: 'careersapply', component: CareersapplyComponent },
  { path: 'cropimage', component: CropprofileimageComponent },
  { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
  {
    path: 'myproperties',
    component: MypropertiesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myservices',
    component: MyServicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addnewservices',
    component: MypropertiesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'myleases', component: MyleasesComponent, canActivate: [AuthGuard] },
  {
    path: 'addnewevent',
    component: AddneweventComponent,
    canActivate: [AuthGuard],
  },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  {
    path: 'addnewproperty',
    component: AddnewpropertyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addnewservice',
    component: AddnewserviceComponent,
    canActivate: [AuthGuard],
  },
  { path: 'mobilephonetakephoto', component: MobilephonetakephotoComponent },
  {
    path: 'editproperty',
    component: EditpropertyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editservices',
    component: EditServicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eventsconfiguration',
    component: EventsConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'usersconfiguration',
    component: UsersConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'emailconfiguration',
    component: EmailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'modalsubscriptions',
    component: ModalsubscriptionsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'forgotpasswordreset', component: ForgotPasswordRemindComponent },

  { path: '404', component: NotfoundpageComponent },
  { path: '**', redirectTo: '/404' },
]

@Injectable()
export class CustomPreloader implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingcComponents = [FilterpropertyComponent];
