import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { AgmCoreModule } from '@agm/core';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgOptimizedImage } from '@angular/common';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from 'ngx-ui-loader';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
//import { GoogleMapsModule } from '@angular/google-maps';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ng2-ckeditor';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppRoutingModule, routingcComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { FilterpropertyComponent } from './filterproperty/filterproperty.component';
import { RecoverpasswordComponent } from './recoverpassword/recoverpassword.component';
import { HeaderloggedComponent } from './shared/layout/headerlogged/headerlogged.component';

// used to create fake backend
//import { fakeBackendProvider } from './helpers';
import { TokenInterceptor } from './helpers';
import { ErrorInterceptor } from './helpers';
import { LoginComponent } from './loginpage';
import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { MyprofileComponent } from './user/myprofile/myprofile.component';
import { PropertydetailComponent } from './propertydetail/propertydetail.component';
import { VendorsComponent } from './vendors/vendors.component';
import { VendorsdetailsComponent } from './vendorsdetails/vendorsdetails.component';
import { RegisternewuserComponent } from './registernewuser/registernewuser.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { DashboardComponent } from './Dashboard/dashboard.component';
import { WalletComponent } from './wallet/wallet.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ApplicationhistoryComponent } from './applicationhistory/applicationhistory.component';
import { RegistrationsucessedComponent } from './registernewuser/registrationsucessed/registrationsucessed.component';
import { ResetpasswordsucessedComponent } from './resetpassword/resetpasswordsucessed/resetpasswordsucessed.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AuthenticationService } from './services';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { RecoverpasswordsucessedComponent } from './recoverpassword/recoverpasswordsucessed/recoverpasswordsucessed.component';
import { ConfirmuserregistrationComponent } from './confirmuserregistration/confirmuserregistration.component';
import { ForgotPasswordRemindComponent } from './forgot-password-remind/forgot-password-remind.component';
import { AboutComponent } from './staticpages/about/about.component';
import { OurstoryComponent } from './staticpages/ourstory/ourstory.component';
import { OurmissionComponent } from './staticpages/ourmission/ourmission.component';
import { InthenewsComponent } from './staticpages/inthenews/inthenews.component';
import { PressmediaComponent } from './staticpages/pressmedia/pressmedia.component';
import { GlossaryComponent } from './staticpages/glossary/glossary.component';
import { AccountandprofileComponent } from './staticpages/accountandprofile/accountandprofile.component';
import { SigningupComponent } from './staticpages/signingup/signingup.component';
import { ShowcasingyourpropertyholdingsComponent } from './staticpages/showcasingyourpropertyholdings/showcasingyourpropertyholdings.component';
import { UpdatingratingsandrentalhistoryComponent } from './staticpages/updatingratingsandrentalhistory/updatingratingsandrentalhistory.component';
import { FeedbackComponent } from './staticpages/feedback/feedback.component';
import { TestimonialsComponent } from './staticpages/testimonials/testimonials.component';
import { TermsconditionsComponent } from './staticpages/termsconditions/termsconditions.component';
import { PrivacypolicyComponent } from './staticpages/privacypolicy/privacypolicy.component';
import { CareersComponent } from './staticpages/careers/careers.component';
import { ContactusComponent } from './staticpages/contactus/contactus.component';
import { WhoweareComponent } from './staticpages/whoweare/whoweare.component';
import { CareersapplyComponent } from './staticpages/careersapply/careersapply.component';
import { CropprofileimageComponent } from './user/myprofile/cropprofileimage/cropprofileimage.component';
import { EventsComponent } from './events/events.component';
import { MypropertiesComponent } from './myproperties/myproperties.component';
import { AddneweventComponent } from './addnewevent/addnewevent.component';
import { ChatComponent } from './chat/chat.component';
import { AddincludedservicesmodalComponent } from './chat/addincludedservicesmodal/addincludedservicesmodal.component';
import { CameraComponent } from './cameraidentity/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { EventreportuserComponent } from './eventreportuser/eventreportuser.component';
import { SignthecontractComponent } from './signthecontract/signthecontract.component';
import { ContactlandlordComponent } from './contactlandlord/contactlandlord.component';
import { LeftmenumypainelComponent } from './shared/layout/leftmenumypainel/leftmenumypainel/leftmenumypainel.component';
import { AddnewpropertyComponent } from './addnewproperty/addnewproperty.component';
import { AddnewamenitiemodalComponent } from './addnewproperty/addnewamenitiemodal/addnewamenitiemodal.component';
import { PropertycreatedsuccessfullyComponent } from './addnewproperty/propertycreatedsuccessfully/propertycreatedsuccessfully.component';
import { GenerictoastmessageComponent } from './generictoastmessage/generictoastmessage.component';
import { MobilephonetakephotoComponent } from './mobilephonetakephoto/mobilephonetakephoto.component';
import { ChatwebsocketService } from './services/chatwebsocket.service';
import { EditpropertyComponent } from './editproperty/editproperty.component';
import { EditServicesComponent } from './editservices/editservices.component';
import { PropertyupdatedsuccessfullyComponent } from './editproperty/propertyupdatedsuccessfully/propertyupdatedsuccessfully.component';
import { EventsConfigurationComponent } from './configuration/events/events.component';
import { UsersConfigurationComponent } from './configuration/users/users.component';
import { EdituserComponent } from './configuration/users/edituser/edituser.component';
import { PaymentmodalComponent } from './chat/paymentmodal/paymentmodal.component';
import { RatelandlordEndEventComponent } from './chat/ratelandlord-end-event/ratelandlord-end-event.component';
import { PreviewdocumentComponent } from './previewdocument/previewdocument.component';
import { TextToHTMLEditorComponent } from './text-to-htmleditor/text-to-htmleditor.component';
import { EditContractComponent } from './chat/edit-contract/edit-contract.component';
import { MyleasesComponent } from './myleases/myleases.component';
import { ModalsubscriptionsComponent } from './modalsubscriptions/modalsubscriptions.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { AddnewserviceComponent } from './addnewservice/addnewservices.component';
import { MyServicesComponent } from './myservices/myservices.component';
import { EventsPayment } from './eventspayment/eventspayment.component';
import { ServicedetailComponent } from './servicedetail/servicedetail.component';
import { FilterServiceComponent } from './filterservice/filterservice.component';
import { RatingsComponent } from './ratings/ratings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InvitesComponent } from './invites/invites.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SafePipeModule } from 'safe-pipe';
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EmailComponent } from './configuration/email/email.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#ff993b',
  bgsOpacity: 0.5,
  bgsPosition: 'center-center',
  bgsSize: 60,
  bgsType: 'ball-spin-fade-rotating',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#ff993b',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-fade-rotating',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LandingpageComponent,
    FilterpropertyComponent,
    routingcComponents,
    FilterServiceComponent,
    EventsPayment,
    MyServicesComponent,
    RatingsComponent,
    ServicedetailComponent,
    DashboardComponent,
    RecoverpasswordComponent,
    EditServicesComponent,
    HeaderloggedComponent,
    NotificationsComponent,
    InvitesComponent,
    WalletComponent,
    AddnewserviceComponent,
    LoginComponent,
    NotfoundpageComponent,
    MyprofileComponent,
    PropertydetailComponent,
    VendorsComponent,
    VendorsdetailsComponent,
    RegisternewuserComponent,
    ResetpasswordComponent,
    SubscriptionsComponent,
    PreferencesComponent,
    ApplicationhistoryComponent,
    EscapeHtmlPipe,
    RegistrationsucessedComponent,
    ResetpasswordsucessedComponent,
    GoogleMapsComponent,
    RecoverpasswordsucessedComponent,
    ConfirmuserregistrationComponent,
    ForgotPasswordRemindComponent,
    AboutComponent,
    OurstoryComponent,
    OurmissionComponent,
    InthenewsComponent,
    PressmediaComponent,
    GlossaryComponent,
    AccountandprofileComponent,
    SigningupComponent,
    ShowcasingyourpropertyholdingsComponent,
    UpdatingratingsandrentalhistoryComponent,
    FeedbackComponent,
    TestimonialsComponent,
    TermsconditionsComponent,
    PrivacypolicyComponent,
    CareersComponent,
    ContactusComponent,
    WhoweareComponent,
    CareersapplyComponent,
    CropprofileimageComponent,
    EventsComponent,
    MypropertiesComponent,
    AddneweventComponent,
    ChatComponent,
    AddincludedservicesmodalComponent,
    CameraComponent,
    EventreportuserComponent,
    SignthecontractComponent,
    ContactlandlordComponent,
    LeftmenumypainelComponent,
    AddnewpropertyComponent,
    AddnewamenitiemodalComponent,
    PropertycreatedsuccessfullyComponent,
    GenerictoastmessageComponent,
    MobilephonetakephotoComponent,
    EditpropertyComponent,
    PropertyupdatedsuccessfullyComponent,
    EventsConfigurationComponent,
    UsersConfigurationComponent,
    EdituserComponent,
    PaymentmodalComponent,
    RatelandlordEndEventComponent,
    PreviewdocumentComponent,
    TextToHTMLEditorComponent,
    EditContractComponent,
    MyleasesComponent,
    ModalsubscriptionsComponent,
    EmailComponent,

    //DragDropModule
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    NgOptimizedImage,
    FormsModule,
    NgxMaterialTimepickerModule,
    SwiperModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    SafePipeModule,
    HttpClientModule,
    NgxGalleryModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyChH9cCPY-bgRX0fqFgTR0rqz4a9aABrAo',
      libraries: ['places'],
    }),
    SocialLoginModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularMyDatePickerModule,
    NgxPaginationModule,
    ImageCropperModule,
    SlickCarouselModule,
    WebcamModule,
    NgxDropzoneModule,
    QRCodeModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
    }), // ToastrModule added
    NgxPayPalModule,
    NgDragDropModule.forRoot(),
    NgxDocViewerModule,
    AngularEditorModule,
    CKEditorModule,
    PdfViewerModule,
    // FlexLayoutModule,

    // GoogleMapsModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '493235902415-9i0n28if4d7tlaqc0ttcij7ipfnaohgr.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('433829757831387'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    ChatwebsocketService,
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: AuthenticationService }
    // provider used to create fake backend
    //  fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
