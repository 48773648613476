<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>

  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">

        <main id="content" class="bg-gray-01">
          <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" data-animated-id="1">
            <div class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15">Users Configuration<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.recordsTotal}}</span>
                </h2>

              </div>
              <div class="form-inline justify-content-md-end mx-n2">
                <form class="form-inline">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" name="showdeleted" id="showdeleted"
                      [checked]="false" (change)="onshowdeletedchange()">
                    <label class="custom-control-label" for="showdeleted">Show deleted only
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" name="showpending" id="showpending"
                      [checked]="false" (change)="onshowpendingchange()">
                    <label class="custom-control-label" for="showpending">Show pending approval only
                    </label>
                  </div>
                  <!--
                                        <div class="input-group border rounded w-auto bg-white mr-3">
                                            <label
                                                class="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3"
                                                for="inputGroupSelect01"><i
                                                    class="fas fa-align-left fs-16 pr-2"></i>Roles:</label>
                                            <select class="form-control border-0 bg-transparent shadow-none p-0 sortby"
                                                data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                                                id="inputGroupSelect01" name="sortby" #t>
                                                <option [ngValue]="1" value="1" selected>All</option>
                                                <option [ngValue]="2" value="2">SysAdmin</option>
                                                <option [ngValue]="3" value="3">CompanyAdmin</option>
                                                <option [ngValue]="4" value="4">Vendor</option>
                                                <option [ngValue]="5" value="5">Landlord</option>
                                                <option [ngValue]="6" value="6">Tenant</option>
                                            </select>
                                        </div>
                                    -->
                  <input class="form-control mr-sm-2" type="search" placeholder="Search" #s aria-label="Search">
                  <button (click)="searchUserspage(1,s.value)" class="btn btn-outline-primary my-2 my-sm-0"
                    type="submit">Search</button>
                </form>

              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover  border rounded-lg">
                <thead class="thead-sm thead-black">
                  <tr>
                    <!--<th scope="col" class="border-top-0 px-6 pt-5 pb-4">Company Name</th>-->
                    <th scope="col" class="border-top-0 pt-5 pb-4">First Name</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Last Name</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Email</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Phone</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Created Date</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Roles</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Approved</th>
                    <th scope="col" class="border-top-0 pt-5 pb-4">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-hover-xs-2 bg-hover-white" *ngFor="let user of this.lstOfUsers;">
                    <!--<td class="align-middle">{{ user.CompanyName }}</td>-->
                    <td class="align-middle">
                      <p class="fs-13">{{ user.FirstName }}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">{{ user.LastName }}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">{{ user.Email }}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">{{ user.Phone }}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">{{ user.CreatedDate | date: 'MM/dd/yyyy' }}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">{{ user.Roles}}</p>
                    </td>
                    <td class="align-middle">
                      <p class="fs-13">

                        <ng-container *ngIf="user.Approved">
                          <span class="badge text-capitalize font-weight-normal fs-12 badge-green">yes</span>
                        </ng-container>

                        <ng-container *ngIf="!user.Approved">
                          <span class="badge text-capitalize font-weight-normal fs-12 badge-yellow">Pending</span>
                        </ng-container>
                    </td>


                    <td class="align-middle">
                      <ng-container *ngIf="!showdeleted">
                        <a (click)="showEditUser(user.id)" title="Edit"
                          class="d-inline-block fs-18 text-muted hover-primary mr-5" style="cursor: pointer"
                          data-original-title="Edit"><i class="fal fa-pencil-alt"></i></a>
                        <a (click)="deleteUser(user.id, user.Email)" data-toggle="tooltip" title="Deactivate"
                          class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Deactivate"
                          style="cursor: pointer"><i class="fal fa-trash-alt"></i></a>
                      </ng-container>
                      <ng-container *ngIf="showdeleted">
                        <a (click)="reactiveUser(user.id, user.Email)" data-toggle="tooltip" title="Deactivate"
                          class="d-inline-block fs-18 text-muted hover-primary mr-5" data-original-title="Deactivate"
                          style="cursor: pointer">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </a>
                      </ng-container>
                      <!--  <a data-toggle="tooltip" title="Details"
                                            class="d-inline-block fs-18 text-muted hover-primary"
                                            data-original-title="Details" style="cursor: pointer"><i class="fal fa-info"></i></a> -->



                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="this.recordsTotal != '0'">
              <nav class="mt-6">
                <ul class="pagination rounded-active justify-content-center mb-0">
                  <li class="page-item"><a class="page-link"><i class="far fa-angle-double-left"></i></a>
                  </li>
                  <li *ngFor="let page of pagesarray;" ngClass="{{page.pagedesc}}">
                    <a (click)="searchUserspage(page.idpage,'')" class="page-link"
                      style="cursor: pointer">{{page.idpage}}</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" style="cursor: pointer">
                      <i class="far fa-angle-double-right">
                      </i></a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="text-center mt-2">{{this.recordsresultsdescription}} of {{this.recordsTotal}} Results</div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
