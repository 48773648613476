import { Component, OnInit } from '@angular/core';
import { usersService } from '../../services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EdituserComponent } from '../users/edituser/edituser.component';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersConfigurationComponent implements OnInit {
  public lstOfUsers = new Array<{
    id: number;
    CompanyName: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    CreatedDate: string;
    Roles: string;
    Approved: string;
  }>();

  faCheck = faCheck

  public recordsTotal: string = '0';
  public recordsresultsdescription: string = '';
  private totalperpage: number = 10;
  public pagesarray = new Array<{ idpage: number; pagedesc: String }>();
  public drawnumber = 1;
  public payload: string =
    '{"draw":[&drawvalue],"columns":[{"data":"Id","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"BizCompanyName","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"FirstName","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"LastName","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"Email","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"CellPhoneNumber","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"CreatedDate","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"RolesString","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"IsApproved","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"Id","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"asc"}],"start":[&startvalue],"length":[&lengthvalue],"search":{"value":"[&value]","regex":false},"ShowApprovalPendingOnly":[&pendingapproval],"showDeletedOnly":[&showDeletedOnly],"ShowVirtualUsers":false}';
  public showpending: boolean = false;
  public showdeleted: boolean = false;
  //'[&pendingapproval]'

  constructor(
    private userservice: usersService,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.searchUserspage(1, '');
  }

  onshowpendingchange() {
    if (this.showpending) this.showpending = false;
    else this.showpending = true;

    this.searchUserspage(1, '');
  }

  onshowdeletedchange() {
    if (this.showdeleted) this.showdeleted = false;
    else this.showdeleted = true;
    this.searchUserspage(1, '');
  }

  searchUserspage(page: number, searchvalue: string) {
    this.ngxService.startBackground('loadusers');

    var payloadfilter = this.payload
      .replace('[&drawvalue]', this.drawnumber.toString())
      .replace('[&startvalue]', ((page - 1) * this.totalperpage).toString())
      .replace('[&lengthvalue]', this.totalperpage.toString())
      .replace('[&value]', searchvalue)
      .replace('[&pendingapproval]', this.showpending.toString())
      .replace('[&showDeletedOnly]', this.showdeleted.toString());

    this.drawnumber = this.drawnumber + 1;

    this.userservice
      .getAllUsers(payloadfilter)
      .pipe()
      .subscribe(
        (data) => {
          this.processUsersData(data, page);
        },
        (error) => {}
      );
  }

  processUsersData(dataarray: any, page: number) {
    var userqty = Object.keys(dataarray.data).length;

    //clear array
    this.lstOfUsers.splice(0, this.lstOfUsers.length);

    for (var j = 0; j < userqty; j++) {
      var roles: string = dataarray.data[j].RolesString;
      roles = roles
        .replace('Tenants', 'Tenant')
        .replace('BizLandlord', 'Landlord');

      this.lstOfUsers.push({
        id: dataarray.data[j].Id,
        CompanyName: dataarray.data[j].BizCompanyName,
        FirstName: dataarray.data[j].FirstName,
        LastName: dataarray.data[j].LastName,
        Email: dataarray.data[j].Email,
        Phone: dataarray.data[j].CellPhoneNumber,
        CreatedDate: dataarray.data[j].CreatedDate,
        Roles: roles,
        Approved: dataarray.data[j].IsApproved,
      });
    }

    this.recordsTotal = dataarray.recordsFiltered;

    this.pagesarray.splice(0, this.pagesarray.length);

    for (var i = 0; i < Number(this.recordsTotal) / this.totalperpage; i++) {
      if (i == 0) {
        //first element
        this.pagesarray.push({ idpage: i + 1, pagedesc: 'page-item active' });
      } else {
        this.pagesarray.push({ idpage: i + 1, pagedesc: 'page-item' });
      }

      var lastpage = '';

      if (page * this.totalperpage > Number(this.recordsTotal))
        lastpage = this.recordsTotal;
      else lastpage = (page * this.totalperpage).toString();

      this.recordsresultsdescription =
        (page * this.totalperpage - (this.totalperpage - 1)).toString() +
        '-' +
        lastpage;
    }

    this.updateselectedpageUI(page);

    this.ngxService.stopBackground('loadusers');
  }

  updateselectedpageUI(page: number) {
    for (var i = 0; i < this.pagesarray.length; i++) {
      if (this.pagesarray[i].idpage == page)
        this.pagesarray[i].pagedesc = 'page-item active';
      else this.pagesarray[i].pagedesc = 'page-item';
    }
  }

  async deleteUser(id, mail) {
    await this.userservice
      .deleteUser(id, mail)
      .toPromise()
      .then((response) => {
        this.searchUserspage(1, '');
      });
  }
  async reactiveUser(id, mail) {
    await this.userservice
      .reactiveUser(id, mail)
      .toPromise()
      .then((response) => {
        this.searchUserspage(1, '');
      });
  }

  showEditUser(id) {
    const modalRef = this.modalService.open(EdituserComponent, {
      scrollable: true,
      windowClass: 'my-class',
      //size: 'md',
      // keyboard: false,
      //backdrop: 'static'
    });
    let data = {
      prop1: '',
      prop2: '',
      prop3: '1',
      id: id,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
      },
      (reason) => {}
    );
  }
}
