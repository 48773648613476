import { Component, OnInit,AfterViewInit,NgZone, HostListener  } from '@angular/core';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AuthenticationService, propertiesService } from '../services';
import { environment } from './../../environments/environment';

import { NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsComponent } from '../google-maps/google-maps.component';
import { MapsAPILoader } from '@agm/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { faStar, faAngleDown, faAngleUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

  ///////////////////////////////////// Interfaces  ////////////////////////////////////////////

  interface Coordinates {
    address: string;
    latitude: number;
    longitude: number;
  }

@Component({
  selector: 'app-filterproperty',
  templateUrl: './filterproperty.component.html',
  styleUrls: ['./filterproperty.component.css']
})
export class FilterpropertyComponent implements OnInit,AfterViewInit {

  page: number = 1;
  count: number = 0;

  coordinates: Coordinates;
  latitude: number;
  longitude: number;
  address: string;

  public maxValue: number = 1000000000;
  public minValue: number = 0;

  faStar = faStar;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faSortDown = faSortDown;

  public label = 'Date(low to high)';
  public locationDesc = 'With in 20 miles';

  public options = [
    {
      option: 'Date (low to high)',
      value: 1,
    },
    {
      option: 'Date (high to low)',
      value: 2,
    },
    {
      option: 'Price (low to high)',
      value: 3,
    },
    {
      option: 'Price (high to low)',
      value: 4,
    },
    {
      option: 'Beds (low to high)',
      value: 5,
    },
    {
      option: 'Beds (high to low)',
      value: 6,
    },
    {
      option: 'Baths (low to high)',
      value: 7,
    },
    {
      option: 'Baths (high to low)',
      value: 8,
    },
  ]

  private geoCoder;
  public innerWidth: any;

  public openFilter = false;
  public showSortBy = false;
  public showMiles = false;

  public islocationcheked = false;
  public isbedscheked = false;
  public userIdProfile = '';
  public isbathscheked = false;
  public ispricecheked = false;
  public isareacheked = false;
  formfilterproperty: FormGroup;
  public showpropertylistGrid: boolean= false;
  public MAXTotalPropertiesQuery: number = 5000;
  public totalperpage: number = 8;
  public totalproperties: number;
  public listHighlight: any = [];
  public activepage: number = 1;
  public selectedCategory: string = '';
  public locationdistance: string = '';
  public numberofpages:number;
  public dataProps:any;

  public list: any = [];
  public propList: any = [];
  public propData: any = [];
  public alltotalproperties: any = [];
  public allBooster: any = [];

  public imageurl0: string;
  public imageurl1: string;
  public imageurl2: string;
  public imageurl3: string;
  public imageurl4: string;
  public imageurl5: string;
  public imageurl6: string;
  public imageurl7: string;

  public Name0: string;
  public Name1: string;
  public Name2: string;
  public Name3: string;
  public Name4: string;
  public Name5: string;
  public Name6: string;
  public Name7: string;

  public OwnerName0: string;
  public OwnerName1: string;
  public OwnerName2: string;
  public OwnerName3: string;
  public OwnerName4: string;
  public OwnerName5: string;
  public OwnerName6: string;
  public OwnerName7: string;

  public Price0: string;
  public Price1: string;
  public Price2: string;
  public Price3: string;
  public Price4: string;
  public Price5: string;
  public Price6: string;
  public Price7: string;

  public Beds0: string;
  public Beds1: string;
  public Beds2: string;
  public Beds3: string;
  public Beds4: string;
  public Beds5: string;
  public Beds6: string;
  public Beds7: string;

  public Baths0: string;
  public Baths1: string;
  public Baths2: string;
  public Baths3: string;
  public Baths4: string;
  public Baths5: string;
  public Baths6: string;
  public Baths7: string;

  public Area0: string;
  public Area1: string;
  public Area2: string;
  public Area3: string;
  public Area4: string;
  public Area5: string;
  public Area6: string;
  public Area7: string;

  public IdProperty0: string;


  public pagesarray = new Array<{idpage: number;pagedesc: String}>();
  public propertieslist = new Array<{
    id:string,
    pageindex:number,
    page:number,
    idcategory: number,
    namecategory: string,
    SquareFeet: string,
    Baths: string,
    Beds: string,
    MoveInDate: string,
    OwnerName: string,
    Name: string,
    ShortDescription: string,
    FullDescription: string,
    LocationId: string,
    LocationDesc:string,
    CreatedDate: string,
    CreatedBy: string,
    imageurl: string,
    Price: string,
    Garage: string,
    PropertySellType: string,
    IdProperty: string,
    queryparam: string,
    Highlight: boolean
  }>();

  public propertieslistPageToShow = new Array<{
    id:string,
    pageindex:number,
    page:number,
    idcategory: number,
    namecategory: string,
    SquareFeet: string,
    Baths: string,
    Beds: string,
    MoveInDate: string,
    OwnerName: string,
    Name: string,
    ShortDescription: string,
    FullDescription: string,
    LocationId: string,
    LocationDesc:string,
    CreatedDate: string,
    CreatedBy: string,
    imageurl: string,
    Price: string,
    Garage: string,
    PropertySellType: string,
    IdProperty: string,
    queryparam: string
    Highlight: boolean
  }>();
  //default json request
  public jsonrequest : string = '';
  public jsonAllPropertiesRequest : string = '';


  constructor(private route:ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private propertiesService: propertiesService,
              private modalService: NgbModal,
              private mapsAPILoader: MapsAPILoader,
              private authservice: AuthenticationService,
              private ngZone: NgZone,
              private ngxService: NgxUiLoaderService){
    this.loadScripts();

    this.coordinates = {} as Coordinates;


  }

  selectCategoryOption({ target }) {
    this.selectedCategory = target.value;
  }
  ngAfterViewInit():  void {

  }

  ngOnInit() {
    this.onResize('')
    if (this.authservice.isUserLogged) {
      // get user info
      const userinfo = localStorage.getItem('roundcombuserdetail');

      let UserJsonObject = JSON.parse(userinfo);

      this.userIdProfile = UserJsonObject.userIdProfile;
    } else {
      this.userIdProfile = '';
    }

    this.locationdistance  = '20';

    this.formfilterproperty = this.formBuilder.group({
      keyword: ['',],
      bedsform:['',],
      switchbedsform:['',],
      switchbathsform:['',],
      switchpriceform: ['',],
      switchareaform: ['',],
      switchac:['',],
      switchstreetparking:['',],
      switchwasher:['',],
      switchgarage:['',],
      switchdryer:['',],
      switchpool:['',],
      switchpetsallowed:['',],
      switchathleticcenter:['',],
      switchclubhouse:['',],
      switchlocationform:['',],

    });

    this.route.queryParams.subscribe(
      (queryparams: Params) => {

        this.setSelectCategory(queryparams.propertytype);
      });

     //load Places Autocomplete
     this.mapsAPILoader.load().then(() => {
     // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

     this.getAllProperties()
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAllProperties()
  }

  toNext(p) {
    if (!p.isLastPage()) {
      p.next();
    }
  }

  verifyResize() {
    this.openFilter = !this.openFilter
  }

  /////////////////////////////////////// dropdownList  //////////////////////////////////////////////

  lstpropertylist = [
    {id: 1,idcategory: 1, desctext: 'All Properties', isSelected:true},
    {id: 2,idcategory: 17,desctext: 'Apartment', isSelected:false},
    {id: 3,idcategory: 22,desctext: 'Single House', isSelected:false},
    {id: 4,idcategory: 26,desctext: 'Single Luxury', isSelected:false},
    {id: 5,idcategory: 18,desctext: 'Single Family', isSelected:false},
    {id: 6,idcategory: 23,desctext: 'Townhouse', isSelected:false},
    {id: 7,idcategory: 24,desctext: 'Villa', isSelected:false}
  ];

  lstmileslocationlist = [
    {id: 1,desctext: 'With in 10 miles',value:'10', isSelected:false},
    {id: 2,desctext: 'With in 20 miles',value:'20', isSelected:true},
    {id: 3,desctext: 'With in 30 miles',value:'30', isSelected:false},
    {id: 4,desctext: 'With in 50 miles',value:'50', isSelected:false},
    {id: 5,desctext: 'With in 100 miles',value:'100', isSelected:false},
    {id: 6,desctext: 'With in 200 miles',value:'200', isSelected:false},
    {id: 7,desctext: 'With in 300 miles',value:'300', isSelected:false}
  ];

  /////////////////////////////////////// Form  //////////////////////////////////////////////

searchProperties(beds: string, baths: string,pricerange:string,arearange:string)
{
  var minvalue:string = '';
  var maxvalue:string = '';

  var locationIdfilter:string = '';
  var bathdynamic:string = '';
  var beddynamic:string = '';
  var areadynamic:string = '';
  var featuresdynamic:string = '';
  var DynamicPropertyFilter:string = '';
  var minpricevalue:string = '';
  var maxpricevalue:string = '';
  var featurearray = new Array<{name: string}>();

  DynamicPropertyFilter = ', "[&PropertyId]": { "Name": "[&name]", "DisplayName": "[&name]", "PropertyId": [&PropertyId],"PropertyType": "[&PropertyType]","CheckBoxesValues": [], "MinDateValue": [&MinDateValue], "MaxDateValue": [&MaxDateValue],"MinNumberValue": [&MinNumberValue],"MaxNumberValue": [&MaxNumberValue],"MinCurrencyValue": [&MinCurrencyValue],"MaxCurrencyValue": [&MaxCurrencyValue],"BooleanFilterValue": null,"StringFilterValue": null,"defer": {"promise": {}}}';

  if(this.f.switchbedsform.value)
  {
    var bedsarray = beds.split(' ');
    minvalue = bedsarray[0];
    maxvalue = bedsarray[bedsarray.length-2];

    beddynamic = DynamicPropertyFilter.replace('[&PropertyId]','18').replace('[&PropertyId]','18');
    beddynamic = beddynamic.replace('[&name]','Beds').replace('[&name]','Beds').replace('[&PropertyType]','Number').replace('[&MinDateValue]','null').replace('[&MaxDateValue]', 'null').replace('[&MinNumberValue]',minvalue).replace('[&MaxNumberValue]',maxvalue).replace('[&MinCurrencyValue]','null').replace('[&MaxCurrencyValue]', 'null');

  }

   if(this.f.switchbathsform.value)
  {
    var bathssarray = baths.split(' ');
    minvalue = bathssarray[0];
    maxvalue = bathssarray[bathssarray.length-2];


    bathdynamic = DynamicPropertyFilter.replace('[&PropertyId]','17').replace('[&PropertyId]','17');
    bathdynamic = bathdynamic.replace('[&name]','Baths').replace('[&name]','Baths').replace('[&PropertyType]','Number').replace('[&MinDateValue]','null').replace('[&MaxDateValue]', 'null').replace('[&MinNumberValue]',minvalue).replace('[&MaxNumberValue]',maxvalue).replace('[&MinCurrencyValue]','null').replace('[&MaxCurrencyValue]', 'null');

  }


  if(this.f.switchareaform.value)
  {
    var areaarray = arearange.split(' ');


    minvalue = areaarray[0];
    maxvalue = areaarray[areaarray.length-2];


    areadynamic = DynamicPropertyFilter.replace('[&PropertyId]','16').replace('[&PropertyId]','16');
    areadynamic = areadynamic.replace('[&name]','Square Feet').replace('[&name]','Square Feet').replace('[&PropertyType]','Number').replace('[&MinDateValue]','null').replace('[&MaxDateValue]', 'null').replace('[&MinNumberValue]',minvalue).replace('[&MaxNumberValue]',maxvalue).replace('[&MinCurrencyValue]','null').replace('[&MaxCurrencyValue]', 'null');


  }

  if(this.f.switchpriceform.value)
  {

    minpricevalue = this.minValue.toString();
    maxpricevalue = this.maxValue.toString();

  }

  if(this.f.switchac.value)
  {
    featurearray.push({name:'"A/C"'});
  }

  if(this.f.switchstreetparking.value)
  {
    featurearray.push({name:'"Street Parking"'});
  }

  if(this.f.switchwasher.value)
  {
    featurearray.push({name:'"Washer"'});
  }

  if(this.f.switchgarage.value)
  {
    featurearray.push({name:'"Garage"'});
  }
  if(this.f.switchdryer.value)
  {
    featurearray.push({name:'"Dryer"'});
  }
  if(this.f.switchpool.value)
  {
    featurearray.push({name:'"Pool"'});
  }
  if(this.f.switchpetsallowed.value)
  {
    featurearray.push({name:'"Pets Allowed"'});
  }
  if(this.f.switchathleticcenter.value)
  {
    featurearray.push({name:'"Athletic Center"'});
  }
  if(this.f.switchclubhouse.value)
  {
    featurearray.push({name:'"Club House"'});
  }

  if(featurearray.length >0)
  {
    var features: string = '"CheckBoxesValues": [';


    for(var i = 0; i < featurearray.length; i++)
    {
      //first one
      if(featurearray.length > 1 && (featurearray.length-1) != i)
        features = features.concat(featurearray[i].name,',');
      else
       {

        //last one
        if((featurearray.length-1) == i)
          features = features.concat(featurearray[i].name,']');
       }
    }

    featuresdynamic = DynamicPropertyFilter.replace('[&PropertyId]','14').replace('[&PropertyId]','14');
    featuresdynamic = featuresdynamic.replace('[&name]','Features').replace('[&name]','Features').replace('[&PropertyType]','CheckBoxes').replace('[&MinDateValue]','null').replace('[&MaxDateValue]', 'null').replace('[&MinNumberValue]','null').replace('[&MaxNumberValue]','null').replace('[&MinCurrencyValue]','null').replace('[&MaxCurrencyValue]', 'null').replace('"CheckBoxesValues": []',features);
  }


  if(this.f.switchlocationform.value)
  {
    // enable search by location
    this.propertiesService.locationServiceSetting()
        .pipe()
        .subscribe(
            data => {
            },
            error => {

            });

    // enable search by location
    this.propertiesService.boundingLocations(this.coordinates.latitude.toString(),this.coordinates.longitude.toString(), this.locationdistance)
    .pipe()
    .subscribe(
        data => {

           for(var j = 0; j < data.length ; j++)
          {
            if(j==0)
            locationIdfilter = locationIdfilter + data[j].Id.toString();
            else
            locationIdfilter = locationIdfilter.concat(',',data[j].Id.toString());
          }

          this.buildjsonrequestSearch(this.f.keyword.value,this.getIDCategorybyName(this.selectedCategory),beddynamic,bathdynamic,minpricevalue,maxpricevalue,featuresdynamic,locationIdfilter);
               this.getAllProperties()

        },
        error => {

        });

  }
  else
  {
    this.buildjsonrequestSearch(this.f.keyword.value,this.getIDCategorybyName(this.selectedCategory),beddynamic,bathdynamic,minpricevalue,maxpricevalue,featuresdynamic,locationIdfilter,areadynamic);
         this.getAllProperties()
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
    this.openFilter = false;
  }

  getIDCategorybyName(selectedValue: string)
  {
    for(var i = 0;i < this.lstpropertylist.length; i++)
    {
      if(this.lstpropertylist[i].desctext == selectedValue)
      {
        return (this.lstpropertylist[i].idcategory).toString();
      }
    }
    return '0';
  }


    getlocationById(locationid:string):string
    {
      var returnvalue:string = 'Impossible to get location';

      this.propertiesService.getQueryLocationById(locationid)
      .pipe()
      .subscribe(
          data => {
            returnvalue = data;
          },
          error => {
            returnvalue = error;
          });

          return returnvalue;
    }

    public formatnumberasUSDCurrency(amount:string)
    {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return formatter.format(parseInt(JSON.stringify(amount).replace('"','').replace('"','')))
    }

      // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
          //this.f.switchlocationform.enable;// = true;
        //document.getElementById('switchlocationform').checked = false;.setAttribute('checked',false);
      });
    }

  }

  private getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {

        if (results[0]) {

            this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });

  }


  clearfilters()
  {
    this.islocationcheked = false;
    this.isareacheked = false;
    this.isbathscheked = false;
    this.isbedscheked = false;
    this.ispricecheked = false;


    this.address = '';
    this.coordinates.address = '';
    this.coordinates.latitude = 0;
    this.coordinates.longitude = 0;


    this.f.switchac.setValue(false);
    this.f.switchstreetparking.setValue(false);
    this.f.switchwasher.setValue(false);
    this.f.switchgarage.setValue(false);
    this.f.switchdryer.setValue(false);
    this.f.switchpool.setValue(false);
    this.f.switchpetsallowed.setValue(false);
    this.f.switchathleticcenter.setValue(false);
    this.f.switchclubhouse.setValue(false);

    this.f.keyword.setValue('');
  }

  clearlocation()
  {
    this.islocationcheked = false;
    this.address = '';
    this.coordinates.address = '';
    this.coordinates.latitude = 0;
    this.coordinates.longitude = 0;

  }

  onchangebeds(event: any)
  {
      //default value
    if((<HTMLInputElement>document.getElementById('beds')).value != '1  to 10' )
     {
      this.isbedscheked = true;
     }
  }

  onchangebaths(event: any)
  {
    //default value
    if((<HTMLInputElement>document.getElementById('baths')).value != '1  to 5' )
      this.isbathscheked = true;
  }

  onchangeprice(event: any, type)
  {
    if(type === 'max') {
      this.maxValue = event.target.value
    } else {
      this.minValue = event.target.value
    }
    //default value
    if(this.minValue !== 0 || this.maxValue !== 1000000000)
      this.ispricecheked = true;
  }


  onchangearea(event: any)
  {

    //default value
    if((<HTMLInputElement>document.getElementById('area')).value != '50 sqft to 15000 sqft')
      this.isareacheked = true;
  }


      openGoogelMapsModal() {

      const modalRef = this.modalService.open(GoogleMapsComponent,
        {
          scrollable: true,
          windowClass: 'mycustommodelclass',
          // keyboard: false,
          // backdrop: 'static'
        });

      let data = {
        prop1: 'Some Data',
        prop2: 'From Parent Component',
        prop3: 'This Can be anything'
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {

        this.coordinates = result;

        this.address = this.coordinates.address;

        // auto change to true the lo0cation
        if(typeof this.address!='undefined' && this.address)
          this.islocationcheked = true;

      }, (reason) => {
      });
    }


    onLocationcheckChange(e)
    {
     this.islocationcheked =this.f.switchlocationform.value;

     if(!this.f.switchlocationform.value)
     {
       this.coordinates.address = '';
       this.coordinates.latitude = 0;
       this.coordinates.longitude = 0;
       this.address = '';
     }

    }

    getMultipleRandom(arr, num) {
      const shuffled = [...arr].sort(() => 0.5 - Math.random());

      return shuffled.slice(0, num);
    }

     getAllProperties() {
       this.propertiesService
        .getAllProperties()
        .pipe()
        .subscribe((data) => {
          let filterBySPropertySellType = data.filter((el) => {
            return el.PropertySellType !== 'Unavailable' && !el.IsDeleted
          })
          let filterByStatus = filterBySPropertySellType.filter((el) => {
            return el.status !== 'Draft' && el.status === "AvailableForSell"
          })
          let filteredBooster = filterByStatus.filter(el => {
            return el.Booster === true && el.MonthBooster > 0;
          })
          this.allBooster = filteredBooster
          let filteredHighlight = filterByStatus.filter(el => {
            return el.Highlight === true && el.MonthHighlight > 0;
          })
          let random = [];
          random = this.getMultipleRandom(filteredBooster, 1)
          this.list = random
          this.listHighlight = filteredHighlight
          this.queryProperties(random, filteredHighlight)
        });
    }

   queryProperties(list, high) {
    this.ngxService.startBackground("loadproperties");

    this.propertiesService.getQueryProperty(this.jsonrequest)
        .pipe()
        .subscribe(
            async data => {
              // let filterByFirstId = data.Data.filter((el) => {
              //   return el.Id === this.list[0].Id
              // })
              // let filterBySecondId = data.Data.filter((el) => {
              //   return el.Id === this.list[1].Id
              // })
              // let filterByThirdId = data.Data.filter((el) => {
              //   return el.Id === this.list[2].Id
              // })
              // let filterById = [...filterByFirstId, ...filterBySecondId, ...filterByThirdId]
              this.propData = data.Data
              this.alltotalproperties = data.Data.length;
              let filterById = data.Data.filter((el) => {
                return el.Id === list[0]?.Id
              })

              let filterByDiffId = data.Data.filter(el => {
                return el.Id !== list[-1 + 1]?.Id
              })
              this.propList = []
              filterById.forEach((el) => {
                this.propList.push({
                  idcategory: parseInt(JSON.stringify(el.Category.Id).replace('"','').replace('"','')),
                  namecategory: JSON.stringify(el.Category.Name).replace('"','').replace('"',''),
                  SquareFeet: JSON.stringify(el.ExtendedProperties.SquareFeet.IntValue).replace('null','0'),
                  Baths: JSON.stringify(el.ExtendedProperties.Baths.IntValue).replace('null','0'),
                  Beds: JSON.stringify(el.ExtendedProperties.Beds.IntValue).replace('null','0'),
                  MoveInDate: JSON.stringify(el.ExtendedProperties.MoveInDate.DateTimeValue).replace('"','').replace('"',''),
                  OwnerName: JSON.stringify(el.OwnerName).replace('"','').replace('"',''),
                  Name: JSON.stringify(el.Name).replace('"','').replace('"',''),
                  ShortDescription: JSON.stringify(el.ShortDescription).replace('"','').replace('"',''),
                  FullDescription: JSON.stringify(el.FullDescription).replace('"','').replace('"',''),
                  LocationId: JSON.stringify(el.LocationId).replace('"','').replace('"',''),
                  LocationDesc: '' ,
                  CreatedDate: JSON.stringify(el.CreatedDate).replace('"','').replace('"',''),
                  CreatedBy: JSON.stringify(el.CreatedBy).replace('"','').replace('"',''),
                  imageurl: `${environment.apiUrl}${el.Images[0].Url}`,
                  Price: JSON.stringify(el.Price).replace('"','').replace('"',''),
                  Garage: JSON.stringify(el.ExtendedProperties.Features.CheckboxValues).includes('Garage') ? 'Yes' : 'No',
                  PropertySellType: JSON.stringify(el.PropertySellType).replace('"','').replace('"',''),
                  IdProperty: JSON.stringify(el.Id),
                  queryparam: JSON.stringify(el.Id),
              })
              })
              let set = new Set([...this.propList])
              this.propList = set

              this.totalproperties = filterByDiffId.length;

              //clear array
              this.propertieslist.splice(0,this.propertieslist.length);
              this.pagesarray.splice(0,this.pagesarray.length);
              this.listProp(filterByDiffId, high)

              this.copypropertiesListIntoListoToShow();

              this.updateGridListVariables();
              this.ngxService.stopBackground("loadproperties");

            },
            error => {

            });
}

listProp(filterByDiffId, high) {
  var page:number = 1;
  var pageindex:number = 1;
  for(var j = 0; j <= (this.totalproperties-1) ; j++)
  {

    var imageurltemp: string = '';
    var badgeclasstemp: string = '';

            var DisplayOrder: string= '';

      try{ DisplayOrder = JSON.stringify(filterByDiffId[j].Images[0].Url);
        /*if(JSON.stringify(filterByDiffId[j].Images[0].DisplayOrder) == '0')
        {
        }
        else
        {
          imageurltemp = "assets/images/photonotavailable.jfif";
        }*/
        imageurltemp = `${environment.apiUrl}`.concat(JSON.stringify(filterByDiffId[j].Images[0].Url),'');

  }
  catch(Error){imageurltemp = "assets/images/photonotavailable.jfif";}

  imageurltemp = imageurltemp.replace('"','').replace('"','');

    var SquareFeet: string= '';

    try{ SquareFeet = JSON.stringify(filterByDiffId[j].ExtendedProperties.SquareFeet.IntValue); }
    catch(Error){SquareFeet = '0';}

    var Baths: string= '';

    try { Baths = JSON.stringify(filterByDiffId[j].ExtendedProperties.Baths.IntValue);}
    catch(Error) {  Baths = '0'; }

    var Beds: string= '';

    try { Beds = JSON.stringify(filterByDiffId[j].ExtendedProperties.Beds.IntValue);}
    catch(Error) {  Beds = '0'; }

    var MoveInDate: string= '';

    try { MoveInDate = JSON.stringify(filterByDiffId[j].ExtendedProperties.MoveInDate.DateTimeValue);}
    catch(Error) {  MoveInDate = '0'; }

    var LocationId: string= '';

    try { LocationId = JSON.stringify(filterByDiffId[j].LocationId);}
    catch(Error) {  LocationId = '0'; }

    var CreatedDate: string= '';

    try { CreatedDate = JSON.stringify(filterByDiffId[j].CreatedDate)}
    catch(Error) {  CreatedDate = '0'; }

    var Garage: string= '';
    let i = high.find((el) => {
      if(el.Id === filterByDiffId[j].Id) {
        return true
      } else {
        return false
      }
    })


    try {
      Garage = JSON.stringify(filterByDiffId[j].ExtendedProperties.Features.CheckboxValues);
      if(Garage.includes('Garage'))
        Garage = 'Yes';
      else
        Garage = 'No';
    }
    catch(Error) {  Garage = '0'; }

    this.propertieslist.push(
      {
        id:(page.toString()+pageindex.toString()),
        pageindex:pageindex,
        page:page,
        idcategory: parseInt(JSON.stringify(filterByDiffId[j].Category.Id).replace('"','').replace('"','')),
        namecategory: JSON.stringify(filterByDiffId[j].Category.Name).replace('"','').replace('"',''),
        SquareFeet: SquareFeet.replace('null','0'),
        Baths: Baths.replace('null','0'),
        Beds: Beds.replace('null','0'),
        MoveInDate: MoveInDate.replace('"','').replace('"',''),
        OwnerName: JSON.stringify(filterByDiffId[j].OwnerName).replace('"','').replace('"',''),
        Name: JSON.stringify(filterByDiffId[j].Name).replace('"','').replace('"',''),
        ShortDescription: JSON.stringify(filterByDiffId[j].ShortDescription).replace('"','').replace('"',''),
        FullDescription: JSON.stringify(filterByDiffId[j].FullDescription).replace('"','').replace('"',''),
        LocationId: LocationId.replace('"','').replace('"',''),
        LocationDesc: '' ,
        CreatedDate: CreatedDate.replace('"','').replace('"',''),
        CreatedBy: JSON.stringify(filterByDiffId[j].CreatedBy).replace('"','').replace('"',''),
        imageurl: imageurltemp,
        Price: JSON.stringify(filterByDiffId[j].Price).replace('"','').replace('"',''),//formatter.format(parseInt(JSON.stringify(filterByDiffId[j].Price).replace('"','').replace('"',''))),//JSON.stringify(filterByDiffId[j].Price).replace('"','').replace('"',''),
        Garage: Garage,
        PropertySellType: JSON.stringify(filterByDiffId[j].PropertySellType).replace('"','').replace('"',''),
        IdProperty: JSON.stringify(filterByDiffId[j].Id),
        queryparam: JSON.stringify(filterByDiffId[j].Id),
        Highlight: i === undefined ? false : i.Highlight,
      });

      pageindex = pageindex+1;

      if(page/this.totalperpage == 0)
      {
        page += page;
        pageindex = 1;
      }
  }
}

private copypropertiesListIntoListoToShow()
{

  this.propertieslistPageToShow.splice(0,this.propertieslistPageToShow.length);

  for(var j = ((this.activepage*this.totalperpage)-this.totalperpage); j < ((this.activepage*this.totalperpage)) ; j++)
  {
    this.propertieslistPageToShow.push(this.propertieslist[j]);
  }

}

onChangelocationdistance(selectedvalue, desc)
{
  this.showMiles = false;
  this.locationDesc = desc;
  this.locationdistance = selectedvalue;
  this.locationdistance = this.locationdistance.replace('With in ','').replace(' miles','');
}

onChangeorderby(selectedvalue, label) {
  this.label = label;
  this.showSortBy = false;

 if(selectedvalue == 1)// price low to high
 {
   let sortedBuilds = this.propertieslist.sort((n1,n2) => {
     return this.sortCompare(n1.CreatedDate, n2.CreatedDate)
   })


 }
  if(selectedvalue == 2) // price high to low
  {
    let sortedBuilds = this.propertieslist.sort((n2,n1) => {
      return this.sortCompare(n1.CreatedDate, n2.CreatedDate)
    })
  }

 if(selectedvalue == 3)// price low to high
 {
   let sortedBuilds = this.propertieslist.sort((n1,n2) => {
     return this.sortCompare(n1.Price, n2.Price)
   })

 }
  if(selectedvalue == 4) // price high to low
  {
    let sortedBuilds = this.propertieslist.sort((n2,n1) => {
      return this.sortCompare(n1.Price, n2.Price)
    })

  }
  if(selectedvalue == 5) // beds low to high
  {
    let sortedBuilds = this.propertieslist.sort((n1,n2) => {
      return this.sortCompare(n1.Beds, n2.Beds)
    })

  }
   if(selectedvalue == 6) // beds high to low
   {
     let sortedBuilds = this.propertieslist.sort((n2,n1) => {
       return this.sortCompare(n1.Beds, n2.Beds)
     })

   }
   if(selectedvalue == 7) // baths low to high
  {
    let sortedBuilds = this.propertieslist.sort((n1,n2) => {
      return this.sortCompare(n1.Baths, n2.Baths)
    })

  }
   if(selectedvalue == 8) // baths high to low
   {
     let sortedBuilds = this.propertieslist.sort((n2,n1) => {
       return this.sortCompare(n1.Baths, n2.Baths)
     })

   }

   this.copypropertiesListIntoListoToShow();

   this.updateGridListVariables();
}

sortCompare(a, b) {
  var ax = [], bx = [];

  a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
  b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

  while (ax.length && bx.length) {
    var an = ax.shift();
    var bn = bx.shift();
    var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }

  return ax.length - bx.length;
}

previouspageindex()
{
  if(this.activepage > 1)
  {
    this.activepage = this.activepage -1;
    this.copypropertiesListIntoListoToShow();
    this.updateGridListVariables();
    this.updatepagesarray();
  }
}

nextpageindex()
{
  if(this.activepage < this.numberofpages)
  {
    this.activepage = this.activepage +1;
    this.copypropertiesListIntoListoToShow();
    this.updateGridListVariables();
    this.updatepagesarray();
  }

}

updatepageindex(page:number)
{

  this.activepage = page;
  this.copypropertiesListIntoListoToShow();
  this.updateGridListVariables();
  this.updatepagesarray();
}

updatepagesarray()
{

  for(var i = 0;i <this.numberofpages;i++)
  {
    if(this.pagesarray[i].idpage == this.activepage) {
      this.pagesarray[i].pagedesc = "page-item active";
    }
    else {
      this.pagesarray[i].pagedesc = "page-item";
    }

  }
}

navigateToPropertyDetails(propertyId:string)
{
  this.router.navigate(['/propertydetail'], { queryParams: { propertyId } });
}

buildjsonrequestSearch(keyword:string='',categoryId:string='',bedsdynamic:string='',bathdynamic:string='',minpricevalue:string = '',maxpricevalue:string = '',featuresdynamic:string='', locationIdfilter: string= '',areadynamic:string = '')
{
  this.jsonrequest = '{"PageSize":[&pagesize],"PageIndex":[&pageindex],"Keyword":"[&keyword]","CategoryId":"[&categoryId]","LocationIds":[[&locationid]],"TagNames":[],"MinPrice": [&MinPrice], "MaxPrice": [&MaxPrice],"DynamicPropertyFilters":{"searchKeyword":"[&keyword]"[&bathsdymanic][&bedsdymanic][&bathsdymanic][&areadymanic]},"SearchByLocations":[&searchbylocation]}';
  this.jsonrequest = this.jsonrequest.replace('[&pagesize]',this.MAXTotalPropertiesQuery.toString()).replace('[&pageindex]', (this.activepage-1).toString());

  if(keyword.length > 0)
    this.jsonrequest = this.jsonrequest.replace('[&keyword]',keyword).replace('[&keyword]',keyword);
  else
    this.jsonrequest = this.jsonrequest.replace('[&keyword]','').replace('[&keyword]','');

  if(categoryId.length > 0 && categoryId != '1')
    this.jsonrequest = this.jsonrequest.replace('[&categoryId]',categoryId);
  else
    this.jsonrequest = this.jsonrequest.replace('[&categoryId]','');

  if(bedsdynamic.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&bedsdymanic]',bedsdynamic);
  }
  else
    this.jsonrequest = this.jsonrequest.replace('[&bedsdymanic]','');

  if(areadynamic.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&areadymanic]',areadynamic);
  }
  else
    this.jsonrequest = this.jsonrequest.replace('[&areadymanic]','');

  if(bathdynamic.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&bathsdymanic]',bathdynamic);
  }
  else
    this.jsonrequest = this.jsonrequest.replace('[&bathsdymanic]','');

  if(minpricevalue.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&MinPrice]',minpricevalue);
  }
  else
    this.jsonrequest = this.jsonrequest.replace('"MinPrice": [&MinPrice],','');

  if(maxpricevalue.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&MaxPrice]',maxpricevalue);
  }
  else
    this.jsonrequest = this.jsonrequest.replace(' "MaxPrice": [&MaxPrice],','');

  if(featuresdynamic.length > 0)
  {
    this.jsonrequest = this.jsonrequest.replace('[&bathsdymanic]',featuresdynamic);
  }
  else
    this.jsonrequest = this.jsonrequest.replace('[&bathsdymanic]','');


  if(this.islocationcheked)
  {
    this.jsonrequest = this.jsonrequest.replace('[&searchbylocation]','true');
    this.jsonrequest = this.jsonrequest.replace('[&locationid]',locationIdfilter);
  }
  else
  {
    this.jsonrequest = this.jsonrequest.replace('[&searchbylocation]','false');
    this.jsonrequest = this.jsonrequest.replace('[&locationid]','');
  }



}

  setSelectCategory(selectedValue: string)
  {
    if(selectedValue.length > 0)
    {

      for(var i = 0;i < this.lstpropertylist.length; i++)
      {
        if(this.lstpropertylist[i].desctext == selectedValue)
        {
          this.lstpropertylist[i].isSelected = true;
          this.selectedCategory = this.lstpropertylist[i].desctext;

          if(this.lstpropertylist[i].idcategory!= 1)
          {
            this.buildjsonrequestSearch('',this.lstpropertylist[i].idcategory.toString());

          }
          else //all properties
          {
            this.buildjsonrequestSearch('','');
          }

        }
        else
        this.lstpropertylist[i].isSelected = false;
      }
    }
  }

  get f(){
    return this.formfilterproperty.controls;
  }

  resetgridListVariables()
  {
    this.IdProperty0 = '0';
  }
  public updateGridListVariables()
  {
    this.resetgridListVariables();


    for(var i = 0; i < this.propertieslistPageToShow.length-1; i++)
    {

      if(this.propertieslistPageToShow[i] != undefined)
      {



        if(i == 0)
        {
          this.imageurl0 = this.propertieslistPageToShow[i].imageurl;
          this.Name0 = this.propertieslistPageToShow[i].Name;
          this.OwnerName0 = this.propertieslistPageToShow[i].OwnerName;
          this.Price0 = this.propertieslistPageToShow[i].Price;
          this.Beds0 = this.propertieslistPageToShow[i].Beds;
          this.Baths0 = this.propertieslistPageToShow[i].Baths;
          this.Area0 = this.propertieslistPageToShow[i].SquareFeet;
          this.IdProperty0 = this.propertieslistPageToShow[i].IdProperty;

        }
        if(i == 1)
        {
          this.imageurl1 = this.propertieslistPageToShow[i].imageurl;
          this.Name1 = this.propertieslistPageToShow[i].Name;
          this.OwnerName1 = this.propertieslistPageToShow[i].OwnerName;
          this.Price1 = this.propertieslistPageToShow[i].Price;
          this.Beds1 = this.propertieslistPageToShow[i].Beds;
          this.Baths1 = this.propertieslistPageToShow[i].Baths;
          this.Area1 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 2)
        {
          this.imageurl2 = this.propertieslistPageToShow[i].imageurl;
          this.Name2 = this.propertieslistPageToShow[i].Name;
          this.OwnerName2 = this.propertieslistPageToShow[i].OwnerName;
          this.Price2 = this.propertieslistPageToShow[i].Price;
          this.Beds2 = this.propertieslistPageToShow[i].Beds;
          this.Baths2 = this.propertieslistPageToShow[i].Baths;
          this.Area2 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 3)
        {
          this.imageurl3 = this.propertieslistPageToShow[i].imageurl;
          this.Name3 = this.propertieslistPageToShow[i].Name;
          this.OwnerName3 = this.propertieslistPageToShow[i].OwnerName;
          this.Price3 = this.propertieslistPageToShow[i].Price;
          this.Beds3 = this.propertieslistPageToShow[i].Beds;
          this.Baths3 = this.propertieslistPageToShow[i].Baths;
          this.Area3 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 4)
        {
          this.imageurl4 = this.propertieslistPageToShow[i].imageurl;
          this.Name4 = this.propertieslistPageToShow[i].Name;
          this.OwnerName4 = this.propertieslistPageToShow[i].OwnerName;
          this.Price4 = this.propertieslistPageToShow[i].Price;
          this.Beds4 = this.propertieslistPageToShow[i].Beds;
          this.Baths4 = this.propertieslistPageToShow[i].Baths;
          this.Area4 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 5)
        {
          this.imageurl5 = this.propertieslistPageToShow[i].imageurl;
          this.Name5 = this.propertieslistPageToShow[i].Name;
          this.OwnerName5 = this.propertieslistPageToShow[i].OwnerName;
          this.Price5 = this.propertieslistPageToShow[i].Price;
          this.Beds5 = this.propertieslistPageToShow[i].Beds;
          this.Baths5 = this.propertieslistPageToShow[i].Baths;
          this.Area5 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 6)
        {
          this.imageurl6 = this.propertieslistPageToShow[i].imageurl;
          this.Name6 = this.propertieslistPageToShow[i].Name;
          this.OwnerName6 = this.propertieslistPageToShow[i].OwnerName;
          this.Price6 = this.propertieslistPageToShow[i].Price;
          this.Beds6 = this.propertieslistPageToShow[i].Beds;
          this.Baths6 = this.propertieslistPageToShow[i].Baths;
          this.Area6 = this.propertieslistPageToShow[i].SquareFeet;
        }
        if(i == 7)
        {
          this.imageurl7 = this.propertieslistPageToShow[i].imageurl;
          this.Name7 = this.propertieslistPageToShow[i].Name;
          this.OwnerName7 = this.propertieslistPageToShow[i].OwnerName;
          this.Price7 = this.propertieslistPageToShow[i].Price;
          this.Beds7 = this.propertieslistPageToShow[i].Beds;
          this.Baths7 = this.propertieslistPageToShow[i].Baths;
          this.Area7 = this.propertieslistPageToShow[i].SquareFeet;
        }

    }
    }

  }

  ///////////////////////////////////////// functions  ////////////////////////////////////////////////

  changepropertylistGrid(): void {
  this.showpropertylistGrid = !this.showpropertylistGrid;
}

loadScripts() {
  const dynamicScripts = [
    'assets/js/theme.js',
    'assets/vendors/jquery.min.js',
    'assets/vendors/jquery-ui/jquery-ui.min.js',
    'assets/vendors/bootstrap/bootstrap.bundle.js',
    'assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
    'assets/vendors/slick/slick.min.js',
    'assets/vendors/waypoints/jquery.waypoints.min.js',
    'assets/vendors/counter/countUp.js',
    'assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
    'assets/vendors/chartjs/Chart.min.js',
    'assets/vendors/dropzone/js/dropzone.min.js',
    'assets/vendors/timepicker/bootstrap-timepicker.min.js',
    'assets/vendors/hc-sticky/hc-sticky.min.js',
    'assets/vendors/jparallax/TweenMax.min.js',
    'assets/vendors/mapbox-gl/mapbox-gl.js',
    'assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js'

  ];
  for (let i = 0; i < dynamicScripts.length; i++) {
    const node = document.createElement('script');
    node.src = dynamicScripts[i];
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
}
