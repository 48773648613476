import { Component, OnInit, Input } from '@angular/core';
import {
  AuthenticationService,
  eventsService,
  propertiesService,
} from '../../../../services';
import { Router } from '@angular/router';
import { GenerictoastmessageComponent } from '../../../../generictoastmessage/generictoastmessage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { DataInviteService } from 'src/app/services/invites.service';

//
export class eventscount {
  constructor(
    public IDEvent: number,
    public Name: string,
    public total: string,
    public linkdetails: string
  ) {}
}

@Component({
  selector: 'app-leftmenumypainel',
  templateUrl: './leftmenumypainel.component.html',
  styleUrls: ['./leftmenumypainel.component.css'],
})
export class LeftmenumypainelComponent implements OnInit {
  @Input() changeTotal: any
  public howId: any
  public isexternalproviderlogin = false;
  public events = new Array<eventscount>();
  public managelistingtitle = '';
  public url = '';
  public serviceTotal = 0;
  public leaseTotal = 0;
  public invites = [];
  public loaded = false;

  private iduser: string;
  public userIdProfile: number;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private propertiesservice: propertiesService,
    private eventservice: eventsService,
    private modalService: NgbModal,
    private dataService: DataInviteService
  ) {
    this.dataService.dataAtt.subscribe((dados) => {
      this.howId = dados;
      if (dados !== 0) {
        this.ngOnInit()
        dados = 0
        this.howId = 0
      }
    });
  }

  async ngOnInit() {
    if (
      +JSON.parse(localStorage.getItem('roundcombuserprovider')).loginprovider >
      0
    )
      this.isexternalproviderlogin = true;

    const userinfo = localStorage.getItem('roundcombuserdetail');

    let UserjsonObject = JSON.parse(userinfo);

    this.iduser = UserjsonObject.Id;
    this.userIdProfile = UserjsonObject.userIdProfile;

    //this.events = JSON.parse('[{"IDEvent":"1","Name":"Lease","total":"5"},{"IDEvent":"2","Name":"Inspection","total":"3"}');
    await this.geteventsServices();
    await this.getevents();
    this.getInvites(this.userIdProfile)

    if (this.isuserlandlord()) this.managelistingtitle = 'My Properties';
    else this.managelistingtitle = 'My Leases';
    this.url = this.router.url
    if(this.howId === 2) {
      await this.geteventsServices();
    } else if ( this.howId === 1) {
      await this.getevents();
    }
  }
  async getevents() {
    this.eventservice
      .getmyEventsListCount(this.iduser)
      .pipe()
      .subscribe(
        (data) => {
          if(JSON.parse(data).length > 0) {
            this.leaseTotal = JSON.parse(data)[0].total;
          }
        },
        (error) => {}
      );
  }
  async geteventsServices() {
    await this.eventservice
      .getmyEventsServicesListCount(this.userIdProfile)
      .toPromise()
      .then((response) => {
        // this.events = JSON.parse(response[0]);
        if(response.length > 0) {
          this.events = response;
          this.serviceTotal = response[0].total;
        }
      });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }

  navigateToPageEvent(destpage: string, paramname: string, paramvalue: number) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'IDEvent') {
        this.router.navigate(['/' + destpage], {
          queryParams: { IDEvent: paramvalue },
        });
      }
    }
    this.ngOnInit()
  }

   getInvites(id) {
     this.propertiesservice
      .getInvites(id)
      .toPromise()
      .then((response) => {
        this.invites = response;
        this.loaded = true
      });
  }

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    /*
  if(destpage == 'addnewproperty' && !this.isuserlandlord())
  {
    this.showToastMessage("Only users with Landlord profile can add new properties!");
  }
  else{
*/
    /* window.scrollTo(0,0);

    let activeDiv = document.getElementById("topmenu");

    if(activeDiv.classList.contains("show"))
      activeDiv.classList.remove('show');
  */
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'IDEvent') {
        this.router.navigate(['/' + destpage], {
          queryParams: { IDEvent: paramvalue },
        });
      }
    }
    this.ngOnInit()
    /*}*/
  }
  showToastMessage(message) {
    const modalRef = this.modalService.open(GenerictoastmessageComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
    });
    let data = {
      prop1: 'Information',
      prop2: message,
      prop3: 'This Can be anything',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  public isuserlandlord() {
    if (this.authenticationService.roles.includes('landlord')) return true;
    else return false;
  }
  public isuservendor() {
    if (this.authenticationService.roles.includes('vendor')) return true;
    else return false;
  }

  public isusersysadmin() {
    if (this.authenticationService.roles.includes('sysadmin')) return true;
    else return false;
  }
}
