import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';

// structure of the message client <-> server
export class Message {
  constructor(
      public guidChatRoom: string,
      public JSONformatcontent: string,
      public isBroadcast = false,
      public senderID: string,
      public senderName: string,
      public event:string
  ) { }
}


@Injectable({
  providedIn: 'root'
})
export class ChatwebsocketService {

  private socket$: WebSocketSubject<Message>;

  constructor() { 

    this.socket$ = new WebSocketSubject('wss://roundcomb.rwi-cloud.com/chat.server');

  }

  public getSocket()
  {
    return this.socket$;
  }
}
