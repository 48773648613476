import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService, usersService } from '../../services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsComponent } from '../../google-maps/google-maps.component';
import { CropprofileimageComponent } from '../../user/myprofile/cropprofileimage/cropprofileimage.component';
import {
  IAngularMyDpOptions,
  IMyCalendarViewChanged,
  IMyDateModel,
  IMyDisabledDates,
} from 'angular-mydatepicker';
import { Guid } from 'guid-typescript';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { isConstructSignatureDeclaration } from 'typescript';
import { faXmark, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { HeaderloggedComponent } from 'src/app/shared/layout/headerlogged/headerlogged.component';
import { DataService } from 'src/app/services/profile.service';

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css'],
})
export class MyprofileComponent implements OnInit, AfterViewInit {
  data: boolean;

  url: any;
  filedata: any;
  coordinates: Coordinates;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;

  islocationcheked = false;

  public myOptions: IAngularMyDpOptions = {
    dateRange: false, // If false = date picker, if true = date range picker
    dateFormat: 'mm-dd-yyyy',
    // disable all dates by default
    disableDateRanges: [],
    // other options are here...
  };

  myprofileForm: FormGroup;
  public firstname: string;
  public lastname: string;
  public email: string;
  public phonenumber: string;
  public locationId: string;
  public imageurl: string = 'assets/images/User-Default.jpg';
  public profilesumary: string;
  public iduser: string;
  public birthday: string;
  private datachanged: boolean = false;
  public isexternalproviderlogin = false;
  public isSavedmyprofile: boolean = false;
  public istoUploadfoto: boolean = false;
  public userroles: string;

  public origfirstname: string;
  public origlastname: string;
  public origphonenumber: string;
  public myDatePickerOptions: any;

  //public userinfo:string;
  public modelbirthdate: IMyDateModel = null;

  constructor(
    private formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private userservice: usersService,
    private router: Router,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService,
    private dataService: DataService
  ) {
    this.dataService.dataAtt.subscribe((dados) => {
      this.data = dados;
    });
    this.coordinates = {} as Coordinates;
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.ngxService.stopBackground('loadUserInfo');
    this.myprofileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', Validators.required],
      address: [''],
      iduser: [''],
      myDate: [''],
      url: [''],
      profile: [''],
    });

    this.getUserDetailsFromServer();
    //config calendar - 18 years
    this.onCalendarViewChanged();
  }
  onCalendarViewChanged() {
    let date: Date = new Date();
    var year: number = +date.getFullYear();
    let copy = this.getCopyOfOptions();
    copy.disableDateRanges = [
      {
        begin: { year: year - 18, month: 1, day: 1 },
        end: { year: 9999, month: 12, day: 31 },
      },
    ];
    this.myOptions = copy;
  }
  // get copy of options
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.myOptions));
  }

  getUserDetailsFromServer() {
    if (
      +JSON.parse(localStorage.getItem('roundcombuserprovider')).loginprovider >
      0
    )
      this.isexternalproviderlogin = true;

    const userinfo = localStorage.getItem('roundcombuserdetail');

    let UserjsonObject = JSON.parse(userinfo);

    if (
      UserjsonObject.ProfilePhotoUrl == null ||
      UserjsonObject.ProfilePhotoUrl == 'null'
    )
      this.url = 'assets/images/User-Default.jpg';
    else if (String(UserjsonObject.ProfilePhotoUrl).startsWith('https'))
      this.url = UserjsonObject.ProfilePhotoUrl;
    else
      this.url = `${environment.apiUrl}`.concat(
        '/',
        UserjsonObject.ProfilePhotoUrl
      );

    this.firstname = UserjsonObject.FirstName;
    this.lastname = UserjsonObject.LastName;
    this.iduser = UserjsonObject.Id;
    this.email = UserjsonObject.Email;
    this.phonenumber = UserjsonObject.PhoneNumber;

    this.address = String(UserjsonObject.Address).replace('null', '');

    if (this.address.length > 0) this.islocationcheked = true;

    if (Date.parse(UserjsonObject.Birthday) > 0) {
      let date: Date = new Date(UserjsonObject.Birthday);

      var year: number = +date.getFullYear();
      var day: number = +date.getDate();
      var month: number = +date.getMonth();

      this.modelbirthdate = {
        isRange: false,
        singleDate: { date: { year: year, month: month + 1, day: day } },
      };

      this.birthday = UserjsonObject.Birthday;
    }
    //var birthdate = UserjsonObject.Birthday;

    this.origfirstname = this.firstname;
    this.origlastname = this.lastname;
    this.origphonenumber = this.phonenumber;
  }

  // optional date changed callback
  onDateChanged(event: IMyDateModel): void {
    this.modelbirthdate = {
      isRange: false,
      singleDate: {
        date: {
          year: event.singleDate.date.year,
          month: event.singleDate.date.month,
          day: event.singleDate.date.day,
        },
      },
    }; //event.singleDate.date.formatted;
    this.birthday = event.singleDate.formatted;
    this.datachanged = true;
  }

  clearlocation() {
    this.address = '';
    this.datachanged = true;
    this.islocationcheked = false;
  }

  openCropImageModal(event: any) {
    const modalRef = this.modalService.open(CropprofileimageComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
      //size: 'lg',
      //backdrop: 'static'
    });
    let data = {
      prop1: event,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        if (result != 'close') {
          this.url = result;
          this.filedata = result;

          document.getElementById('imgfile').setAttribute('value', '');
          this.istoUploadfoto = true;
          this.datachanged = true;
        }
      },
      (reason) => {}
    );
  }

  onFileSelect(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      //this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      //this.msg = "Only images are supported";
      return;
    }

    this.openCropImageModal(event);

    //document.getElementById('profileimage').style.borderColor = "red";

    //this.datachanged = true;
    //this.istoUploadfoto = true;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }

  onChangefirstname(event: any) {
    //document.getElementById('firstname').style.borderColor = "red";
    this.firstname = event.target.value;
    this.datachanged = true;
  }

  onChangelastname(event: any) {
    //document.getElementById('lastname').style.borderColor = "red";
    this.lastname = event.target.value;
    this.datachanged = true;
  }

  onChangeaddress(event: any) {
    //document.getElementById('address').style.borderColor = "red";
    this.address = event;
    this.datachanged = true;
  }

  onChangebirthday(event: any) {
    /*document.getElementById('birthday').style.borderColor = "red";
  this.birthday = event.target.value;
  this.datachanged = true;
  */
  }

  onChangephonenumber(event: any) {
    //document.getElementById('phonenumber').style.borderColor = "red";
    this.phonenumber = event.target.value;
    this.datachanged = true;
  }

  openGoogelMapsModal() {
    const modalRef = this.modalService.open(GoogleMapsComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclassgmaps',
      // keyboard: false,
      // backdrop: 'static'
    });
    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        this.coordinates = result;

        if (this.coordinates.address.length > 0) {
          this.onChangeaddress(this.coordinates.address);

          this.islocationcheked = true;
        }
      },
      (reason) => {}
    );
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg',
    });
  }
  closeSave() {
    this.isSavedmyprofile = false;
  }

  onSubmit() {
    if (this.datachanged) {
      if (this.istoUploadfoto) {
        const formData = new FormData();
        formData.append(
          'file[0]',
          this.dataURItoBlob(this.url),
          Guid.create().toString().concat('.png')
        );

        this.userservice
          .setUserprofilephoto(formData)
          .pipe()
          .subscribe(
            (data) => {
              this.url = JSON.parse(JSON.stringify(data)).FileUrl;
              this.authenticationService.setuserProfileImage(this.url);
              this.istoUploadfoto = false;
              this.datachanged = false;

              this.userservice
                .setUsermyProfile(
                  this.iduser,
                  this.firstname,
                  this.lastname,
                  this.email,
                  this.url,
                  this.birthday,
                  this.phonenumber,
                  this.locationId,
                  this.address
                )
                .pipe()
                .subscribe(
                  (data) => {
                    //reload data and set immedialty user name

                    this.isSavedmyprofile = true;
                    /*document.getElementById('firstname').style.borderColor = "#ededed";
                    document.getElementById('lastname').style.borderColor = "#ededed";
                    document.getElementById('address').style.borderColor = "#ededed";
                    document.getElementById('phonenumber').style.borderColor = "#ededed";
                    document.getElementById('myDate').style.borderColor = "#ededed";
                    */
                    this.authenticationService.getLoginDetailswithtoken(
                      this.firstname,
                      this.lastname
                    );
                    this.dataService.attData(true);
                  },
                  (error) => {}
                );
            },
            (error) => {}
          );
      } else {
        this.userservice
          .setUsermyProfile(
            this.iduser,
            this.firstname,
            this.lastname,
            this.email,
            this.url,
            this.birthday,
            this.phonenumber,
            this.locationId,
            this.address
          )
          .pipe()
          .subscribe(
            (data) => {
              this.isSavedmyprofile = true;
              //reload data and set immedialty user name
              this.authenticationService.getLoginDetailswithtoken(
                this.firstname,
                this.lastname
              );
              this.dataService.attData(true);
            },
            (error) => {}
          );
      }
    }
  }
}
