import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup} from '@angular/forms';

@Component({
  selector: 'app-propertycreatedsuccessfully',
  templateUrl: './propertycreatedsuccessfully.component.html',
  styleUrls: ['./propertycreatedsuccessfully.component.css']
})
export class PropertycreatedsuccessfullyComponent implements OnInit {

  public showsuccessscreen: boolean = false;
  formcontactlandlord: FormGroup;
  public OwnerName:string;
  public OwnerPhotourl:string;
  public phonenumber:string;
  public istoshowphonenumber:boolean = false;

  @Input() fromParent;
  public firstmessage: string;
  //public event: string = "Lease";
  
  constructor(public activeModal: NgbActiveModal, ) { }

  ngOnInit(): void {

  }

  closeModal() { 

    this.activeModal.close('close'); 
  }


}
