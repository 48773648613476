<div class="modal-header">
  <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">CONTACT
    {{this.Name | uppercase}}</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <ng-container *ngIf="!showsuccessscreen">


    <div class="card mb-4" style="border:0px">
      <div class="card-body px-6 py-4">
        <div class="media mb-4">

          <div class="recipient-block" style="justify-content: center;">
            <img loading="lazy" src="{{this.OwnerPhotourl}}"
              style="max-width:150px;display: block;margin-left: auto;margin-right: auto;" alt="">


          </div>

          <div class="media-body">

            <p class="fs-16 lh-1 text-dark mb-0 font-weight-500">
              {{this.OwnerName}}
            </p>

            <p class="mb-0">{{this.Name}}</p>
            <ng-container *ngIf="istoshowphonenumber">
              <p class="text-heading font-weight-500 mb-0">
                <span class="text-primary d-inline-block mr-1"><i class="fal fa-phone"></i></span>
                {{phonenumber}}
              </p>
            </ng-container>
            <div style="font-size: 12px; font-weight: bold;">
              {{this.ratingCalc}}/5
            </div>
            <div class="stars-note">
              <ng-container *ngIf="this.ratingLength <= 0">
                <div class="arrow arrow-vendor star">
                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                </div>
                <div class="arrow arrow-vendor star">
                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                </div>
                <div class="arrow arrow-vendor star">
                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                </div>
                <div class="arrow arrow-vendor star">
                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                </div>
                <div class="arrow arrow-vendor star">
                  <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="this.ratingLength > 0">
                <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                  <ng-container *ngIf="this.ratingCalc > ratingConditional.moreThan">
                    <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="this.ratingCalc > ratingConditional.inBetweenLess && this.ratingCalc <= ratingConditional.inBetweenMore">
                    <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                    <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="this.ratingCalc <= ratingConditional.lessThan">
                    <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
        <form [formGroup]="formcontactlandlord" class="form" (ngSubmit)="setCreateNewEvent()">
          <!--
            <div class="form-group mb-4">
                <label for="event" class="text-heading">Event</label>
                <input type="text" class="form-control form-control-lg border-0"
                    id="event" name="event" formControlName="event"
                    [(ngModel)]="event" readonly>
            </div>-->
          <div class="form-group mb-4">
            <label for="firstmessage" class="text-heading">Message</label>
            <textarea class="form-control border-0" rows="4" id="firstmessage" formControlName="firstmessage"
              placeholder="Message" [(ngModel)]="firstmessage"></textarea>
          </div>
          <button type="submit" class="btn btn-primary btn-lg btn-block shadow-none">Contact {{this.Name}}
          </button>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showsuccessscreen">
    <table style="border-collapse: collapse; width: 100%; text-align: center;" border="0">
      <tbody>
        <tr>
          <td style="width: 100%;">
            <!--<svg xmlns="http://www.w3.org/2000/svg" width="376.58" height="376.58" viewBox="0 0 376.58 376.58">
                <path id="frown-regular" d="M188.29,8A188.29,188.29,0,1,0,376.58,196.29,188.258,188.258,0,0,0,188.29,8Zm0,340.137c-83.744,0-151.847-68.1-151.847-151.847S104.547,44.443,188.29,44.443s151.847,68.1,151.847,151.847S272.034,348.137,188.29,348.137ZM127.551,184.142a24.3,24.3,0,1,0-24.3-24.3A24.269,24.269,0,0,0,127.551,184.142Zm121.477-48.591a24.3,24.3,0,1,0,24.3,24.3A24.269,24.269,0,0,0,249.029,135.551ZM188.29,232.733a102.374,102.374,0,0,0-78.808,36.9A18.222,18.222,0,1,0,137.5,292.94a66.029,66.029,0,0,1,101.585,0A18.222,18.222,0,1,0,267.1,269.632,102.374,102.374,0,0,0,188.29,232.733Z" transform="translate(0 -8)" fill="#ff993c"/>
              </svg>
            -->
            <svg xmlns="http://www.w3.org/2000/svg" width="216.58" height="216.58" viewBox="0 0 439 439">
              <path id="check-circle-solid"
                d="M447,227.5C447,348.727,348.727,447,227.5,447S8,348.727,8,227.5,106.273,8,227.5,8,447,106.273,447,227.5ZM202.111,343.723,364.965,180.869a14.161,14.161,0,0,0,0-20.027l-20.027-20.027a14.161,14.161,0,0,0-20.028,0L192.1,273.629l-62.008-62.008a14.163,14.163,0,0,0-20.028,0L90.035,231.648a14.161,14.161,0,0,0,0,20.027l92.048,92.048a14.162,14.162,0,0,0,20.028,0Z"
                transform="translate(-8 -8)" fill="#0ec6d5" />
            </svg>

          </td>
        </tr>
        <tr>
          <td style="width: 100%;">
            NEW EVENT CREATED! <br>Please acess you area to continue the chat.
          </td>
        </tr>
        <tr>
          <td style="width: 100%;">
            <br>In case of any doubt, read the terms of use and or get in touch with us! (support@roundcomb.com)
          </td>
        </tr>
      </tbody>
    </table>

  </ng-container>

</div>
<div class="modal-footer">

  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>

</div>
