import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-addnewamenitiemodal',
  templateUrl: './addnewamenitiemodal.component.html',
  styleUrls: ['./addnewamenitiemodal.component.css']
})
export class AddnewamenitiemodalComponent implements OnInit {

  public name:string = "";
  public description:string = "";
  public action:string = "close";


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() { 

    this.activeModal.close(); 
  }

  updatevalue(event:any,component:string)
  {
       if(component == 'name')
        this.name = event.target.value;
        else
          if(component == 'description')
            this.description = event.target.value;
  }

  savenewservice()
  {
    const data = {
      name: this.name,
      description: this.description
    }

    this.activeModal.close(data);
  }
}