<section class="py-8" data-animated-id="2">
    <div class="container">
        <div class="row ml-xl-0 mr-xl-n6">
            <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div class="primary-sidebar-inner" style="position: static; left: auto; width: 370px;">

                    <div class="px-6 pb-6">

                        <ul class="list-group list-group-no-border" style="padding: 10px 15px; background-color: white;">
                            <li class="list-group-item p-0">
                                <a class="d-flex text-body hover-primary">
                                    <span class="lh-29"><strong>Glossary</strong></span>
                                </a>
                            </li>
                            <li class="list-group-item p-0">
                                <a class="d-flex text-body hover-primary" routerLink="/accountandprofile">
                                    <span class="lh-29">Account and Profile</span>

                                </a>
                            </li>
                            <li class="list-group-item p-0">
                                <a class="d-flex text-body hover-primary"  routerLink="/Signingup">
                                    <span class="lh-29">Signing Up</span>

                                </a>
                            </li>
                            <li class="list-group-item p-0">
                                <a class="d-flex text-body hover-primary" routerLink="/showcasingyourpropertyholdings">
                                    <span class="lh-29">Showcasing your property holdings</span>

                                </a>
                            </li>
                            <li class="list-group-item p-0">
                                <a class="d-flex text-body hover-primary" routerLink="/updatingratingsandrentalhistory">
                                    <span class="lh-29">Updating ratings and rental history</span>

                                </a>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
                <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

                    <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
                        data-animate="fadeInDown">Glossary</h1>
                    <!--
                    <form>
                        <div class="position-relative pt-5">
                            <input type="text" id="search02" class="form-control form-control-lg border-0 shadow-none"
                                placeholder="Search..." name="search">
                            <div class="position-absolute pos-fixed-center-right">
                                <button type="submit" class="btn fs-15 text-dark shadow-none"><i
                                        class="fal fa-search"></i></button>
                            </div>
                        </div>
                    </form>
                -->
                    <div id="selling-collapse-01" class="collapse collapsible show pt-5"
                        aria-labelledby="headingSelling-01" data-parent="#collapse-tabs-accordion-01">
                        <div id="accordion-style-01" class="accordion accordion-01 row my-7 my-md-0 mx-3 mx-md-0">
                            <div class="col-md-12">
                                <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                    <div class="card-header border-0 p-0 rounded-top" id="heading_1">
                                        <h5 class="mb-0">
                                            <button
                                                class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                data-toggle="collapse" data-target="#collapse_1" aria-expanded="false"
                                                aria-controls="collapse_1">
                                                HOW DO I BECOME A SUBSCRIBING LANDLORD?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse_1" class="collapse" aria-labelledby="heading_1"
                                        data-parent="#accordion-style-01" style="">
                                        <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                            Register to create your user/personal profile, upload your property or
                                            properties showing listing status i.e. Rent/Sale/Occupied, then subscribe to
                                            Roundcomb's services. Applicants can view your listed properties and submit
                                            applications
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-0 shadow-xxs-2 mb-6 rounded-top overflow-hidden">
                                    <div class="card-header border-0 p-0" id="heading_2">
                                        <h5 class="mb-0">
                                            <button
                                                class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                data-toggle="collapse" data-target="#collapse_2" aria-expanded="false"
                                                aria-controls="collapse_2">
                                                WHAT DO I BENEFIT AS A SUBSCRIBING LANDLORD?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse_2" class="collapse" aria-labelledby="heading_2"
                                        data-parent="#accordion-style-01" style="">
                                        <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                            As a subscribing landlord or property manager, you will be notified of the
                                            people viewing your property for rent or sale, and you will receive and view
                                            completed applications submitted by interested parties. You can review the
                                            applicant's rental history and assigned rating(s), if renting from a
                                            subscribing landlord on Roundcomb, and at your sole discretion, you can
                                            proceed with a credit or background check by clicking the Transunion link on
                                            our site. You can proactively send listing information to prospective
                                            tenants with matching specifications.
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                    <div class="card-header border-0 p-0" id="heading_3">
                                        <h5 class="mb-0">
                                            <button
                                                class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                data-toggle="collapse" data-target="#collapse_3" aria-expanded="false"
                                                aria-controls="collapse_3">
                                                WHAT IF I CHOOSE NOT TO SUBSCRIBE?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse_3" class="collapse" aria-labelledby="heading_3"
                                        data-parent="#accordion-style-01" style="">
                                        <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                            You can still register and create your profile, upload property holdings
                                            showing listing status i.e. Rent/Sale/Occupied. A user or applicant can view
                                            your properties listed for rent or sale and you will be notified as people
                                            view your property. However, you cannot have access to completed
                                            applications, as such submissions will not be enabled.
                                        </div>
                                    </div>
                                </div>
                                <div class=" pt-5"></div>
                                <div class="card border-0 shadow-xxs-2 rounded-top overflow-hidden">
                                    <div class="card-header border-0 p-0" id="heading_4">
                                        <h5 class="mb-0">
                                            <button
                                                class="btn btn-link font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed"
                                                data-toggle="collapse" data-target="#collapse_4" aria-expanded="false"
                                                aria-controls="collapse_4">
                                                WHAT IF I'M A TENANT?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse_4" class="collapse" aria-labelledby="heading_4"
                                        data-parent="#accordion-style-01" style="">
                                        <div class="card-body fs-13 lh-2 pl-6 pr-7 pb-6">
                                            As a tenant or prospective tenant, you can register and create your
                                            user/personal profile. Thereafter, you can search for properties and submit
                                            applications. You can customize and save your search criteria, highlighting
                                            property features of interest to you, including move-in date. No subscription
                                            is required.
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                    <h1 class="fs-20 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated pt-6"
                    data-animate="fadeInDown">HAVE MORE QUESTIONS?</h1>

                    <div class="text-center pt-2">
                        <a routerLink="/contactus" class="btn btn-lg btn-primary px-9">Contact us</a>
                        </div>

                </div>


            </div>

        </div>
    </div>
</section>
