<ng-container *ngIf="!isemailsentsuccessfull">
  <section class="py-8" data-animated-id="2">
    <div class="container" style="display: flex;justify-content: center;">

      <div class=" box-bg col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">


        <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
          data-animate="fadeInDown">Careers</h1>

        <h6 class="text-heading mb-2 fs-22 fs-md-20 text-center lh-16 fadeInDown">Looking for a place to start or
          advance your career? Please contact us:</h6>

        <form class="form" [formGroup]="careersapplyForm" (ngSubmit)="onSubmit()">


          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-4">
                <label for="firstname">First Name</label>
                <div class="input-group input-group-lg">
                  <input type="text" placeholder="First Name" class="form-control form-control-lg border-0"
                    formControlName="firstname" name="firstname" id="firstname" name="firstname"
                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                  <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                    <div *ngIf="f.firstname.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input type="text" placeholder="Last Name" name="lastname" formControlName="lastname"
                  class="form-control form-control-lg border-0" id="lastname" name="lastname"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last Name is required</div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input placeholder="Your Email" class="form-control form-control-lg border-0" type="email"
                  formControlName="email" name="email" id="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors}"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.pattern">Email is invalid</div>
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <input type="text" placeholder="Your Phone" name="phone" id="phone" formControlName="phone"
                  class="form-control form-control-lg border-0"
                  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors.required">Phone number is required</div>

                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-6">
            <label for="address">Address</label>
            <textarea class="form-control border-0" placeholder="Address" name="address " id="address"
              formControlName="address" rows="5" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">Address is required</div>

            </div>

          </div>
          <div class="form-group mb-6">
            <label for="city">City</label>
            <input class="form-control border-0" placeholder="City" name="city " id="city" formControlName="city">

          </div>
          <div class="form-group mb-6">
            <label for="state">State</label>
            <input class="form-control border-0" placeholder="State" name="state " id="state" formControlName="state">
          </div>
          <div class="form-group mb-6">
            <label for="postalcode">Zip/Postal Code</label>
            <input class="form-control border-0" placeholder="Zip/Postal Code" name="postalcode " id="postalcode"
              formControlName="postalcode">

          </div>
          <div class="form-group mb-6">
            <label for="coverletter">Cover Letter</label>
            <textarea class="form-control border-0" placeholder="Cover Letter" name="coverletter " id="coverletter"
              formControlName="coverletter" rows="5"></textarea>

          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary btn-lg rounded">Apply</button>
          </div>
        </form>


        <div class="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type="47"
          id="block-c83921fba08a46a81075">
          <div class="sqs-block-content">
            <hr>
          </div>
        </div>

        <div class="sqs-block html-block sqs-block-html" data-block-type="2" id="block-3c5e6d798a6576047ab1">
          <div class="sqs-block-content">
            <p>U.S. Equal Employment Opportunity/Affirmative Action Information</p>
            <p>Individuals seeking employment at Roundcomb are considered without regards to race, color, religion, sex,
              sexual orientation, gender identification, national origin, age, marital status, ancestry, physical or
              mental disability, or veteran status. You are being given the opportunity to provide the following
              information in order to help us comply with federal and state Equal Employment Opportunity/Affirmative
              Action record keeping, reporting, and other legal requirements.</p>
          </div>
        </div>

      </div>


    </div>

  </section>
</ng-container>
<ng-container *ngIf="isemailsentsuccessfull">
  <section class="py-13" data-animated-id="1">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 mx-auto">
          <div class="card border-0 login-register">
            <div class="card-body p-6">
              <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Thanks for your contact</h2>

              <div>
                <p class="mb-4">We've received your message. We will get back to you as soon as possbile.</p>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
