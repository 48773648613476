<section style="margin-top: 30px;" *ngIf="this.roleUser === 'Tenant'">
  <div class="wrapper">
    <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100"></div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">
        <div *ngIf="this.openModal === true" class="modal-background">
          <div class="modal-buy shadow">
            <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
              Payment
            </h3>
            <button (click)="closeModal()" class="close-icon">
              <img src="../../assets/images/close-icon.png" alt="Close icon" />
            </button>
            <div class="modal-overflow" style="overflow-y: auto; height: 400px; padding-right: 20px">
              <form *ngIf="this.stepPayment === 'add-card'" style="margin-bottom: 0" type="submit"
                class="payment-method-section">
                <div (click)="paymentModal(this.valuePayment)" id="paypal-box"
                  class="paypal-box box-to-position shadow">
                  <div class="dolar-symbol">
                    <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                  </div>
                  <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="paypal"
                    class="heading-3">
                    PayPal
                  </p>
                </div>
                <!-- (click)="setCurrent('credit', $event)" -->
                <div id="credit-box" class="card-box box-to-position shadow blocked">
                  <div class="dolar-symbol">
                    <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                  </div>
                  <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="credit"
                    class="heading-3">
                    Credit Card
                  </p>
                  <div class="payment-form" *ngIf="this.typeCurrent.length > 0">
                    <div class="owner-control">
                      <label style="margin-bottom: 0; font-weight: bold; color: black" for="owner-name">Owner
                        Name</label>
                      <input placeholder="John Wayme" class="modal-input" type="text" name="owner-name"
                        id="owner-name" />
                    </div>
                    <div class="card-info">
                      <input class="modal-input card-input" type="text" name="card" id="card"
                        placeholder="xxxx-xxxx-xxxx-1234" />
                      <div class="owner-control">
                        <label style="
                            margin-bottom: 0;
                            font-weight: bold;
                            color: black;
                          " for="cvv">CVV</label>
                        <input class="modal-input" type="number" name="cvv" id="cvv" placeholder="xxx" />
                      </div>
                    </div>
                    <div class="date-company">
                      <input class="modal-input" type="text" name="date" id="date" placeholder="2021, January 21" />
                      <div class="bank-companies">
                        <div class="visa to-style">
                          <img src="../../assets/images/visa-logo.png" alt="Visa logo" />
                        </div>
                        <div class="mastercard to-style">
                          <img src="../../assets/images/mastercard-logo.png" alt="mastercard logo" />
                        </div>
                        <div style="padding: 10px 5px" class="express to-style">
                          <img style="width: 80px" src="../../assets/images/american-express-logo.png"
                            alt="American express logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 34px;
                  ">
                  <button (click)="changeStep()" *ngIf="this.typeCurrent.length > 0" class="pay-button">
                    Pay now
                  </button>
                </div>
              </form>
              <form *ngIf="this.stepPayment === 'billing'" style="margin-bottom: 20px" type="submit"
                class="payment-method-section">
                <h3 class="heading-3">Billing Details</h3>
                <div class="input-fields">
                  <div class="first-column">
                    <div class="owner-control">
                      <label class="label" for="first-name">First Name</label>
                      <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="text"
                        name="first-name" id="first-name" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="last-name">Last Name</label>
                      <input style="background-color: white;" placeholder="James" class="modal-input" type="text"
                        name="last-name" id="last-name" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="email">Email</label>
                      <input style="background-color: white;" placeholder="saraj@gmail.com" class="modal-input"
                        type="email" name="email" id="email" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="phone">Phone</label>
                      <input style="background-color: white;" placeholder="+1 1234 1234" class="modal-input" type="text"
                        name="phone" id="phone" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="id-number">ID Number</label>
                      <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="number"
                        name="id-number" id="id-number" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="vat">VAT</label>
                      <input style="background-color: white;" placeholder="1234567" class="modal-input" type="number"
                        name="vat" id="vat" />
                    </div>
                  </div>
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="address">Address line 1</label>
                    <input style="background-color: white;" placeholder="123 Fakestreet" class="modal-input" type="text"
                      name="address" id="address" />
                  </div>
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="address-two">Address line 2</label>
                    <input style="background-color: white;" placeholder="" class="modal-input" type="text"
                      name="address-two" id="address-two" />
                  </div>
                  <div style="gap: 20px 30px;" class="first-column">
                    <div style="margin-top: 20px;" class="owner-control">
                      <label class="label" for="country">Country</label>
                      <input style="background-color: white;" placeholder="USA" class="modal-input" type="text"
                        name="country" id="country" />
                    </div>
                    <div style="margin-top: 20px;" class="owner-control">
                      <label class="label" for="address-two">State</label>
                      <input style="background-color: white;" placeholder="New Mexico" class="modal-input" type="text"
                        name="state" id="state" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="city">City</label>
                      <input style="background-color: white;" placeholder="Roswell" class="modal-input" type="text"
                        name="city" id="city" />
                    </div>
                    <div class="owner-control">
                      <label class="label" for="zip-code">ZIP CODE</label>
                      <input style="background-color: white;" placeholder="512345" class="modal-input" type="text"
                        name="zip-code" id="zip-code" />
                    </div>
                  </div>
                  <div class="terms-section">
                    <div (click)="checkTerms()" class="checkbox">
                      <div class="checked" *ngIf="this.checked === true"></div>
                    </div>
                    <p class="term">I have read and agree to the <a href="#">terms of use</a></p>
                  </div>
                  <div class="end-button">
                    <button [disabled]="this.checked === true ? false : true" class="submit-button">SUBMIT &
                      PAY</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <main id="content">
          <h2 class="component__title">Open payment events</h2>
          <div class="d-flex wrap d-flex-to-center mb-5 flex-wrap flex-xl-nowrap gap-box">
            <div class="personal-information">
              <div style="margin-top: 20px;" class="profile_payment shadow bg-white">
                <h3 class="title" style="text-align: center;">Open Payment Events</h3>
                <ul class="list-header list-header-payment" style="list-style: none; display: flex  !important;">
                  <li class="first-list">Property</li>
                  <li class="second-list">Date</li>
                  <li class="third-list">Status</li>
                </ul>
                <div style="display: flex; justify-content: center; margin-top: 20px;">
                  <div class="events-payment-box">
                    <div style="display: flex;" class="events-tenant-payment" *ngFor="let events of this.eventsPayment">
                      <p class="openevents-name" [title]="events.property" style="margin-bottom: 0;">
                        {{ events.property.length > 18 ? events.property.substring(0, 15) : events.property }}</p>
                      <span class="events-date">{{events.date}}</span>
                      <span *ngIf="events.status === 'Open' || events.status === 'refused'"
                        (click)="handleOpenBuySlotsModal(events.status, events.userAId, '',events.value, events.idProperty,events.date)"
                        [ngClass]="{'status-tenant': true,'open-status': events.status === 'Open','refused-status': events.status === 'refused'}">
                        {{events.status}}
                      </span>
                      <span *ngIf="events.status !== 'Open' && events.status !== 'refused'"
                        style="text-transform: capitalize; cursor: default !important;"
                        [ngClass]="{'status-tenant': true, 'paid-status': events.status === 'paid', 'due-status': events.status === 'Due', 'pendent-status': events.status === 'pendent'}">
                        {{events.status === 'pendent' ? 'Waiting' : events.status}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
<section style="margin-top: 30px;" *ngIf="this.roleUser === 'Landlord'">
  <div class="wrapper">
    <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px">
            <div style="padding-top: 0 !important;" class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100"></div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">
        <main id="content">
          <h2 class="component__title">Open payment events</h2>
          <div class="d-flex wrap d-flex-to-center mb-5 flex-wrap flex-xl-nowrap gap-box">
            <div class="personal-information">
              <div style="margin-top: 20px;" [ngClass]="{'profile-pendent': this.checkPendent.length > 0}"
                class="profile_payment shadow bg-white">
                <h3 class="title" style="text-align: center;">Open Payment Events</h3>
                <ul class="list-header list-header-payment" style="list-style: none; display: flex  !important;">
                  <li [ngClass]="{'first-pendent': this.checkPendent.length > 0}" class="first-list">Property</li>
                  <li [ngClass]="{'second-pendent': this.checkPendent.length > 0}" class="second-list">Date</li>
                  <li class="third-list">Status</li>
                </ul>
                <div class="event-display" style="display: flex; justify-content: center; margin-top: 20px;">
                  <div class="events-payment-box">
                    <div style="display: flex; position: relative;" [ngClass]="{'landlord-pendent': this.checkPendent.length > 0 && events.status === 'pendent'}" class="events-tenant-payment"
                      *ngFor="let events of this.eventsPayment;">
                      <p class="openevents-name" [title]="events.property" style="margin-bottom: 0;">
                        {{ events.property.length > 18 ? events.property.substring(0, 15) : events.property }}</p>
                      <span class="events-date">{{events.date}}</span>
                      <span *ngIf="events.status !== 'pendent'" style="text-transform: capitalize;"
                        [ngClass]="{'status-tenant': true, 'paid-status': events.status === 'paid', 'due-status': events.status === 'Due', 'open-status': events.status === 'Open','event-pendent': this.checkPendent.length > 0 && true,'refused-status': events.status === 'refused', 'refused-pendent': this.checkPendent.length > 0 && events.status === 'refused' && true}">
                        {{events.status}}
                      </span>
                      <div *ngIf="events.status === 'pendent'" class="actions-buttons">
                        <button (click)="updatePayment('refused', events.date, events.idProperty)"
                          class="refuse-button">Refuse</button>
                        <button (click)="updatePayment('paid', events.date, events.idProperty)"
                          class="accept-button">Accept</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
