<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
    <div class="container">
        <div class="row ml-xl-0 mr-xl-n6">
            <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div class="primary-sidebar-inner" style="position: static; left: auto; max-width: 370px; width: 100%;">

                        <div class="px-6 pb-6 media-list">

                            <ul class="list-group list-group-no-border" style="padding: 10px 15px; background-color: white;">
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary" routerLink="/whoweare">
                                        <span class="lh-29">Who We Are</span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a routerLink="/about" class="d-flex text-body hover-primary">
                                        <span class="lh-29">About Us</span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary" routerLink="/ourstory" >
                                        <span class="lh-29">Our Story</span>

                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a  class="d-flex text-body hover-primary">
                                        <span class="lh-29"><strong>Our Mission</strong></span>

                                    </a>
                                </li>
                            </ul>
                        </div>


                </div>
            </div>
            <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
                <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

                    <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated" data-animate="fadeInDown">Our Mission</h1>


                <p class="desc-static mb-4 lh-214 pt-5">Our mission is to re-define the future of property listing and rental business by restoring integrity and accountability in the system, which at present, is largely unfriendly to landlords, property managers, agents and other players in the space. Roundcomb is a due diligence platform that users can leverage in a collaborative fashion to proactively screen applicants in order to make informed rental decision. No longer would a provider rely solely on the information and references provided in a rental application, but also receive direct feedback by engaging and collaborating with other providers in our database. Roundcomb is therefore a virtual marketplace where landlords, property managers, agents, renters, and like-kind investors connect. Imagine a world where tenants strive to maintain the highest rating, while prudent investors with the best properties, proactively target them, all within the Roundcomb space. Imagine that. </p>
                                    </div>


            </div>

        </div>
    </div>
</section>
