import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applicationhistory',
  templateUrl: './applicationhistory.component.html',
  styleUrls: ['./applicationhistory.component.css']
})
export class ApplicationhistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
