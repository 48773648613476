<section>
  <div style="position: relative">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <!--  <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white"> -->
      <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
        <div style="height: 279px;">
          <div class="shadow-xs-1 py-3">
            <div class="d-flex px-3 px-xl-6 w-100">
            </div>
            <app-leftmenumypainel></app-leftmenumypainel>
          </div>
        </div>
      </nav>
      <!-- </div> -->
      <div class="page-content">

        <main id="content" class="bg-gray-01">
          <div style="padding-top: 0 !important; margin-top: 15px;"
            class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 media-width" data-animated-id="1">
            <div class="d-flex flex-wrap flex-md-nowrap mb-6">
              <div class="mr-0 mr-md-auto">
                <h2 class="mb-0 text-heading fs-22 lh-15">{{this.eventName}}<span
                    class="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">{{this.totalevents}}</span>
                </h2>
              </div>

              <form style="padding-top: 0 !important;" class="form-inline py-2">
                <label class="sr-only" for="inlineFormInputName2">Name</label>
                <input type="search" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Search"
                  id="freetextfilter" #s>

                <label class="sr-only" for="inlineFormInputGroupUsername2">Status</label>
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Status</div>
                  </div>
                  <select class="form-control" id="exampleFormControlSelect1" name="sortby" #t
                    (change)="onstatuschanged(t.value)">
                    <option [ngValue]="1" value="1">All</option>
                    <option [ngValue]="2" value="2">Presentation</option>
                    <option [ngValue]="3" value="3">Negotiation</option>
                    <option [ngValue]="4" value="4">{{this.eventName === 'Lease' ? "Information Supply" : 'Scheduling'}}
                    </option>
                    <option *ngIf="this.eventName === 'Lease'" [ngValue]="5" value="5">Validate your Identity</option>
                    <option *ngIf="this.eventName === 'Lease'" [ngValue]="6" value="6">Signing the Contract</option>
                    <option [ngValue]="7" value="7">Payment</option>
                  </select>
                </div>

                <button class="btn btn-outline-primary mb-2" type="submit"
                  (click)="searchevent(s.value)">Search</button>
              </form>
            </div>
            <div *ngIf="this.allEventsList.length > 0" class="table-responsive to-list-desktop">
              <table class="table table-hover bg-white border rounded-lg">
                <thead class="thead-sm thead-black">
                  <tr>
                    <th style="padding-left: 20px;" scope="col" class="border-top-0 pt-6 pb-3"><span
                        style="margin-left: 29px;">ID</span></th>
                    <th scope="col" class="border-top-0 px-6 pb-3" style="white-space: nowrap;">Event Type</th>
                    <th scope="col" class="border-top-0 px-6 pt-6 pb-3">Name</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3" style="white-space: nowrap;">Date Created</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3">Participants</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3">Status</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3" style="padding-left: 35px;">Action</th>
                    <th scope="col" class="border-top-0 pt-6 pb-3"></th>

                  </tr>
                </thead>
                <tbody>

                  <tr class="shadow-hover-xs-2 bg-hover-white"
                    *ngFor="let event of this.allEventsList | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                    <td class="align-middle">

                      <div style="display: flex; flex-wrap: nowrap;">
                        <ng-container *ngIf="event.totalnewmessages == '0'">
                          <span class="badge badge-info">{{event.totalnewmessages}}</span> <span
                            style="margin-left: 10px;">#{{event.propertyId}}</span>
                        </ng-container>
                        <ng-container *ngIf="event.totalnewmessages != '0'">

                          <span class="badge badge-danger">{{event.totalnewmessages}}</span> <span
                            style="margin-left: 10px;">#{{event.propertyId}}</span>
                        </ng-container>
                      </div>

                    </td>
                    <ng-container *ngIf="event.EventName == 'Maintenance'">
                      <td style="padding-left: 20px;" class="align-middle">Maintenance</td>
                    </ng-container>
                    <ng-container *ngIf="event.EventName != 'Maintenance'">
                      <td style="padding-left: 40px;" class="align-middle">Lease</td>
                    </ng-container>
                    <td class="align-middle p-6">
                      <span style="overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;">
                        {{event.Name}}
                      </span>
                    </td>
                    <td class="align-middle">{{ event.CreationDate | date: 'MM/dd/yyyy' }}</td>
                    <td style="display: flex; gap: 10px;" class="align-middle">
                      <div class="photo-participants">
                        <ng-container
                          *ngIf="event.IDUserChat_A.toString() === this.userId && event?.ImagemUserB === 'null'">
                          <img src="assets/images/User-Default.jpg" alt="User photo">
                        </ng-container>
                        <ng-container
                          *ngIf="event.IDUserChat_B.toString() === this.userId && event?.ImagemUserA === 'null'">
                          <img src="assets/images/User-Default.jpg" alt="User photo">
                        </ng-container>
                        <ng-container
                          *ngIf="event.IDUserChat_A.toString() === this.userId && event?.ImagemUserB !== 'null' && !event?.ImagemUserB.startsWith('https')">
                          <img
                            src="{{this.apiUrl + event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                            alt="User photo">
                        </ng-container>
                        <ng-container
                          *ngIf="event.IDUserChat_B.toString() === this.userId && event?.ImagemUserA !== 'null' && !event?.ImagemUserA?.startsWith('https')">
                          <img
                            src="{{this.apiUrl + event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                            alt="User photo">
                        </ng-container>
                        <ng-container
                          *ngIf="event.IDUserChat_A.toString() === this.userId && event?.ImagemUserB?.startsWith('https')">
                          <img
                            src="{{event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                            alt="User photo">
                        </ng-container>
                        <ng-container
                          *ngIf="event.IDUserChat_B.toString() === this.userId && event?.ImagemUserA?.startsWith('https')">
                          <img
                            src="{{event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                            alt="User photo">
                        </ng-container>
                      </div>
                      <div>
                        <p *ngIf="event.IDUserChat_A.toString() === this.userId" style="margin-bottom: 0 !important; font-size: 18px; text-transform: capitalize; overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;">
                          {{event.FNameUserB}} {{event.LNameUserB}}
                        </p>
                        <p *ngIf=" event.IDUserChat_B.toString()===this.userId" style="margin-bottom: 0 !important; font-size: 18px; text-transform: capitalize; overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;">
                          {{event.FNameUserA}} {{event.LNameUserA}}
                        </p>
                        <a href="
                          userreviews?id={{event.IDUserChat_A.toString() === this.userId ? event.IDUserChat_B : event.IDUserChat_A}}"
                          class="stars-note">
                          <ng-container
                            *ngIf="(event.ratingB === 'begin' && event.IDUserChat_B.toString() !== this.userId) || (event.ratingA === 'begin' && event.IDUserChat_A.toString() !== this.userId)">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="event.ratingA !== 'begin' && event.IDUserChat_A.toString() !== this.userId">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <ng-container *ngIf="event.ratingA >= ratingConditional.moreThan">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="event.ratingA > ratingConditional.inBetweenLess && event.ratingA <= ratingConditional.inBetweenMore">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingA <= ratingConditional.lessThan">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="event.ratingB !== 'begin' && event.IDUserChat_B.toString() !== this.userId">
                            <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                              <ng-container *ngIf="event.ratingB >= ratingConditional.moreThan">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container
                                *ngIf="event.ratingB > ratingConditional.inBetweenLess && event.ratingB <= ratingConditional.inBetweenMore">
                                <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                                <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="event.ratingB <= ratingConditional.lessThan">
                                <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                              </ng-container>
                            </div>
                          </ng-container>
                          </a>
                      </div>
                    </td>
                    <ng-container *ngIf="event.EventName == 'Maintenance'">
                      <td *ngIf="!event.JSON[3].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.status}}</span>
                      </td>
                      <td *ngIf="event.JSON[3].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.JSON[5].isStepclosed ? 'Finished' : 'Payment'}}</span>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="event.EventName != 'Maintenance'">
                      <td *ngIf="!event.JSON[4].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.status}}</span>
                      </td>
                      <td *ngIf="event.JSON[4].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.JSON[5].isStepclosed ? 'Finished' : 'Payment'}}</span>
                      </td>
                    </ng-container>
                    <td class="align-middle">
                      <a style="display: flex; align-items: center; justify-content: center;"
                        href="{{'chat?chatroomguid=' + event.chatroomguid}}"
                        class="btn btn-sm bg-hover-light border fs-14 px-3">View
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span>
                      </a>
                    </td>
                    <td class="align-middle">
                      <!--<a class="d-inline-block fs-18 text-muted hover-primary"><i
                                                    class="fal fa-trash-alt"></i></a>-->
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
            <div class="to-list-mobile" style="gap: 30px; flex-direction: column;">
              <div class="shadow-hover-xs-2 bg-hover-white" style="background-color: white; padding: 5px;"
                *ngFor="let event of this.allEventsList | paginate: { itemsPerPage: 4, currentPage: page, totalItems: count }; let i = index">
                <div class="align-middle">
                  <p style="text-align: center; margin-bottom: 10px; font-size: 20px;">
                    {{event.Name}}
                  </p>
                </div>
                <div style="display: flex;
                gap: 5px;">
                  <span class="span-weight">Type: </span>
                  <ng-container *ngIf="event.EventName == 'Maintenance'">
                    <p class="align-middle">Maintenance</p>
                  </ng-container>
                  <ng-container *ngIf="event.EventName != 'Maintenance'">
                    <p class="align-middle">Lease</p>
                  </ng-container>
                </div>
                <div class="align-middle" style="margin-bottom: 10px;">
                  <div style="display: flex; flex-wrap: nowrap;">
                    <ng-container *ngIf="event.totalnewmessages == '0'">
                      <span style="margin-right: 5px;" class="span-weight">ID: </span>
                      <span class="badge badge-info">{{event.totalnewmessages}}</span> <span
                        style="margin-left: 10px;">#{{event.propertyId}}</span>
                    </ng-container>
                    <ng-container *ngIf="event.totalnewmessages != '0'">
                      <span style="margin-right: 5px;" class="span-weight">ID: </span>
                      <span class="badge badge-danger">{{event.totalnewmessages}}</span> <span
                        style="margin-left: 10px;">#{{event.propertyId}}</span>
                    </ng-container>
                  </div>
                </div>
                <div class="align-middle">
                  <span class="span-weight">Date created: </span> {{ event.CreationDate | date: 'MM/dd/yyyy' }}
                </div>
                <div style="display: flex; gap: 10px; justify-content: center; margin: 10px 0;" class="align-middle">
                  <div class="photo-participants">
                    <ng-container *ngIf="event.IDUserChat_A.toString() === this.userId && event?.ImagemUserB === null">
                      <img src="assets/images/User-Default.jpg" alt="User photo">
                    </ng-container>
                    <ng-container *ngIf="event.IDUserChat_B.toString() === this.userId && event?.ImagemUserA === null">
                      <img src="assets/images/User-Default.jpg" alt="User photo">
                    </ng-container>
                    <ng-container
                      *ngIf="event.IDUserChat_A.toString() === this.userId && !event?.ImagemUserB.startsWith('https') && event?.ImagemUserB!== null">
                      <img
                        src="{{this.apiUrl + event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                        alt="User photo">
                    </ng-container>
                    <ng-container
                      *ngIf="event.IDUserChat_B.toString() === this.userId && !event?.ImagemUserA?.startsWith('https') && event?.ImagemUserA !== null">
                      <img
                        src="{{this.apiUrl + event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                        alt="User photo">
                    </ng-container>
                    <ng-container
                      *ngIf="event.IDUserChat_A.toString() === this.userId && event?.ImagemUserB?.startsWith('https')">
                      <img
                        src="{{event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                        alt="User photo">
                    </ng-container>
                    <ng-container
                      *ngIf="event.IDUserChat_B.toString() === this.userId && event?.ImagemUserA?.startsWith('https')">
                      <img
                        src="{{event.IDUserChat_A.toString() === this.userId ? event?.ImagemUserB : event?.ImagemUserA}}"
                        alt="User photo">
                    </ng-container>
                  </div>
                  <div>
                    <p *ngIf="event.IDUserChat_A.toString() === this.userId"
                      style="margin-bottom: 0 !important; font-size: 18px; text-transform: capitalize;">
                      {{event.FNameUserB}} {{event.LNameUserB}}
                    </p>
                    <p *ngIf="event.IDUserChat_B.toString() === this.userId"
                      style="margin-bottom: 0 !important; font-size: 18px; text-transform: capitalize;">
                      {{event.FNameUserA}} {{event.LNameUserA}}
                    </p>
                    <a href="userreviews?id={{event.IDUserChat_A.toString() === this.userId ? event.IDUserChat_B : event.IDUserChat_A}}"
                      class="stars-note">
                      <ng-container *ngIf="event.rating === 'begin'">
                        <div class="arrow arrow-vendor star">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="event.rating !== 'begin'">
                        <div class="arrow arrow-vendor star">
                          <ng-container *ngIf="event.rating > 0.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating > 0 && event.rating <= 0.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                            <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating <= 0">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <ng-container *ngIf="event.rating >= 2">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating >= 1.1 && event.rating <= 1.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                            <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating <= 1">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <ng-container *ngIf="event.rating >= 3">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating >= 2.1 && event.rating <= 2.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                            <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating <= 2">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <ng-container *ngIf="event.rating >= 4">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating >= 3.1 && event.rating <= 3.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                            <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating <= 3">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                        </div>
                        <div class="arrow arrow-vendor star">
                          <ng-container *ngIf="event.rating == 5">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating >= 4.1 && event.rating <= 4.9">
                            <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                            <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                          </ng-container>
                          <ng-container *ngIf="event.rating <= 4">
                            <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                          </ng-container>
                        </div>
                      </ng-container>
                    </a>
                  </div>
                </div>
                <div style="display: flex; justify-content: center; gap: 15px;">
                  <div style="display: flex;
                  flex-direction: column; gap: 10px;
                  align-items: center;">
                    <span class="span-weight">Status</span>
                    <ng-container *ngIf="event.EventName == 'Maintenance'">
                      <div *ngIf="!event.JSON[3].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.status}}</span>
                      </div>
                      <div *ngIf="event.JSON[3].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.JSON[5].isStepclosed ? 'Finished' : 'Payment'}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="event.EventName != 'Maintenance'">
                      <div *ngIf="!event.JSON[4].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.status}}</span>
                      </div>
                      <div *ngIf="event.JSON[4].isStepclosed" class="align-middle"><span
                          class="badge text-capitalize font-weight-normal fs-12 badge-yellow">{{event.JSON[5].isStepclosed ? 'Finished' : 'Payment'}}</span>
                      </div>
                    </ng-container>
                  </div>
                  <div style="display: flex;
                  flex-direction: column; gap: 10px;
                  align-items: center;">
                    <span class="span-weight">Action</span>
                    <div class="align-middle">
                      <a href="{{'chat?chatroomguid=' + event.chatroomguid}}"
                        class="btn btn-sm bg-hover-light border fs-14 px-3">
                        View
                        <span class="d-inline-block ml-1 text-primary "><i class="fal fa-eye"></i></span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <ng-container *ngIf="this.eventName != 'Maintenance'">
              <div *ngIf="this.empty" style="font-size: 18px;">
                No lease events!
              </div>
            </ng-container>
            <ng-container *ngIf="this.eventName == 'Maintenance'">
              <div *ngIf="this.empty" style="font-size: 18px;">
                No maintenance events!
              </div>
            </ng-container>
            <div *ngIf="this.allEventsList.length > 4" style="margin-top: 2rem;" class="d-flex justify-content-center">
              <div class="pagination-desktop">
                <pagination-template #p="paginationApi" (pageChange)="page = $event">
                  <div class="custom-pagination">
                    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                      <span (click)="toPrevious(p)">
                        < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                          [class.current]="p.getCurrent() === page.value">
                          <span (click)="setCurrent(p, page.value)"
                            *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                          <div class="pages" *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                          </div>
                    </div>
                    <div class="pagination-next" [class.disabled]="p.isLastPage()">
                      <span (click)="toNext(p)"> > </span>
                    </div>
                  </div>
                </pagination-template>
              </div>
              <div class="pagination-mobile">
                <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                  (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
