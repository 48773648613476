import { Component, OnInit } from '@angular/core';
import { usersService} from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  contactusForm: FormGroup;
  submitted = false;
  isemailsentsuccessfully = false;

  constructor(private usersservice: usersService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.contactusForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]

    });
  }


  // convenience getter for easy access to form fields
  get f() { return this.contactusForm.controls; }

  onSubmit() {

    this.submitted = true;

     // stop here if form is invalid
    if (this.contactusForm.invalid) {
        return;
    }

    this.usersservice.sendEmailToAdministration(this.f.email.value,this.f.firstname.value, this.f.lastname.value, this.f.phone.value,this.f.message.value)
        .pipe()
        .subscribe(
            data => {
              this.usersservice.sendMail(this.f.email.value,this.f.firstname.value, this.f.lastname.value, this.f.phone.value,this.f.message.value, "Feedback");
              this.isemailsentsuccessfully = true;
            },
            error => {

            });
  }

}
