<section style="margin-top: 40px; margin-bottom: 40px;">
  <div *ngIf="this.loading" style="position: relative; width: 100%; height: 100vh;">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
  <div *ngIf="!this.loading">
    <main style="width: 95%; max-width: 1240px; margin: 0 auto;">
      <div style="display: flex; flex-direction: column; gap: 40px;">
        <ng-container *ngIf="this.roleUser === 'Landlord'">
          <div class="service-reviews" style="justify-content: center;">
            <div *ngIf="this.arrServices.length > 0" style="padding: 20px; background-color: white; width: 77%;">
              <h4 class="fs-22 text-heading mb-4" style="text-align: center;">User Reviews</h4>
              <div>
                <div *ngFor="let review of this.arrServices.slice(0, this.slicedReviews); let i = index"
                  style="border-bottom: 1px solid #c0bcbc; padding-bottom: 15px;">
                  <h5 [ngClass]="{'diff-one': i > 0, 'username-style': true}">{{review.userName}}</h5>
                  <div class="stars-note">
                    <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                      <ng-container *ngIf="review.rating > ratingConditional.moreThan">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="review.rating > ratingConditional.inBetweenLess && review.rating <= ratingConditional.inBetweenMore">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>

                      </ng-container>
                      <ng-container *ngIf="review.rating <= ratingConditional.lessThan">
                        <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                      </ng-container>
                    </div>
                  </div>
                  <p class="review-date">Review date: {{review.create_date}}</p>
                  <p class="rating-desc">{{review.text === '' ? 'No description.' : review.text}}</p>
                </div>
              </div>
              <div *ngIf="this.arrServices.length > 3 && this.arrServices.length >= slicedReviews"
                style="display: flex; justify-content: center; margin-top: 40px;">
                <button (click)="this.loadMoreReviews('', '')" class="more-reviews">Load more
                  reviews</button>
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.roleUser === 'Vendor'">
          <div *ngFor="let service of this.arrServices" class="service-reviews reviews-responsive">
            <div class="service-card shadow">
              <img src="{{this.apiurl}}/{{service.imageOne}}" alt="">
              <div class="card-reviews-info">
                <h4>{{service.title}}</h4>
                <div class="review-area review-area-vendor">
                  <div class="stars-note to-vendor">
                    <ng-container *ngIf="lengthRating(service.totalrating)">
                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!lengthRating(service.totalrating)">
                      <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                        <ng-container *ngIf="service.rating > ratingConditional.moreThan">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="service.rating > ratingConditional.inBetweenLess && service.rating <= ratingConditional.inBetweenMore">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                          <fa-icon class="star-no-icon star-icon star-half" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="service.rating <= ratingConditional.lessThan">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <div class="services-review">
                    <p style="margin-bottom: 0 !important;">{{lengthRating(service.rating) ? '0' : service.rating}}/5
                    </p>
                    <span>({{lengthRating(service.totalrating) ? '0' : service.totalrating}})</span>
                  </div>
                </div>

              </div>
            </div>
            <div *ngIf="this.serviceReviews.length > 0" class="reviews-width">
              <h4 class="fs-22 text-heading mb-4" style="text-align: center;">Service Reviews</h4>
              <div>
                <div *ngFor="let review of service.ratings.slice(0, service.slice); let i = index"
                  style="border-bottom: 1px solid #c0bcbc; padding-bottom: 15px;">
                  <h5 [ngClass]="{'diff-one': i > 0, 'username-style': true}">{{review.userName}}</h5>
                  <div class="stars-note">
                    <div *ngFor="let ratingConditional of this.ratingArr" class="arrow arrow-vendor star">
                      <ng-container *ngIf="review.rating > ratingConditional.moreThan">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="review.rating > ratingConditional.inBetweenLess && review.rating <= ratingConditional.inBetweenMore">
                        <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="review.rating <= ratingConditional.lessThan">
                        <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                      </ng-container>
                    </div>
                  </div>
                  <p class="review-date">Review date: {{review.create_date}}</p>
                  <div>
                    <p class="rating-desc">{{review.text === '' ? 'No description.' : review.text}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="service.ratings.length > 3 && service.ratings.length > service.slice"
                style="display: flex; justify-content: center; margin-top: 40px;">
                <button (click)="this.loadMoreReviews(service.slice, service.id)" class="more-reviews">Load more
                  reviews</button>
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.roleUser === 'Tenants'">
          <div class="service-reviews" style="justify-content: center;">
            <div style="padding: 20px; background-color: white; width: 77%;">
              <h4 class="fs-22 text-heading mb-4" style="text-align: center;">User Reviews</h4>
              <ng-container *ngIf="this.arrServices.length > 0">
                <div>
                  <div *ngFor="let review of this.arrServices.slice(0, this.slicedReviews); let i = index"
                    style="border-bottom: 1px solid #c0bcbc; padding-bottom: 15px;">
                    <h5 [ngClass]="{'diff-one': i > 0, 'username-style': true}">{{review.userName}}</h5>
                    <div class="stars-note">
                      <div class="arrow arrow-vendor star">
                        <ng-container *ngIf="review.rating > 0.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating > 0 && review.rating <= 0.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating <= 0">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                      <div class="arrow arrow-vendor star">
                        <ng-container *ngIf="review.rating >= 2">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating >= 1.1 && review.rating <= 1.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating <= 1">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                      <div class="arrow arrow-vendor star">
                        <ng-container *ngIf="review.rating >= 3">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating >= 2.1 && review.rating <= 2.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating <= 2">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                      <div class="arrow arrow-vendor star">
                        <ng-container *ngIf="review.rating >= 4">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating >= 3.1 && review.rating <= 3.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating <= 3">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                      <div class="arrow arrow-vendor star">
                        <ng-container *ngIf="review.rating == 5">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating >= 4.1 && review.rating <= 4.9">
                          <fa-icon class="star-bg-icon star-icon" [icon]="faStarHalf"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="review.rating <= 4">
                          <fa-icon class="star-no-icon star-icon" [icon]="faStar"></fa-icon>
                        </ng-container>
                      </div>
                      <!-- </ng-container> -->
                    </div>
                    <p class="review-date">Review date: {{review.create_date}}</p>
                    <p class="rating-desc">{{review.text === '' ? 'No description.' : review.text}}</p>
                  </div>
                </div>
                <div *ngIf="this.arrServices.length > 3 && this.arrServices.length >= slicedReviews"
                  style="display: flex; justify-content: center; margin-top: 40px;">
                  <button (click)="this.loadMoreReviews('', '')" class="more-reviews">Load more
                    reviews</button>
                </div>
              </ng-container>
              <ng-container *ngIf="this.arrServices.length === 0">
                <p
                  style="font-size: 20px; text-align: center; font-family: Arial, Helvetica, sans-serif; color: black;">
                  Has no comments!</p>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div *ngIf="this.empty" style="font-size: 18px; width: 100%; height: 100vh; text-align: center;">
          This user has no reviews
        </div>
      </div>
    </main>
  </div>
</section>
