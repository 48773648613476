import { Component, OnInit,Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cropprofileimage',
  templateUrl: './cropprofileimage.component.html',
  styleUrls: ['./cropprofileimage.component.css']
})
export class CropprofileimageComponent implements OnInit {

  @Input() fromParent;

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.imageChangedEvent = this.fromParent.prop1;

  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      /* show cropper */
  }
  cropperReady() {
      /* cropper ready */
  }
  loadImageFailed() {
      /* show message */
  }



  closeModal(sendData) {
    this.activeModal.close(sendData);
    }

    saveCropImage(){
      this.activeModal.close(this.croppedImage);
    }

}
