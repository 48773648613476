import { Component, HostListener, OnInit } from '@angular/core';
import { propertiesService, usersService } from '../services';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css'],
})
export class VendorsComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  total: number = 0;

  faStar = faStar;
  faStarHalf = faStarHalf;

  public innerWidth: number = window.innerWidth;

  public listVendors: any = [];
  public previewListVendors: any = [];

  public listRatingss: any;

  public regionDrop: boolean = false;
  public regionText: any = 'Country';
  public regions = [
    {
      name: 'Any',
      value: 'Region',
    },
    {
      name: 'Austin',
      value: 'Austin',
    },
    {
      name: 'Boston',
      value: 'Boston',
    },
    {
      name: 'Chicago',
      value: 'Chicago',
    },
    {
      name: 'Denver',
      value: 'Denver',
    },
    {
      name: 'Los Angeles',
      value: 'Los Angeles',
    },
    {
      name: 'New york',
      value: 'New york',
    },
    {
      name: 'San Francisco',
      value: 'San Francisco',
    },
  ];

  public sortByMenu: boolean = false;
  public sortByText: any = 'Alphabet';

  public companyDrop: boolean = false;
  public companyText: any = 'Company';
  public companies = [
    {
      name: 'Any',
      value: 'Company',
    },
    {
      name: 'Google',
      value: 'Google',
    },
    {
      name: 'Facebook',
      value: 'Facebook',
    },
  ];

  public languageDrop: boolean = false;
  public languageText: any = 'Language';
  public languages = [
    {
      name: 'Any',
      value: 'Language',
    },
    {
      name: 'English',
      value: 'English',
    },
    {
      name: 'France',
      value: 'France',
    },
  ];
  constructor(
    private propertiesservice: propertiesService,
    private usersService: usersService
  ) {}

  async ngOnInit() {
    await this.getVendors();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getVendors();
  }

  toNext(p) {
    if (!p.isLastPage()) {
      p.next();
    }
  }
  private async getVendors() {
    this.previewListVendors = [];
    this.listVendors = [];
    await this.usersService
      .getVendors()
      .toPromise()
      .then((response) => {
        this.total = response.length;
        response.forEach((el) => {
          this.getUserRating(el.Id, el);
        });
      });
  }
  openSortBy() {
    this.sortByMenu = !this.sortByMenu;
  }

  selectSortBy(value) {
    this.sortByText = value;
    this.sortByMenu = !this.sortByMenu;
  }

  handleShowDropMenu(type) {
    if (type === 'company') {
      this.companyDrop = !this.companyDrop;
    } else if (type === 'language') {
      this.languageDrop = !this.languageDrop;
    } else {
      this.regionDrop = !this.regionDrop;
    }
  }

  selectValue(value, type) {
    if (type === 'company') {
      this.companyText = value;
      this.companyDrop = !this.companyDrop;
    } else if (type === 'language') {
      this.languageText = value;
      this.languageDrop = !this.languageDrop;
    } else {
      this.regionText = value;
      this.regionDrop = !this.regionDrop;
    }
  }

  async getUserRating(id, element) {
    var totalRating = 0;
    await this.propertiesservice
      .getUserRating(id)
      .pipe()
      .subscribe((data) => {
        element.CellPhoneNumber = element.CellPhoneNumber.toString();
        data.forEach((element) => {
          totalRating = totalRating + element.rating;
        });
        let calc = totalRating / data.length;
        let checkCalc = calc.toString().includes('.')
          ? Number(calc).toFixed(1)
          : calc;
        this.previewListVendors.push(
          Object.assign(element, {
            ratingLength: data.length,
            ratingCalc: isNaN(Number(checkCalc)) ? 0 : Number(checkCalc),
          })
        );
        if (this.total === this.previewListVendors.length) {
          this.listVendors = this.previewListVendors;
        }
      });
  }
}
