<section>
  <div class="add__gap d-flex flex-wrap flex-xl-nowrap">
    <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
      <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
        <div style="height: 279px">
          <div class="shadow-xs-1">
            <div class="d-flex px-3 px-xl-6 w-100"></div>
            <app-leftmenumypainel></app-leftmenumypainel>
          </div>
        </div>
      </nav>
    </div>
    <div class="page-content">
      <div *ngIf="this.openOrCloseModal === true" class="modal-background">
        <div class="modal-buy shadow">
          <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
            Buy RCT
          </h3>
          <button (click)="closeModal()" class="close-icon">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <div class="modal-overflow" style="overflow-y: auto; height: 475px; padding-right: 20px">
            <div class="add-cash-area">
              <div class="my-cash">
                <span>Total</span>
                <fa-icon style="margin: 0 5px;" class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                <span>{{ this.value }}</span>
              </div>
              <div class="input-control">
                <input type="range" (input)="change($event)" min="0" max="2000" value="0" name="buy_funds" step="1"
                  id="funds" />
                <p class="position-paragraph" style="margin-bottom: 0">
                  You will get
                  <span class="span-cash" id="demo">+{{ this.value }} RCT
                    <fa-icon style="font-size: 14px;" class="coins-icon" [icon]="faCoins"></fa-icon>
                  </span>
                </p>
              </div>
            </div>
            <div class="warning">
              <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
              <p style="width: 100%; text-align: justify;">
                The funds will be credited on you ROUNDCOMB Wallet within 24H
                from the verified payment.
              </p>
            </div>
            <form *ngIf="this.step === 'add-card'" style="margin-bottom: 0" type="submit"
              class="payment-method-section">
              <div (click)="paymentModal(this.value)" id="paypal-box" class="paypal-box box-to-position shadow">
                <div class="dolar-symbol">
                  <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                </div>
                <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="paypal"
                  class="heading-3">
                  PayPal
                </p>
              </div>
              <!-- (click)="setCurrent('credit', $event)" -->
              <div id="credit-box" class="card-box box-to-position shadow blocked">
                <div class="dolar-symbol">
                  <fa-icon class="dollar-icon" [icon]="faDollarSign"></fa-icon>
                </div>
                <p style="margin-bottom: 0; text-align: left; margin: 0; font-size: 18px;" id="credit"
                  class="heading-3">
                  Credit Card
                </p>
                <div class="payment-form" *ngIf="this.typeCurrent.length > 0">
                  <div class="owner-control">
                    <label style="margin-bottom: 0; font-weight: bold; color: black" for="owner-name">Owner Name</label>
                    <input placeholder="John Wayme" class="modal-input" type="text" name="owner-name" id="owner-name" />
                  </div>
                  <div class="card-info">
                    <input class="modal-input card-input" type="text" name="card" id="card"
                      placeholder="xxxx-xxxx-xxxx-1234" />
                    <div class="owner-control">
                      <label style="
                          margin-bottom: 0;
                          font-weight: bold;
                          color: black;
                        " for="cvv">CVV</label>
                      <input class="modal-input" type="number" name="cvv" id="cvv" placeholder="xxx" />
                    </div>
                  </div>
                  <div class="date-company">
                    <input class="modal-input" type="text" name="date" id="date" placeholder="2021, January 21" />
                    <div class="bank-companies">
                      <div class="visa to-style">
                        <img src="../../assets/images/visa-logo.png" alt="Visa logo" />
                      </div>
                      <div class="mastercard to-style">
                        <img src="../../assets/images/mastercard-logo.png" alt="mastercard logo" />
                      </div>
                      <div style="padding: 10px 5px" class="express to-style">
                        <img style="width: 80px" src="../../assets/images/american-express-logo.png"
                          alt="American express logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  margin-right: 34px;
                ">
                <button (click)="changeStep()" *ngIf="this.typeCurrent.length > 0" class="pay-button">
                  Pay now
                </button>
              </div>
            </form>
            <form *ngIf="this.step === 'billing'" style="margin-bottom: 20px" type="submit"
              class="payment-method-section">
              <h3 class="heading-3">Billing Details</h3>
              <div class="input-fields">
                <div class="first-column">
                  <div class="owner-control">
                    <label class="label" for="first-name">First Name</label>
                    <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="text"
                      name="first-name" id="first-name" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="last-name">Last Name</label>
                    <input style="background-color: white;" placeholder="James" class="modal-input" type="text"
                      name="last-name" id="last-name" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="email">Email</label>
                    <input style="background-color: white;" placeholder="saraj@gmail.com" class="modal-input"
                      type="email" name="email" id="email" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="phone">Phone</label>
                    <input style="background-color: white;" placeholder="+1 1234 1234" class="modal-input" type="text"
                      name="phone" id="phone" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="id-number">ID Number</label>
                    <input style="background-color: white;" placeholder="Sarah" class="modal-input" type="number"
                      name="id-number" id="id-number" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="vat">VAT</label>
                    <input style="background-color: white;" placeholder="1234567" class="modal-input" type="number"
                      name="vat" id="vat" />
                  </div>
                </div>
                <div style="margin-top: 20px;" class="owner-control">
                  <label class="label" for="address">Address line 1</label>
                  <input style="background-color: white;" placeholder="123 Fakestreet" class="modal-input" type="text"
                    name="address" id="address" />
                </div>
                <div style="margin-top: 20px;" class="owner-control">
                  <label class="label" for="address-two">Address line 2</label>
                  <input style="background-color: white;" placeholder="" class="modal-input" type="text"
                    name="address-two" id="address-two" />
                </div>
                <div style="gap: 20px 30px;" class="first-column">
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="country">Country</label>
                    <input style="background-color: white;" placeholder="USA" class="modal-input" type="text"
                      name="country" id="country" />
                  </div>
                  <div style="margin-top: 20px;" class="owner-control">
                    <label class="label" for="address-two">State</label>
                    <input style="background-color: white;" placeholder="New Mexico" class="modal-input" type="text"
                      name="state" id="state" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="city">City</label>
                    <input style="background-color: white;" placeholder="Roswell" class="modal-input" type="text"
                      name="city" id="city" />
                  </div>
                  <div class="owner-control">
                    <label class="label" for="zip-code">ZIP CODE</label>
                    <input style="background-color: white;" placeholder="512345" class="modal-input" type="text"
                      name="zip-code" id="zip-code" />
                  </div>
                </div>
                <div class="terms-section">
                  <div (click)="checkTerms()" class="checkbox">
                    <div class="checked" *ngIf="this.checked === true"></div>
                  </div>
                  <p class="term">I have read and agree to the <a href="#">terms of use</a></p>
                </div>
                <div class="end-button">
                  <button [disabled]="this.checked === true ? false : true" class="submit-button">SUBMIT & PAY</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div *ngIf="this.openBuyModal === true" class="modal-background">
        <div class="modal-buy shadow">
          <a style="text-decoration: none;" class="user-cash-dashboard cash-boost">
            <span>{{ this.cash.toFixed(2) }}</span>
            <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
          </a>
          <h3 style="margin: 0; margin-bottom: 30px; font-size: 23px;" class="heading-3">
            Renew Boosts
          </h3>
          <button (click)="closeModal()" class="close-icon">
            <img src="../../assets/images/close-icon.png" alt="Close icon" />
          </button>
          <form (submit)="submitChanges()" style="margin-bottom: 0" type="submit">
            <div [ngClass]="{
              'radius': this.total > this.cash,
              'radius-all': this.total < this.cash
            }" class="listing-type-boosts">
              <h4 class="buy-title">Renew Listing Boosts</h4>
              <ng-container *ngIf="this.stepBoost === 'boost'">
                <div (click)="checkBoosts()" *ngIf="this.filteredItem[0].Booster === true"
                  class="modal-select-boosters blue-box">
                  <div class="terms-section-renew">
                    <div class="checkbox-renew">
                      <div class="checked-renew" *ngIf="this.checkedBoost === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="arrow-boost to-arrow" [icon]="faCircleArrowUp"></fa-icon>
                  </div>
                  <p>
                    There is a special spot at top of each listing page that will randomly cycle
                    through the pool of "top" listings. This will highly improve your listing views.
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="this.stepBoost === 'high'">
                <div (click)="checkHighlight()" *ngIf="this.filteredItem[0].Highlight === true"
                  class="modal-select-boosters purple-box">
                  <div class="terms-section-renew">
                    <div class="checkbox-renew">
                      <div class="checked-renew" *ngIf="this.checkedHigh === true"></div>
                    </div>
                  </div>
                  <div class="arrow arrow-boost-modal">
                    <fa-icon class="star-bg-icon arrow-boost  star-icon" [icon]="faStar"></fa-icon>
                  </div>
                  <p>
                    Your property will appear with a visual highlight to make it stand out to
                    potential interested clients.
                  </p>
                </div>
              </ng-container>
              <div class="mobile-direction"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 12px;">
                <div class="same-styles">
                  <p style="margin-bottom: 0;">Duration month</p>
                  <input type="text" name="month" maxlength="2" [ngClass]="{'less-then-ten': this.valueMonth <= 10}"
                    [value]="this.valueMonth" (input)="changeBoostMonthValue($event)" id="month">
                </div>
                <div class="number-boost">
                  <p style="margin-bottom: 0;">Total</p>
                  <span>{{this.total.toFixed(2)}} RCT</span>
                </div>
              </div>
            </div>
            <div *ngIf="this.total > this.cash" class="warn-orange">
              <span>insufficient RCT on your Wallet</span>
              <a routerLink="/wallet" class="user-cash buy-more-button">Buy more RCT
                <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
              </a>
            </div>
            <div class="warning warning-slots">
              <fa-icon class="exclamation-icon" [icon]="faExclamation"></fa-icon>
              <p style="width: 100%; text-align: justify;">
                By completing the purchase, the funds will be taken from your wallet.
              </p>
            </div>
            <div [ngClass]="{
              'top-short-distance': this.total > this.cash,
              'top-distance': this.total < this.cash
            }">
              <p style="font-weight: normal; font-size: 18px;margin: 0;" class="heading-3">Your new boosts will be
                available until:</p>
              <p class="heading-3 available-date">{{this.dateavailable}}
              </p>
            </div>
            <div style="
                  display: flex;
                  justify-content: center;
                  margin-right: 34px;
                ">
              <button [disabled]="this.total > this.cash ? true : this.valueMonthInNumber === 0 ? true : false"
                class="submit-button">SUBMIT & PAY</button>
            </div>
          </form>
        </div>
      </div>
      <main id="content">
        <h2 class="component__title">Wallet</h2>
        <div class="columns">
          <div class="column-one">
            <div class="boxs">
              <div class="balance box-wallet">
                <h4>Your balance</h4>
                <div class="user-cash">
                  <span>{{ this.cash.toFixed(2) }}</span>
                  <fa-icon class="coins-icon" [icon]="faCoins"></fa-icon>
                </div>
              </div>
              <div (click)="openModal('add-card')" class="add-money box-wallet">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50.000000pt" height="50.000000pt"
                  viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M230 340 l0 -70 -70 0 c-63 0 -70 -2 -70 -20 0 -18 7 -20 70 -20 l70
                    0 0 -70 c0 -63 2 -70 20 -70 18 0 20 7 20 70 l0 70 70 0 c63 0 70 2 70 20 0
                    18 -7 20 -70 20 l-70 0 0 70 c0 63 -2 70 -20 70 -18 0 -20 -7 -20 -70z" />
                  </g>
                </svg>
                <span>Buy RCT</span>
              </div>
            </div>
            <div *ngIf="this.authenticationService.customizedroles === 'landlord'">
              <div>
                <h3 class="heading-3">Expired Features</h3>
                <div class="expired-box shadow">
                  <fa-icon class="house-icon" [icon]="faHouseChimney"></fa-icon>
                  <div class="expired-quants">
                    <p>YOU HAVE</p>
                    <p class="equal-zero" *ngIf="this.valueExpired?.length === 0"><span
                        style="cursor: pointer;">{{ this.valueExpired.length }}
                        EXPIRED SLOTS</span></p>
                    <a *ngIf="this.valueExpired?.length !== 0" href="/dashboard"><span style="cursor: pointer;"
                        class="shadow">{{ this.valueExpired.length }}
                        EXPIRED SLOTS</span></a>
                  </div>
                </div>
              </div>
              <div class="boosted-box shadow">
                <h4 class="heading-4">Expired Boosts</h4>
                <div class="to-overflow overflow">
                  <ng-container *ngIf="this.propretiesBoostedArr?.length > 0">
                    <div class="to-display" *ngFor="let boosted of this.propretiesBoostedArr">
                      <div class="property-info-wallet">
                        <p>{{ boosted.Name }}</p>
                        <div style="display: flex;">
                          <button *ngIf="boosted.monthBoost <= 0" (click)="this.openModalRenew(boosted.Id, 'boost')"
                            class="renew-button">RENEW TO THE
                            TOP</button>
                          <button *ngIf="boosted.monthBoost > 0" class="renew-button disabled-renew">RENEW TO THE
                            TOP</button>
                          <button *ngIf="boosted.monthHigh <= 0" (click)="this.openModalRenew(boosted.Id, 'high')"
                            class="renew-button">RENEW FEATURED</button>
                          <button *ngIf="boosted.monthHigh > 0" class="renew-button disabled-renew">RENEW
                            FEATURED</button>
                        </div>
                      </div>
                      <div class="date-renew" style="display: flex; flex-direction: column;">
                        <span *ngIf="boosted.monthBoost <= 0" [ngClass]="{'expired-booster': boosted.monthBoost <= 0}">
                          <strong>Top:</strong> Expired
                        </span>
                        <span *ngIf="boosted.monthBoost > 0">
                          <strong>Top:</strong> {{boosted.Top}} {{boosted.Top > 1 ? 'days' : 'day'}}
                        </span>
                        <span *ngIf="boosted.monthHigh <= 0" [ngClass]="{'expired-booster': boosted.monthHigh <= 0}">
                          <strong>Featured:</strong> Expired
                        </span>
                        <span *ngIf="boosted.monthHigh > 0">
                          <strong>Featured:</strong> {{boosted.Featured}} {{boosted.Featured > 1 ? 'days' : 'day'}}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="this.propretiesBoostedArr?.length === 0">
                    <p style="text-align: center;" class="empty-transactions">No Expired Booster</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column-two shadow">
            <h4 class="transaction-title">Transactions List</h4>
            <div class="overflow"
              [ngClass]="{'to-transaction-overflow': this.transactionsList.length >= 12 && this.showMore, 'auto-transaction': this.showMore && this.transactionsList.length < 12}">
              <ul class="list">
                <li>Transaction</li>
                <li>Date</li>
                <li>Ammount</li>
              </ul>
              <!-- 525 -->
              <div *ngIf="this.transactionsList.length > 0" class="separate-transactions">
                <div *ngFor="let transaction of this.transactionsList.slice(0, !this.showMore ? 5 : this.transactionsList.length)">
                  <div class="transaction-display">
                    <div class="to-mobile-name">
                      <p class="transaction-name">
                        {{transaction.name.substring(0,6) + "..."}}</p>
                    </div>
                    <div class="to-desktop-name">
                      <p class="transaction-name">
                        {{transaction.name}}</p>
                    </div>
                    <div>
                      <span class="trans-date-time"> {{ transaction.date }} </span>
                      <span class="trans-date-time"> {{ transaction.time }} </span>
                    </div>
                    <p style="width: 72px" class="ammount blue-wallet" *ngIf="transaction.ammount > 0">
                      +{{ transaction.ammount }} RCT
                    </p>
                    <p style="width: 72px" class="ammount orange-wallet" *ngIf="transaction.ammount < 0">
                      {{ transaction.ammount }} RCT
                    </p>
                  </div>
                </div>
                <div *ngIf="this.transactionsList.length >= 12 && !this.showMore" class="showMore-button">
                  <button (click)="show()">
                    Show all
                  </button>
                </div>
                <div *ngIf="this.showMore" class="showMore-button">
                  <button (click)="show()">
                    See less
                  </button>
                </div>
              </div>
              <div *ngIf="this.transactionsList.length === 0">
                <p style="text-align: center;" class="empty-transactions">No Transactions</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>
