export const plans = [
    {
      id: "P-9J579603RY563470FMONUBZQ",
      name: "basic",
      price: 9,
    },
    {
      id: "P-46J45670AA584240WMONUPEQ",
      name: "advanced",
      price: 99,
    },
  ];

  //P-9J579603RY563470FMONUBZQ