<ng-container *ngIf="!isemailsentsuccessfully">
  <section style="margin-top: 80px;" class="py-8" data-animated-id="2">
    <div class="container" style="display: flex;justify-content: center;">

      <div style="padding: 30px;" class="card border-0 mt-n13 z-index-3 pb-8 pt-10">
        <div class="card-body p-0">
          <h6 class="text-heading mb-2 fs-22 fs-md-20 text-center lh-16 fadeInDown animated" data-animate="fadeInDown">
            We're always eager to hear from
            you!</h6>
          <p class="text-center mxw-670 mb-8">
            We would like to know how we are doing, and what we need to do to ensure better user experience. We are
            always listening, and we welcome your feedback.
          </p>
          <form class="form" [formGroup]="contactusForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <input type="text" placeholder="First Name" class="form-control form-control-lg border-0"
                    formControlName="firstname" name="firstname" id="firstname" name="firstname"
                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                  <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                    <div *ngIf="f.firstname.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" placeholder="Last Name" name="lastname" formControlName="lastname"
                    class="form-control form-control-lg border-0" id="lastname" name="lastname"
                    [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                  <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                    <div *ngIf="f.lastname.errors.required">Last Name is required</div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input placeholder="Your Email" class="form-control form-control-lg border-0" type="email"
                    formControlName="email" name="email" id="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors}"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">Email is invalid</div>
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" placeholder="Your Phone" name="phone" id="phone" formControlName="phone"
                    class="form-control form-control-lg border-0"
                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">Phone number is required</div>

                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-6">
              <textarea class="form-control border-0" placeholder="Message" name="message" id="message"
                formControlName="message" rows="5"
                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                <div *ngIf="f.message.errors.required">Message is required</div>

              </div>

            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary btn-lg rounded">Contact Us</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="isemailsentsuccessfully">
  <section class="py-13" data-animated-id="1">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 mx-auto">
          <div class="card border-0 login-register">
            <div class="card-body p-6">
              <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Thanks for your contact</h2>

              <div>
                <p class="mb-4">We've received your message. We will get back to you as soon as possbile.</p>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
