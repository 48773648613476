<section>
    <div class="wrapper dashboard-wrapper">
        <div class="d-flex flex-wrap flex-xl-nowrap">
          <div id = "showDesktopDashboardNavBar" class="db-sidebar bg-white">
            <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
              <div style="height: 279px;">
                <div class="shadow-xs-1 py-3">
                  <div class="d-flex px-3 px-xl-6 w-100">
                  </div>
                  <app-leftmenumypainel></app-leftmenumypainel>
                </div>
              </div>
            </nav>
          </div>
            <div class="page-content">

                <main id="content" class="bg-gray-01">

                    <div class="container event-margin" data-select2-id="7">
                        <div class="row" data-select2-id="6">
                          <div class="col-11" style="margin-top: 15px;">


                            <div class="section-title d-flex align-items-center">
                              <h2>New Event</h2>
                            </div>

                            <form class="form" [formGroup]="newEventForm" (ngSubmit)="onSubmit()">


                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label>Event type</label>
                                  <select class="form-control "
                                  name="eventtype" title="Event Type"
                                  data-style="btn-lg py-2 h-52" id="eventtype"
                                  (change)="selectEventTypeOption($event)">
                                  <option *ngFor="let eventtype of lstEventslist"
                                      [ngValue]="eventtype.id" id="eventtype"
                                      name="eventtype" [selected]=eventtype.isSelected>
                                      {{
                                        eventtype.desctext }}</option>
                              </select>
                                </div>

                                 <div class="form-group col-md-6">

                                </div>

                                    <div class="form-group col-md-6">
                                      <label>Property</label>
                                      <select class="form-control "
                                      name="property" title="Property"
                                      data-style="btn-lg py-2 h-52" id="property"
                                      (change)="selectPropertyOption($event)">
                                      <option *ngFor="let property of lstMyPropertieslist"
                                          [ngValue]="property.id" id="property"
                                          name="property" [selected]=property.isSelected>
                                          {{
                                            property.name }}</option>
                                  </select>
                                    </div>

                                     <div class="form-group col-md-6">

                                    </div>

                                <ng-container *ngIf="showfirstmessagetextbox">

                                <div class="form-group col-md-6">
                                    <label for="firstmessage" class="text-heading">Please send the first message
                                        </label>
                                    <textarea rows="3" formControlName="firstmessage"  class="form-control"
                                        id="firstmessage" name="firstmessage" [ngClass]="{ 'is-invalid': submitted && f.firstmessage.errors }"
                                        ></textarea>
                                        <div *ngIf="submitted && f.firstmessage.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstmessage.errors.required">First Message is required</div>

                                        </div>
                                </div>

                            </ng-container>
                                  <hr class="my-5">
                                  <div class="col-md-12">

                                   <button class="btn btn-primary" type="submit">Create new event</button>
                                </div>
                              </div>
                            </form>
                             </div>
                        </div>
                      </div>
                </main>
            </div>
        </div>
    </div>
</section>
