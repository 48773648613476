<!--<div class="imagebox"></div>
<img src="/assets/images/bigstock-Real-estate-agent-shaking-hand-102025619.jpg" class="responsive">-->


<div class="container-fluid no-padding display-flex" style="padding-right: 0px;padding-left: 0px;">

  <img src="/assets/images/bigstock-Real-estate-agent-shaking-hand-102025619.jpg" class="img-responsive"
    style="background-color: rgb(0 0 0 / 30%);z-index: -1;" />

  <div class="hero-image-overlay">

    <h1 style="font-size:4vw;">ROUNDCOMB, INC.</h1>

    <p style="font-size:2vw;">Building a community to facilitate the management of rental property relationships.</p>

  </div>
  <!-- end hero-image-overlay -->

</div>

<!-- end container-fluid -->

<section class="py-8" data-animated-id="2">
  <div class="container">
    <div class="row ml-xl-0 mr-xl-n6">
      <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
        <div class="primary-sidebar-inner" style="position: static; left: auto; max-width: 370px; width: 100%;">

          <div class="px-6 pb-6 media-list">

            <ul class="list-group list-group-no-border" style="padding: 10px 15px; background-color: white;">
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary">
                  <span class="lh-29"><strong>Who We Are</strong></span>
                </a>
              </li>
              <li class="list-group-item p-0">
                <a routerLink="/about" class="d-flex text-body hover-primary">
                  <span class="lh-29">About Us</span>
                </a>
              </li>
              <li class="list-group-item p-0">
                <a class="d-flex text-body hover-primary" routerLink="/ourstory">
                  <span class="lh-29">Our Story</span>

                </a>
              </li>
              <li class="list-group-item p-0">
                <a routerLink="/ourmission" class="d-flex text-body hover-primary">
                  <span class="lh-29">Our Mission</span>

                </a>
              </li>
            </ul>
          </div>


        </div>
      </div>
      <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
        <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

          <h1 class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated"
            data-animate="fadeInDown">Who We Are</h1>

          <p class="desc-static mb-4 lh-214 pt-5">Roundcomb is an online marketplace where landlords, property managers, agents,
            tenants, and like-kind investors connect and collaborate in information sharing to facilitate rental due
            diligence. Together, we are changing the face of property listing and rental business by aiding user
            integrity and accountability, resulting in improved landlord-tenant relationships. Sign up and be a part of
            this movement.</p>


          <p class="desc-static mb-4 lh-214 pt-2">Are you tired of living in the same property and neighborhood? Looking for a great
            property in a great location? Do you feel like you're not valued enough for being a good tenant? Looking to
            brand yourself and begin to monetize your excellent rental history? Join Roundcomb, and discover what's in
            it for you. Our system allows you to customize your search and set target move-in date.</p>


          <p class="desc-static mb-4 lh-214 pt-2">You have worked so hard to improve your property. Ready to give it the best
            exposure that it deserves? Looking for the opportunity to attract and capture the right tenant, and begin to
            monetize your investment? We are here to help.</p>


          <p class="desc-static mb-4 lh-214 pt-2">Need to assign and/or update tenant rating, view and track rental history and
            experience? We can help.</p>

        </div>
      </div>

    </div>
  </div>
</section>
