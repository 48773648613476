<div class="modal-header">
    <h3 class="modal-title">Search Location</h3>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">


    <div class="form-group">
        <input #search type="text" class="form-control pac-container" style="position: fixed" (keydown.enter)="$event.preventDefault()"
            placeholder="Search Nearest Location" autocorrect="on" autocapitalize="off" spellcheck="off" type="text" (blur)="findAddress()" >
    </div>

    <div class="map">
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [disableDefaultUI]="false" [scrollwheel]="null"  >
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
    </agm-map>
</div>

    <div class="pt-5"></div>
    <h6><strong>Location:</strong>  {{address}}</h6>
    <!--<div>Latitude: {{latitude}}</div>
    <div>Longitude: {{longitude}}</div>-->


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveLocation()">Save Location</button>
</div>
