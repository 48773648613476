import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChatwebsocketService, propertiesService } from '../../../services';
import { User } from '../../../models';
import { NavigationEnd, Router } from '@angular/router';
import { WebSocketSubject } from 'rxjs/webSocket';
import { ToastrService } from 'ngx-toastr';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { DataService } from 'src/app/services/profile.service';
// structure of the message client <-> server
export class Message {
  constructor(
    public guidChatRoom: string,
    public JSONformatcontent: string,
    public isBroadcast = false,
    public senderID: string,
    public senderName: string,
    public event: string
  ) {}
}

@Component({
  selector: 'app-headerlogged',
  templateUrl: './headerlogged.component.html',
  styleUrls: ['./headerlogged.component.css'],
})
export class HeaderloggedComponent implements OnInit {
  public changedProfile = false;
  public profileJson: any;
  currentUser: User;
  public innerWidth: number = window.innerWidth;
  private socket$: WebSocketSubject<Message>;
  public totalnewmessages: number = 0;
  private userId: string;
  public userIdAuth: string;
  public cash: number = 0;
  public userIdProfile: string = '';
  public url: string = '';
  public Isvendor: boolean;
  public Istenant: boolean;
  public ishamburgeropen: boolean = false;
  public loading: boolean = true;
  public toggleModal: boolean = false;
  public notifications = [];
  public notLength = 0;
  public profileimageurl: string = '';
  public userName: string = '';
  public apiUrl: string = '';
  public userjsonprofile: any = {};
  public AuthToken: string = '';
  public idHtml: string = '';

  faCoins = faCoins;
  emojilist = [
    { emoji: '😀', decimalvalue: '&#128512;' },
    { emoji: '😁', decimalvalue: '&#128513;' },
    { emoji: '😂', decimalvalue: '&#128514;' },
    { emoji: '😅', decimalvalue: '&#128517;' },
    { emoji: '😇', decimalvalue: '&#128519;' },
    { emoji: '😉', decimalvalue: '&#128521;' },
    { emoji: '😊', decimalvalue: '&#128522;' },
    { emoji: '😋', decimalvalue: '&#128523;' },
    { emoji: '😍', decimalvalue: '&#128525;' },
    { emoji: '😏', decimalvalue: '&#128527;' },
    { emoji: '😜', decimalvalue: '&#128539;' },
    { emoji: '😡', decimalvalue: '&#128545;' },
    { emoji: '😭', decimalvalue: '&#128557;' },
    { emoji: '😴', decimalvalue: '&#128564;' },
    { emoji: '👍', decimalvalue: '&#128077;' },
    { emoji: '👎', decimalvalue: '&#128078;' },
    { emoji: '👊', decimalvalue: '&#128074;' },
    { emoji: '👌', decimalvalue: '&#128076;' },
    { emoji: '💪', decimalvalue: '&#128170;' },
    { emoji: '🙏', decimalvalue: '&#128591;' },
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
    public authenticationService: AuthenticationService,
    private chatwebsocket: ChatwebsocketService,
    private propertiesservice: propertiesService,
    private toastr: ToastrService,
    private dataService: DataService
  ) {
    this.dataService.dataAtt.subscribe((dados) => {
      this.changedProfile = dados;
      if (this.changedProfile === true) {
        this.getMyProfile();
        this.changedProfile = false;
      }
    });
    this.loadScripts();

    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.socket$ = this.chatwebsocket.getSocket();

    this.toggleModal = false;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit(): void {
    const userinfo = localStorage.getItem('roundcombuserdetail');

    let UserjsonObject = JSON.parse(userinfo);
    this.userName = UserjsonObject?.FirstName;
    this.userId = UserjsonObject?.Id;
    this.getMyProfile();
  }

  toggleNotificationsModal() {
    this.toggleModal = !this.toggleModal;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  private getMyProfile() {
    var AuthToken = JSON.parse(
      localStorage.getItem('roundcombuser') || '{}'
    ).value;

    const headers = {
      'Content-Type': 'application/json',
      AuthToken: AuthToken,
    };

    return this.http
      .get<any>(`${environment.apiUrl}/membership.api/api/user/myprofile`, {
        headers,
      })
      .subscribe({
        next: (data) => {
          data.userIdProfile = '[&userIdProfile]';
          this.userjsonprofile = JSON.stringify(data);

          var photoprofile = JSON.parse(this.userjsonprofile).ProfilePhotoUrl;

          if (
            photoprofile == '' ||
            photoprofile == 'null' ||
            photoprofile == null
          )
            this.profileimageurl = 'assets/images/User-Default.jpg';
          else if (photoprofile.indexOf(`${environment.apiUrl}`) != -1)
            this.profileimageurl = photoprofile;
          else
            this.profileimageurl = `${environment.apiUrl}`.concat(photoprofile);
          if (this.profileimageurl.includes('com//upload')) {
            this.profileimageurl = this.profileimageurl
              .toString()
              .split('com//')
              .join('com/');
          } else if (this.profileimageurl.includes('com///upload')) {
            this.profileimageurl = this.profileimageurl
              .toString()
              .split('com///')
              .join('com/');
          }
          this.apiUrl = environment.apiUrl;
          this.getUsermypersonalinfo(this.userjsonprofile);
        },
        error: (error) => {},
      });
  }
  private getUsermypersonalinfo(data) {
    var AuthToken = JSON.parse(
      localStorage.getItem('roundcombuser') || '{}'
    ).value;

    const headers = {
      'Content-Type': 'application/json',
      AuthToken: AuthToken,
    };

    return this.http
      .get<any>(
        `${environment.apiUrl}/membership.api/api/user/mypersonalinfo`,
        { headers }
      )
      .subscribe({
        next: (data) => {
          //change
          if (JSON.stringify(data).includes('"PhoneNumber":null')) {
            data = JSON.stringify(data).replace(
              '"PhoneNumber":null',
              '"PhoneNumber":"' +
                JSON.parse(JSON.stringify(data)).CellPhoneNumber +
                '"'
            );
          }
          data = JSON.stringify(data).replace(
            '[&userIdProfile]',
            JSON.parse(JSON.stringify(data)).Id
          );
          data = JSON.parse(data);
          this.profileJson = data;
          this.getNotifications(data.Id.toString());
        },
        error: (error) => {},
      });
  }
  convertTimeSlot(
    date,
    time,
    month,
    read,
    guid,
    photo,
    message,
    day,
    monthDate,
    year,
    isnotification,
    icon,
    color,
    amOrPm,
    idmessage
  ) {
    var datetime = moment(date + ' ' + time);
    datetime.add(
      month < 10 ? Number(month.toString().replace('0', '')) : month,
      'months'
    );
    var toDay = moment();
    var startDate = moment(datetime);
    var duration = moment.duration(startDate.diff(toDay));
    var minutes = duration.asMinutes() % 60;
    this.notifications.push({
      date: moment(
        `${monthDate} ${day} ${year}, ${time} ${
          amOrPm === undefined ? '' : amOrPm
        }`
      )
        .subtract()
        .fromNow(),
      day: startDate.diff(toDay, 'days'),
      hours: startDate.diff(toDay, 'hours') % 24,
      minutes: Number(minutes.toFixed(0)),
      wasRead: read,
      guid: guid,
      userPhoto: photo,
      message: message,
      isnotification: isnotification,
      icon: icon,
      color: color,
      id: idmessage,
    });
  }
  updateRead(guid, id) {
    this.propertiesservice
      .updateRead(Number(id))
      .toPromise()
      .then((response) => {});
    // this.goToEvent('chat', 'chatroomguid', guid);
    this.ngOnInit();
  }

  read(el) {
    let data = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'data'
    );
    let message = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'chatmessage'
    );
    let photo = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'userimageurl'
    );
    let icon = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'notificationicon'
    );
    let color = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'myrgbcolor'
    );
    let isnotification = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'isnotification'
    );
    var isimage = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'isimage'
    );
    var istodownload = this.readChatMessageParameter(
      JSON.stringify(el.Message),
      'istodownload'
    );

    return {
      data,
      message,
      photo,
      icon,
      color,
      isnotification,
      isimage,
      istodownload,
    };
  }

  notificationFiltering(response) {
    let idfilter = response.filter((el) => {
      return !JSON.stringify(el.Message).includes(this.userId?.toString());
    });
    let checkNameAndIsNot = idfilter.filter((el) => {
      let message = this.readChatMessageParameter(
        JSON.stringify(el.Message),
        'chatmessage'
      );
      let isnotification = this.readChatMessageParameter(
        JSON.stringify(el.Message),
        'isnotification'
      );
      return !message.includes(this.userName);
    });
    let checkIfidExist = checkNameAndIsNot.filter((el) => {
      let photo = this.readChatMessageParameter(
        JSON.stringify(el.Message),
        'userimageurl'
      );
      if (photo.includes('com//upload')) {
        photo = photo.toString().split('com//').join('com/');
      } else {
        photo = photo.toString().split('com///').join('com/');
      }
      return !photo.toString().includes(this.profileimageurl?.toString());
    });
    return { checkIfidExist };
  }

  getNotifications(id) {
    this.propertiesservice
      .getNotifications(id)
      .toPromise()
      .then((response) => {
        this.notifications = [];
        let { checkIfidExist } = this.notificationFiltering(response);
        checkIfidExist.forEach((el) => {
          let {
            data,
            message,
            photo,
            icon,
            color,
            isnotification,
            isimage,
            istodownload,
          } = this.read(el);
          let amOrPm;
          if (data.includes('AM') || data.includes('PM')) {
            amOrPm = data.slice(-2, data.length);
          }
          let formattedData = `${data.slice(3, 5)}/${data.slice(
            0,
            2
          )}/${data.slice(6, 10)}`;

          let returnmessage: string;
          if (
            istodownload == 'false' &&
            isimage == 'false' &&
            isnotification == 'false'
          ) {
            if (message.startsWith('&#') || message.includes('&#128')) {
              returnmessage = this.convertDecimalToEmoji(message);
            } else returnmessage = message;
          } // new attachment
          else if (istodownload == 'true' && isnotification == 'false') {
            returnmessage = 'New attachement to donwload.';
          } // new image added to chat
          else if (isimage == 'true' && isnotification == 'false') {
            returnmessage = 'New image added.';
          } else returnmessage = message;
          this.convertTimeSlot(
            formattedData,
            data.slice(11, 20),
            Number(data.slice(3, 5)),
            el.isread,
            el.GUID,
            photo,
            returnmessage,
            data.slice(0, 2),
            data.slice(3, 5),
            data.slice(6, 10),
            isnotification,
            icon,
            color,
            amOrPm,
            el.Idmessage
          );
        });
        let filtered = this.notifications.filter((el) => {
          return !el.wasRead;
        });
        this.notLength = filtered.length;
        this.loading = false
      });
  }

  goToEvent(destpage: string, paramname: string, paramvalue: string) {
    // this.router.navigate(['/' + '']);
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'chatroomguid') {
        this.router.navigate(['/' + destpage], {
          queryParams: { chatroomguid: paramvalue },
        });
      }
    }
    this.toggleModal = false;
    this.callClass();
    this.url = this.router.url;
  }

  private readChatMessageParameter(
    messagecontent: string,
    messageparameter: string
  ) {
    var indexbegin =
      messagecontent.indexOf('<' + messageparameter + '>') +
      messageparameter.length +
      2;
    var indexend = messagecontent.indexOf('</' + messageparameter + '>');

    return messagecontent.substring(indexbegin, indexend);
  }

  convertDecimalToEmoji(decimal: string) {
    var n: number;
    var nreplaces: number;

    for (n = 0; n < this.emojilist.length; n++) {
      if (decimal.includes(this.emojilist[n].decimalvalue)) {
        // because it replaces only the first match
        for (nreplaces = 0; nreplaces < 4; nreplaces++) {
          decimal = decimal.replace(
            this.emojilist[n].decimalvalue,
            this.emojilist[n].emoji
          );
        }
      }
    }

    return decimal;
  }

  logout() {
    this.authenticationService.userId = undefined;
    this.authenticationService.logout();
    this.router.navigate(['/']);
    this.toggleModal = false;
    this.ngOnInit();
  }

  navigateToPage(destpage: string, paramname: string, paramvalue: string) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'propertytype' || paramname == 'servicetype')
        this.router.navigate(['/' + destpage], {
          queryParams: paramname === 'propertytype' ? { propertytype: paramvalue } : { servicetype: paramvalue },
        });
    }
    this.toggleModal = false;
    this.callClass();
    this.url = this.router.url;
  }

  navigateToPageEvent(destpage: string, paramname: string, paramvalue: number) {
    if (paramname == '') this.router.navigate(['/' + destpage]);
    else {
      if (paramname == 'IDEvent') {
        this.router.navigate(['/' + destpage], {
          queryParams: { IDEvent: paramvalue },
        });
      }
    }
    this.callClass();
    this.url = this.router.url;
  }

  callClass() {
    var getsub = document.querySelectorAll(
      '.rotate-sub-icon'
    ) as NodeListOf<HTMLElement>;
    getsub.forEach((el) => {
      el.classList.remove('rotate-sub-icon');
    });
    if (this.idHtml !== '') {
      var parent = document.getElementById('navbarroundcomb');
      var cont = document.getElementById(this.idHtml);
      if (cont.classList.contains('second')) {
        parent.style.display = 'none';
        parent.parentElement.classList.remove('rotate-icon');
      } else {
        var getSecond = document.querySelectorAll(
          '.second'
        ) as NodeListOf<HTMLElement>;
        getSecond.forEach((el) => {
          if (el.style.display === 'block') {
            el.style.display = 'none';
          }
        });
        var getDropdown = document.querySelectorAll(
          '.dropdown'
        ) as NodeListOf<HTMLElement>;
        getDropdown.forEach((el) => {
          if (el.style.display === 'block') {
            el.style.display = 'none';
          }
        });
        parent.style.display = 'none';
        parent.parentElement.classList.remove('rotate-icon');
      }
      cont.style.display = 'none';
      cont.parentElement.classList.remove('rotate-icon');
    }
    this.ngOnInit();
    this.showhidenavbar();
  }

  ngDoCheck() {
    if (this.authenticationService.userId && !this.userIdAuth) {
      this.userIdAuth = this.authenticationService.userId;
      this.getWallet(this.authenticationService.userId);
      this.Isvendor = this.isuservendor();
      this.Istenant = this.isusertenant();
    }
    if (this.userIdAuth) {
      return;
    }
  }
  private getWallet(id) {
    this.propertiesservice
      .getWallet(id)
      .toPromise()
      .then((response) => {
        this.cash = Number(response[0].Wallet);
      });
  }

  togglenavbar(element, subMenu) {
    this.idHtml = element;
    var cont = document.getElementById(element);
    if (cont.style.display == 'block') {
      cont.style.display = 'none';
      cont.parentElement.classList.remove(
        subMenu ? 'rotate-sub-icon' : 'rotate-icon'
      );
    } else {
      cont.style.display = 'block';
      cont.parentElement.classList.add(
        subMenu ? 'rotate-sub-icon' : 'rotate-icon'
      );
    }
  }

  isuservendor() {
    if (this.authenticationService.roles.includes('vendor')) return true;
    else return false;
  }
  isusertenant() {
    if (this.authenticationService.roles.includes('tenants')) return true;
    else return false;
  }

  showhidenavbar() {
    var element = <HTMLInputElement>document.getElementById('check');
    var isChecked = element.checked;

    if (isChecked == true) this.ishamburgeropen = false;
  }

  private loadScripts() {
    const dynamicScripts = [
      './assets/js/theme.js',
      './assets/vendors/jquery.min.js',
      './assets/vendors/jquery-ui/jquery-ui.min.js',
      './assets/vendors/bootstrap/bootstrap.bundle.js',
      './assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      './assets/vendors/slick/slick.min.js',
      './assets/vendors/waypoints/jquery.waypoints.min.js',
      './assets/vendors/counter/countUp.js',
      './assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      './assets/vendors/chartjs/Chart.min.js',
      './assets/vendors/dropzone/js/dropzone.min.js',
      './assets/vendors/timepicker/bootstrap-timepicker.min.js',
      './assets/vendors/hc-sticky/hc-sticky.min.js',
      './assets/vendors/jparallax/TweenMax.min.js',
      './assets/vendors/mapbox-gl/mapbox-gl.js',
      './assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }
}
