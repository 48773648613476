import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { usersService} from '../services';

@Component({
  selector: 'app-forgot-password-remind',
  templateUrl: './forgot-password-remind.component.html',
  styleUrls: ['./forgot-password-remind.component.css']
})
export class ForgotPasswordRemindComponent implements OnInit {

  constructor(private route:ActivatedRoute,private usersservice: usersService,private formBuilder: FormBuilder,) { }

  forgotresetpasswordForm: FormGroup;
  submitted = false;
  passwordchanged = false;
  emaildata:string;
  ResetPasswordToken: string;
  errorMessage:string;

  ngOnInit(): void {

    this.route.queryParams.subscribe(
      (queryparams: Params) => {

        this.emaildata = queryparams.Email
        this.ResetPasswordToken = queryparams.resetpasswordToken;

      });

      this.forgotresetpasswordForm = this.formBuilder.group({
        mail: ['', Validators.required],
        hiddentoken:['', Validators.required],
        newPassword: ['', Validators.required],
        confirmNewPassword: ['', Validators.required],
      },{
        validator: this.ConfirmedValidator('newPassword', 'confirmNewPassword')
      });
  }

  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

  // convenience getter for easy access to form fields
  get f() { return this.forgotresetpasswordForm.controls; }

  onSubmit()
  {

    this.submitted = true;
    // stop here if form is invalid
   if (this.forgotresetpasswordForm.invalid) {
   return;
   }
   this.usersservice.setForgotResetPassword(this.emaildata,this.ResetPasswordToken,this.f.newPassword.value,this.f.confirmNewPassword.value)
       .pipe()
       .subscribe(
           data => {
             this.passwordchanged = true;
           },
           error => {

             if(JSON.stringify(error).length > 4)
                 this.errorMessage = JSON.parse(JSON.stringify(error)).error.errorMessage;

           });

  }

}
