<section>
  <div class="wrapper dashboard-wrapper">
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <div id="showDesktopDashboardNavBar" class="db-sidebar bg-white">
        <nav class="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div style="height: 279px;">
            <div class="shadow-xs-1 py-3">
              <div class="d-flex px-3 px-xl-6 w-100">
              </div>
              <app-leftmenumypainel></app-leftmenumypainel>
            </div>
          </div>
        </nav>
      </div>
      <div class="page-content">

        <main id="content" class="bg-gray-01">

          <div class="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile media-width" style="padding-top: 15px !important;" data-animated-id="1">
            <div class="mb-6">
              <h2 class="mb-0 text-heading fs-22 lh-15">Add new service
              </h2>
              <p class="mb-1">In a matter of minutes, Roundcomb will help your service get the
                much-needed visibility.</p>
            </div>
            <div class="collapse-tabs new-property-step">
              <ul class="nav nav-pills border py-2 px-3 mb-6 d-none d-md-flex mb-6" role="tablist">
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block "
                    [ngClass]="classshowativetabStep1" (click)="setStep(1)"
                    [ngStyle]="this.stepnumber > 1 && {'cursor': 'pointer'}" id="description-tab"
                    aria-selected="true"><span>
                      <ng-container *ngIf="stepnumber > 1">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Description</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block"
                    [ngClass]="classshowativetabStep2" (click)="setStep(2)"
                    [ngStyle]="this.stepnumber > 2 && {'cursor': 'pointer'}"
                    [ngStyle]="this.stepnumber < 2 && {'cursor': 'not-allowed'}" id="amenities-tab"
                    aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber == 2">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span>Services</a>
                </li>
                <li class="nav-item col">
                  <a class="nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block "
                    [ngClass]="classshowativetabStep3" [ngStyle]="this.stepnumber < 3 && {'cursor': 'not-allowed'}"
                    id="media-tab" aria-selected="false"><span>
                      <ng-container *ngIf="stepnumber == 3">
                        <i class="fal fa-check text-primary"></i>
                      </ng-container>
                    </span> Media</a>
                </li>
              </ul>
              <div class="tab-content shadow-none p-0">
                <div id="collapse-tabs-accordion">
                  <form *ngIf="isuservendor()" class="form" [formGroup]="addnewserviceFormstep1">
                    <!-- Property summary-->
                    <ng-container *ngIf="stepnumber == 1">
                      <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep1"
                        id="description">
                        <div class="card bg-transparent border-0">
                          <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                            id="heading-description">
                            <h5 class="mb-0">
                              <button class="btn btn-lg collapse-parent btn-block border shadow-none"
                                data-toggle="false" data-number="1.">
                                <span>1.</span> Description
                              </button>
                            </h5>
                          </div>

                          <div id="description-collapse">
                            <div class="card-body py-4 py-md-0 px-0">

                              <div class="row">

                                <div class="col-lg-6">
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Service
                                        Description
                                      </h3>
                                      <div class="form-group">
                                        <label for="servicetitle" class="text-heading">Title

                                        </label>
                                        <input type="text" class="form-control form-control-lg border-0"
                                          [(ngModel)]="servicetitle" id="servicetitle" maxlength="50" minlength="3"
                                          formControlName="servicetitle"
                                          [ngClass]="{ 'is-invalid': submitted && fdescstep1.servicetitle.errors}">
                                        <div *ngIf="submitted && fdescstep1.servicetitle.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdescstep1.servicetitle.errors.required">
                                            Service title is required
                                          </div>
                                        </div>

                                      </div>
                                      <div class="form-group mb-0">
                                        <label for="servicefulldescription" class="text-heading">Full
                                          Description</label>
                                        <textarea class="form-control border-0" rows="3" maxlength="400" minlength="3"
                                          formControlName="servicefulldescription" [(ngModel)]="servicefulldescription"
                                          id="servicefulldescription"
                                          [ngClass]="{ 'is-invalid': submitted && fdescstep1.servicefulldescription.errors}"></textarea>
                                        <div *ngIf="submitted && fdescstep1.servicefulldescription.errors"
                                          class="invalid-feedback">
                                          <div *ngIf="fdescstep1.servicefulldescription.errors.required">
                                            Service description is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card mb-6">
                                    <div class="card-body p-6">
                                      <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                        Operation area
                                      </h3>
                                      <div class="form-row mx-n2">
                                        <div class="col-md-6 col-lg-12 col-xxl-12 px-2">
                                          <div class="form-group">
                                            <label for="serviceprice" class="text-heading">
                                              Area
                                            </label>
                                            <input type="text" class="form-control form-control-lg border-0"
                                              [(ngModel)]="serviceoperation" id="operation"
                                              formControlName="serviceoperation"
                                              [ngClass]="{ 'is-invalid': submitted && fdescstep1.serviceoperation.errors}">
                                            <div *ngIf="submitted && fdescstep1.serviceoperation.errors"
                                              class="invalid-feedback">
                                              <div *ngIf="fdescstep1.serviceoperation.errors.required">
                                                Operation area is
                                                required
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-right">

                                <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                              <!--</form>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </form>
                  <!-- fotos -->
                  <ng-container *ngIf="stepnumber == 2">

                    <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep2" id="amenities">
                      <div class="card bg-transparent border-0">
                        <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                          id="heading-amenities">
                          <h5 class="mb-0">
                            <button class="btn btn-block collapse-parent collapsed border shadow-none"
                              data-toggle="collapse" data-number="5." data-target="#amenities-collapse"
                              aria-expanded="true" aria-controls="amenities-collapse">
                              <span class="number"></span> Services
                            </button>
                          </h5>
                        </div>
                        <div id="amenities-collapse">
                          <div class="card-body py-4 py-md-0 px-0">
                            <div class="card mb-6">
                              <div class="card-body p-6">
                                <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                  Services

                                </h3>
                                <button type="button" class="btn btn-outline-primary"
                                  (click)="includenewamenitiesModal()">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="29" height="29" viewBox="0 0 47 47">
                                    <defs>
                                      <filter id="Ellipse_317" x="0" y="0" width="47" height="47"
                                        filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                        <feFlood flood-opacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_317)">
                                      <circle id="Ellipse_317-2" data-name="Ellipse 317" cx="14.5" cy="14.5" r="14.5"
                                        transform="translate(9 6)" fill="#fff" />
                                    </g>
                                    <text id="_" data-name="+" transform="translate(0 41)" fill="#0ec6d5" font-size="38"
                                      font-family="SegoeUI, Segoe UI">
                                      <tspan x="10" y="-8">+</tspan>
                                    </text>
                                  </svg>
                                  New Service</button>

                                <div style="margin-top: 10px;" class="row">
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id <= 8">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id > 8 && commoditie.id <= 15">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                  <div class="col-sm-6 col-lg-3">
                                    <ul class="list-group list-group-no-border">

                                      <div *ngFor="let commoditie of this.listgeneralcommodities; let i = index">

                                        <div *ngIf="commoditie.id > 15">

                                          <li class="list-group-item px-0 pt-0 pb-2">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                name="{{commoditie.checkboxname}}" id="{{commoditie.id}}"
                                                [checked]="commoditie.isSelected"
                                                (change)="onGeneralCommoditiesChange($event,commoditie.id)">
                                              <label class="custom-control-label"
                                                for="{{commoditie.id}}">{{commoditie.desctext}}
                                              </label>
                                            </div>
                                          </li>

                                        </div>

                                      </div>

                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex flex-wrap">
                              <a (click)="movetoPreviousStep()"
                                class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                <span class="d-inline-block text-primary mr-2 fs-16"><i
                                    class="fal fa-long-arrow-left"></i></span>Prev
                                step
                              </a>

                              <div class="text-right">

                                <button class="btn btn-lg btn-primary" (click)="movetoNextStep()">Next step
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="stepnumber == 3">

                    <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep3" id="media">
                      <div class="card bg-transparent border-0">
                        <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                          id="heading-media">
                          <h5 class="mb-0">
                            <button class="btn btn-lg collapse-parent btn-block border shadow-none" data-toggle="false"
                              data-number="2." data-target="#media-collapse" aria-expanded="true"
                              aria-controls="media-collapse" disabled>
                              <span class="number">2.</span> Media
                            </button>
                          </h5>
                        </div>
                        <div id="media-collapse">


                          <div class="card-body py-4 py-md-0 px-0">



                            <div class="row">
                              <div class="col-lg-12">
                                <div class="card mb-12">
                                  <div class="card-body p-12">
                                    <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                      Upload photos
                                      of your service (At least 3 photos)</h3>

                                    <ngx-dropzone (change)="onSelect($event)">
                                      <ngx-dropzone-label>Drag and drop image
                                        or

                                        <br>
                                        <button class="btn btn-indigo px-7 mb-2" type="button">
                                          Browse file
                                        </button>
                                      </ngx-dropzone-label>

                                      <ngx-dropzone-image-preview [removable]="true" ngProjectAs="ngx-dropzone-preview"
                                        *ngFor="let f of files" [file]="f.file" (removed)="onRemove(f.file)">

                                      </ngx-dropzone-image-preview>
                                    </ngx-dropzone>

                                  </div>
                                  <div *ngIf="showphotoserrormessage" class="alert alert-danger" role="alert">
                                    Please upload at least 3 photos!
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="d-flex flex-wrap">
                              <a (click)="movetoPreviousStep()"
                                class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                <span class="d-inline-block text-primary mr-2 fs-16"><i
                                    class="fal fa-long-arrow-left"></i></span>Prev
                                step
                              </a>

                              <div class="text-right">

                                <button class="btn btn-lg btn-primary" (click)="handleInsertService()">
                                  Add service
                                  <span class="d-inline-block ml-2 fs-16"><i class="fal fa-long-arrow-right"></i></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Contract -->
                  <ng-container *ngIf="stepnumber == 5">

                    <div class="tab-pane tab-pane-parent fade px-0" [ngClass]="classshowativetabStep5" id="amenities">
                      <div class="card bg-transparent border-0">
                        <div class="card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0"
                          id="heading-amenities">
                          <h5 class="mb-0">
                            <button class="btn btn-block collapse-parent collapsed border shadow-none"
                              data-toggle="collapse" data-number="5." data-target="#amenities-collapse"
                              aria-expanded="true" aria-controls="amenities-collapse">
                              <span class="number">5.</span> Contract
                            </button>
                          </h5>
                        </div>
                        <div id="amenities-collapse">
                          <div class="card-body py-4 py-md-0 px-0">
                            <!-- Contract  -->
                            <div class="card mb-12">
                              <div class="card-body p-6">
                                <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                                  Contract
                                </h3>
                                <div class="form-row">
                                  <div class="form-group col-md-5">
                                    <label for="predefinedcontract" class="text-heading">Available
                                      Samples </label>
                                    <select class="form-control border-0 shadow-none form-control-lg"
                                      name="predefinedcontract" title="Contracts" data-style="btn-lg py-2 h-52"
                                      id="predefinedcontract" (change)="selectContractSample($event)">
                                      <option *ngFor="let predefinedcontract of lstPreDefinedContracts"
                                        [ngValue]="predefinedcontract.Id" id="predefinedcontract"
                                        name="predefinedcontract" [selected]=predefinedcontract.isSelected>
                                        {{
                                                                                predefinedcontract.name
                                                                                }}</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-2">
                                    <div style="text-align: center;">
                                      <label for="colFormLabelLg" class="col-form-label col-form-label-lg"
                                        style="padding-top: calc(0.625rem + 35px)!important;"><strong>OR</strong></label>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-5">
                                    <label for="addnewcontractfile" class="text-heading">Create new contract</label>

                                    <div class="input-group">
                                      <button type="button" (click)="OpenContractFile()"
                                        class="btn btn-secondary mb-2">New Contract</button>
                                    </div>


                                  </div>
                                </div>

                                <div class="form-row mx-n2">
                                  <div class="list-group">
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordname">@Landlord Name</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordid">@Landlord ID</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordidtype">@Landlord ID Type</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordaddress">@Landlord Address</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Phone Number</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Email</a>
                                    <a href="javascript:void(0);"
                                      class="list-group-item list-group-item-action landlordstyledraganddrop"
                                      value="landlordphonenumber">@Landlord Signature</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantname">@Tenant Name</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantid">@Tenant ID</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenanttype">@Tenant ID Type</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantddress">@Tenant Address</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Phone Number</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Email</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(155, 214, 59)" value="tenantphonenumber">@Tenant Signature</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Date Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Day Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@Month Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantname">@year Today</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenantid">@Initial Date</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@End Date</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Move-In date</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property address</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property City</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Property State</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Monthly Fee</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Deposit Value</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Included services</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Amenities services</a>
                                  </div>
                                  <div class="list-group ml-2">
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)"
                                      value="tenanttype">@Category(Apartment,Villa,..)</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Bedrooms</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Bathrooms</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Sq Ft</a>
                                    <a href="javascript:void(0);" class="list-group-item list-group-item-action"
                                      style="color:rgb(52, 191, 196)" value="tenanttype">@Parking</a>
                                  </div>
                                </div>
                                <div class="py-4">
                                  <ckeditor [(ngModel)]="mycontent" #myckeditor="ngModel" name="myckeditor" required
                                    [config]="ckeConfig" debounce="500" (paste)="onPaste($event)"
                                    (change)="onChange($event)">
                                    <ckbutton [name]="'saveButton'" [command]="'insert_name'"
                                      (click)="insert_name($event)" [label]="'Insert User Name'"
                                      [toolbar]="'clipboard,1'">
                                    </ckbutton>
                                  </ckeditor>
                                </div>
                                <ng-container *ngIf="this.showfilemessage==true">
                                  <div class="form-row mx-n2">
                                    <article class="col-lg-14 pr-xl-7">
                                      <div class="alert alert-danger" role="alert">
                                        {{this.warningmessage}}
                                      </div>
                                    </article>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="this.contractfileuploaded">

                                  <div class="form-row mx-n2">
                                  </div>

                                </ng-container>

                              </div>
                            </div>

                            <div class="d-flex flex-wrap">
                              <a (click)="movetoPreviousStep()"
                                class="btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button">
                                <span class="d-inline-block text-primary mr-2 fs-16"><i
                                    class="fal fa-long-arrow-left"></i></span>Prev
                                step
                              </a>


                              <button class="btn btn-lg btn-primary mb-3" type="submit">Submit
                                property
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>

              </div>
            </div>
          </div>


        </main>
      </div>
    </div>
  </div>
</section>
