import {
  Component,
  OnInit,
  AfterViewInit,
  NgZone,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, propertiesService } from '../services';
import { environment } from './../../environments/environment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsComponent } from '../google-maps/google-maps.component';
import { MapsAPILoader } from '@agm/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  faStar,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

///////////////////////////////////// Interfaces  ////////////////////////////////////////////

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-filterservice',
  templateUrl: './filterservice.component.html',
  styleUrls: ['./filterservice.component.css'],
})
export class FilterServiceComponent implements OnInit, AfterViewInit {
  public options = [
    {
      option: 'Date(low to high)',
      value: 1,
    },
    {
      option: 'Date(high to low)',
      value: 2,
    }
  ];

  public showSortBy = false;
  public label = 'Date(low to high)';

  public userIdProfile = '';
  page: number = 1;
  count: number = 0;

  coordinates: Coordinates;
  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;
  public locationdistance: string = '';

  faStar = faStar;
  faAngleDown = faAngleDown;

  public islocationcheked = false;
  public isbedscheked = false;
  public isbathscheked = false;
  public ispricecheked = false;
  public isareacheked = false;

  public innerWidth: number = window.innerWidth;
  public selectedCategory: string = '';

  public numberofpages: number;
  public activepage: number = 1;
  public pagesarray = new Array<{ idpage: number; pagedesc: String }>();

  public formfilterproperty: FormGroup;
  public showpropertylistGrid: boolean = false;
  public loading: boolean = true;
  public MAXTotalPropertiesQuery: number = 5000;
  public totalperpage: number = 8;
  public totalproperties: number;
  public listHighlight: any = [];
  public list: any = [];
  public propList: any = [];
  public dataProps: any;
  public apiurl: string = '';
  public listServices = new Array<{
    name: string;
    id: string;
    image: string;
    description: string;
    CreatedDate: string;
    status: string;
    OwnerName: String;
    Highlight: boolean;
  }>();

  //default json request
  public jsonrequest: string = '';
  public jsonAllPropertiesRequest: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private propertiesService: propertiesService,
    private modalService: NgbModal,
    private mapsAPILoader: MapsAPILoader,
    private authservice: AuthenticationService,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService
  ) {
    this.loadScripts();

    this.coordinates = {} as Coordinates;
  }

  selectCategoryOption({ target }) {
    this.selectedCategory = target.value;
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.apiurl = environment.apiUrl
    if (this.authservice.isUserLogged) {
      // get user info
      const userinfo = localStorage.getItem('roundcombuserdetail');

      let UserJsonObject = JSON.parse(userinfo);

      this.userIdProfile = UserJsonObject.userIdProfile;
    } else {
      this.userIdProfile = '';
    }

    this.locationdistance = '20';

    this.formfilterproperty = this.formBuilder.group({
      keyword: [''],
      bedsform: [''],
      switchbedsform: [''],
      switchbathsform: [''],
      switchpriceform: [''],
      switchareaform: [''],
      switchac: [''],
      switchstreetparking: [''],
      switchwasher: [''],
      switchgarage: [''],
      switchdryer: [''],
      switchpool: [''],
      switchpetsallowed: [''],
      switchathleticcenter: [''],
      switchclubhouse: [''],
      switchlocationform: [''],
    });

    this.route.queryParams.subscribe((queryparams: Params) => {
      this.setSelectCategory(queryparams.servicetype);
    });

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      this.getAllServices();
    });
  }
  onTableDataChange(event: any) {
    this.page = event;
  }

  toNext(p) {
    if (!p.isLastPage()) {
      p.next();
    }
  }
  /////////////////////////////////////// dropdownList  //////////////////////////////////////////////

  lstpropertylist = [
    { id: 1, idcategory: 1, desctext: 'All Services', isSelected: true },
    // { id: 2, idcategory: 17, desctext: 'Apartment', isSelected: false },
    // { id: 3, idcategory: 22, desctext: 'Single House', isSelected: false },
    // { id: 4, idcategory: 26, desctext: 'Single Luxury', isSelected: false },
    // { id: 5, idcategory: 18, desctext: 'Single Family', isSelected: false },
    // { id: 6, idcategory: 23, desctext: 'Townhouse', isSelected: false },
    // { id: 7, idcategory: 24, desctext: 'Villa', isSelected: false },
  ];

  lstmileslocationlist = [
    { id: 1, desctext: 'With in 10 miles', value: '10', isSelected: false },
    { id: 2, desctext: 'With in 20 miles', value: '20', isSelected: true },
    { id: 3, desctext: 'With in 30 miles', value: '30', isSelected: false },
    { id: 4, desctext: 'With in 50 miles', value: '50', isSelected: false },
    { id: 5, desctext: 'With in 100 miles', value: '100', isSelected: false },
    { id: 6, desctext: 'With in 200 miles', value: '200', isSelected: false },
    { id: 7, desctext: 'With in 300 miles', value: '300', isSelected: false },
  ];

  /////////////////////////////////////// Form  //////////////////////////////////////////////


  getIDCategorybyName(selectedValue: string) {
    for (var i = 0; i < this.lstpropertylist.length; i++) {
      if (this.lstpropertylist[i].desctext == selectedValue) {
        return this.lstpropertylist[i].idcategory.toString();
      }
    }
    return '0';
  }

  getlocationById(locationid: string): string {
    var returnvalue: string = 'Impossible to get location';

    this.propertiesService
      .getQueryLocationById(locationid)
      .pipe()
      .subscribe(
        (data) => {
          returnvalue = data;
        },
        (error) => {
          returnvalue = error;
        }
      );

    return returnvalue;
  }

  public formatnumberasUSDCurrency(amount: string) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(
      parseInt(JSON.stringify(amount).replace('"', '').replace('"', ''))
    );
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
        //this.f.switchlocationform.enable;// = true;
        //document.getElementById('switchlocationform').checked = false;.setAttribute('checked',false);
      });
    }
  }

  private getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  clearfilters() {
    this.islocationcheked = false;
    this.isareacheked = false;
    this.isbathscheked = false;
    this.isbedscheked = false;
    this.ispricecheked = false;

    this.address = '';
    this.coordinates.address = '';
    this.coordinates.latitude = 0;
    this.coordinates.longitude = 0;

    this.f.switchac.setValue(false);
    this.f.switchstreetparking.setValue(false);
    this.f.switchwasher.setValue(false);
    this.f.switchgarage.setValue(false);
    this.f.switchdryer.setValue(false);
    this.f.switchpool.setValue(false);
    this.f.switchpetsallowed.setValue(false);
    this.f.switchathleticcenter.setValue(false);
    this.f.switchclubhouse.setValue(false);

    this.f.keyword.setValue('');
  }

  clearlocation() {
    this.islocationcheked = false;
    this.address = '';
    this.coordinates.address = '';
    this.coordinates.latitude = 0;
    this.coordinates.longitude = 0;
  }

  onchangebeds(event: any) {
    //default value
    if (
      (<HTMLInputElement>document.getElementById('beds')).value != '1  to 10'
    ) {
      this.isbedscheked = true;
    }
  }

  onchangebaths(event: any) {
    //default value
    if ((<HTMLInputElement>document.getElementById('baths')).value != '1  to 5')
      this.isbathscheked = true;
  }

  onchangeprice(event: any) {
    //default value
    if (
      (<HTMLInputElement>document.getElementById('price')).value !=
      '$100 to $5,000,000'
    )
      this.ispricecheked = true;
  }

  onchangearea(event: any) {
    //default value
    if (
      (<HTMLInputElement>document.getElementById('area')).value !=
      '50 sqft to 15000 sqft'
    )
      this.isareacheked = true;
  }

  searchServices() {}

  openGoogelMapsModal() {
    const modalRef = this.modalService.open(GoogleMapsComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
    });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        this.coordinates = result;

        this.address = this.coordinates.address;

        // auto change to true the lo0cation
        if (typeof this.address != 'undefined' && this.address)
          this.islocationcheked = true;
      },
      (reason) => {}
    );
  }

  onLocationcheckChange(e) {
    this.islocationcheked = this.f.switchlocationform.value;

    if (!this.f.switchlocationform.value) {
      this.coordinates.address = '';
      this.coordinates.latitude = 0;
      this.coordinates.longitude = 0;
      this.address = '';
    }
  }

  getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }
  async getAllServices() {
    this.ngxService.startBackground('loadmyservices');
    this.listServices = [];
    await this.propertiesService
      .getAllServices()
      .pipe()
      .subscribe((data) => {
        if (this.f.keyword.value !== '') {
          let filteredByName = data.filter((el) => {
            return el.title
              .toLowerCase()
              .includes(this.f.keyword.value.toLowerCase());
          });

          filteredByName.forEach((element) => {
            this.listServices.push({
              name: element.title,
              id: element.id,
              image: element.imageOne,
              description: element.description,
              CreatedDate: moment
                .unix(element.create_date)
                .format('MM.DD.YYYY'),
              status: element.status,
              OwnerName: element.FirstName + ' ' + element.LastName,
              Highlight: false,
            });
          });
        } else {
          let filter = data.filter((el) => {
            return el.status === 'AvailableForSell';
          });
          filter.forEach((element) => {
            this.listServices.push({
              name: element.title,
              id: element.id,
              image: element.imageOne,
              description: element.description,
              CreatedDate: moment
                .unix(element.create_date)
                .format('MM.DD.YYYY'),
              status: element.status,
              OwnerName: element.FirstName + ' ' + element.LastName,
              Highlight: false,
            });
          });
        }
        this.ngxService.stopBackground('loadmyservices');
        this.loading = false
      });
  }

  onChangelocationdistance(selectedvalue) {
    this.locationdistance = selectedvalue;
    this.locationdistance = this.locationdistance
      .replace('With in ', '')
      .replace(' miles', '');
  }

  onChangeorderby(selectedvalue, label) {
    this.label = label;
    this.showSortBy = false;
    if (selectedvalue == 1) {
      // price low to high
      let sortedBuilds = this.listServices.sort((n1, n2) => {
        return this.sortCompare(n1.CreatedDate, n2.CreatedDate);
      });
    }
    if (selectedvalue == 2) {
      // price high to low
      let sortedBuilds = this.listServices.sort((n2, n1) => {
        return this.sortCompare(n1.CreatedDate, n2.CreatedDate);
      });
    }
  }

  sortCompare(a, b) {
    var ax = [],
      bx = [];

    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }

    return ax.length - bx.length;
  }

  navigateToPropertyDetails(serviceId: string) {
    this.router.navigate(['/servicedetail'], { queryParams: { serviceId } });
  }
  setSelectCategory(selectedValue: string) {
    if (selectedValue.length > 0) {
      for (var i = 0; i < this.lstpropertylist.length; i++) {
        if (this.lstpropertylist[i].desctext == selectedValue) {
          this.lstpropertylist[i].isSelected = true;
          this.selectedCategory = this.lstpropertylist[i].desctext;

          if (this.lstpropertylist[i].idcategory != 1) {
          } //all properties
          else {
          }
        } else this.lstpropertylist[i].isSelected = false;
      }
    }
  }

  get f() {
    return this.formfilterproperty.controls;
  }
  ///////////////////////////////////////// functions  ////////////////////////////////////////////////

  changepropertylistGrid(): void {
    this.showpropertylistGrid = !this.showpropertylistGrid;
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/theme.js',
      'assets/vendors/jquery.min.js',
      'assets/vendors/jquery-ui/jquery-ui.min.js',
      'assets/vendors/bootstrap/bootstrap.bundle.js',
      'assets/vendors/bootstrap-select/js/bootstrap-select.min.js',
      'assets/vendors/slick/slick.min.js',
      'assets/vendors/waypoints/jquery.waypoints.min.js',
      'assets/vendors/counter/countUp.js',
      'assets/vendors/magnific-popup/jquery.magnific-popup.min.js',
      'assets/vendors/chartjs/Chart.min.js',
      'assets/vendors/dropzone/js/dropzone.min.js',
      'assets/vendors/timepicker/bootstrap-timepicker.min.js',
      'assets/vendors/hc-sticky/hc-sticky.min.js',
      'assets/vendors/jparallax/TweenMax.min.js',
      'assets/vendors/mapbox-gl/mapbox-gl.js',
      'assets/cdn-cgi/head/2oc_RD5SS6wgN5SiQnSEnWVNHg8.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
