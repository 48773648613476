
        <div class="modal-header">
            <h5 class="modal-title" style="text-align: center;align-items: center;" id="exampleModalLabel">VERIFY YOUR IDENTITY</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <span style="color: #707070; font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;">
              Please do not use <strong>SUNGLASSES, HATS,</strong> or any <strong>ACCESSORIES</strong> that may influence the way you look.
              <br>
              In case of any doubt, read the terms of use and or get in touch with us!  (support@roundcomb.com)
            </span>
            <br>
            <br>
                <ng-container *ngIf="showWebcam">

                <div style="text-align:center">
                <div>
                  <webcam [height]="430" [width]="430" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                          [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                          [videoOptions]="videoOptions"
                          [imageQuality]="1"
                          (cameraSwitched)="cameraWasSwitched($event)"
                          (initError)="handleInitError($event)"
                  ></webcam>
                  <br/>
                  <br/>
                </div>
              </div>
              </ng-container>
              <ng-container *ngIf="!showWebcam">
              <div class="snapshot" *ngIf="webcamImage">


        <div class="row" style="margin-top: 7px; align-items: center;">

          <div class="text-center">

              <h5>Crop Image</h5>

              <image-cropper

              [imageURL]="imageChangedEvent"


              [maintainAspectRatio]="true"

              [roundCropper] = "true"

              [aspectRatio]="4 / 4"

              [resizeToWidth]="256"

              format="png"

              (imageCropped)="imageCropped($event)"

              (imageLoaded)="imageLoaded()"

              (cropperReady)="cropperReady()"

              (loadImageFailed)="loadImageFailed()"></image-cropper>

          </div>
      </div>
              </div>
            </ng-container>
              <h4 *ngIf="errors.length > 0">Messages:</h4>
              <ul *ngFor="let error of errors">
                <li>{{error | json}}</li>
              </ul>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary close-webcam" data-dismiss="modal"  (click)="closeModal()">Close</button>
            <ng-container *ngIf="!showWebcam">
            <button type="button" class="btn btn-primary" (click)="toggleWebcam();">Take New Photo</button>
            <button type="button" class="btn btn-primary submit-photo" (click)="savenewimage()">Submit</button>
            </ng-container>
            <ng-container *ngIf="showWebcam">
              <button class="btn btn-outline-info" (click)="triggerSnapshot();">Take Photo</button>
            </ng-container>


          </div>
