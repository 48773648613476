<section style="width: 100%;
height: 100vh;" *ngIf="this.loading">
  <div style="position: absolute;">

    <ngx-ui-loader></ngx-ui-loader>

  </div>
</section>
<ng-container *ngIf="!this.loading">
  <section *ngIf="this.featuredpropertieslist.length > 0">
    <div style="position: relative;">
      <ngx-slick-carousel *ngIf="this.featuredpropertieslist.length >= 0" [ngClass]="{'slick-slider': true, 'custom-slider-3': true, 'custom-dots': true, 'mx-0': true, 'loading-slice': this.loading === true}" #slickModal="slick-carousel"
        (beforeChange)="beforeChange($event)"
        [config]="slideConfig">
        <div *ngFor="let prop of this.featuredpropertieslist ; let i = index" ngxSlickItem
          class="box px-0 d-flex flex-column">
          <!--  <ng-container *ngIf="isDataAvailable"> -->
          <div class="bg-cover bg-overlay d-flex align-items-center custom-vh-02">
            <img *ngIf="this.featuredpropertieslist[0].imageurl" srcset="{{prop.imageurl}}"
              style="width: 100%; height: 100vh; position: absolute; top: 0;" alt="{{prop?.Name}}" fetchpriority="high">
            <div *ngIf="this.featuredpropertieslist.length >= 1" class="container container-xxl">
              <div class="row position-from-mobile">
                <div class="col-xl-4 offset-xl-8 col-md-7 offset-md-5 py-8">
                  <div [ngClass]="{'fadeInRight': currentSlide === i }"
                    class="bg-white px-sm-7 py-sm-6 py-3 px-3 rounded-lg position-relative z-index-2 ml-sm-3 mb-14">
                    <div class="pb-6">
                      <dl class="d-flex mb-0">
                        <dt class="w-140px pr-1 fs-15 font-weight-500 text-uppercase text-dark">Type
                        </dt>
                        <dd>{{prop?.namecategory}}</dd>
                      </dl>
                      <dl class="d-flex mb-0">
                        <dt class="w-140px pr-1 fs-15 font-weight-500 text-uppercase text-dark">
                          Garage
                        </dt>
                        <dd>{{prop?.Garage}}</dd>
                      </dl>
                      <dl class="d-flex mb-0">
                        <dt class="w-140px pr-1 fs-15 font-weight-500 text-uppercase text-dark">Area </dt>
                        <dd>{{prop?.SquareFeet}} sqft</dd>
                      </dl>
                      <dl class="d-flex mb-0">
                        <dt class="w-140px pr-1 fs-15 font-weight-500 text-uppercase text-dark">
                          Price/month
                        </dt>
                        <dd>${{prop?.Price}}</dd>
                        <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                      </dl>
                    </div>
                    <h2 class="mb-1 lh-12 fs-30 pt-5 border-top">
                      <a (click)="navigateToPage('propertydetail','propertyId',prop?.IdProperty)"
                        style="cursor: pointer" class="text-dark hover-primary font-weight-600">Facts &
                        Features</a>
                    </h2>
                    <p class="mb-0">{{prop?.Name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  </ng-container> -->
        </div>
      </ngx-slick-carousel>
      <button style="display: flex !important;" class="slick-prev slick-arrow" (click)="prev()">prev</button>
      <button style="display: flex !important;" class="slick-next slick-arrow" (click)="next()">next</button>
    </div>
  </section>
  <section class="pt-10" *ngIf="this.featuredpropertieslist.length > 0">
    <div class="container container-xxl">
      <div class="row">
        <div class="col-md-6">
          <h2 class="text-heading">Popular Properties</h2>
          <span class="heading-divider"></span>
        </div>

      </div>

      <ngx-slick-carousel #slickModal="slick-carousel" style="height: 510px; display: flex;"
        class="slick-slider slick-dots-mt-1 popular-slick" [config]="slideTwoConfig">
        <div ngxSlickItem *ngIf="this.featuredpropertieslist.length >= 1" class="px-3 card-width py-6">

          <div class="card card-slick shadow-hover-1" data-animate="fadeInUp" style="min-height: 430px;">
            <a (click)="this.navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[0].IdProperty)">
              <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                <ng-container *ngIf="isDataAvailable">
                  <ng-container *ngIf="this.featuredpropertieslist[0].imageurl !== undefined">
                    <!-- <advanced-image [cldImg]="img"></advanced-image> -->
                    <img fetchpriority="high"
                      srcset="{{this.featuredpropertieslist[0].imageurl}}" alt="{{this.featuredpropertieslist[0].Name}}"
                      class="responsiveimage">
                  </ng-container>
                </ng-container>
                <div class="card-img-overlay p-2 d-flex flex-column">
                </div>
              </div>
            </a>
            <div class="card-body pt-3">
              <h2 class="card-title fs-16 lh-2 mb-0">
                <ng-container *ngIf="isDataAvailable">
                  <a (click)="navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[0].IdProperty)"
                    style="cursor: pointer" class="text-dark hover-primary">{{this.featuredpropertieslist[0].Name}}</a>
                </ng-container>

              </h2>
              <ng-container *ngIf="isDataAvailable">
                <p class="card-text font-weight-500 text-gray-light mb-2">{{this.featuredpropertieslist[0].OwnerName}}
                </p>
              </ng-container>
              <ul class="list-inline d-flex justify-content-between mb-0 flex-wrap mr-n2">
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bedrooms">
                  <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                    <use xlink:href="#icon-bedroom"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[0].Beds}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bathrooms">
                  <svg class="icon icon-shower fs-18 text-primary mr-1">
                    <use xlink:href="#icon-shower"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[0].Baths}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Area">
                  <svg class="icon icon-square fs-18 text-primary mr-1">
                    <use xlink:href="#icon-square"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[0].SquareFeet}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Garage">
                  <svg class="icon icon-Garage fs-18 text-primary mr-1">
                    <use xlink:href="#icon-Garage"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[0].Garage}}
                  </ng-container>
                </li>
              </ul>
            </div>
            <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
              <p class="fs-17 font-weight-bold text-heading mb-0">
                <ng-container *ngIf="isDataAvailable">
                  ${{this.featuredpropertieslist[0].Price}}
                </ng-container>
                <span class="text-gray-light font-weight-500 fs-14"> / month</span>
              </p>
            </div>
          </div>

        </div>
        <div ngxSlickItem *ngIf="this.featuredpropertieslist.length >= 2" class="px-3 card-width py-6">

          <div class="card card-slick shadow-hover-1" data-animate="fadeInUp" style="min-height: 430px;">
            <a (click)="this.navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[1].IdProperty)">
              <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                <ng-container *ngIf="isDataAvailable">
                  <ng-container *ngIf="this.featuredpropertieslist[1].imageurl !== undefined">
                    <!-- <advanced-image [cldImg]="img"></advanced-image> -->
                    <img loading="lazy" decoding="async" fetchpriority="high"
                      src="{{this.featuredpropertieslist[1].imageurl}}" alt="{{this.featuredpropertieslist[1].Name}}"
                      class="responsiveimage">
                  </ng-container>
                </ng-container>
                <div class="card-img-overlay p-2 d-flex flex-column">
                </div>
              </div>
            </a>
            <div class="card-body pt-3">
              <h2 class="card-title fs-16 lh-2 mb-0">
                <ng-container *ngIf="isDataAvailable">
                  <a (click)="navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[1].IdProperty)"
                    style="cursor: pointer" class="text-dark hover-primary">{{this.featuredpropertieslist[1].Name}}</a>
                </ng-container>

              </h2>
              <ng-container *ngIf="isDataAvailable">
                <p class="card-text font-weight-500 text-gray-light mb-2">{{this.featuredpropertieslist[1].OwnerName}}
                </p>
              </ng-container>
              <ul class="list-inline d-flex justify-content-between mb-0 flex-wrap mr-n2">
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bedrooms">
                  <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                    <use xlink:href="#icon-bedroom"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[1].Beds}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bathrooms">
                  <svg class="icon icon-shower fs-18 text-primary mr-1">
                    <use xlink:href="#icon-shower"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[1].Baths}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Area">
                  <svg class="icon icon-square fs-18 text-primary mr-1">
                    <use xlink:href="#icon-square"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[1].SquareFeet}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Garage">
                  <svg class="icon icon-Garage fs-18 text-primary mr-1">
                    <use xlink:href="#icon-Garage"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[1].Garage}}
                  </ng-container>
                </li>
              </ul>
            </div>
            <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
              <p class="fs-17 font-weight-bold text-heading mb-0">
                <ng-container *ngIf="isDataAvailable">
                  ${{this.featuredpropertieslist[1].Price}}
                </ng-container>
                <span class="text-gray-light font-weight-500 fs-14"> / month</span>
              </p>
            </div>
          </div>

        </div>
        <div ngxSlickItem *ngIf="this.featuredpropertieslist.length >= 3" class="px-3 card-width py-6">

          <div class="card card-slick shadow-hover-1" data-animate="fadeInUp" style="min-height: 430px;">
            <a (click)="this.navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[2].IdProperty)">
              <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                <ng-container *ngIf="isDataAvailable">
                  <ng-container *ngIf="this.featuredpropertieslist[2].imageurl !== undefined">
                    <!-- <advanced-image [cldImg]="img"></advanced-image> -->
                    <img loading="lazy" decoding="async" fetchpriority="high"
                      src="{{this.featuredpropertieslist[2].imageurl}}" alt="{{this.featuredpropertieslist[2].Name}}"
                      class="responsiveimage">
                  </ng-container>
                </ng-container>
                <div class="card-img-overlay p-2 d-flex flex-column">
                </div>
              </div>
            </a>
            <div class="card-body pt-3">
              <h2 class="card-title fs-16 lh-2 mb-0">
                <ng-container *ngIf="isDataAvailable">
                  <a (click)="navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[2].IdProperty)"
                    style="cursor: pointer" class="text-dark hover-primary">{{this.featuredpropertieslist[2].Name}}</a>
                </ng-container>

              </h2>
              <ng-container *ngIf="isDataAvailable">
                <p class="card-text font-weight-500 text-gray-light mb-2">{{this.featuredpropertieslist[2].OwnerName}}
                </p>
              </ng-container>
              <ul class="list-inline d-flex justify-content-between mb-0 flex-wrap mr-n2">
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bedrooms">
                  <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                    <use xlink:href="#icon-bedroom"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[2].Beds}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bathrooms">
                  <svg class="icon icon-shower fs-18 text-primary mr-1">
                    <use xlink:href="#icon-shower"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[2].Baths}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Area">
                  <svg class="icon icon-square fs-18 text-primary mr-1">
                    <use xlink:href="#icon-square"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[2].SquareFeet}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Garage">
                  <svg class="icon icon-Garage fs-18 text-primary mr-1">
                    <use xlink:href="#icon-Garage"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[2].Garage}}
                  </ng-container>
                </li>
              </ul>
            </div>
            <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
              <p class="fs-17 font-weight-bold text-heading mb-0">
                <ng-container *ngIf="isDataAvailable">
                  ${{this.featuredpropertieslist[2].Price}}
                </ng-container>
                <span class="text-gray-light font-weight-500 fs-14"> / month</span>
              </p>
            </div>
          </div>

        </div>
        <div ngxSlickItem *ngIf="this.featuredpropertieslist.length >= 4" class="px-3 card-width py-6">

          <div class="card card-slick shadow-hover-1" data-animate="fadeInUp" style="min-height: 430px;">
            <a (click)="this.navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[3].IdProperty)">
              <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                <ng-container *ngIf="isDataAvailable">
                  <ng-container *ngIf="this.featuredpropertieslist[3].imageurl !== undefined">
                    <!-- <advanced-image [cldImg]="img"></advanced-image> -->
                    <img loading="lazy" decoding="async" fetchpriority="high"
                      src="{{this.featuredpropertieslist[3].imageurl}}" alt="{{this.featuredpropertieslist[3].Name}}"
                      class="responsiveimage">
                  </ng-container>
                </ng-container>
                <div class="card-img-overlay p-2 d-flex flex-column">
                </div>
              </div>
            </a>
            <div class="card-body pt-3">
              <h2 class="card-title fs-16 lh-2 mb-0">
                <ng-container *ngIf="isDataAvailable">
                  <a (click)="navigateToPage('propertydetail','propertyId',this.featuredpropertieslist[3].IdProperty)"
                    style="cursor: pointer" class="text-dark hover-primary">{{this.featuredpropertieslist[3].Name}}</a>
                </ng-container>

              </h2>
              <ng-container *ngIf="isDataAvailable">
                <p class="card-text font-weight-500 text-gray-light mb-2">{{this.featuredpropertieslist[3].OwnerName}}
                </p>
              </ng-container>
              <ul class="list-inline d-flex justify-content-between mb-0 flex-wrap mr-n2">
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bedrooms">
                  <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                    <use xlink:href="#icon-bedroom"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[3].Beds}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Bathrooms">
                  <svg class="icon icon-shower fs-18 text-primary mr-1">
                    <use xlink:href="#icon-shower"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[3].Baths}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Area">
                  <svg class="icon icon-square fs-18 text-primary mr-1">
                    <use xlink:href="#icon-square"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[3].SquareFeet}}
                  </ng-container>
                </li>
                <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-2"
                  data-toggle="tooltip" title="Garage">
                  <svg class="icon icon-Garage fs-18 text-primary mr-1">
                    <use xlink:href="#icon-Garage"></use>
                  </svg>
                  <ng-container *ngIf="isDataAvailable">
                    {{this.featuredpropertieslist[3].Garage}}
                  </ng-container>
                </li>
              </ul>
            </div>
            <div class="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
              <p class="fs-17 font-weight-bold text-heading mb-0">
                <ng-container *ngIf="isDataAvailable">
                  ${{this.featuredpropertieslist[3].Price}}
                </ng-container>
                <span class="text-gray-light font-weight-500 fs-14"> / month</span>
              </p>
            </div>
          </div>

        </div>
      </ngx-slick-carousel>
    </div>
  </section>
  <section *ngIf="this.featuredpropertieslist.length > 0">
    <div class="col-md-12 text-md-center to-center">
      <a (click)="navigateToPage('filterproperty','propertytype','All Properties')"
        class="btn btn-lg text-secondary btn-accent rounded-lg">View all properties
        <i class="far fa-long-arrow-right ml-1"></i>
      </a>
    </div>
  </section>
  <section *ngIf="!this.loading"
    [ngClass]="{'pt-11': this.featuredpropertieslist.length > 0, 'pt-md-14': this.featuredpropertieslist.length > 0}"
    style="padding-top: 40px;" class="pb-11" data-animated-id="2">
    <div class="container container-xxl">
      <div class="row">
        <div class="col-md-6">
          <h2 class="text-heading">Browse By Category</h2>
          <span class="heading-divider"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" style="height: 100%;" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top" style="height: 100%;">
              <a routerLink="/filterproperty" [queryParams]='{propertytype:"Apartment"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img decoding="async" loading="lazy" fetchpriority="high" style="aspect-ratio: auto 640 / 360; height: 100%;"
                  width="640" height="360" ngSrc="assets/images/apartmentCategory.webp" alt="Apartment Category">
                <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                  <p class="categorydescription">Apartment</p>
                </div>
                <div class="card-img-overlay d-flex flex-column">

                </div>

              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
              <a routerLink="/filterproperty" [queryParams]='{propertytype:"Single Family"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img decoding="async" loading="lazy" fetchpriority="high" style="width: 100%; height: 324px;"
                  width="560" height="373" ngSrc="assets/images/singlefamilyCategory.webp" alt="Single Family">
                <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                  <p class="categorydescription">Single Family</p>
                </div>
                <div class="card-img-overlay d-flex flex-column">

                </div>
              </a>
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" style="height: 324px;" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top" style="height: 324px;">
              <a style="height: 100%;" routerLink="/filterproperty" [queryParams]='{propertytype:"Single House"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img decoding="async" loading="lazy" fetchpriority="high" style="width: 100%; height: 100%;" width="480"
                  height="320" ngSrc="assets/images/SingleHouseCategory.webp" alt="Single House">
                <div class="card-img-overlay d-flex flex-column" style="height: 100%;">
                  <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                    <p class="categorydescription">Single House</p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
              <a routerLink="/filterproperty" [queryParams]='{propertytype:"Single Luxury"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img decoding="async" loading="lazy" fetchpriority="high" style="width: 100%; height: 324px;"
                  width="540" height="359" ngSrc="assets/images/singleluxuryCategory.webp" alt="Single Luxury">
                <div class="card-img-overlay d-flex flex-column">
                  <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                    <p class="categorydescription">Single Luxury</p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
              <a routerLink="/filterproperty" [queryParams]='{propertytype:"Townhouse"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img decoding="async" loading="lazy" fetchpriority="high" style="width: 100%; height: 324px;"
                  width="466" height="315" ngSrc="assets/images/townhouseCategory.webp" alt="Townhouse">

                <div class="card-img-overlay d-flex flex-column">
                  <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                    <p class="categorydescription">Townhouse</p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 pb-6">
          <div class="card fadeInUp animated" data-animate="fadeInUp">
            <div class="hover-change-image bg-hover-overlay rounded-lg card-img-top">
              <a routerLink="/filterproperty" [queryParams]='{propertytype:"Villa"}'
                class="s-xl-20 fs-lg-16 fs-20 lh-16 text-white">
                <img style="width: 100%; height: 324px;" width="506" height="338"
                  ngSrc="assets/images/villaCategory.webp" alt="Villa">
                <div class="card-img-overlay d-flex flex-column">
                  <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" class="box-back">
                    <p class="categorydescription">Villa</p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </section>
</ng-container>

<!--</div>-->
