<main id="content">
    <section class="py-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <div class="card border-0 login-register">
                        <div class="card-body p-2">
                            <div class="card-body px-8 py-6">
                                <h2 class="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Sign In</h2>
                                <p class="mb-4">Don’t have an account yet? <a routerLink="/newuser"
                                        class="text-heading hover-primary"><u>Register.</u></a></p>
                                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group mb-4">
                                        <label for="username">Email</label>
                                        <input type="text" formControlName="username"
                                            class="form-control form-control-lg border-0" id="username" name="username"
                                            placeholder="Your email"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Email is required</div>
                                            <div *ngIf="f.username.errors.pattern">Email is invalid</div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-4">
                                        <label for="password">Password</label>
                                        <div class="input-group input-group-lg">
                                            <input type="password" class="form-control border-0 shadow-none fs-13"
                                                id="password" name="password" placeholder="Password"
                                                formControlName="password" autocomplete="off"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">

                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-4">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="remember-me-1"
                                                name="remember">
                                            <label class="form-check-label" for="remember-me-1">
                                                Stay signed in
                                            </label>
                                        </div>
                                        <a routerLink="/recoverpassword"
                                            class="d-inline-block ml-auto fs-13 lh-2 text-body">
                                            <u>Forgot your password?</u>
                                        </a>
                                    </div>
                                    <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-lg btn-block rounded">Sign
                                        In</button>
                                </form>
                                <div class="divider text-center my-2">
                                    <span class="px-4 bg-white lh-17 text text-heading">
                                        or Sign In with
                                    </span>
                                </div>
                                <div class="row no-gutters mx-n2">
                                    <div class="col-sm-4 px-2 mb-4">
                                        <a (click) = "signInWithFB()"
                                            class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent">
                                            <img src="assets/images/facebook.png" alt="Facebook" class="mr-2">
                                            Facebook
                                        </a>
                                    </div>
                                    <div class="col-sm-4 px-2 mb-4">
                                        <a (click) = "signInWithGoogle()"
                                            class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent">
                                            <img src="assets/images/google.png" alt="Google" class="mr-2">
                                            Google
                                        </a>
                                    </div>
                                    <div class="col-sm-4 px-2 mb-4">
                                        <a href="#"
                                            class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent">
                                            <img src="assets/images/apple.png" alt="Apple" class="mr-2">
                                            Apple
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
