import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { AuthenticationService, propertiesService } from '../services';
import { PaymentmodalComponent } from '../chat/paymentmodal/paymentmodal.component';
import { unit_amount } from '../chat/chat.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import {
  faHouseChimney,
  faHouseChimneyWindow,
  faPlus,
  faHandHoldingDollar,
  faCoins,
  faExclamation,
  faStar,
  faLock,
  faCircleArrowUp,
  faCaretLeft,
  faCaretRight,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TemplateBindingParseResult } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css'],
})
export class WalletComponent implements OnInit {
  public userIdProfile: string = '';

  faCoins = faCoins;
  faDollarSign = faDollarSign;
  faExclamation = faExclamation;
  faHouseChimney = faHouseChimney;
  faHouseChimneyWindow = faHouseChimneyWindow;
  faPlus = faPlus;
  faHandHoldingDollar = faHandHoldingDollar;
  faStar = faStar;
  faLock = faLock;
  faCircleArrowUp = faCircleArrowUp;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;

  public openOrCloseModal: boolean = false;
  public openBuyModal: boolean = false;
  public checked: boolean = false;
  public showMore: boolean = false;
  public dateavailable: any;

  public step: string = 'add-card';
  public typeCurrent: string = '';
  public innerWidth: number = window.innerWidth;

  public idpropertypay: any;
  public filteredItem: any[];
  public propretiesBoostedArr: any[];

  public listSliced: any[];
  public allOrSliced: any[];

  public stepBoost: string = '';
  public checkedBoost: boolean = false;
  public checkedHigh: boolean = false;
  public highlightPrice: number = 0;
  public boostPrice: number = 0;
  public boostedList: any = [];

  public cash: number;
  public total: number = 10;
  public valueMonth: number = 1;
  public valueExpired: any;
  public valueMonthInNumber: number = 1;
  public value: number = 0;

  public paymentItems = new Array<{
    name: string;
    quantity: string;
    category: string;
    unit_amount: unit_amount;
  }>();
  public transactionsList = new Array<{
    name: string;
    date: string;
    time: string;
    ammount: number;
  }>();

  constructor(
    private ngxService: NgxUiLoaderService,
    public authenticationService: AuthenticationService, // private propertiesservice: propertiesService
    private router: Router,
    private modalService: NgbModal,
    private propertiesservice: propertiesService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    // if (this.authenticationService.customizedroles !== 'landlord') {
    //   this.router.navigate(['/*']);
    // }

    const userinfo = localStorage.getItem('roundcombuserdetail');
    let UserJsonObject = JSON.parse(userinfo);

    this.userIdProfile = UserJsonObject.userIdProfile;
    var toDay = moment();
    toDay.add(1, 'months');
    this.dateavailable = toDay.format('Do [of] MMMM [of] YYYY');
    this.getUniqueWallet(this.userIdProfile);
    this.getSlot(UserJsonObject.userIdProfile);
    this.getMyProperties();
  }

  ngAfterViewInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  private getSlot(id) {
    this.propertiesservice
      .getSlot(id)
      .toPromise()
      .then((response) => {
        this.getExpired(response);
      })
      .catch((e) => console.error(e));
  }

  show() {
    if (this.showMore) {
      this.showMore = false;
    } else {
      this.showMore = true;
    }
    this.ngOnInit()
  }

  getExpired(response) {
    this.valueExpired = response.filter((x) => x.Month === 0 && !x.dateExpired.toString().includes('Expired'));
  }

  private updateEventProperty(id, value, event) {
    let result;
    this.propertiesservice
      .updateEventProperty(id, value, event)
      .toPromise()
      .then((response) => {
        result = response;
      });
    return result;
  }

  openModalRenew(id, step) {
    this.onResize('')
    this.stepBoost = step;
    let filter = this.boostedList.filter((el) => {
      return el.Id === id;
    });
    this.filteredItem = filter;
    this.idpropertypay = id;
    this.openBuyModal = true;
    document.body.style.overflow = 'hidden';
    if (this.innerWidth <= 768) {
      document.getElementById('nav-btn').style.display = 'none';
    }
    if (step === 'boost') {
      this.checkedBoost = true;
      this.boostPrice = 10;
    } else if (step === 'high') {
      this.checkedHigh = true;
      this.highlightPrice = 10;
    }
  }

  changeBoostMonthValue(el: any) {
    this.valueMonth = el.target.value;
    el.target.value = el.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1');
    this.valueMonth = Number(el.target.value) > 12 ? 12 : el.target.value;
    this.valueMonthInNumber = Number(this.valueMonth);
    var toDay = moment();
    toDay.add(el.target.value, 'months');
    this.dateavailable = toDay.format('Do [of] MMMM [of] YYYY');

    if (!this.checkedBoost && !this.checkedHigh) {
      this.total = Number(this.valueMonth) * 10;
    } else {
      this.total = (this.boostPrice + this.highlightPrice) * this.valueMonth;
    }
  }

  checkBoosts() {
    this.checkedBoost = !this.checkedBoost;
    if (this.checkedBoost) {
      this.boostPrice = 10;
    } else {
      this.boostPrice = 0;
    }
    if (!this.checkedBoost && !this.checkedHigh) {
      this.total = Number(this.valueMonth) * 10;
    } else {
      this.total = (this.boostPrice + this.highlightPrice) * this.valueMonth;
    }
  }

  checkHighlight() {
    this.checkedHigh = !this.checkedHigh;
    if (this.checkedHigh) {
      this.highlightPrice = 10;
    } else {
      this.highlightPrice = 0;
    }
    if (!this.checkedBoost && !this.checkedHigh) {
      this.total = Number(this.valueMonth) * 10;
    } else {
      this.total = (this.boostPrice + this.highlightPrice) * this.valueMonth;
    }
  }
  submitChanges() {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to renew the boosts?',
      showDenyButton: true,
      confirmButtonText: 'Buy',
      denyButtonText: `Cancel`,
    }).then((res) => {
      /* Read more about isConfirmed, isDenied below */
      if (res.isConfirmed) {
        if (this.cash < this.total) {
          this.toast.error('Insufficient RCT!', 'Error!');
          return;
        }
        let f = this.boostedList.filter((el) => {
          return el.Id === this.idpropertypay;
        });
        if (!this.checkedBoost && !this.checkedHigh) {
          this.toast.error('Select a boost, to proceed!', 'Error!');
          return;
        } else if (this.checkedBoost) {
          this.updateEventProperty(
            Number(this.idpropertypay),
            Number(this.valueMonth) + Number(f[0].MonthBooster),
            'MonthBooster'
          );
        } else if (this.checkedHigh) {
          this.updateEventProperty(
            Number(this.idpropertypay),
            Number(this.valueMonth) + Number(f[0].MonthHighlight),
            'MonthHighlight'
          );
        } else {
          return;
        }
        let valueWallet = Number(this.cash) - this.total;
        this.updateWallet(valueWallet);
        this.transactionInsert(
          -this.total,
          this.idpropertypay,
          'Boost payment'
        );
        this.getUniqueWallet(this.userIdProfile);
        this.closeModal();
        this.toast.success('boost renewed successfully!', 'Success!');
        this.ngOnInit();
      } else if (res.isDenied) {
        return;
      }
    });
  }

  private getUniqueWallet(id) {
    this.propertiesservice
      .getWallet(id)
      .toPromise()
      .then((response) => {
        this.cash = Number(response[0].Wallet);
        document.querySelector('.cash-in-account').innerHTML = Number(
          response[0].Wallet
        ).toFixed(2);
      });
  }
  openModal(step) {
    this.onResize('')
    this.step = step;
    this.openOrCloseModal = true;
    document.body.style.overflow = 'hidden';
    if (this.innerWidth <= 768) {
      document.getElementById('nav-btn').style.display = 'none';
    }
  }

  private getMyProperties() {
    this.propertiesservice
      .getAllMyProperties(this.userIdProfile)
      .pipe()
      .subscribe((data) => {

        let ifHasBooster = data.filter(el => {
          return el.Booster || el.Highlight
        })
        let filteredBooster = ifHasBooster.filter((el) => {
          /*TODO
          I put this filter to show expired boosters at 0, create a function to check if it is expired or not
          */
          return el.MonthBooster <= 0 || el.MonthHighlight <= 0;
        });
        this.boostedList = filteredBooster;
        this.getTransaction();
      });
    this.ngxService.stopBackground('loadmyproperties');
  }

  closeModal() {
    this.step = 'add-card';
    this.typeCurrent = '';
    this.openOrCloseModal = false;
    this.openBuyModal = false;
    this.checkedBoost = false;
    this.checkedHigh = false;
    this.boostPrice = 0;
    this.highlightPrice = 0;
    this.value = 0;
    this.getUniqueWallet(this.userIdProfile);
    document.body.style.overflow = '';
    document.getElementById('nav-btn').style.display = 'flex';
  }

  change(e) {
    this.value = e.target.value;
  }

  changeStep() {
    if (this.step === 'add-card') {
      this.step = 'billing';
    } else if (this.step === 'billing') {
      this.step = 'buy-slot';
    } else {
      this.step = 'add-card';
    }
  }

  checkTerms() {
    this.checked = !this.checked;
  }

  private updateWallet(value) {
    let result;
    this.propertiesservice
      .updateWallet(this.userIdProfile, value)
      .toPromise()
      .then((response) => {
        result = response;
      });

    return result;
  }

  async getTransaction() {
    await this.propertiesservice
      .getTrasaction(this.userIdProfile)
      .toPromise()
      .then((response) => {
        response.forEach((element) => {
          var date = moment.unix(element.timestamp).format('MM.DD.YYYY');
          var time = moment.unix(element.timestamp).format('HH:mm:ss');
          this.transactionsList.push({
            name: element.description,
            date: date,
            time: time,
            ammount: element.value,
          });
        });
        this.propretiesBoostedArr = [];
        if (this.boostedList.length > 0) {
          this.boostedList.forEach((element) => {
            var found = response.find((obj) => {
              return obj.idProperty === element.Id;
            });
            var toDay = moment();
            if (element.Booster == true && element.Highlight == true) {
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(element.MonthBooster, 'months');
              var startDateBooster = moment(datetime);
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(element.MonthHighlight, 'months');
              var startDateHigh = moment(datetime);
            } else if (element.Highlight == true && element.Booster == false) {
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(element.MonthHighlight, 'months');
              var startDateHigh = moment(datetime);
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(0, 'months');
              var startDateBooster = moment(datetime);
            } else if (element.Booster == true && element.Highlight == false) {
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(element.MonthBooster, 'months');
              var startDateBooster = moment(datetime);
              var datetime = moment(found.date + ' ' + found.time);
              datetime.add(0, 'months');
              var startDateHigh = moment(datetime);
            }
            this.propretiesBoostedArr.push({
              Name: element.Name,
              Booster: element.Booster,
              Highlight: element.Highlight,
              price: element.Price,
              Id: element.Id,
              monthBoost: element.MonthBooster,
              monthHigh: element.MonthHighlight,
              Top: startDateBooster.diff(toDay, 'days'),
              Featured: startDateHigh.diff(toDay, 'days'),
            });
          });
        }
        this.propretiesBoostedArr.forEach((el) => {
          if (Number(el.Top) <= 0) {
            this.updateEventProperty(Number(el.Id), 0, 'MonthBooster');
          }
          if (Number(el.Featured) <= 0) {
            this.updateEventProperty(Number(el.Id), 0, 'MonthHighlight');
          }
        });
      });
    this.sortData();
  }
  private sortData() {
    return this.transactionsList.sort((a, b) => {
      return <any>new Date(b.time) - <any>new Date(a.time);
    });
  }
  private transactionInsert(value, propId, message) {
    var myHeaders = new Headers();
    const dttoday = moment();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      userId: `${this.userIdProfile}`,
      value: Number(value),
      date: `${dttoday.format('MM.DD.YYYY')}`,
      time: `${dttoday.format('HH:mm:ss')}`,
      description: message,
      idslot: 0,
      idProperty: propId,
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${environment.apihelloSign}transactionsinsert`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != null) {
          console.log('data - ' + '');
        }
      })
      .catch((error) => console.log('error', error));
  }

  paymentModal(value) {
    this.closeModal();
    this.paymentItems = [];
    this.paymentItems.push({
      name: 'teste ',
      quantity: '1',
      category: 'DIGITAL_GOODS',
      unit_amount: { currency_code: 'USD', value: value },
    });

    if (value === 0) {
      this.toast.error('Add a value to proceed!', 'Error!');
      return;
    }

    const modalRef = this.modalService.open(PaymentmodalComponent, {
      scrollable: true,
      windowClass: 'mycustommodelclass',
      // keyboard: false,
      // backdrop: 'static'
    });
    let data = {
      prop1: JSON.stringify(this.paymentItems),
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.componentInstance.slots = true;
    modalRef.result.then(
      (result) => {
        if (result == true) {
          let valueWallet = Number(this.cash) + Number(value);
          this.updateWallet(valueWallet);
          this.transactionInsert(value, 0, 'RC Bonus Tokens');
          this.getUniqueWallet(this.userIdProfile);
          this.getTransaction();
        }
      },
      (reason) => {}
    );
  }

  setCurrent(type: any, event: any) {
    this.typeCurrent = type;
    document.getElementById('paypal-box').classList.remove('opened-method'); 
    if (event.target.id === 'credit') {
      if (event.target.parentElement.classList.contains('opened-method')) {
        event.target.parentElement.classList.remove('opened-method');
        this.typeCurrent = '';
      } else {
        event.target.parentElement.classList.add('opened-method');
      }
    } else if (event.target.id === 'credit-box') {
      if (event.target.classList.contains('opened-method')) {
        event.target.classList.remove('opened-method');
        this.typeCurrent = '';
      } else {
        event.target.classList.add('opened-method');
      }
    } else {
      return;
    }
  }
}
