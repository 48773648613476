<div class="imagebox"></div>


<section class="py-8" data-animated-id="2">
    <div class="container">
        <div class="row ml-xl-0 mr-xl-n6">
            <div class="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div class="primary-sidebar-inner" style="position: static; left: auto; max-width: 370px; width: 100%;">

                        <div class="px-6 pb-6 media-list">

                            <ul style="padding: 10px 15px; background-color: white;" class="list-group list-group-no-border">
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary" routerLink="/whoweare">
                                        <span class="lh-29">Who We Are</span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a class="d-flex text-body hover-primary">
                                        <span class="lh-29"><strong>About Us</strong></span>
                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a routerLink="/ourstory" class="d-flex text-body hover-primary">
                                        <span class="lh-29">Our Story</span>

                                    </a>
                                </li>
                                <li class="list-group-item p-0">
                                    <a routerLink="/ourmission" class="d-flex text-body hover-primary">
                                        <span class="lh-29">Our Mission</span>

                                    </a>
                                </li>
                            </ul>
                        </div>


                </div>
            </div>
            <div class="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0">
                <div style="padding: 30px;" class="card border-0 mb-6 pb-6 border-bottom">

                    <h1 style="margin-bottom: 20px !important;" class="fs-30 lh-15 mb-0 text-heading font-weight-500 text-center fadeInDown animated" data-animate="fadeInDown">About Us</h1>

                    <div style="display: flex;justify-content: center;">

                    <div class="d-flex align-items-end col-md-6 p-0 ">

                            <img src="assets/images/aboutus.jpeg"
                                alt="About Us" class="card-img">

                    </div>
                </div>

                <p class="desc-static mb-4 lh-214 pt-5">Roundcomb was founded on April 6, 2015. It is a leading contemporary real estate platform enabling users meet their property listing, rental, and search needs. User can create an individual or business profile, showcasing property holdings, while maintaining confidentiality of same. Instantly perform routine due diligence with respect to rental application submission, screening, accept/decline notification, and subsequent fulfillment. Assign and update ratings. Track and verify rental history. Join Roundcomb and begin to collaborate with landlords, property managers, agents, or tenants in information sharing, and experience the power of due diligence. With a Roundcomb online or mobile application, you will discover that your ideal tenant or property is only a click away. Together, we are creating a healthy marketplace for prudent real estate investors and tenants, one user at a time.</p>
                </div>


            </div>

        </div>
    </div>
</section>
