import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountandprofile',
  templateUrl: './accountandprofile.component.html',
  styleUrls: ['./accountandprofile.component.css']
})
export class AccountandprofileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
