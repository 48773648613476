import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, usersService } from '../services';

import { SocialAuthService } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';

const fbLoginOptions = {
  scope: 'email', //'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages'
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

@Component({
  selector: 'app-registernewuser',
  templateUrl: './registernewuser.component.html',
  styleUrls: ['./registernewuser.component.css'],
})
export class RegisternewuserComponent implements OnInit {
  newuserForm: FormGroup;
  loading = false;
  submitted = false;
  submitted2 = false;
  returnUrl: string;
  errorMessage = '';
  errorRole = false;
  errorMessageRole: string = 'Select role required';
  public step: number = 1;
  validateformsecondstep = false;
  validateformfirststep = true;
  regularloginprovider = true;
  Externalprovider: string = 'regular';
  userselectedroles = new Array<{}>();
  imageprofileurl: string = '';

  user: SocialUser;

  istenantChecked: boolean = false;
  isvendorChecked: boolean = false;
  islandlordChecked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: SocialAuthService,
    private usersService: usersService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.isUserLogged) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {
    this.newuserForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        phoneNumber: [
          '',
          [Validators.required, Validators.pattern('[- +()0-9]+')],
        ],
        password: ['', Validators.required],
        repassword: ['', Validators.required],
        acceptterms: ['', Validators.requiredTrue],
      },
      {
        validator: this.ConfirmedValidator('password', 'repassword'),
      }
    );

    this.authService.authState.subscribe((user) => {
      this.user = user;
      //this.loggedIn = (user != null);
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = 'userregisterdone'; //this.route.snapshot.queryParams['returnUrl'] || '/';

    //by default tenant
    this.userselectedroles.push(90295);

    /* this.newuserForm = new FormGroup({
    firstName: new FormControl(this.newuserForm, Validators.required),
    lastName: new FormControl(this.newuserForm, Validators.required),
    email: new FormControl(this.newuserForm, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  });*/
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  navigateToRecoverPassword() {
    this.router.navigate(['/recoverpassword']);
  }

  async signInWithFB() {
    await this.authService.signIn(
      FacebookLoginProvider.PROVIDER_ID,
      fbLoginOptions
    );

    if (JSON.stringify(this.user).length > 3) {
      this.f.firstName.setValue(this.user.firstName);
      this.f.lastName.setValue(this.user.lastName);
      this.f.email.setValue(this.user.email);
      this.f.password.setValue(this.user.id);
      this.f.repassword.setValue(this.user.id);

      this.regularloginprovider = false;
      this.validateformfirststep = false;
      this.step = 2;

      this.Externalprovider = 'Facebook';

      this.imageprofileurl = this.user.photoUrl;
    }
  }

  async signInWithGoogle() {
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

    if (JSON.stringify(this.user).length > 3) {
      this.f.firstName.setValue(this.user.firstName);
      this.f.lastName.setValue(this.user.lastName);
      this.f.email.setValue(this.user.email);
      this.f.password.setValue(this.user.id);
      this.f.repassword.setValue(this.user.id);

      this.regularloginprovider = false;
      this.validateformfirststep = false;
      this.step = 2;

      this.Externalprovider = 'Google';

      this.imageprofileurl = this.user.photoUrl;
    }
  }

  public movenextstep() {
    this.validateformsecondstep = false;
    this.submitted = true;
    if (
      (this.f.firstName.value == '' || this.f.firstName.status === 'INVALID') ||
      (this.f.lastName.value == '' || this.f.lastName.status) === 'INVALID' ||
      (this.f.email.value == ''  || this.f.email.status === 'INVALID')
    )
      return;

    if (this.step < 2) {
      this.step = this.step + 1;
    }
  }

  public validateSubmit() {
    this.validateformsecondstep = false;
    this.submitted2 = true;
    this.errorRole = false;
    if (this.userselectedroles.length === 0) {
      this.validateformsecondstep = true;
      this.errorRole = true;
      return;
    }

    if (this.newuserForm.invalid) {
      this.validateformsecondstep = true;
      return;
    }

    // this.validateformsecondstep = true;
    // stop here if form is invalid

    // this.validateformsecondstep = false;

    /* if(this.f.phoneNumber.value == '' || this.f.password.value == '' || this.f.repassword.value == "" || this.f.acceptterms.value == "" || this.userselectedroles.length === 0)
        return;*/
    this.submitted2 = false;
    this.onSubmit();
  }

  public movepreviousstep() {
    if (this.step > 1) this.step = this.step - 1;
  }

  OnChangeRole(event) {
    this.userselectedroles = [];
    if (!event.target.checked) {
      this.userselectedroles.forEach((element, index) => {
        if (element == event.target.value)
          this.userselectedroles.splice(index, 1);
      });
    } else {
      this.userselectedroles.push(event.target.value);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newuserForm.controls;
  }

  get firstName() {
    return this.newuserForm.get('firstName');
  }

  get lastName() {
    return this.newuserForm.get('lastName');
  }

  get email() {
    return this.newuserForm.get('email');
  }

  onSubmit() {
    let jsonselectedroles = JSON.stringify(this.userselectedroles);
    this.loading = true;
    this.authenticationService
      .setNewUserRegistration(
        this.f.firstName.value,
        this.f.lastName.value,
        this.f.email.value,
        this.f.phoneNumber.value,
        this.f.password.value,
        this.f.repassword.value,
        this.Externalprovider,
        jsonselectedroles
      )
      .pipe(first())
      .subscribe(
        (data) => {
          //this.authenticationService.setUserLogged();
          this.authenticationService.sendMailRegistration(this.f.email.value, 'Activion');
          this.router.navigate(['/userregisterdone']);

        },
        (error) => {
          // erro no servidor, mas regista user
          if (JSON.parse(JSON.stringify(error)).status == '500')
            this.router.navigate(['/userregisterdone']);

          if (JSON.stringify(error).length > 4)
            this.errorMessage = JSON.parse(
              JSON.stringify(error)
            ).error.errorMessage; //.replace('"','').replace('"','').replace('"','').replace('"','').replace("\",'').replace('\/','');
        }
      );
  }
}
