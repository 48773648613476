<main id="content">

  <section class="pt-8 pb-11 bg-gray-01">
    <div style="position: relative">

      <ngx-ui-loader></ngx-ui-loader>

    </div>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb py-3">
          <li class="breadcrumb-item fs-12 letter-spacing-087">
            <a routerLink="/home">Home</a>
          </li>
          <li class="breadcrumb-item fs-12 letter-spacing-087 active">
            Listing
          </li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-lg-4 order-lg-1 primary-sidebar sidebar-sticky no-reponsive" id="sidebar">
          <div class="primary-sidebar-inner">
            <div class="card mb-4">
              <div class="card-body card-responsive-menu px-6 py-4" style="transition: all 1s ease;">

                <div class="responsive-downIcon">
                  <h4 class="card-title fs-16 lh-2 text-dark mb-3">Find your home</h4>
                </div>
                <form [formGroup]="formfilterproperty" class="form"
                  (ngSubmit)="searchProperties(bedsrange.value, bathsrange.value,pricerange.value,arearange.value)">
                  <div class="form-group">
                    <label for="keyword" class="sr-only">Key Word</label>
                    <input type="text" formControlName="keyword"
                      class="form-control form-control-lg border-0 shadow-none" id="keyword" name="keyword"
                      placeholder="Enter keyword...">
                  </div>
                  <div class="form-group">
                    <label for="propertycategory" class="text-heading">Property Category</label>
                    <select class="form-control border-0 shadow-none form-control-lg" name="propertycategory"
                      title="Property Category" data-style="btn-lg py-2 h-52" id="propertycategory"
                      (change)="selectCategoryOption($event)">
                      <option *ngFor="let propertycategory of lstpropertylist" [ngValue]="propertycategory.id"
                        id="propertycategory" name="propertycategory" [selected]=propertycategory.isSelected>
                        {{
                                                propertycategory.desctext }}</option>
                    </select>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">

                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Beds</label>
                      <input type="checkbox" class="custom-control-input" name="switchbeds" id="switchbeds"
                        formControlName="switchbedsform" [value]="false" [(ngModel)]="isbedscheked">
                      <label class="custom-control-label h-24" for="switchbeds"></label>
                    </div>

                    <div [hidden]="!isbedscheked" data-slider="true"
                      data-slider-options='{"min":0,"max":15,"values":[1,10],"type":"quantity"}'
                      (click)="onchangebeds($event)">
                    </div>
                    <div [hidden]="!isbedscheked" class="text-center mt-2">
                      <input #bedsrange id="beds" type="text" readonly name="beds" formControlName="bedsform"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>

                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Baths</label>
                      <input type="checkbox" class="custom-control-input" name="switchbaths" id="switchbaths"
                        formControlName="switchbathsform" [(ngModel)]="isbathscheked">
                      <label class="custom-control-label h-24" for="switchbaths"></label>
                    </div>
                    <div [hidden]="!isbathscheked" data-slider="true"
                      data-slider-options='{"min":0,"max":8,"values":[1,5],"type":"quantity"}'
                      (click)="onchangebaths($event)">
                    </div>
                    <div [hidden]="!isbathscheked" class="text-center mt-2">
                      <input #bathsrange id="baths" type="text" readonly name="baths"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Price
                        Range</label>
                      <input type="checkbox" class="custom-control-input" name="switchpricerange" id="switchpricerange"
                        formControlName="switchpriceform" [(ngModel)]="ispricecheked">
                      <label class="custom-control-label h-24" for="switchpricerange"></label>
                    </div>
                    <div [hidden]="!ispricecheked" style="display: flex; gap: 10px;" class="text-center mt-2">
                      <input [value]="this.minValue" class="form-control form-control-lg border-0 shadow-none"
                        placeholder="Min value..." type="text" #pricerange name="min" id="min"
                        (input)="onchangeprice($event, 'min')">
                      <input [value]="this.maxValue" class="form-control form-control-lg border-0 shadow-none"
                        placeholder="Max Value..." type="text" name="max" id="max"
                        (input)="onchangeprice($event, 'max')">
                    </div>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Area
                        Size</label>
                      <input type="checkbox" class="custom-control-input" name="switchareasize"
                        [(ngModel)]="isareacheked" id="switchareasize" formControlName="switchareaform">
                      <label class="custom-control-label h-24" for="switchareasize"></label>
                    </div>
                    <div [hidden]="!isareacheked" data-slider="true"
                      data-slider-options='{"min":0,"max":15000,"values":[50,15000],"type":"sqrt"}'
                      (click)="onchangearea($event)">
                    </div>
                    <div [hidden]="!isareacheked" class="text-center mt-2">
                      <input #arearange id="area" type="text" readonly name="area"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>
                  </div>
                  <div class="form-group">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Location</label>
                      <input type="checkbox" class="custom-control-input" name="location" id="switchlocation"
                        formControlName="switchlocationform" (change)="onLocationcheckChange($event)" [value]="false"
                        [(ngModel)]="islocationcheked">
                      <label class="custom-control-label h-24" for="switchlocation"></label>
                      <a [hidden]="!islocationcheked" style="padding-left:20px;" (click)="clearlocation()">
                        <label style="cursor: pointer;color:#0ca5b1">clear location</label></a>
                    </div>

                    <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">
                      {{this.address}}
                    </label>

                    <div class="mb-4">
                      <a class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent" data-toggle="modal"
                        (click)="openGoogelMapsModal()">
                        <img src="assets/images/location-pin.png" alt="Set Location" class="mr-2">
                        Set Location
                      </a>
                    </div>

                  </div>
                  <div style="cursor: default;" class="form-group">
                    <p style="margin-bottom: 0 !important;" for="mileslocation" class="text-heading">With in Miles</p>
                    <div class="form-control border-0 shadow-none form-control-lg"
                      style="position: relative; width: 100%;">
                      <div (click)="this.showMiles = !this.showMiles">
                        <span>{{this.locationDesc}}</span>
                      </div>
                      <div *ngIf="this.showMiles" class="sort-properties location-top">
                        <ul style="padding-left: 0 !important;">
                          <li style="list-style: none;" *ngFor="let location of lstmileslocationlist"
                            (click)="onChangelocationdistance(location.value, location.desctext)">
                            {{location.desctext }}
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>

                  <section class="accordion">

                    <input type="checkbox" name="collapse" id="handle4">
                    <h2 class="handle">

                      <label for="handle4">
                        <span class="text-primary d-inline-block mr-1"><i class="far fa-plus-square"></i></span>
                        <span class="fs-15 text-heading font-weight-500 hover-primary">Other
                          Features</span>
                      </label>
                    </h2>
                    <div class="content" id="other-feature">

                      <div class="card card-body border-0 px-0 pb-0 pt-3">
                        <ul class="list-group list-group-no-border">
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check1"
                                formControlName="switchac">
                              <label class="custom-control-label" for="check1">A/C</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check2"
                                formControlName="switchstreetparking">
                              <label class="custom-control-label" for="check2">Street
                                Parking</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check3"
                                formControlName="switchwasher">
                              <label class="custom-control-label" for="check3">Washer</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check4"
                                formControlName="switchgarage">
                              <label class="custom-control-label" for="check4">Garage</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check5"
                                formControlName="switchdryer">
                              <label class="custom-control-label" for="check5">Dryer</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check6"
                                formControlName="switchpool">
                              <label class="custom-control-label" for="check6">Pool</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check7"
                                formControlName="switchpetsallowed">
                              <label class="custom-control-label" for="check7">Pets
                                Allowed</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check8"
                                formControlName="switchathleticcenter">
                              <label class="custom-control-label" for="check8">Athletic
                                Center</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check9"
                                formControlName="switchclubhouse">
                              <label class="custom-control-label" for="check9">Club
                                House</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <div class="mb-4">
                    <a (click)="clearfilters()" class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent">
                      <img src="assets/images/clearfilter.png" alt="Clear Filters" class="mr-2">
                      Clear Filters
                    </a>
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block shadow-none mt-4">Search
                  </button>

                  <!-- -->
                </form>
              </div>
            </div>
            <!--
                        <div class="card property-widget mb-4">
                            <div class="card-body px-6 pt-5 pb-6">
                                <h4 class="card-title fs-16 lh-2 text-dark mb-3">Featured Properties</h4>
                                <div class="slick-slider mx-0"
                                    data-slick-options='{"slidesToShow": 1, "autoplay":true}'>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a class="text-white" routerLink="/propertydetail">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a routerLink="/filterproperty" class="text-white">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a routerLink="/propertydetail" class="text-white">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    -->
          </div>
        </div>
        <div class="col-lg-4 order-lg-1 primary-sidebar sidebar-sticky to-responsive" id="sidebar">
          <div class="primary-sidebar-inner">
            <div class="card mb-4">
              <div class="card-body card-responsive-menu px-6 py-4" style="transition: all 1s ease;"
                [ngClass]="{'filter-responsive': !this.openFilter }">

                <div (click)="verifyResize()" class="responsive-downIcon">
                  <ng-container *ngIf="!this.openFilter">
                    <h4 class="card-title fs-16 lh-2 text-dark mb-3 responsive-title">Open home filter</h4>
                  </ng-container>
                  <ng-container *ngIf="this.openFilter">
                    <h4 class="card-title fs-16 lh-2 text-dark mb-3 no-responsive">Find your home</h4>
                  </ng-container>
                  <fa-icon [ngClass]="{'rotate-downIcon': this.openFilter}" [icon]="faSortDown"></fa-icon>
                </div>
                <form [formGroup]="formfilterproperty" class="form"
                  (ngSubmit)="searchProperties(bedsrange.value, bathsrange.value,pricerange.value,arearange.value)">
                  <div class="form-group">
                    <label for="keyword" class="sr-only">Key Word</label>
                    <input type="text" formControlName="keyword"
                      class="form-control form-control-lg border-0 shadow-none" id="keyword" name="keyword"
                      placeholder="Enter keyword...">
                  </div>
                  <div class="form-group">
                    <label for="propertycategory" class="text-heading">Property Category</label>
                    <select class="form-control border-0 shadow-none form-control-lg" name="propertycategory"
                      title="Property Category" data-style="btn-lg py-2 h-52" id="propertycategory"
                      (change)="selectCategoryOption($event)">
                      <option *ngFor="let propertycategory of lstpropertylist" [ngValue]="propertycategory.id"
                        id="propertycategory" name="propertycategory" [selected]=propertycategory.isSelected>
                        {{
                                                propertycategory.desctext }}</option>
                    </select>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">

                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Beds</label>
                      <input type="checkbox" class="custom-control-input" name="switchbeds" id="switchbeds"
                        formControlName="switchbedsform" [value]="false" [(ngModel)]="isbedscheked">
                      <label class="custom-control-label h-24" for="switchbeds"></label>
                    </div>

                    <div [hidden]="!isbedscheked" data-slider="true"
                      data-slider-options='{"min":0,"max":15,"values":[1,10],"type":"quantity"}'
                      (click)="onchangebeds($event)">
                    </div>
                    <div [hidden]="!isbedscheked" class="text-center mt-2">
                      <input #bedsrange id="beds" type="text" readonly name="beds" formControlName="bedsform"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>

                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Baths</label>
                      <input type="checkbox" class="custom-control-input" name="switchbaths" id="switchbaths"
                        formControlName="switchbathsform" [(ngModel)]="isbathscheked">
                      <label class="custom-control-label h-24" for="switchbaths"></label>
                    </div>
                    <div [hidden]="!isbathscheked" data-slider="true"
                      data-slider-options='{"min":0,"max":8,"values":[1,5],"type":"quantity"}'
                      (click)="onchangebaths($event)">
                    </div>
                    <div [hidden]="!isbathscheked" class="text-center mt-2">
                      <input #bathsrange id="baths" type="text" readonly name="baths"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Price
                        Range</label>
                      <input type="checkbox" class="custom-control-input" name="switchpricerange" id="switchpricerange"
                        formControlName="switchpriceform" [(ngModel)]="ispricecheked">
                      <label class="custom-control-label h-24" for="switchpricerange"></label>
                    </div>
                    <div [hidden]="!ispricecheked" style="display: flex; gap: 10px;" class="text-center mt-2">
                      <input [value]="this.minValue" class="form-control form-control-lg border-0 shadow-none"
                        placeholder="Min value..." type="text" #pricerange name="min" id="min"
                        (input)="onchangeprice($event, 'min')">
                      <input [value]="this.maxValue" class="form-control form-control-lg border-0 shadow-none"
                        placeholder="Max Value..." type="text" name="max" id="max"
                        (input)="onchangeprice($event, 'max')">
                    </div>
                  </div>
                  <div class="form-group slider-range slider-range-secondary">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Area
                        Size</label>
                      <input type="checkbox" class="custom-control-input" name="switchareasize"
                        [(ngModel)]="isareacheked" id="switchareasize" formControlName="switchareaform">
                      <label class="custom-control-label h-24" for="switchareasize"></label>
                    </div>
                    <div [hidden]="!isareacheked" data-slider="true"
                      data-slider-options='{"min":0,"max":15000,"values":[50,15000],"type":"sqrt"}'
                      (click)="onchangearea($event)">
                    </div>
                    <div [hidden]="!isareacheked" class="text-center mt-2">
                      <input #arearange id="area" type="text" readonly name="area"
                        class="border-0 amount text-center text-body font-weight-500">
                    </div>
                  </div>
                  <div class="form-group">
                    <div style="display: flex; justify-content: space-between;"
                      class="p-1 custom-control custom-switch custom-switch-right ml-lg-auto">
                      <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">Location</label>
                      <input type="checkbox" class="custom-control-input" name="location" id="switchlocation"
                        formControlName="switchlocationform" (change)="onLocationcheckChange($event)" [value]="false"
                        [(ngModel)]="islocationcheked">
                      <label class="custom-control-label h-24" for="switchlocation"></label>
                      <a [hidden]="!islocationcheked" style="padding-left:20px;" (click)="clearlocation()">
                        <label style="cursor: pointer;color:#0ca5b1">clear location</label></a>
                    </div>

                    <label class="fs-13 text-heading font-weight-500 custom-switch-right mr-7">
                      {{this.address}}
                    </label>

                    <div class="mb-4">
                      <a class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent" data-toggle="modal"
                        (click)="openGoogelMapsModal()">
                        <img src="assets/images/location-pin.png" alt="Set Location" class="mr-2">
                        Set Location
                      </a>
                    </div>

                  </div>
                  <div style="cursor: default;" class="form-group">
                    <p style="margin-bottom: 0 !important;" for="mileslocation" class="text-heading">With in Miles</p>
                    <div class="form-control border-0 shadow-none form-control-lg"
                      style="position: relative; width: 100%;">
                      <div (click)="this.showMiles = !this.showMiles">
                        <span>{{this.locationDesc}}</span>
                      </div>
                      <div *ngIf="this.showMiles" class="sort-properties location-top">
                        <ul style="padding-left: 0 !important;">
                          <li style="list-style: none;" *ngFor="let location of lstmileslocationlist"
                            (click)="onChangelocationdistance(location.value, location.desctext)">
                            {{location.desctext }}
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>

                  <section class="accordion">

                    <input type="checkbox" name="collapse" id="handle4">
                    <h2 class="handle">

                      <label for="handle4">
                        <span class="text-primary d-inline-block mr-1"><i class="far fa-plus-square"></i></span>
                        <span class="fs-15 text-heading font-weight-500 hover-primary">Other
                          Features</span>
                      </label>
                    </h2>
                    <div class="content" id="other-feature">

                      <div class="card card-body border-0 px-0 pb-0 pt-3">
                        <ul class="list-group list-group-no-border">
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check1"
                                formControlName="switchac">
                              <label class="custom-control-label" for="check1">A/C</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check2"
                                formControlName="switchstreetparking">
                              <label class="custom-control-label" for="check2">Street
                                Parking</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check3"
                                formControlName="switchwasher">
                              <label class="custom-control-label" for="check3">Washer</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check4"
                                formControlName="switchgarage">
                              <label class="custom-control-label" for="check4">Garage</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check5"
                                formControlName="switchdryer">
                              <label class="custom-control-label" for="check5">Dryer</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check6"
                                formControlName="switchpool">
                              <label class="custom-control-label" for="check6">Pool</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check7"
                                formControlName="switchpetsallowed">
                              <label class="custom-control-label" for="check7">Pets
                                Allowed</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check8"
                                formControlName="switchathleticcenter">
                              <label class="custom-control-label" for="check8">Athletic
                                Center</label>
                            </div>
                          </li>
                          <li class="list-group-item px-0 pt-0 pb-2">
                            <div class="custom-control custom-checkbox form-group">
                              <input type="checkbox" class="custom-control-input" name="features[]" id="check9"
                                formControlName="switchclubhouse">
                              <label class="custom-control-label" for="check9">Club
                                House</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <div class="mb-4">
                    <a (click)="clearfilters()" class="btn btn-lg btn-block text-heading border px-0 bg-hover-accent">
                      <img src="assets/images/clearfilter.png" alt="Clear Filters" class="mr-2">
                      Clear Filters
                    </a>
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block shadow-none mt-4">Search
                  </button>

                  <!-- -->
                </form>
              </div>
            </div>
            <!--
                        <div class="card property-widget mb-4">
                            <div class="card-body px-6 pt-5 pb-6">
                                <h4 class="card-title fs-16 lh-2 text-dark mb-3">Featured Properties</h4>
                                <div class="slick-slider mx-0"
                                    data-slick-options='{"slidesToShow": 1, "autoplay":true}'>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a class="text-white" routerLink="/propertydetail">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a routerLink="/filterproperty" class="text-white">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box px-0">
                                        <div class="card border-0">
                                            <img src="assets/images/feature-property-01.jpg" class="card-img" alt="Villa on Hollywood
                                                        Boulevard">
                                            <div class="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">

                                                <div class="px-2 pb-2">
                                                    <a routerLink="/propertydetail" class="text-white">
                                                        <h5 class="card-title fs-16 lh-2 mb-0">Villa on Hollywood
                                                            Boulevard</h5>
                                                    </a>
                                                    <p class="card-text text-gray-light mb-0 font-weight-500">1421 San
                                                        Predro
                                                        St, Los Angeles</p>
                                                    <p class="text-white mb-0"><span
                                                            class="fs-17 font-weight-bold">$2500 </span>/month
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    -->
          </div>
        </div>
        <div class="col-lg-8 mb-8 mb-lg-0 order-1 order-lg-2">
          <div class="row align-items-sm-center mb-4">
            <div class="col-md-6">
              <h2 class="fs-15 text-dark mb-0">We found <span class="text-primary">{{ this.alltotalproperties
                                    }}</span>
                properties
                available for
                you
              </h2>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <div class="d-flex justify-content-md-end align-items-center">
                <div class="input-group border rounded input-group-lg w-auto bg-white mr-3">
                  <div style="display: flex; align-items: center; padding: 0.85rem 1.5rem; position: relative;">
                    <p class="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093"
                      style="margin-bottom: 0 !important; padding: 0 0.75rem 0 0 !important;"><i
                        class="fas fa-align-left fs-16"></i>Sort By:</p>
                    <div>
                      <p (click)="this.showSortBy = !this.showSortBy"
                        style="margin-bottom: 0 !important; cursor: default; font-weight: 600;">{{this.label}} <fa-icon
                          [icon]="faAngleDown">
                        </fa-icon>
                      </p>
                    </div>
                    <div *ngIf="this.showSortBy" class="sort-properties sort-top">
                      <ul style="list-style: none; padding-left: 0 !important;">
                        <li [ngClass]="{'line-down': i !== this.options.length - 1}"
                          *ngFor="let option of this.options; let i = index"
                          (click)="onChangeorderby(option.value, option.option)">
                          {{option.option}}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <label class="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3"
                    for="inputGroupSelect01"><i class="fas fa-align-left fs-16 pr-2"></i>Sortby:</label>
                  <select class="form-control border-0 bg-transparent shadow-none p-0 sortby"
                    data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3" id="inputGroupSelect01"
                    name="sortby" #t (change)="onChangeorderby(t.value)">
                    <option [ngValue]="1" value="1">Date(low to high)</option>
                    <option [ngValue]="2" value="2">Date(high to low)</option>
                    <option [ngValue]="3" value="3" selected>Price(low to high)</option>
                    <option [ngValue]="4" value="4">Price(high to low)</option>
                    <option [ngValue]="5" value="5">Beds(low to high)</option>
                    <option [ngValue]="6" value="6">Beds(high to low)</option>
                    <option [ngValue]="7" value="7">Baths(low to high)</option>
                    <option [ngValue]="8" value="8">Baths(high to low)</option>
                  </select> -->
                </div>
                <ng-container *ngIf="!this.showpropertylistGrid">
                  <div class="d-none d-md-block">
                    <a class="fs-sm-18 text-dark">
                      <i class="fas fa-list"></i>
                    </a>
                    <a class="fs-sm-18 text-dark opacity-2 ml-5" style="cursor: pointer"
                      (click)="changepropertylistGrid()">
                      <i class="fa fa-th-large"></i>
                    </a>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.showpropertylistGrid">
                  <div class="d-none d-md-block">
                    <a class="fs-sm-18 text-dark opacity-2" style="cursor: pointer" (click)="changepropertylistGrid()">
                      <i class="fas fa-list"></i>
                    </a>
                    <a class="fs-sm-18 text-dark ml-5">
                      <i class="fa fa-th-large"></i>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!this.showpropertylistGrid">
            <div *ngFor="let propertie of this.propList; let i = index">
              <ng-container>
                <div
                  class="py-5 px-4 border rounded-lg shadow-hover-1 top-border border-boosted bg-white mb-4 fadeInUp animated">
                  <div class="emphasis-text boosted-prop">
                    <p class="text-position">TOP</p>
                  </div>
                  <div class="media flex-column flex-sm-row no-gutters">
                    <div class="col-sm-3 mr-sm-5 card border-0 hover-change-image bg-hover-overlay mb-sm-5"
                      (click)="navigateToPropertyDetails(propertie.IdProperty)">

                      <img style="height: 120px !important;" [src]="propertie.imageurl" alt="{{propertie.Name}}">

                    </div>
                    <div class="media-body mt-3 mt-sm-0">
                      <h2 class="my-0"><a (click)="navigateToPropertyDetails(propertie.IdProperty)"
                          style="cursor: pointer"
                          class="fs-16 lh-2 text-dark hover-primary d-block props-link">{{propertie.Name}}</a>
                      </h2>
                      <p class="mb-1 font-weight-500 text-gray-light">{{propertie.OwnerName}}
                      </p>
                      <p class="fs-17 font-weight-bold text-heading mb-1">
                        {{ this.formatnumberasUSDCurrency(propertie.Price) }}
                        <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                      </p>
                      <p class="mb-2 ml-0">{{ propertie.ShortDescription }}</p>
                    </div>
                  </div>
                  <div class="d-sm-flex justify-content-sm-between">
                    <ul class="list-inline d-flex mb-0 flex-wrap">
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Bedrooms">
                        <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                          <use xlink:href="#icon-bedroom"></use>
                        </svg>
                        {{ propertie.Beds }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Bathrooms">
                        <svg class="icon icon-shower fs-18 text-primary mr-1">
                          <use xlink:href="#icon-shower"></use>
                        </svg>
                        {{ propertie.Baths }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Size Sq.Ft">
                        <svg class="icon icon-square fs-18 text-primary mr-1">
                          <use xlink:href="#icon-square"></use>
                        </svg>
                        {{ propertie.SquareFeet }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Garage">
                        <svg class="icon icon-Garage fs-18 text-primary mr-1">
                          <use xlink:href="#icon-Garage"></use>
                        </svg>
                        {{ propertie.Garage }}
                      </li>
                    </ul>
                    <ng-container *ngIf="propertie.namecategory ==='Single house'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color: yellowgreen;color:white;">
                        {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Apartment'">
                      <span class="badge badge-indigo mr-xl-2 mt-3 mt-sm-0"> {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Single Luxury'">
                      <span class="badge badge-orange mr-xl-2 mt-3 mt-sm-0"> {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Single Family'">
                      <span class="badge badge-primary mr-xl-2 mt-3 mt-sm-0"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Villa'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color:rgb(201, 201, 5);color:white;"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Townhouse'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color:burlywood;;color:white;"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!this.showpropertylistGrid">

            <div
              *ngFor="let propertie of this.propertieslist | paginate: { itemsPerPage: 7, currentPage: page, totalItems: count }; let i = index">

              <ng-container *ngIf="propertie != undefined">
                <div [ngClass]="{'emphasis': propertie.Highlight}"
                  class="py-5 px-4 border rounded-lg shadow-hover-1 bg-white mb-4 fadeInUp animated">
                  <div *ngIf="propertie.Highlight" class="emphasis-text">
                    <fa-icon class="text-position star-position" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="media flex-column flex-sm-row no-gutters">
                    <div class="col-sm-3 mr-sm-5 card border-0 hover-change-image bg-hover-overlay mb-sm-5"
                      (click)="navigateToPropertyDetails(propertie.IdProperty)">

                      <img src="{{propertie.imageurl}}" class="card-img responsivelistimage" alt="{{propertie.Name}}">

                    </div>
                    <div class="media-body mt-3 mt-sm-0">
                      <h2 class="my-0"><a (click)="navigateToPropertyDetails(propertie.IdProperty)"
                          style="cursor: pointer"
                          class="fs-16 lh-2 text-dark hover-primary d-block props-link">{{propertie.Name}}</a>
                      </h2>
                      <p class="mb-1 font-weight-500 text-gray-light">{{propertie.OwnerName}}
                      </p>
                      <p class="fs-17 font-weight-bold text-heading mb-1">
                        {{ this.formatnumberasUSDCurrency(propertie.Price) }}
                        <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                      </p>
                      <p class="mb-2 ml-0">{{ propertie.ShortDescription }} </p>
                    </div>
                  </div>
                  <div class="d-sm-flex justify-content-sm-between">
                    <ul class="list-inline d-flex mb-0 flex-wrap">
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Bedrooms">
                        <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                          <use xlink:href="#icon-bedroom"></use>
                        </svg>
                        {{ propertie.Beds }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Bathrooms">
                        <svg class="icon icon-shower fs-18 text-primary mr-1">
                          <use xlink:href="#icon-shower"></use>
                        </svg>
                        {{ propertie.Baths }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Size Sq.Ft">
                        <svg class="icon icon-square fs-18 text-primary mr-1">
                          <use xlink:href="#icon-square"></use>
                        </svg>
                        {{ propertie.SquareFeet }}
                      </li>
                      <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip" title="Garage">
                        <svg class="icon icon-Garage fs-18 text-primary mr-1">
                          <use xlink:href="#icon-Garage"></use>
                        </svg>
                        {{ propertie.Garage }}
                      </li>
                    </ul>
                    <ng-container *ngIf="propertie.namecategory ==='Single house'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color: yellowgreen;color:white;">
                        {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Apartment'">
                      <span class="badge badge-indigo mr-xl-2 mt-3 mt-sm-0"> {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Single Luxury'">
                      <span class="badge badge-orange mr-xl-2 mt-3 mt-sm-0"> {{ propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Single Family'">
                      <span class="badge badge-primary mr-xl-2 mt-3 mt-sm-0"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Villa'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color:rgb(201, 201, 5);color:white;"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                    <ng-container *ngIf="propertie.namecategory ==='Townhouse'">
                      <span class="badge mr-xl-2 mt-3 mt-sm-0" style="background-color:burlywood;;color:white;"> {{
                                            propertie.namecategory
                                            }}</span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="this.showpropertylistGrid">
            <div class="row">

              <ng-container *ngFor="let propertie of this.propList; let i = index">
                <div class="col-md-6 mb-6">
                  <div class="card border-0 top-border fadeInUp animated">
                    <div class="emphasis-text boosted-prop">
                      <p class="text-position">TOP</p>
                    </div>
                    <a (click)="navigateToPropertyDetails(this.IdProperty0)">
                      <div class="position-relative hover-change-image bg-hover-overlay rounded-lg card-img">
                        <img src="{{propertie.imageurl}}" alt="{{propertie.Name}}" class="responsivegridimage">
                        <div class="card-img-overlay d-flex flex-column">
                          <div class="mt-auto d-flex hover-image">

                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="card-body pt-3 px-0 pb-1" style="margin-left:0.5em">
                      <h2 class="fs-16 mb-1"><a (click)="navigateToPropertyDetails(this.IdProperty0)"
                          class="text-dark hover-primary" style=" text-indent: 10px; "
                          style="cursor: pointer">{{propertie.Name}}</a>
                      </h2>
                      <p class="font-weight-500 text-gray-light mb-0">{{propertie.OwnerName}}
                      </p>
                      <p class="fs-17 font-weight-bold text-heading mb-0 lh-16">
                        {{this.formatnumberasUSDCurrency(propertie.Price)}}
                        <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                      </p>
                    </div>
                    <div class="card-footer bg-transparent px-0 pb-0 pt-2" style="margin-left:0.5em">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-7" data-toggle="tooltip"
                          title="" data-original-title="3 Bedrooms">
                          <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                            <use xlink:href="#icon-bedroom"></use>
                          </svg>
                          {{propertie.Beds}}
                        </li>
                        <li class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-7" data-toggle="tooltip"
                          title="" data-original-title="3 Bathrooms">
                          <svg class="icon icon-shower fs-18 text-primary mr-1">
                            <use xlink:href="#icon-shower"></use>
                          </svg>
                          {{propertie.Baths}}
                        </li>
                        <li class="list-inline-item text-gray font-weight-500 fs-13" data-toggle="tooltip" title=""
                          data-original-title="Size">
                          <svg class="icon icon-square fs-18 text-primary mr-1">
                            <use xlink:href="#icon-square"></use>
                          </svg>
                          {{propertie.SquareFeet}}
                        </li>
                      </ul>
                      <tr style="height: 10px !important;">
                        <td colspan="3"></td>
                      </tr>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngFor="let propertie of this.propertieslist | paginate: { itemsPerPage: 7, currentPage: page, totalItems: count }; let i = index">
                <div class="col-md-6 mb-6">
                  <div [ngClass]="{'emphasis': propertie.Highlight}" class="card border-0 fadeInUp animated">
                    <div *ngIf="propertie.Highlight" class="emphasis-text">
                      <fa-icon class="text-position star-position" [icon]="faStar"></fa-icon>
                    </div>
                    <a (click)="navigateToPropertyDetails(this.IdProperty0)">
                      <div class="position-relative hover-change-image bg-hover-overlay rounded-lg card-img">
                        <img src="{{propertie.imageurl}}" alt="{{propertie.Name}}" class="responsivegridimage">
                        <div class="card-img-overlay d-flex flex-column">
                          <div class="mt-auto d-flex hover-image">

                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="card-body pt-3 px-0 pb-1" style="margin-left:0.5em">
                      <h2 class="fs-16 mb-1"><a (click)="navigateToPropertyDetails(this.IdProperty0)"
                          class="text-dark hover-primary" style=" text-indent: 10px; "
                          style="cursor: pointer">{{propertie.Name}}</a>
                      </h2>
                      <p class="font-weight-500 text-gray-light mb-0">{{propertie.OwnerName}}
                      </p>
                      <p class="fs-17 font-weight-bold text-heading mb-0 lh-16">
                        {{this.formatnumberasUSDCurrency(propertie.Price)}}
                        <span class="text-gray-light font-weight-500 fs-14"> / month</span>
                      </p>
                    </div>
                    <div class="card-footer bg-transparent px-0 pb-0 pt-2" style="margin-left:0.5em">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-7" data-toggle="tooltip"
                          title="" data-original-title="3 Bedrooms">
                          <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                            <use xlink:href="#icon-bedroom"></use>
                          </svg>
                          {{propertie.Beds}}
                        </li>
                        <li class="list-inline-item text-gray font-weight-500 fs-13 mr-sm-7" data-toggle="tooltip"
                          title="" data-original-title="3 Bathrooms">
                          <svg class="icon icon-shower fs-18 text-primary mr-1">
                            <use xlink:href="#icon-shower"></use>
                          </svg>
                          {{propertie.Baths}}
                        </li>
                        <li class="list-inline-item text-gray font-weight-500 fs-13" data-toggle="tooltip" title=""
                          data-original-title="Size">
                          <svg class="icon icon-square fs-18 text-primary mr-1">
                            <use xlink:href="#icon-square"></use>
                          </svg>
                          {{propertie.SquareFeet}}
                        </li>
                        <!-- <li class="list-inline-item text-gray font-weight-500 fs-13" data-toggle="tooltip"
                          title="Garage">
                          <svg class="icon icon-Garage fs-18 text-primary mr-1">
                            <use xlink:href="#icon-Garage"></use>
                          </svg>
                          {{ propertie.Garage }}
                        </li> -->
                      </ul>
                      <tr style="height: 10px !important;">
                        <td colspan="3"></td>
                      </tr>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- <div *ngIf="this.totalproperties!=0">
            <nav class="pt-6">
              <ul class="pagination rounded-active justify-content-center mb-0">
                <li class="page-item"><a class="page-link" style="cursor: pointer" (click)="previouspageindex()"><i
                      class="far fa-angle-double-left"></i></a>
                </li>
                <li *ngFor="let page of pagesarray;" ngClass="{{page.pagedesc}}">
                  <a class="page-link" style="cursor: pointer"
                    (click)="updatepageindex(page.idpage)">{{page.idpage}}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" style="cursor: pointer" (click)="nextpageindex()">
                    <i class="far fa-angle-double-right">
                    </i></a>
                </li>
              </ul>
            </nav>
          </div> -->
            </div>
          </ng-container>
          <div style="margin-top: 2rem;" class="d-flex justify-content-center">
            <div class="pagination-desktop">
              <pagination-template #p="paginationApi" (pageChange)="page = $event">
                <div class="custom-pagination">
                  <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                    <span (click)="p.previous()">
                      < </span> </div> <div class="page-number" *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value">
                        <span (click)="p.setCurrent(page.value)"
                          *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                        <div class="pages" *ngIf="p.getCurrent() === page.value">
                          <span>{{ page.label }}</span>
                        </div>
                  </div>
                  <div class="pagination-next" [class.disabled]="p.isLastPage()">
                    <span (click)="toNext(p)"> > </span>
                  </div>
                </div>
              </pagination-template>
            </div>
            <div  class="pagination-mobile">
              <pagination-controls [responsive]="true" previousLabel="" nextLabel=""
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>

</main>
