import { Component, OnInit,ViewChild, ElementRef,OnDestroy } from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ActivatedRoute, Params } from '@angular/router';
import { WebSocketSubject } from 'rxjs/webSocket';

// structure of the message client <-> server
export class Message {
  constructor(
      public guidChatRoom: string,
      public JSONformatcontent: string,
      public isBroadcast = false,
      public senderID: string,
      public senderName: string,
      public event:string
  ) { }
}

@Component({
  selector: 'app-mobilephonetakephoto',
  templateUrl: './mobilephonetakephoto.component.html',
  styleUrls: ['./mobilephonetakephoto.component.css']
})
export class MobilephonetakephotoComponent implements OnInit,OnDestroy {

   @ViewChild("video")
    public video: ElementRef;

    @ViewChild("canvas")
    public canvas: ElementRef;

  public captures: Array<any>;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public submitted = false;
  public userid:string;
  public chatroomguid:string;
  public phototype:string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  private socket$: WebSocketSubject<Message>;


  constructor(private route: ActivatedRoute) {

    this.captures = [];

  }

  ngOnInit(): void {


    this.route.queryParams.subscribe(
      (queryparams: Params) => {

        this.userid = queryparams.userid;
        this.chatroomguid = queryparams.chatroomguid;
        this.phototype = queryparams.phototype;
      });

    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });

    this.socket$ = new WebSocketSubject('wss://roundcomb.rwi-cloud.com/chat.server');

   // listen for messages from the server
    this.socket$
        .subscribe(
        (message) =>
        {


    });

  }


public capture() {
  var context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 640, 480);
  this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
}

closeModal() {

  this.releaseWebCam();

}

savenewimage()
{

  var messagecontent:string = '{"MessageContent":"<message><mobilephotourl>[&mobilephotourl]</mobilephotourl><phototype>[&phototype]</phototype></message>"}';

  var dttoday: Date = new Date();

  messagecontent = messagecontent.replace('[&date]',dttoday.toLocaleString());
  messagecontent = messagecontent.replace('[&mobilephotourl]',this.croppedImage);
  messagecontent = messagecontent.replace('[&phototype]',this.phototype);

  const message = new Message(this.chatroomguid,messagecontent,false, this.userid,'','updateIdentityPhotoFromMobilephone');

  this.socket$.next(message);

  this.releaseWebCam();

  this.submitted = true;

}


public triggerSnapshot(): void {
  this.trigger.next();

}

public toggleWebcam(): void {
  this.showWebcam = !this.showWebcam;
}

public handleInitError(error: WebcamInitError): void {
  this.errors.push(error);
}

public showNextWebcam(directionOrDeviceId: boolean|string): void {
  // true => move forward through devices
  // false => move backwards through devices
  // string => move to device with given deviceId
  this.nextWebcam.next(directionOrDeviceId);
}

public handleImage(webcamImage: WebcamImage): void {
  //console.info('received webcam image', webcamImage);
  this.webcamImage = webcamImage;
  this.imageChangedEvent =  this.webcamImage.imageAsDataUrl;
  this.toggleWebcam();
}

public cameraWasSwitched(deviceId: string): void {

  this.deviceId = deviceId;
}
public releaseWebCam(): void {
  this.showWebcam = !this.showWebcam;
  this.trigger.complete();
 /* this.trigger.forEach(track => {
    track.stop();
  })
  */
}

public get triggerObservable(): Observable<void> {
  return this.trigger.asObservable();
}

public get nextWebcamObservable(): Observable<boolean|string> {
  return this.nextWebcam.asObservable();
}

imageChangedEvent: any = '';
croppedImage: any = '';

fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}
imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
}
imageLoaded() {
    /* show cropper */
}
cropperReady() {
    /* cropper ready */
}
loadImageFailed() {
    /* show message */
}

ngOnDestroy(): void {
  this.trigger.complete();

}

}
